import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  
  createPdf = (html,fileName='Admin_Invoice.pdf' ) => {
    // alert(JSON.stringify(html))
    console.log("this is html", html)
    savePDF(html, { 
      paperSize: 'A3',
      fileName: fileName,
      margin: 3
    })
  }
}

const Doc = new DocService();
export default Doc;