import React, {  Fragment, PureComponent } from 'react'
//import './InvoiceDetails.css'
import { InvoiceDetailsStatus } from './InvoiceDetailsConstants';
import InvoicesDetails from "./InvoicesDetails"
import { withRouter } from 'react-router-dom';
//import Fragment from "../ConfirmationContract/ConfirmationContract"
//import Snackbar from '@material-ui/core/Snackbar/';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../Utils/Loading';

export class InvoiceDetailsView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,

    }
    props.resetReducerState()
    this.getInvoiceDetails()
  }


  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }
  getInvoiceDetails = () => {

    const data = {
      invoice_id: this.props.Invoice.invoice_id
    }
    this.props.getInvoiceDetails(data)
  }
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
     this.props.getInvoiceDetails()
  }
  getScreen(status) {
    switch (status) {
      case InvoiceDetailsStatus.InvoiceDetails_GET.NEW:
        return (
          <Fragment >
            <div style={{ height: '70vh', width: '97vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h1> Loading </h1>
            </div>
          </Fragment>
        );


      case InvoiceDetailsStatus.InvoiceDetails_GET.SUCCESS:
        //  alert(JSON.stringify(this.props.InvoiceDetails))

        return (
          <Fragment >
            <InvoicesDetails InvoiceDetails={this.props.InvoiceDetails}
              cookies={this.props.cookies}
              Invoice={this.props.Invoice}
            />
          </Fragment>
        );
        break;
      case InvoiceDetailsStatus.InvoiceDetails_GET.FAILED:
        toast.error(this.props.InvoiceDetails_Error)
        return (
          <Fragment>
            <InvoicesDetails
              InvoiceDetails={this.props.InvoiceDetails}
              cookies={this.props.cookies}
              Invoice={this.props.Invoice}
            />

          </Fragment>
        );

      case InvoiceDetailsStatus.InvoiceDetails_GET.LOADING:
        this.state.open = true
        return (
          <div style={{ height: '60vh', width: '90vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.InvoiceDetails_GET_status)
    return (
      this.getScreen(this.props.InvoiceDetails_GET_status)
    )
  }
}

export default withRouter(InvoiceDetailsView)
