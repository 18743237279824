
import React, { Component, Fragment } from 'react'

import "./InvoicesPopup.css"
import logo from "../../Assets/Images/logo-main.png"
import Doc from '../PDFDownload/DocService';
import PdfContainer from '../PDFDownload/PdfContainer';
import dateFormat from 'dateformat'

// import {coupon_transaction_subtype_id} from "RefRecordsConstants"
const modalRoot = document.getElementById("invoice_portal");

const invoicePop = [
    { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },
    { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },
    { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },
    { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },

];


class InvoiceDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'Still, Jaime',
            rank: 'SGT',
            description: 'Demonstrate how to export an HTML section to PDF',
            BusinessProfileData: this.props.cookies.get("BusinessProfileData"),
            ReferalFee: [],
            ProgramFee: 0
        };
        //alert(JSON.stringify(this.state.BusinessProfileData))
        if (props.InvoiceDetails.ReferalFee && props.InvoiceDetails.ReferalFee.length > 0) {
            this.state.ReferalFee = props.InvoiceDetails.ReferalFee
        }

        if (props.InvoiceDetails.ProgramFee && props.InvoiceDetails.ProgramFee.length > 0)
            this.state.ProgramFee = props.InvoiceDetails.ProgramFee[0].ProgramFee



    }

    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            state[name] = value;
            return state;
        })
    }

    createPdf = (html) => Doc.createPdf(html, this.state.BusinessProfileData.business_name.replace(/ /g, '_') + '_Invoice.pdf');


    onClick = () => {
        this.props.onClose()
    }
    donehandler = () => {
        alert("donehandler Work")
    }
    downloadHandler = () => {
        alert("downloadHandler Work")
    }

    render() {
        // this.state.ReferalFee.push({coupon_face_value:45,coupon_quantity:453,referral_fees:454565})
        return (

            <Fragment>
                <React.Fragment>
                    <PdfContainer createPdf={this.createPdf}>
                        <React.Fragment>
                            <div className="pdf-box pdf-121">

                                <div className="pdf-inner">
                                    <div className="pdf-inner-box">
                                        <div className="padf-invoice-bo">
                                            <div className="pdf-in-box">
                                                <div className="pdf-in-head">
                                                    <h2>Invoice</h2>
                                                    <p>NJT&nbsp;Rewards</p>
                                                </div>
                                                <div className="pdf-log">
                                                    <img src={logo} alt="image not found" />
                                                </div>
                                            </div>

                                            <div className="padf-invoice">
                                                <div className="pdf-info-box">
                                                    <div className="padf-info-1">
                                                        Billed to:
                               </div>
                                                    <div className="padf-info-2">
                                                        {this.state.BusinessProfileData.business_name}
                                                    </div>
                                                </div>

                                                <div className="pdf-info-box">
                                                    <div className="padf-info-1">
                                                        Issue Date:
                               </div>
                                                    <div className="padf-info-2">
                                                        {dateFormat(this.props.Invoice.invoice_date, 'mm/dd/yyyy')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="padf-invoice margin">
                                                <div className="pdf-in-value-box">
                                                    {this.state.ReferalFee && this.state.ReferalFee.length>0?
                                                    <table className="pdf-table">
                                                        <thead>
                                                            <th>Face Value</th>
                                                            <th>Quantity</th>
                                                            <th>Individual Referral Fee</th>
                                                            <th>Total Referral Fee</th>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.ReferalFee.map((res, index) => (
                                                                <tr key={index}>
                                                                    {/* {coupon_transaction_subtype_id.PercentageType == res.coupon_transaction_subtype_id? */}
                                                                    {/* <td>{res.coupon_face_value +"%"}</td> */}
                                                                    {/* :<td>{"$"+res.coupon_face_value }</td>} */}
                                                                    <td>{res.coupon_face_value }</td>
                                                                    <td>{res.coupon_quantity}</td>
                                                                    <td>${parseFloat(res.referral_fees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                    <td>${parseFloat(res.coupon_quantity * res.referral_fees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>:null}
                                                </div>
                                            </div>
                                            <div className="padf-invoice">
                                                <div className="pdf-in-value-box-2">
                                                    <table className="pdf-table-2">
                                                        <tr>
                                                            <td>Program Fee</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>${parseFloat(this.state.ProgramFee).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="padf-invoice">
                                                <div className="pdf-in-value-box-3">
                                                    <table className="pdf-table-2">
                                                        <tr>
                                                            <td>Total Fee</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>${parseFloat(this.props.Invoice.totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="pdf-btn-pop">

                                </div>
                            </div>
                        </React.Fragment>
                    </PdfContainer>
                </React.Fragment>
            </Fragment>

        )
    }
}

export default InvoiceDetails