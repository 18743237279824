//******************Exchange Rate Server Calls******************;

import { MessageHistoryActions } from "./MessageHistoryConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const MessageHistoryServer = {
  getMessageHistory: getMessageHistory
};

//******************Get MessageHistorys******************;

function getMessageHistory(data) {
  const res = fetch(ROOT_URL + "/api/messaging/getMessageHistory", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("MessageHistory:", response);
        //  alert(JSON.stringify(response.notificationListCount)) 
          if(response.status == "200")
          store.dispatch({
            type: MessageHistoryActions.MessageHistory_Get.SUCCESS,
            payload: response.data, notificationListCount:response.notificationListCount
          });
          else
          store.dispatch({
            type: MessageHistoryActions.MessageHistory_Get.FAILED
          });
        })
        .catch(err => {
          console.log("MessageHistoryError:", err);
          store.dispatch({
            type: MessageHistoryActions.MessageHistory_Get.FAILED
          });
        });
    })
    .catch(err => {
      console.log("MessageHistoryError:", err);
      store.dispatch({
        type: MessageHistoryActions.MessageHistory_Get.FAILED
      });
    });

  return { type: MessageHistoryActions.MessageHistory_Get.LOADING };
}
