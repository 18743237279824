export const UpdateApplicantsStatusActions= {
	
	
	UpdateApplicantsStatus_Post :{
		NEW:"UpdateApplicantsStatus_Post_NEW",
		SUCCESS:"UpdateApplicantsStatus_Post_SUCCESS",
		FAILED:"UpdateApplicantsStatus_Post_FALIURE",
		LOADING:"UpdateApplicantsStatus_Post_LOADING",
		NOT_AUTHORIZED: "UpdateApplicantsStatus_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const UpdateApplicantsStatusStatus ={

	UpdateApplicantsStatus_Post :{
		NEW:"UpdateApplicantsStatus_Post_NEW",
		SUCCESS:"UpdateApplicantsStatus_Post_SUCCESS",
		FAILED:"UpdateApplicantsStatus_Post_FALIURE",
		LOADING:"UpdateApplicantsStatus_Post_LOADING",
		NOT_AUTHORIZED: "UpdateApplicantsStatus_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
		
	}

}
