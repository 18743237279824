import React, { Component } from 'react'
import UploadBannerHeader from '../../UploadBanner/UploadBannerHeader/UploadBannerHeader'
import SelectSearchInput from '../../Inputs/SelectSearch/SelectSearchInput'

import '../UploadCoupon.css'
import RetailerListContainer from "../../RetailerList/RetailerListContainer";
import CouponImageListContainer from "./CouponImageListContainer"
class RetailerCouponImage extends Component {
    constructor(props) {
        super(props);
        // const banner = props.UpdateBannerData || props.DuplicateBannerData;
        this.state = {
          editMode:true,
          searchName: "",
          business_name: "",
          searchNameFlag: false,
          searchNameErrorMsg: "Field Required",
          selectedImage: "",
          ImageErrorMsg: "",
          notes: "",
          fileimg: "",
          RetailerList:[]
        };
      }
      componentDidMount() {
        window.scrollTo(0, 0);
      }
      setRetailerList = data =>{
        this.setState({
          RetailerList:[{name:'',value:'all'},...data]
        })
      }
      searchNameHandler = e => {
        // console.log("this e check", e);
        // const businessObj = this.state.RetailerList.find(element => {
        //   return element.value == e;
        // });
        // // alert(JSON.stringify(businessObj))
        this.setState({
          searchName: e,
          searchNameFlag: false,
          
        });
      };
    render() {
        const {
            searchName,
            searchNameFlag,
            searchNameErrorMsg,
          } = this.state;
        return (
            <div className="retailer-upload-box">
                <div className="retailer-upload-container">
                    <div className="retailer-upload-header">
                        <UploadBannerHeader
                        heading="Partner Offer Images"
                        />
                    </div>
                    <div className="retailer-upload-inner">
                        <div className="retailer-up-box">
                            <div className="reatiler-search-box">
                                {/* <div className="retailer-label">
                                Partner Coupons
                                </div> */}
                                <div className="retailer-input-search">
                                    <SelectSearchInput
                                    placeholder="Filter By Partners"
                                    inputValue={searchName}
                                    flag={searchNameFlag}
                                    errorMsg={searchNameErrorMsg}
                                    onChange={this.searchNameHandler}
                                    data={this.state.RetailerList}
                                    />
                                     <RetailerListContainer
                            setRetailerList={this.setRetailerList}
                          /> 
                                </div>
                            </div>
                            <div className="retailer-links">
                                <a id="newUploadLink" onClick = {this.props.Go_Next} className="retailer-link-b">New Upload</a>
                            </div>
                        </div>
                    </div>
                    <div className="box-card-retailer">
                    <CouponImageListContainer Retailer = {this.state.searchName}/>
                    {/* <div className="retailer-card-box">
                        <RetailerCard/>
                        
                    </div> */}
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default RetailerCouponImage
