import { connect } from "react-redux";
import GetReferralInvoicesFlagView from "./GetReferralInvoicesFlag_View";
import {GetReferralInvoicesFlagServer} from "./server"
import {GetReferralInvoicesFlagActions} from './GetReferralInvoicesFlagConstants'
const mapStateToProps = state => {
  //  console.log(state.GetReferralInvoicesFlagReducer.GetReferralInvoicesFlag)
    return {
        GetReferralInvoicesFlag_GET_status:state.GetReferralInvoicesFlagReducer.GetReferralInvoicesFlag_Status,
        DisplayTypes:state.GetReferralInvoicesFlagReducer.DisplayTypes,
        GetReferralInvoicesFlag_Error:state.GetReferralInvoicesFlagReducer.GetReferralInvoicesFlag_Error
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GetReferralInvoicesFlag: (setParentState) => {

           dispatch( GetReferralInvoicesFlagServer.GetReferralInvoicesFlag(setParentState) )
        },
        resetReducerState: async()=>{
            dispatch( {type:GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.NEW} )
           
        }
    }
};
const GetReferralInvoicesFlagContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetReferralInvoicesFlagView)

export default GetReferralInvoicesFlagContainer;