import { RetailerListStatus, RetailerListActions } from './RetailerListConstants';

const RetailerList_initialState = {
    RetailerList_Status: RetailerListStatus.RetailerList_GET.NEW,
    RetailerList_Error: '',
    RetailerListDetails: [],
}
export default  function  (state = RetailerList_initialState, action) {
    switch (action.type) {
        case RetailerListActions.RetailerList_GET.LOADING:
            return { ...state, RetailerList_Status: RetailerListStatus.RetailerList_GET.LOADING }
            case RetailerListActions.RetailerList_GET.NEW:
                return { ...state, RetailerList_Status: RetailerListStatus.RetailerList_GET.NEW, RetailerListDetails: [] }
    
        case RetailerListActions.RetailerList_GET.FAILED:
            return { ...state,  RetailerList_Status: RetailerListStatus.RetailerList_GET.FAILED, RetailerList_Error:action.error}
        case RetailerListActions.RetailerList_GET.SUCCESS:
          
          return { ...state,  RetailerList_Status: RetailerListStatus.RetailerList_GET.SUCCESS, RetailerListDetails:action.payload}
          
        
        default:
            return { ...state,
                
            }
    }
}
