import React, { Component } from "react";
import CheckBoxSingle from '../Inputs/CheckBoxSingle/CheckBoxSingle'
import RotateLoading from "../Utils/RotateLoading";

export class ApprovalCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {ApprovalCheckList:props.ApprovalCheckList};

    // this.handleClick = this.handleClick.bind(this);
  }

  render() {
    this.state.ApprovalCheckList = this.props.ApprovalCheckList
    return (
      <>
            <div className="check-list mrg-l-chck">
                {
                  this.state.ApprovalCheckList.map((checkList_item, index)=>
                    <CheckBoxSingle
                    label={checkList_item.rejected_text}
                    id={"checList"+index}
                    onChange={(e)=>{
                     this.props.setCheckListError("")
                      if(checkList_item.value)
                      this.state.ApprovalCheckList[index].value = false
                      else
                      this.state.ApprovalCheckList[index].value = true
                      this.props.updateApprovalList(this.state.ApprovalCheckList)
                    }}
                    value = {checkList_item.value}
                    />
                  )
                  

                }
                <RotateLoading LoadingIcon={this.props.LoadingIcon} loaderStyle={this.props.loaderStyle} />
            </div>
            {/* <div className="checklist-for-user">
                  <CheckBoxSingle
                  label="Send Email  notification to 'NJT Rewards'"
                  />
                </div> */}
        </>
    );
  }
}

export default ApprovalCheckList;
