import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './AdministrationLoginPage.css';
import LabelInput from '../Inputs/LabelInput';
import { STATIC_IMAGES_URL } from '../../Config/Config';
import PasswordShowHide from '../Inputs/PasswordShowHide/PasswordShowHide';

class AdministrationLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userNameErrorMsg: 'Field Required',
      userNameFlag: '',
      password: '',
      passwordErrorMsg: 'Field Required',
      passwordFlag: '',
    };
    //alert(this.props.cookies.get('admin_token_partner'))
  }

  // _handleKeyEnter Function ...................................
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('keydown', this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 13:
        this.loginHandler();
        // this.state.activePopover.hide();
        break;
      default:
        break;
    }
  };
  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyDown);
  }

  userNameHandler = (e) => {
    const reg = /[^\s]+$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ userNameFlag: false, userName: e.target.value });
    } else if (e.target.value.length == 0) {
      this.setState({ userNameFlag: false, userName: e.target.value });
    }
    // else {
    //   this.setState({
    //     userNameFlag: true,
    //     userNameErrorMsg: "Use only Alphanumeric"
    //   });
    // }
    // this.setState({ userName: e.target.value });
  };
  passwordHandler = (e) => {
    const reg =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,])[A-Z\a-z\d.\\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,]{8,}$/;
    const validReg = reg.test(e.target.value);
    // if (validReg) {
    //   this.setState({ passwordFlag: false });
    // }
    // else {
    //   this.setState({
    //     passwordFlag: true,
    //     passwordErrorMsg: "Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol"
    //   });
    // }
    this.setState({ password: e.target.value, passwordFlag: false });
  };
  loginHandler = () => {
    if (this.state.userNameFlag || this.state.userName === '') {
      this.setState({
        userNameErrorMsg:
          this.state.userNameFlag && this.state.userName.length > 0
            ? 'Use only Alphabets and Space'
            : 'Field Required',
        userNameFlag: true,
      });
    } else if (this.state.passwordFlag || this.state.password === '') {
      this.setState({
        passwordErrorMsg:
          this.state.passwordFlag && this.state.password.length > 0
            ? // ? 'Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol'
              ''
            : 'Field Required',
        passwordFlag: true,
      });
    } else {
      //alert("LoginHandler Works");
      var data = {
        userName: this.state.userName.toLowerCase(),
        password: this.state.password,
      };
      this.props.AdministrationLogin(data);
      // this.props.history.push({
      //   pathname: "/AdministrationMain"
      // });
    }
  };
  render() {
    const {
      userName,
      userNameErrorMsg,
      userNameFlag,
      password,
      passwordErrorMsg,
      passwordFlag,
    } = this.state;

    return (
      <div className='login-wrap'>
        <div className='login-img'>
          <img
            src={STATIC_IMAGES_URL + 'admin_njtransit_banner.jpg'}
            alt='NJ Transit Banner'
          />
        </div>
        <div className='alp_container'>
          <div className='login-inner-wrap'>
            <div className='row'>
              <div className='col-sm-2'></div>
              <div className='col-sm-8'>
                <div className='alp_inner_container login-screen title'>
                  <h1 className='alp_h1'>Administration Log In Screen</h1>
                </div>
              </div>
              <div className='col-sm-2'></div>
            </div>

            {/* <h1 className="alp_h1">
         Administration Log In Screen
        </h1> */}

            <div className='row'>
              <div className='col-sm-12'>
                <div className='alp_inner_container login-screen'>
                  <LabelInput
                    type='text'
                    label='User Name:'
                    name='userName'
                    id="userName"
                    inputValue={userName}
                    errorMsg={userNameErrorMsg}
                    flag={userNameFlag}
                    onChange={this.userNameHandler}
                  />
                  <PasswordShowHide
                    type='password'
                    label='Password:'
                    name='password'
                    id="password"
                    inputValue={password}
                    errorMsg={passwordErrorMsg}
                    flag={passwordFlag}
                    onChange={this.passwordHandler}
                    containerStyle={{ marginTop: '20px' }}
                  />

                  <div className='alp_btn_container'>
                    <input
                      type='button'
                      id="loginSubmit"
                      value='Login'
                      className='alp_btn1'
                      onClick={this.loginHandler}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="alp_inner_container login-screen">
          <LabelInput
            type="text"
            label="User Name:"
            name="userName"
            inputValue={userName}
            errorMsg={userNameErrorMsg}
            flag={userNameFlag}
            onChange={this.userNameHandler}
          />
          <PasswordShowHide
            type="password"
            label="Password:"
            name="password"
            inputValue={password}
            errorMsg={passwordErrorMsg}
            flag={passwordFlag}
            onChange={this.passwordHandler}
            containerStyle={{ marginTop: "20px" }}
          />

          <div className="alp_btn_container">
            <input type="button" value="Login" className="alp_btn1" onClick={this.loginHandler}/>
           
          </div>
        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdministrationLogin);
