//******************Exchange Rate Server Calls******************;

import { CreateApplicantProfileActions } from "./CreateApplicantProfileConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const CreateApplicantProfileServer = {
  CreateApplicantProfile: CreateApplicantProfile
};

//******************Get CreateApplicantProfiles******************;

function CreateApplicantProfile(data, Application) {
  console.log("Application:", Application)
  const res = fetch(ROOT_URL + "/api/business/CreateRetailerUser", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("CreateApplicantProfile:", response);
          
          if(response.status == "200")
          {

            store.dispatch({
            type: CreateApplicantProfileActions.CreateApplicantProfile_Post.SUCCESS,
            payload: {...Application,application_user_id:response.data.application_user_id}
          })
        }
        else
        store.dispatch({
          type: CreateApplicantProfileActions.CreateApplicantProfile_Post.FAILED
        });
        })
        .catch(err => {
          console.log("CreateApplicantProfileError:", err);
          store.dispatch({
            type: CreateApplicantProfileActions.CreateApplicantProfile_Post.FAILED
          });
        });
    })
    .catch(err => {
      console.log("CreateApplicantProfileError:", err);
      store.dispatch({
        type: CreateApplicantProfileActions.CreateApplicantProfile_Post.FAILED
      });
    });

  return { type: CreateApplicantProfileActions.CreateApplicantProfile_Post.LOADING };
}
