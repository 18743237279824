
//******************Exchange Rate Server Calls******************;

import { GetReferralInvoicesFlagActions } from './GetReferralInvoicesFlagConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const GetReferralInvoicesFlagServer = {
  GetReferralInvoicesFlag: GetReferralInvoicesFlag,
  GetCouponsbyFaceValues:GetCouponsbyFaceValues
  //  updateGetReferralInvoicesFlag:updateGetReferralInvoicesFlag
};

//****************** GetReferralInvoicesFlag with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function GetReferralInvoicesFlag(setParentState) {

  const request = fetch(ROOT_URL + '/api/ref/getReferralInvoicesFlag', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        setParentState(response.data)
  //****************** responce.data contains values of business Quotas along with business crontact
  //****************** responce.CouponFaceValues contains count of generated coupon based on business_id and year_id
        store.dispatch({ type: GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.SUCCESS, payload: response.data, QuotaYearsPayload:response.QuotaYears});
        return;
      } else {
        store.dispatch({ type: GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.FAILED,error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.FAILED,error:failure_messages.quotasDetailsError });
    })
  }).catch((error) => {
    store.dispatch({ type: GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.FAILED,error:failure_messages.quotasDetailsError });
  })
  return { type: GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.LOADING }

};

function GetCouponsbyFaceValues(data, token){

  const request = fetch(ROOT_URL + '/api/coupon/GetReferralInvoicesFlag', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log('coupons details', response.data)

        return;
      } else {
        
        return;
      }
    }).catch((error) => {
      
    })
  }).catch((error) => {
    
  })
  
}
