import React, { Component, Fragment } from "react";
//import './PausePlayCoupon.css'
import { PausePlayCouponStatus } from "./PausePlayCouponConstants";
import { withCookies } from "react-cookie";
import Loading from "../../Utils/Loading";
// import Snackbar from "../../Utils/Snackbar";
import {ref_status} from "RefRecordsConstants"
import PausePlayCoupon from "./PausePlayCoupon";


export class PausePlayCouponView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      loading: false,
      retailer_details: props.cookies.get("BusinessProfileData"),
    };
    // this.props.resetReducerState();
  }
  // closeSnackBar = () => {
  //   this.setState({
  //     open: false,
  //   });
  // };
  componentWillUnmount() {
    this.props.resetReducerState();
  }

  pausePlay = () => {
    // alert(this.props.coupon.status_id)
    var status_id = this.props.coupon.status_id;
    // var ref_status = this.props.ref_data.ref_status;
// alert(JSON.stringify(this.props.ref_data.BatchStatus[11]))
    if (status_id == ref_status.PAUSED) 
    status_id = ref_status.ACTIVE;
    else status_id = ref_status.PAUSED;

    var BatchStatus = this.props.ref_data.BatchStatus.find(
      (item) => item.status_id === status_id
    );
    if (status_id == ref_status.ACTIVE) 
    if(new Date(this.props.coupon.promotion_end_date) < new Date())
    {BatchStatus.to_status_code= this.props.ref_data.BatchStatus[11].to_status_code
      BatchStatus.display_color =  this.props.ref_data.BatchStatus[11].display_color
    }
// alert(JSON.stringify(BatchStatus.to_status_code))
    var data = {
      to_status_code: BatchStatus.to_status_code,
      coupon_batch_id: this.props.coupon.coupon_batch_id,
      batch_status: status_id,
      display_color:BatchStatus.display_color,
      BussinessId: this.state.retailer_details.business_id,
    };
    // alert(JSON.stringify(data))
    // this.state.loading = true;
    this.props.handlePausePlayCoupon(data);
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.redeem) {
  //     // alert(JSON.stringify(nextProps.coupon_code))
  //     nextProps.setRedeem(false);
  //     var data = {
  //       redeemed_datetime: new Date(),
  //       coupon_code: nextProps.coupon_code,
  //       BussinessId : prevState.retailer_details.business_id,
  //       email: prevState.retailer_details.email
  //     };
  //     nextProps.handlePausePlayCoupon(data, prevState.retailer_details.access_token);
  //     // nextProps.LoginRetailer(nextProps.data);
  //   }
  //   return null;
  // }

  getScreen(status) {
    switch (status) {
      case PausePlayCouponStatus.PausePlayCoupon_Post.NEW:
        return (
          <PausePlayCoupon
            onClick={this.pausePlay}
            ref_data={this.props.ref_data}
            coupon={this.props.coupon}
          />
        );

      case PausePlayCouponStatus.PausePlayCoupon_Post.SUCCESS:
        if(this.state.loading)
   {    this.state.loading = false;
        var updatedCoupon = this.props.coupon
        updatedCoupon.status_id = this.props.PausePlayCoupon.batch_status
        if (updatedCoupon.status_id == ref_status.ACTIVE) 
        if(new Date(this.props.coupon.promotion_end_date) < new Date())
          updatedCoupon.to_status_code= this.props.ref_data.BatchStatus[11].to_status_code
        else
        updatedCoupon.to_status_code = this.props.PausePlayCoupon.to_status_code
       
        
        updatedCoupon.display_color = this.props.PausePlayCoupon.display_color
        // alert(updatedCoupon.to_status_code)
        this.props.updateSelectedCoupon(updatedCoupon)
   }
        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="success"
              message={this.props.PausePlayCoupon_Message}
            /> */}

            <PausePlayCoupon
              onClick={this.pausePlay}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
            />
          </Fragment>
        );
        break;
      case PausePlayCouponStatus.PausePlayCoupon_Post.FAILED:
        this.state.loading = false;

        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="error"
              message={this.props.PausePlayCoupon_Message}
            /> */}

            <PausePlayCoupon
              onClick={this.pausePlay}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
            />
          </Fragment>
        );
        break;
      case PausePlayCouponStatus.PausePlayCoupon_Post.LOADING:
        this.state.open = true;
        this.state.loading = true
        return <button className={"inv-button-pause "}>
         <Loading />
         </button>
        // if (this.state.loading) return (
        // <button className={"inv-button-pause "}>
        // <Loading />
        // </button>)
        // else
        //   return (
        //     <PausePlayCoupon
        //       onClick={this.pausePlay}
        //       ref_data={this.props.ref_data}
        //       coupon={this.props.coupon}
        //     />
        //   );

        break;
      default:
        break;
    }
  }
  render() {
    // alert(this.props.PausePlayCoupon_post_status)
    return this.getScreen(this.props.PausePlayCoupon_post_status);
  }
}

export default withCookies(PausePlayCouponView);
