import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Cropper from 'react-easy-crop'
import ImgDialog from './ImgDialog'
import getCroppedImg from './cropImage'
import './NewUploadFile.css'
import { AcceptImages, AcceptVideos } from "../../../../Config/Config";

// const minZoom = 0.4

class NewUploadFile extends React.Component {
  state = {
    imageSrc:null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
    croppedAreaPixels: null,
    croppedImage: null,
    fileFlag: false,
    videoFlag: false,
    buttonDisable:false,
    ImageErrorMsg: "",
    minZoom: 0.4,
    showCropImage:false,
    videoError:false
  }

  onCropChange = (crop) => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    this.setState({ croppedAreaPixels })
  }

  onZoomChange = (zoom) => {
    console.log("jdkjdskxjcdsx",zoom)
    this.setState({ zoom })
  }

  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels
    )
    console.log("croppedImage", croppedImage)
    // alert(buttonDisable)
        
    this.props.buttonDisableHandler(true)

    this.setState({
         croppedImage,
         showCropImage:true
         })
  }

  handleClose = () => {
    this.setState({ croppedImage: null })
  }
  showResetCropImage= () => {
    this.setState({
        showCropImage:false
        })
  }

  onFileChange = async (e) => {
    const maxAllowedHeight=228.0
    const maxAllowedWidth=339.0

    if (e.target.files && e.target.files.length > 0) {
      console.log("img data", e.target.files);
      var re = /(?:\.([^.]+))?$/;
      let ext = "." + re.exec(e.target.files[0].name)[1];
      if (ext) {
        let videoFlag = false;
        let buttonDisable = this.state.buttonDisable;
        let fileFlag = false;
        let videoError = false
        // let buttonDisable = this.state.buttonDisable
        // if(  ImageSizeLimit={this.props.ImageSizeLimit}
        // VideoSizeLimit = {this.props.VideoSizeLimit})
        // alert(ext)
        // alert(JSON.stringify(AcceptVideos.split(", ")))
        if (AcceptVideos.split(", ").includes(ext)) {
          buttonDisable = true;
          videoFlag = true;
          if (
            e.target.files[0].size / 1024 / 1024 >
            this.props.VideoSizeLimit
          ) {
            // alert("video limit")
            this.state.ImageErrorMsg =
              "Video size should be less than " +
              this.props.VideoSizeLimit +
              " MB";
            buttonDisable = false;
            fileFlag = true;
            videoError= true
            // videoFlag = false;
            
            this.setState({ imageSrc: null, videoError:videoError, fileFlag:false });
          }
        //   alert(this.state.imageSrc)
            
        } else {
          if (
            e.target.files[0].size / 1024 / 1024 >
            this.props.ImageSizeLimit
          ) {
            this.state.ImageErrorMsg =
              "Image size should be less than " +
              this.props.ImageSizeLimit +
              " MB";
            fileFlag = true;
            buttonDisable = false;
          }

          var reader = new FileReader();
          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = (e)=> {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e.target.result;
            //Validate the File Height and Width.
            image.onload = ()=> {
                let widthPropottion
                let heightPropottion
              var width = image.naturalWidth;
              var height = image.naturalHeight;
              console.log('imgWidthdkdkk: ', width);
              console.log('imgHeight: ', height);

              if (width < maxAllowedWidth || height < maxAllowedHeight) {
                // alert("Height and Width must not exceed 228px.");

            this.state.ImageErrorMsg ="Width and height must not be less than  "+maxAllowedWidth+ " by " + maxAllowedHeight+".";

            buttonDisable = false

            this.setState({
              fileFlag: true,
              buttonDisable: buttonDisable,
              videoError:videoError,
              imageSrc:null
            },()=>{

            })
              }
              else{
                // widthPropottion= width/maxAllowedWidth
                // heightPropottion= height/maxAllowedHeight


                widthPropottion= maxAllowedWidth/width
                heightPropottion= maxAllowedHeight/height

                let minScaleValue=Math.max(widthPropottion, heightPropottion)
                console.log('heightPropottion: ', widthPropottion);
              console.log('heightPropottion: ', heightPropottion);
              console.log("salevalue here", minScaleValue)

                this.setState({
                    minZoom: minScaleValue
                })

                // buttonDisable= true
              }
            };
          };
        }
        // alert(videoFlag)
        // alert(e.target.files[0])

        this.setState({
            
          imageData: e.target.files[0],
          editMode: true,
          fileFlag: fileFlag,
          videoFlag: videoFlag,
          buttonDisable: buttonDisable,
          src: undefined,
        });
        if (!videoFlag) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            // alert(reader)
            // console.log("aksjndajksd: ",e.target.files )
            this.setState({ imageSrc: reader.result });
          });
          reader.readAsDataURL(e.target.files[0]);
        }else
        this.setState({ imageSrc: null, videoError:videoError, fileFlag:true });
      }
    }
  }

  render() {
    const { classes } = this.props

    const {minZoom,fileFlag,showCropImage, imageSrc} = this.state
    // alert(fileFlag)

    console.log("video", this.state.imageData)
    console.log("video", this.state.videoFlag)
    // alert(imageSrc)

    return (
      <div>
        <div className='upload_file'>
        <div className="reeoe-handle box-upload">
                  <div
                    style={{
                      display: this.state.showFileUpload ? "none" : "block",
                    }}
                    className="pe-file-container-upload"
                  >
                    <input
                      type="file"
                      id="pe-inp-btn1"
                      className="pe-inp-btn-upload"
                      onChange={this.onFileChange}
                      accept={AcceptImages + ", " + AcceptVideos}
                      flag={fileFlag ? fileFlag : undefined}
                    />
                    <label
                      htmlFor="pe-inp-btn1"
                      className="pe-upload-btn-upload pe-upload-btn"
                    >
                      <i className="material-icons">photo_camera</i>
                      <div className="banner-up camera-upload-hover">
                        Upload Image
                      </div>
                    </label>
                  </div>
                </div>
                <div className="upload-instruction">
                  <p className="upload-instruction-text">
                    Note: When the video is displayed it will be resized to fit
                    in a box 228 pixels in height and 339 pixels in width. So as
                    not to distort the video, the video is resized
                    proportionally to have a height of 228 pixels. The maximum
                    image upload size is {this.props.ImageSizeLimit}MB and video
                    upload size {this.props.VideoSizeLimit}MB.
                  </p>
                </div>
        </div>
         {this.state.imageSrc ? (
        <Fragment>
             {fileFlag ? (
                <></>
              ) : (
        <div style={{display:showCropImage?"none":"block"}}>
        <div className='box_image'>
        <div className={classes.cropContainer}>
          <Cropper
            maxZoom={1}
            minZoom={minZoom}
            image={this.state.imageSrc}
            crop={this.state.crop}
            zoom={this.state.zoom}
            // aspect={this.state.aspect}
            cropSize={{width: 339, height: 228}}
            restrictPosition={true}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
        </div>
        </div>
        <div className="controls_slider">
            <div className="slider_contrl">
          <Slider
            value={this.state.zoom}
            min={minZoom}
            max={1}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => this.onZoomChange(zoom)}
            classes={{ container: 'slider' }}
          />
          </div>
        </div>
        <div className='button_new_crop'>
        <button
            onClick={this.showCroppedImage}
            variant="contained"
            color="primary"
            className="upload-button"
          >
            <span className="material-icons">crop</span> Crop
                            Image
          </button>
        </div>
        </div>
              )}
        <div   
        style={{display:showCropImage?"block":"none"}}
        >
        <ImgDialog img={this.state.croppedImage} onClose={this.handleClose} />
        <div className='reset_image'>
        <button
            onClick={this.showResetCropImage}
            variant="contained"
            color="primary"
            className="again-crop-image"
          >
            <span className="material-icons">restore</span> Reset Cropped Image
          </button>
        </div>
        </div>
        </Fragment>
        
        ) : ( 
            <></>
           )}
           <div className='video_tag_box'>
             {this.state.videoFlag && !this.state.videoError && (
                      <div className="croping-video-offer">
                        <video controls>
                          <source
                            src={URL.createObjectURL(this.state.imageData)}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    )}
            </div>
            {fileFlag ? (
                  <p className="error-upload">{this.state.ImageErrorMsg}</p>
                ) : (
                  <></>
                )}
      </div>
    )
  }
}

const styles = {
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
}

// const StyledApp = withStyles(styles)(App)

// const rootElement = document.getElementById('root')
// ReactDOM.render(<StyledApp />, rootElement)


export default withStyles(styles)(NewUploadFile)