
//******************Exchange Rate Server Calls******************;

import { GetDisplayTypesActions } from './GetDisplayTypesConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const GetDisplayTypesServer = {
  //  GetDisplayTypes:GetDisplayTypes,
  GetDisplayTypes: GetDisplayTypes,
  GetCouponsbyFaceValues:GetCouponsbyFaceValues
  //  updateGetDisplayTypes:updateGetDisplayTypes
};

//****************** GetDisplayTypes with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function GetDisplayTypes(setParentState) {

  const request = fetch(ROOT_URL + '/api/business/getDisplayTypes', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        setParentState(response.data)
  //****************** responce.data contains values of business Quotas along with business crontact
  //****************** responce.CouponFaceValues contains count of generated coupon based on business_id and year_id
        store.dispatch({ type: GetDisplayTypesActions.GetDisplayTypes_GET.SUCCESS, payload: response.data, QuotaYearsPayload:response.QuotaYears});
        return;
      } else {
        store.dispatch({ type: GetDisplayTypesActions.GetDisplayTypes_GET.FAILED,error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: GetDisplayTypesActions.GetDisplayTypes_GET.FAILED,error:failure_messages.quotasDetailsError });
    })
  }).catch((error) => {
    store.dispatch({ type: GetDisplayTypesActions.GetDisplayTypes_GET.FAILED,error:failure_messages.quotasDetailsError });
  })
  return { type: GetDisplayTypesActions.GetDisplayTypes_GET.LOADING }

};

function GetCouponsbyFaceValues(data, token){

  const request = fetch(ROOT_URL + '/api/coupon/GetDisplayTypes', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log('coupons details', response.data)

        return;
      } else {
        
        return;
      }
    }).catch((error) => {
      
    })
  }).catch((error) => {
    
  })
  
}

//******************Get GetDisplayTypess******************;


//******************Update GetDisplayTypes******************;


// function GetDisplayTypes(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.GetDisplayTypes.length>0)
//              store.dispatch({type:GetDisplayTypesActions.GetDisplayTypes_Get.SUCCESS,payload:response.GetDisplayTypes});
//              else
//              store.dispatch({type:GetDisplayTypesActions.GetDisplayTypes_GET.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:GetDisplayTypesActions.GetDisplayTypes_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:GetDisplayTypesActions.GetDisplayTypes_Get.FAILED});
//           })
//        return {type:GetDisplayTypesActions.GetDisplayTypes_GET.LOADING}

//     };

// function updateGetDisplayTypes(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:GetDisplayTypesActions.GetDisplayTypes_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:GetDisplayTypesActions.GetDisplayTypes_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:GetDisplayTypesActions.GetDisplayTypes_GET.FAILED});
//     })
// return {type:GetDisplayTypesActions.GetDisplayTypes_GET.LOADING}

// };
