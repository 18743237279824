import React, { Component } from "react";
import "./BusinessContract.css";
import {  withRouter } from "react-router-dom";
import LabelInput from "../Inputs/LabelInput";
// import LabelDropDownInput from "../Inputs/LabelDropDownInput";
import LabelDatePicker from "../Inputs/LabelDatePicker";
import LabelTextAreaInput from "../Inputs/LabelTextAreaInput";
// import SimpleInput from "../Inputs/SimpleInput";
import InputRadio from "../Inputs/InputRadio/InputRadio";
import Snackbar from '@material-ui/core/Snackbar/';
import InputDollar from "../Inputs/InputDollar/InputDollar";
import PercentageInputSign from "../Inputs/PercentageInputSign/PercentageInputSign";
// import dateFormat from "dateformat";
// import moment from "moment"
import CheckBoxSingle from '../Inputs/CheckBoxSingle/CheckBoxSingle'
import SingleInputRadio from "../Inputs/InputRadio/SingleInputRadio";
import GetDisplayTypesContainer from "./GetDisplayTypes/GetDisplayTypesContainer";
import GETCOUPON_TYPESCONTAINER from "./GetCoupon_Types/GetCoupon_TypesContainer"
import Axios from 'axios'


import { ROOT_URL, getCookie } from "../../Config/Config";


import Loading from '../Utils/Loading';
import GetReferralInvoicesFlagContainer from "./GetReferralInvoicesFlag/GetReferralInvoicesFlagContainer";

// const CouponTypeData = [
//   { value: "selectType", name: "Select Type" },
//   { value: "regular", name: "Regular" },

// ];
class BusinessContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: props.BusinessProfileData.business_name,
      businessNameErrorMsg: "Field Required",
      businessNameFlag: false,
      annualProgFee: "",
      annualProgFeeErrorMsg: "Field Required",
      annualProgFeeFlag: false,
      monthlyProgFee: "",
      monthlyProgFeeErrorMsg: "Field Required",
      monthlyProgFeeFlag: false,
      startDate: "",
      startDateErrorMsg: "Field Required",
      startDateFlag: false,
      endDate: "",
      endDateErrorMsg: "Field Required",
      endDateFlag: "",
      CouponType: "",
      CouponTypeErrorMsg: "Field Required",
      CouponTypeFlag: false,
      notes: "",
      notesErrorMsg: "",
      notesFlag: "",
      fixedValue: "",
      fixedValueErrorMsg: "Field Required",
      fixedValueFlag: false,
      percentValue: "",
      percentValueErrorMsg: "Field Required",
      percentValueFlag: false,
      radioCheck: false,
      monthlyCheck: false,
      open: false,
      snackBarMsg:'',
      GOTO_QUOTAS:false,
      override_default_coupon_behavior : false,
      sendServer: true,
      showOfferCode: true,
      showQRCode: false,
      showRedeemButton: false,
      couponBehaviour: true,
      newRadioCheck: true,
      newRadioCheck2: false,
      system_managed_coupons:false,
      displayTypes:[],
      minimumDate:"",
      displayId:0,
      displayCouponBehaviourErrorMsg:"Atleast one checkbox must be selected.",
      displayCouponBehaviourFlag:false,
      populateDefaultOfferCodeSection: false,
      default_coupon_display_behavior:'',
      showReferralFeeSection: true
    };
  }
  componentDidMount() {
    // alert("")
    window.scrollTo(0, 0);
  }

  manageCouponsRadioHandler = () => {
    this.setState({
      newRadioCheck: true,
      newRadioCheck2: false,
      system_managed_coupons:false
    })
  }
  rewardSystemRadioHandler = () => {
    this.setState({
      newRadioCheck: false,
      newRadioCheck2: true,
      system_managed_coupons: true 
    })
  }
  coupnCodeRadioHandler = () => {
    alert("coupnCodeRadioHandler work")
  }
  QRCodeRadioHandler = () => {
    alert("QRCodeRadioHandler work")
  }
  setServer = () => {
    // alert(this.state.sendServer)
    this.setState({
      sendServer: !this.state.sendServer
    });
  };
  setOfferCode = () => {
    this.setState({
      showOfferCode: !this.state.showOfferCode
    }, () => {

      if (this.state.showOfferCode)
        this.setState({
          displayCouponBehaviourFlag: false
        })

    });
  };


  setQRCode = () => {
    this.setState({
      showQRCode: !this.state.showQRCode
    },()=>{

      if (this.state.showQRCode)
      this.setState({
        displayCouponBehaviourFlag: false
      })

    });
  };


  setRedeemButton = () => {
    this.setState({
      showRedeemButton: !this.state.showRedeemButton
    },()=>{

      
      if (this.state.showRedeemButton)
      this.setState({
        displayCouponBehaviourFlag: false
      })

    });
  };

  handleReferralFeeSection =(showReferralFeeSection)=>{

    try {


      console.log("showReferralFeeSection", showReferralFeeSection)
      const { enable_referral_invoices_flag } = showReferralFeeSection

      this.setState({
        showReferralFeeSection: enable_referral_invoices_flag
      })

    } catch (error) {

      console.log("error", error)

    }
    
  }


  setBehaviour = () => {
    this.setState({
      override_default_coupon_behavior: !this.state.override_default_coupon_behavior
    });
  }
  setMonthly = (check) => {

    this.setState({
      monthlyCheck: check,

    })
  }
  setDisplayTypes = (data) =>{

    console.log("these are display types", data)

    // if(this.state.populateDefaultOfferCodeSection)
    //   this.setDefaultValuesDisplayOfferCodes(data)

    if(data && data.length > 0)
    {
      if(this.state.displayId == 0)
      this.setState({
      displayTypes:data,
      displayId:data[0].value
    })
    else
    this.setState({
      displayTypes:data
    })
  }
  }


  setDefaultValuesDisplayOfferCodes = (data)=>{


    
    // console.log("display types", this.state.displayTypes)
    
    const { default_coupon_display_behavior } = this.state
    
    
    
    
    const couponDisplayBehavior = data.filter((displayType) => {
      
      
      return displayType.value === default_coupon_display_behavior
    })
    
    console.log("coupon display behaviour", default_coupon_display_behavior)

    console.log("coupon display behaviour", data)
    console.log("coupon display behaviour", couponDisplayBehavior)
    
    
    // const {showOfferCode, showQRCode, showRedeemButton} = this.state
    // let couponDisplayBehaviourEncodedString = couponDisplayBehavior[0].name
    

    let couponDisplayBehaviourEncodedString = couponDisplayBehavior[0].name.split("")

   
   this.setState({
     populateDefaultOfferCodeSection: false,
     showOfferCode : !!parseInt(couponDisplayBehaviourEncodedString[0] ),
     showQRCode : !!parseInt(couponDisplayBehaviourEncodedString[1]),
     showRedeemButton : !!parseInt(couponDisplayBehaviourEncodedString[2])
    })
    
    

  }



  setDefaultCouponBehavior = (check) => {

    this.setState({
      override_default_coupon_behavior: check,

    })
  }

  componentDidUpdate(prevProps, prevState) {

    // console.log("sdsdsdsd", prevState.populateDefaultOfferCodeSection)
    
    console.log("sdsdsdsdjasjd", prevState.default_coupon_display_behavior)
    console.log("sdsdsdsdjasjd", this.state.default_coupon_display_behavior)
    
    
    console.log("sdsdsdsd", this.state.populateDefaultOfferCodeSection)


    
    
    if ( 
      // this.state.default_coupon_display_behavior
      this.state.displayTypes.length > 1 
      && this.state.default_coupon_display_behavior !== "" && this.state.populateDefaultOfferCodeSection
      ) {
        
        console.log("state sadjjasdjksa", this.state.default_coupon_display_behavior)
        console.log("state sadjjasdjksa", this.state.displayTypes)


        // if(this.state.default_coupon_display_behavior !== ""){
          
          console.log('display typesdsdsds', this.state.displayTypes)
          this.setDefaultValuesDisplayOfferCodes(this.state.displayTypes)
        // }

    }
  }



  // todo: Annual Programe Fee onChange Function ...................
  UNSAFE_componentWillMount() {
    
    // alert(this.props.BusinessContract)

    
    if (this.props.BusinessContract) {
      // alert(JSON.stringify(this.props.BusinessContract.override_default_coupon_behavior))


      console.log("this is business contract data", this.props.BusinessContract)

      this.state.override_default_coupon_behavior = this.props.BusinessContract.override_default_coupon_behavior
      this.state.annualProgFee = this.props.BusinessContract.annual_program_fee;
      this.state.monthlyProgFee = this.props.BusinessContract.monthly_program_fee;
    
      if(this.state.monthlyProgFee)
      {
        this.setMonthly(false)
        this.state.annualProgFee = ""
      }
      else
      {
        this.setMonthly(true)
        this.state.monthlyProgFee = ""
      }
      if(this.props.BusinessContract.coupon_display_behavior_id > 0){

        // this.state.displayId = this.props.BusinessContract.coupon_display_behavior_id
        console.log("display", this.props.BusinessContract.coupon_display_behavior)
        
        console.log("split value", this.props.BusinessContract.coupon_display_behavior.split(""))

        const couponDisplayBehaviourEncodedString = this.props.BusinessContract.coupon_display_behavior.split("")

        this.state.showOfferCode = !!parseInt(couponDisplayBehaviourEncodedString[0] )
        this.state.showQRCode = !!parseInt(couponDisplayBehaviourEncodedString[1])
        this.state.showRedeemButton = !!parseInt(couponDisplayBehaviourEncodedString[2])

        
        // this.state.(showOfferCode ? "1":"0") + (showQRCode ? "1":"0") + (showRedeemButton ? "1":"0")

      }
      this.state.system_managed_coupons = this.props.BusinessContract.system_managed_coupons
      if(this.state.system_managed_coupons)
     {
       
        this.state.newRadioCheck = false
        this.state.newRadioCheck2 =true
     }
     
        this.state.sendServer = this.props.BusinessContract.generate_coupon_redemption_file
      
      
      this.state.startDate = new Date( this.props.BusinessContract.term_start_date)
      // new Date(
      //   this.props.BusinessContract.term_start_date
      // );
      // this.state.startDate=new Date( moment(this.state.startDate))
      this.state.endDate = new Date( this.props.BusinessContract.term_end_date)
      // new Date(this.props.BusinessContract.term_end_date);
      //alert(JSON.stringify(this.props.BusinessContract))
      this.state.CouponType = this.props.BusinessContract.coupon_type_name;
      // this.state.CouponType = this.props.BusinessContract.coupon_type_id;
      this.state.notes = this.props.BusinessContract.notes;

      if (!this.props.BusinessContract.referral_fee_type_fixed) {
        this.state.radioCheck = true;
        this.state.percentValue = this.props.BusinessContract.referral_fee_percent;
      } else
        this.state.fixedValue = this.props.BusinessContract.referral_fee_fixed;
    }


    else{

      this.getDefaultContractData()
    }


  }



  getDefaultContractData = ()=>{


    const api_route = 'api/business/getDefaultContractConfigurations'
    const URL = `${ROOT_URL}/${api_route}`
    

    Axios.request({
      method: "POST",
      url: URL,
      headers: { 
        'authorization':getCookie("admin_token_partner"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(async (response) => {
        if(response.status === 200){

          console.log("get default configuration data", response.data)
          // Set Default Values

        
          let {
            coupon_type_id,
            default_contract_year,
            default_coupon_display_behavior,
            referral_fee_type,
            referral_fee_percent,
            referral_fee_fixed,
            program_fee_type,
            annual_program_fee,
            monthly_program_fee,
          } = response.data.data



          // Monthly Program Fee Logic
          if(program_fee_type.toLowerCase().replace(/\s/g, "") === "monthly"){

            if(!monthly_program_fee.includes('\.') && monthly_program_fee.length == 7){
              monthly_program_fee = monthly_program_fee+'.'
            }
            
            this.setState({
              monthlyCheck: false,
              monthlyProgFee: monthly_program_fee,
            })
          }
          else{

            if(!annual_program_fee.includes('\.') && annual_program_fee.length == 7){
              annual_program_fee = annual_program_fee+'.'
            }
            
            this.setState({
              monthlyCheck: true,
              annualProgFee: annual_program_fee,
            })

          }


          const currentDate = new Date()
          let defaultContractEndDate = new Date(new Date().setFullYear(new Date().getFullYear() + parseInt(default_contract_year)))


          console.log("defaultContractEndDate", defaultContractEndDate)


          if(referral_fee_type.toLowerCase().replace(/\s/g, "") === "percent"){
            
            this.radioCheckHandler()
            this.percentValueHandler({'target':{'value':referral_fee_percent}})


          }
          else{

            // this.radioCheckHandler()
            this.fixedValueHandler({'target':{'value':referral_fee_fixed}})


          }

          // radioCheck

          // const couponDisplayBehaviourEncodedString = default_coupon_display_behavior.split("")

          console.log("default_coupon_display_behavior", default_coupon_display_behavior)

          // this.state.default_coupon_display_behavior = parseInt(default_coupon_display_behavior)

       
          this.setState({
            startDate: currentDate,
            endDate: defaultContractEndDate,
            CouponType: coupon_type_id,
            populateDefaultOfferCodeSection: true,
            default_coupon_display_behavior: parseInt(default_coupon_display_behavior)
          })



        }
        
      })
      .catch((error) => {
        console.log("allow registartion error", error);
      });


  }





  annualProgFeeHandler = e => {

    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ annualProgFeeFlag: false, monthlyProgFeeFlag: false });
    } else {
      this.setState({
        annualProgFeeFlag: true,
        annualProgFeeErrorMsg: "Use only Numeric"
      });
    }
    var val =e.target.value
    if(!val.includes('\.') && val.length == 7){
      if( this.state.annualProgFee.length != 8)
      val = val+'.'
    }

    // const regex = /^[0]+\.?[0]*$/;

    // const RegexValue = regex.test(e.target.value);
    //  if(RegexValue){
    //    this.setState({
    //     annualProgFeeFlag: true,
    //     annualProgFeeErrorMsg: "Zero value not allow"
    //    });
    //  }


    this.setState({ annualProgFee: val, monthlyProgFee: null });


    // const reg = /^[0-9]*$/;
    // const validReg = reg.test(e.target.value);
    // if (validReg) {
    //   this.setState({ annualProgFeeFlag: false });
    // } else {
    //   this.setState({
    //     annualProgFeeFlag: true,
    //     annualProgFeeErrorMsg: "Use only Numeric"
    //   });
    // }
    // this.setState({ annualProgFee: e.target.value, monthlyProgFee: 0 });
  };
  // todo: Monthly Programe Fee onChange Function ...................

  monthlyProgFeeHandler = e => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ monthlyProgFeeFlag: false, annualProgFeeFlag: false });
    } else {
      this.setState({
        monthlyProgFeeFlag: true,
        monthlyProgFeeErrorMsg: "Use only Numeric"
      });
    }
    var val =e.target.value
    if(!val.includes('\.') && val.length == 7){
      if( this.state.monthlyProgFee.length != 8)
      val = val+'.'
    }
    
    // const regex = /^[0]+\.?[0]*$/;

    // const RegexValue = regex.test(e.target.value);
    //  if(RegexValue){
    //    this.setState({
    //     monthlyProgFeeFlag: true,
    //     monthlyProgFeeErrorMsg: "Zero value not allow"
    //    });
    //  }


    this.setState({ monthlyProgFee: val, annualProgFee: null });
  };
  // todo: Start Date onChange Function ...................

  startDateHandler = e => {

    this.setState({ startDate: e, startDateFlag: false, endDate: "" });
  };

  // todo: End Date onChange Function ...................

  endDateHandler = e => {
    this.setState({ endDate: e, endDateFlag: false });
  };

  // todo: Coupon Type onChange Function ...................

  CouponTypeHandler = e => {
    if (
      this.state.CouponType !== "" ||
      this.state.CouponType !== "selectType"
    ) {
      this.setState({ CouponTypeFlag: false });
    }
    this.setState({ CouponType: e.target.value });
  };
  // todo: Notes onChange Function ...................

  notesHandler = e => {
    if (this.state.notes !== "") {
      this.setState({ notesFlag: false });
    }
    this.setState({ notes: e.target.value });
  };

  // todo: radioCheckHandler onChange function ..........................

  radioCheckHandler = () => {
    this.setState({ radioCheck: !this.state.radioCheck }, () => {
      this.setState(
        this.state.radioCheck
          ? { fixedValue: "", fixedValueFlag: false }
          : { percentValue: "", percentValueFlag: false }
      );
    });
  };

  handleSnackBar = (msg) =>{
    this.setState({
      open: true,
      snackBarMsg:msg
    })
  }

  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }
  // todo: fixedValueHandler onChange function ..........................

  fixedValueHandler = e => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ fixedValueFlag: false });
    } else {
      this.setState({
        fixedValueFlag: true,
        fixedValueErrorMsg: "Use only Numeric"
      });
    }
    var val =e.target.value
    if(!val.includes('\.') && val.length == 7){
      if( this.state.fixedValue.length != 8)
      val = val+'.'
    }
    // const regex = /^[0]+\.?[0]*$/;

    // const RegexValue = regex.test(e.target.value);
    //  if(RegexValue){
    //    this.setState({
    //     fixedValueFlag: true,
    //     fixedValueErrorMsg: "Zero value not allow"
    //    });
    //  }


    this.setState({ fixedValue: val });
  };

  // todo: percentValueHandler onChange function ..........................

  percentValueHandler = e => {
    const percent = e.target.value;
    const reg = /^((100)|(\d{1,2}(\.\d*)?))$/;
    const validReg = reg.test(percent);
    if (validReg) {
      this.setState({ percentValueFlag: false });
    } else {
      if(percent.length > 0)
      this.setState({
        percentValueFlag: true,
        percentValueErrorMsg:
          "Please enter a Numeric Percentage value between 0 - 100"
      });
      else
      this.setState({ percentValueFlag: false });
    }

    // const regex = /^[0]+\.?[0]*$/;

    // const RegexValue = regex.test(e.target.value);
    //  if(RegexValue){
    //    this.setState({
    //     percentValueFlag: true,
    //     percentValueErrorMsg: "Zero value not allow"
    //    });
    //  }


    this.setState({ percentValue: percent });
  };

  // todo: homeHandler onClick function ..........................

  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin"
    });
  };
  // todo: backHandler onClick Function ...................
  backHandler = () => {
    // alert("backHandler work")
    this.props.history.push({
      pathname: "/business_profile"
    });
  };
  // todo: nextHandler onClick Function ...................
  nextHandler = () => {
    alert("nextHandler work")
  };
  // todo: SaveHandler onClick Function ...................

  saveHandler = () => {
    // this.state.annualProgFee === "" ?'as':'asd'

    if (this.state.annualProgFeeFlag || this.state.annualProgFee === "" && this.state.monthlyCheck) {
      this.setState({
        annualProgFeeErrorMsg:
          this.state.annualProgFeeFlag && this.state.annualProgFee.length > 0
            ? this.state.annualProgFeeErrorMsg
            : "Field Required",
        annualProgFeeFlag: true
      });

      this.scrollToProgramFee()
    } else if (
      this.state.monthlyProgFeeFlag ||
      this.state.monthlyProgFee === "" && !this.state.monthlyCheck
    ) {
      this.setState({
        monthlyProgFeeErrorMsg:
          this.state.monthlyProgFeeFlag && this.state.monthlyProgFee.length > 0
            ? this.state.monthlyProgFeeErrorMsg
            : "Field Required",
        monthlyProgFeeFlag: true
      });
      this.scrollToProgramFee()
    } else if (this.state.startDate === "") {
      this.setState({
        startDateErrorMsg: "Field Required",
        startDateFlag: true
      });
      this.scrollToTermStartDate()
    } else if (this.state.endDate === "") {
      this.setState({
        endDateErrorMsg: "Field Required",
        endDateFlag: true
      });
      this.scrollToTermEndDate()
    } else if (
      this.state.CouponType === "selectType" ||
      this.state.CouponType === ""
    ) {
      this.setState({
        CouponTypeErrorMsg: "Field Required",
        CouponTypeFlag: true
      });
      this.scrollToCouponType()
    }
    // else if (this.state.notes === "") {
    //   this.setState({
    //     notesErrorMsg: "Field Required",
    //     notesFlag: true
    //   });
    // }
    else if (
      ( !this.state.showOfferCode && !this.state.showQRCode && !this.state.showRedeemButton ) 
    ) {
      this.setState({
        displayCouponBehaviourFlag: true
      });
      // this.scrollToCouponType()
    }

    else {
      var flag = true;
      var fee_type_percent = false;
      var fee_type_fixed = false;
      if (this.state.radioCheck) {
        if (this.state.percentValue === "" || this.state.percentValueFlag) {
          this.setState({
            percentValueErrorMsg:
              this.state.percentValueFlag && this.state.percentValue.length > 0
                ? this.state.percentValueErrorMsg
                : "Field Required",
            percentValueFlag: true
          });
          this.scrollToReferralFees()
          flag = false;
          
        }
        this.state.fixedValue = 0;
        fee_type_percent = true;
      } else {
        if (this.state.fixedValue === "" || this.state.fixedValueFlag) {
          this.setState({
            fixedValueErrorMsg:
              this.state.fixedValueFlag && this.state.fixedValue.length > 0
                ? this.state.fixedValueErrorMsg
                : "Field Required",
            fixedValueFlag: true
          });
          this.scrollToReferralFees()
          flag = false;
        }
        this.state.percentValue = 0;
        fee_type_fixed = true;
      }
      if (flag) {

        const {showOfferCode, showQRCode, showRedeemButton} = this.state

        let couponDisplayBehaviourEncodedString = (showOfferCode ? "1":"0") + (showQRCode ? "1":"0") + (showRedeemButton ? "1":"0")
         
        // alert(couponDisplayBehaviourEncodedString)

        console.log("encoded", couponDisplayBehaviourEncodedString)

        const couponDisplayBehavior = this.state.displayTypes.filter((displayType)=>{
            return displayType.name === couponDisplayBehaviourEncodedString
        })
        
        const coupon_display_behavior_id = couponDisplayBehavior[0].value
        
        
        console.log("encoded", coupon_display_behavior_id)
        // return;

        var data = {
          business_id: this.props.BusinessProfileData.business_id,
          annual_program_fee: this.state.annualProgFee  && this.state.annualProgFee !== ""?parseFloat(this.state.annualProgFee).toFixed(2):null,
          monthly_program_fee: this.state.monthlyProgFee && this.state.monthlyProgFee !== ""?parseFloat(this.state.monthlyProgFee).toFixed(2):null,
          coupon_type_id: this.state.CouponType,
          term_start_date:this.state.startDate, // moment(this.state.startDate).format("YYYY-MM-DD"),  //  dateFormat(this.state.startDate, "yyyy-mm-dd"),
          term_end_date:this.state.endDate, //moment(this.state.endDate).format("YYYY-MM-DD"), // dateFormat(this.state.endDate,"yyyy-mm-dd"),
          notes: this.state.notes,
          referral_fee_fixed: parseFloat(this.state.fixedValue).toFixed(2),
          referral_fee_percent: parseFloat(this.state.percentValue).toFixed(2),
          referral_fee_type_fixed: fee_type_fixed,
          referral_fee_type_percent: fee_type_percent,
          override_default_coupon_behavior : this.state.override_default_coupon_behavior,
          system_managed_coupons : this.state.system_managed_coupons,
          coupon_display_behavior_id : coupon_display_behavior_id,
          coupon_display_behavior: couponDisplayBehaviourEncodedString,
          generate_coupon_redemption_file : this.state.sendServer,
          last_updated : new Date()
        };

        if (this.props.BusinessContract)
        {
          data.coupon_type_name = this.props.BusinessContract.coupon_type_name
          // alert(this.props.BusinessContract.coupon_type_id)
          // if(this.props.BusinessContract.coupon_type_id)
          // alert(data.annual_program_fee )
          // alert(data.annual_program_fee !=  prevData.annual_program_fee)
          data.coupon_type_id = this.props.BusinessContract.coupon_type_id
            var prevData = this.props.BusinessContract
        var flag =false
        if(data.annual_program_fee !=  prevData.annual_program_fee)
          flag = true
          if(data.monthly_program_fee !=  prevData.monthly_program_fee)
          flag = true
          if(data.term_end_date !=  prevData.term_end_date)
          flag = true
          if(data.notes !=  prevData.notes)
          flag = true
          if(data.referral_fee_fixed !=  prevData.referral_fee_fixed)
         
          flag = true
          if(data.referral_fee_percent !=  prevData.referral_fee_percent)
   
          flag = true
          if(data.referral_fee_type_fixed !=  prevData.referral_fee_type_fixed)
        
          flag = true
          if( parseFloat(data.referral_fee_type_percent).toFixed(2) !==  parseFloat(prevData.referral_fee_type_percent).toFixed(2))
          
          flag = true
          if(data.override_default_coupon_behavior !=  prevData.override_default_coupon_behavior)
       
          flag = true
          if(data.system_managed_coupons !=  prevData.system_managed_coupons)
         
          flag = true
          if(data.coupon_display_behavior_id !=  prevData.coupon_display_behavior_id)
         
          flag = true
          if(data.generate_coupon_redemption_file !=  prevData.generate_coupon_redemption_file)
          flag = true

          // if(data.coupon_type_id != prevData.coupon_type_id)
          // flag = true

          if(flag)
          {
            // alert(JSON.stringify(data))
            this.props.saveBusinessContract(
            data,
            this.props.BusinessContract.coupon_contract_id)
        }
          else if(this.state.GOTO_QUOTAS){
            // if(this.props.updated){
            //   this.props.cookies.remove("BusinessProfileData", { path: '/' })
            //   this.props.cookies.set("BusinessProfileData", this.props.object,
            //     { path: '/' })
      
            // }
            this.props.history.push({
              pathname: "./busines_coupon_quotas"
            });
            
          }

        }
        else this.props.saveBusinessContract(data);
      }
    }
  };

  setCouponType=(typeId)=>{
    
    if (!this.props.BusinessContract)
    this.state.CouponType = typeId
    // alert(JSON.stringify(this.props.BusinessContract.coupon_type_id))
  }
  // listHandler onClick function .............
  QuotasHandler = () => {
    this.state.GOTO_QUOTAS = true
    this.props.GOTO_QUOTAS();
    this.saveHandler();
  };
  // profileHandler onClick function .............
  profileHandler = () => {
    this.props.history.push({
      pathname: "./business_profile"
    });
  };
  couponDisplayHandler = (display_id) =>{
    this.setState({
      displayId:display_id
    })
  }

  scrollToProgramFee = () => {
    this.ProgramFee.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToTermStartDate = () => {
    this.TermStartDate.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToTermEndDate = () => {
    this.TermEndDate.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToCouponType  = () => {
    this.CouponType.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  
  scrollToReferralFees = () => {
    this.ReferralFees.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  render() {
    const {
      businessName,
      businessNameErrorMsg,
      businessNameFlag,
      annualProgFee,
      annualProgFeeErrorMsg,
      annualProgFeeFlag,
      monthlyProgFee,
      monthlyProgFeeErrorMsg,
      monthlyProgFeeFlag,
      startDate,
      startDateErrorMsg,
      startDateFlag,
      endDate,
      endDateErrorMsg,
      endDateFlag,
      CouponType,
      CouponTypeErrorMsg,
      CouponTypeFlag,
      notes,
      notesErrorMsg,
      notesFlag,
      fixedValue,
      fixedValueErrorMsg,
      fixedValueFlag,
      percentValue,
      percentValueErrorMsg,
      percentValueFlag,
      radioCheck,
      newRadioCheck,
      newRadioCheck2,
      displayCouponBehaviourErrorMsg,
      displayCouponBehaviourFlag,
      showReferralFeeSection
    } = this.state;
    // alert(this.state.CouponType)
    //console.log('business information', this.props.BusinessContract)
    return (
      <div className="bc_container">
        <div className="bc_header">
          <h1>Business Contract</h1>
          {/* <button className="bc_btn bc_btn0" onClick={this.homeHandler}>
            Admin - Home
          </button> */}
        </div>
        
        <GetReferralInvoicesFlagContainer setParentState = {this.handleReferralFeeSection}/>

        <GetDisplayTypesContainer setParentState = {this.setDisplayTypes}/>

        <div className="bc_content_container">
          <LabelInput
            type="text"
            name="businessName"
            star="*"
            id="businessName"
            label="Business Name:"
            inputValue={businessName}
            flag={businessNameFlag}
            errorMsg={businessNameErrorMsg}
            readOnly={true}
          />
          {/* {this.state.monthlyProgFee === "" ? <LabelInput type="text" name="annualProgFee" label="Annual Program Fee:" inputValue={annualProgFee} flag={annualProgFeeFlag} errorMsg={annualProgFeeErrorMsg} containerStyle={{ marginTop: '20px' }} onChange={this.annualProgFeeHandler} /> : <LabelInput type="text" name="annualProgFee" label="Annual Program Fee:" inputValue={annualProgFee} flag={annualProgFeeFlag} errorMsg={annualProgFeeErrorMsg} containerStyle={{ marginTop: '20px' }} disabled={true} />}
                    {this.state.annualProgFee === "" ? <LabelInput type="text" name="monthlyProgFee" label="Monthly Program Fee:" inputValue={monthlyProgFee} flag={monthlyProgFeeFlag} errorMsg={monthlyProgFeeErrorMsg} containerStyle={{ marginTop: '20px' }} onChange={this.monthlyProgFeeHandler} /> : <LabelInput type="text" name="monthlyProgFee" label="Monthly Program Fee:" inputValue={monthlyProgFee} flag={monthlyProgFeeFlag} errorMsg={monthlyProgFeeErrorMsg} containerStyle={{ marginTop: '20px' }} disabled={true} />} */}
          <InputRadio radioCheck={this.state.monthlyCheck}  onChange = {()=>this.setMonthly(!this.state.monthlyCheck)}/>
          {!this.state.monthlyCheck ?
           
            <LabelInput
              type="text"
              maxLength="10"
              name="monthlyProgFee"
              star="*"
              id="monthlyProgFee"
              label="Program Fee $:"
              inputValue={monthlyProgFee}
              flag={monthlyProgFeeFlag}
              errorMsg={monthlyProgFeeErrorMsg}
              containerStyle={{ marginTop: "20px" }}
              onChange={this.monthlyProgFeeHandler}
            />
            :
            <LabelInput
            type="text"
            maxLength="10"
            name="annualProgFee"
            star="*"
            id="annualProgFee"
            label="Program Fee $:"
            inputValue={annualProgFee}
            flag={annualProgFeeFlag}
            errorMsg={annualProgFeeErrorMsg}
            containerStyle={{ marginTop: "20px" }}
            onChange={this.annualProgFeeHandler}
          />
          }
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.ProgramFee = el;
            }}
          ></div>
          <LabelDatePicker
            minDate={new Date()}
            label="Contract Term Start Date"
            star="*"
            id="termStartDate"
            inputValue={startDate}
            errorMsg={startDateErrorMsg}
            flag={startDateFlag}
            containerStyle={{ marginTop: "20px" }}
            onChange={this.startDateHandler}
           // onClick={()=>alert('test')}
            disabled={this.props.BusinessContract ? true:false}
          />
          {this.state.startDate === "" ? (
            <LabelDatePicker
              containerStyle={{ marginTop: "20px" }}
              disabled={true}
              label="Contract Term End Date"
              star="*"
            />
          ) : (
              <LabelDatePicker
                minDate={this.props.BusinessContract ? new Date(this.props.BusinessContract.term_end_date) > new Date() ? new Date(this.props.BusinessContract.term_end_date) : new Date() :new Date(startDate) > new Date()?startDate : new Date()}
                label="Contract Term End Date"
                star="*"
                id="termEndDate"
                inputValue={endDate}
                errorMsg={endDateErrorMsg}
                flag={endDateFlag}
                containerStyle={{ marginTop: "20px" }}
                onChange={this.endDateHandler}
                
              />
            )}
            <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.TermStartDate = el;
            }}
          ></div>
          {/* <LabelDatePicker minDate={endDateMinDate} label="Contract Term End Date" inputValue={endDate} errorMsg={endDateErrorMsg} flag={endDateFlag} containerStyle={{ marginTop: '20px' }} /> */}
          {/* <LabelDropDownInput
            data={CouponTypeData}
            type="text"
            name="CouponType"
            label="Coupon Class:"
            inputValue={CouponType}
            flag={CouponTypeFlag}
            errorMsg={CouponTypeErrorMsg}
            containerStyle={{ marginTop: "20px" }}
            onChange={this.CouponTypeHandler}
            disabled={this.props.BusinessContract ? true:false}
          /> */}
          <GETCOUPON_TYPESCONTAINER 
            setCouponType = {this.setCouponType}

                      CouponType={CouponType}
                      CouponTypeFlag={CouponTypeFlag}
                      CouponTypeErrorMsg={CouponTypeErrorMsg}
 
                      onChange={this.CouponTypeHandler}
                      disabled={this.props.BusinessContract ? true:false}
          />
             <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.TermEndDate = el;
            }}
          ></div>
           {/* <InputRadio labelA= "Off" labelM= "On" labelName = "Override Default Coupon Behaviour"  radioCheck={this.state.override_default_coupon_behavior} onChange = {()=>this.setDefaultCouponBehavior(!this.state.override_default_coupon_behavior)} /> */}
           <div className="check-override1 overridecheck">
           <CheckBoxSingle
            value={this.state.override_default_coupon_behavior}
            onChange={this.setBehaviour}
            name="couponBehaviour"
            id="couponBehaviour"
           label = "Advanced Offers Behaviour"
           />
                        <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.CouponType = el;
            }}
          ></div>
           </div>
          <LabelTextAreaInput
            name="notes"
            inputValue={notes}
            label="Notes:"
            id="notes"
            flag={notesFlag}
            errorMsg={notesErrorMsg}
            containerStyle={{ marginTop: "20px", marginBottom: "20px" }}
            onChange={this.notesHandler}
          />

          {
            showReferralFeeSection ?

            <fieldset className="bc_fieldset inputfeild-set">
            
            
            <legend className="bc_heading">Referral Fees<span style={{color: "red"}}>*</span></legend>
            <div className="bc_fieldset_div bc_fieldset_div1">
              <label className="radio-container">
                <input
                  type="radio"
                  name="bc_radio"
                  id="bc_radio1"
                  checked={!radioCheck}
                  onChange={this.radioCheckHandler}
                />{" "}
                <span className="checkmark"></span>
                <label htmlFor="bc_radio1">Fixed</label>
              </label>
              <div style={{ width: "50%" }}>
               
                <InputDollar
                id="inputDollar"
                inputValue={fixedValue}
                errorMsg={fixedValueErrorMsg}
                flag={fixedValueFlag}
                maxLength="10"
                onChange={this.fixedValueHandler}
                disabled={radioCheck}
                />
              </div>
            </div>

            <div className="bc_fieldset_div bc_fieldset_div2">
              <label className="radio-container">
                <input
                  type="radio"
                  name="bc_radio"
                  id="bc_radio2"
                  checked={radioCheck}
                  onChange={this.radioCheckHandler}
                />{" "}
                <span className="checkmark"></span>
                <label htmlFor="bc_radio2">Percent of Face Value</label>
              </label>

              <div style={{ width: "50%" }}>
              
              <PercentageInputSign
              id="percentageInput"
              inputValue={percentValue}
              errorMsg={percentValueErrorMsg}
              flag={percentValueFlag}
              onChange={this.percentValueHandler}
              disabled={!radioCheck}
              />
                   
              </div>
            </div>
          </fieldset>
          :
          null


          }
          
        
          
          <fieldset className="bc_fieldset inputfeild-set m-margin">
            <legend className="bc_heading">Manage Offers</legend>
            <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.ReferralFees = el;
            }}
          ></div>
            <div className="manage-f-box">
              <div className="manage-radio">
                <SingleInputRadio
                label= "The Rewards System will generate and manage offers"
                nameinput='radio'
                id="manageOffers"
                onChange={this.manageCouponsRadioHandler}
                checked={newRadioCheck}
                />
              </div>
              <div className="manage-radio">
                <SingleInputRadio
                nameinput='radio'
                label= "The Rewards System will generate the offers and allow for the export of offers (either using default or advanced behavior)"
                id="advanceOffer"
                onChange={this.rewardSystemRadioHandler}
                checked={newRadioCheck2}
                />
              </div>
              {/* <hr className="hr-line" /> */}
              {/* <GetDisplayTypesContainer setParentState = {this.setDisplayTypes}/>
              {
                this.state.displayTypes.map((type, index) =>{
                  return <div className="manage-radio" key={index}>
                  <SingleInputRadio
                  nameinput='radio1'
                  id="offerQRCode"
                  label= {type.name}
                  onChange={() =>this.couponDisplayHandler(type.value)}
                  checked={type.value == this.state.displayId? true:false}
                  />
                </div>
                })
          } */}

            </div>
          </fieldset>
          {
            this.state.displayTypes && this.state.displayTypes.length > 0 ?


            <fieldset className="bc_fieldset inputfeild-set m-margin">
              <legend className="bc_heading">Display Offer Code</legend>
              <div className="manage-f-box">
                <div className="manage-radio check-box">
                  <CheckBoxSingle
                    value={this.state.showOfferCode}
                    onChange={this.setOfferCode}
                    name="displayOfferCode"
                    id="displayOfferCode"
                    label="Display Offer Code"
                  />
                </div>
                <div className="manage-radio check-box">
                  <CheckBoxSingle
                    value={this.state.showQRCode}
                    onChange={this.setQRCode}
                    name="displayQRCode"
                    id="displayQRCode"
                    label="Display QR Code"
                  />
                </div>
                <div className="manage-radio check-box">
                  <CheckBoxSingle
                    value={this.state.showRedeemButton}
                    onChange={this.setRedeemButton}
                    name="redeemCouponButton"
                    id="redeemCouponButton"
                    label="Display Redeem Coupon Button"

                    />
                  </div>
                </div>
                <p className="si_error_text" style={{ display: displayCouponBehaviourFlag ? "block" : "none" }}>
                        {displayCouponBehaviourErrorMsg}
                </p>

              </fieldset> :

              <fieldset className="bc_fieldset inputfeild-set m-margin">
                <Loading />
              </fieldset>

          }

          <fieldset className="bc_fieldset inputfeild-set m-margin">
            <legend className="bc_heading">Generate File</legend>
            <div className="manage-f-box">
              <div className="manage-radio check-box">
              <CheckBoxSingle
                  value={this.state.sendServer}
                  onChange={this.setServer}
                  name="sendServer"
                  id="sendServer"
                  label = "Generate a claimed/redeemed offers details file and send it to the server"
              />
              </div>
              
            </div>
          </fieldset>

          <div className="bc_btn_container">
          
            <button id="saveButton2" className="bc_btn bc_btn3" onClick={this.saveHandler}>
              Save
            </button>
          </div>
          <div className="bc_btn_container change-box margin">
            <div className="bc_btn_ch_left left">
              <button id="backButton2" className="bc_btn bc_btn3 left" onClick={this.backHandler}>
              <i className="material-icons"> keyboard_arrow_left </i>
            </button>
            </div>
           
            <div className="bc_btn_ch_left right">
              <button id="nextButton2" className="bc_btn bc_btn3" onClick={this.QuotasHandler}>
                <i className="material-icons"> keyboard_arrow_right </i>
              </button>
            </div>
          </div>
        </div>
        <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
            message={<span id="message-id">{this.state.snackBarMsg}</span>}
            />
      </div>
    );
  }
}

export default withRouter(BusinessContract);
