import React, {  PureComponent, Fragment } from "react";
//import './CreateApplicantProfile.css'
import { CreateApplicantProfileStatus } from "./CreateApplicantProfileConstants";
import CreateApplicantProfile from "./CreateApplicantProfile";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/";
import Loading from "../../Utils/Loading";

export class CreateApplicantProfileView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      loading: false,
      callBack: false
    };
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  setLoading = () => {
    this.state.loading = true
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case CreateApplicantProfileStatus.CreateApplicantProfile_Post.NEW:

        //  alert(JSON.stringify( this.props.CreateApplicantProfile))
        return (
          <CreateApplicantProfile className={this.props.className} CreateApplicantProfile={this.props.CreateApplicantProfile}
            Application={this.props.Application}
            setLoading={this.setLoading}
            cookies={this.props.cookies} />
        );

      case CreateApplicantProfileStatus.CreateApplicantProfile_Post.SUCCESS:
        if (this.state.callBack) {
        //  alert(JSON.stringify(this.props.ApplicantProfileDetails))
        

          this.props.cookies.remove("BusinessProfileData", { path: '/' })
          this.props.cookies.remove("BusinessApplicationData", { path: '/' })
          
          this.props.cookies.set("BusinessApplicationData", this.props.ApplicantProfileDetails, { path: '/' })
          this.props.resetReducerState()
          this.props.history.push({ pathname: "/business_profile" });
        }
        return <Fragment />;
        break;

      case CreateApplicantProfileStatus.CreateApplicantProfile_Post.FAILED:
        //  alert(JSON.stringify( this.props.CreateApplicantProfile))
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to create applicant</span>
              }
            />
            <CreateApplicantProfile className={this.props.className} CreateApplicantProfile={this.props.CreateApplicantProfile}
              Application={this.props.Application}
              cookies={this.props.cookies}
              setLoading={this.setLoading}
            />
          </Fragment>
        );

      case CreateApplicantProfileStatus.CreateApplicantProfile_Post.LOADING:
        this.state.open = true
        if (this.state.loading) {
          this.state.callBack = true
          this.state.loading = false
          return (
            <div style={{ textAlign: "center" }}>
              <Loading/>
            </div>)
        }
        else
          return (
            <CreateApplicantProfile className={this.props.className}
              CreateApplicantProfile={this.props.CreateApplicantProfile}
              Application={this.props.Application}
              setLoading={this.setLoading}
              cookies={this.props.cookies} />
          );
      default:
        return <Fragment />;
    }
  }
  render() {
    // alert(this.props.history)
    return this.getScreen(this.props.CreateApplicantProfile_Post_status);
  }
}

export default withRouter(CreateApplicantProfileView);
