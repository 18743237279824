import React, { Component, Fragment } from 'react'
import LegendInput from '../Inputs/LegendInput'
import Header from '../Header/Header'
import './ConfirmationContract.css'
// import img from '../../assets/transit.png'
import LegendDropDownInput from '../Inputs/LegendDropDownInput'
export class ConfirmationContract extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <div className="cc_container">
                    <div className="cc_div1">
                        <div className="cc_content_container">
                            <p className="cc_heading">NJ Transit</p>
                            <p className="cc_desc">Confirmation Contract</p>
                            <section className="cc_sec1">
                                <div className="cc_left_sec">
                                    <LegendInput name="businessName" type='text' placeholder="Annual Programme Fee" />
                                    <LegendInput name="street1" type='text' placeholder="Monthly Programme Fee" />
                                    <LegendInput name="street2" type='text' placeholder="Start Date" />
                                    <LegendInput name="city" type='text' placeholder="End Date" />
                                    <LegendDropDownInput placeholder="Coupon Type ID" containerStyle={{marginBottom: '16px'}} />
                                    <LegendDropDownInput placeholder="Contract ID" />
                                </div>
                                <div className="cc_right_sec">
                                    {/* <img className="cc_img" src={img} alt="image not found" /> */}
                                    <div className="cc_right_sec_inner_div">
                                        <p className="cc_inner_text">INSERT NJ TRANSIT ILLUSTRATION</p>
                                    </div>
                                </div>

                            </section>
                            <button className="cc_next_btn">Submit</button>
                        </div>
                        <div className="cc_bottoms">
                            <button className="cc_btn cc_btn1">Help</button>
                            <button className="cc_btn cc_btn2">Privacy</button>
                            <button className="cc_btn cc_btn3">Terms</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ConfirmationContract
