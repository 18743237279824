import { ChangePasswordStatus, ChangePasswordActions } from './ChangePasswordConstants';

const ChangePassword_initialState = {
    ChangePassword_Status: ChangePasswordStatus.ChangePassword_POST.NEW,
    ChangePassword_Message: '',
    ChangePasswordDetails: [],
}
export default  function  (state = ChangePassword_initialState, action) {
    switch (action.type) {
        case ChangePasswordActions.ChangePassword_POST.LOADING:
            return { ...state, ChangePassword_Status: ChangePasswordStatus.ChangePassword_POST.LOADING }
            case ChangePasswordActions.ChangePassword_POST.NEW:
                return { ...state, ChangePassword_Status: ChangePasswordStatus.ChangePassword_POST.NEW, ChangePasswordDetails: [] }
    
        case ChangePasswordActions.ChangePassword_POST.FAILED:
            return { ...state,  ChangePassword_Status: ChangePasswordStatus.ChangePassword_POST.FAILED, ChangePassword_Message:action.error}
        case ChangePasswordActions.ChangePassword_POST.SUCCESS:
          
          return { ...state,  ChangePassword_Status: ChangePasswordStatus.ChangePassword_POST.SUCCESS, ChangePasswordDetails:action.payload,
            ChangePassword_Message:action.message
        }
          
        
        default:
            return { ...state,
                
            }
    }
}
