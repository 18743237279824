export const ChangeEmailActions= {
	
	ChangeEmail_POST :{
		NEW:"ChangeEmail_POST_NEW",
		SUCCESS:"ChangeEmail_POST_SUCCESS",
		FAILED:"ChangeEmail_POST_FALIURE",
		LOADING:"ChangeEmail_POST_LOADING",
	},
	
}


export const ChangeEmailStatus ={

	ChangeEmail_POST :{
		NEW:"ChangeEmail_POST_NEW",
		SUCCESS:"ChangeEmail_POST_SUCCESS",
		FAILED:"ChangeEmail_POST_FALIURE",
		LOADING:"ChangeEmail_POST_LOADING",
	}

}
