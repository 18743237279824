export const DeleteBannerActions= {
	
	DeleteBanner_POST :{
		NEW:"DeleteBanner_POST_NEW",
		SUCCESS:"DeleteBanner_POST_SUCCESS",
		FAILED:"DeleteBanner_POST_FALIURE",
		LOADING:"DeleteBanner_POST_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const DeleteBannerStatus ={

	DeleteBanner_POST :{
		NEW:"DeleteBanner_POST_NEW",
		SUCCESS:"DeleteBanner_POST_SUCCESS",
		FAILED:"DeleteBanner_POST_FALIURE",
		LOADING:"DeleteBanner_POST_LOADING",
	}

}
