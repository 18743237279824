export const PreviousCouponsActions= {
	
	GetCouponTerms_GET :{
		NEW:"GetCouponTerms_GET_NEW",
		SUCCESS:"GetCouponTerms_GET_SUCCESS",
		FAILED:"GetCouponTerms_GET_FALIURE",
		LOADING:"GetCouponTerms_GET_LOADING",
		UPDATE:"DisableGetCouponTerms_Update_SUCCESS",
	},
	
}


export const GetCouponTermsStatus ={

	GetCouponTerms_GET :{
		NEW:"GetCouponTerms_GET_NEW",
		SUCCESS:"GetCouponTerms_GET_SUCCESS",
		FAILED:"GetCouponTerms_GET_FALIURE",
		LOADING:"GetCouponTerms_GET_LOADING",
	}

}
