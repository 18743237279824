import { GetCouponTermsStatus, PreviousCouponsActions } from './GetCouponTermsConstants';

const GetCouponTerms_initialState = {
    GetCouponTerms_Status: GetCouponTermsStatus.GetCouponTerms_GET.NEW,
    GetCouponTerms_Error: '',
    GetCouponTerms: [],
}
export default  function  (state = GetCouponTerms_initialState, action) {
    switch (action.type) {
        case PreviousCouponsActions.GetCouponTerms_GET.LOADING:
            return { ...state, GetCouponTerms_Status: GetCouponTermsStatus.GetCouponTerms_GET.LOADING }
            case PreviousCouponsActions.GetCouponTerms_GET.NEW:
                return { ...state, GetCouponTerms_Status: GetCouponTermsStatus.GetCouponTerms_GET.NEW }
    
        case PreviousCouponsActions.GetCouponTerms_GET.FAILED:
            return { ...state,  GetCouponTerms_Status: GetCouponTermsStatus.GetCouponTerms_GET.FAILED, GetCouponTerms_Error:action.error}
        case PreviousCouponsActions.GetCouponTerms_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  GetCouponTerms_Status: GetCouponTermsStatus.GetCouponTerms_GET.SUCCESS, GetCouponTerms:action.payload}
        
        default:
            return { ...state,
            }
    }
}
