import React, { PureComponent, Component, Fragment } from 'react'
//import './UploadBanner.css'
import { UploadBannerStatus } from './UploadBannerConstants';
import UploadBannerForm from "./UploadBannerForm/UploadBannerForm"
import UploadBannerHeader from "./UploadBannerHeader/UploadBannerHeader"
// import ProgressBar from '../ProgressBar/ProgressBar'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../Utils/Loading';

export class UploadBannerView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
    props.resetReducerState()
  }
  // componentDidMount(){
  //   this.props.resetReducerState()
  // }

  componentWillUnmount(){
    this.props.resetReducerState()
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.UploadBanner_post_status === UploadBannerStatus.UploadBanner_Post.SUCCESS) {

        toast.success(nextProps.UploadBanner_Message)
        nextProps.resetReducerState()
        nextProps.cookies.remove("banner_duplicate", { path: "/" });
        nextProps.cookies.remove("banner_edit", { path: "/" });
    
        nextProps.history.replace({
          pathname:"/Manage_Banner"
        })

      // nextProps.resetReducerState()

      // var object = nextProps.UploadBanner

      // const business = nextProps.cookies.get("UploadBannerData") ||  nextProps.cookies.get("BusinessApplicationData")
      // object = { ...business, ...object }
      // nextProps.cookies.remove("BusinessApplicationData", { path: '/' })
      // nextProps.cookies.remove("UploadBannerData", { path: '/' })
     

      // nextProps.cookies.set("UploadBannerData", object, { path: '/' })

      // if (nextProps.GoToContractScreen) {

      //   nextProps.history.push({
      //     pathname: "./business_contract"
      //   });
      // }else
      // toast.success(nextProps.UploadBanner_Message)
    
    }
    else return null;
  }
  
  // closePostSnackBar = () => {
  //   this.setState({
  //     open: false
  //   })
  // }
  saveUploadBanner=(data)=>{
    // alert(JSON.stringify(this.props.cookies)+" create")
    // alert(JSON.stringify(this.props.cookies))
    data={...data,cookies:this.props.cookies, history:this.props.history}
    this.props.saveUploadBanner(data)

  }
  updateRetailerBanner = (data, bannerId) => {
    // alert(JSON.stringify(this.props.cookies)+" update")
    data={...data,cookies:this.props.cookies, history:this.props.history}
    this.props.updateRetailerBanner(data, bannerId)
 }
  getScreen(status) {

    // alert(JSON.stringify(this.props.cookies.get('UploadBannerData')))
    switch (status) {
      case UploadBannerStatus.UploadBanner_Post.NEW:
        return (
          <UploadBannerForm 
            saveUploadBanner={this.saveUploadBanner}
            UpdateBannerData={this.props.cookies.get('banner_edit')} 
            updateRetailerBanner={this.updateRetailerBanner}
            DuplicateBannerData = {this.props.cookies.get('banner_duplicate')}
            // BusinessApplicationData={this.props.cookies.get("BusinessApplicationData")}
            // GotoContract={this.props.GotoContract}
            // CreateRetailerBussiness={this.props.CreateRetailerBussiness}
          />
        );

      case UploadBannerStatus.UploadBanner_Post.SUCCESS:
        
        return (
          <Fragment>
            <UploadBannerForm 
            saveUploadBanner={this.saveUploadBanner}
            UpdateBannerData={this.props.cookies.get('banner_edit')} 
           updateRetailerBanner={this.updateRetailerBanner}
           DuplicateBannerData = {this.props.cookies.get('banner_duplicate')}
            />

          </Fragment>
        );
        break;
      case UploadBannerStatus.UploadBanner_Post.FAILED:
        toast.error(this.props.UploadBanner_Message)

        return (
          <Fragment>

            <UploadBannerForm 
            saveUploadBanner={this.saveUploadBanner}
            UpdateBannerData={this.props.cookies.get('banner_edit')} 
           updateRetailerBanner={this.updateRetailerBanner}
           DuplicateBannerData = {this.props.cookies.get('banner_duplicate')}
            />

          </Fragment>
        );
        break
      case UploadBannerStatus.UploadBanner_Post.LOADING:
        this.state.open = true
        return (
          <div style={{ textAlign: "center",display:"flex", height:"65vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.UploadBanner_post_status)
    return (
      <Fragment>
        <UploadBannerHeader />
        {this.getScreen(this.props.UploadBanner_post_status)}
      </Fragment>
    )
  }
}

export default withRouter(UploadBannerView)
