export const GenerateCouponsActions= {
	
	GenerateCoupons_Post :{
		NEW:"GenerateCoupons_Post_NEW",
		SUCCESS:"GenerateCoupons_Post_SUCCESS",
		FAILED:"GenerateCoupons_Post_FALIURE",
		LOADING:"GenerateCoupons_Post_LOADING",
	}
}


export const GenerateCouponsStatus ={

	GenerateCoupons_Post :{
		NEW:"GenerateCoupons_Post_NEW",
		SUCCESS:"GenerateCoupons_Post_SUCCESS",
		FAILED:"GenerateCoupons_Post_FALIURE",
		LOADING:"GenerateCoupons_Post_LOADING",
	
	}

}
