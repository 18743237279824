import { DeleteCouponStatus, DeleteCouponActions } from './DeleteCouponConstants';

const DeleteCoupon_initialState = {
    DeleteCoupon_Status: DeleteCouponStatus.DeleteCoupon_Post.NEW,
    DeleteCoupon_Message: '',
    DeleteCouponDetails: {},
}
export default function (state = DeleteCoupon_initialState, action) {
    switch (action.type) {
        case DeleteCouponActions.DeleteCoupon_Post.LOADING:
            return { ...state, 
            DeleteCoupon_Status: DeleteCouponStatus.DeleteCoupon_Post.LOADING, DeleteCoupon_Message: '',
            DeleteCouponDetails: {}
         }
        case DeleteCouponActions.DeleteCoupon_Post.NEW:
            return { ...state, DeleteCoupon_Status: DeleteCouponStatus.DeleteCoupon_Post.NEW}

        case DeleteCouponActions.DeleteCoupon_Post.FAILED:
           // alert("test")
            return { ...state, DeleteCoupon_Status: DeleteCouponStatus.DeleteCoupon_Post.FAILED, DeleteCouponDetails: action.payload, DeleteCoupon_Message: action.error }
        case DeleteCouponActions.DeleteCoupon_Post.SUCCESS:
            return { ...state, DeleteCoupon_Status: DeleteCouponStatus.DeleteCoupon_Post.SUCCESS, DeleteCouponDetails: action.payload, DeleteCoupon_Message: action.message }

        default:
            return { ...state }
    }
}
