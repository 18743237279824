import React, { PureComponent, Fragment } from 'react'
//import './BusinessContract.css'
import { BusinessContractStatus } from './BusinessContractConstants';
//import BusinessContractUpdate from "./BusinessContractUpdate"
import BusinessContract from "./BusinessContract"
// import ProgressBar from '../ProgressBar/ProgressBarFive'
import ProgressBarFour from '../ProgressBar/ProgressBarFour'
import { withRouter } from 'react-router-dom';
//import Snackbar from '@material-ui/core/Snackbar/';
import BusinessName from "../BusinessName/BusinessName"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../Utils/Loading';

export class BusinessContractView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      load:true,
      mount: false,
      BusinessProfileData: this.props.cookies.get("BusinessProfileData")
    }
    props.getBusinessContractList({setLoad:this.setLoad,business_id:this.props.cookies.get("BusinessProfileData").business_id})
  }

  setLoad=(load)=>{
    this.state.load=load
    // this.setState({
    //   load:load
    // })
  }

  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
    this.props.getBusinessContractList(this.state.BusinessProfileData.business_id)

  }


  componentWillUnmount(){
    this.props.resetReducerState()
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.BusinessContract_Status === BusinessContractStatus.BusinessContract_Post.SUCCESS){
      // alert("test")
      toast.success(nextProps.BusinessContract_Message)
        
      // this.state.mount = false
      let object = nextProps.BusinessContract
      const business = nextProps.cookies.get("BusinessProfileData")
      delete object.last_updated;

      
        // alert(object.coupon_type_name)
      object = { ...business, ...object, coupon_type_name:object.coupon_type_name }
        // alert(JSON.stringify(object))

        nextProps.cookies.remove("BusinessProfileData", { path: '/' })
        nextProps.cookies.set("BusinessProfileData", object,
        { path: '/' })
        nextProps.resetSuccessState(object)
      if (nextProps.GotoQuotas) {
        nextProps.history.push({
          pathname: "./busines_coupon_quotas"
        });
    }
    return {mount:false}
    }
    return null;
  }
   
  getScreen(status) {

    switch (status) {

      case BusinessContractStatus.BusinessContract_Post.NEW:
        return (
          <Fragment>
          {this.state.load?"":
            <BusinessContract GOTO_QUOTAS={this.props.GOTO_QUOTAS} BusinessProfileData={this.state.BusinessProfileData} saveBusinessContract={this.props.saveBusinessContract} />
        }
          </Fragment>
        );

      case BusinessContractStatus.BusinessContract_Get.SUCCESS:
        // alert(this.props.BusinessContract)
        // alert(JSON.stringify(this.props.BusinessContract))
        return (
          <BusinessContract saveBusinessContract={this.props.updateBusinessContract}
            BusinessProfileData={this.state.BusinessProfileData}
            BusinessContract={this.props.BusinessContract}
            GOTO_QUOTAS={this.props.GOTO_QUOTAS}
          />
        );
      case BusinessContractStatus.BusinessContract_Get.FAILED:
        toast.error(this.props.BusinessContract_Message)
        // this.props.resetReducerState()
       //   alert(JSON.stringify( this.props.BusinessContract))
        return (
          <Fragment>
          
            <BusinessContract GOTO_QUOTAS={this.props.GOTO_QUOTAS} BusinessProfileData={this.state.BusinessProfileData} saveBusinessContract={this.props.saveBusinessContract} />

          </Fragment>
        );

      case BusinessContractStatus.BusinessContract_Post.SUCCESS:
      
        return (
          <Fragment>

          </Fragment>
        );
        break;

      case BusinessContractStatus.BusinessContract_Post.FAILED:
        if(this.state.mount)
        {
          this.state.mount = false
          toast.error(this.props.BusinessContract_Message)
        }
        return (
          <Fragment>
            <BusinessContract GOTO_QUOTAS={this.props.GOTO_QUOTAS} BusinessProfileData={this.state.BusinessProfileData} saveBusinessContract={this.props.saveBusinessContract} />
          </Fragment>
        );
      case BusinessContractStatus.BusinessContract_Post.LOADING:
        this.state.mount = true
        return (
          <div style={{ textAlign: "center", height:"65vh", display:"flex",width:"100%", justifyContent:"center", alignItems:"center" }}>
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.BusinessContract_Status)
    return (
      <Fragment>
        <BusinessName/>
        <ProgressBarFour ProgressBar1='active' ProgressBar2='active'/>
      
      {this.getScreen(this.props.BusinessContract_Status)}
      </Fragment>
    )
  }
}

export default withRouter(BusinessContractView)
