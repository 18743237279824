import React, {  Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import "./BusinessCouponQuotas.css";
import LabelInput from "../Inputs/LabelInput";
import LabelDropDownInput from "../Inputs/LabelDropDownInput";
import GetFaceValuesContainer from "./GetFaceValues/GetFaceValuesContainer";
import { yearsServer } from "./GetYears/server";

import Snackbar from "@material-ui/core/Snackbar/";
//import ListYearBox from "../ListYearBox/ListYearBox";
//import { BusinessCouponQuotasStatus } from './BusinessCouponQuotasconstants';

class BusinessCouponQuotasForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "",
      business_id: "",
      couponType: "Regular",
      year: "",
      yearErrorMsg: "",
      yearFlag: "",
      yearData: [],
      couponLifeTime: "",
      PrevLifeTime: "",
      couponLifeTimeErrorMsg: "",
      couponLifeTimeFlag: "",
      MaxMonthlyIssue: [],
      PrevMonthlyIssue: [],
      open: false,
      BusinessProfileData: props.cookies.get("BusinessProfileData"),
      yearReadonly: false,
      QuotaListlength: 0,
      RecentlySaved: props.RecentlySaved,
      Goto_ManageCoupons: false,
    };


    
    const {term_end_date, term_start_date} = this.state.BusinessProfileData
    
    // in months
    let allowedLifeTimePeriod = this.getMonthDiff(new Date(term_start_date), new Date(term_end_date))
    // alert(allowedLifeTimePeriod)
    this.state.couponLifeTime = allowedLifeTimePeriod + 1
    // alert(JSON.stringify(props.cookies.get("BusinessProfileData")))
  }
  setYearData = (data) => {
    // alert(JSON.stringify(data))
    // this.setState({
    //   yearData: data,
    // });
  // if (!this.props.editMode)
  //   alert(JSON.stringify(data))
     this.props.setPrevYearData(data);
  };
  setQuotasData = (data) => {
    //  alert(JSON.stringify(data))
    
    this.setState({
      MaxMonthlyIssue: data
    })
  };
  // todo: Face Value onChange Function ...................
  UNSAFE_componentWillMount() {
    //  alert(this.props.updateBusinessCouponQuotas)
    // if(this.props.setRecentlySaved){
    //   this.props.setRecentlySaved()
    // alert('edit save')
    // }
    // this.props.set_Goto_ManageCoupons(false, false);

    this.state.business_id = this.state.BusinessProfileData.business_id;
    this.state.businessName = this.state.BusinessProfileData.business_name;
    this.state.couponType = this.state.BusinessProfileData.coupon_type_name
    if (this.props.BusinessCouponQuotas.length > 0) {
      this.state.couponLifeTime = this.props.BusinessCouponQuotas[0].coupon_lifetime_in_months;
      this.state.PrevLifeTime = this.state.couponLifeTime;
      var arr = [...this.props.BusinessCouponQuotas];

      // this.props.BusinessCouponQuotas.forEach((item, index) => {
      //   // alert(JSON.stringify(item))
      //   arr[index] = {
      //     coupon_max_quota: item.coupon_max_quota,
      //     coupon_max_quota_errFlag: false,
      //     coupon_id: item.coupon_id,
      //     face_value_id: item.face_value_id,

      //   };
      // });
      this.state.MaxMonthlyIssue = arr;
      this.state.PrevMonthlyIssue = this.state.MaxMonthlyIssue;
    }
    //alert(JSON.stringify(this.props.QuotaList))
    if (this.props.QuotaList.length > 0) {
      // alert(this.props.editMode)

      if (this.props.editMode && this.props.BusinessCouponQuotas.length > 0) {
        // alert(this.props.BusinessCouponQuotas[0].year)
        // alert(JSON.stringify(this.props.BusinessCouponQuotas))
        this.setYear(this.props.BusinessCouponQuotas[0].year_id);

        this.setYearData([
          {
            value: this.props.BusinessCouponQuotas[0].year_id,
            name: this.props.BusinessCouponQuotas[0].year,
          },
        ]);
      }else if(this.props.prevYearData.length > 0 && this.state.year == ""){
        // alert(this.props.prevYearData[0].value)
        this.setYear(this.props.prevYearData[0].value);

      }
    }
    
  }

  getYears = (year) => {
    var years = this.getDateArray(
      new Date(this.state.BusinessProfileData.term_start_date),
      new Date(this.state.BusinessProfileData.term_end_date)
    );

    var data = {
      setYearData: this.setYearData,
      failedTogetYears: this.failedTogetYears,
      years: years,
      setYear: this.setYear,
    };

    yearsServer.getyears(data, year);
  };

  setYear = (year_id) => {
    this.setState({
      year: year_id,
      yearFlag: false,
    });
  };
  yearHandler = (e) => {
    // alert(e.target.value)
    if (this.state.year !== "" || this.state.year !== 0) {
      this.setState({ yearFlag: false });
    }
    this.setState({ year: e.target.value });
  };
  // todo: couponLifeTimeHandler onChange Function ...................

  getMonthDiff = (dateFrom, dateTo)=> {
    return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
   }


  couponLifeTimeHandler = (e) => {

    const {term_end_date, term_start_date} = this.state.BusinessProfileData
    
    // in months
    let allowedLifeTimePeriod = this.getMonthDiff(new Date(term_start_date), new Date(term_end_date))
    allowedLifeTimePeriod = allowedLifeTimePeriod+1
    // alert(allowedLifeTimePeriod)


    var val =e.target.value
    
    const reg = /^[0-9]*$/;
    const validReg = reg.test(val);
    if (validReg) {
      this.setState({ couponLifeTimeFlag: false });
    } else {
      this.setState({
        couponLifeTimeFlag: true,
        couponLifeTimeErrorMsg: "Use only Numeric"
      });

    }

    const regex = /^[0]+\.?[0]*$/;

   const RegexValue = regex.test(val);
    if(RegexValue){
      this.setState({
        couponLifeTimeFlag: true,
        couponLifeTimeErrorMsg: "Zero value not allow"
      });
    }
    // if(val == ""){
    //   this.setState({
    //     couponLifeTimeFlag: true,
    //     couponLifeTimeErrorMsg: "Field Required"
    //   });
    // }
    
    // lifetime value entered is greater than allowed lifetime
    // throw error in this case
    if(val > allowedLifeTimePeriod){
      this.setState({
        couponLifeTimeFlag: true,
        couponLifeTimeErrorMsg: "Offer Lifetime in months value should not exceed the range of contract months. "
      });
    }

    this.setState({ couponLifeTime: e.target.value });
  };

  // todo: inputHandler onChange Function ...................

  // todo: homeHandler onClick function ..........................
  // todo: backHandler onClick Function ...................
  backHandler = () => {
    // alert("backHandler work")
    this.props.history.replace({
      pathname: "/business_contract",
    });
  };
  // todo: nextHandler onClick Function ...................
  nextHandler = () => {
    var flag = true
    if(this.state.BusinessProfileData.override_default_coupon_behavior != null ||
      this.state.BusinessProfileData.override_default_coupon_behavior != undefined)
      {
       if(this.state.BusinessProfileData.override_default_coupon_behavior){
           flag = true
       }else{
        flag = false
       }
      }
      
    if(this.props.prevYearData.length == 0)
    {  
    this.GoNext(flag)
    }else{
      if(!this.isFieldFilled())
      this.GoNext(flag)
      else
      {
        // alert(flag)
        if(flag)
        this.props.set_Goto_ManageCoupons(false, true);
        else
        this.props.set_Goto_ManageCoupons(true, false);
       this.saveHandler()
      }
    }

  };

  isFieldFilled = () =>{
    var flag = false
    if(this.state.couponLifeTime != "")
    flag = true
    var MaxMonthlyLimit = this.state.MaxMonthlyIssue;
    MaxMonthlyLimit.forEach(item=>{
      if(item.coupon_max_quota && item.coupon_max_quota !="")
      flag = true
    })

    return flag
    
  }
  GoNext = (flag) =>{
    
      if(flag)
      this.props.history.push({
        pathname: "/OverridePricing",
      });
      else{
        this.props.history.push({
          pathname: "/ManageCouponInventory",
        });
      }
  }
  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin",
    });
  };
  // todo: homeHandler onClick function ..........................

  businessListHandler = () => {
    this.props.history.push({
      pathname: "/business_list",
    });
  };

  // todo: homeHandler onClick function ..........................

  businessProfileHandler = () => {
    this.props.history.push({
      pathname: "/business_profile",
    });
  };
  getDateArray = (start, end) => {
    var arr = [];
    var dt = new Date(start).getFullYear();
    if(dt <new Date().getFullYear())
    dt = new Date().getFullYear()
    var en = new Date(end).getFullYear();
    while (dt <= en) {
      arr.push(dt);
      dt++;
    }
    return arr;
  };

  // todo: saveHandler onClick Function ...................
  failedTogetYears = (msg) => {
    this.setState({
      open: true,
      ErrorMsg: msg,
    });
  };
  saveHandler = () => {
    var flag = false;
    if (this.state.year == 0 || this.state.year === "") {
      this.setState({
        yearErrorMsg: "Field Required",
        yearFlag: true,
      });
    } else if (
      this.state.couponLifeTimeFlag ||
      this.state.couponLifeTime === ""
    ) {
      this.setState({
        couponLifeTimeErrorMsg:
          this.state.couponLifeTimeFlag && this.state.couponLifeTime.length > 0
            ? this.state.couponLifeTimeErrorMsg
            : "Field Required",
        couponLifeTimeFlag: true,
      });
    }
    // else if (this.state.couponLifeTime > 12) {
    //   this.setState({
    //     couponLifeTimeErrorMsg: "Invalid no. of months",
    //     couponLifeTimeFlag: true,
    //   });
    // } 
      else if (this.state.MaxMonthlyIssue.length > 0) {
      var MaxMonthlyLimit = this.state.MaxMonthlyIssue;
      // var MaxLength = this.state.MaxMonthlyIssue.length
      var counter = 0;
      var data = [];
      var loopFlag = true
      var totalQuota = 0
      MaxMonthlyLimit.forEach((item, index) => {
        if(loopFlag)
       { 
        
         if (item.coupon_max_quota >= 0 && !item.coupon_max_quota_errFlag) {
          // alert(item.coupon_max_quota_errFlag)
          //  alert(JSON.stringify(item))
          totalQuota = totalQuota + item.coupon_max_quota
          data.push({
            business_id: this.state.business_id,
            year_id: this.state.year,
            coupon_max_quota: item.coupon_max_quota,
            coupon_lifetime_in_months: this.state.couponLifeTime,
            needsInsert:item.needsInsert,
            face_value_id: item.face_value_id,
            last_updated:new Date()
          });
          if(this.props.updateBusinessCouponQuotas && this.props.editMode && this.state.PrevMonthlyIssue[index].coupon_max_quota !=item.coupon_max_quota)
          flag = true

          if (item.coupon_id && this.props.editMode) {
            data[index] = { ...data[index], coupon_id: item.coupon_id };
          }
          counter++;
        } else {
          if(MaxMonthlyLimit[index].coupon_max_quota_errFlag && MaxMonthlyLimit[index].coupon_max_quota_err == "" || !MaxMonthlyLimit[index].coupon_max_quota_err)
          MaxMonthlyLimit[index].coupon_max_quota_err = "Field Required";
          
          MaxMonthlyLimit[index].coupon_max_quota_errFlag = true;
          loopFlag = false
          return false;
        }
      }
      });
      if (MaxMonthlyLimit.length == counter) {
        if(totalQuota > 0)
     {   if (this.props.updateBusinessCouponQuotas && this.props.editMode) {
          
          if (this.state.PrevLifeTime != this.state.couponLifeTime) 
          flag = true;
         
          if (flag)
          {
          //  alert(JSON.stringify(this.state.MaxMonthlyIssue[11]))
             this.props.updateBusinessCouponQuotas(data);
          }
          else if (this.state.Goto_ManageCoupons) {
            this.props.history.push({
              pathname: "/business_list",
            });
          }
        } else {
          console.log(data);
          //  alert(JSON.stringify(data))
          this.props.saveBusinessCouponQuotas(data);
        }
      }else{
        this.setState({
          open: true,
          ErrorMsg: "Atleast one face value quota should be greater than zero",
        });
      }
      } else {
        this.setState({
          MaxMonthlyIssue: []
        },() => this.setQuotasData(MaxMonthlyLimit)
        )

        // this.setState({
        //   open: true,
        //   ErrorMsg: "Please fill out every Max Monthly Limit values correctly",
        // });
      }
    }
  };
  previousYearHandler = () => {
    // alert(JSON.stringify(this.props.QuotaList))

    if (this.props.prevYearData.length > 0 && this.props.QuotaList.length > 0) {
      const year_id = this.props.QuotaList[this.props.QuotaList.length - 1]
        .year_id;

      this.props.getBusinessCouponQuotasList(
        year_id,
        this.state.BusinessProfileData.business_id
      );
    }
  };
  render() {
    // alert(JSON.stringify(this.state.MaxMonthlyIssue))
    // alert(this.props.QuotaList.length)
    // alert(this.props.isLoading)
    if(!this.props.isLoading){
      // alert(this.props.RecentlySaved)
      if (
          this.props.QuotaList.length != this.state.QuotaListlength &&
          this.props.QuotaList.length > 0
        ) {
          if (!this.props.editMode) {
            this.state.QuotaListlength = this.props.QuotaList.length;
            // alert("get")
            this.getYears(this.props.QuotaList);
            this.props.setRecentlySaved(true);
          }
          else if (this.state.QuotaListlength == 0 && !this.props.RecentlySaved) {
            //  alert("get")
            this.getYears(this.props.QuotaList);
            this.props.setRecentlySaved(true);
          }
         
        }else if (!this.props.RecentlySaved && this.props.QuotaList.length == 0){
          // alert(this.state.QuotaListlength)
          this.getYears("");
          this.props.setRecentlySaved(true);
        }
    }
    // 
    // alert(JSON.stringify(this.props.prevYearData))
    const {
      businessName,
      couponType,
      year,
      yearErrorMsg,
      yearFlag,
      couponLifeTime,
      couponLifeTimeErrorMsg,
      couponLifeTimeFlag,
    } = this.state;
    // alert(year)
    return (
      <Fragment>
        <div className="bus-form-main">
        <div className="b-coupon-form">
        <LabelInput
          type="text"
          name="businessName"
          star="*"
          id="businessName"
          label="Business Name:"
          inputValue={businessName}
          readOnly={true}
        />
        <LabelInput
          type="text"
          name="couponType"
          star="*"
          id="couponClass"
          label="Offer Class:"
          inputValue={couponType}
          readOnly={true}
          containerStyle={{ marginTop: "20px" }}
        />
        <LabelDropDownInput
          data={this.props.prevYearData}
          type="text"
          name="year"
          star="*"
          id="year"
          label="Year:"
          inputValue={year}
          flag={yearFlag}
          errorMsg={yearErrorMsg}
          containerStyle={{ marginTop: "20px" }}
          onChange={this.yearHandler}
        />
        {/* <ListYearBox/> */}
        <LabelInput
          type="text"
          name="couponLifeTime"
          id="couponLifeTime"
          star="*"
          maxLength={2}
          label="Offer Life time in months:"
          inputValue={couponLifeTime}
          flag={couponLifeTimeFlag}
          errorMsg={couponLifeTimeErrorMsg}
          containerStyle={{ marginTop: "20px" }}
          onChange={this.couponLifeTimeHandler}
        />
        </div>
        <div className="bus-col-right">
        <div className="bcq_calendar">
          <GetFaceValuesContainer
            MaxMonthlyIssue={this.state.MaxMonthlyIssue}
            setQuotasData={this.setQuotasData}
            coupon_type_id={this.state.BusinessProfileData.coupon_type_id}
          />

        </div>
        </div>
</div>
        <div
          // style={{ justifyContent: "space-between", flex: 1, width: "200px" }}
          className="bcq_btn_container save-btn-c"
        >
          {/* <button className="bcq_btn bcq_btn0" onClick={this.homeHandler}>
              Back To Home
            </button> */}
          {/* <button
              className="bcq_btn bcq_btn1"
              onClick={this.businessListHandler}
            >
              Back To Business List
            </button>
            <button
              className="bcq_btn bcq_btn2"
              onClick={this.businessProfileHandler}
            >
              Back To Business Profile
            </button> */}
          {/* <button
            className="bcq_btn bcq_btn3"
            onClick={this.previousYearHandler}
          >
            Copy
          </button> */}
        
          <button id="saveButton3" className="bcq_btn bcq_btn4" onClick={()=>{this.props.set_Goto_ManageCoupons(false,false);this.saveHandler()}}>
            Save
          </button>
        </div>
        <div className="bcq_btn_container change-box margin">
          <div className="bc_btn_ch_left left">
            <button
              id="backButton3"
              className="bcq_btn bcq_btn4 left"
              onClick={this.backHandler}
            >
              <i className="material-icons"> keyboard_arrow_left </i>
            </button>
          </div>
          {/* <button className="bp_btn2" onClick={this.contractHandler}>
              Business Contract
            </button> */}
          <div className="bc_btn_ch_left right">
            <button id="nextButton3" className="bcq_btn bcq_btn4" onClick={this.nextHandler}>
              {/* Finish */}
              <i className="material-icons"> keyboard_arrow_right </i>
            </button>
          </div>
        </div>

        <Snackbar
          open={this.state.open}
          autoHideDuration={2000}
          onClose={() => this.setState({ open: false })}
          //  TransitionComponent={<Slide {...props} direction="up" />}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.ErrorMsg}</span>}
        />
      </Fragment>
    );
  }
}

export default withRouter(BusinessCouponQuotasForm);
