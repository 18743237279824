import React, { Component, Fragment } from "react";
//import './AddNewUser.css'
import { AddNewUserStatus } from "./AddNewUserConstants";
import AddNewUser from './AddNewUser'
import Loading from "../../Utils/Loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import { Link, withRouter } from "react-router-dom";
export class AddNewUserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showModal:false,
      isLoading:false
    };
    //this.getRiderUnAnsweredQuestions()
  // props.getAddNewUser()
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.AddNewUser_POST_status === AddNewUserStatus.AddNewUser_POST.SUCCESS){
      toast.success(nextProps.AddNewUser_Message)
      nextProps.resetReducerState()
    }
    else if(nextProps.AddNewUser_POST_status === AddNewUserStatus.AddNewUser_POST.FAILED){
      toast.error(nextProps.AddNewUser_Message)
      nextProps.resetReducerState()
    }
    return null
  }
  getScreen(status) {
    switch (status) {
      case AddNewUserStatus.AddNewUser_POST.NEW:
        //this.props.disableAllFields(false)
        return (
          <AddNewUser AddNewAdminUser = {this.props.AddNewAdminUser}
          AddNewRetailerUser = {this.props.AddNewRetailerUser}
          cookies = {this.props.cookies}
          />
        );

      case AddNewUserStatus.AddNewUser_POST.SUCCESS:
          // alert(this.props.AddNewUser_Message)
        
        // this.props.history.replace("/UserManagement")
        //this.props.disableAllFields(false)
        return (
          <AddNewUser AddNewAdminUser = {this.props.AddNewAdminUser} 
          AddNewRetailerUser = {this.props.AddNewRetailerUser}
          cookies = {this.props.cookies}
          />
        );
        break;
      case AddNewUserStatus.AddNewUser_POST.FAILED:
        
        
         //this.props.disableAllFields(false)
        return (
          <AddNewUser AddNewAdminUser = {this.props.AddNewAdminUser} 
          AddNewRetailerUser = {this.props.AddNewRetailerUser}
                cookies = {this.props.cookies}
          />
        );

      case AddNewUserStatus.AddNewUser_POST.LOADING:
        //this.props.disableAllFields(true)
        return (
          
            <Loading />
        
        );

      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.AddNewUser_POST_status)
    return <Fragment>
            <div className="usr-m-box">
        <div className="usr-m-conatiner">
          <div className="user-m-heading">
            <h5>Add User</h5>
          </div>
     {this.getScreen(this.props.AddNewUser_POST_status)}
     </div></div>
    </Fragment>
  }
}

export default AddNewUserView
