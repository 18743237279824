//******************Exchange Rate Server Calls******************;

import { ListOfApplicantsActions } from "./ListOfApplicantsConstants";
import store from "../../Store/store";
import { ROOT_URL,getCookie } from "../../Config/Config";

export const ListOfApplicantsServer = {
  getListOfApplicants: getListOfApplicants
};

//******************Get ListOfApplicantss******************;

function getListOfApplicants(data) {
  data.props.setLoading(true)
  const res = fetch(ROOT_URL + "/api/business/getAllBusinessApplicants", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  })
    .then(res => {
      res
        .json()
        .then(response => {
          data.props.setLoading(false)
          
      
          console.log("ListOfApplicants:", response);
          if(response.status == "200")
         { 
          if(response.ApplicantsCount > 0 && data.props.activePage == 1){
            data.props.setItemList(response.ApplicantsCount)
          }
           store.dispatch({
            type: ListOfApplicantsActions.ListOfApplicants_Get.SUCCESS,
            payload: response.data, ApplicantsCount:response.ApplicantsCount
          });
        }
          else
          {
            data.props.setItemList(0)
            store.dispatch({
            type: ListOfApplicantsActions.ListOfApplicants_Get.FAILED
          });
        }
        })
        .catch(err => {
          data.props.setLoading(false)
          console.log("ListOfApplicantsError:", err);
          store.dispatch({
            type: ListOfApplicantsActions.ListOfApplicants_Get.FAILED
          });
        });
    })
    .catch(err => {
      data.props.setLoading(false)
      console.log("ListOfApplicantsError:", err);
      store.dispatch({
        type: ListOfApplicantsActions.ListOfApplicants_Get.FAILED
      });
    });

  return { type: ListOfApplicantsActions.ListOfApplicants_Get.LOADING };
}
