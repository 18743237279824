import React, { Component } from "react";
import "../Cards/RadeemPointsCard.css";
// import { Link } from "react-router-dom";
// import logo from "../../Assets/Images/company-log.png"
import ReactDOM from "react-dom";
import { ADMIN_END_BRANDING_IMAGE_URL } from "../../Config/Config";
// var dateFormat = require("dateformat");
import UploadCouponForm from "../UploadCoupon/UploadCouponImage/UploadCouponForm";
const modalRoot = document.getElementById("message_portal");

export class RadeemPointsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collaps: true,
      detailShow: false,
      // is_disabledDeal: !(parseFloat(this.props.UserPoints) >= parseFloat(this.props.item.points))
    };
  }
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }
  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }
  render() {
    const {
      onClose,
      message = "Are you sure you want to delete this record",
      onConfirm,
    } = this.props;
    return ReactDOM.createPortal(
      <div
        style={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.3)",
          minHeight: "100vh",
          zIndex: "9999999999",
        }}
        onClick={this.props.onClose}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            borderRadius: "2px",
            display: "inline-block",
            minHeight: "200px",
            margin: "1rem",
            position: "relative",
            minWidth: "300px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
            justifySelf: "center",
            maxHeight:"80vh",
            overflow:"auto"
          }}
        >
          {/* <div className="col-sm-12 col-md-6 col-lg-4"> */}
          <UploadCouponForm Go_Back={this.props.Go_Back} BusinessProfileData={this.props.BusinessProfileData} getCouponImages={this.props.getCouponImages}/>
         </div></div>,
      modalRoot
    );
  }
}

export default RadeemPointsCard;
