import React, { Component } from "react";

import { withRouter } from "react-router-dom";

class DisableBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: props.is_disabled ? "Enable" : "Disable",
      bussinessUpdating: false
    };
  }
  DisableBusiness = () => {
    var data = {
      is_disabled: !this.props.is_disabled,
      needDisable:true
    };
    // alert(JSON.stringify(this.props.business_id))
    this.props.updateDisableBusiness(data, this.props.business_id);
  };

  render() {
    // alert(this.props.is_disabled)
    console.log("business_ID:",this.props.business_id)
    console.log("business_ID:",this.props.is_disabled)
    return (
    
      <div
        className="toggle-box"
        title={!this.props.is_disabled ? "Enable Partner" : "Disable Partner"}
      >
        <label className="switch">
          <input
          id={!this.props.is_disabled ? "enablePartner" : "disablePartner"}
            // checked={!this.props.is_disabled}
            defaultChecked={!this.props.is_disabled}
            onClick={this.DisableBusiness}
            type="checkbox"
          />
          <span className="toggle round"></span>
        </label>
      </div>
    );
  }
}

export default withRouter(DisableBusiness);
