import React, {  Fragment, PureComponent } from 'react'
//import './Authentication.css'
import { AuthenticationStatus } from './AuthenticationConstants';

import { withRouter } from 'react-router-dom';
//import Fragment from "../ConfirmationContract/ConfirmationContract"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export class AuthenticationView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
    this.checkToken()
  }

  checkToken = () =>{
    var token =this.props.cookies.get('admin_token_partner')
    if(token){
      this.props.AuthenticateUser(token)
 
     }else{
       this.props.history.replace({
         pathname:"/"
       })
     }
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.Authentication_TOKEN_status === AuthenticationStatus.Authentication_TOKEN.AUTHORIZED){
      //toast.success(nextProps.Authentication_Message)
      nextProps.resetReducerState()
      if(nextProps.location.pathname == '/')
      nextProps.history.replace({
          pathname:"/Admin"
        })  
   }
   else if(nextProps.Authentication_TOKEN_status === AuthenticationStatus.Authentication_TOKEN.NOT_AUTHORIZED){ //Authentication failed most probably token expired
    nextProps.resetReducerState()
    toast.error(nextProps.Authentication_Message)
    nextProps.cookies.remove('admin_token_partner', { path: '/' });
    nextProps.cookies.remove('njt_admin_userId_partner', { path: '/' });
    nextProps.history.replace({
     pathname:"/"
   })
   }
   else if(nextProps.Authentication_TOKEN_status === AuthenticationStatus.Authentication_TOKEN.FAILED){ //Authentication failed because of connection error
    nextProps.resetReducerState()
    toast.error(nextProps.Authentication_Message)
    if(nextProps.location.pathname !== '/')
    nextProps.history.replace({
     pathname:"/"
   })
  }
   return null;
 }
  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  getScreen(status) {
    switch (status) {
      case AuthenticationStatus.Authentication_TOKEN.NEW:
        return (
          <Fragment />

        );


      case AuthenticationStatus.Authentication_TOKEN.AUTHORIZED:
        //  alert(JSON.stringify(this.props.Authentication))

        return (
          <Fragment />

        );
        break;
      case AuthenticationStatus.Authentication_TOKEN.NOT_AUTHORIZED:
        return (

          <Fragment />
        );
        case AuthenticationStatus.Authentication_TOKEN.FAILED:
         // toast.error(this.props.Authentication_Message)
          return (
            <Fragment />
          );
  
      case AuthenticationStatus.Authentication_TOKEN.LOADING:
        this.state.open = true
        return (
          <Fragment />

          // <div style={{ textAlign: "center" }}>
          //   <h1> Loading </h1>
          // </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.Authentication_TOKEN_status)
    return (
      this.getScreen(this.props.Authentication_TOKEN_status)
    )
  }
}

export default withRouter(AuthenticationView)
