export const BusinessCategoryActions= {
	
	BusinessCategory_GET :{
		NEW:"BusinessCategory_GET_NEW",
		SUCCESS:"BusinessCategory_GET_SUCCESS",
		FAILED:"BusinessCategory_GET_FALIURE",
		LOADING:"BusinessCategory_GET_LOADING",
		UPDATE:"DisableBusinessCategory_Update_SUCCESS",
	},
	
}


export const BusinessCategoryStatus ={

	BusinessCategory_GET :{
		NEW:"BusinessCategory_GET_NEW",
		SUCCESS:"BusinessCategory_GET_SUCCESS",
		FAILED:"BusinessCategory_GET_FALIURE",
		LOADING:"BusinessCategory_GET_LOADING",
	}

}
