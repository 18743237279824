import React, { Component } from "react";
import ReactDOM from "react-dom";
import "../ApplicantsStatusMessage/ApplicantsStatusMessage.css";
import LabelInput from "../Inputs/LabelInput";
import LabelTextAreaInput from "../Inputs/LabelTextAreaInput";
// import LabelDropDownInput from "../Inputs/LabelDropDownInput";
import ApplicantsStatusFieldContainer from "./ApplicantsStatusField/ApplicantsStatusFieldContainer";
import UpdateApplicantsStatusContainer from "./UpdateApplicantsStatus/UpdateApplicantsStatusContainer";

class ApplicantsStatusMessage extends Component {
  componentDidMount(){
    document.body.style.overflow = "hidden"
  }
  componentWillUnmount(){
    document.body.style.overflow = "auto"
  }


  constructor(props) {
    super(props);
    this.state = {
      statusValue: props.business_application.application_status_id,
      statusValueFlag: "",
      statusError: "",
      statusName: props.business_application.to_status_code,
      username:props.business_application.username,
      notes: props.business_application.note?props.business_application.note:"",
      notesFlag: "",
      notesError: "",
      message:"",
      change:false
    };
    //console.log("test business_application:", props.business_application)
  }
  setStatusName = (name) =>{
  
    this.state.statusName = name
  }
  applicantsNameHandler = () => {
    alert("applicantsNameHandler Works");
  };
  applicantsMesageHandler = (e) => {
    this.setState({
      notes: e.target.value,
      notesFlag:false
    })
    if(e.target.value != this.props.business_application.note)
    this.state.change = true
    else
    this.state.change = false
  };
  applicantsDisplayHandler = () => {
    alert("applicantsDisplayHandler Works");
  };
  applicantsDropDownHandler = statusData => {
    // alert(JSON.stringify(statusData.target.value))
    try {
      const res = JSON.parse(statusData.target.value);
      
      // console.log("statusData :", res);
      // this.state.change = true
      this.setStatusName(res.to_status_code)
      this.setState({
        statusValue: res.status_id,
        statusValueFlag: false,
        message:res.message,
        change:true
      });
    } catch (e) {
      var change = true
      if(this.state.notes == this.props.business_application.note){

        change = false
        
      }
      this.setStatusName(this.props.business_application.to_status_code)
      this.setState({
        change:change,
        statusValue: this.props.business_application.application_status_id
      })
    }
  };
  setStatusError = (err = "Field Required") => {

    this.setState({
      statusValueFlag: true,
      statusError: err
    });

  };

  setNotesError = (err = "Field Required") => {

    this.setState({
      notesFlag: true,
      notesError: err
    });

  };
  render() {
    return ReactDOM.createPortal(
      <div className="asm-main-box">
        <i id="colseBtn" className="material-icons asm-icon" onClick={this.props.onClick}>
              close
            </i>
        <div className="asm-container">
          <div className="asm-main-heading">
            <h2 className="asm-heading">Applicant Messaging Status</h2>
            <i id="colseBtn" className="material-icons asm-icon" onClick={this.props.onClick}>
              close
            </i>
          </div>
          <div className="asm-content-boc">
            <div className="asm-name-input">
              <LabelInput
                label="Business Name:"
                type="text"
                id="businessName"
                inputValue={this.props.business_application.business_name}
                readOnly="readOnly"
                onChange={this.applicantsNameHandler}
              />
            </div>

            <div className="asm-text-message">
              <LabelTextAreaInput
                label="Message"
                inputValue={this.state.notes}
                name="Message"
                id="message"
                onChange={this.applicantsMesageHandler}
                flag={this.state.notesFlag}
                errorMsg={this.state.notesError}
                maxLength = {250}
              />
            </div>
            {/* <div className="asm-display-box">
              <div className="asm-display-input">
                <LabelInput
                  label="Display for"
                  type="text"
                  inputValue=""
                  onChange={this.applicantsDisplayHandler}
                />
              </div>
              <div className="asm-display-text">
                <label className="ltai_label">days from today</label>
              </div>
            </div> */}
            <ApplicantsStatusFieldContainer
              label="or While Status Is"
              onChange={this.applicantsDropDownHandler}
              id="status_id"
              name="to_status_code"
              msgPopup={this.props.msgPopup}
              inputValue={this.state.statusValue}
              errorMsg={this.state.statusError}
              flag={this.state.statusValueFlag}
              application_status_id={this.props.business_application.application_status_id}
            
              
            />
            <UpdateApplicantsStatusContainer
              onClick={this.props.onClick}
              statusValue={this.state.statusValue}
              business_application={this.props.business_application}
              setStatusError={this.setStatusError}
              setNotesError = {this.setNotesError}
              message = {this.state.message}
              notes={this.state.notes}
              statusName={this.state.statusName}
              username={this.state.username}
              change = {this.state.change}
            />
          </div>
        </div>
      </div>,
      document.getElementById("message_portal")
    );
  }
}

export default ApplicantsStatusMessage;
