import React, { Component } from 'react'
import './EnrollmentForm.css'
import {BusinessEnrollment_Status} from './constants';

import EnrollmentForm from "./EnrollmentForm"
import CofirmationContract from "../ConfirmationContract/ConfirmationContract"
export class EnrollmentFormView extends Component {
    constructor()
    {
        super();
        this.state = {
          
        }

    }
    getScreen(status)
    {
        switch (status) {
   
            case BusinessEnrollment_Status.BusinessEnrollment_Post.NEW:
                return (
                  <EnrollmentForm saveBusinessEnrollment={this.props.saveBusinessEnrollment} businessEnrollment_post_status={this.props.businessEnrollment_post_status} BusinessEnrollment={this.props.BusinessEnrollment} />
    
                );
                case BusinessEnrollment_Status.BusinessEnrollment_Post.SUCCESS:
                return (
                  <CofirmationContract/>
                );
                break;
                case BusinessEnrollment_Status.BusinessEnrollment_Post.FAILURE:
                return (
                  <div>
                  <EnrollmentForm saveBusinessEnrollment={this.props.saveBusinessEnrollment} businessEnrollment_post_status={this.props.businessEnrollment_post_status} BusinessEnrollment={this.props.BusinessEnrollment} />

                  </div>
                );
                break;
                case BusinessEnrollment_Status.BusinessEnrollment_Post.LOADING:
                return (
                  <div style={{textAlign:"center"}}>
                    <h1> Loading </h1>
                  </div>
                );
                break;
                  default:
                  break;
        }

    }
    render() {
        return (
            this.getScreen(this.props.businessEnrollment_post_status)
        )
    }
}

export default EnrollmentFormView
