import { BusinessCategoryStatus, BusinessCategoryActions } from './BusinessCategoryConstants';

const BusinessCategory_initialState = {
    BusinessCategory_Status: BusinessCategoryStatus.BusinessCategory_GET.NEW,
    BusinessCategory_Error: '',
    BusinessCategory: [],
}
export default  function  (state = BusinessCategory_initialState, action) {
    switch (action.type) {
        case BusinessCategoryActions.BusinessCategory_GET.LOADING:
            return { ...state, BusinessCategory_Status: BusinessCategoryStatus.BusinessCategory_GET.LOADING }
            case BusinessCategoryActions.BusinessCategory_GET.NEW:
                return { ...state, BusinessCategory_Status: BusinessCategoryStatus.BusinessCategory_GET.NEW }
    
        case BusinessCategoryActions.BusinessCategory_GET.FAILED:
            return { ...state,  BusinessCategory_Status: BusinessCategoryStatus.BusinessCategory_GET.FAILED}
        case BusinessCategoryActions.BusinessCategory_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  BusinessCategory_Status: BusinessCategoryStatus.BusinessCategory_GET.SUCCESS, BusinessCategory:action.payload}
        
        default:
            return { ...state,
            }
    }
}
