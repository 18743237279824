import { connect } from "react-redux";
import GetFaceValuesView from "./GetFaceValues_View";
import {GetFaceValuesServer} from "./server"
// import {GetFaceValuesActions} from './GetFaceValuesconstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        GetFaceValues_GET_status:state.GetFaceValuesReducer.GetFaceValues_Status,
        GetFaceValues:state.GetFaceValuesReducer.GetFaceValuesDetails,

       // GetFaceValuesIndex:state.GetFaceValuesReducer.GetFaceValuesIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getGetFaceValues: (id) => {

           dispatch( GetFaceValuesServer.getGetFaceValues(id) )
        },
        
    }
};
const GetFaceValuesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetFaceValuesView)

export default GetFaceValuesContainer;