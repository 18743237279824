export const GetDisplayTypesActions= {
	
	GetDisplayTypes_GET :{
		NEW:"GetDisplayTypes_GET_NEW",
		SUCCESS:"GetDisplayTypes_GET_SUCCESS",
		FAILED:"GetDisplayTypes_GET_FALIURE",
		LOADING:"GetDisplayTypes_GET_LOADING",
		UPDATE:"DisableGetDisplayTypes_Update_SUCCESS",
	},
	
}


export const GetDisplayTypesStatus ={

	GetDisplayTypes_GET :{
		NEW:"GetDisplayTypes_GET_NEW",
		SUCCESS:"GetDisplayTypes_GET_SUCCESS",
		FAILED:"GetDisplayTypes_GET_FALIURE",
		LOADING:"GetDisplayTypes_GET_LOADING",
	}

}
