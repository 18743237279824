import React, { Component } from 'react'
import '../CheckBoxSingle/CheckBoxSingle.css'

class CheckBoxSingle extends Component {
    render() {
        const {
            label = "Expired Banners",
            onChange,
            value = false,
            inputType='checkbox',
            disabled = false,
            id="checkbox",
            name="expiredbanner"
          } = this.props;
        //   alert(value)
        return (
            <div className="check-box">

                <label className="check-wrap checkbox responsive">
                    <input type={inputType} className="hidden" id={id} name={name} checked={value} onChange= {onChange} disabled ={disabled} />
                    <span className="checkmark1" id={id}></span>
                    <label className="checkbox-label" htmlFor={id}>{label}</label>
                </label>
            </div>
        )
    }
}

export default CheckBoxSingle
