import React, { Component, Fragment } from "react";
// import { withRouter } from "react-router-dom";
import MessageHistoryModel from "../../Cards/MessageHistoryModel/MessageHistoryModel" 
import Empty from "../../Utils/Empty";
// import dateFormat from "dateformat";
import moment from "moment"
export class ListOfMessageHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgPopup: false,
      business_application: null
    };
  }

  // todo: msgPopupHandler onClick Function ...................

  render() {
    // console.log("MessageHistoryProps:", this.props.MessageHistory);
    // const { msgPopup } = this.state;
    const { MessageHistory } = this.props;
    return (
      <Fragment>
        {MessageHistory.length >0 ?
      <div className="msge-scroll">
        {MessageHistory.map((item,index) => {
            
            return (
        <div className="msge-row" key={index}>
        <div className="mesg-row-1 body-t border">{item.sent_to.toString().split(',')[0]}</div>
            <div className="mesg-row-1 body-t border">{moment(item.last_updated).format("MM/DD/YYYY")
            // dateFormat(item.last_updated,"mm/dd/yyyy")
            }</div>
        <div className="mesg-row-3 body-t border">
        {item.message}
        </div>
        <div className="mesg-row-1 body-t"><MessageHistoryModel item = {item}/></div>
      </div>
           )
         })}
      
      </div>:<Empty message = "Message history is empty"/>
    }
      </Fragment>
    );
  }
}

export default ListOfMessageHistory;
