import { connect } from "react-redux";
import ChangePasswordView from "./ChangePassword_View";
import {ChangePasswordServer} from "./server"
import {ChangePasswordActions} from './ChangePasswordConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        ChangePassword_POST_status:state.ChangePasswordReducer.ChangePassword_Status,
        ChangePassword:state.ChangePasswordReducer.ChangePasswordDetails,
        ChangePassword_Message:state.ChangePasswordReducer.ChangePassword_Message
       // ChangePasswordIndex:state.ChangePasswordReducer.ChangePasswordIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        ChangePassword: (data) => {

           dispatch( ChangePasswordServer.ChangePassword(data) )
        },
        resetReducerState:()=>{
            dispatch({type:ChangePasswordActions.ChangePassword_POST.NEW})
        }
    }
};
const ChangePasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordView)

export default ChangePasswordContainer;