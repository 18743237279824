import { connect } from "react-redux";
import CouponsInventoryView from "./CouponsInventory_View";
import {CouponsInventoryServer} from "./server"
import {CouponsInventoryActions} from './CouponsInventoryConstants'
const mapStateToProps = state => {
  //  console.warn(state.CouponsInventoryReducer.CouponsInventory)
    return {
        CouponsInventory_GET_status:state.CouponsInventoryReducer.CouponsInventory_Status,
        CouponsInventory:state.CouponsInventoryReducer.CouponsInventory,
        CouponsInventory_Error:state.CouponsInventoryReducer.CouponsInventory_Error,
        ref_data:state.CouponsInventoryReducer.ref_data
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getCouponsInventory: (data) => {

           dispatch( CouponsInventoryServer.getCouponsInventory(data) )
        },
        resetReducerState:() =>{
            dispatch({type:CouponsInventoryActions.CouponsInventory_GET.NEW})
        }
    }
};
const CouponsInventoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponsInventoryView)

export default CouponsInventoryContainer;