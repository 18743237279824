//******************Exchange Rate Server Calls******************;

import { BannerListActions } from './BannerListConstants';
import store from '../../Store/store';
import { ROOT_URL, getCookie } from '../../Config/Config';
import { failure_messages } from 'Messages';

export const BannerListServer = {
  //  getBannerList:getBannerList,
  getBannerList: getBannerList,
  //  updateBannerList:updateBannerList
};

//******************Insert BannerLists******************;

function getBannerList(data) {
  const request = fetch(ROOT_URL + '/api/banners/getAllBanners', {
    method: 'Post',
    mode: 'cors',
    headers: {
      authorization: getCookie('admin_token_partner'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      response.json().then((response) => {
          if(data.setLoading)
          data.setLoading(false)
        if (response.status == '200') {
          data.setBannerList(response.data, [])
          store.dispatch({
            type: BannerListActions.BannerList_GET.SUCCESS,
            payload: response.data,
            weekBanners: [],
          });
          return;
        } else {
          
          store.dispatch({
            type: BannerListActions.BannerList_GET.FAILED,
            error: response.error,
          });
          return;
        }
      });
    })
    .catch((error) => {
      if(data.setLoading)
      data.setLoading(false)
      store.dispatch({
        type: BannerListActions.BannerList_GET.FAILED,
        error: failure_messages.Businessparticipants,
      });
    });
  return { type: BannerListActions.BannerList_GET.LOADING };
}

//******************Get BannerLists******************;

//******************Update BannerList******************;

// function getBannerList(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.BannerList.length>0)
//              store.dispatch({type:BannerListActions.BannerList_Get.SUCCESS,payload:response.BannerList});
//              else
//              store.dispatch({type:BannerListActions.BannerList_GET.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:BannerListActions.BannerList_Get.FAILED});
//                return ;
//                }
//             });
//            }).catch((error) => {
//             store.dispatch({type:BannerListActions.BannerList_Get.FAILED});
//           })
//        return {type:BannerListActions.BannerList_GET.LOADING}

//     };

// function updateBannerList(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:BannerListActions.BannerList_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:BannerListActions.BannerList_GET.FAILED});
//         return ;
//         }
//      });
//     }).catch((error) => {
//       store.dispatch({type:BannerListActions.BannerList_GET.FAILED});
//     })
// return {type:BannerListActions.BannerList_GET.LOADING}

// };
