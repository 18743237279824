export const RetailerListActions= {
	
	RetailerList_GET :{
		NEW:"RetailerList_GET_NEW",
		SUCCESS:"RetailerList_GET_SUCCESS",
		FAILED:"RetailerList_GET_FALIURE",
		LOADING:"RetailerList_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const RetailerListStatus ={

	RetailerList_GET :{
		NEW:"RetailerList_GET_NEW",
		SUCCESS:"RetailerList_GET_SUCCESS",
		FAILED:"RetailerList_GET_FALIURE",
		LOADING:"RetailerList_GET_LOADING",
	}

}
