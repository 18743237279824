import { connect } from "react-redux";
import UpdateApplicantsStatus_View from "./UpdateApplicantsStatus_View";
import { UpdateApplicantsStatusServer } from "./server";
import { UpdateApplicantsStatusActions } from "./UpdateApplicantsStatusConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    UpdateApplicants_status:
      state.UpdateApplicantsStatusReducer.UpdateApplicantsStatus_Status,
    // UpdateApplicantsStatus_post_status:state.NJT_UpdateApplicantsStatus_Reducer.UpdateApplicantsStatus_Status,
    UpdateApplicantsStatusDetails: state.UpdateApplicantsStatusReducer.UpdateApplicantsStatusDetails,
    UpdateMsg: state.UpdateApplicantsStatusReducer.UpdateMsg
  };
};
const mapDispatchToProps = dispatch => {
  return {
    UpdateApplicantsStatus: (data) => {
      dispatch(UpdateApplicantsStatusServer.UpdateApplicantsStatus(data));
    },
    ResetReducerState:()=>{
      dispatch({type:UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.NEW})
    }
  };
};
const UpdateApplicantsStatusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateApplicantsStatus_View);

export default UpdateApplicantsStatusContainer;
