import React, { Component } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
// import
// import { ArrowForwardIos } from "@material-ui/icons";
// import Axios from 'axios';
// import { ROOT_URL } from "../../../constants/config";
import "./CountryAddress.css";
import postalCodes from "postal-codes-js";
import LabelInput from "../Inputs/LabelInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const USContry = { id: 233, name: "United States", iso2: "US" };
export default class CountryAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Allcountry: [],
      SelecterCity: [],
      SelecterCode: null,
      Allcity: [],
      Zipcode: "",
      ZipcodeErrorMsg: "",
      ZipcodeFlag: "",
      allowedCountries: [],

      countryCode: "",
      //   selectCountry: {
      //     name: "United State",
      //   },
      selectCountry: "us",
      selectstate: {
        name: "",
      },
      SelectedCity: {
        name: "",
      },
      Zipcode: null,
      EligibleCategory: this.props.PrevData?.patientOccupation
        ? this.props.PrevData?.patientOccupation
        : "",
      NextLoading: false,
    };

    // this.getAllCountry();
  }

  zipCodeHandler = (e) => {
    // alert("hell")
    var Zipcode = e.target.value;
    // var reg = /^[a-zA-Z0-9_ ,-.'#]+$/;
    const selectedCountry = this.state.selectCountry;

    const validReg = postalCodes.validate(selectedCountry, Zipcode);
    //  reg.test(Zipcode);
    //alert(typeof validReg)
    if (validReg + "" == "true") {
      // alert("code is valid")
      this.setState({ ZipcodeFlag: false });
    } else {
      // alert("code is in-valid")
      this.setState({
        ZipcodeFlag: true,
        ZipcodeErrorMsg: "Enter Valid Zip Code",
      });
    }
    // if(e.target.value == ""){
    //   this.setState({ ZipcodeErrorMsg: "" });
    // }
    this.props.zipCodeHandler(e.target.value);
    this.setState({ Zipcode: e.target.value });
  };
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  UNSAFE_componentWillMount() {
    // console.log("kajsdkjasdkjasd", this.props)
    let val = {
      iso2: "2",
      name: this.props.countryName,
    };

    this.getCountriesIntially();
    // this.getAllCountry();

    // this.StatePopulatedcountrybase(val.iso2, val);
    // this.setState({
    //   selectCountry:val
    // },()=>{
    //   // this.StatePopulatedcountrybase(val.iso2, val);
    // })
  }

  getCountriesIntially = () => {
    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "VnR4QWdkM3RLWk9OZTFDelBTdUljZDdCMXRiQ3lKZEVuRXUwUDVpbQ=="
    );
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("coutnries", result);

        if (this.props.countryName && this.props.countryName !== "") {
          // let countryISO2 = null
          let countryISO2 = result.find((item) => {
            return (
              item.name == this.props.countryName ||
              item.iso2.toLowerCase() == this.props.countryName.toLowerCase()
            );
          });

          console.log("iso2", countryISO2);

          if (countryISO2) {
            let iso2 = countryISO2.iso2;

            this.StatePopulatedcountrybase(iso2, { iso2: iso2 });
          }
        }

        this.setState(
          {
            Allcountry: result,
            Allcity: [],
          },
          () => {
            if (
              this.props.BusinessApplicationData &&
              this.props.state !== "" &&
              this.props.state
            ) {
              this.props.setCoutry(USContry.name);

              this.StatePopulatedcountrybase(USContry.iso2, USContry);
            }
          }
        );
      })
      .catch((error) => {
        if (
          this.props.BusinessApplicationData &&
          this.props.state !== "" &&
          this.props.state
        ) {
          this.setState({
            countryCode: USContry,
            Zipcode: "",
          });
          this.props.setCoutry(USContry.name);

          this.StatePopulatedcountrybase(USContry.iso2, USContry);
        }
      });
  };

  getAllCountry = () => {
    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "VnR4QWdkM3RLWk9OZTFDelBTdUljZDdCMXRiQ3lKZEVuRXUwUDVpbQ=="
    );
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("coutnries", result);

        this.setState({
          Allcountry: result,
          SelecterCity: [],
          Allcity: [],
        });
      })
      .catch((error) => console.log("error", error));
  };

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  StatePopulatedcountrybase = (v, selectCountry) => {
    console.log("country", { v, selectCountry });
    // alert(this.props.phone)
    // this.state.selectCountry = selectCountry ? selectCountry.iso2 : ''

    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "VnR4QWdkM3RLWk9OZTFDelBTdUljZDdCMXRiQ3lKZEVuRXUwUDVpbQ=="
    );
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    // Pass Country Code -- Eg: Country Code : IN
    fetch(
      `https://api.countrystatecity.in/v1/countries/${v}/states`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // alert(JSON.stringify(result))
        console.log("states", result);

        if (result.error == "Not found.") {
          // alert("Hello")

          this.setState({
            SelecterCity: [],
            SelecterCode: null,
            Allcity: [],
            selectstate: { name: null },
          });
        } else {
          let clearFlag = true;
          let sortedArray = result.sort((a, b) => {
            if (a.iso2 === this.props.state || b.iso2 === this.props.state)
              clearFlag = false;
            return a.name.localeCompare(b.name);
          });

          if (clearFlag) {
            this.props.setCoutryState("");
            // this.props.phoneNumberHandler("")
          }
          // alert(JSON.stringify(sortedArray[0]))
          this.setState(
            {
              Allcity: [],
              selectstate: { name: null },
              SelecterCity: sortedArray,
              SelecterCode: v,
            },
            () => {
              console.log("these are states", this.state);
            }
          );
        }
      })
      .catch((error) => console.log("error", error));
  };

  CityPopulatedCstatebase = (CountryCode, selectstate) => {
    console.log(CountryCode, selectstate, "ajsdkjasdkjasd");
    this.setState({
      selectstate: selectstate,
    });
    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "VnR4QWdkM3RLWk9OZTFDelBTdUljZDdCMXRiQ3lKZEVuRXUwUDVpbQ=="
    );
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    // Pass Country & State Code -- Eg: Country Code : IN & State Code : MH
    fetch(
      `https://api.countrystatecity.in/v1/countries/${this.state.SelecterCode}/states/${CountryCode}/cities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("kajsdkajsdkajsd", result);
        if (result.error == "Not found.") {
          this.setState({
            Allcity: [],
          });
        } else {
          console.log("akjsdhkajsdkjasd", result);
          this.setState({
            Allcity: result,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    const {
      Zipcode,
      ZipcodeErrorMsg,
      ZipcodeFlag,
      // errorMsg = 'errorMsg',
      Allcountry,
      // flag = false,
    } = this.state;
    const {
      // cityErrorMsg,
      // cityFlag,
      countryError,
      countryFlag,
      stateErrorMsg,
      stateFlag,
      phone,
      phoneNumberErrorMsg,
      // phoneNumberFlag,
      phoneNumberHandler,
      state,
    } = this.props;
    console.log("====================================");
    console.log(this.state, this.props);
    console.log("====================================");
    console.log("akjsdjasdkjasdjasd", this.props);

    console.log("state values", this.state.SelecterCity);

    return (
      <div className="CheckVaccine">
        <div className="b-b-p">
          <label className="input-lab country">
            Country:
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="handle-input-fff">
            <Autocomplete
              className="EligbnbleAutocomplete"
              id="comboBoxCountry"
              options={Allcountry}
              onChange={(event, newValue) => {
                //alert(newValue.iso2)
                this.props.zipCodeHandler("");
                // this.setState({Zipcode:'', phone:''})

                // alert(JSON.stringify(newValue))

                this.setState({
                  countryCode: newValue,
                  Zipcode: "",
                });
                if (newValue?.iso2) {
                  // phoneNumberHandler('')
                  // alert(this.props.state)
                  // alert(newValue?.iso2)
                  // alert(JSON.stringify(newValue))

                  this.props.setCoutry(newValue.name);
                  // if(newValue.iso2 !=="US")
                  // this.props.setCoutryState('')
                  this.StatePopulatedcountrybase(newValue.iso2, newValue);
                } else {
                  this.props.setCoutry("");
                  this.props.setCoutryState("");
                  this.StatePopulatedcountrybase("");
                }
              }}
              defaultValue={{ name: this.props.countryName }}
              // inputValue="United States"
              renderOption={(option) => (
                <React.Fragment>
                  <span>{this.countryToFlag(option.iso2)}</span>
                  {option.name} ({option.iso2})
                </React.Fragment>
              )}
              getOptionLabel={(option) => {
                if (option.hasOwnProperty("name")) {
                  return option.name;
                }
                return option;
              }}
              // getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            <p
              className="drop_down_input_error_text auto-select"
              style={{ display: countryFlag ? 'block' : 'none' }}
            >
              {countryError}
            </p>
          </div>
        </div>

        <div className="b-b-p state">
          <label className="input-lab state">
            State:
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="handle-input-fff">
            <Autocomplete
              // freeSolo
              // disabled={this.state.SelecterCity.length == 0 ? true : false}
              className="EligbnbleAutocomplete"
              id="comboBoxState"
              onChange={(event, newValue) => {
                // alert(JSON.stringify(newValue))
                if (newValue?.iso2) {
                  this.props.setCoutryState(newValue.iso2);
                  // this.CityPopulatedCstatebase(newValue.iso2, newValue );
                } else {
                  this.props.setCoutryState("");
                  // this.CityPopulatedCstatebase('');
                }
              }}
              // getOptionSelected={(option, value) =>
              // option.name === this.state.selectstate.name
              // }
              renderOption={(option) => (
                <React.Fragment>
                  {option.name} ({option.iso2})
                </React.Fragment>
              )}
              options={this.state.SelecterCity}
              defaultValue={state}
              inputValue={state}
              getOptionLabel={(option) => {
                if (option.hasOwnProperty("name")) {
                  return option.name;
                }
                return option;
              }}
              // getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            <p
              className="drop_down_input_error_text auto-select"
              style={{ display: countryFlag ? 'block' : 'none' }}
              
            >
              {stateErrorMsg}
            </p>
          </div>
        </div>

        <div className="b-b-p city">
          <LabelInput
            type="text"
            maxLength="128"
            name="city"
            star="*"
            id="city"
            label="City:"
            inputValue={this.props.city}
            flag={this.props.cityFlag}
            errorMsg={this.props.cityErrorMsg}
            // containerStyle={{ marginTop: "20px" }}
            onChange={this.props.cityHandler}
          />
        </div>

        <div className="b-b-p zip_code">
          <LabelInput
            type="text"
            star="*"
            //   id={this.state.businessId}
            //   maxLength={50}
            label="Zip Code:"
            name="zip"
            id="zipCode"
            inputValue={Zipcode ? Zipcode : this.props.Zipcode}
            errorMsg={
              this.props.Zipcode == ""
                ? this.props.ZipcodeErrorMsg
                : ZipcodeErrorMsg
            }
            flag={
              this.props.Zipcode == "" ? this.props.ZipcodeFlag : ZipcodeFlag
            }
            onChange={this.zipCodeHandler}
            //   disabled={this.state.disableFields}
          />
        </div>

        <div className="box_phone_profile">
          <div className="b-b-p phone">
            <label className="input-lab phone">
              Phone Number:
              <span style={{ color: "red" }}>*</span>
            </label>
            <PhoneInput
              containerClass="phoneNumber"
              inputClass="phoneNumberInput"
              buttonClass="phoneButton"
              dropdownClass="phoneDropdown"
              country={'us'}
              //onlyCountries={[...this.state.allowedCountries, this.state.selectCountry.toLowerCase()]}
              value={phone}
              onChange={(phone) => {
                console.log("kjasdkjasdkjasd", phone);
                this.setState({ phone });
                phoneNumberHandler(phone);
              }}
            />
          </div>
          <p
            className="drop_down_input_error_text auto-select phone-ca"
            style={{ display: this.props.phoneNumberFlag ? 'block' : 'none' }}
          >
            {phoneNumberErrorMsg}
          </p>
        </div>
      </div>
    );
  }
}
