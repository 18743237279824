export const InvoicesActions= {
	
	Invoices_GET :{
		NEW:"Invoices_GET_NEW",
		SUCCESS:"Invoices_GET_SUCCESS",
		FAILED:"Invoices_GET_FALIURE",
		LOADING:"Invoices_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const InvoicesStatus ={

	Invoices_GET :{
		NEW:"Invoices_GET_NEW",
		SUCCESS:"Invoices_GET_SUCCESS",
		FAILED:"Invoices_GET_FALIURE",
		LOADING:"Invoices_GET_LOADING",
	}

}
