import { connect } from "react-redux";
import InvoiceDetailsView from "./InvoiceDetails_View";
import {InvoiceDetailsServer} from "./server"
import {InvoiceDetailsActions} from './InvoiceDetailsConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        InvoiceDetails_GET_status:state.InvoiceDetailsReducer.InvoiceDetails_Status,
        InvoiceDetails:state.InvoiceDetailsReducer.InvoiceDetailsDetails,
        InvoiceDetails_Error:state.InvoiceDetailsReducer.InvoiceDetails_Error
       // InvoiceDetailsIndex:state.InvoiceDetailsReducer.InvoiceDetailsIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getInvoiceDetails: (data) => {

           dispatch( InvoiceDetailsServer.getInvoiceDetails(data) )
        },
        resetReducerState:()=>{
            dispatch({type:InvoiceDetailsActions.InvoiceDetails_GET.NEW})
        }
    }
};
const InvoiceDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceDetailsView)

export default InvoiceDetailsContainer;