export const ApplicantsStatusFieldActions= {
	
	
	ApplicantsStatusField_Get :{
		NEW:"ApplicantsStatusField_Get_NEW",
		SUCCESS:"ApplicantsStatusField_Get_SUCCESS",
		FAILED:"ApplicantsStatusField_Get_FALIURE",
		LOADING:"ApplicantsStatusField_Get_LOADING",
		NOT_AUTHORIZED: "ApplicantsStatusField_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ApplicantsStatusField_Post :{
		NEW:"ApplicantsStatusField_Post_NEW",
		SUCCESS:"ApplicantsStatusField_Post_SUCCESS",
		FAILED:"ApplicantsStatusField_Post_FALIURE",
		LOADING:"ApplicantsStatusField_Post_LOADING",
		NOT_AUTHORIZED: "ApplicantsStatusField_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ApplicantsStatusFieldStatus ={

	ApplicantsStatusField_Get :{
		NEW:"ApplicantsStatusField_Get_NEW",
		SUCCESS:"ApplicantsStatusField_Get_SUCCESS",
		FAILED:"ApplicantsStatusField_Get_FALIURE",
		LOADING:"ApplicantsStatusField_Get_LOADING",
		NOT_AUTHORIZED: "ApplicantsStatusField_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ApplicantsStatusField_Post :{
		NEW:"ApplicantsStatusField_Post_NEW",
		SUCCESS:"ApplicantsStatusField_Post_SUCCESS",
		FAILED:"ApplicantsStatusField_Post_FALIURE",
		LOADING:"ApplicantsStatusField_Post_LOADING",
		NOT_AUTHORIZED: "ApplicantsStatusField_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
