import React, { Component, Fragment } from "react";
//import './DeleteCoupon.css'
import { DeleteCouponStatus } from "./DeleteCouponConstants";
import { withCookies } from "react-cookie";
import Loading from "../../Utils/Loading";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteCoupon from "./DeleteCoupon";
import {ref_status} from "RefRecordsConstants"
export class DeleteCouponView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      retailer_details: props.cookies.get("BusinessProfileData"),
      DeleteCoupon_Message:""
    };
    // this.props.resetReducerState();
  }
  closeSnackBar = () => {
    this.setState({
      open: false,

    });
  };
  
  showSnackBar = (text) => {
    this.setState({
      open: true,
      DeleteCoupon_Message:text
    });
  };

  
  componentWillUnmount() {
    this.props.resetReducerState();
  }


  DeleteCoupon = () => {

  {  var BatchStatus = this.props.ref_data.BatchStatus.find(
      (item) => item.status_id === ref_status.DELETED
    );

    var data = {
      to_status_code: BatchStatus.to_status_code,
      coupon_batch_id: this.props.coupon.coupon_batch_id,
      batch_status: ref_status.DELETED,
      display_color:BatchStatus.display_color,
      BussinessId: this.state.retailer_details.business_id,
    };
    // alert(JSON.stringify(data))                    disable = {this.props.ref_status.EXPIRED ==this.props.status_id || this.props.ref_status.CLOSED == this.props.status_id}
    // alert(JSON.stringify(data))
    this.state.loading = true;
    this.props.handleDeleteCoupon(data);
  }
  
  };
  
  getScreen(status) {
    switch (status) {
      case DeleteCouponStatus.DeleteCoupon_Post.NEW:
        return (
          <DeleteCoupon
            DeleteCoupon={this.DeleteCoupon}
            ref_data={this.props.ref_data}
            coupon={this.props.coupon}
            showSnackBar={this.showSnackBar}
          />
        );

      case DeleteCouponStatus.DeleteCoupon_Post.SUCCESS:
        if(this.state.loading)
       { 

         if(this.props.resetFaceValue)
        this.props.resetFaceValue()

        this.state.loading = false;
         var updatedCoupon = this.props.coupon
        updatedCoupon.to_status_code = this.props.DeleteCouponDetails.to_status_code
        this.props.updateSelectedCoupon(updatedCoupon)
        updatedCoupon.status_id =  this.props.DeleteCouponDetails.batch_status

        const data = {
          business_id: this.props.cookies.get("BusinessProfileData").business_id
        }
        // alert(this.props.resetFaceValue)
        // this.props.resetFields()
        // this.props.getCouponsInventory(data)

       }
        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="success"
              message={this.props.DeleteCoupon_Message}
            /> */}

            {/* <DeleteCoupon
              DeleteCoupon={this.DeleteCoupon}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
              showSnackBar={this.showSnackBar}
            /> */}
          </Fragment>
        );
        break;
      case DeleteCouponStatus.DeleteCoupon_Post.FAILED:
        this.state.loading = false;
          // alert("test")
        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="error"
              message={this.props.DeleteCoupon_Message}
            /> */}

            <DeleteCoupon
              DeleteCoupon={this.DeleteCoupon}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
              showSnackBar={this.showSnackBar}
            />
          </Fragment>
        );
        break;
      case DeleteCouponStatus.DeleteCoupon_Post.LOADING:
        this.state.loading = true
        this.state.open = true;
        return  <button className={"inv-button-b "}>
           <Loading color={"white"}/>
            </button>
      default:
        break;
    }
  }
  render() {

    return <>
      <Snackbar open={this.state.open} autoHideDuration={2000} onClose={this.closeSnackBar}>
        <Alert onClose={this.closeSnackBar} severity="error">
        {this.state.DeleteCoupon_Message}
        </Alert>
      </Snackbar>
   
    {this.getScreen(this.props.DeleteCoupon_post_status)}
    </>

  }
  
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default withCookies(DeleteCouponView);
