import React, {useState} from "react";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DisableBusinessContainer from "./DisableBusiness/DisableBusinessContainer";
// import UserEmpty from '../UserManagement/UserEmpty';
import "./BusinessList.css";
import SimpleInput from "../Inputs/SimpleInput";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RotateLoading from "../Utils/RotateLoading";
import CheckBoxSingle from '../Inputs/CheckBoxSingle/CheckBoxSingle';

const columns = [
  { id: "no", label: "Sr. #", maxWidth: "8%", align: "left" },
  { id: "code", label: "Business Name", maxWidth: "36%", align: "left" }
  //   { id: "id", label: "ID", maxWidth: "10%",align: "left" }
];

function createData(no, code, ListItem) {
  //   alert(id)
  //  alert(ListItem.is_disabled)
  return { no, code, ListItem };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 100,
    marginBottom: 60
  },
  tableWrapper: {
    maxHeight: 500,
    overflow: "auto"
  }
});

const BusinessList = props => {

  // const [showDisabled, setShowDisabled] = useState(false);

  var rows = [];

  if (props.BusinessList) {
    var count = 1;

    props.BusinessList.forEach(item => {
      //  alert(JSON.stringify(item))
      rows.push(createData(count, item.business_name, item));
      count++;
    });
  }

  const handleDisableCheckBox = () => {
    // alert("working")
    // setShowDisabled(!showDisabled)
    setPage(0)
    props.setShowDisabled()
    

  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  // const [searchVal, setSearchVal] = React.useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const newHandler = () => {
    props.cookies.remove("BusinessProfileData", { path: "/" });
    props.cookies.remove("BusinessApplicationData", { path: "/" });
      
    props.history.push({
      pathname: "/business_profile"
    });
  };

  // todo: homeHandler onClick function ..........................

  // const homeHandler = () => {
  //   props.history.push({
  //     pathname: "/Admin"
  //   });
  // };
  // todo: disableHandler onClick function ..........................
  const changeRowIsDisabled = (business_id, is_disabled) => {
    var index = -1;
    for (var i = page * rowsPerPage; i < rows.length; i++) {
      if (rows[i].ListItem.business_id === business_id) {
        index = i;
        break;
      }
    }
    if (index >= 0) rows[index].ListItem.is_disabled = is_disabled;

    props.refreshBusinessList()
  };
  const quotasHandler = ListItem => {
    if (!ListItem.is_disabled && ListItem.coupon_contract_id) {
      props.cookies.set("BusinessProfileData", ListItem, { path: "/" });

      props.history.push({
        pathname: "./busines_coupon_quotas"
      });
    }
  };

  const messageHandler = ListItem => {
    if (!ListItem.is_disabled) {
      props.cookies.set("BusinessProfileData", ListItem, { path: "/" });
    props.history.push({
      pathname: "./Invoices"
    });
    }
  }

  const contractHandler = ListItem => {
    if (!ListItem.is_disabled) {
      props.cookies.set("BusinessProfileData", ListItem, { path: "/" });

      props.history.push({
        pathname: "./business_contract"
      });
    }
    // alert("contractHandler working");
  };

  const businessHandler = ListItem => {
    if (!ListItem.is_disabled) {
      props.cookies.remove("BusinessApplicationData", {path:'/'})   
      props.cookies.set("BusinessProfileData", ListItem, { path: "/" });

      //localStorage.setItem("BusinessProfileData", JSON.stringify(ListItem));

      props.history.push({
        pathname: "/business_profile"
        //  state: { BusinessProfileData: props.BusinessList[index] }
      });
    }
  };
  //alert('test')
  // alert(setShowDisabled, "toggle_disabled")
  return (
    <div className="bl-main-box-wrap search-add-new">
      <div className="bl_container list-b-bo">
        {/* <h1>Business List</h1> */}
        <div className="bl_header-b22">
          
          <div className="bl-heading-box">
          <h1>Business Participants</h1>
        
          </div>
         
          <div className="bl-new-user">
          <button id="createNew" className="bl_btn bl_btn2" onClick={newHandler}>
            New
          </button>
          </div>
          {/* <button className="bl_btn bl_btn0" onClick={homeHandler}>
          Admin - Home
        </button> */}
        </div>
    <div className="sreach-fff search-partner">
    <div className="usr-m-search">
            <div className="usr-search">
              <div className="label-searc">Search Users</div>
              <SimpleInput
                label="Business Name"
                id="searchName"
                onChange={(e)=>{
                  // setSearchVal
                  props.applySearchOnBusinessName(e.target.value)
                  // console.log(e.target.value, "jhadsjhasdjhasdjh")
                  // setSearchVal(e.target.value)
                }}
                inputValue={props.PrevSearchVal}
                placeholder="Business Name"
                maxLength="300"
                // containerStyle={{ height: 38, width: "90%" }}
              />
            </div>
        
            <div className="search-btn">
              <button onClick={()=>{
                // if(props.PrevSearchVal){
                  props.searchrecordByBusinessName()
                // }
                
              }} 
              className="filter-btn"
              id="searchBtn"
              >
                Search
              </button>
            </div>
    </div>
    <div className="new-partner-toggle-box">
      <div className="new-partner-toggle-box">
      {/* <div
        className="toggle-box"
        title={!props.showDisabled ? "Enable Partner" : "Disable Partner"}
      >
        <label className="switch">
          <input
            // checked={true}
            defaultChecked={props.showDisabled}
            onClick={handleDisableToggle}
            type="checkbox"
          />
          <span className="toggle round"></span>
        </label>
        
      </div> */}
      <CheckBoxSingle
        value={props.showDisabled}
        onChange={handleDisableCheckBox}
        id="showDisabledPartners"
        name='showDisabledPartners'
        label='Show Disabled Partners'
      />

      {/* <div className="toggle-lable-box">
      {!props.showDisabled ? "Hide Disabled Partners " : "Show Disabled Partners "}
      </div> */}

      </div>
    </div>
    </div>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: "bold",  maxWidth: "8%" }}>Sr. #</TableCell>
                  <TableCell align="left" style={{fontWeight: "bold", maxWidth: "36%"}}>
                    <div className="box-arrow-filter" onClick={()=>{
                      props.applySortOnBusiness()
                    }}>
                      <div className="business-name-heading">
                        Business Name
                      </div>
       
                      {props.sortyType==='asc'?<div className="face-value-sort-arrow">
                          <ArrowUpwardIcon/>
                        </div>:<div className="face-value-sort-arrow">
                        <ArrowDownwardIcon style={{ opacity: !props.sortFlag?'0.36':'1' }}/>
                      </div>}
                      
          
                </div>
                    </TableCell>
                  <TableCell align="center" style={{fontWeight: "bold"}}>Contract Terms</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold"}}>Offer Quotas</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold"}}>Invoices</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold"}}>{props.showDisabled ? "Enable/Disable Partner":"Enabled Partner"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index2) => {
                    //    alert(JSON.stringify(row.ListItem.is_disabled))
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        // tabIndex={-1}
                        key={row.ListItem.business_id}
                        style={{ cursor: "pointer" }}
                        // onClick={() => businessHandler(index)}
                      >
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id || index}
                              align={column.align}
                              onClick={() => businessHandler(row.ListItem)}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}

                        {/* <TableCell //onClick={disableHandler}
                        >
                          <DisableBusinessContainer
                            className="bl_table_btn"
                            business_id={row.ListItem.business_id}
                            changeRowIsDisabled={changeRowIsDisabled}
                            is_disabled={row.ListItem.is_disabled}
                          />
                        </TableCell> */}
                        <TableCell
                          onClick={() => contractHandler(row.ListItem)}
                          style={{textAlign: "center"}}
                        >
                          <button
                            // style={{
                            //   backgroundColor: row.ListItem.is_disabled
                            //     ? "grey"
                            //     : null
                            // }}
                            className="bl_table_btn_contract"
                            // onClick={contractHandler}
                            title="Contract Terms"
                            id="contactTerms"
                          >
                            <i className="material-icons contract-icon">
                              description
                            </i>
                          </button>
                        </TableCell>
                        <TableCell onClick={() => quotasHandler(row.ListItem)} style={{textAlign: "center"}}>
                          <button
                            // style={{
                            //   backgroundColor: row.ListItem.is_disabled
                            //     ? "grey"
                            //     : null
                            // }}
                            className="bl_table_btn_contract"
                            // onClick={disableHandler}
                            title="Coupon Quotas"
                            id="couponQuotas"
                          >
                            <i className="material-icons contract-icon">edit</i>
                          </button>
                        </TableCell>
                        <TableCell onClick={() => messageHandler(row.ListItem)} style={{textAlign: "center"}}>
                          <button
                            // style={{
                            //   backgroundColor: row.ListItem.is_disabled
                            //     ? "grey"
                            //     : null
                            // }}
                            className="bl_table_btn_contract"
                            // onClick={disableHandler}
                            title="Invoices"
                            id="invoices"
                          >
                            <i className="fa fa-money" aria-hidden="true"></i>
                          </button>
                        </TableCell>
                        <TableCell //onClick={disableHandler}
                        style={{textAlign: "center"}}
                        >
                          <DisableBusinessContainer
                            className="bl_table_btn"
                            business_id={row.ListItem.business_id}
                            changeRowIsDisabled={changeRowIsDisabled}
                            is_disabled={row.ListItem.is_disabled}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                 
              </TableBody>
            </Table>
            {rows.length===0?<div className="error-no-record">
                   <h1 className="no-record">No record found for related search</h1>
          {props.showLoader &&  <span>  <RotateLoading LoadingIcon={true} loaderStyle={{left:"10px", bottom:"-13px"}} /> </span> }
                 
                    </div>:''}
          </div>
          <TablePagination
            style={{ paddingLeft: "10px" }}
            rowsPerPageOptions={[25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <div className="bl_btn_container">
          {/* <button className="bl_btn bl_btn1" onClick={homeHandler}>
          Back To Home
        </button> */}
          {/* <button className="bl_btn bl_btn2" onClick={newHandler}>
            New
          </button> */}
        </div>
      </div>

    </div>
    
  );
};

export default withRouter(BusinessList);
