import { PausePlayCouponStatus, PausePlayCouponActions } from './PausePlayCouponConstants';

const PausePlayCoupon_initialState = {
    PausePlayCoupon_Status: PausePlayCouponStatus.PausePlayCoupon_Post.NEW,
    PausePlayCoupon_Message: '',
    PausePlayCouponDetails: {},
}
export default function (state = PausePlayCoupon_initialState, action) {
    switch (action.type) {
        case PausePlayCouponActions.PausePlayCoupon_Post.LOADING:
            return { ...state, 
            PausePlayCoupon_Status: PausePlayCouponStatus.PausePlayCoupon_Post.LOADING,PausePlayCoupon_Message: '',
            PausePlayCouponDetails: {}
         }
        case PausePlayCouponActions.PausePlayCoupon_Post.NEW:
            return { ...state, PausePlayCoupon_Status: PausePlayCouponStatus.PausePlayCoupon_Post.NEW}

        case PausePlayCouponActions.PausePlayCoupon_Post.FAILED:
           // alert("test")
            return { ...state, PausePlayCoupon_Status: PausePlayCouponStatus.PausePlayCoupon_Post.FAILED, PausePlayCouponDetails: action.payload, PausePlayCoupon_Message: action.error }
        case PausePlayCouponActions.PausePlayCoupon_Post.SUCCESS:
            return { ...state, PausePlayCoupon_Status: PausePlayCouponStatus.PausePlayCoupon_Post.SUCCESS, PausePlayCouponDetails: action.payload, PausePlayCoupon_Message: action.message }

        default:
            return { ...state }
    }
}
