import React, { Component } from 'react'
import "../../Inputs/InputRadio/SingleInputRadio.css"

class SingleInputRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {
          radioCheck: false,
        
        };
      }

    render() {
        const { radioCheck, label= "Monthly", nameinput='radio', onChange, disabled, checked, value, name, id="radio"} = this.props;
        return (
            <div className="sigle-radio-box">
            <div className="rd_btn_container">
             <label htmlFor={id} className="radio-container">
                {label}
            <input id={id} type="radio" name={nameinput} onChange= {onChange} disabled={disabled} checked={checked} value={value} name={name}/>
            <span className="checkmark"></span>
          </label>
        </div>
        </div>
        )
    }
}

export default SingleInputRadio
