import { connect } from "react-redux";
import BannerScheduleView from "./BannerScheduleView";
import {BannerScheduleServer} from "./server"
import {BannerScheduleActions} from './BannerScheduleConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        BannerSchedule_POST_status:state.BannerScheduleReducer.BannerSchedule_Status,
        BannerSchedule:state.BannerScheduleReducer.BannerScheduleDetails,
        BannerSchedule_Error:state.BannerScheduleReducer.BannerSchedule_Error,
       message:state.BannerScheduleReducer.message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        BannerSchedule: (data) => {

           dispatch( BannerScheduleServer.BannerSchedule(data) )
        },
        resetReducerState : () =>{
            dispatch({type:BannerScheduleActions.BannerSchedule_POST.NEW})
        }
    }
};
const BannerScheduleContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BannerScheduleView)

export default BannerScheduleContainer;