export const DisableBusinessActions= {
	
	DisableBusiness_Update :{
		NEW:"DisableBusiness_Update_NEW",
		SUCCESS:"DisableBusiness_Update_SUCCESS",
		FAILED:"DisableBusiness_Update_FALIURE",
		LOADING:"DisableBusiness_Update_LOADING",
		NOT_AUTHORIZED: "DisableBusiness_Update_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	
}


export const DisableBusinessStatus ={

	DisableBusiness_Update :{
		NEW:"DisableBusiness_Update_NEW",
		SUCCESS:"DisableBusiness_Update_SUCCESS",
		FAILED:"DisableBusiness_Update_FALIURE",
		LOADING:"DisableBusiness_Update_LOADING",
		NOT_AUTHORIZED: "DisableBusiness_Update_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
