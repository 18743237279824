import { connect } from "react-redux";
import UploadBannerView from "./UploadBanner_View";
import {UploadBannerServer} from "./server"
import {UploadBannerActions} from './UploadBannerConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        UploadBanner_post_status:state.UploadBannerReducer.UploadBanner_Status,
        UploadBanner_Message:state.UploadBannerReducer.UploadBanner_Message,
        // GoToContractScreen:state.UploadBannerReducer.GoToContract,
        UploadBanner:state.UploadBannerReducer.UploadBannerDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateRetailerBanner: (data, bannerId) => {
            dispatch( UploadBannerServer.updateRetailerBanner(data, bannerId) )
         },

        saveUploadBanner: (data) => {

           dispatch( UploadBannerServer.saveUploadBanner(data) )
        },
        
        resetReducerState: () =>{
            dispatch({type:UploadBannerActions.UploadBanner_Post.NEW})
        }
    }
};
const UploadBannerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadBannerView)

export default UploadBannerContainer;