import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import LabelTextAreaInput from '../Inputs/LabelTextAreaInput';

import {ADMIN_END_BRANDING_IMAGE_URL} from "../../Config/Config"
import moment from "moment";
import { Modal as PreviewModal} from '../Cards/PreviewNJTCouponCard'
import PausePlayCouponContainer from "./PausePlayCoupon/PausePlayCouponContainer"
import EndCouponContainer from './EndCoupon/EndCouponContainer'
import DeleteCouponContainer from './DeleteCoupon/DeleteCouponContainer'
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from '@material-ui/core';

import {withCookies} from "react-cookie"
import { ref_status,  coupon_transaction_type_id, coupon_transaction_subtype_id} from "RefRecordsConstants"
import UndoDeleteCouponContainer from './UndoDeleteCoupon/UndoDeleteCouponContainer';
const modalRoot = document.getElementById("coupons_approval");

class Modal extends React.Component {
   CapitalizedText = (text) => {
     text = text.charAt(0).toUpperCase() +text.substring(1);
    return text
   }
  
   getUTC_date = (date) => {
    var d = new Date(date);
    d.setDate(d.getUTCDate());

    return d;
  };

  capitalizeTerms = (text )=>{
    var couponTerms=text?text.trim():"";
    couponTerms = couponTerms.charAt(0).toUpperCase() + couponTerms.substring(1);
    couponTerms = couponTerms.replace(/([^.])$/, '$1.');
    return couponTerms
  }

  checkDaysSelected = ()=>{
    var days = []
    // alert(JSON.stringify(this.props.coupon.valid_monday))
     if (this.props.coupon.valid_monday) days.push("Monday");
     if (this.props.coupon.valid_tuesday) days.push("Tuesday");
     if (this.props.coupon.valid_wednesday) days.push("Wednesday");
     if (this.props.coupon.valid_thursday) days.push("Thursday");
     if (this.props.coupon.valid_friday) days.push("Friday");
     if (this.props.coupon.valid_saturday) days.push("Saturday");
     if (this.props.coupon.valid_sunday) days.push("Sunday");
     if(days.length>0 && days.length < 7)
     return days;
     else
     return false
  }
  
  render() {

    const { coupon, ref_data,logo_url } = this.props;
 
    const terms = coupon.coupon_terms == "" ||
    coupon.coupon_terms == undefined ||
    coupon.coupon_terms == null
      ? "$" +
        coupon.coupon_face_value +
        " off your next purchase."
      : coupon.coupon_terms
      var imgUrl = "";
       if (coupon.logo_url && coupon.logo_url !== "") {
        imgUrl = coupon.logo_url;
      } 
      else if(logo_url && logo_url !=="")
      imgUrl = logo_url;
      else{
        imgUrl = ADMIN_END_BRANDING_IMAGE_URL + "Njt-Logo-All.png";
      }
    //  else if (coupon.image_location && coupon.image_location !== "")
    //     imgUrl = coupon.image_location;
   
        if(coupon.media_location)
    coupon.image_location = coupon.media_location.split(';;;')
    else if(coupon.image_location)
    coupon.image_location = [coupon.image_location]
    
    //   if(imgUrl.includes('.mp4') || imgUrl == '')
    //   imgUrl = ADMIN_END_BRANDING_IMAGE_URL + "Njt-Logo-All.png";
    // alert(imgUrl)
    return ReactDOM.createPortal(
        <div
          style={{
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            minHeight: "100vh",
            zIndex:"8"
          }}
          // onClick={onClose}
        >
          <div
          className="resp-p-new resp"
            style={{
              padding: 20,
              background: '#fff',
              borderRadius: '20px',
              display: 'inline-block',
              minHeight: '200px',
              margin: '1rem',
              position: 'relative',
              minWidth: '300px',
              boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              justifySelf: 'center',
              width:"40%"
            }}
          >
          <div className="approvl-model-box">
            <button id="closeModal" className="cros-icon-model" onClick={this.props.onClose}><span className="material-icons">close</span></button>
            <div className="app-model-inner-box">
              <div className="app-model-head">
                <div className="col-app-img">
                  <img src={imgUrl} alt="not found" />
                </div>
                <div className="app-head-col2"><span>
                  {coupon.offer_name ? coupon.offer_name : '- -'}
                </span></div>
              </div>

              {/* <div className='row'>
                <div className='col-sm-12'>
                  <div className='export-rca cpm'>
                    {coupon.coupon_code_file_url && coupon.coupon_code_file_url != "" ?
                      <a href={coupon.coupon_code_file_url} download style={{ textDecoration: "none" }}>
                        {(coupon.status_id == ref_status.SAVED || coupon.status_id == ref_status.PENDING || coupon.status_id == ref_status.DECLINED) ? <Button
                          variant="contained" color="default"
                          startIcon={<GetAppIcon />}
                        >
                          Offer Codes.csv
                        </Button> : <></>}
                      </a> : ""}



                  </div>
                </div>
              </div> */}
              <div className="inv-model-box">
                <div className="inv-model-inner">
                <div className="inv-card-feild-box">
                    <div className="inv-card-left">Partner Name</div>
                    <div className="inv-card-right">{coupon.business_name}
                    </div>
                  </div>

                  <div className="inv-card-feild-text-area">
                    <div className="inv-card-txt">
                      <LabelTextAreaInput
                        disabled={true}
                        inputValue={this.capitalizeTerms(coupon.offer_name)}
                        label="Offer Title"
                      />
                    </div>
                  </div>

                  <div className='inv-card-feild-text-area'>
                    <div className='inv-card-txt'>
                      <LabelTextAreaInput
                        disabled={true}
                        inputValue={coupon.offer_description ? coupon.offer_description : (coupon.description? coupon.description:"---")}
                        label='Offer Description'
                      />
                    </div>
                  </div>

                  {coupon.legalese && coupon.legalese.trim() != '' ? (
                    <div className='inv-card-feild-text-area'>
                      <div className='inv-card-txt'>
                        <LabelTextAreaInput
                          disabled={true}
                          inputValue={coupon.legalese}
                          label='Terms & Conditions'
                        />
                      </div>
                    </div>
                  ) : null}


                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Start Date Time</div>
                    <div className='inv-card-right'>
                      {
                        moment(coupon.promotion_start_date).format('MM/DD/YYYY')
                      }{' '}
                      at {moment(coupon.promotion_start_date).format('h:mm A')}
                    </div>
                  </div>
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>
                      {coupon.coupon_transaction_type_id !== 2
                        ? 'End Date Time'
                        : 'Must Be Used Before'}{' '}
                    </div>
                    <div className='inv-card-right'>
                      {
                        moment(coupon.promotion_end_date).format('MM/DD/YYYY') + " at " + moment(coupon.promotion_end_date).format("h:mm A")

                      }
                    </div>
                  </div>
                  
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>
                      {parseInt(coupon.coupon_transaction_type_id) === coupon_transaction_type_id.prepaid ?
                          "Face Value" : "Discount Face Value"}
                    </div>

                    <div className='inv-card-right'>
                      {parseInt(coupon.coupon_transaction_subtype_id) == coupon_transaction_subtype_id.PercentageType ?
                          <>{coupon.coupon_face_value}%</> :
                          <>${coupon.coupon_face_value}</>}
                    </div>
                  </div>

                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Quantity</div>
                    <div className='inv-card-right'>
                      {coupon.coupon_quantity}
                    </div>
                  </div>

                  <div className='inv-card-feild-box' style={{ display: "flex", alignItems: "center" }}>
                    <div className='inv-card-left'>Offer Code Options</div>
                    <div className='inv-card-right'>

                      {coupon.coupon_code_file_url && coupon.coupon_code_file_url != "" ?
                        <a href={coupon.coupon_code_file_url} download style={{ textDecoration: "none" }}>
                          <Button
                            variant="contained" color="default"
                            startIcon={<GetAppIcon />}
                          >
                            Offer Codes.csv
                          </Button>
                        </a>
                        :
                        coupon.coupon_code && coupon.coupon_code != "" ?
                          coupon.coupon_code
                          : "Auto Generated"
                      }
                    </div>
                  </div>

                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Redemption URL</div>
                    <div className='inv-card-right'>
                    {coupon.redemption_url ? coupon.redemption_url : '- -'}
                    </div>
                  </div>

                  {parseInt(coupon.status_id) !== parseInt(ref_status.DECLINED) && !(coupon.status_id === ref_status.SAVED && coupon.feedback) ?
                    <div className="inv-card-feild-box">
                      <div className="inv-card-left">Status</div>
                      <div className="inv-card-right">
                        <span style={{ color: coupon.display_color }}>{coupon.to_status_code}</span>
                      </div>
                    </div> : ""}

                  <div className="inv-card-feild-text-area">
                    <div className="inv-card-txt">
                      <LabelTextAreaInput
                        disabled={true}
                        inputValue={this.capitalizeTerms(terms)}
                        label="Offer terms"
                      />
                    </div>
                  </div>
                  
                  <div className="inv-card-feild-box">
                    <div className="inv-card-left">Quota Month</div>
                    <div className="inv-card-right">{moment(coupon.promotion_start_date).format("MMMM YYYY")}
                    </div>
                  </div>

                  <div className="inv-card-feild-box">
                    {/* <div className="inv-card-left">{isOverride?"Generated":"Submitted"} By</div> */}
                    <div className="inv-card-left">{coupon.generated_by && parseInt(coupon.status_id) !== parseInt(ref_status.SAVED) ? "Generated" : "Submitted"} By</div>
                    <div className="inv-card-right">{coupon.generated_by ? coupon.generated_by : coupon.user_name}</div>
                  </div>
                  <div className="inv-card-feild-box">
                    {/* <div className="inv-card-left">{isOverride?"Generated":"Submitted"} On</div> */}
                    <div className="inv-card-left">{coupon.generated_by && parseInt(coupon.status_id) !== parseInt(ref_status.SAVED) ? "Generated" : "Submitted"} On</div>
                    <div className="inv-card-right">

                      {moment(coupon.coupon_create_dttm).format("MM/DD/YYYY")

                      } at {moment(coupon.coupon_create_dttm).format("h:mm A")}

                    </div>
                  </div>

                  
                  <div className="inv-card-feild-box">
                    <div className="inv-card-left">Inventory</div>
                    <div className="inv-card-right">{coupon.Inventory}</div>

                        </div>
                        <div className="inv-card-feild-box">

                            <div className="inv-card-left">{coupon.coupon_transaction_type_id == coupon_transaction_type_id.prepaid?"Purchased":"Claimed"}</div>
                            <div className="inv-card-right">{coupon.Claimed_Coupons}</div>
                          
                        </div>
                        <div className="inv-card-feild-box">
                        
                            <div className="inv-card-left">Redeemed</div>
                            <div className="inv-card-right">{coupon.Redeemed_Coupons}</div>
                        </div>
                        
                        
                        </div>
                  
                    {parseInt(coupon.status_id) === parseInt(ref_status.DECLINED) ?
                        <div className="inv-card-feild-box">
                            <div className="inv-card-left">Status</div>
                            <div className="inv-card-right">
                            <span style={{color: coupon.display_color}}>{coupon.to_status_code}</span>
                            </div>
                        </div>
                    :
                    (coupon.status_id === ref_status.SAVED && coupon.feedback) ?
                      <Fragment>
                      <div className="inv-card-feild-box">
                        <div className="inv-card-left">Status</div>
                          <div className="inv-card-right">
                            <span style={{color: coupon.display_color}}>{"FEEDBACK SENT"}</span>
                          </div>
                        </div>
                      </Fragment>
                      :
                      null
                    }
               </div>
               <div className="rej-emp">

{parseInt(coupon.status_id) === parseInt(ref_status.DECLINED) || (coupon.status_id === ref_status.SAVED && coupon.feedback) ?
  <div className="reject-box">
    <h6 className="reject-heading">Explanation</h6>
    <div className="reject-des">
      {coupon.feedback}
      {/* Image is not clear. Coupon description is ambiguous. Coupon terms and conditions are ambiguous or unclear. Lewd product or service are not permitted on the site. Alcoholic beverages are not permitted to be advertised on the site. */}
    </div>
  </div> : null}
</div>
{parseInt(coupon.status_id) === parseInt(ref_status.PENDING) || parseInt(coupon.status_id) === parseInt(ref_status.PENDINGEXPIRED) || parseInt(coupon.status_id) === parseInt(ref_status.DELETED) ?
            <div className="app-preview-box-bbbtn">
              {/* <p>Your Coupons are Pending for Approval</p> */}
              <button id="previewBtn" onClick={this.props.handleShowPreviewModal} className="decline-box-new-prev">
                Preview
              </button>
              {parseInt(coupon.status_id) === parseInt(ref_status.DELETED) ?
              <UndoDeleteCouponContainer resetFaceValue={this.props.resetFaceValue} updateSelectedCoupon={this.props.updateSelectedCoupon} ref_data={this.props.ref_data} coupon={coupon}/>
              :null
              }
            </div> : parseInt(coupon.status_id) === parseInt(ref_status.DECLINED) ? null :
           <div className="inv-btn-wrap">

           <div className="decl-box">
           <EndCouponContainer updateSelectedCoupon={this.props.updateSelectedCoupon} ref_data={this.props.ref_data} coupon={coupon} />
           </div>
           <div className="app-btn-box">
               {/* <button className="inv-button-pause" onClick={this.props.handleShowPreviewModal}>
                   Delete
               </button> */}
               <DeleteCouponContainer   resetFaceValue={this.props.resetFaceValue} updateSelectedCoupon={this.props.updateSelectedCoupon} ref_data={this.props.ref_data} coupon={coupon} getCouponsInventory={this.props.getCouponsInventory} resetFields={this.props.resetFields}/>
           </div>
           <div className="app-btn-box">
           <PausePlayCouponContainer updateSelectedCoupon={this.props.updateSelectedCoupon} ref_data={this.props.ref_data} coupon={coupon} />
           </div>
           
           <div className="app-btn-box">
               <button id="previewBtn" className="decline-box-new-prev" onClick={this.props.handleShowPreviewModal}>
                   Preview
               </button>
           </div>

           </div>}
                    
                </div>
            </div>
            {this.props.children}
        </div>
        {this.props.showPreviewModal?
        <PreviewModal
          fromInventory={true}
          image_location={coupon.image_location}
          offer_name={coupon.offer_name}
          coupon_face_value={coupon.coupon_face_value}
          CouponTerms = {terms}
          business_name={this.props.business_name}
          threshold = {ref_data.threshold}
          Quantity={coupon.coupon_quantity}
          expiryDate={moment(coupon.promotion_end_date).format("MM/DD/YYYY h:mm A")}
          redemption_end_date={coupon.redemption_end_date}
          can_only_be_used_on={coupon.can_only_be_used_on}
          Inventory= {coupon.Inventory}
          points = {coupon.points || coupon.cost_in_points}
          ValidFor={this.checkDaysSelected}
          Display_Coupon_Tag={ref_data.Display_Coupon_Tag}
         
          coupon_transaction_type_id={coupon.coupon_transaction_type_id}
          legalese={coupon.legalese}
          coupon_transaction_subtype_id={coupon.coupon_transaction_subtype_id}

          business_logo_url={logo_url}
          business_street_1={this.props.cookies.get("BusinessProfileData").business_street_1}
          business_street_2={this.props.cookies.get("BusinessProfileData").business_street_2}
          company_url={this.props.cookies.get("BusinessProfileData").company_url}
          business_description={coupon.offer_description?coupon.offer_description:this.props.cookies.get("BusinessProfileData").description}
          RewardURL={coupon.rewards_page_offer_url}
          WalletURL={coupon.wallet_page_offer_url}
          referral_fee_fixed={parseFloat(this.props.cookies.get("BusinessProfileData").referral_fee_fixed)}
          referral_fee_percent={parseFloat(this.props.cookies.get("BusinessProfileData").referral_fee_percent)}
          referral_fee_type_fixed={this.props.cookies.get("BusinessProfileData").referral_fee_type_fixed}
          referral_fee_type_percent={this.props.cookies.get("BusinessProfileData").referral_fee_type_percent}
          display_address_on_coupon={this.props.cookies.get("BusinessProfileData").display_address_on_coupon} 
          coupon_display_behavior_id={this.props.coupon_display_behavior_id}
          coupon_display_behavior={this.props.cookies.get("BusinessProfileData").coupon_display_behavior}
          city={coupon.city}
          state={coupon.state}
          zip5={coupon.zip5}
          zip4={coupon.zip4}
          onClose={this.props.handleClosePreviewModal}
          coupon_code={coupon.coupon_code}
          redemption_url={coupon.redemption_url}
          show_code_in_redemption_url={coupon.show_code_in_redemption_url}
          redemption_message={coupon.redemption_message}
        />:null}
      </div>,
      modalRoot,
    )
  }
}

class CouponInventoryPreviewModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showModal: false,
      showPreviewModal: false,
      coupon:props.coupon
    }
  }
  
  handleShowMessageClick = () => this.setState({showModal: true})
  handleCloseModal = () => this.setState({showModal: false})
  updateSelectedCoupon = (updatedCoupon)=>{
    this.setState({
      coupon:updatedCoupon
    })
  }
  handleShowPreviewModal = () => this.setState({showPreviewModal: true})
  handleClosePreviewModal = () => this.setState({showPreviewModal: false})

  render() {
    console.log('coupon data', this.props.coupon)
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        }}
      >
        <div
          className="app-dec-line-model"
        >
          <button className="mat-crcle" onClick={this.handleShowMessageClick} id={this.props.edit_id}>
          <span 
            // onClick={()=>this.props.openCouponModel(coupon)} 
            className="material-icons">
              fiber_manual_record
            </span>
          </button>
          {this.state.showModal ? (
            <Modal
            business_name={this.props.cookies.get("BusinessProfileData").business_name}
            coupon_display_behavior_id={this.props.cookies.get("BusinessProfileData").coupon_display_behavior_id}
            logo_url={this.props.cookies.get("BusinessProfileData").logo_url}
            Admin={ this.props.cookies.get("njt_admin_userId_partner") &&
            this.props.cookies.get("njt_admin_userId_partner").user_name
              ? this.props.cookies.get("njt_admin_userId_partner").user_name
              : "admin"}
            getRetailerCouponsInventory = {this.props.getRetailerCouponsInventory}
            onClose={this.handleCloseModal}
            ref_data = {this.props.ref_data}
            coupon={this.props.coupon}
            activePage={this.props.activePage}
            resetFilterStates={this.props.resetFilterStates}
            handleShowPreviewModal={this.handleShowPreviewModal}
            handleClosePreviewModal={this.handleClosePreviewModal}
            isOverride={this.props.isOverride}
            showPreviewModal={this.state.showPreviewModal}
            updateSelectedCoupon = {this.updateSelectedCoupon}
            cookies={this.props.cookies}
            resetFaceValue={this.props.resetFaceValue}
            getCouponsInventory={this.props.getCouponsInventory}
            resetFields={this.props.resetFields}
            > 
            </Modal>
          ) : null}
        </div>
        

      </div>
    )
  }
}



export default withCookies(CouponInventoryPreviewModal)

