
//******************Exchange Rate Server Calls******************;

import {DisableBusinessActions} from './DisableBusinessconstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';


export const DisableBusinessServer = {
  //  getDisableBusiness:getDisableBusiness,
 // getDisableBusiness:getDisableBusiness,
    updateDisableBusiness:updateDisableBusiness
};

//******************Insert DisableBusinesss******************;

function updateDisableBusiness(data, bussinessId){
  const request=fetch(ROOT_URL+'/api/business/updateBusinessById/'+bussinessId, {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
             // alert(JSON.stringify(response))
            store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.SUCCESS, payload:{bussinessId:bussinessId, is_disabled:data.is_disabled}});
           
            return ;
            }else
            {
              store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.FAILED});
              return ;
              }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.FAILED});
            })
      return {type:DisableBusinessActions.DisableBusiness_Update.LOADING,payload:{bussinessId:bussinessId}}

};
  
//******************Get DisableBusinesss******************;


//******************Update DisableBusiness******************;

  
// function getDisableBusiness(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.DisableBusiness.length>0)
//              store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.SUCCESS,payload:response.DisableBusiness});
//              else
//              store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.FAILED});
//           })
//        return {type:DisableBusinessActions.DisableBusiness_Update.LOADING}
 
//     };
  
// function updateDisableBusiness(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:DisableBusinessActions.DisableBusiness_Update.FAILED});
//     })
// return {type:DisableBusinessActions.DisableBusiness_Update.LOADING}

// };
