import React, { Component } from "react";
import RotateLoading from "../../Utils/RotateLoading";

class UpdateApplicantsStatus extends Component {
  UpdateApplicantsStatus = () => {

    if(this.props.statusValue === "")
    this.props.setStatusError()
    else if(this.props.notes !== "" && this.props.notes !== null & this.props.notes !== undefined && this.props.notes.trim() == "")
    this.props.setNotesError('Invalid Message')
    else{
      var Message = this.props.business_application.message
      if(this.props.message && this.props.message!="")
      Message = this.props.message
      if(this.props.notes !="")
      Message = this.props.notes
   var data = {
    business_application_id:this.props.business_application.business_application_id,
    application_status_id:this.props.statusValue,
    note:this.props.notes,
    email_address:this.props.business_application.email_address,
    phone:this.props.business_application.phone,
    statusName:this.props.statusName,
    username:this.props.username,
    message:Message
    //this.props.business_application_id
   }
  //  alert(JSON.stringify(data))
  if(this.props.change)
  {
    this.props.UpdateApplicantsStatus(data)
  }
  //  this.props.UpdateApplicantsStatus(data)
   //alert(JSON.stringify(data))
    }
  }
  render() {

    return (
      <div className="asm-box-btn">
    

     {this.props.showLoader &&    <RotateLoading LoadingIcon={true} 
        loaderStyle={{right:"100px", top:"10px"}} /> }
        <button id="btnMessageStauts" className="asm-btn-button" onClick={this.UpdateApplicantsStatus}>
          Send
        </button>
        
      </div>
    );
  }
}

export default UpdateApplicantsStatus;
