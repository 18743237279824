import { connect } from "react-redux";
import AddNewUserView from "./AddNewUser_View";
import {AddNewUserServer} from "./server"
import {AddNewUserActions} from './AddNewUserConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        AddNewUser_POST_status:state.AddNewUserReducer.AddNewUser_Status,
        AddNewUser:state.AddNewUserReducer.AddNewUserDetails,
        AddNewUser_Message:state.AddNewUserReducer.AddNewUser_Message
       // AddNewUserIndex:state.AddNewUserReducer.AddNewUserIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        AddNewAdminUser: (data) => {

           dispatch( AddNewUserServer.AddNewAdminUser(data) )
        },
        AddNewRetailerUser:(data) =>{
            dispatch(AddNewUserServer.AddNewRetailerUser(data))
        },
        resetReducerState:()=>{
            dispatch({type:AddNewUserActions.AddNewUser_POST.NEW})
        }
    }
};
const AddNewUserContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNewUserView)

export default AddNewUserContainer;