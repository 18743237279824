export const AddNewUserActions= {
	
	AddNewUser_POST :{
		NEW:"AddNewUser_POST_NEW",
		SUCCESS:"AddNewUser_POST_SUCCESS",
		FAILED:"AddNewUser_POST_FALIURE",
		LOADING:"AddNewUser_POST_LOADING",
	},
	
}


export const AddNewUserStatus ={

	AddNewUser_POST :{
		NEW:"AddNewUser_POST_NEW",
		SUCCESS:"AddNewUser_POST_SUCCESS",
		FAILED:"AddNewUser_POST_FALIURE",
		LOADING:"AddNewUser_POST_LOADING",
	}

}
