
//******************Exchange Rate Server Calls******************;

import { UploadBannerActions } from './UploadBannerConstants';
import store from '../../Store/store'
import { ROOT_URL,getCookie } from '../../Config/Config';
import {failure_messages} from "Messages"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const UploadBannerServer = {
  //  getUploadBanner:getUploadBanner,
  saveUploadBanner: saveUploadBanner,
  updateRetailerBanner: updateRetailerBanner
  ///api/business/CreateRetailerBussiness
};

//******************Insert UploadBanners******************;

function saveUploadBanner(data) {
  if(data.selectedImage != "" && (typeof data.selectedImage ) === "object")
 { 
  var image =data.selectedImage.name.split(".")
  var img_name = image[0]
  var img_ext = image[image.length - 1]
  data = {
    ...data,
    isBase64:true,
    img_name:img_name,
    img_ext:img_ext
  }
  // alert(JSON.stringify(data.cookies))
  const request = fetch(ROOT_URL + '/api/business/upload_logo', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log("upload response: ",response.data)
        data={
          ...data,
          banner_location:response.data.url
        }
      //  alert(JSON.stringify(data.cookies))
        uploadBanner(data)
///        store.dispatch({ type: UploadBannerActions.UploadBanner_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")

        store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:response.error });

      }
    });
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:failure_messages.unExpectedError});
  })
}else
uploadBanner(data)

return { type: UploadBannerActions.UploadBanner_Post.LOADING }
};

function uploadBanner(data) {
  console.log("data:", data)
  delete data.selectedImage
  
  const request = fetch(ROOT_URL + '/api/banners/createNewBanner', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    // alert(JSON.stringify(data.cookies))
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response.data)
        // toast.success(response.message)
        // // this.props.resetReducerState()
        // data.cookies.remove("banner_duplicate", { path: "/" });
        // data.cookies.remove("banner_edit", { path: "/" });
    
        // data.history.replace({
        //   pathname:"/Manage_Banner"
        // })
        
        store.dispatch({ type: UploadBannerActions.UploadBanner_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:response.error });

      }
    });
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:failure_messages.unExpectedError});
  })

};



function updateRetailerBanner(data, bannerId) {
  if( data.selectedImage != "" && (typeof data.selectedImage ) === "object")
 { 
var image =data.selectedImage.name.split(".")
    var img_name = image[0]
    var img_ext = image[image.length - 1]
    data = {
      ...data,
      isBase64:true,
      img_name:img_name,
      img_ext:img_ext
    }
  const request = fetch(ROOT_URL + '/api/business/upload_logo', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
     response.json().then(response => {
      delete data.banner_location
       if (response.status == "200") {
         console.log("upload response: ",response)
         data={
          ...data,
          banner_location:response.data.url
        }
        updateBanner(data, bannerId)

         return;
       } else {
         // alert("test")
 
         store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:response.error });
 
       }
     });
   }).catch((error) => {
     //  alert(error)
     store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:failure_messages.unExpectedError});
   })
 }else
  updateBanner(data, bannerId)

  return { type: UploadBannerActions.UploadBanner_Post.LOADING }

};

function updateBanner(data, bannerId) {
  delete data.selectedImage
  console.log("update after image upload:", data)
  const request = fetch(ROOT_URL + '/api/banners/updateBanner/' + bannerId, {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        
        store.dispatch({ type: UploadBannerActions.UploadBanner_Post.SUCCESS, payload: response.data, message:response.message });
        // return ;
      } else {
        store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:response.error });
        // return ;
      }
    });
  }).catch((error) => {
    store.dispatch({ type: UploadBannerActions.UploadBanner_Post.FAILED, error:failure_messages.unExpectedError });
  })

};