import {
    BusinessMessagesStatus,
    BusinessMessagesActions
  } from "./BusinessMessagesConstants";
  
  const BusinessMessages_initialState = {
    BusinessMessages_Status: BusinessMessagesStatus.BusinessMessages_Get.NEW,
    BusinessMessages_Error: "",
    BusinessMessagesDetails: []
  };
  export default function(state = BusinessMessages_initialState, action) {
    switch (action.type) {
      case BusinessMessagesActions.BusinessMessages_Get.LOADING:
        return {
          ...state,
          BusinessMessages_Status:
            BusinessMessagesStatus.BusinessMessages_Get.LOADING
        };
      case BusinessMessagesActions.BusinessMessages_Get.NEW:
        return {
          ...state,
          BusinessMessages_Status:
            BusinessMessagesStatus.BusinessMessages_Get.NEW,
          BusinessMessagesDetails: []
        };
      case BusinessMessagesActions.BusinessMessages_Get.SUCCESS:
        return {
          ...state,
          BusinessMessages_Status:
            BusinessMessagesStatus.BusinessMessages_Get.SUCCESS,
          BusinessMessagesDetails: action.payload
        };
      case BusinessMessagesActions.BusinessMessages_Get.FAILED:
        return {
          ...state,
          BusinessMessages_Status:
            BusinessMessagesStatus.BusinessMessages_Get.FAILED
        };
  
      default:
        return {
          ...state,
          BusinessMessages_Error: "",
          BusinessMessages_Status: BusinessMessagesStatus.BusinessMessages_Get.NEW
        };
    }
  }
  