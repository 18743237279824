export const ChangePasswordActions= {
	
	ChangePassword_POST :{
		NEW:"ChangePassword_POST_NEW",
		SUCCESS:"ChangePassword_POST_SUCCESS",
		FAILED:"ChangePassword_POST_FALIURE",
		LOADING:"ChangePassword_POST_LOADING",
	},
	
}


export const ChangePasswordStatus ={

	ChangePassword_POST :{
		NEW:"ChangePassword_POST_NEW",
		SUCCESS:"ChangePassword_POST_SUCCESS",
		FAILED:"ChangePassword_POST_FALIURE",
		LOADING:"ChangePassword_POST_LOADING",
	}

}
