import React, { Component } from "react";
import "./Messaging.css";
import SingleInputRadio from "../Inputs/InputRadio/SingleInputRadio";
// import { Link } from "react-router-dom";
import LabelTextAreaInput from "../Inputs/LabelTextAreaInput";
import MultiSelectDropdown from "../Inputs/MultiSelectDropdown/MultiSelectDropdown";
import RetailerListContainer from "../RetailerList/RetailerListContainer";
import ManageMessagingContainer from "./ManageMessagingContainer";
import Loading from "../Utils/Loading";
class ManageMessaging extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectCheck: false,
      searchname: "",
      searchNameFlag: false,
      searchNameErrorMsg: "Field Required",
      allCheck: true,
      selectedRetailers: [],
      Retailers: [],
      message: "",
      MessageErrorMsg: "Field Required",
      MessageFlag: false,
      sendMsg: false,
      Data: "",
      isLoading: false,
      disabledFields: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setLoading = (Loading) => {
    this.setState({
      isLoading: Loading,
    });
  };

  disableAllFields =(disable)=>{
    this.setState({disabledFields:disable})
  }
  // todo: allRadioHandler onChange function ..........................

  sendMessage = (check) => {
    this.setState({
      sendMsg: check,
    });
  };
  allRadioHandler = () => {
    // alert("allRadioHandler work ")
    this.setState({
      allCheck: true,
      selectCheck: false,
      selectedRetailers: [],
    });
    //   this.setState({
    //     weekCheck: true,
    //     fiveCheck: false,
    //     twoCheck: false
    //   });
  };
  setRetailerList = (data) => {
    this.setState({
      Retailers: data,
    });
  };
  // todo: selectRadioHandler onChange function ..........................

  selectRadioHandler = () => {
    this.setState({
      allCheck: false,
      selectCheck: true,
    });
    //   this.setState({
    //     weekCheck: true,
    //     fiveCheck: false,
    //     twoCheck: false
    //   });
  };
  searchName = () =>{
    // alert("test")
  }
  // todo: searchNameHandler onChange function ..........................
  searchNameHandler = (e) => {
    
    // console.log("this e check", e);
    // const businessObj = this.state.RetailerList.find(element => {
    //   return element.value == e;
    // });
    // alert(JSON.stringify(e))
    this.setState({
      selectedRetailers: e,
      searchNameFlag: false,
    });
  };

  messageHandler = (e) => {
    // alert("messageHandler work ")
    this.setState({
      message: e.target.value,
      MessageFlag: false,
    });
  };

  // todo: sendHandler onChange function ..........................
  sendHandler = () => {
    var flag = true;
    if (!this.state.allCheck) {
      if (this.state.selectedRetailers.length == 0) {
        flag = false;
        this.setState({
          searchNameFlag: true,
        });
      }
    }
    if (flag) {
      if (this.state.message == 0) {
        this.setState({
          MessageFlag: true,
        });
      } else {
        var retailers = [];
        if (this.state.selectedRetailers.length == 0)
          retailers = this.state.Retailers;
        else retailers = this.state.selectedRetailers;

        this.state.Data = {
          message: this.state.message,
          isEveryone: this.state.allCheck,
          Retailers: retailers,
        };
        // alert(JSON.stringify(this.state.Data))
        this.sendMessage(true);
      }
    }
  };
  resetStates = () => {
    this.setState({
      message: "",
      selectedRetailers:[],
      isLoading:true
    },() =>{
      this.setState({
        isLoading:false
      })
    });
  };
  render() {
    const {
      searchNameFlag,
      searchNameErrorMsg,
      MessageErrorMsg,
      MessageFlag,
    } = this.state;
    return (
      <div className="upload-banner-wrap message">
        <div className="upload-banner-container">
          <div className="upload-banner-box">
            <div className="upload-banner-box2">
              <div className="upload-header-heading">
                <h2 className="upload-header-head">Messaging</h2>
              </div>
              <div className="upload-banner-select">
                <div className="upload-banner-input-box">
                  <div className="upload-form-input-b">
                    <div className="upload-form-label">Send message to</div>
                    <div className="upload-form-input radio-margin">
                      <div className="mes-ret">
                        <SingleInputRadio
                          checked={this.state.allCheck}
                          label="All Partners"
                          id="allPartners"
                          onChange={this.allRadioHandler}
                          disabled={this.state.disabledFields}

                        />
                      </div>
                      <div className="mes-ret">
                        <SingleInputRadio
                          checked={this.state.selectCheck}
                          label="Selected Partners"
                          id="selectedPartners"
                          onChange={this.selectRadioHandler}
                          disabled={this.state.disabledFields}
                        />
                      </div>
                    </div>
                  </div>
                  {!this.state.allCheck ? (
                    this.state.isLoading ? (
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Loading />
                      </div>
                    ) : (
                      <div className="upload-form-input-b select-business">
                        <MultiSelectDropdown
                          placeHolder={this.state.selectedRetailers.length>0?"":"Select Business"} //selectedRetailers

                          flag={searchNameFlag}
                          errorMsg={searchNameErrorMsg}
                          onChange={this.searchNameHandler}
                          onSelect={this.searchNameHandler}
                          onRemove={this.searchNameHandler}
                          data={!this.state.disabledFields ? this.state.Retailers:[]}
                          searchName= {this.searchName}
                          disabled={this.state.disabledFields}
                        />
                      </div>
                    )
                  ) : null}
                  <RetailerListContainer
                    setLoading={this.setLoading}
                    getRetailerBusinessOnly={true}
                    setRetailerList={this.setRetailerList}
                  />
                  <div className="upload-form-input-b">
                    <LabelTextAreaInput
                      label="Message"
                      placeholder = "Your Message"
                      id="messageBox"
                      flag={MessageFlag}
                      errorMsg={MessageErrorMsg}
                      inputValue={this.state.message}
                      onChange={this.messageHandler}
                      disabled={this.state.disabledFields}
                    />
                  </div>

                  <div className="upload-form-button-box">
                    <div className="back-btn">
                      {/* <Link to="/Admin" className="back-link">
                        Back
                      </Link> */}
                    </div>
                    <div className="next-btn">
                      <ManageMessagingContainer
                        resetStates={this.resetStates}
                        Data={this.state.Data}
                        sendMessage={this.sendMessage}
                        sendMsg={this.state.sendMsg}
                        sendHandler={this.sendHandler}
                        disableAllFields={this.disableAllFields}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageMessaging;
