import React, { Component } from "react";
import "../Invoices/Invoices.css";
import InvoicesContainer from "./InvoicesContainer";
import LabelInput from "../Inputs/LabelInput";
import { Api_Pagination } from "RefRecordsConstants";
import { Link } from "react-router-dom";
import BusinessName from "../BusinessName/BusinessName";
class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "",
      business_id: "",
      BusinessProfileData: this.props.cookies.get("BusinessProfileData"),
      NoOfPages: 0,
      activePage: 1,
      isLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  invoiceHadler = () => {
    alert("invoiceHadler work");
  };


  setLoading = (loading) => {
    //  alert(loading)
    this.state.isLoading = loading;
  };

  setItemList = (ListLength) => {
    if (ListLength - 1 > 0)
      this.setState({
        NoOfPages:
          Math.trunc(
            (ListLength - 1) / Api_Pagination.NotificationHistoryLimit
          ) + 1,
      });
  };

  ChangePage = (page) => {
    // alert(this.state.isLoading)
    console.log("Loading page:", this.state.isLoading);
    if (this.state.activePage != page && !this.state.isLoading) {
      this.setState({
        activePage: page,
      });
      //   this.setLoading(true)
    }
  };
  handleNext = () => {
    //   alert(this.state.isLoading)
    if (this.state.activePage < this.state.NoOfPages && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage + 1,
      });
      // this.setLoading(true)
    }
  };
  handlePrev = () => {
    // alert(this.state.isLoading)
    if (this.state.activePage > 1 && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage - 1,
      });
      // this.setLoading(true)
    }
  };
  render() {
    const items = [];
    items.push(
      <Link
        to="#"
        onClick={() => this.ChangePage(1)}
        className={this.state.activePage == 1 ? "active-page" : null}
      >
        {" "}
        1{" "}
      </Link>
    );
    let i = 2;
    let offset = 6;
    if (this.state.activePage > 3 && this.state.NoOfPages > 5) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      items.push(<Link to="#">{"..."}</Link>);
    }
    if (this.state.activePage + 3 > this.state.NoOfPages && i > 2)
      i = this.state.NoOfPages - 3;
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset - 1;
      index++
    ) {
      items.push(
        <Link
          to="#"
          key={index}
          onClick={() => this.ChangePage(index)}
          className={i == this.state.activePage ? "active-page" : null}
        >
          {" "}
          {i}{" "}
        </Link>
      );
      i++;
    }
    if (this.state.NoOfPages > this.state.activePage + 2)
      if (this.state.NoOfPages > 5) items.push(<Link to="#">{"..."}</Link>);
    items.push(
      <Link
        to="#"
        onClick={() => this.ChangePage(this.state.NoOfPages)}
        className={
          this.state.activePage == this.state.NoOfPages ? "active-page" : null
        }
      >
        {this.state.NoOfPages}{" "}
      </Link>
    );

    return (
      <div className="main-invoices">
        <BusinessName/>
        <div className="upload-header-wrap">
          <div className="upload-header-container">
            <div className="upload-header-box">
              <div className="upload-header-box1">
                <div className="upload-header-heading">
                  <h2 className="upload-header-head">Business Invoice History</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="upload-header-wrap">
          <div className="invoices-container">
            <div className="business-b2">
              <div className="busniness-b32">
                <LabelInput
                  type="text"
                  name="businessName"
                  label="Business Name:"
                  inputValue={this.state.BusinessProfileData.business_name}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="invoices-container">
          <div className="in-inner-box heaight">
            {/* <h1>George's Autobody - Invoice History</h1> */}
            <div className="invoice-box scrol">
              <table className="invoces-table">
                <thead>
                  <tr>
                    <th>Invoice Date</th>
                    <th>Invoice Amount</th>
                    <th>Invoice Status</th>
                    {/* <th>Payment Account</th> */}
                     <th //style={{width:"12%"}}
                     >Invoice Details</th>
                  </tr>
                </thead>
                <tbody>
                  <InvoicesContainer
                    activePage={this.state.activePage}
                    setItemList={this.setItemList}
                    setLoading={this.setLoading}
                    cookies={this.props.cookies}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.NoOfPages > 1 ? (
                <div className="pagination-box">
                  <div className="pagination-inner-box">
                    <div className="box-pagination">
                      <div className="next-btn">
                        <div className="cont1">
                          <Link to="#" onClick={this.handlePrev}>prev</Link>
                        </div>
                        <div style={{minWidth:300}} className="count-btn">
                        {items}
                        </div>
                        <div className="cont1">
                          <Link to="#" onClick ={this.handleNext}>next</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
      </div>
    );
  }
}

export default Invoices;
