import { BusinessContractStatus, BusinessContractActions } from './BusinessContractConstants';

const BusinessContract_initialState = {
    BusinessContract_Status: BusinessContractStatus.BusinessContract_Post.NEW,
    BusinessContract_Message: '',
    BusinessContractDetails: {},
    GotoQuotas:false
}
export default  function  (state = BusinessContract_initialState, action) {
    switch (action.type) {
        
        case BusinessContractActions.BusinessContract_Post.NEW:
        
            return { ...state, BusinessContract_Status: BusinessContractStatus.BusinessContract_Post.NEW,BusinessContract_Message: ''}
    
        case BusinessContractActions.BusinessContract_Post.LOADING:
            return { ...state, BusinessContract_Status: BusinessContractStatus.BusinessContract_Post.LOADING }
    
        case BusinessContractActions.BusinessContract_Get.FAILED:
            return { ...state,  BusinessContract_Status: BusinessContractStatus.BusinessContract_Get.FAILED, BusinessContract_Message:action.error }
        
            case BusinessContractActions.BusinessContract_Post.FAILED:
            return { ...state,  BusinessContract_Status: BusinessContractStatus.BusinessContract_Post.FAILED, BusinessContract_Message:action.error}
        
            case BusinessContractActions.BusinessContract_Get.SUCCESS:
          
          return { ...state,  BusinessContract_Status: BusinessContractStatus.BusinessContract_Get.SUCCESS, BusinessContractDetails:action.payload, GotoQuotas:false}
          case BusinessContractActions.BusinessContract_Post.SUCCESS:
                return { ...state,  BusinessContract_Status: BusinessContractStatus.BusinessContract_Post.SUCCESS, BusinessContractDetails:action.payload, BusinessContract_Message:action.message}
            
          case BusinessContractActions.BusinessContract_Post.GOTO_QUOTAS:
                return { ...state,  GotoQuotas: true}
            
        default:
            return { ...state,
            }    
    }
}
