import React, { Component } from 'react'
import './UploadBannerHeader.css'
import {  withRouter } from 'react-router-dom'

class UploadBannerHeader extends Component {
    render() {
        const { 
            // backIcon="keyboard_backspace", backLink="#",
        heading="Upload Banner" } = this.props;
        return (
            <div className="upload-header-wrap">
              <div className="upload-header-container">
                  <div className="upload-header-box">
                      <div className="upload-header-box1">
                          {/* <div className="upload-header-back">
                            <Link style = {{cursor: 'pointer'}}  onClick={()=>this.props.history.goBack()}><span className="material-icons">{backIcon}</span></Link>
                           </div> */}
                           <div className="upload-header-heading">
                                <h2 className="upload-header-head">{heading}</h2>
                           </div>
                      </div>
                  </div>
              </div>
            </div>
        )
    }
}

export default withRouter(UploadBannerHeader)
