import React, { Component, Fragment } from "react";
//import './MessageHistory.css'
import { MessageHistoryStatus } from "./MessageHistoryConstants";
import ListOfMessageHistory from "./ListOfMessageHistory";

import Loading from "../../Utils/Loading";
import Empty from "../../Utils/Empty";
import {Api_Pagination} from "RefRecordsConstants";


export class MessageHistoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      activePage:0
    };
    // const data = {
    //   offset : 0
    // }
    // props.getMessageHistory(data)
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.activePage !== nextProps.activePage){
      // nextProps.setLoading(true)
      const data = {
        offset : (nextProps.activePage - 1) * Api_Pagination.NotificationHistoryLimit
      }
      // alert(JSON.stringify(data))
      nextProps.getMessageHistory(data)

      return{
        activePage:nextProps.activePage
      }
   }
   else if(nextProps.MessageHistory_Get_status ===MessageHistoryStatus.MessageHistory_Get.SUCCESS){
  
    nextProps.setLoading(false)
          
    if(nextProps.notificationListCount > 0 && nextProps.activePage == 1){
      nextProps.setItemList(nextProps.notificationListCount)
    }
   }
   else if(nextProps.MessageHistory_Get_status ===MessageHistoryStatus.MessageHistory_Get.FAILED){
    nextProps.setLoading(false)
    nextProps.setItemList(0)
   } else if(nextProps.MessageHistory_Get_status ===MessageHistoryStatus.MessageHistory_Get.LOADING){
    nextProps.setLoading(true)
   }
   return null;
 }

  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  
  getScreen(status) {
    // alert(status)
    switch (status) {
      case MessageHistoryStatus.MessageHistory_Get.NEW:
        //  alert(JSON.stringify( this.props.MessageHistory))
        return (
          <ListOfMessageHistory MessageHistory={this.props.MessageHistory} //getMessageHistory = {this.props.getMessageHistory} cookies={this.props.cookies}
          />
        );

      case MessageHistoryStatus.MessageHistory_Get.SUCCESS:
      
        return (
          <ListOfMessageHistory MessageHistory={this.props.MessageHistory} //getMessageHistory = {this.props.getMessageHistory} cookies={this.props.cookies}
          />
        );
      case MessageHistoryStatus.MessageHistory_Get.FAILED:
    
        return (
          <Fragment>
            <Empty message = "Failed to Get List of Messages"></Empty>
          </Fragment>
        );

      case MessageHistoryStatus.MessageHistory_Get.LOADING:
 
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center",height:"58vh", width:"100%" }}>
           <Loading/>
          </div>
        );
      default:
        return <div />;
    }
  }
  render() {

    return this.getScreen(this.props.MessageHistory_Get_status);
  }
}

export default MessageHistoryView;
