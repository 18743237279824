import React from 'react';
import './Header.css';

function SubHeader() {
    return (
        <header className="header">
            <div className="fix">
                <p className="h_p1">Rewards Platform</p>
                <p className="h_p2">Sign Out</p>
            </div>
        </header>
    )
}

export default SubHeader
