import React, { Component, Fragment } from 'react'
import {Modal} from "./EndCouponModel"
import {ref_status} from "RefRecordsConstants"
class EndCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel:false
        };
    
      }
      
      handleModel=(check)=>{
        this.setState({
          showModel:check
        })
      }
    render() {
     var disabled=""
      if(ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id || ref_status.DELETED === this.props.coupon.status_id || ref_status.SAVED === this.props.coupon.status_id)
      disabled="disabled"
        return (
            <Fragment>
            <button id="endBtn" disabled={ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id || ref_status.SAVED === this.props.coupon.status_id} onClick={()=>this.handleModel(true)} className={"inv-button-b "+disabled}>
            End
            </button>
         {this.state.showModel? <Modal onClose={()=>this.handleModel(false) } onAccept={this.props.EndCoupon}/>:null}
          </Fragment>
        )
    }
}

export default EndCoupon
