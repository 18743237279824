import React from "react";
import { FormControl, Grid, Row, Col } from "react-bootstrap";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import "../DateRange/DateRange.css";
import { withCookies } from "react-cookie";
import SimpleInput from "../SimpleInput";
import dateFormat from "dateformat";

class DateRange extends React.Component {
  constructor(props) {
    super(props);
    let start = moment(new Date(2020, 8, 20, 0, 0, 0, 0));
    let end = moment(start)
      .add(5, "days")
      .subtract(1, "second");
    this.state = {
      start: props.week_start_date ? moment(props.week_start_date) : start,
      end: props.week_end_date ? moment(props.week_end_date) : end
    };
    if (!props.week_start_date)
      this.props.cookies.set(
        "banner_week",
        { week_start_date: start, week_end_date: end },
        { path: "/" }
      );

    this.onClick = this.onClick.bind(this);
    this.applyCallback = this.applyCallback.bind(this);
  }

  applyCallback(startDate, endDate) {
    console.log("Apply Callback");
    console.log(startDate.format("DD-MM-YYYY HH:mm"));
    console.log(endDate.format("DD-MM-YYYY HH:mm"));
    this.props.cookies.remove("banner_week", { path: "/" });
    this.props.cookies.set(
      "banner_week",
      { week_start_date: startDate, week_end_date: endDate },
      { path: "/" }
    );
    this.setState({
      start: startDate,
      end: endDate
    });
  }

  rangeCallback(index, value) {
    console.log(index, value);
  }

  onClick() {
    let newStart = moment(this.state.start).subtract(3, "days");
    // console.log("On Click Callback");
    // console.log(newStart.format("DD-MM-YYYY HH:mm"));
    this.setState({ start: newStart });
  }

  renderVanillaPicker(ranges, local) {
    let value = `${this.state.start.format(
      "DD-MM-YYYY"
    )} - ${this.state.end.format("DD-MM-YYYY")}`;
    let disabled = false;
    return (
      <div>
        <DateTimeRangeContainer
          id="dateRange"
          ranges={ranges}
          start={this.state.start}
          end={this.state.end}
          local={local}
          //   maxDate={maxDate}
          applyCallback={this.applyCallback}
          rangeCallback={this.rangeCallback}
          smartMode
        >
          <FormControl
            id="formControlsTextB"
            type="text"
            label="Text"
            placeholder="Enter text"
            style={{ cursor: "pointer" }}
            disabled={disabled}
            value={value}
          />
        </DateTimeRangeContainer>
      </div>
    );
  }

  renderGridPicker(ranges, local) {
    let disabled = false;
    let value = `${this.state.start.format(
      "DD-MM-YYYY"
    )} - ${this.state.end.format("DD-MM-YYYY")}`;
    return (
      <Grid>
        <Row className="show-grid" style={{ textAlign: "center" }}>
          <Col xs={3} />
          <Col xs={6} md={4} id="DateTimeRangeContainerMobileMode">
            <DateTimeRangeContainer
              id="dateRange"
              ranges={ranges}
              start={this.state.start}
              end={this.state.end}
              local={local}
              applyCallback={this.applyCallback}
              smartMode
            >
              <FormControl
                id="formControlsTextB"
                type="text"
                label="Text"
                placeholder="Enter text"
                style={{ cursor: "pointer" }}
                disabled={disabled}
                value={value}
              />
            </DateTimeRangeContainer>
          </Col>
          <Col xs={3} md={4} />
        </Row>
        <br />
      </Grid>
    );
  }
  render() {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start)
      .add(1, "days")
      .subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days")
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)]
    };
    let local = {
      format: "DD-MM-YYYY",
      sundayFirst: false
    };
    // let maxDate = moment(end).add(24, "hour");
    if (this.props.disabled && this.props.week_start_date)
      return (
        <div>
          <SimpleInput
            type="text"
            name="Week"
            label="Week"
            inputValue={dateFormat(new Date(this.props.week_start_date), "mm/dd/yyyy") +" - "+ dateFormat(new Date(this.props.week_end_date), "mm/dd/yyyy") }
            disabled={true}
          />
        </div>
      );
    return (
      <div className="container">{this.renderVanillaPicker(ranges, local)}</div>
    );
  }
}
export default withCookies(DateRange);
