import { ChangeEmailStatus, ChangeEmailActions } from './ChangeEmailConstants';

const ChangeEmail_initialState = {
    ChangeEmail_Status: ChangeEmailStatus.ChangeEmail_POST.NEW,
    ChangeEmail_Message: '',
    ChangeEmailDetails: [],
}
export default  function  (state = ChangeEmail_initialState, action) {
    switch (action.type) {
        case ChangeEmailActions.ChangeEmail_POST.LOADING:
            return { ...state, ChangeEmail_Status: ChangeEmailStatus.ChangeEmail_POST.LOADING }
            case ChangeEmailActions.ChangeEmail_POST.NEW:
                return { ...state, ChangeEmail_Status: ChangeEmailStatus.ChangeEmail_POST.NEW, ChangeEmailDetails: [] }
    
        case ChangeEmailActions.ChangeEmail_POST.FAILED:
            return { ...state,  ChangeEmail_Status: ChangeEmailStatus.ChangeEmail_POST.FAILED, ChangeEmail_Message:action.error}
        case ChangeEmailActions.ChangeEmail_POST.SUCCESS:
          
          return { ...state,  ChangeEmail_Status: ChangeEmailStatus.ChangeEmail_POST.SUCCESS, ChangeEmailDetails:action.payload,
            ChangeEmail_Message:action.message
        }
          
        
        default:
            return { ...state,
                
            }
    }
}
