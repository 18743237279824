import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import "./MessageHistoryModel.css";
// import dateFormat from "dateformat"
import moment from "moment"
const modalRoot = document.getElementById("message_history");


export class Modal extends React.Component {
  componentDidMount(){

    document.body.style.overflow = "hidden"
  }
  componentWillUnmount(){
    document.body.style.overflow = "auto"
  }
    render() {
      const {
        onClose,
        // message = "Are you sure you want to delete this record",
        // onConfirm,
        item
      } = this.props
      // alert(JSON.stringify(item))
      return ReactDOM.createPortal(
        <div
          style={{
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            minHeight: "100vh",
            zIndex:"9999999999"
          }}
          // onClick={onClose}
        >
          <div
            style={{
              padding: 20,
              background: '#fff',
              borderRadius: '2px',
              display: 'inline-block',
              minHeight: '200px',
              margin: '1rem',
              position: 'relative',
              minWidth: '300px',
              boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              justifySelf: 'center',
            }}
          >
            {this.props.children}
            <div className="msge-pop-box">
            <div className="close-msg-p">
            {/* <i className="material-icons asm-icon" onClick={onClose}>
              close
            </i> */}
            </div>
            <div className="msge-pop-box-m">
               <div className="msge-pop-f-b">
                 <div className="msge-pop-l">
                  Sent to
                 </div>
                 <div className="msge-pop-f">
                  {item.sent_to}
                 </div>
               </div>
               <div className="msge-pop-f-b">
                 <div className="msge-pop-l">
                   Date
                 </div>
                 <div className="msge-pop-f">
                 {moment(item.last_updated).format("MM/DD/YYYY")
                //  dateFormat(item.last_updated,"mm/dd/yyyy")
                 }
                 </div>
               </div>
               <div className="msge-pop-f-b">
                 <div className="msge-pop-l">
                 Message
                 </div>
                 <div className="msge-pop-f text-area">
                 {item.message}
                 </div>
               </div>
                <div className="msg-c-btn">
                <button id="closeBtn" onClick={onClose} className="btn-yes">Close</button>
                </div>
            </div>
            </div>
          </div>
        </div>,
        modalRoot,
      )
    }
  }


class MessageHistoryModel extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }

    }
  handleShowMessageClick = () => this.setState({showModal: true})
  handleCloseModal = () => this.setState({showModal: false})
  render() {
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="del-pop-btn">
          <button id="detailsBtn" onClick={this.handleShowMessageClick} className="mesge-detailed">
          Details
          </button>
          {this.state.showModal ? (
            <Modal onClose={this.handleCloseModal}
            item = {this.props.item}
            >
            </Modal>
          ) : null}
        </div>
      </div>
    )
  }
}

export default MessageHistoryModel
