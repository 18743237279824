import { UploadBannerStatus, UploadBannerActions } from './UploadBannerConstants';

const UploadBanner_initialState = {
    UploadBanner_Status: UploadBannerStatus.UploadBanner_Post.NEW,
    UploadBanner_Message: '',
    UploadBannerDetails: {}
}
export default function (state = UploadBanner_initialState, action) {
    switch (action.type) {
        case UploadBannerActions.UploadBanner_Post.LOADING:
            return { ...state, UploadBanner_Status: UploadBannerStatus.UploadBanner_Post.LOADING }
        case UploadBannerActions.UploadBanner_Post.NEW:
            return { ...state, UploadBanner_Status: UploadBannerStatus.UploadBanner_Post.NEW,  UploadBannerDetails: {}}
        
        case UploadBannerActions.UploadBanner_Post.FAILED:
           // alert("test")
            return { ...state, UploadBanner_Status: UploadBannerStatus.UploadBanner_Post.FAILED, UploadBanner_Message:action.error }
        case UploadBannerActions.UploadBanner_Post.SUCCESS:
            //  state.UploadBannerDetails.push({})
            return { ...state, UploadBanner_Status: UploadBannerStatus.UploadBanner_Post.SUCCESS, UploadBannerDetails: action.payload, UploadBanner_Message:action.message }
        

        default:
            return { ...state }
    }
}
