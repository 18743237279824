
//******************Exchange Rate Server Calls******************;

import { GetQuotasContractDetailsActions } from './GetQuotasContractDetailsConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const GetQuotasContractDetailsServer = {
  //  GetQuotasContractDetails:GetQuotasContractDetails,
  GetQuotasContractDetails: GetQuotasContractDetails,
  GetCouponsbyFaceValues:GetCouponsbyFaceValues,
  GetYearQuotasDetails:GetYearQuotasDetails
};

//****************** GetQuotasContractDetails with coupons ******************;
function GetYearQuotasDetails(data) {
  // alert(JSON.stringify(data))
  const request = fetch(ROOT_URL + '/api/coupons/GetYearQuotasDetails', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log('Business quota details', response)
        // alert(response.paymentAccount)
        store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.UPDATE, payload: response.data, QuotaYearsPayload:response.QuotaYears,paymentAccount:response.paymentAccount});
        return;
      } else {
        store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED,error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED,error:failure_messages.quotasDetailsError });
    })
  }).catch((error) => {
    store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED,error:failure_messages.quotasDetailsError });
  })
  return { type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.LOADING }

};
//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function GetQuotasContractDetails(data) {
  // alert(JSON.stringify(data))
  const request = fetch(ROOT_URL + '/api/coupons/GetQuotasContractDetails', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log('Business quota details', response)
        // alert(response.paymentAccount)
        store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.SUCCESS, payload: response.data, QuotaYearsPayload:response.QuotaYears,paymentAccount:response.paymentAccount});
        return;
      } else {
        store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED,error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED,error:failure_messages.quotasDetailsError });
    })
  }).catch((error) => {
    store.dispatch({ type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED,error:failure_messages.quotasDetailsError });
  })
  return { type: GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.LOADING }

};

function GetCouponsbyFaceValues(data, token){

  const request = fetch(ROOT_URL + '/api/coupon/GetQuotasContractDetails', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log('coupons details', response.data)

        return;
      } else {
        
        return;
      }
    }).catch((error) => {
      
    })
  }).catch((error) => {
    
  })
  
}

//******************Get GetQuotasContractDetailss******************;


//******************Update GetQuotasContractDetails******************;


// function GetQuotasContractDetails(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.GetQuotasContractDetails.length>0)
//              store.dispatch({type:GetQuotasContractDetailsActions.GetQuotasContractDetails_Get.SUCCESS,payload:response.GetQuotasContractDetails});
//              else
//              store.dispatch({type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:GetQuotasContractDetailsActions.GetQuotasContractDetails_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:GetQuotasContractDetailsActions.GetQuotasContractDetails_Get.FAILED});
//           })
//        return {type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.LOADING}

//     };

// function updateGetQuotasContractDetails(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED});
//     })
// return {type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.LOADING}

// };
