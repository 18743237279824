import { connect } from "react-redux";
import CreateApplicantProfile_View from "./CreateApplicantProfile_View";
import { CreateApplicantProfileServer } from "./server";
import { CreateApplicantProfileActions } from "./CreateApplicantProfileConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    CreateApplicantProfile_Post_status:
    state.CreateApplicantProfileReducer.CreateApplicantProfile_Status,
    ApplicantProfileDetails: state.CreateApplicantProfileReducer.CreateApplicantProfileDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    CreateApplicantProfile: (data, Application) => {
      dispatch(CreateApplicantProfileServer.CreateApplicantProfile(data, Application));
    },
    resetReducerState:()=>{
      dispatch({type:CreateApplicantProfileActions.CreateApplicantProfile_Post.NEW})
    }
  };
};
const CreateApplicantProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateApplicantProfile_View);

export default CreateApplicantProfileContainer;
