import { InvoiceDetailsStatus, InvoiceDetailsActions } from './InvoiceDetailsConstants';

const InvoiceDetails_initialState = {
    InvoiceDetails_Status: InvoiceDetailsStatus.InvoiceDetails_GET.NEW,
    InvoiceDetails_Error: '',
    InvoiceDetailsDetails: [],
}
export default  function  (state = InvoiceDetails_initialState, action) {
    switch (action.type) {
        case InvoiceDetailsActions.InvoiceDetails_GET.LOADING:
            return { ...state, InvoiceDetails_Status: InvoiceDetailsStatus.InvoiceDetails_GET.LOADING }
            case InvoiceDetailsActions.InvoiceDetails_GET.NEW:
                return { ...state, InvoiceDetails_Status: InvoiceDetailsStatus.InvoiceDetails_GET.NEW, InvoiceDetailsDetails: [] }
    
        case InvoiceDetailsActions.InvoiceDetails_GET.FAILED:
            return { ...state,  InvoiceDetails_Status: InvoiceDetailsStatus.InvoiceDetails_GET.FAILED, InvoiceDetails_Error:action.error}
        case InvoiceDetailsActions.InvoiceDetails_GET.SUCCESS:
          
          return { ...state,  InvoiceDetails_Status: InvoiceDetailsStatus.InvoiceDetails_GET.SUCCESS, InvoiceDetailsDetails:action.payload}
          
        
        default:
            return { ...state,
                
            }
    }
}
