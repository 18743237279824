import React, { Component, Fragment } from 'react';
//import './GetFaceValues.css'
import { GetFaceValuesStatus } from './GetFaceValuesconstants';
import GetFaceValues from './GetFaceValues';
import { withRouter } from 'react-router-dom';
//import Fragment from "../ConfirmationContract/ConfirmationContract"
// import Snackbar from '@material-ui/core/Snackbar/';
// import Loading from '../../Utils/Loading';

export class GetFaceValuesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    if (
      this.props.GetFaceValues_GET_status !==
      GetFaceValuesStatus.GetFaceValues_GET.LOADING
    ) {
      // alert(this.props.coupon_type_id)
      props.getGetFaceValues(this.props.coupon_type_id);
    }
  }
  // UNSAFE_componentWillMount() {
  //   alert(this.props.coupon_type_id)
  //   this.props.getGetFaceValues(this.props.coupon_type_id)
  // }
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  closePostSnackBar = () => {
    this.setState({
      open: false,
    });
    // this.props.getGetFaceValues()
  };
  getScreen(status) {
    switch (status) {
      case GetFaceValuesStatus.GetFaceValues_GET.NEW:
        return <Fragment></Fragment>;

      case GetFaceValuesStatus.GetFaceValues_GET.SUCCESS:
        // alert(JSON.stringify(this.props.MaxMonthlyIssue))
        // this.props.setQuotasData(this.props.MaxMonthlyIssue)
        console.log("props of max quota", this.props)

        return (
          <Fragment>
            <GetFaceValues
              MaxMonthlyIssue={this.props.MaxMonthlyIssue}
              setQuotasData={this.props.setQuotasData}
              GetFaceValues={this.props.GetFaceValues}
            />
          </Fragment>
        );
        break;
      case GetFaceValuesStatus.GetFaceValues_GET.FAILED:
        //alert("failed")
        return (
          <div>
            <GetFaceValues
              MaxMonthlyIssue={this.props.MaxMonthlyIssue}
              setQuotasData={this.props.setQuotasData}
              GetFaceValues={this.props.GetFaceValues}
            />
          </div>
        );

      case GetFaceValuesStatus.GetFaceValues_GET.LOADING:
        this.state.open = true;
        return (

          <Fragment>

          <div className="fc-box">
            <div className="fc-header">
              <div className="fc-col-1 bold">
              FaceValue
              </div>
              <div className="fc-col-1">
              Max Monthly Limit
              </div>
            </div>
            <div className="fc-body-sec">
              <div className="fc-body-row fc-1">
              </div>
              <div className="fc-body-row fc-1">
    
                </div>
            </div>
          </div>
                <div className='outer-loader'>
                  <div className='inner-loader' style={{top:"8px", left:"-35px"}}>
                    <span className='input-group-addon'>
                      <i className='fa fa-refresh fa-spin bc'></i>
                    </span>
                  </div>
                </div>

          </Fragment>
           
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.GetFaceValues_GET_status)
    return this.getScreen(this.props.GetFaceValues_GET_status);
  }
}

export default withRouter(GetFaceValuesView);
