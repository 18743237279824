export const UploadCouponImageActions= {
	
	UploadCouponImage_Post :{
		NEW:"UploadCouponImage_Post_NEW",
		SUCCESS:"UploadCouponImage_Post_SUCCESS",
		FAILED:"UploadCouponImage_Post_FALIURE",
		LOADING:"UploadCouponImage_Post_LOADING",
		GOTO_CONTRACTS: "UploadCouponImage_Post_GOTO_CONTRACTS",
	}
}


export const UploadCouponImageStatus ={

	UploadCouponImage_Post :{
		NEW:"UploadCouponImage_Post_NEW",
		SUCCESS:"UploadCouponImage_Post_SUCCESS",
		FAILED:"UploadCouponImage_Post_FALIURE",
		LOADING:"UploadCouponImage_Post_LOADING",
		GOTO_CONTRACTS: "UploadCouponImage_Post_GOTO_CONTRACTS",

	}

}
