import { OverrideCouponsInventoryStatus, OverrideCouponsInventoryActions } from './OverrideCouponsInventoryConstants';

const OverrideCouponsInventory_initialState = {
    OverrideCouponsInventory_Status: OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.NEW,
    OverrideCouponsInventory_Error: '',
    OverrideCouponsInventory: [],
    ref_data:[]
}
export default  function  (state = OverrideCouponsInventory_initialState, action) {

    if(window.location.pathname === "/OverridePricing" && action.payload && action.payload.coupon_batch_id)
    {
        let updatedPauseCoupon =  state.OverrideCouponsInventory.find(item=>{
            if(item.coupon_batch_id == action.payload.coupon_batch_id)
                return item
        })

        updatedPauseCoupon.to_status_code = action.payload.to_status_code
        updatedPauseCoupon.status_id = action.payload.batch_status
        updatedPauseCoupon.display_color = action.payload.display_color
        
        state = {...state,OverrideCouponsInventory:[...state.OverrideCouponsInventory] }
        delete action.payload.coupon_batch_id
    }

    switch (action.type) {
        case OverrideCouponsInventoryActions.OverrideCouponsInventory_GET.LOADING:
            return { ...state, OverrideCouponsInventory_Status: OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.LOADING,OverrideCouponsInventory: [] }
            case OverrideCouponsInventoryActions.OverrideCouponsInventory_GET.NEW:
                return { ...state, OverrideCouponsInventory_Status: OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.NEW  }
    
        case OverrideCouponsInventoryActions.OverrideCouponsInventory_GET.FAILED:
            return { ...state,  OverrideCouponsInventory_Status: OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.FAILED, OverrideCouponsInventory_Error:action.error}
        case OverrideCouponsInventoryActions.OverrideCouponsInventory_GET.SUCCESS:
        
          return { ...state,  OverrideCouponsInventory_Status: OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.SUCCESS, OverrideCouponsInventory:action.payload, ref_data:action.ref_data}
        
        default:
            return { ...state,
            }
    }
}
