
//******************Exchange Rate Server Calls******************;

import { CouponTypesActions } from './GetCouponTypesConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"
import { coupon_transaction_type_id } from 'RefRecordsConstants'


export const CouponTypesServer = {
  //  GetCouponTypes:GetCouponTypes,
  GetCouponTypes: GetCouponTypes,
  //  updateGetCouponTypes:updateGetCouponTypes
};

//****************** GetCouponTypes with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function GetCouponTypes() {
  const request = fetch(ROOT_URL + '/api/coupons/GetCouponTypes', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then((response) => {
    response.json().then(response => {
 
      if (response.status == "200") {
        console.log("response Data:", response.data);


        const typeSubTypesData = response.data.doc
        const prepaidOfferFlagEnabled = response.data.enable_prepaid_offer_flag
        
       
        
        // let modifiedTypeSubTypes = typeSubTypesData.filter((item)=>{
          
          
        //   const isPrepaidOffer = (!item.coupon_transaction_subtype_id && item.value === coupon_transaction_type_id.prepaid)
          
        //   console.log("isPrepaid", isPrepaidOffer , prepaidOfferFlagEnabled)
          
        //   return isPrepaidOffer && !prepaidOfferFlagEnabled
        // })

        

        let modifiedTypeSubTypes= typeSubTypesData.flatMap((item, index) => {
          
          const isPrepaidOffer = (!item.coupon_transaction_subtype_id && item.value === coupon_transaction_type_id.prepaid)

          
          if (isPrepaidOffer && !prepaidOfferFlagEnabled){
            return []
          }

          else { 
            return item
          }
        })

        console.log("modified", modifiedTypeSubTypes)


        store.dispatch({ type: CouponTypesActions.GetCouponTypes_GET.SUCCESS, payload: modifiedTypeSubTypes});
        return;
      } else {

        store.dispatch({ type: CouponTypesActions.GetCouponTypes_GET.FAILED, error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: CouponTypesActions.GetCouponTypes_GET.FAILED, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
    
    store.dispatch({ type: CouponTypesActions.GetCouponTypes_GET.FAILED, error:failure_messages.unExpectedError });
  })
  return { type: CouponTypesActions.GetCouponTypes_GET.LOADING }

};
