import React, { Component } from "react";
import "../UploadBannerCard/UploadBannerCard.css";
// import { Link } from "@material-ui/core";

// import DeleteBannerContainer from "../../UploadBannerManage/DeleteBanner/DeleteBannerContainer";

const zoneIdData = [
  {name: "Select Zone", value:""},
  {name: "Zone 1", value:1},
  {name: "Zone 2", value:2},
  {name: "Zone 3", value:3},
  {name: "Zone 4", value:4},
]


class UploadBannerCardCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.banner.active ? props.banner.active : false,
      disable: props.banner.disable ? props.banner.disable : false,
      isLoading: false
    };
  }
  bannerCheckHandler = (banner, index) => {
    
    if (!this.state.active) {
      // alert(banner)
      this.props.addSelectedBanner(banner, index);
    } else {
      this.props.removeSelectedBanner(banner, index);
    }

    // this.props.banner.active = !this.state.active;
    // this.props.banner.disable = !this.state.disable;

    this.setState({
      active: !this.state.active,
      disable: !this.state.disable
    });
  };

  setIsLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };
  DaysBetween = (StartDate, EndDate) => {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(
      EndDate.getFullYear(),
      EndDate.getMonth(),
      EndDate.getDate()
    );
    const end = Date.UTC(
      StartDate.getFullYear(),
      StartDate.getMonth(),
      StartDate.getDate()
    );
    const days = (start - end) / oneDay;
    if (days > 0) return days;
    else return 0;
  };
  render() {
    const {
      bannerTitle = "",
      bannerImage = "",
      RetailerName  = "",
      startDate = "12/04/2020",
      expiryDate = "12/04/2020",
      number_of_days = "10",
      banner,
      // disableDelete = false,
      allocation,
      index = 0,
      notes = "",
    } = this.props;
    // alert(banner.active)
    return (
      <div
       className="upload-card-check">
        <div
          className="card-box"
          style={{
            background: this.state.active ? "rgba(222, 222, 222, 0.6)" : "#fff"
          }}
        >
          <div
            className="card-check-box"
            onClick={() => this.bannerCheckHandler(banner, index)}
          >
            <span
              id={"checkBox"+index}
              className="material-icons"
              style={{
                background: this.state.active ? "#656565" : "#fff"
              }}
            >
              check
            </span>
          </div>
          <div 
            data-title={notes.length > 300
              ? notes.slice(0, 300) + "..."
              : notes
            }
          className="bbb-box">
          <div className="card-img">
            <img src={bannerImage} alt="not found" />
          </div>
          <div className="card-banner-heading">
            <p>File:  {bannerTitle.length > 20
              ? bannerTitle.slice(0, 20) + "..."
              : bannerTitle}
              </p>
              <p>
              Bus: {RetailerName.length > 20
              ? RetailerName.slice(0, 20) + "..."
              : RetailerName}
              </p>
          </div>

          
          <div className="card-banner-text-box">
            <div className="card-banertext-left">
              <div className="card-headig">Start date</div>
            </div>
            <div className="card-banertext-left text-right">
              <div className="card-text">{startDate}</div>
            </div>
          </div>
          <div className="card-banner-text-box">
            <div className="card-banertext-left">
              <div className="card-headig">Expiration Date</div>
            </div>
            <div className="card-banertext-left text-right">
              <div className="card-text">{expiryDate}</div>
            </div>
          </div>
          <div className="card-banner-text-box">
            <div className="card-banertext-left">
              <div className="card-headig">No. of days</div>
            </div>
            <div className="card-banertext-left text-right">
              <div className="card-text">{number_of_days}</div>
            </div>
          </div>
          <div className="card-banner-text-box">
            <div className="card-banertext-left">
              <div className="card-headig">Frequency</div>
            </div>
            <div className="card-banertext-left text-right">
              <div className="card-text">
                {banner.seven_days
                  ? "7 days a week"
                  : banner.week_days
                  ? "Weekdays"
                  : banner.week_ends
                  ? "Weekends"
                  : ""}
              </div>
            </div>
          </div>
          <div className="card-banner-text-box">
            <div className="card-banertext-left">
              <div className="card-headig">Allocation</div>
            </div>
            <div className="card-banertext-left text-right">
              <div className="card-text">
              {allocation} /{" "}
              {number_of_days}
              </div>
            </div>
          </div>


          <div className="card-banner-text-box">
          <div className="card-banertext-left">
            <div className="card-headig">Zone Type</div>
          </div>
            <div className="card-banertext-left text-right">
              <div className="card-text">
                {
                  banner.zone_id ?
                    zoneIdData.map((item, index) => {
                      return (item.value === banner.zone_id ? item.name : "")
                    }):
                    "---"
                }
              </div>
          </div>
        </div>
          {/* <div className="card-button-2">
            <div className="card-button">
              <a onClick={() => this.props.duplicate_Banner(banner)}>
                Duplicate
              </a>
            </div>
            <div className="card-button margin">
              <a onClick={() => this.props.edit_Banner(banner)}>Edit</a>
            </div>
          </div> */}
          {/* <div className="card-button-center">
            {!disableDelete ? 
            <DeleteBannerContainer
              isLoading={this.state.isLoading}
              setIsLoading={this.setIsLoading}
              banner={banner}
            />
            : <a >Delete</a>
        }
          </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default UploadBannerCardCheck;
