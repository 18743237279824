import React, { Component, Fragment } from "react";
import "../ListOfApplicants/ListOfApplicants.css";
import { withRouter } from "react-router-dom";
import { Api_Pagination } from "RefRecordsConstants";
import { Link } from "react-router-dom";
import ListOfApplicantsContainer from "./ListOfApplicantsContainer"
export class ListOfApplicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
        NoOfPages: 0,
        activePage: 1,
        isLoading: false,
    };
  }

  setLoading = (loading) => {
    //  alert(loading)
    this.state.isLoading = loading;
  };

  setItemList = (ListLength) => {
    if (ListLength - 1 > 0)
      this.setState({
        NoOfPages:
          Math.trunc(
            (ListLength - 1) / Api_Pagination.ApplicantsLimit
          ) + 1,
      });
  };

  ChangePage = (page) => {
    // alert(this.state.isLoading)
    console.log("Loading page:", this.state.isLoading);
    if (this.state.activePage != page && !this.state.isLoading) {
      this.setState({
        activePage: page,
      });
      //   this.setLoading(true)
    }
  };
  handleNext = () => {
    //   alert(this.state.isLoading)
    if (this.state.activePage < this.state.NoOfPages && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage + 1,
      });
      // this.setLoading(true)
    }
  };
  handlePrev = () => {
    // alert(this.state.isLoading)
    if (this.state.activePage > 1 && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage - 1,
      });
      // this.setLoading(true)
    }
  };
  render() {
    const items = [];
    var key = 1
    items.push(
      <Link
        key={key}
        to="#"
        onClick={() => this.ChangePage(1)}
        className={this.state.activePage == 1 ? "active-page" : null}
      >
        {" "}
        1{" "}
      </Link>
    );
    let i = 2;
    let offset = 6;
    if (this.state.activePage > 3 && this.state.NoOfPages > 5) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      key +=1
      items.push(<Link key={key} to="#">{"..."}</Link>);
    }
    if (this.state.activePage + 3 > this.state.NoOfPages && i > 2)
      i = this.state.NoOfPages - 3;
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset - 1;
      index++
    ) {
      key +=1
      items.push(
        <Link
          to="#"
          key={key}
          onClick={() => this.ChangePage(index)}
          className={i == this.state.activePage ? "active-page" : null}
        >
          {" "}
          {i}{" "}
        </Link>
      );
      i++;
    }

    if (this.state.NoOfPages > this.state.activePage + 2)
      if (this.state.NoOfPages > 5)
    {
      key+=1
      items.push(<Link key={key} to="#">{"..."}</Link>);
    }
    key+=1
    items.push(
      <Link
        to="#"
        key={key}
        onClick={() => this.ChangePage(this.state.NoOfPages)}
        className={
          this.state.activePage == this.state.NoOfPages ? "active-page" : null
        }
      >
        {this.state.NoOfPages}{" "}
      </Link>
    );
    return (
      <Fragment>
        <div className="la-main-box">
          <div className="la_conatiner">
            <div className="la-heading">
              <h1>List of New Applicants</h1>
            </div>
            <ListOfApplicantsContainer cookies={this.props.cookies} 
                                activePage={this.state.activePage}
                                setItemList={this.setItemList}
                                setLoading={this.setLoading}
            />
            </div></div>
            {this.state.NoOfPages > 1 ? (
                <div className="pagination-box">
                  <div className="pagination-inner-box">
                    <div className="box-pagination">
                      <div className="next-btn">
                        <div className="cont1">
                          <Link to="#" onClick={this.handlePrev}>prev</Link>
                        </div>
                        <div style={{minWidth:300}} className="count-btn">
                        {items}
                        </div>
                        <div className="cont1">
                          <Link to="#" onClick ={this.handleNext}>next</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Fragment>
    );
  }
}

export default withRouter(ListOfApplicants);
