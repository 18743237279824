import { connect } from "react-redux";
import RetailerListView from "./RetailerList_View";
import {RetailerListServer} from "./server"
import {RetailerListActions} from './RetailerListConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        RetailerList_GET_status:state.RetailerListReducer.RetailerList_Status,
        RetailerList:state.RetailerListReducer.RetailerListDetails,
        RetailerList_Error:state.RetailerListReducer.RetailerList_Error
       // RetailerListIndex:state.RetailerListReducer.RetailerListIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getRetailerList: (data) => {

           dispatch( RetailerListServer.getRetailerList(data) )
        },
        getRetailers: (data) => {

            dispatch( RetailerListServer.getRetailers(data) )
         },
        resetReducerState:()=>{
            dispatch({type:RetailerListActions.RetailerList_GET.NEW})
        }
    }
};
const RetailerListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RetailerListView)

export default RetailerListContainer;