import React, { Component, Fragment } from "react";

import "../../OverridePricing/OverridePricing.css";
// import Dateformat from "dateformat";
import moment from "moment"
import UserMessage from "../../UserManagement/UserMessage";
import CouponInventoryPreviewModal from '../CouponInventoryPreviewModal'
import {coupon_transaction_subtype_id, ref_status} from 'RefRecordsConstants'
import EditIcon from '@material-ui/icons/Edit';
import {withCookies} from 'react-cookie'

class CouponsInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: "",
      accountFlag: false,
      modalVisible: false,
    };
  }
  getUTC_date = (date) => {
    var d = new Date(date);
    d.setDate(d.getUTCDate());
    // alert(d)
    return d;
  };
  render() {
    const { CouponsInventory,  ref_data,
    // ApprovedBusiness
       } = this.props;

    console.log('kajsndkajsndkjasd', this.props)

    // CouponsInventory.map((res,item)=>{
    //   console.log("this is coupon", res.to_status_code)
    // })
    // console.log("this is coupon", CouponsInventory)
    //console.log("ref data", this.props.ref_data.BatchStatus)
    return (
      <Fragment>
        <table //style = {{    height: '5vh'}}
          className="table-p"
        >
          <thead className="thead-all-over">
            <tr className="heading-tab">
              <th>Offer Id</th>
              <th>Offer Title</th>
              <th>Offer Type</th>
              <th>Offer Value</th>
              {/* <th>Offer Terms</th> */}
              <th>Inventory</th>
              <th>Claimed Offers</th>
              <th>Redeemed Offers</th>
              <th>Promo Start Date Time</th>
              <th>Promo End Date Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="tbody-over">
            {CouponsInventory.map((res, index) => {

           if( this.props.cookies.get("Coupon_Batch_Id_ForApproval"))
{
    if( this.props.cookies.get("Coupon_Batch_Id_ForApproval") == res.coupon_batch_id)
    {
      this.props.cookies.remove("Coupon_Batch_Id_ForApproval")
      this.props.setEditCouponData(res)
      // alert(res.coupon_batch_id)
    }
}
              var terms = res.coupon_terms == "" ||
              res.coupon_terms == undefined ||
              res.coupon_terms == null
              ? (coupon_transaction_subtype_id.PercentageType == res.coupon_transaction_subtype_id ?(res.coupon_face_value+"%"):("$"+res.coupon_face_value))
              + " off your next purchase."
                : res.coupon_terms
                
              return <tr key={index}>
                <td>{res.coupon_batch_id}</td>
                <td>{res.offer_name ? (res.offer_name.length > 15 ? res.offer_name.slice(0,15)+"...":res.offer_name) :"---"}</td>
                <td>{res.coupon_transaction_type}</td>
                {
                  parseInt(res.coupon_transaction_subtype_id) == coupon_transaction_subtype_id.PercentageType ?
                  <td>{res.coupon_face_value}%</td>
                  :
                  <td>${res.coupon_face_value}</td>
                }
                {/* <td title={terms} style={{textAlign:"left",
              whiteSpace: 'nowrap',
              overflow: 'hidden'
              }}>
                  {terms.length > 15
                ? terms.slice(0, 15) + "..."
                : terms}
                </td> */}
                <td>{res.Inventory}</td>
                <td>{res.Claimed_Coupons}</td>
                <td>{res.Redeemed_Coupons}</td>
                <td>{moment(res.promotion_start_date).format("MMMM Do YYYY") + " at " + moment(res.promotion_start_date).format("h:mm A")
                // Dateformat(res.promotion_start_date, "mmmm dS yyyy")
                }</td>
                <td>{moment(res.promotion_end_date).format("MMMM Do YYYY") + " at " + moment(res.promotion_end_date).format("h:mm A")
                // Dateformat(res.promotion_end_date, "mmmm dS yyyy")
                }</td>
                <td>
                <div key={index} ><span style={{color:res.display_color}}><>
                {res.to_status_code}
                </></span></div>
                  {/* {ref_data.BatchStatus.map((item, index)=>(<div key={index} ><span style={{color:new Date(res.promotion_end_date)<new Date()?ref_data.BatchStatus[11].display_color:item.display_color}}><>
                {item.status_id==res.status_id? (item.status_id === ref_status.SAVED && res.feedback) ? "FEEDBACK SENT":new Date(res.promotion_end_date )<new Date()?ref_data.BatchStatus[11].to_status_code: item.to_status_code:''}
                </></span></div>))} */}
                </td>
                <td>
                    {/* res.batch_status === ref_status.SAVED ? */}
                    <div className="edit-func">
                      <CouponInventoryPreviewModal 
                      edit_id={(res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE || res.status_id === ref_status.PENDING) ? res.to_status_code+'previewEditable' : res.to_status_code+'previewNon-editable'}
                      ref_data = {this.props.ref_data} coupon={res} getCouponsInventory={this.props.getCouponsInventory} resetFields={this.props.resetFields}/>
                      < EditIcon
                        onClick={()=>this.props.setEditCouponData(res)}
                        style={{
                          opacity: (res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE || res.status_id === ref_status.PENDING) ? 1 : 0.3,
                          pointerEvents: (res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE  || res.status_id === ref_status.PENDING) ? 'cursor' : 'none'
                        }}
                        id={(res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE || res.status_id === ref_status.PENDING) ? res.to_status_code+'editable' : res.to_status_code+'non-editable'}
                      />
                    </div>
                </td>
              </tr>
            
          })}
          </tbody>
        </table>
        {CouponsInventory.length == 0 ? (
        <>
          <UserMessage message="Coupon Inventory is empty" /> <span>
          {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader'  style={{top:"31px", left:"430px"}}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
          </span>
     
        </>
        ) : null}
      </Fragment>
    );
  }
}

export default withCookies(CouponsInventory);
