import { connect } from "react-redux";
import OverridePricingView from "./OverridePricing_View";
import {OverridePricingServer} from "./server"
import {OverridePricingActions} from './OverridePricingConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        OverridePricing_post_status:state.OverridePricingReducer.OverridePricing_Status,
        Generated:state.OverridePricingReducer.Generated,
        CouponsDetails:state.OverridePricingReducer.OverridePricingDetails,
        message:state.OverridePricingReducer.message
    };
}; 
const mapDispatchToProps = dispatch => {
    return {
        GenerateCouponsFromDraft: (data) => {
            dispatch( OverridePricingServer.GenerateCouponsFromDraft(data) )
         },
        createDraft: (data) => {
            dispatch(OverridePricingServer.createDraft(data))
        },
        updateDraft: (data) => {
            dispatch(OverridePricingServer.updateDraft(data))
        },
        GenerateOverridePriceCoupons: (data) => {

           dispatch( OverridePricingServer.GenerateOverridePriceCoupons(data) )
        },
        updateExpiryDate:(data)=>{
            dispatch( OverridePricingServer.updateExpiryDate(data) )
            
        },
        resetReducerState:async () => {
            //alert('test')
         await   dispatch( {type:OverridePricingActions.OverridePricing_Post.LOADING})
         await   dispatch( {type:OverridePricingActions.OverridePricing_Post.NEW})
            
         },
    }
};
const OverridePricingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OverridePricingView)

export default OverridePricingContainer;