import {BusinessEnrollment_Actions, BusinessEnrollment_Status } from './constants';
const get_initialState = {
  businessEnrollment_get_status: BusinessEnrollment_Status.BusinessEnrollment_Get.NEW,
  BusinessEnrollments: [],
};

//******************Get Reducer******************;

export const NJT_BusinessEnrollment_Get_Reducer = (state = get_initialState, action) => {
  //console.log(action.type);
  switch (action.type) {

    case BusinessEnrollment_Actions.BusinessEnrollment_Get.NEW:
     // console.log("I am from Reduce NEW..");
      return { ...state, businessEnrollment_get_status: BusinessEnrollment_Status.BusinessEnrollment_Get.NEW};

    case BusinessEnrollment_Actions.BusinessEnrollment_Get.SUCCESS:
     console.log("I am from Reduce SUCCESS..",action.status);
     if(action.status == undefined)
     {
       console.log("here")
     return { ...state, businessEnrollment_get_status: BusinessEnrollment_Status.BusinessEnrollment_Get.SUCCESS, BusinessEnrollments: action.payload };
     } 
      else
      return { ...state, businessEnrollment_get_status: BusinessEnrollment_Status.BusinessEnrollment_Get.SUCCESS};

      console.log(state);
    case BusinessEnrollment_Actions.BusinessEnrollment_Get.OFFLINE:
    //  console.log("I am from Reduce NEW..");
      return { ...state, businessEnrollment_get_status: BusinessEnrollment_Status.BusinessEnrollment_Get.OFFLINE,  BusinessEnrollments: action.payload };

     case BusinessEnrollment_Actions.BusinessEnrollment_Get.LOADING:
     //  console.log("I am from Reduce NEW..");
       return { ...state, businessEnrollment_get_status: BusinessEnrollment_Status.BusinessEnrollment_Get.LOADING };
      
    case BusinessEnrollment_Actions.BusinessEnrollment_Get.FAILURE:
    //  console.log("I am from Reduce NEW..");
        return { ...state, businessEnrollment_get_status: BusinessEnrollment_Status.BusinessEnrollment_Get.FAILURE, BusinessEnrollments: action.payload  };
            
    default:
      return { ...state }
  }
};


//******************Post Reducer******************;

const post_initialState = {
  businessEnrollment_post_status: BusinessEnrollment_Status.BusinessEnrollment_Post.NEW,
  BusinessEnrollments: null,
};

export const NJT_BusinessEnrollment_Post_Reducer = (state = post_initialState, action) => {
  //console.log(action.type);
  switch (action.type) {

    case BusinessEnrollment_Actions.BusinessEnrollment_Post.NEW:
     // console.log("I am from Reduce NEW..");
      return { ...state, businessEnrollment_Post_status: BusinessEnrollment_Status.BusinessEnrollment_Post.NEW };

    case BusinessEnrollment_Actions.BusinessEnrollment_Post.SUCCESS:
     console.log("I am from Reduce SUCCESS..", action.payload,action);

      return { ...state, businessEnrollment_post_status: BusinessEnrollment_Status.BusinessEnrollment_Post.SUCCESS, BusinessEnrollments: action.payload,};

    case BusinessEnrollment_Actions.BusinessEnrollment_Post.OFFLINE:
    //  console.log("I am from Reduce NEW..");
      return { ...state, businessEnrollment_post_status: BusinessEnrollment_Status.BusinessEnrollment_Post.OFFLINE, BusinessEnrollments: action.payload };

     case BusinessEnrollment_Actions.BusinessEnrollment_Post.LOADING:
     //  console.log("I am from Reduce NEW..");
       return { ...state, businessEnrollment_post_status: BusinessEnrollment_Status.BusinessEnrollment_Post.LOADING, BusinessEnrollments: action.payload };
      
    case BusinessEnrollment_Actions.BusinessEnrollment_Post.FAILURE:
    //  console.log("I am from Reduce NEW..");
        return { ...state, businessEnrollment_post_status: BusinessEnrollment_Status.BusinessEnrollment_Post.FAILURE, BusinessEnrollments: action.payload };
            
    default:
      return { ...state }
  }
};



