import React, {  Fragment, PureComponent } from "react";
//import './DeleteBanner.css'
import { DeleteBannerStatus } from "./DeleteBannerConstants";
// import Loading from "../../Utils/Loading";
import DeleteModal from "../../Cards/UploadBannerCard/DeleteModal";
import Snackbar from "@material-ui/core/Snackbar/";
import {failure_messages} from 'Messages'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export class DeleteBannerView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showModal: false,
    };
  }

  deleteBanner = () => {
    var flag = false
    if (this.props.banner.TotalAllocated > 0) {
      const scheduled_day = new Date(this.props.banner.scheduled_day);
      if (scheduled_day < new Date()) {
        const days = this.days_between(new Date(), scheduled_day);

        if (days > 30) {
          this.handleShowMessageClick();

        }else
        flag = true
      }
      else
      flag = true
    } else {
      this.handleShowMessageClick();
    }
    if(flag){
      toast.error("you can’t delete this banner")
      // this.setState({
      //   open:true
      // })
    }
  };

  days_between = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  };
  handleShowMessageClick = () => this.setState({ showModal: true });

  handleCloseModal = () => this.setState({ showModal: false });

  handleConfirm = () => {
    this.props.setIsLoading(true);
    this.props.deleteBanner({ banner_id: this.props.banner.banner_id });
  };
  getScreen(status) {
    // alert(status)
    switch (status) {
      case DeleteBannerStatus.DeleteBanner_POST.NEW:
        return (
          <Fragment>
            <DeleteModal
              showModal={this.state.showModal}
              handleShowMessageClick={this.deleteBanner}
              handleCloseModal={this.handleCloseModal}
              handleConfirm={this.handleConfirm}
              banner = {this.props.banner}
            />
          </Fragment>
        );

      case DeleteBannerStatus.DeleteBanner_POST.SUCCESS:
        if (this.props.isLoading) {
        toast.success(this.props.DeleteBanner_Message)
        this.props.setIsLoading(false);
        }
        return (
          <Fragment>
            <DeleteModal
              showModal={this.state.showModal}
              handleShowMessageClick={this.deleteBanner}
              handleCloseModal={this.handleCloseModal}
              handleConfirm={this.handleConfirm}
              banner = {this.props.banner}
            />
          </Fragment>
        );
        break;
      case DeleteBannerStatus.DeleteBanner_POST.FAILED:
      
        if (this.props.isLoading) {
          toast.error(this.props.DeleteBanner_Message)
          this.props.setIsLoading(false);
        }
        return (
          <Fragment>
            <DeleteModal
              showModal={this.state.showModal}
              handleShowMessageClick={this.deleteBanner}
              handleCloseModal={this.handleCloseModal}
              handleConfirm={this.handleConfirm}
              banner = {this.props.banner}
            />
          </Fragment>
        );

      case DeleteBannerStatus.DeleteBanner_POST.LOADING:
        if (this.props.isLoading)
          return (
            <Fragment>
            <DeleteModal
              showModal={this.state.showModal}
              handleShowMessageClick={this.deleteBanner}
              handleCloseModal={this.handleCloseModal}
              handleConfirm={this.handleConfirm}
              banner = {this.props.banner}
              loading = {true}
            />
          </Fragment>
            // <div
            //   style={{
            //     textAlign: "center",
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     width: "100%",
            //   }}
            // >
            //   <Loading />
            // </div>
          );
        else
          return (
            <Fragment>
              <DeleteModal
                showModal={this.state.showModal}
                handleShowMessageClick={this.deleteBanner}
                handleCloseModal={this.handleCloseModal}
                handleConfirm={this.handleConfirm}
                banner = {this.props.banner}
              />
            </Fragment>
          );
      default:
        return <div />;
    }
  }

  render() {
    // alert(this.props.isLoading)
    //  alert(this.props.DeleteBanner_POST_status)
    return (
      <Fragment>
        {     this.getScreen(this.props.DeleteBanner_POST_status)}
        <Snackbar
          open={this.state.open}
          autoHideDuration={2000}
          onClose={() => this.setState({ open: false })}
          //  TransitionComponent={<Slide {...props} direction="up" />}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{failure_messages.DeleteBanner}</span>}
        />
      </Fragment>
      )

  }
}

export default DeleteBannerView;
