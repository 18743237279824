import { GetPreviousCouponsStatus, PreviousCouponsActions } from './GetPreviousCouponsConstants';

const GetPreviousCoupons_initialState = {
    GetPreviousCoupons_Status: GetPreviousCouponsStatus.GetPreviousCoupons_GET.NEW,
    GetPreviousCoupons_Error: '',
    GetPreviousCoupons: [],
    exchangeRates:[]
}
export default  function  (state = GetPreviousCoupons_initialState, action) {
    switch (action.type) {
        case PreviousCouponsActions.GetPreviousCoupons_GET.LOADING:
            return { ...state, GetPreviousCoupons_Status: GetPreviousCouponsStatus.GetPreviousCoupons_GET.LOADING }
            case PreviousCouponsActions.GetPreviousCoupons_GET.NEW:
                return { ...state, GetPreviousCoupons_Status: GetPreviousCouponsStatus.GetPreviousCoupons_GET.NEW }
    
        case PreviousCouponsActions.GetPreviousCoupons_GET.FAILED:
            return { ...state,  GetPreviousCoupons_Status: GetPreviousCouponsStatus.GetPreviousCoupons_GET.FAILED, GetPreviousCoupons_Error:action.error }
        case PreviousCouponsActions.GetPreviousCoupons_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  GetPreviousCoupons_Status: GetPreviousCouponsStatus.GetPreviousCoupons_GET.SUCCESS, GetPreviousCoupons:action.payload, exchangeRates:action.exchangeRates}
        
        default:
            return { ...state,
                GetPreviousCoupons_Status: GetPreviousCouponsStatus.GetPreviousCoupons_GET.NEW,
            }
    }
}
