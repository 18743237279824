
//******************Exchange Rate Server Calls******************;

import { PreviousCouponsActions } from './GetCouponImagesConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"

export const PreviousCouponsServer = {
  //  GetCouponImages:GetCouponImages,
  GetCouponImages: GetCouponImages,
  //  updateGetCouponImages:updateGetCouponImages
};

//****************** GetCouponImages with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function GetCouponImages(data) {

  const request = fetch(ROOT_URL + '/api/coupons/GetCouponImages', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        // console.log('Business quota details', response.data)
        // alert(JSON.stringify(response.ref_data))
  //****************** responce.data contains values of business Quotas along with business crontact
  //****************** responce.CouponFaceValues contains count of generated coupon based on business_id and year_id
        store.dispatch({ type: PreviousCouponsActions.GetCouponImages_GET.SUCCESS, payload: response.data,ref_data: response.ref_data});
        return;
      } else {
        store.dispatch({ type: PreviousCouponsActions.GetCouponImages_GET.FAILED, error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: PreviousCouponsActions.GetCouponImages_GET.FAILED, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
    store.dispatch({ type: PreviousCouponsActions.GetCouponImages_GET.FAILED, error:failure_messages.unExpectedError });
  })
  return { type: PreviousCouponsActions.GetCouponImages_GET.LOADING }

};
