import { connect } from "react-redux";
import RetailerCouponApprovalView from "./RetailerCouponApproval_View";
import {RetailerCouponApprovalServer} from "./server"
import {RetailerCouponApprovalActions} from './RetailerCouponApprovalConstants'
import {withCookies}from "react-cookie"
const mapStateToProps = state => {
    console.log(state)
    return {
        RetailerCouponApproval_Status:state.RetailerCouponApprovalReducer.RetailerCouponApproval_Status,
        RetailerCouponApproval:state.RetailerCouponApprovalReducer.RetailerCouponApprovalDetails,
        ApprovalCheckList:state.ApprovalCheckListReducer.ApprovalCheckListDetails,
        RetailerCouponApproval_Message:state.RetailerCouponApprovalReducer.RetailerCouponApproval_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        handleRetailerCouponApproval: (data) => {

           dispatch( RetailerCouponApprovalServer.handleRetailerCouponApproval(data) )
        },
        resetReducerState:async ()=>{
           // await dispatch({type:RetailerCouponApprovalActions.RefrenceData_Update.LOADING})
             dispatch({type:RetailerCouponApprovalActions.RefrenceData_Update.NEW})
    }
    }
};
const RetailerCouponApprovalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RetailerCouponApprovalView)

export default withCookies(RetailerCouponApprovalContainer);