import { RetailerCouponApprovalStatus, RetailerCouponApprovalActions } from './RetailerCouponApprovalConstants';

const RetailerCouponApproval_initialState = {
    RetailerCouponApproval_Status: RetailerCouponApprovalStatus.RefrenceData_Update.NEW,
    RetailerCouponApproval_Message: '',
    RetailerCouponApprovalDetails: [],
}
export default  function  (state = RetailerCouponApproval_initialState, action) {
    switch (action.type) {
        case RetailerCouponApprovalActions.RefrenceData_Update.LOADING:
            return { ...state, RetailerCouponApproval_Status: RetailerCouponApprovalStatus.RefrenceData_Update.LOADING }
            case RetailerCouponApprovalActions.RefrenceData_Update.NEW:
                return { ...state, RetailerCouponApproval_Status: RetailerCouponApprovalStatus.RefrenceData_Update.NEW, RetailerCouponApproval_Message:"" }
    
        case RetailerCouponApprovalActions.RefrenceData_Update.FAILED:
            return { ...state,  RetailerCouponApproval_Status: RetailerCouponApprovalStatus.RefrenceData_Update.FAILED, RetailerCouponApproval_Message:action.error}
        case RetailerCouponApprovalActions.RefrenceData_Update.SUCCESS:
          
          return { ...state,  RetailerCouponApproval_Status: RetailerCouponApprovalStatus.RefrenceData_Update.SUCCESS, RetailerCouponApprovalDetails:action.payload, RetailerCouponApproval_Message:action.message}
          
        default:
            return { ...state,
                RetailerCouponApproval_Status: RetailerCouponApprovalStatus.RefrenceData_Update.NEW,
            }
    }
}
