import { connect } from "react-redux";
import GetPreviousCouponsView from "./GetPreviousCoupons_View";
import {PreviousCouponsServer} from "./server"
// import {PreviousCouponsActions} from './GetPreviousCouponsConstants'
const mapStateToProps = state => {
  //  console.warn(state.GetPreviousCouponsReducer.GetPreviousCoupons)
    return {
        GetPreviousCoupons_GET_status:state.GetPreviousCouponsReducer.GetPreviousCoupons_Status,
        PreviousCoupons:state.GetPreviousCouponsReducer.GetPreviousCoupons,
        exchangeRates:state.GetPreviousCouponsReducer.exchangeRates,
        CouponFaceValues:state.GetPreviousCouponsReducer.CouponFaceValues,
        GetPreviousCoupons_Error:state.GetPreviousCouponsReducer.GetPreviousCoupons_Error

    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GetPreviousCoupons: (data) => {

           dispatch( PreviousCouponsServer.GetPreviousCoupons(data) )
        },
        
    }
};
const GetPreviousCouponsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetPreviousCouponsView)

export default GetPreviousCouponsContainer;