import React, { Component } from 'react'
//import './UsersInventory.css'
import { UsersInventoryStatus } from './UsersInventoryConstants';
// import LoadingScreen from "../.utils/LoadingScreen"
import UsersInventory from './UsersInventory'
import { withCookies } from 'react-cookie';
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Api_Pagination } from "RefRecordsConstants";
import LoadingScreen from "../../Utils/Loading";

export class UsersInventoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      callBack: false,
      mount: false,
      filter:props.filter,
      activePage:0
    }
    // props.getUsersInventory()
  }

  getUsersInventory = () => {
    // alert("test")
//     if(this.props.cookies.get("BusinessProfileData"))
//  {   const data = {
//       business_id: this.props.cookies.get("BusinessProfileData").business_id
//     }
    this.props.getUsersInventory()
    // alert(JSON.stringify(data))
  // }
    // this.props.getUsersInventory(data, realm.objects('UserToken')[0].AccessToken)
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.activePage !== nextProps.activePage){
      // nextProps.setLoading(true)
      const data = {
        offset : (nextProps.activePage - 1) * Api_Pagination.UsersListLimit,
        filter : nextProps.filter
        
      }
      // alert(JSON.stringify(data))
      nextProps.getUsersInventory(data)

      return{
        activePage:nextProps.activePage
      }
   }
   if(JSON.stringify(prevState.filter) !== JSON.stringify(nextProps.filter))
  {
    const data = {
      offset : 0,
      filter : nextProps.filter
    }
    nextProps.getUsersInventory(data)

     return{
      filter:nextProps.filter
    }
  }
  if(nextProps.UsersInventory_GET_status === UsersInventoryStatus.UsersInventory_GET.SUCCESS){
    nextProps.setLoading(false)
        
    nextProps.setItemList(nextProps.UsersCount)
   
  }
  else if(nextProps.UsersInventory_GET_status === UsersInventoryStatus.UsersInventory_GET.FAILED){
    nextProps.setLoading(false)
    nextProps.setItemList(0)
  }
   return null;
 }

  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }
  getScreen(status) {
    switch (status) {
      case UsersInventoryStatus.UsersInventory_GET.NEW:

        return (
        <div style={{marginTop:20}}>
         
          <LoadingScreen />
         
           </div>
        );


      case UsersInventoryStatus.UsersInventory_GET.SUCCESS:
       
        this.state.callBack = true
        return (

            <UsersInventory UsersInventory={this.props.UsersInventory}
            applySortByBusinessName={this.props.applySortByBusinessName}
              navigation={this.props.navigation}
              filter = {this.props.filter}
              cookies = {this.props.cookies}
              activePage = {this.props.activePage}
              UsersRoles = {this.props.UsersRoles}
              sortEnabled={this.props.sortEnabled}
              sortType={this.props.sortType}
            />
        
        );
        break;
      case UsersInventoryStatus.UsersInventory_GET.FAILED:
        this.state.callBack = true
       
        return (

            <UsersInventory
              UsersInventory={this.props.UsersInventory}
              applySortByBusinessName={this.props.applySortByBusinessName}
              filter = {this.props.filter}
              failed ={true}
              cookies = {this.props.cookies}
              activePage = {this.props.activePage}
              UsersRoles = {this.props.UsersRoles}
              sortEnabled={this.props.sortEnabled}
              sortType={this.props.sortType}
            />
        );

      case UsersInventoryStatus.UsersInventory_GET.LOADING:
        return (

          <UsersInventory
          UsersInventory={this.props.UsersInventory}
          applySortByBusinessName={this.props.applySortByBusinessName}
          filter = {this.props.filter}
          failed ={true}
          cookies = {this.props.cookies}
          activePage = {this.props.activePage}
          UsersRoles = {this.props.UsersRoles}
          sortEnabled={this.props.sortEnabled}
          sortType={this.props.sortType}
        />

          // <div style={{marginTop:20}}>
 
          //   <LoadingScreen />
           
          //    </div>
          );
        break;
      default:
        break;
    }

  }
  render() {
    return (
      

        this.getScreen(this.props.UsersInventory_GET_status)
      
    )
  }
}

export default withCookies(UsersInventoryView)
