import { connect } from "react-redux";
import EnrollmentFormView from "./EnrollmentFormView";
import {BusinessEnrollmentServer} from "./server"
const mapStateToProps = state => {
    console.log(state)
    return {

        businessEnrollment_post_status:state.NJT_BusinessEnrollment_Post_Reducer.businessEnrollment_post_status,
        BusinessEnrollment:state.NJT_BusinessEnrollment_Post_Reducer.BusinessEnrollment,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getBusinessEnrollments: () => {
            BusinessEnrollmentServer.getBusinessEnrollments()
        },
        saveBusinessEnrollment: (data) => {
           BusinessEnrollmentServer.saveBusinessEnrollment(data)
        },
        updateBusinessEnrollement: (data) => {
            BusinessEnrollmentServer.updateBusinessEnrollement(data)
        }
    }
};
const NJTBusinessEnrollmentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EnrollmentFormView)

export default NJTBusinessEnrollmentContainer;