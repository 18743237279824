export const CouponImageListActions= {
	
	CouponImageList_GET :{
		NEW:"CouponImageList_GET_NEW",
		SUCCESS:"CouponImageList_GET_SUCCESS",
		FAILED:"CouponImageList_GET_FALIURE",
		LOADING:"CouponImageList_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const CouponImageListStatus ={

	CouponImageList_GET :{
		NEW:"CouponImageList_GET_NEW",
		SUCCESS:"CouponImageList_GET_SUCCESS",
		FAILED:"CouponImageList_GET_FALIURE",
		LOADING:"CouponImageList_GET_LOADING",
	}

}
