import React, { PureComponent, Fragment } from "react";

import { withRouter } from 'react-router-dom'
export class CreateApplicantProfile extends PureComponent {


  GotoBussinessProfile = (Application) => {
    this.props.setLoading()
  console.log('new Application Data:', Application)

  // var data = {
  //   username:Application.username,
  //   password:Application.password,
  //   email_address:Application.email_address
  // }

 // alert(JSON.stringify(data))

    // this.props.CreateApplicantProfile(data, Application)
    this.props.cookies.remove("BusinessProfileData", { path: '/' })

    this.props.cookies.remove("BusinessApplicationData", { path: '/' })
    this.props.cookies.set("BusinessApplicationData", Application,
      { path: '/' })
    
    this.props.history.push({
      pathname: "/business_profile"
    });

  }

  render() {
   // console.log("CreateApplicantProfileProps:", this.props.CreateApplicantProfile);
   // const { CreateApplicantProfile } = this.props;
    return (
        <Fragment>
                  <div onClick={() => this.GotoBussinessProfile(this.props.Application)} className={this.props.className}>
                    <button id="btnCreate" className="inner-header-btn">Create</button>
                  </div>
      </Fragment>
    );
  }
}

export default withRouter(CreateApplicantProfile);
