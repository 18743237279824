export const BusinessMessagesActions= {
	
	
	BusinessMessages_Get :{
		NEW:"BusinessMessages_Get_NEW",
		SUCCESS:"BusinessMessages_Get_SUCCESS",
		FAILED:"BusinessMessages_Get_FALIURE",
		LOADING:"BusinessMessages_Get_LOADING",
		NOT_AUTHORIZED: "BusinessMessages_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	BusinessMessages_Post :{
		NEW:"BusinessMessages_Post_NEW",
		SUCCESS:"BusinessMessages_Post_SUCCESS",
		FAILED:"BusinessMessages_Post_FALIURE",
		LOADING:"BusinessMessages_Post_LOADING",
		NOT_AUTHORIZED: "BusinessMessages_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const BusinessMessagesStatus ={

	BusinessMessages_Get :{
		NEW:"BusinessMessages_Get_NEW",
		SUCCESS:"BusinessMessages_Get_SUCCESS",
		FAILED:"BusinessMessages_Get_FALIURE",
		LOADING:"BusinessMessages_Get_LOADING",
		NOT_AUTHORIZED: "BusinessMessages_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	BusinessMessages_Post :{
		NEW:"BusinessMessages_Post_NEW",
		SUCCESS:"BusinessMessages_Post_SUCCESS",
		FAILED:"BusinessMessages_Post_FALIURE",
		LOADING:"BusinessMessages_Post_LOADING",
		NOT_AUTHORIZED: "BusinessMessages_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
