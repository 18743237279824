import React, { Component, Fragment } from 'react'
import "../Invoices/Invoices.css"
import InvoicesPopup from "../InvoicesPopup/InvoicesPopup"
var dateFormat = require('dateformat');

// const invoice = [
//   { date: "12/31/2019", amount: "145", status: "Paid", account: "0433xxxxx" },
//   { date: "12/31/2019", amount: "145", status: "Paid", account: "0433xxxxx" },
//   { date: "12/31/2019", amount: "145", status: "Paid", account: "0433xxxxx" },
//   { date: "12/31/2019", amount: "145", status: "Paid", account: "0433xxxxx" },
//   { date: "12/31/2019", amount: "145", status: "Paid", account: "0433xxxxx" },
//   { date: "12/31/2019", amount: "145", status: "Paid", account: "0433xxxxx" },
// ];


class InvoicesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //  BusinessProfileData: this.props.cookies.get("BusinessProfileData")
    }
  }

  invoiceHadler = () => {
    alert("invoiceHadler work")
  }
  render() {

    return (
      <Fragment>
        {this.props.Invoices.length > 0 ?
         this.props.Invoices.map((res, index) => (
          <tr key={index}>
            <td>{dateFormat(res.invoice_date, 'mm/dd/yyyy')}</td>
            <td>${parseFloat(res.totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
            <td>{res.status}</td>
            {/* <td>******{res.account_number}</td> */}
            <td> <Details Invoice={res} cookies={this.props.cookies}/></td>
          </tr>
        )):
        <tr>
          {/* <td></td> */}
          {/* <td></td> */}
          <td className="sms-il" style={{width:"100%"}}>
          <h3 
          style={{ margin: "40px auto",textAlign:"center" }}
          >
              No records found
            </h3>
          </td>
          {/* <td></td> */}
        
        </tr>
  }
      </Fragment>

    )
  }
}

class Details extends Component {
  state = { showModal: false };
  handleShowMessageClick = () => this.setState({ showModal: true });
  handleCloseModal = () => this.setState({ showModal: false });
  render() {
    return (
      <div className="in-popup-box">
        <button onClick={this.handleShowMessageClick} className="invoice-det">Details</button>
        {this.state.showModal ? (
          <InvoicesPopup Invoice={this.props.Invoice} cookies={this.props.cookies} onClose={this.handleCloseModal}></InvoicesPopup>
        ) : null}

      </div>
    )
  }
}



export default InvoicesList
