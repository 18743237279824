import React, { Component } from 'react'
//import './CouponsInventory.css'
import { CouponsInventoryStatus } from './CouponsInventoryConstants';
// import LoadingScreen from "../.utils/LoadingScreen"
import CouponsInventory from './CouponsInventory'
// import realm from '../utils/realm/UserSchema'
// import SnackBar from '../utils/SnackBar'
// import { NavigationEvents } from 'react-navigation';
import { withCookies } from 'react-cookie';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingScreen from "../../Utils/Loading";

export class CouponsInventoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      callBack: false,
    }
    this.getCouponsInventory()
  }

  componentWillUnmount(){
    this.props.resetReducerState()
  }

  getCouponsInventory = () => {
    // alert("test")
    if(this.props.cookies.get("BusinessProfileData"))
 {   const data = {
      business_id: this.props.cookies.get("BusinessProfileData").business_id
    }
    this.props.getCouponsInventory(data)
    // alert(JSON.stringify(data))
  }
    // this.props.getCouponsInventory(data, realm.objects('UserToken')[0].AccessToken)
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }
  getScreen(status) {
    switch (status) {
      case CouponsInventoryStatus.CouponsInventory_GET.NEW:

        return (
        <div style={{marginTop:20}}>
         
          <LoadingScreen />
         
           </div>
        );


      case CouponsInventoryStatus.CouponsInventory_GET.SUCCESS:
          // alert(JSON.stringify(this.props.CouponsInventory))
        this.state.callBack = true
     

        return (

            <CouponsInventory
              CouponsInventory={this.props.CouponsInventory}
              navigation={this.props.navigation}
              ApprovedBusiness = {this.props.cookies.get("BusinessProfileData")}
              ref_data = {this.props.ref_data}
              setEditCouponData={this.props.setEditCouponData}
              getCouponsInventory={this.props.getCouponsInventory}
              resetFields={this.props.resetFields}
            />
        
        );
        break;
      case CouponsInventoryStatus.CouponsInventory_GET.FAILED:
        this.state.callBack = true
        // this.props.resetReducerState()
        toast.error(this.props.CouponsInventory_Error);
        return (

            <CouponsInventory
              CouponsInventory={this.props.CouponsInventory}
              setEditCouponData={this.props.setEditCouponData}
              getCouponsInventory={this.props.getCouponsInventory}
              resetFields={this.props.resetFields}
            />
        );

      case CouponsInventoryStatus.CouponsInventory_GET.LOADING:
        return (
          // <div style={{marginTop:20}}>
           
          //   <LoadingScreen />
           
          //    </div>
          <CouponsInventory
          CouponsInventory={this.props.CouponsInventory}
          setEditCouponData={this.props.setEditCouponData}
          getCouponsInventory={this.props.getCouponsInventory}
          resetFields={this.props.resetFields}
          LoadingIcon={true}
        />
          );
        break;
      default:
        break;
    }

  }
  render() {
    // alert(this.props.CouponsInventory_GET_status)
    return (
      

        this.getScreen(this.props.CouponsInventory_GET_status)
      
    )
  }
}

export default withCookies(CouponsInventoryView)
