import React from 'react'
import "../InputDollar/InputDollar.css"

function InputDollar(props) {
    const {
        onChange,
        inputValue,
        placeholder,
        name,
        id,
        errorMsg = "Only numeric values are allowed",
        flag = false,
        maxLength = "12",
        containerStyle,
        disabled = false,
        labelIcon = "$",
        classN ="input-box",
        classE = "si_error_text",
        idName = "si_input"
      } = props;
    return (
        <div className="wrap-input">
            <div className="container-input">
                <div className={classN}>
                    <label className="material-icons border-rd-left">{labelIcon}</label>
                    <input
                    id={id}
                    className="si_input border-rd-left"
                    id= {idName}
                    type="text"
                    name={name}
                    value={inputValue}
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength}
                    style={containerStyle}
                    disabled={disabled}
                    />
                </div>
                <p className={classE} style={{ display: flag ? "block" : "none" }}>
                        {errorMsg}
                </p>
            </div>
        </div>
    )
}


export default InputDollar;