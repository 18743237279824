import { DisableBusinessStatus, DisableBusinessActions } from './DisableBusinessconstants';

const DisableBusiness_initialState = {
    DisableBusiness_Status: DisableBusinessStatus.DisableBusiness_Update.NEW,
    DisableBusiness_Error: '',
    businessId:0
}
export default  function  (state = DisableBusiness_initialState, action) {
 //   alert(action.type)
    switch (action.type) {
        
        case DisableBusinessActions.DisableBusiness_Update.LOADING:
         //   alert('load')
            return { ...state, DisableBusiness_Status: DisableBusinessStatus.DisableBusiness_Update.LOADING,businessId:action.payload.bussinessId }
            case DisableBusinessActions.DisableBusiness_Update.NEW:
                return { ...state, DisableBusiness_Status: DisableBusinessStatus.DisableBusiness_Update.NEW }
    
        case DisableBusinessActions.DisableBusiness_Update.FAILED:
            return { ...state,  DisableBusiness_Status: DisableBusinessStatus.DisableBusiness_Update.FAILED}
        case DisableBusinessActions.DisableBusiness_Update.SUCCESS:
          
          return { ...state,  DisableBusiness_Status: DisableBusinessStatus.DisableBusiness_Update.SUCCESS, businessId:action.payload.bussinessId}
          
        default:
            return { ...state, DisableBusiness_Error: '', businessId:0}
    }
}
