//******************Exchange Rate Server Calls******************;

import { InvoicesActions } from "./InvoicesConstants";
import store from "../../Store/store";
import { ROOT_URL,getCookie } from "../../Config/Config";
import { failure_messages } from "Messages";

export const InvoicesServer = {
  //  getInvoices:getInvoices,
  getInvoices: getInvoices,
  //  updateInvoices:updateInvoices
};

//******************Insert Invoicess******************;

function getInvoices(data) {
  const request = fetch(
    ROOT_URL + "/api/businessInvoices/getInvoicesByBusinessId",
    {
      method: "Post",
      mode: "cors",
      headers: { 
        'authorization':getCookie("admin_token_partner"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      response.json().then((response) => {
        if (response.status == "200") {
          // alert(JSON.stringify(response))
          store.dispatch({
            type: InvoicesActions.Invoices_GET.SUCCESS,
            payload: response.data,
            InvoicesCount: response.InvoicesCount,
          });
          return;
        } else {
          store.dispatch({
            type: InvoicesActions.Invoices_GET.FAILED,
            error: response.error,
          });
          return;
        }
      });
    })
    .catch((error) => {
      //  alert(error)
      store.dispatch({
        type: InvoicesActions.Invoices_GET.FAILED,
        error: failure_messages.unExpectedError,
      });
    });
  return { type: InvoicesActions.Invoices_GET.LOADING };
}

//******************Get Invoicess******************;

//******************Update Invoices******************;

// function getInvoices(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.Invoices.length>0)
//              store.dispatch({type:InvoicesActions.Invoices_Get.SUCCESS,payload:response.Invoices});
//              else
//              store.dispatch({type:InvoicesActions.Invoices_GET.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:InvoicesActions.Invoices_Get.FAILED});
//                return ;
//                }
//             });
//            }).catch((error) => {
//             store.dispatch({type:InvoicesActions.Invoices_Get.FAILED});
//           })
//        return {type:InvoicesActions.Invoices_GET.LOADING}

//     };

// function updateInvoices(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:InvoicesActions.Invoices_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:InvoicesActions.Invoices_GET.FAILED});
//         return ;
//         }
//      });
//     }).catch((error) => {
//       store.dispatch({type:InvoicesActions.Invoices_GET.FAILED});
//     })
// return {type:InvoicesActions.Invoices_GET.LOADING}

// };
