//******************Exchange Rate Server Calls******************;

import { UpdateApplicantsStatusActions } from "./UpdateApplicantsStatusConstants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";
import { failure_messages } from "Messages";

export const UpdateApplicantsStatusServer = {
  UpdateApplicantsStatus: UpdateApplicantsStatus
};

//******************Get UpdateApplicantsStatuss******************;

function UpdateApplicantsStatus(data) {
  const res = fetch(ROOT_URL + "/api/status/updateBusinessApplicationStatus", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      res
        .json()
        .then(response => {
          if(response.status == "200")
          store.dispatch({
            type: UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.SUCCESS,
            payload: data,
            message:response.message
          })
          else
            store.dispatch({
              type: UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.FAILED,
              error:response.error
            });
          
        })
        .catch(err => {
          console.log("UpdateApplicantsStatusError:", err);
          store.dispatch({
            type: UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.FAILED,
            error:failure_messages.unExpectedError
          });
        });
    })
    .catch(err => {
      console.log("UpdateApplicantsStatusError:", err);
      store.dispatch({
        type: UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.FAILED
      });
    });

  return { type: UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.LOADING };
}
