import React, { Component} from 'react'
import './Empty.css'

class UserMessage extends Component {
    render() {
        const {message = "Users not Found"} = this.props

        return (
            
            <div className="empty-box">
            <h3>{message}</h3>
            </div>


        )
    }
}

export default UserMessage
