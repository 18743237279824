import { connect } from "react-redux";
import ChangeEmailView from "./ChangeEmail_View";
import {ChangeEmailServer} from "./server"
import {ChangeEmailActions} from './ChangeEmailConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        ChangeEmail_POST_status:state.ChangeEmailReducer.ChangeEmail_Status,
        ChangeEmail:state.ChangeEmailReducer.ChangeEmailDetails,
        ChangeEmail_Message:state.ChangeEmailReducer.ChangeEmail_Message
       // ChangeEmailIndex:state.ChangeEmailReducer.ChangeEmailIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        ChangeEmail: (data) => {

           dispatch( ChangeEmailServer.ChangeEmail(data) )
        },
        resetReducerState:()=>{
            dispatch({type:ChangeEmailActions.ChangeEmail_POST.NEW})
        }
    }
};
const ChangeEmailContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeEmailView)

export default ChangeEmailContainer;