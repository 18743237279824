import React, { Component, Fragment } from 'react'
//import './BusinessProfile.css'
import { BusinessProfileStatus } from './BusinessProfileconstants';
import BusinessProfile from "./BusinessProfile"
// import ProgressBar from '../ProgressBar/ProgressBarFive'
import ProgressBarFour from '../ProgressBar/ProgressBarFour'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../Utils/Loading';
import BusinessName from "../BusinessName/BusinessName"

export class BusinessProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      override:true,
    }

  }

  componentWillUnmount(){
    this.props.resetReducerState()
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.BusinessProfile_post_status === BusinessProfileStatus.BusinessProfile_Post.SUCCESS) {
      nextProps.resetReducerState()

      var object = nextProps.BusinessProfile

      const business = nextProps.cookies.get("BusinessProfileData") ||  nextProps.cookies.get("BusinessApplicationData")
      object = { ...business, ...object }
      nextProps.cookies.remove("BusinessApplicationData", { path: '/' })
      nextProps.cookies.remove("BusinessProfileData", { path: '/' })
     
      nextProps.cookies.set("BusinessProfileData", object, { path: '/' })

      if (nextProps.GoToContractScreen) {

        nextProps.history.push({
          pathname: "./business_contract"
        });
      }
      toast.success(nextProps.BusinessProfile_Message)
    
    }
   return null;
  }
  
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
  }
  getScreen(status) {

    // alert(JSON.stringify(this.props.cookies.get('BusinessProfileData')))
    switch (status) {
      case BusinessProfileStatus.BusinessProfile_Post.NEW:

        return (
          <BusinessProfile saveBusinessProfile={this.props.saveBusinessProfile}
            BusinessProfileData={this.props.cookies.get('BusinessProfileData')} updateBusinessProfile={this.props.updateBusinessProfile}
            BusinessApplicationData={this.props.cookies.get("BusinessApplicationData")}
            GotoContract={this.props.GotoContract}
            CreateRetailerBussiness={this.props.CreateRetailerBussiness}
          />
        );

      case BusinessProfileStatus.BusinessProfile_Post.SUCCESS:
      
        return (
          <Fragment>
            <BusinessProfile saveBusinessProfile={this.props.saveBusinessProfile}
            BusinessProfileData={this.props.cookies.get('BusinessProfileData')} updateBusinessProfile={this.props.updateBusinessProfile}
            BusinessApplicationData={this.props.cookies.get("BusinessApplicationData")}
            GotoContract={this.props.GotoContract}
            CreateRetailerBussiness={this.props.CreateRetailerBussiness}
          />
          </Fragment>
        );
        break;
      case BusinessProfileStatus.BusinessProfile_Post.FAILED:
        toast.error(this.props.BusinessProfile_Message)
          // alert(JSON.stringify(this.props.FormData))
        return (
          <Fragment>

            <BusinessProfile saveBusinessProfile={this.props.saveBusinessProfile}
              BusinessProfileData={this.props.cookies.get('BusinessProfileData')} updateBusinessProfile={this.props.updateBusinessProfile}
              GotoContract={this.props.GotoContract}
              BusinessApplicationData={this.props.cookies.get("BusinessApplicationData")}
              CreateRetailerBussiness={this.props.CreateRetailerBussiness}
              FormData ={this.props.FormData}
            />

          </Fragment>
        );
        break
      case BusinessProfileStatus.BusinessProfile_Post.LOADING:
        this.state.open = true
        return (
          <div style={{ textAlign: "center",display:"flex", height:"65vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.history)
    return (
      <Fragment>
         <BusinessName/>
        <ProgressBarFour ProgressBar1='active' />
      
        {this.getScreen(this.props.BusinessProfile_post_status)}
      </Fragment>
    )
  }
}

export default withRouter(BusinessProfileView)
