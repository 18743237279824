export const BannerListActions= {
	
	BannerList_GET :{
		NEW:"BannerList_GET_NEW",
		SUCCESS:"BannerList_GET_SUCCESS",
		FAILED:"BannerList_GET_FALIURE",
		LOADING:"BannerList_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const BannerListStatus ={

	BannerList_GET :{
		NEW:"BannerList_GET_NEW",
		SUCCESS:"BannerList_GET_SUCCESS",
		FAILED:"BannerList_GET_FALIURE",
		LOADING:"BannerList_GET_LOADING",
	}

}
