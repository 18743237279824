import React, { Component } from 'react'
import "./BusinessName.css"
import {withCookies} from 'react-cookie'
class BusinessName extends Component {
    render() {
        return (
            <div className="busines-name-main">
                <div className="busines-name-b">
        <h2>{this.props.cookies.get('BusinessProfileData') && this.props.cookies.get('BusinessProfileData').business_name?this.props.cookies.get('BusinessProfileData').business_name:""}</h2>
                </div>
            </div>
        )
    }
}

export default withCookies(BusinessName)
