import { connect } from "react-redux";
import GetQuotasContractDetailsView from "./GetQuotasContractDetails_View";
import {GetQuotasContractDetailsServer} from "./server"
import {GetQuotasContractDetailsActions} from './GetQuotasContractDetailsConstants'
const mapStateToProps = state => {
  //  console.warn(state.GetQuotasContractDetailsReducer.GetQuotasContractDetails)
    return {
        GetQuotasContractDetails_GET_status:state.GetQuotasContractDetailsReducer.GetQuotasContractDetails_Status,
        QuotasContractDetails:state.GetQuotasContractDetailsReducer.GetQuotasContractDetails,
        QuotaYears:state.GetQuotasContractDetailsReducer.QuotaYears,
        GetQuotasContractDetails_Error:state.GetQuotasContractDetailsReducer.GetQuotasContractDetails_Error,
        paymentAccount:state.GetQuotasContractDetailsReducer.paymentAccount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GetQuotasContractDetails: (data) => {

           dispatch( GetQuotasContractDetailsServer.GetQuotasContractDetails(data) )
        },
        GetYearQuotasDetails: (data) => {

            dispatch( GetQuotasContractDetailsServer.GetYearQuotasDetails(data) )
         },
        resetReducerState: async()=>{
        //    await dispatch( {type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.LOADING} )
            dispatch( {type:GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.NEW} )
           
        }
    }
};
const GetQuotasContractDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetQuotasContractDetailsView)

export default GetQuotasContractDetailsContainer;