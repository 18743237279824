import React from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import DatePicker from '../DatePicker';
import 'react-day-picker/lib/style.css';
import "./DateRange.css";
import dateFormat from "dateformat";
import { withCookies } from "react-cookie";
function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .toDate()
    );
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date)
      .startOf('week')
      .toDate(),
    to: moment(date)
      .endOf('week')
      .toDate(),
  };
}

class WeekPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverRange: undefined,
      selectedDays: [],
      disabledDays:[{before:new Date(moment().startOf('week'))}],
      showWeekPicker :false,
      WeekList:props.WeekList
    }
    // const disabledDays = this.state.disabledDays
    // this.state.WeekList.forEach((e)=>{
    //   for (let i = 0; i < 7; i += 1) {
    //     disabledDays.push(
    //       moment(e.week_start_date)
    //         .add(i, 'days')
    //         .toDate()
    //     )
    //   }
    //   this.state.disabledDays = disabledDays
    // })
    
}
    
  handleDayChange = (date, getScheduledBanners = true)  => {
    // alert(date)
    const weekDays = getWeekDays(getWeekRange(date).from)
    // var flag = true
    // const startDate = new Date(weekDays[0])
    // for (let i = 1; i < this.state.disabledDays.length; i += 1) {
    //   if(dateFormat(startDate,"mm-dd-yyyy") == dateFormat(new Date(this.state.disabledDays[i]),"mm-dd-yyyy"))
    //   {
    //     flag = false
    //     break;
    //   }
    // }
    // alert(weekDays[0].getUTCDate())
    // alert(dateFormat(new Date(weekDays[0]),"dd") +"  "+ dateFormat(new Date(),"dd"))
    if(new Date( dateFormat(new Date(weekDays[0]),"yyyy-mm-dd") ) >=new Date( dateFormat(new Date(moment().startOf('week')),"yyyy-mm-dd")))
 {  
  // alert(new Date(moment().startOf('week')))
   if(new Date(weekDays[0]) < new Date(moment().endOf('week')))
   this.props.setIsCurrentWeek(true)
   else
   this.props.setIsCurrentWeek(false)
         

    this.setState({
      selectedDays: weekDays
    });
    // if(setWeeks)
    this.props.setWeeks(weekDays, getScheduledBanners)

    this.props.cookies.remove("banner_week", { path: "/" });
    this.props.cookies.set(
      "banner_week",
      { week_start_date: weekDays[0], week_end_date: weekDays[6] },
      { path: "/" }
    );
 }
  };

  handleDayEnter = date => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.setState({
      selectedDays: days,
    });
  };

  showWeekpicker =()=>{
    this.setState({
      showWeekPicker:!this.state.showWeekPicker
    })
  }

  render() {
    if(this.props.Week && this.state.selectedDays.length < 6){
      this.handleDayChange(this.props.cookies.get("banner_week").week_start_date, false)
    }
    const { hoverRange, selectedDays } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };
    if (this.props.disabled && this.props.week_start_date)
      return (
        <div>
          <DatePicker 
           disabled = {true}
            
            placeholderText={dateFormat(new Date(this.props.week_start_date), "mm/dd/yyyy") +" - "+ dateFormat(new Date(this.props.week_end_date), "mm/dd/yyyy") }
          
          />
        </div>
      );
    return (
      <div className="SelectedWeekExample">
        <DatePicker 
        disabled = {true}
        onClick = {this.showWeekpicker}
        placeholderText = {selectedDays.length > 6 ? dateFormat(new Date(selectedDays[0]) , "mm/dd/yyyy")+ " - " + dateFormat(new Date(selectedDays[6]) , "mm/dd/yyyy") : this.props.Week !=""?
        dateFormat(this.props.cookies.get("banner_week").week_start_date,"mm/dd/yyyy") +" - "+dateFormat(this.props.cookies.get("banner_week").week_end_date,"mm/dd/yyyy"): "mm/dd/yyyy - mm/dd/yyyy"}
        />
        {this.state.showWeekPicker?
        <DayPicker
          selectedDays={selectedDays}
          showWeekNumbers
          showOutsideDays
          modifiers={modifiers}
          onDayClick={this.handleDayChange}
          onDayMouseEnter={this.handleDayEnter}
          onDayMouseLeave={this.handleDayLeave}
          onWeekClick={this.handleWeekClick}
          disabledDays={this.state.disabledDays}
        />:null
    }
        {/* {selectedDays.length === 7 && (
          <div>
            {moment(selectedDays[0]).format('LL')} –{' '}
            {moment(selectedDays[6]).format('LL')}
          </div>
        )} */}
        {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader'  style={{left:"274px", bottom:"11px"}}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default  withCookies(WeekPicker);
