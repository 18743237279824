import { BusinessListStatus, BusinessListActions } from './BusinessListconstants';

const BusinessList_initialState = {
    BusinessList_Status: BusinessListStatus.BusinessList_GET.NEW,
    BusinessList_Error: '',
    BusinessListDetails: [],
}
export default  function  (state = BusinessList_initialState, action) {
    switch (action.type) {
        case BusinessListActions.BusinessList_GET.LOADING:
            return { ...state, BusinessList_Status: BusinessListStatus.BusinessList_GET.LOADING }
            case BusinessListActions.BusinessList_GET.NEW:
                return { ...state, BusinessList_Status: BusinessListStatus.BusinessList_GET.NEW, BusinessListDetails: [] }
    
        case BusinessListActions.BusinessList_GET.FAILED:
            return { ...state,  BusinessList_Status: BusinessListStatus.BusinessList_GET.FAILED, BusinessList_Error:action.error}
        case BusinessListActions.BusinessList_GET.SUCCESS:
          
          return { ...state,  BusinessList_Status: BusinessListStatus.BusinessList_GET.SUCCESS, BusinessListDetails:action.payload}
          
        
        default:
            return { ...state,
                
            }
    }
}
