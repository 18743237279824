export const ApprovalCheckListActions= {
	
	ApprovalCheckList_Post :{
		NEW:"ApprovalCheckList_Post_NEW",
		SUCCESS:"ApprovalCheckList_Post_SUCCESS",
		FAILED:"ApprovalCheckList_Post_FALIURE",
		LOADING:"ApprovalCheckList_Post_LOADING",
		UPDATE:"ApprovalCheckList_Post_UPDATE"
	}
}


export const ApprovalCheckListStatus ={

	ApprovalCheckList_Post :{
		NEW:"ApprovalCheckList_Post_NEW",
		SUCCESS:"ApprovalCheckList_Post_SUCCESS",
		FAILED:"ApprovalCheckList_Post_FALIURE",
		LOADING:"ApprovalCheckList_Post_LOADING",
		UPDATE:"ApprovalCheckList_Post_UPDATE"
	}

}
