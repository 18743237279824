import React, { PureComponent } from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("preview_coupon_portal");

// const createPdf = () => props.createPdf(bodyRef.current);

export class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collaps: true,
      detailShow: false,
      screenCapture: "",
      imgURL: "",
    };
  }
  
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  collapsHandler = () => {
    // this.setState({ menuFlag: !this.state.menuFlag });
    this.setState({ collaps: !this.state.collaps });
  };
  getUTC_date = (date) => {
    var d = new Date(date);
    d.setHours(d.getHours() + 5);
    // alert(d)
    return d;
  };

 

  render() {

    return ReactDOM.createPortal(
      <div
        style={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.3)",
          // minHeight: "100vh",
          // overflow: 'hidden',
          zIndex: "9999999999",
        }}
        // onClick={this.props.onClose}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            borderRadius: "4px",
            display: "inline-block",
            minHeight: "140px",
            margin: "1rem",
            position: "relative",
            minWidth: "300px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
            justifySelf: "center",
          }}
        >
          <div className="ret-pop-box end-popup">
            <div className="main-box-cpn">
                  <div className="end-compai">
                    Delete Offer
                  </div>
                  <hr className="comp-line" />
                <p className="cpn-heading are-you">Are you sure you want to delete this offer?</p>
                <div className="btn-are-you new-sty">
                <button id="no-popup" onClick={this.props.onClose} className="button-cancel can-new">
                No
              </button>
              <button id="yes-popup" onClick={this.props.onAccept} className="buttonStyle">
                Yes
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>,
      modalRoot
    );
  }
}

class PreviewCouponCard extends React.Component {
  state = { showModal: false };
  handleShowMessageClick = () => this.setState({ showModal: true });
  handleCloseModal = () => this.setState({ showModal: false });
  render() {
    return (
      <div
        style={{
          height: "100%",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 400,
            position: "relative",
          }}
        >
          <button className="buttonStyle" onClick={this.handleShowMessageClick}>
            Preview Coupon
          </button>
          {this.state.showModal ? (
            <Modal onClose={this.handleCloseModal}>
              {/* This is the secret modal message! */}
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

export default PreviewCouponCard;
