import { EndCouponStatus, EndCouponActions } from './EndCouponConstants';

const EndCoupon_initialState = {
    EndCoupon_Status: EndCouponStatus.EndCoupon_Post.NEW,
    EndCoupon_Message: '',
    EndCouponDetails: {},
}
export default function (state = EndCoupon_initialState, action) {
    switch (action.type) {
        case EndCouponActions.EndCoupon_Post.LOADING:
            return { ...state, 
            EndCoupon_Status: EndCouponStatus.EndCoupon_Post.LOADING, EndCoupon_Message: '',
            EndCouponDetails: {}
         }
        case EndCouponActions.EndCoupon_Post.NEW:
            return { ...state, EndCoupon_Status: EndCouponStatus.EndCoupon_Post.NEW}

        case EndCouponActions.EndCoupon_Post.FAILED:
           // alert("test")
            return { ...state, EndCoupon_Status: EndCouponStatus.EndCoupon_Post.FAILED, EndCouponDetails: action.payload, EndCoupon_Message: action.error }
        case EndCouponActions.EndCoupon_Post.SUCCESS:
            return { ...state, EndCoupon_Status: EndCouponStatus.EndCoupon_Post.SUCCESS, EndCouponDetails: action.payload, EndCoupon_Message: action.message }

        default:
            return { ...state }
    }
}
