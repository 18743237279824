import { connect } from "react-redux";
import Login from "./DeleteCoupon_View";
import {DeleteCouponServer} from "./server"
import {DeleteCouponActions} from './DeleteCouponConstants'
import { withCookies } from 'react-cookie';
const mapStateToProps = state => {
    console.log(state)
    return {
        DeleteCoupon_post_status:state.DeleteCouponReducer.DeleteCoupon_Status,
        DeleteCoupon_Message:state.DeleteCouponReducer.DeleteCoupon_Message,
        DeleteCouponDetails:state.DeleteCouponReducer.DeleteCouponDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateDeleteCoupon: (data, bussinessId) => {
        //     dispatch( DeleteCouponServer.updateDeleteCoupon(data, bussinessId) )
        //  },

        handleDeleteCoupon: (data) => {

           dispatch(DeleteCouponServer.handleDeleteCoupon(data))
        },
        resetReducerState: () => {
            dispatch({type:DeleteCouponActions.DeleteCoupon_Post.NEW})
         }
        //  updateDeleteCoupon: (data, token) =>{
        //     dispatch( DeleteCouponServer.updateDeleteCoupon(data, token) )
        //  }
        //  GotoContract: () => {
        //     dispatch({type:DeleteCouponActions.DeleteCoupon_Post.GOTO_CONTRACTS})
        //  },
        
    }
};
const DeleteCouponContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

export default withCookies(DeleteCouponContainer);