export const InvoiceDetailsActions= {
	
	InvoiceDetails_GET :{
		NEW:"InvoiceDetails_GET_NEW",
		SUCCESS:"InvoiceDetails_GET_SUCCESS",
		FAILED:"InvoiceDetails_GET_FALIURE",
		LOADING:"InvoiceDetails_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const InvoiceDetailsStatus ={

	InvoiceDetails_GET :{
		NEW:"InvoiceDetails_GET_NEW",
		SUCCESS:"InvoiceDetails_GET_SUCCESS",
		FAILED:"InvoiceDetails_GET_FALIURE",
		LOADING:"InvoiceDetails_GET_LOADING",
	}

}
