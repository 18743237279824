import React from "react";
// import { render } from "react-dom";
// import { fontStacks, countries, friends } from "../SelectSearch/data";
import "../SelectSearch/SelectSearchInput.css";
import SelectSearch from "react-select-search";

function renderFriend(props, option,  className) {
  const imgStyle = {
    borderRadius: "50%",
    verticalAlign: "middle",
    marginRight: 10
  };

  return (
    <button {...props} className={className} type="button">
      <span>
        <img
          alt="not found"
          style={imgStyle}
          width="32"
          height="32"
          src={option.photo}
        />
        <span>{option.name}</span>
      </span>
    </button>
  );
}

function renderFontValue(valueProps, snapshot, className) {
  const { selectedOption } = snapshot;
  const style = {
    fontFamily:
      selectedOption && "stack" in selectedOption ? selectedOption.stack : null
  };

  return (
    <input
      {...valueProps}
      className={className}
      style={style}
      value={snapshot.displayValue}
    />
  );
}

function renderFontOption(props, { stack, name }, snapshot, className) {
  return (
    <button {...props} className={className} type="button">
      <span style={{ fontFamily: stack }}>{name}</span>
    </button>
  );
}

export default class SelectSearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  state = {};

  render() {
    //console.log(this.state);
    const {
      errorMsg = "errorMsg",
      flag = false,
      disabled = false,
      onChange,
      inputValue,
      key = 'value',
      data = [],
      placeholder="Search for Retailer Name"
    } = this.props;

    return (
      <div className="clddi_input_container">
        <div className="select-search-input">
          <SelectSearch
            key={key}
            id="searchRetailerName"
            value={inputValue}
            options={data}
            onChange={onChange}
            placeholder={placeholder}
            search
            disabled={disabled}
            // printOptions="always"
          />
        </div>
        <p
          className="clddi_error_text"
          style={{ display: flag ? "block" : "none" }}
        >
          {errorMsg}
        </p>
      </div>
    );
  }
}
