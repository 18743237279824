import {Units_Actions, Units_Status } from './constants';
const initialState = {
  Units_get_status: Units_Status.Units_Get.NEW,
  Units: [],
};

const NJT_Units_Get_Reducer = (state = initialState, action) => {
  //console.log(action.type);
  switch (action.type) {

    case Units_Actions.Units_Get.NEW:
     // console.log("I am from Reduce NEW..");
      return { ...state, Units_get_status: Units_Status.Units_Get.NEW };

    case Units_Actions.Units_Get.SUCCESS:
     console.log("I am from Reduce SUCCESS..", action.payload);
      return { ...state, Units_get_status: Units_Status.Units_Get.SUCCESS, Units: action.payload};

    case Units_Actions.Units_Get.OFFLINE:
    //  console.log("I am from Reduce NEW..");
      return { ...state, Units_get_status: Units_Status.Units_Get.OFFLINE, Units: action.payload };

     case Units_Actions.Units_Get.LOADING:
     //  console.log("I am from Reduce NEW..");
       return { ...state, Units_get_status: Units_Status.Units_Get.LOADING, Units: action.payload };
      
    case Units_Actions.Units_Get.FAILURE:
    //  console.log("I am from Reduce NEW..");
        return { ...state, Units_get_status: Units_Status.Units_Get.FAILURE, Units: action.payload };
            
    default:
      return { ...state }
  }
};

export default NJT_Units_Get_Reducer;

