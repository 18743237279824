export const OverrideCouponsInventoryActions= {
	
	OverrideCouponsInventory_GET :{
		NEW:"OverrideCouponsInventory_GET_NEW",
		SUCCESS:"OverrideCouponsInventory_GET_SUCCESS",
		FAILED:"OverrideCouponsInventory_GET_FALIURE",
		LOADING:"OverrideCouponsInventory_GET_LOADING",
		UPDATE:"DisableOverrideCouponsInventory_Update_SUCCESS",
	},
	
}


export const OverrideCouponsInventoryStatus ={

	OverrideCouponsInventory_GET :{
		NEW:"OverrideCouponsInventory_GET_NEW",
		SUCCESS:"OverrideCouponsInventory_GET_SUCCESS",
		FAILED:"OverrideCouponsInventory_GET_FALIURE",
		LOADING:"OverrideCouponsInventory_GET_LOADING",
	}

}
