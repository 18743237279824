import React, { Component } from "react";
import UploadBannerHeader from "../UploadBanner/UploadBannerHeader/UploadBannerHeader";
import "./ScheduleBanner.css";
import { withRouter } from "react-router-dom";
import SelectBannerList from "./SelectBannerList"
import SelectedBannerList from "./SelectedBannerList"
import BannerScheduleContainer from "./BannerSchedule/BannerScheduleContainer"
export class BannerScheduleMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      RetailerList: [],
      Retailer: "",
      bannerList: [],
      selectedBanners: [],
      scheduledBanners:[],
      weekBanners:[],
      showExpired: false,
      bannerDelted: false,
      errorMsg:"",
      Week:"",
      isCurrentWeekSelected:false,
      scheduleState:0
    };
    props.cookies.remove("SelectedBanner", { path: "/" });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setIsCurrentWeek = (selected)=>{
    this.setState({
      isCurrentWeekSelected:selected
    })
  }

  setRetailerList = RetailerList => {
    this.setState({ RetailerList });
  };
  setWeekBanners = weekBanners => {
    this.setState({ weekBanners });
  };
  setWeeks  = (weeks) =>{
  
    this.setState({
      Week:weeks,
      errorMsg:""
    })
  }
  addSelectedBanner = (banner, index) => {
    // const bannerList1 = this.state.bannerList
    // alert(index)
    // alert(this.state.bannerList.length)
    var selected= this.state.bannerList.find(item=>{
      return item.banner_id == banner.banner_id
       
     })
     selected.active = true
     selected.disable = true
    //this.state.bannerList[index].active = true
    //this.state.bannerList[index].disable = true
    
    // const bannerList2 = this.state.selectedBanners
    // bannerList2.push(banner)
    // this.setState({
    //   selectedBanners:bannerList2,
    //   // bannerList:bannerList1
    // })
 
  };
  removeSelectedBanner = (banner, index) => {
    // const bannerList1 = this.state.bannerList
   var selected= this.state.bannerList.find(item=>{
     return item.banner_id == banner.banner_id
      
    })
    selected.active = false
    selected.disable = false
    
    // this.state.bannerList[index].active = false
    // this.state.bannerList[index].disable = false
    
    // const newArr = this.state.selectedBanners.filter(e => {
    //   return e.banner_id != banner.banner_id;
    // });
    // // this.state.selectedBanners = newArr;
    // this.setState({
    //   selectedBanners:newArr,
    //   // bannerList:bannerList1
    // })
  };
  searchRetailerHandler = e => {
    this.setState({ Retailer: e });
    // const
    //  this.props.cookies.remove("SelectedBanner", { path: "/" });
  };

  setBannerList = data => {
    this.setState({
      bannerList: data
    });
  };

  edit_Banner = banner => {
    // this.props.cookies.remove("banner_duplicate", { path: "/" });
    // this.props.cookies.remove("banner_edit", { path: "/" });
    // this.props.cookies.set("banner_edit", banner, { path: "/" });
    // this.props.history.push("/Upload_Banner_Form");
    // alert(JSON.stringify(banner))
  };

  duplicate_Banner = banner => {
    // if (new Date() > new Date(banner.end_date)) {
    //   this.props.cookies.remove("banner_duplicate", { path: "/" });
    //   this.props.cookies.remove("banner_edit", { path: "/" });
    //   this.props.cookies.set("banner_duplicate", banner, { path: "/" });
    //   this.props.history.push("/Upload_Banner_Form");
    // }
  };

  setScheduledBanners = (data) =>{
    // alert(JSON.stringify(data))
    // this.state.scheduledBanners = data
    this.setState({
      scheduledBanners:data
    })
  }

  Go_Next = () => {
    
    this.setState({
      scheduleState: this.state.scheduleState + 1,
      // selectedBanners:selectedBanners
    })
  
  };

  Go_Back = () => {
    // if(this.state.scheduleState == 1){
    //   // this.state.selectedBanners = []
    // }
    
    this.setState({
      scheduleState: this.state.scheduleState - 1
    })
  }

  setSelectedBanner = data =>{
    this.setState({
      selectedBanners:data
    })
  }
  getScreen()
  {
    // alert(JSON.stringify(this.state.selectedBanners))
    // alert(JSON.stringify(this.state.bannerList))
    switch(this.state.scheduleState)
    {
      case 0:
        return(
          <SelectBannerList 
           cookies={this.props.cookies} Week = {this.state.Week} selectedBanners={this.state.selectedBanners} 
           setWeeks={this.setWeeks} Go_Next={this.Go_Next} addSelectedBanner={this.addSelectedBanner} 
           removeSelectedBanner={this.removeSelectedBanner}  setBannerList={this.setBannerList}
           bannerList={this.state.bannerList}  setWeekBanners = {this.setWeekBanners}
           weekBanners = {this.state.weekBanners} setScheduledBanners = {this.setScheduledBanners}
           setSelectedBanner = {this.setSelectedBanner} scheduledBanners = {this.state.scheduledBanners}
           setIsCurrentWeek = {this.setIsCurrentWeek}

           />
          
        )
      case 1:
        return( <SelectedBannerList Go_Back={this.Go_Back} Week={this.state.Week} cookies={this.props.cookies} Go_Next={this.Go_Next} selectedBanners={this.state.selectedBanners} Go_Next={this.Go_Next}/> )
        break;
      case 2:
        return ( <BannerScheduleContainer Go_Back={this.Go_Back} Week={this.state.Week} cookies={this.props.cookies} Go_Next={this.Go_Next} selectedBanners={this.state.selectedBanners} 
          scheduledBanners = {this.state.scheduledBanners}
          isCurrentWeekSelected = {this.state.isCurrentWeekSelected}
          bannerList={this.state.bannerList}/>)
        break;
      default:
        
        return(
          <SelectBannerList Week={this.state.Week} Go_Next={this.Go_Next} selectedBanners={this.selectedBanners} />
          
        )

    }
  }
  render() {
    return (
      <div className="upload-manage-wrap">
        <UploadBannerHeader heading="Schedule Banners" />
        
        {
          this.getScreen()
        }
     </div>
    );
  }
}

export default withRouter(BannerScheduleMainContainer);
