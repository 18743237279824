export const RetailerCouponsInventoryActions= {
	
	RetailerCouponsInventory_GET :{
		NEW:"RetailerCouponsInventory_GET_NEW",
		SUCCESS:"RetailerCouponsInventory_GET_SUCCESS",
		FAILED:"RetailerCouponsInventory_GET_FALIURE",
		LOADING:"RetailerCouponsInventory_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const RetailerCouponsInventoryStatus ={

	RetailerCouponsInventory_GET :{
		NEW:"RetailerCouponsInventory_GET_NEW",
		SUCCESS:"RetailerCouponsInventory_GET_SUCCESS",
		FAILED:"RetailerCouponsInventory_GET_FALIURE",
		LOADING:"RetailerCouponsInventory_GET_LOADING",
	}

}
