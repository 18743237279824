import { GenerateCouponsStatus, GenerateCouponsActions } from './GenerateCouponsConstants';

const GenerateCoupons_initialState = {
    GenerateCoupons_Status: GenerateCouponsStatus.GenerateCoupons_Post.NEW,
    message: '',
    GenerateCouponsDetails: {},
    Generated:false
}
export default function (state = GenerateCoupons_initialState, action) {
    switch (action.type) {
        case GenerateCouponsActions.GenerateCoupons_Post.LOADING:
            return { ...state, GenerateCoupons_Status: GenerateCouponsStatus.GenerateCoupons_Post.LOADING }
        case GenerateCouponsActions.GenerateCoupons_Post.NEW:
            return { ...state, GenerateCoupons_Status: GenerateCouponsStatus.GenerateCoupons_Post.NEW }

        case GenerateCouponsActions.GenerateCoupons_Post.FAILED:
           // alert("test")
            return { ...state, GenerateCoupons_Status: GenerateCouponsStatus.GenerateCoupons_Post.FAILED,message:action.error }
        case GenerateCouponsActions.GenerateCoupons_Post.SUCCESS:
            return { ...state, GenerateCoupons_Status: GenerateCouponsStatus.GenerateCoupons_Post.SUCCESS, GenerateCouponsDetails: action.payload,Generated:true, message:action.message }

        default:
            return { ...state}
    }
}
