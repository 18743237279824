import React, { Component } from "react";
import "./UploadBannerForm.css";
import SelectSearchInput from "../../Inputs/SelectSearch/SelectSearchInput";
import DatePicker from "../../Inputs/DatePicker";
// import DropDownInput from "../../Inputs/DropDownInput";
import SingleInputRadio from "../../Inputs/InputRadio/SingleInputRadio";
// import LegendInput from "../../Inputs/LegendInput";
import LabelTextAreaInput from "../../Inputs/LabelTextAreaInput";
import SimpleInput from "../../Inputs/SimpleInput";
import RetailerListContainer from "../../RetailerList/RetailerListContainer";
import CustomDropDownInput from "../../Inputs/CustomDropDownInput/CustomDropDownInput";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import validator from 'validator'

import moment from "moment";
import {Modal} from "../../FileUpload/FileUploadBanner"


const zoneIdData = [
  {name: "Select Zone", value:""},
  {name: "Zone 1: Rewards Home Top", value:1},
  {name: "Zone 2: Rewards Home Below Navigation Buttons", value:2},
  {name: "Zone 3: Interspersed on Rewards Offers Page (after n offers dispaly an ad)", value:3},
  {name: "Zone 4: Interspersed in Wallet Page (after n offers display an n)", value:4},
]


// import moment from "moment"
class UploadBannerForm extends Component {
  constructor(props) {
    super(props);
    const banner = props.UpdateBannerData || props.DuplicateBannerData;
    // alert(banner.zone_id)
    this.state = {
      editMode: banner ? false : true,
      weekCheck: banner ? banner.seven_days : true,
      fiveCheck: banner ? banner.week_days : false,
      twoCheck: banner ? banner.week_ends : false,
      searchName: banner ? banner.business_id : "",
      business_name: banner ? banner.business_name : "",
      searchNameFlag: false,
      searchNameErrorMsg: "Field Required",
      fromDate: props.UpdateBannerData ? new Date(moment(banner.start_date).format("MM/DD/YYYY")) : "",
      fromDateErrorMsg: "Field Required",
      fromDateFlag: false,
      toDate: props.UpdateBannerData ? new Date(moment(banner.end_date).format("MM/DD/YYYY")) : "",
      MintoDate: props.UpdateBannerData
        ? new Date(banner.start_date).setDate(
          new Date(banner.start_date).getDate() + 1
        )
        : new Date(),
      toDateErrorMsg: "Field Required",
      toDateFlag: false,
      numberDays: banner ? banner.number_of_days : "",
      numberDaysErrorMsg: "Only numeric values are allowed",
      numberDaysFlag: false,
      redirectURL: banner ? banner.banner_redirect_url : "",
      redirectURLErrorMsg: "",
      redirectURLFlag: false,
      RetailerList: [],
      selectedImage: "",
      ImageErrorMsg: "",
      notes: banner ? banner.notes : "",
      ContractEndDate: banner ? banner.term_end_date : "",
      fileimg: banner ? banner.banner_location : "",
      allocation: 0,
      zoneId:banner ? (banner.zone_id ? banner.zone_id : ""):"",
      zoneIdFlag:false,
      zoneIdErrorMsg:"Field Required"
    };

    if (props.UpdateBannerData && banner.TotalAllocated > 0) {

      this.state.allocation = banner.TotalAllocated;
      this.state.MintoDate = new Date(banner.end_date).setDate(
        new Date(banner.end_date).getDate() + 1
      )
    }
    //  alert(JSON.stringify(banner.notes))
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  inputHandler = () => {
    // this function clears the upload file image
    this.setState({
      editMode: true,
      fileimg: "",
      selectedImage: "",
      ImageErrorMsg: ""
    });
  };

  fileHandler = e => {
    // thish fuction sets the image from local file system
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var fileName = file.name;
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, file.length).toLowerCase();

      console.log("length: ", file.length)
      //  var FileLength
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        if (Math.round(file.size / 1024) > 150) {
          document.getElementById("pe-inp-btn1").value = "";
          //alert("File size should be less than 2 MB.");
          //   // alert("Height and Width must not exceed 100px.");
          this.setState({
            selectedImage: "",
            ImageErrorMsg: "File size should be less than 150KB"
          });
        } else
          reader.onload = async () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
              const imgWidth = img.naturalWidth;
              const imgHeight = img.naturalHeight;
              // var ratio  = 0
              console.log('imgWidth: ', imgWidth);
              console.log('imgHeight: ', imgHeight);
              console.log("images ratio", imgWidth / imgHeight)
              //  if (imgWidth > imgHeight)
              //    ratio = imgHeight/imgWidth
              //   else
              //   ratio = imgWidth/imgHeight
              // var ratio = imgWidth / imgHeight


              // if(imgWidth > 314 || imgHeight > 125)
              // if(ratio !== 2 && ratio !== 2.52 && ratio !== 3.5) 
              // this.setState({
              //   selectedImage: "",
              //   ImageErrorMsg: "Please upload an image with 2 : 1 in aspect ratio"
              // });
              // else
              this.setState({
                editMode: false,
                fileimg: reader.result,
                selectedImage: file,
                ImageErrorMsg: "",
                LogoSize: file.size,
                FileLength: extFile
              });

            };

            // console.log("This is preview path");
            console.log("Result : ", reader.result);

            //  alert("Uploaded image has valid Height and Width.");

          };

        reader.readAsDataURL(file);
      } else {
        this.setState({
          selectedImage: "",
          ImageErrorMsg: "Please upload an image with extensions: jpg/jpeg/png"
        });
      }
    }
  };
  // todo: weekRadioHandler onChange function ..........................

  weekRadioHandler = () => {
    // alert("this")
    if (this.state.allocation == 0)
      this.setState({
        weekCheck: true,
        fiveCheck: false,
        twoCheck: false
      });
    console.log("weekCheck", this.state.weekCheck);
  };

  // todo: weekRadioHandler onChange function ..........................
  // todo: fiveRadioHandler onChange function ..........................

  fiveRadioHandler = () => {
    if (this.state.allocation == 0)
      this.setState({
        weekCheck: false,
        fiveCheck: true,
        twoCheck: false
      });
    console.log("fiveCheck", this.state.fiveCheck);
  };

  // todo: fiveRadioHandler onChange function ..........................
  // todo: twoRadioHandler onChange function ..........................

  twoRadioHandler = () => {
    if (this.state.allocation == 0)
      this.setState({
        weekCheck: false,
        fiveCheck: false,
        twoCheck: true
      });
    console.log("twoCheck", this.state.twoCheck);
  };

  // todo: twoRadioHandler onChange function ..........................
  // todo: searchNameHandler onChange function ..........................
  searchNameHandler = e => {
    console.log("this e check", e);
    const businessObj = this.state.RetailerList.find(element => {
      return element.value == e;
    });
    // alert(JSON.stringify(businessObj))
    this.setState({
      searchName: e,
      searchNameFlag: false,
      ContractEndDate: businessObj.term_end_date,
      toDate: "",
      toDateFlag: false,
      fromDate: "",
      fromDateFlag: false,
      numberDaysFlag: false,
      numberDays: "",
      redirectURLFlag: false,
      redirectURL: ""
    });
  };

  fromDateHandler = e => {

    if (new Date(this.state.ContractEndDate) < new Date(e)) {
      this.setState({
        fromDateFlag: true,
        fromDateErrorMsg: "You have exceeded contract end date",
        fromDate: new Date(e),
        toDate: "",
        toDateFlag: false,
        MintoDate: new Date(e).setDate(new Date(e).getDate() + 1)
      });
    } else {
      this.setState({
        fromDate: new Date(e),
        fromDateFlag: false,
        toDate: "",
        toDateFlag: false,
        fromDateErrorMsg: "Field Required",
        MintoDate: new Date(e).setDate(new Date(e).getDate() + 1)
      });
    }
  };
  // todo: SaveHandler onClick Function ...................
  //Check for Valid URL in redirect Url
  isValidURL =(string)=> {

    // Check for localhost
    if(string.includes("localhost")){
      // alert("hello")
      var res = string.match(/^https?:\/\/\w+(\.\w+)*(:[0-9]+)?(\/.*)?$/);
      return (res !== null)
    }
    
    var res = string.match(/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/);
    return (res !== null)
  };

  // (https?:\/\/)
  // isValidURL =(value)=> {
  //   return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  // }


  //Redirect URL Handler
  redirectURLHandler = e => {
    if (this.isValidURL(e.target.value)) {
      this.setState({
        redirectURL: e.target.value,
        redirectURLErrorMsg: '',
        redirectURLFlag: false,
      }
        , () => {
          if (this.state.redirectURL.length > 254) {
            this.setState({
              redirectURLFlag: true,
              redirectURLErrorMsg: "URL is too Long..!"
            });
          }
        }
      )

    }
    else {
      if (e.target.value === "") {
        this.setState({
          redirectURL: e.target.value,
          redirectURLFlag: false
        });
      }
      else {
        this.setState({
          redirectURL: e.target.value,
          redirectURLFlag: true,
          redirectURLErrorMsg: "Please Enter Correct URL"
        });
      }
    }
  };

  numberDaysHandler = e => {
    // alert(e.target.value)
    if (this.state.toDate !== "") {
      const reg = /^\d*$/;
      const validReg = reg.test(e.target.value);
      if (validReg) {
        const days = this.days_between(
          new Date(this.state.toDate),
          new Date(this.state.fromDate)
        );
        if (days < e.target.value) {
          this.setState({
            numberDaysFlag: true,
            numberDaysErrorMsg: "You have exceeded days b/w Start and End date"
          });
        } else if (e.target.value < 1) {
          this.setState({
            numberDaysFlag: true,
            numberDaysErrorMsg: "No of days should be greater than 0"
          });
        } else if (e.target.value < this.state.allocation) {
          this.setState({
            numberDaysFlag: true,
            numberDaysErrorMsg:
              "No of days should be greater than allocated slots"
          });
        } else this.setState({ numberDaysFlag: false });
      } else {
        this.setState({
          numberDaysFlag: true,
          numberDaysErrorMsg: "Only numeric values are allowed"
        });
      }

      this.setState({ numberDays: e.target.value });
    } else {
      this.setState({
        numberDaysFlag: true,
        numberDaysErrorMsg: "Please Select End Date"
      });
    }
  };



  days_between = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = date1 - date2;

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  };
  // todo: To Date onChange Function ...................

  toDateHandler = e => {
    //alert(dateFormat(e, 'yyyy-mm-dd'))
    if (new Date(this.state.ContractEndDate) < new Date(e)) {
      this.setState({
        toDateFlag: true,
        toDateErrorMsg: "You have exceeded contract end date",
        toDate: new Date(e),
        numberDays: "",
        numberDaysFlag: false,
      });
    } else
      this.setState({
        toDate: new Date(e),
        numberDays: "",
        toDateFlag: false,
        numberDaysFlag: false,
        toDateErrorMsg: "Field Required"
      });
    // this.setState({ toDate: e.target.value, toDateFlag: false });
  };

  scrollToUploadFile = () => {
    this.uploadFile.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  // todo: SaveHandler onClick Function ...................
  doneHandler = () => {
    //alert('test')

    if (this.state.fileimg === "") {

      if (this.state.ImageErrorMsg === "")
        this.setState({
          ImageErrorMsg: "Please upload an image"
        });
        this.scrollToUploadFile()
    } else if (this.state.searchName === "") {
      this.setState({ searchNameFlag: true });
      this.scrollToUploadFile()
    } else if (this.state.fromDate === "" || this.state.fromDateFlag) {
      this.setState({ fromDateFlag: true });
      this.scrollToUploadFile()
    } else if (this.state.toDate === "" || this.state.toDateFlag) {
      this.setState({ toDateFlag: true });
      this.scrollToUploadFile()
    } else if (this.state.numberDaysFlag || this.state.numberDays === "") {
      this.setState({
        numberDaysErrorMsg:
          this.state.numberDaysFlag && this.state.numberDays.length > 0
            ? this.state.numberDaysErrorMsg
            : "Field Required",
        numberDaysFlag: true
      });
      this.scrollToUploadFile()
    } else if (this.state.numberDays < 1) {
      this.setState({
        numberDaysErrorMsg: "Number of days should be greater than 0",
        numberDaysFlag: true
      });
    }
    else if (this.state.redirectURLFlag) {
      this.setState({
        redirectURLFlag: true
      })
    }
    else if (this.state.zoneId === "" || this.state.zoneIdFlag) {
      this.setState({
        zoneIdFlag: true
      })
    }


    else {

      // alert(this.state.fromDate.getUTCFullYear()+"-"+ this.state.fromDate.getUTCMonth()+"-"+ this.state.fromDate.getUTCDate())
      // alert(this.state.toDate.getUTCFullYear()+"-"+ this.state.toDate.getUTCMonth()+"-"+ this.state.toDate.getUTCDate())
      // alert(moment(this.state.fromDate).format('YYYY-MM-DD'))
      // alert(moment(this.state.toDate).format('YYYY-MM-DD'))
      // alert(this.state.toDate.getDate() - this.state.toDate.getUTCDate())

      console.log("zoneId",JSON.parse(this.state.zoneId))


      var data = {
        business_id: parseInt(this.state.searchName),
        start_date: this.state.fromDate, // moment(this.state.fromDate).add(this.state.fromDate.getDate() - this.state.fromDate.getUTCDate(), 'days').format('YYYY-MM-DD'), //moment(this.state.fromDate).format('YYYY-MM-DD'),
        end_date: this.state.toDate, // moment(this.state.toDate).add(this.state.toDate.getDate() - this.state.toDate.getUTCDate(), 'days').format('YYYY-MM-DD'), //  moment(this.state.toDate).format('YYYY-MM-DD'),
        number_of_days: this.state.numberDays,
        seven_days: this.state.weekCheck,
        week_days: this.state.fiveCheck,
        week_ends: this.state.twoCheck,
        notes: this.state.notes,
        selectedImage: this.state.selectedImage,
        banner_redirect_url: this.state.redirectURL === "" ? null : this.state.redirectURL,
        fileimg: this.state.fileimg,
        zone_id: this.state.zoneId
      };

      //console.log("Current State redirect URL",data.banner_redirect_url)
      //console.log("redirect URL in db",this.props.UpdateBannerData.redirectURL)

      if (this.props.UpdateBannerData) {
        // delete data.selectedImage;
        const prevDate = this.props.UpdateBannerData;
        console.log("This is the previous data", prevDate)

        var flag = false;
        if (
          dateFormat(new Date(prevDate.start_date), "yyyy-mm-dd") !=
          dateFormat(data.start_date, "yyyy-mm-dd")
        )
          flag = true;
        if (
          dateFormat(new Date(prevDate.end_date), "yyyy-mm-dd") !=
          dateFormat(data.end_date, "yyyy-mm-dd")
        )
          flag = true;


        console.log("prevdate", prevDate)
        console.log("prevdate", data)


        if (prevDate.number_of_days != data.number_of_days) flag = true;
        if (prevDate.notes != data.notes) flag = true;
        if (prevDate.banner_location != this.state.fileimg) flag = true;
        if (prevDate.seven_days != data.seven_days) flag = true;
        if (prevDate.week_days != data.week_days) flag = true;
        if (prevDate.week_ends != data.week_ends) flag = true;
        if (prevDate.banner_redirect_url != data.banner_redirect_url) flag = true;
        if (prevDate.zone_id != data.zone_id) flag = true;

        if (flag)
          this.props.updateRetailerBanner(data, this.props.UpdateBannerData.banner_id);
      } else {
        if (this.props.DuplicateBannerData)
          data = {
            ...data,
            banner_location: this.state.fileimg
          };
        //alert(JSON.stringify(data))
        this.props.saveUploadBanner(data);
      }
      // alert('success' + this.props.UpdateBannerData.banner_id)
      //  this.props.saveUploadBanner(data);
    }
  };
  // todo: homeHandler onClick function ..........................

  setRetailerList = RetailerList => {
    this.setState({ RetailerList });
  };
  setNotes = e => {
    // this.state.notes = e.target.value;
    // if(this.state.notes.length <300)
    this.setState({
      notes: e.target.value
    });
  };


  inputHandler = (data) => {
    // this function clears the upload file image
    // alert(JSON.stringify(data))

    const {
      editMode,
      fileimg,
      selectedImage,
      ImageErrorMsg,
      LogoSize,
      FileLength 
    } = data
    
    this.setState({
      editMode: editMode,
      fileimg: fileimg,
      selectedImage: selectedImage,
      ImageErrorMsg: ImageErrorMsg,
      LogoSize: LogoSize,
      FileLength: FileLength 
    });
  };
  inputCloseHandler=()=>{
    this.setState({
      editMode: true,
      fileimg: "",
      selectedImage: "",
      ImageErrorMsg: ""
    })
  }

  handleModel=(check)=>{
    this.setState({
      showModel:check,
      ImageErrorMsg: "",
      // editMode: false,
    })
  }

  zoneIdHandler =(zoneId)=>{

    console.log("zone value", zoneId)
    
    this.setState({
      zoneId: zoneId,
      zoneIdFlag: false      
    })

  }

  render() {
    const {
      searchName,
      searchNameFlag,
      searchNameErrorMsg,
      fromDate,
      fromDateErrorMsg,
      fromDateFlag,
      toDate,
      toDateErrorMsg,
      toDateFlag,
      numberDays,
      numberDaysErrorMsg,
      numberDaysFlag,
      redirectURL,
      redirectURLErrorMsg,
      redirectURLFlag,
      zoneId,
      zoneIdFlag,
      zoneIdErrorMsg
    } = this.state;
    return (
      <div className="upload-banner-wrap">
        <div className="upload-banner-container">
          <div className="upload-banner-box">
            <div className="upload-banner-box2">
              <div ref={(ref)=>this.uploadFile=ref}  className="upload-banner-select">
                {/* <div className="upload-banner-select-label">Select Banner</div> */}
                {this.state.showModel ?
            <Modal onClose={()=>this.handleModel(false)} inputHandler={(data)=>this.inputHandler(data)}/>
             :null}
                {this.state.editMode === true ? (
                  <div
                  className="pe-file-new-box"
                   >
                      <button className="camera-upload-hover-upload" onClick={()=>this.handleModel(true)}>
                        New Upload
                      </button>
                    <p className="error-upload">{this.state.ImageErrorMsg}</p>
                  </div>
                ) : (
                  <div className="pe-img-container1-upload">
                    {/* {this.state.allocation == 0 ? ( */}
                      <div>
                        <button
                          className="pe-btn1-upload"
                          onClick={this.inputCloseHandler}
                        >
                          <i className="material-icons pe-btn-clear">clear</i>
                        </button>
                      </div>
                    {/* ) : null} */}
                    <div className="pe-img-icon1-upload">
                      <img
                        src={this.state.fileimg}
                        alt="not found"
                        onLoad={this.onImgLoad}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div className="pe-text-block1-upload">
                      <p className="pe-p1-1-upload">
                        {this.state.selectedImage.name}
                      </p>
                      {/* <p className="pe-p1-2">
                      {Math.round(this.state.LogoSize / 1024)} KB
                    </p>  */}
                    </div>
                  </div>
                )}
              </div>
              <div className="upload-banner-input-box">
                <div className="upload-form-input-b">
                  <div className="upload-form-label">Partner Name</div>
                  <div className="upload-form-input">
                    {this.state.business_name == "" ? (
                      <div>
                        <SelectSearchInput
                          inputValue={searchName}
                          placeholder="Search for Partner Banners"
                          flag={searchNameFlag}
                          errorMsg={searchNameErrorMsg}
                          onChange={this.searchNameHandler}
                          data={this.state.RetailerList}
                        />
                        <RetailerListContainer
                          setRetailerList={this.setRetailerList}
                        />
                      </div>
                    ) : (
                      <SimpleInput
                        id="showSearchBanners"
                        type="text"
                        inputValue={this.state.business_name}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>
                <div className="upload-form-input-b">
                  <div className="upload-form-label">Start Date</div>
                  <div className="upload-form-input">
                    <DatePicker
                      htmlFor="startDate"
                      inputValue={fromDate}
                      errorMsg={fromDateErrorMsg}
                      flag={fromDateFlag}
                      minDate={new Date()}
                      onChange={this.fromDateHandler}
                      disabled={
                        this.state.allocation !== 0 &&
                        this.props.UpdateBannerData != undefined
                      }
                    />
                  </div>
                </div>
                <div className="upload-form-input-b">
                  <div className="upload-form-label">End Date</div>
                  <div className="upload-form-input">
                    <DatePicker
                    htmlFor="endDate"
                      inputValue={toDate}
                      errorMsg={toDateErrorMsg}
                      flag={toDateFlag}
                      minDate={this.state.MintoDate}
                      onChange={this.toDateHandler}
                      disabled={this.state.ContractEndDate == ""}
                    />
                  </div>
                </div>
                <div className="upload-form-input-b">
                  <div className="upload-form-label">Number of Days</div>
                  <div className="upload-form-input">
                    <SimpleInput
                      type="text"
                      maxLength="10"
                      name="number days"
                      id="numberDays"
                      inputValue={numberDays}
                      errorMsg={numberDaysErrorMsg}
                      flag={numberDaysFlag}
                      onChange={this.numberDaysHandler}
                    />
                  </div>
                </div>
                <div className="upload-form-radio">
                  <div className="upload-form-r-box">
                    <SingleInputRadio
                      checked={this.state.weekCheck}
                      label="7 days a week"
                      onChange={this.weekRadioHandler}
                      id="7DaysWeek"
                    />
                  </div>
                  <div className="upload-form-r-box">
                    <SingleInputRadio
                      checked={this.state.fiveCheck}
                      label="Weekdays"
                      onChange={this.fiveRadioHandler}
                      id="weekdays"
                    />
                  </div>
                  <div className="upload-form-r-box">
                    <SingleInputRadio
                      checked={this.state.twoCheck}
                      label="Weekends"
                      onChange={this.twoRadioHandler}
                      id="weekends"
                    />
                  </div>
                </div>
                
                <div className="upload-form-input-b">
                <div className="upload-form-label">Zone Type</div>
                  <div className="upload-form-input">
                    <CustomDropDownInput
                      data={zoneIdData}
                      type="text"
                      name="CouponType"
                      id="offerClass"
                      value={zoneId}
                      flag={zoneIdFlag}
                      errorMsg={zoneIdErrorMsg}
                      onChangeEvent={this.zoneIdHandler}
                      // disabled={this.props.disabled}
                      LoadingIcon={this.props.LoadingIcon}
                      style={{"border":"1px solid #cccccc"}}
                      // loaderStyle={{ left: '15px', top: '8px' }}
                    />

                  </div>
                </div>

                <div className="upload-form-input-b">
                  <div className="upload-form-label">Redirect URL (Optional)</div>
                  <div className="upload-form-input">
                    <SimpleInput
                      type="text"
                      name="redirect URL"
                      id="redirectURL"
                      maxLength="255"
                      inputValue={redirectURL}
                      errorMsg={redirectURLErrorMsg}
                      flag={redirectURLFlag}
                      onChange={(e) => this.redirectURLHandler(e)}
                    />
                  </div>
                </div>

                <div className="upload-form-input-b">
                  <div className="upload-form-textarea new-option">
                    <LabelTextAreaInput
                      label={"Notes (Optional)"}
                      id="notesOptional"
                      onChange={this.setNotes}
                      inputValue={this.state.notes}
                      maxLength={"300"}
                    />
                  </div>
                </div>
                <div className="upload-form-button-box">
                  <div className="back-btn">
                    <Link id="backLink" to='/Manage_Banner' className="back-link">Back</Link>
                  </div>
                  <div className="next-btn">
                    <button
                      id="doneButton"
                      className="upload-button"
                      onClick={this.doneHandler}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadBannerForm;
