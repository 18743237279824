import { GetCouponTypesStatus, CouponTypesActions } from './GetCouponTypesConstants';

const GetCouponTypes_initialState = {
    GetCouponTypes_Status: GetCouponTypesStatus.GetCouponTypes_GET.NEW,
    GetCouponTypes_Error: '',
    GetCouponTypes: [],
}
export default  function  (state = GetCouponTypes_initialState, action) {
    switch (action.type) {
        case CouponTypesActions.GetCouponTypes_GET.LOADING:
            return { ...state, GetCouponTypes_Status: GetCouponTypesStatus.GetCouponTypes_GET.LOADING }
            case CouponTypesActions.GetCouponTypes_GET.NEW:
                return { ...state, GetCouponTypes_Status: GetCouponTypesStatus.GetCouponTypes_GET.NEW }
    
        case CouponTypesActions.GetCouponTypes_GET.FAILED:
            return { ...state,  GetCouponTypes_Status: GetCouponTypesStatus.GetCouponTypes_GET.FAILED, GetCouponTypes_Error:action.error}
        case CouponTypesActions.GetCouponTypes_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  GetCouponTypes_Status: GetCouponTypesStatus.GetCouponTypes_GET.SUCCESS, GetCouponTypes:action.payload}
        
        default:
            return { ...state,
            }
    }
}
