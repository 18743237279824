export const BusinessListActions= {
	
	BusinessList_GET :{
		NEW:"BusinessList_GET_NEW",
		SUCCESS:"BusinessList_GET_SUCCESS",
		FAILED:"BusinessList_GET_FALIURE",
		LOADING:"BusinessList_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const BusinessListStatus ={

	BusinessList_GET :{
		NEW:"BusinessList_GET_NEW",
		SUCCESS:"BusinessList_GET_SUCCESS",
		FAILED:"BusinessList_GET_FALIURE",
		LOADING:"BusinessList_GET_LOADING",
	}

}
