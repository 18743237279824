import { connect } from "react-redux";
import BusinessCouponQuotas from "./BusinessCouponQuotas";
import {BusinessCouponQuotasServer} from "./server"
import {BusinessCouponQuotasActions} from './BusinessCouponQuotasconstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        BusinessCouponQuotas_post_status:state.BusinessCouponQuotasReducer.BusinessCouponQuotas_Status,
        BusinessCouponQuotas_Message:state.BusinessCouponQuotasReducer.BusinessCouponQuotas_Message,
        BusinessCouponQuotas:state.BusinessCouponQuotasReducer.BusinessCouponQuotasDetails,
        Goto_ManageCoupons:state.BusinessCouponQuotasReducer.Goto_ManageCoupons,
        Goto_OverridePricing:state.BusinessCouponQuotasReducer.Goto_OverridePricing,
        GetFaceValues:state.GetFaceValuesReducer.GetFaceValuesDetails,

    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        saveBusinessCouponQuotas: (data) => {

           dispatch( BusinessCouponQuotasServer.saveBusinessCouponQuotas(data) )
        },
        getBusinessCouponQuotasList: (yearId, businessId) => {
           // alert("test")
            dispatch( BusinessCouponQuotasServer.getBusinessCouponQuotasList(yearId, businessId) )
         },

         updateBusinessCouponQuotas: (data) => {

            dispatch( BusinessCouponQuotasServer.updateBusinessCouponQuotas(data) )
         },
         
        resetReducerState: async ()=>{
            await dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.LOADING})
            dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.NEW})

        },

        set_Goto_ManageCoupons:(ManageCouponFlag, OverridePricingFlag)=>{
        dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.Go_To_ManageCoupons, payload:{ManageCouponFlag:ManageCouponFlag,OverridePricingFlag:OverridePricingFlag}})
            
        }
        

    }
};
const BusinessCouponQuotasContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessCouponQuotas)

export default BusinessCouponQuotasContainer;