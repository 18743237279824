
//******************Exchange Rate Server Calls******************;

import { PreviousCouponsActions } from './GetPreviousCouponsConstants';
import store from '../../../Store/store'
import {ROOT_URL, getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"

export const PreviousCouponsServer = {
  //  GetPreviousCoupons:GetPreviousCoupons,
  GetPreviousCoupons: GetPreviousCoupons,
  //  updateGetPreviousCoupons:updateGetPreviousCoupons
};

//****************** GetPreviousCoupons with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function GetPreviousCoupons(data) {
// alert(JSON.stringify(data))
  const request = fetch(ROOT_URL + '/api/coupons/GetPreviousCoupons', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      console.log(response)
      if (response.status == "200") {
        // alert(JSON.stringify(response.exchangeRates))
        console.log('Business quota details', response.data)

  //****************** responce.data contains values of business Quotas along with business crontact
  //****************** responce.CouponFaceValues contains count of generated coupon based on business_id and year_id
        store.dispatch({ type: PreviousCouponsActions.GetPreviousCoupons_GET.SUCCESS, payload: response.data, exchangeRates:response.exchangeRates});
        return;
      } else {
        store.dispatch({ type: PreviousCouponsActions.GetPreviousCoupons_GET.FAILED, error:response.error });
        return;
      }
    }).catch((error) => {
      console.log(error)
      store.dispatch({ type: PreviousCouponsActions.GetPreviousCoupons_GET.FAILED, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
    console.log(error)
    store.dispatch({ type: PreviousCouponsActions.GetPreviousCoupons_GET.FAILED,error:failure_messages.unExpectedError });
  })
  return { type: PreviousCouponsActions.GetPreviousCoupons_GET.LOADING }

};
