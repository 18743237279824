import React, { PureComponent, Fragment } from "react";
//import './RetailerCouponApproval.css'
import { RetailerCouponApprovalStatus } from "./RetailerCouponApprovalConstants";
import RetailerCouponApproval from "./RetailerCouponApproval";
import { withRouter } from "react-router-dom";
// import dateFormat from "dateformat";
import moment from "moment"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Loading from "../../../Utils/Loading";
import {ref_status} from "RefRecordsConstants";
import { Api_Pagination } from "RefRecordsConstants";
import { ADMIN_END_BRANDING_IMAGE_URL } from "../../../../Config/Config";


export class RetailerCouponApprovalView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      loading:false,
      isEmailEnabled:false
    };
    props.resetReducerState()
  }



  getTimeWithTimeZone = (inpuDate) =>{


    let timeWithoutOffset = moment(new Date(inpuDate)).format("HH:mm:ss")
    
    let offSet = new Date().getTimezoneOffset()
    offSet = offSet / 60

    let symbol = "-"
    if (offSet < 0) {
      symbol = "+"
      offSet = Math.abs(offSet)
    }
    if (offSet < 10)
      offSet = "0" + offSet

    const timeWithOffset = (moment(inpuDate).format("YYYY-MM-DD") + "T" + timeWithoutOffset + symbol + offSet + ":00")

    return timeWithOffset

  }

  setIsEmailEnabled = ()=>{

    this.setState({
      isEmailEnabled: !this.state.isEmailEnabled
    })

  }

  approveHandler = () => {
    
    console.log("coupon image", this.props.coupon)
    // return;

    let coupon = this.props.coupon
    
    // alert(this.props.coupon.offer_description)
    // // alert(this.props.coupon.description)

    // alert(this.props.offer_description ? this.props.offer_description : (this.props.description && this.props.description != '' ? this.props.description : null))

     let data = {
            batch_status:ref_status.ACTIVE,
            coupon_batch_id:this.props.coupon_batch_id,
            feedback: null,
              generated_by:this.props.cookies.get("njt_admin_userId_partner") &&
            this.props.cookies.get("njt_admin_userId_partner").user_name
              ? this.props.cookies.get("njt_admin_userId_partner").user_name
              : "admin",
              coupon_face_value: coupon.face_value,
              coupon_terms: coupon.coupon_term,
              promotion_start_date: moment(new Date(coupon.promotion_start_date)).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",
              //  +" 23:59:59",
              promotion_end_date:moment(new Date(coupon.promotion_end_date)).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",
              promotion_end_date_for_email:moment(coupon.promotion_end_date).format("MM/DD/YYYY h:mm A"),
              //  +" 23:59:59",
              coupon_create_dttm: new Date(),
              business_id: coupon.business_id,
              coupon_quota: coupon.coupon_quantity,
              year_id: coupon.year_id,
              month_id: coupon.month_id,
              image_location: coupon.image_location ? coupon.image_location.split(";;;")[0] : (coupon.logo_url ? coupon.logo_url : (ADMIN_END_BRANDING_IMAGE_URL+"Njt-Logo-Email.png")),
              override_coupon_pricing: false,
              coupon_transaction_type_id: coupon.coupon_transaction_type_id,
              legalese: (coupon.legalese === "" || !coupon.legalese) ? "No Terms & Conditions Applied": coupon.legalese,
              coupon_code:coupon.coupon_code,
              redemption_url:coupon.redemption_url,
              email_address:coupon.email_address,
              business_name:coupon.business_name,
              formattedTerms:this.props.formattedTerms,
              formattedAddress:this.props.formattedAddress,
              formattedCouponTag: this.props.formattedCouponTag,
              description:coupon.offer_description ? coupon.offer_description: coupon.description && coupon.description != '' ? coupon.description : null,
              company_url:this.props.company_url,
              formattedPoints:this.props.formattedPoints,
              showPointStarIcon: this.props.showPointStarIcon,
              coupon_code_file_url:coupon.coupon_code_file_url && coupon.coupon_code_file_url !=""? coupon.coupon_code_file_url :null 
      }
      // alert(moment(new Date(coupon.promotion_start_date)).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00")
      if(!this.state.loading)
      {
        
        // console.log("to send data", data)
        // return; 

        this.state.loading = true
        this.props.resetFilterStates()
        
        this.props.handleRetailerCouponApproval(data)
      }
  };

  disapproveHandler = () =>{

    var flag = false
    var feedback=""
    let feedbackArray = []
    this.props.ApprovalCheckList.forEach(element => {
      if(element.value)
      {
        flag = true
        // alert(JSON.stringify(element))
        feedback = feedback+". "+element.rejected_text
        feedbackArray  = [...feedbackArray, element.rejected_text]
      }
    });
    if(flag){
      const data = {
        batch_status:ref_status.DECLINED,
        coupon_batch_id:this.props.coupon_batch_id,
        feedback:feedback.replace(". ",""),
        disapprove:true,
        email_address:this.props.coupon.email_address,
        feedbackArray:feedbackArray,

        business_name:this.props.coupon.business_name,
        formattedCouponTag: this.props.formattedCouponTag,
        image_location: this.props.coupon.image_location ? this.props.coupon.image_location : (this.props.coupon.logo_url ? this.props.coupon.logo_url : (ADMIN_END_BRANDING_IMAGE_URL+"Njt-Logo-Email.png")),
        formattedTerms:this.props.formattedTerms,
        formattedAddress:this.props.formattedAddress,
        formattedPoints:this.props.formattedPoints,
        showPointStarIcon: this.props.showPointStarIcon,
        description:this.props.description,
        company_url:this.props.company_url,
        legalese: (this.props.coupon.legalese === "" || !this.props.coupon.legalese) ? "No Terms & Conditions Applied": this.props.coupon.legalese,
        promotion_end_date: moment(this.props.coupon.promotion_end_date).format("MM/DD/YYYY h:mm A"),
        isEmailEnabled: this.state.isEmailEnabled
        }
        if(!this.state.loading)
      {
        // console.log("decline reason\n", this.props.ApprovalCheckList)
        // console.log("decline reason\n", feedbackArray)

        
        // alert(JSON.stringify(data))

        this.state.loading = true
        this.props.resetFilterStates()

        this.props.handleRetailerCouponApproval(data)
      }
        // alert(JSON.stringify(data))
    }
    
    return flag
  }


  sendFeedbackHandler = () =>{

    let coupon = this.props.coupon
    
    var flag = false
    var feedback=""
    let feedbackArray = []
    this.props.ApprovalCheckList.forEach(element => {
      if(element.value)
      {
        flag = true
        // alert(JSON.stringify(element))
        feedback = feedback+". "+element.rejected_text
        feedbackArray  = [...feedbackArray, element.rejected_text]
      }
    });
    if(flag){
      const data = {
        batch_status:ref_status.SAVED,
        coupon_batch_id:this.props.coupon_batch_id,
        feedback:feedback.replace(". ",""),
        disapprove:true,
        sendFeedback: true,
        email_address:this.props.coupon.email_address,
        feedbackArray: feedbackArray,

        business_name:this.props.coupon.business_name,
        formattedCouponTag: this.props.formattedCouponTag,
        image_location: this.props.coupon.image_location ? this.props.coupon.image_location : (this.props.coupon.logo_url ? this.props.coupon.logo_url : (ADMIN_END_BRANDING_IMAGE_URL+"Njt-Logo-Email.png")),
        formattedTerms:this.props.formattedTerms,
        formattedAddress:this.props.formattedAddress,
        formattedPoints:this.props.formattedPoints,
        showPointStarIcon: this.props.showPointStarIcon,
        description:coupon.offer_description ? coupon.offer_description: coupon.description && coupon.description != '' ? coupon.description : null,
        company_url:this.props.company_url,
        legalese: (this.props.coupon.legalese === "" || !this.props.coupon.legalese) ? "No Terms & Conditions Applied": this.props.coupon.legalese,
        promotion_end_date: moment(this.props.coupon.promotion_end_date).format("MM/DD/YYYY h:mm A"),
        isEmailEnabled: this.state.isEmailEnabled
        }
      if (!this.state.loading) {
        console.log("decline reason\n", this.props.ApprovalCheckList)
        console.log("decline reason\n", feedbackArray)

        // alert(JSON.stringify(data))


        this.state.loading = true
        this.props.resetFilterStates()

        this.props.handleRetailerCouponApproval(data)
      }
        // alert(JSON.stringify(data))
    }
    
    return flag
  }



  getScreen(status) {
    switch (status) {
      case RetailerCouponApprovalStatus.RefrenceData_Update.NEW:
        // setRecentlyDeleted={this.props.setRecentlyDeleted}

        return (
          <Fragment>
            <RetailerCouponApproval
              approveHandler={this.approveHandler}
              disapproveHandler={this.disapproveHandler}
              sendFeedbackHandler={this.sendFeedbackHandler}

              handleShowPreviewModal={this.props.handleShowPreviewModal}

              isEmailEnabled={this.state.isEmailEnabled}
              setIsEmailEnabled={this.setIsEmailEnabled}
              // className={this.props.className}
              // ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              // handleRetailerCouponApproval={this.props.handleRetailerCouponApproval}
            />
          </Fragment>
        );

      case RetailerCouponApprovalStatus.RefrenceData_Update.SUCCESS:
        if(this.state.loading){
          this.state.loading = false
          toast.success(this.props.RetailerCouponApproval_Message);
          this.props.handleCloseModal()

          const data = {
            offset : (this.props.activePage - 1) * Api_Pagination.CouponApprovalLimit
          }
          //activePage={this.props.activePage}
          //alert(this.props.activePage)
          this.props.getRetailerCouponsInventory(data)
        }
        return (
          <Fragment>
            <RetailerCouponApproval
              approveHandler={this.approveHandler}
              disapproveHandler={this.disapproveHandler}
              sendFeedbackHandler={this.sendFeedbackHandler}

              handleShowPreviewModal={this.props.handleShowPreviewModal}

              isEmailEnabled={this.state.isEmailEnabled}
              setIsEmailEnabled={this.setIsEmailEnabled}

              // className={this.props.className}
              // ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              // handleRetailerCouponApproval={this.props.handleRetailerCouponApproval}
            />
          </Fragment>
        );
        break;
      case RetailerCouponApprovalStatus.RefrenceData_Update.FAILED:
        if(this.state.loading)
       { 
        this.state.loading = false
        toast.error(this.props.RetailerCouponApproval_Message);
        this.props.resetReducerState()
        this.props.getRetailerCouponsInventory()
       }

        return (
          <Fragment>
            <RetailerCouponApproval
              approveHandler={this.approveHandler}
              disapproveHandler={this.disapproveHandler}
              sendFeedbackHandler={this.sendFeedbackHandler}

              handleShowPreviewModal={this.props.handleShowPreviewModal}

              isEmailEnabled={this.state.isEmailEnabled}
              setIsEmailEnabled={this.setIsEmailEnabled}

              // className={this.props.className}
              // ExchangeRatesDollarToPoints={this.props.ExchangeRatesDollarToPoints}
              // handleRetailerCouponApproval={this.props.handleRetailerCouponApproval}
            />
          </Fragment>
        );

      case RetailerCouponApprovalStatus.RefrenceData_Update.LOADING:
        this.state.open = true;
        this.state.loading = true
        return (
          <Fragment>
            <RetailerCouponApproval
              approveHandler={this.approveHandler}
              disapproveHandler={this.disapproveHandler}
              sendFeedbackHandler={this.sendFeedbackHandler}

              handleShowPreviewModal={this.props.handleShowPreviewModal}

              isEmailEnabled={this.state.isEmailEnabled}
              setIsEmailEnabled={this.setIsEmailEnabled}

              LoadingIcon={true}
              loaderStyle={{top:"10px", left:"-10px"}}

            />
          </Fragment>
        );
        // return <Loading />
        break;
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.RetailerCouponApproval_Status)
    return this.getScreen(this.props.RetailerCouponApproval_Status);
  }
}

export default withRouter(RetailerCouponApprovalView);
