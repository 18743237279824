import { connect } from "react-redux";
import PausePlayCoupon_View from "./PausePlayCoupon_View";
import {PausePlayCouponServer} from "./server"
import {PausePlayCouponActions} from './PausePlayCouponConstants'
import { withCookies } from 'react-cookie';
const mapStateToProps = state => {
    console.log(state)
    return {
        PausePlayCoupon_post_status:state.PausePlayCouponReducer.PausePlayCoupon_Status,
        PausePlayCoupon_Message:state.PausePlayCouponReducer.PausePlayCoupon_Message,
        PausePlayCoupon:state.PausePlayCouponReducer.PausePlayCouponDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updatePausePlayCoupon: (data, bussinessId) => {
        //     dispatch( PausePlayCouponServer.updatePausePlayCoupon(data, bussinessId) )
        //  },

        handleEndCoupon:(data,token)=>{
            dispatch( PausePlayCouponServer.handleEndCoupon(data, token))
        },

        handlePausePlayCoupon: (data, token) => {

           dispatch( PausePlayCouponServer.handlePausePlayCoupon(data, token))
        },
        resetReducerState: () => {
            dispatch({type:PausePlayCouponActions.PausePlayCoupon_Post.NEW})
         }
        //  updatePausePlayCoupon: (data, token) =>{
        //     dispatch( PausePlayCouponServer.updatePausePlayCoupon(data, token) )
        //  }
        //  GotoContract: () => {
        //     dispatch({type:PausePlayCouponActions.PausePlayCoupon_Post.GOTO_CONTRACTS})
        //  },
        
    }
};
const PausePlayCouponContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PausePlayCoupon_View)

export default withCookies(PausePlayCouponContainer);