import React, { Component, Fragment } from "react";

// import {Fonts} from '../../utils/Fonts';
import { GetCouponTermsStatus } from "./GetCouponTermsConstants";
import { withCookies } from "react-cookie";
// import SelectSearchInput from "../../Inputs/SelectSearch/SelectSearchInput";
import AutoCompleteInput from "../../Inputs/SelectSearch/AutoCompleteInput";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import LoadingScreen from "../../Utils/Loading";
import {coupon_transaction_subtype_id, coupon_transaction_type_id} from "RefRecordsConstants"
export class GetCouponTermsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TermName: "",
      face_value:"",

    };

  }
  interpolate = (template, params)=>{
  
    for (let key in params) {
      // alert(JSON.stringify(key))
      template = template.replace(new RegExp('\\$\\{' + key + '\\}', 'g'), params[key])
    }
    if(coupon_transaction_subtype_id.PercentageType == this.props.coupon_transaction_subtype_id)
   { //template = template.replace(/\$/g,"%",)
    var tmp = template.split(" ")
    template=""
    tmp.forEach((str, index)=>{
      var chunk = str
      if(chunk.includes("$")){
        chunk= chunk.replace("$","")+"%"
      }
      template=template+" "+chunk
    })


  }
    // alert(this.props.coupon_transaction_subtype_id)
    return template.trim()
  }

  setTermChange = (e) => {
  
    if (e) {
      // this.setState({
      //   TermName: e.label.substring(0,100),
      // });
      this.props.setRetailerCouponTerm(e.label.substring(0,100));
    } else {
      // this.setState({
      //   TermName: "",
      // });
      this.props.setRetailerCouponTerm(null);
    }
  };

static getDerivedStateFromProps(nextProps, prevState) {
  if (prevState.face_value !== nextProps.faceValue) {
    if(prevState.face_value == "" && nextProps.GetCouponTerms_GET_status == GetCouponTermsStatus.GetCouponTerms_GET.NEW)
    nextProps.GetCouponTerms()
    else if(nextProps.GetCouponTerms_GET_status == GetCouponTermsStatus.GetCouponTerms_GET.FAILED)
    nextProps.GetCouponTerms()
    return {
      face_value:nextProps.faceValue
    };
  
  }
  
  return null;
}
componentWillUnmount(){
  this.props.resetReducerState()
}

  getScreen(status) {
    switch (status) {
      case GetCouponTermsStatus.GetCouponTerms_GET.NEW:
        return (
          <Fragment>
            <AutoCompleteInput
              maxLimit={100}
              placeholder="Enter Offer Terms"
              onChange={this.setTermChange}
              inputValue={this.props.TermName}
              data={this.props.CouponTerms}
              disabled = {true}
            />
          </Fragment>
        );
      case GetCouponTermsStatus.GetCouponTerms_GET.SUCCESS:
      var Terms = []
        if(Array.isArray(this.props.CouponTerms))
        this.props.CouponTerms.forEach(element => {   

          var item = {... element}
          if(coupon_transaction_type_id.buy_x_gey_y == this.props.coupon_transaction_type_id)
          {
            if(item.label.includes("${Y}") && this.props.xquantity !="" && this.props.yquantity !="")
            {
              item.label = this.interpolate(item.label, {X:this.props.xquantity,Y:this.props.yquantity, Z:this.state.face_value})
              Terms =[...Terms, item]
            }
          }else if (!item.label.includes("${Y}")){
          item.label = this.interpolate(item.label, {X:this.state.face_value})
          Terms =[...Terms, item]
          }
        });
        return (
          <Fragment>
            <AutoCompleteInput
              maxLimit={100}
              placeholder="Enter Offer Terms"
              inputValue={this.props.TermName}
              onChange={this.setTermChange}
              data={Terms}
            />
          </Fragment>
        );
        break;
      case GetCouponTermsStatus.GetCouponTerms_GET.FAILED:
          
        return (
          <Fragment>
            <AutoCompleteInput
              maxLimit={100}
              placeholder="Enter Offer Terms"
              onChange={this.setTermChange}
              inputValue={this.props.TermName}
              data={[]}
              disabled = {true}
            />
          </Fragment>
        );

      case GetCouponTermsStatus.GetCouponTerms_GET.LOADING:
        return (

          // <LoadingScreen />
          <AutoCompleteInput
          maxLimit={100}
          placeholder="Enter Offer Terms"
          // onChange={this.setTermChange}
          // inputValue={this.props.TermName}
          data={[]}
          disabled = {true}
          LoadingIcon={true}

        />
        )
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <Fragment>
        {this.getScreen(this.props.GetCouponTerms_GET_status)}
      </Fragment>
    );
  }
}

export default withCookies(GetCouponTermsView);
