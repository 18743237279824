import React, { Component } from 'react'
import ReactDOM from "react-dom"
import "./InvoicesPopup.css"
// import logo from "../../Assets/Images/logo-2.png"
import Doc from '../PDFDownload/DocService';
// import PdfContainer from '../PDFDownload/PdfContainer';
import InvoiceDetailsContainer from './InvoiceDetailsContainer';

const modalRoot = document.getElementById("invoice_portal");

// const invoicePop = [
//     { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },
//     { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },
//     { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },
//     { value: "1", quantity: "4", individualReferral: "0.25", totalFee: "2" },

//   ];


class InvoicesPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: 'Still, Jaime',
          rank: 'SGT',
          description: 'Demonstrate how to export an HTML section to PDF'
        };
      }

      onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
          state[name] = value;
          return state;
        })
      }
    
      createPdf = (html) => Doc.createPdf(html);


    onClick=()=>
    {
      this.props.onClose()
    }
    donehandler=()=>{
        alert("donehandler Work")
    }
    downloadHandler=()=>{
        alert("downloadHandler Work")
    }
    
    render() {
        console.log(this.state);
        return ReactDOM.createPortal (
            <div className="invoice-popup">
                <div className="invoice-inner">
                    <div className="invoice-btn-close">
                    <span onClick={this.onClick}><i className="material-icons">clear </i></span>
                    </div>

          <InvoiceDetailsContainer cookies={this.props.cookies} Invoice = {this.props.Invoice}/>
        
                </div>
               
                {this.props.children}
            </div>, modalRoot
        )
    }
}

export default InvoicesPopup
