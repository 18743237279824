import { GetQuotasContractDetailsStatus, GetQuotasContractDetailsActions } from './GetQuotasContractDetailsConstants';

const GetQuotasContractDetails_initialState = {
    GetQuotasContractDetails_Status: GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.NEW,
    GetQuotasContractDetails_Error: '',
    GetQuotasContractDetails: [],
    QuotaYears:[],
    paymentAccount:[]
}
export default  function  (state = GetQuotasContractDetails_initialState, action) {
    switch (action.type) {
        case GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.LOADING:
            return { ...state, GetQuotasContractDetails_Status: GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.LOADING }
            case GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.NEW:
                return { ...state, GetQuotasContractDetails_Status: GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.NEW ,
                    GetQuotasContractDetails: [],
                    QuotaYears:[]
                }
    
        case GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.FAILED:
            return { ...state,  GetQuotasContractDetails_Status: GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.FAILED, GetQuotasContractDetails_Error:action.error}
        case GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.SUCCESS:
          
          return { ...state,  GetQuotasContractDetails_Status: GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.SUCCESS, GetQuotasContractDetails:action.payload, QuotaYears:action.QuotaYearsPayload, paymentAccount:action.paymentAccount}
          case GetQuotasContractDetailsActions.GetQuotasContractDetails_GET.UPDATE:
            return { ...state,  GetQuotasContractDetails_Status: GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.UPDATE, GetQuotasContractDetails:action.payload, QuotaYears:action.QuotaYearsPayload, paymentAccount:action.paymentAccount}
            // return { ...state,  GetQuotasContractDetails_Status: GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.UPDATE, GetQuotasContractDetails:action.payload}
        
        default:
            return { ...state,
               
            }
    }
}
