import { GetReferralInvoicesFlagStatus, GetReferralInvoicesFlagActions } from './GetReferralInvoicesFlagConstants';

const GetReferralInvoicesFlag_initialState = {
    GetReferralInvoicesFlag_Status: GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.NEW,
    GetReferralInvoicesFlag_Error: '',
    DisplayTypes: [],
}
export default  function  (state = GetReferralInvoicesFlag_initialState, action) {
    switch (action.type) {
        case GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.LOADING:
            return { ...state, GetReferralInvoicesFlag_Status: GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.LOADING }
            case GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.NEW:
                return { ...state, GetReferralInvoicesFlag_Status: GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.NEW }
    
        case GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.FAILED:
            return { ...state,  GetReferralInvoicesFlag_Status: GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.FAILED, GetReferralInvoicesFlag_Error:action.error}
        case GetReferralInvoicesFlagActions.GetReferralInvoicesFlag_GET.SUCCESS:
          console.log(action.payload)
          return { ...state,  GetReferralInvoicesFlag_Status: GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.SUCCESS, DisplayTypes:action.payload}
        
        default:
            return { ...state,
                GetReferralInvoicesFlag: [],
                QuotaYears:[]
            }
    }
}
