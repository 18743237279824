import React, { Component, Fragment } from "react";
import {Modal} from "../AdministrationLogoutPopup"
import {  withRouter } from "react-router-dom";
class AdministrationMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  logoutHandler = () => {
    // alert(JSON.stringify( this.props.cookies.get("njt_admin_userId_partner")));
    var data = {
      UserId: this.props.cookies.get("njt_admin_userId_partner").application_user_id
    };
    // alert(data.njt_admin_userId_partner)
    this.props.AdministrationLogout(
      data,
      this.props.cookies.get("admin_token_partner")
    );
    this.closeModel()
    // this.props.history.push({
    //   pathname: "/login_page"
    // });
  };
  closeModel = ()=>{
    this.setState({
      open: false
    })
  }
  openModel = () =>{
    this.setState({
      open: true
    })
  }
  render() {
    return (
      <Fragment>
       {this.state.open? <Modal onClose = {this.closeModel} onConfirm={this.logoutHandler}/>:null}
        <button id="signout" className={this.props.className} onClick={this.openModel}>
          Sign Out
        </button>
      </Fragment>
    );
  }
}

export default withRouter(AdministrationMain);
