import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import "../../UserManagement/UserManagement.css";
import LabelInput from "../../Inputs/LabelInput";
import CustomLabelDropDownInput from "../../Inputs/CustomLabelDropDownInput/CustomLabelDropDownInput";
import RetailerListContainer from '../../RetailerList/RetailerListContainer'
import {ref_role}from "RefRecordsConstants"
import PasswordShowHide from "../../Inputs/PasswordShowHide/PasswordShowHide";

import UserRoleListContainer from "./UserRoleList/UserRoleListContainer"

const RolesData = [
  // {name:"Admin",value:ref_role.NJTAdmin},
  {name:"Partner",value:ref_role.SMB}
]
class AddNewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
          password: "",
          passwordErrorMsg: "Field Required",
          passwordFlag: "",
          userName: "",
          userNameErrorMsg: "Field Required",
          userNameFlag: "",
          email:"",
          emailFlag:false,
          emailErrorMsg:"Field Required",
          role_id:2,
          role:RolesData[0],
          user_id:0,
          RetailerList:[],
          retailerFlag:false,
          Retailer:"",
          Business:""
          //disableAllFields: false
        };

      }

      // disableAllFields=(disable)=>{
      //   this.setState({
      //     disableAllFields: disable
      //   })
      // }

      componentDidMount() {
        window.scrollTo(0, 0);
        // console.log("cookies", JSON.parse(this.props.cookies.njt_admin_userId_partner))
      }
      passwordHandler = e => {
        const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,])[A-Z\a-z\d.\\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,]{8,}$/;
        const validReg = reg.test(e.target.value);
        if (validReg) {
          this.setState({ passwordFlag: false });
        } else {
          this.setState({
            passwordFlag: true,
            passwordErrorMsg: "Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol"
          });
        }
        this.setState({ password: e.target.value});
      };
//********************Generate Password */
sendHandler = () =>{
  if (this.state.userNameFlag || this.state.userName === "") {
    this.setState({
      userNameErrorMsg:
        this.state.userNameFlag && this.state.userName.length > 0
          ? this.state.userNameErrorMsg
          : "Field Required",
          userNameFlag: true
    });
  }      else if (this.state.role_id == ref_role.SMB && this.state.Retailer === "") {
    this.setState({

      retailerFlag: true
    });
  }
  else if (this.state.emailFlag || this.state.email === "") {
    this.setState({
      emailErrorMsg:
        this.state.emailFlag && this.state.email.length > 0
          ? this.state.emailErrorMsg
          : "Field Required",
          emailFlag: true
    });
  }  
  else if (this.state.passwordFlag || this.state.password === "") {
      this.setState({
        passwordErrorMsg:
          this.state.passwordFlag && this.state.password.length > 0
            ? "Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol"
            : "Field Required",
        passwordFlag: true
      });
    }

       else  {
    const  data = {
        password_digest:this.state.password,
        role_id:this.state.role_id,
        email_address : this.state.email.trim(),
        user_name:this.state.userName.trim(),
        business:this.state.Retailer,
        retailerAddedByAdmin: true
      }
      if(this.state.role_id == ref_role.NJTAdmin)
      {
        data.business = ""
      this.props.AddNewAdminUser(data)
    }else{
      data.business = JSON.parse(data.business)
      data.is_email_registered = false
      this.props.AddNewRetailerUser(data)
    }
    }
}
setRetailerList = (data) =>{
  // alert(JSON.stringify(data))
  this.setState({
    RetailerList:data
  })
}

userNameHandler = e => {
  const reg = /^[a-zA-Z0-9-_.]*$/;
  const validReg = reg.test(e.target.value);
  if (validReg) {
    this.setState({ userNameFlag: false });
  } else {
    this.setState({
      userNameFlag: true,
      userNameErrorMsg: "Use only Alphanumeric, hyphen, underscore and period"
    });
  }
  this.setState({ userName: e.target.value });
};
passwordGenerateHandler = () => {
  var pLength = 9
  var keyListAlpha="abcdefghijklmnopqrstuvwxyz",
  keyListInt="123456789",
  keyListSpec="!@#$%*?&_",
  password='';
var len = Math.ceil(pLength/2);
len = len - 1;
var lenSpec = pLength-2*len;

for (var i=0;i<len;i++) {
  password+=keyListAlpha.charAt(Math.floor(Math.random()*keyListAlpha.length));
  password+=keyListInt.charAt(Math.floor(Math.random()*keyListInt.length));
}

for (var i=0;i<lenSpec;i++)
  password+=keyListSpec.charAt(Math.floor(Math.random()*keyListSpec.length));

password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
  // alert(password)
  // password = password.substr(0,len)
  this.setState({ password: password, passwordFlag:false});
  
        //  if (this.state.passwordFlag || this.state.password === "") {
        //   this.setState({
        //     passwordErrorMsg:
        //       this.state.passwordFlag && this.state.password.length > 0
        //         ? "Password should be alphanumeric and more than 7 digits and atleast 1 symbol"
        //         : "Field Required",
        //     passwordFlag: true
        //   });
        // } else {

        // }
      };
    backHandler = () =>{
        this.props.history.replace("/UserManagement")
      }
      roleHandler = (e) => {
        //alert(e.target.value)
        this.setState({
          role: e.target.value,
          role_id:JSON.parse(e.target.value).value
        });
      };

      businessHandler = (e) =>{
        // alert(e.target.value)
        this.setState({
          Retailer:e.target.value,
          retailerFlag:false
        })
      } 

      emailHandler = (e) => {


        
        const reg  = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]{2,10}$/;

        const validReg = reg.test(e.target.value);
        if (validReg) {
          this.setState({ email: e.target.value, emailFlag: false });
        } else {
          this.setState({
            emailFlag: true,
            emailErrorMsg: "Enter Valid Email",
            email: e.target.value,
          });
        }
        // this.setState({ email: e.target.value });
      };
  render() {
    console.log(this.state, "ajksdkajsdkjas")
    const {
        password,
        passwordErrorMsg,
        passwordFlag,
        userName,
        userNameErrorMsg,
        userNameFlag,
        email,
        emailErrorMsg,
        emailFlag
      } = this.state;
    return (

          <div class="fiter-search-box change-pass">
            <div className="inner-coantainer">
              <div className="pass-change-box">
                <div className="pass-input">
                <LabelInput
                  label="User Name"
                  id="userName"
                  type="text"
                  name="name"
                  inputValue={userName}
                  errorMsg={userNameErrorMsg}
                  flag={userNameFlag}
                  onChange={this.userNameHandler}
                />
                </div>
                <div className="pass-input drop">
              <UserRoleListContainer
                inputValue={this.state.role}
                onChange={this.roleHandler}
              />

                </div>
                {/* {this.state.role_id == 2? */}
                <div className="pass-input drop">
                <CustomLabelDropDownInput
                label="Business"
                name="name"
                id="BusinessSelectList"
                value=""
                firstName="Select"
                inputValue={this.state.Retailer}
                hideFirst={false}
                onChange={this.businessHandler}
                data={this.state.RetailerList}
                errorMsg ={"Field Required"}
                flag = {this.state.retailerFlag}
                //disabled={this.state.disableAllFields}
                  />
                </div>
                 {/* :null}  */}
          <RetailerListContainer setRetailerList={this.setRetailerList}/>
          
                <div className="pass-input">
                <LabelInput
                  label="Email"
                  type="text"
                  name="email"
                  id="emailInput"
                  inputValue={email}
                  flag={emailFlag}
                  errorMsg={emailErrorMsg}
                  onChange={this.emailHandler}
                  //disabled={this.state.disableAllFields}
                />
                </div>
                <div className="pass-input"> 
                <PasswordShowHide
            type={this.state.passType}
            label="New Password"
            name="text"
            id="newPassword"
            inputValue={password}
            errorMsg={passwordErrorMsg}
            flag={passwordFlag}
            onChange={this.passwordHandler}
            //disabled={this.state.disableAllFields}
            // containerStyle={{ marginTop: "20px" }}
          />
                </div>
                <div className="generate-btn">
                    <button id="generatePasswordBtn" className="filter-btn" onClick={this.passwordGenerateHandler}>
                        Generate Password
                    </button>
                </div>
                <div className="two-pass-btn">
                <button
                  className="filter-btn2 left"
                  onClick={this.backHandler}
                  id="backBtn"
                >
                  Back
                </button>
                <button
                  className="filter-btn right"
                  onClick={this.sendHandler}
                  id="doneBtn"
                >
                  Done
                </button>
                </div>
              </div>
            </div>
    
          </div>
        
    );
  }
}

export default withRouter(AddNewUser);
