import { connect } from "react-redux";
import ManageMessaging_View from "./ManageMessaging_View";
import { ManageMessagingServer } from "./server";
import { ManageMessagingActions } from "./ManageMessagingConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    ManageMessaging_Get_status:
      state.ManageMessagingReducer.ManageMessaging_Status,
      ManageMessaging_Message:
      state.ManageMessagingReducer.ManageMessaging_Message,

  };
};
const mapDispatchToProps = dispatch => {
  return {
    sendNotificationsToRetailers: (data) => {
      dispatch(ManageMessagingServer.sendNotificationsToRetailers(data));
    },
    resetReducerState: () => {
      dispatch({type:ManageMessagingActions.ManageMessaging_Get.NEW})
    },
    
  };
};
const ManageMessagingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageMessaging_View);

export default ManageMessagingContainer;
