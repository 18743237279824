import React from 'react'
import ReactDOM from 'react-dom'
// import LabelTextAreaInput from '../../Inputs/LabelTextAreaInput';
import './RetailerCouponApprovalModel.css'
// import CheckBoxSingle from '../../Inputs/CheckBoxSingle/CheckBoxSingle'
import ApprovalCheckListContainer from '../../ApprovalCheckList/ApprovalCheckListContainer'
import CheckBoxSingle from '../../Inputs/CheckBoxSingle/CheckBoxSingle'
import RotateLoading from "../../Utils/RotateLoading";


const modalRoot = document.getElementById("coupons_approval");

class Modal extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            notes: "",
            checkListError:""
        }
    }
    
    setNotes = e => {
        // this.state.notes = e.target.value;
        // if(this.state.notes.length <300)
        this.setState({
          notes: e.target.value
        });
      };
      setCheckListError=(text)=>{
        this.setState({
          checkListError:text
        })
      }

  render() {

    console.log("this are props", this.props)

    return ReactDOM.createPortal(
        <div
          style={{
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.3)',
            minHeight: "100vh",
            zIndex:"999999999"
          }}
          // onClick={onClose}
        >
          <div
          className="resp-p-new"
            style={{
              padding: 20,
              background: '#fff',
              borderRadius: '20px',
              display: 'inline-block',
              minHeight: '200px',
              margin: '1rem',
              position: 'relative',
              minWidth: '400px',
              boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              justifySelf: 'center',
              width:"40%"
            }}
          >
              <div className="approvl-model-box decline">
              <button id="closeModal" className="cros-icon-model" onClick={this.props.onClose}><span class="material-icons">close</span></button> 
                <div className="app-model-inner-box">
                    <div className="decl-model-head">
                        <h4 className="feedback-heading">{this.props.modalDescription}</h4>
                    </div>
                    
                    <div className="deline-model-box">

                        <ApprovalCheckListContainer
                        setCheckListError = {this.setCheckListError}
                          // disabled={!this.state.RangeCheck}
                          // value={this.state.showMonday}
                          // onChange={this.setMonday}
                        />


                <div className="check-list mrg-chck-list">
                  {
                    <div className="send-email-checkbox">
                      <div className="checklist-for-user">
                      <CheckBoxSingle
                        label={"Send Email Notification to 'NJT Rewards'"}
                        onChange={() => this.props.setIsEmailEnabled()}
                        value={this.props.isEmailEnabled}
                        id="sendEmail"
                      />
                      </div>

                      <RotateLoading LoadingIcon={this.props.LoadingIcon} loaderStyle={this.props.loaderStyle} />


                    </div>
                  }
                </div>



                        {/* <div className="inv-card-feild-text-area">
                            <div className="inv-card-txt">
                                <LabelTextAreaInput
                                // disabled={true}
                                label="Write your comment"
                                onChange={this.setNotes}
                                inputValue={this.state.notes}
                                maxLength = {"2000"}
                                />
                            </div>
                        </div> */}
                      
                        </div>
                    </div>
                    <div className="error-check-bx">
                      <div className="err-stles">{this.state.checkListError}</div>
                    </div>
                    <div className="inv-btn-wrap">
                        <div className="dec-app-btn-box">
                        <button id="selectListBtn" className="app-box-btn" onClick={()=>{
                          // alert(this.props.declineHandler())
                          if(!this.props.declineHandler())
                          this.setCheckListError("At least one checkbox should be selected")
                          // alert("in valid")
                        }}>
                            {this.props.confirmButtonTitle}
                        </button>
                        </div>
                    </div>
                </div>
            {this.props.children}
        </div>
      </div>,
      modalRoot,
    )
  }
}

class RetailerCouponDeclineModel extends React.Component {
  state = {showModal: false}
  handleShowMessageClick = () => this.setState({showModal: true})
  handleCloseModal = () => this.setState({showModal: false})

  render() {

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
        }}
      >
        <div
          className="dec-line-model"
        >
          <button id="declineBtn" id={this.props.id} className="decline-box" onClick={this.handleShowMessageClick}>
          {this.props.modalTitle}
          </button>
          {this.state.showModal ? (
            <Modal
              onClose={this.handleCloseModal}
              declineHandler={this.props.declineHandler}
              modalDescription={this.props.modalDescription}
              confirmButtonTitle={this.props.confirmButtonTitle}

              isEmailEnabled={this.props.isEmailEnabled}
              setIsEmailEnabled={this.props.setIsEmailEnabled}
            >
            </Modal>
          ) : null}
        </div>
      </div>
    )
  }
}



export default RetailerCouponDeclineModel

