import { GetFaceValuesStatus, GetFaceValuesActions } from './GetFaceValuesconstants';

const GetFaceValues_initialState = {
    GetFaceValues_Status: GetFaceValuesStatus.GetFaceValues_GET.NEW,
    GetFaceValues_Error: '',
    GetFaceValuesDetails: [],
}
export default  function  (state = GetFaceValues_initialState, action) {
    switch (action.type) {
        case GetFaceValuesActions.GetFaceValues_GET.LOADING:
            return { ...state, GetFaceValues_Status: GetFaceValuesStatus.GetFaceValues_GET.LOADING }
            case GetFaceValuesActions.GetFaceValues_GET.NEW:
                return { ...state, GetFaceValues_Status: GetFaceValuesStatus.GetFaceValues_GET.NEW }
    
        case GetFaceValuesActions.GetFaceValues_GET.FAILED:
            return { ...state,  GetFaceValues_Status: GetFaceValuesStatus.GetFaceValues_GET.FAILED}
        case GetFaceValuesActions.GetFaceValues_GET.SUCCESS:
          
          return { ...state,  GetFaceValues_Status: GetFaceValuesStatus.GetFaceValues_GET.SUCCESS, GetFaceValuesDetails:action.payload}
          
        
        default:
            return { ...state }
    }
}
