import React, { PureComponent, Fragment } from 'react';

import { BusinessCategoryStatus } from './BusinessCategoryConstants';
// import Loading from '../../Utils/Loading';
// import LabelDropDownInput from '../../Inputs/LabelDropDownInput';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import MultiSelectDropdown from '../../Inputs/MultiSelectDropdown/MultiSelectDropdown';

// const LoadingDummyData = [{ value: '', name: 'Loading...' }];

const stateData2 = [{ value: '', name: 'Select' }];

// const stateDataDummy = [
//   // {value:'', name: 'Select'},
//   { value: 1, name: 'Apparel & Accessories' },
//   { value: 2, name: 'Arts & Entertainment' },
//   { value: 3, name: 'Automotive' },
//   { value: 4, name: 'Beauty & Personal Care' },
//   { value: 5, name: 'Book Music & Video' },
//   { value: 6, name: 'Business' },
//   { value: 7, name: 'BusinessCellular Phone Service' },
//   { value: 8, name: 'Computer & Software' },
//   { value: 9, name: 'Education & Training' },
//   { value: 10, name: 'Electronics' },
//   { value: 11, name: 'Flowers & Gifts' },
//   { value: 12, name: 'Food & Beverage' },
//   { value: 13, name: 'Groceries' },
//   { value: 14, name: 'Health & Medicine' },
//   { value: 15, name: 'Home & Garden' },
//   { value: 16, name: 'Kids & Baby' },
//   { value: 17, name: 'Seasonal' },
//   { value: 18, name: 'Sports & Recreation' },
//   { value: 19, name: 'Toys & Games' },
//   { value: 20, name: 'Travel' },
//   { value: 21, name: 'Others' },
// ];

export class BusinessCategoryView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      BusinessCategory: props.bestTime ? props.bestTime : '',
      open: true,
    };

    props.getBusinessCategory();
    console.log('In Time to Call View');
  }
  setBusinessCategory = (businessCategories) => {
    // alert(JSON.stringify(businessCategories))
    // this.props.onChange(e.target.value)
    this.props.onChange(businessCategories);
  };

  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.bestTime !== prevState.BusinessCategory &&
      nextProps.bestTime == ''
    ) {
      // alert(nextProps.bestTime)
      return {
        BusinessCategory: nextProps.bestTime,
      };
    }
    return null;
  }

  getScreen(status) {
    switch (status) {
      case BusinessCategoryStatus.BusinessCategory_GET.NEW:
        //alert(this.props.inputValue)

        return (
          <Fragment>
            <MultiSelectDropdown
              type='text'
              name='Business Category'
              label='Business Category:'
              star="*"
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              errorMsg={this.props.errorMsg}
              businessCategoryNames={this.props.businessCategoryNames}
              data={
                this.props.BusinessCategory &&
                this.props.BusinessCategory.length > 0
                  ? this.props.BusinessCategory
                  : stateData2
              }
              // containerStyle={{ marginTop: "20px" }}
              onSelect={this.setBusinessCategory}
            />
            {/* <LabelDropDownInput
              type="text"
              name="Business Category"
              label="Business Category"
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              errorMsg={this.props.errorMsg}
              data={
                this.props.BusinessCategory && this.props.BusinessCategory.length > 0
                  ? this.props.BusinessCategory
                  : stateData2
              }
              // containerStyle={{ marginTop: "20px" }}
              onChange={this.setBusinessCategory}
            /> */}
          </Fragment>
        );
      case BusinessCategoryStatus.BusinessCategory_GET.SUCCESS:
        // alert(JSON.stringify(this.props.BusinessCategory))
        // alert(JSON.stringify(this.props.BusinessCategory))
        // console.log("Best time to call flag", this.props.stateFlag)
        return (
          <Fragment>
            <MultiSelectDropdown
              type='text'
              name='Business Category'
              label='Business Category:'
              star="*"
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              errorMsg={this.props.errorMsg}
              businessCategoryNames={this.props.businessCategoryNames}
              data={
                this.props.BusinessCategory &&
                this.props.BusinessCategory.length > 0
                  ? this.props.BusinessCategory
                  : stateData2
              }
              // containerStyle={{ marginTop: "20px" }}
              onSelect={this.setBusinessCategory}
            />
            {/* <LabelDropDownInput
              type="text"
              name="Business Category"
              label="Business Category"
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              errorMsg={this.props.errorMsg}
              data={
                this.props.BusinessCategory && this.props.BusinessCategory.length > 0
                  ? this.props.BusinessCategory
                  : stateData2
              }
              // containerStyle={{ marginTop: "20px" }}
              onChange={this.setBusinessCategory}
            /> */}
          </Fragment>
        );
        break;
      case BusinessCategoryStatus.BusinessCategory_GET.FAILED:
        // this.state.maxUnusedQuota = 0
        // this.props.setmaxUnusedQuota(this.state.maxUnusedQuota)
        // alert("failed")
        toast.error('Failed to get Business Category');
        return (
          <Fragment>
            <MultiSelectDropdown
              type='text'
              name='Business Category'
              label='Business Category:'
              star="*"
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              errorMsg={this.props.errorMsg}
              businessCategoryNames={this.props.businessCategoryNames}
              data={
                this.props.BusinessCategory &&
                this.props.BusinessCategory.length > 0
                  ? this.props.BusinessCategory
                  : stateData2
              }
              // containerStyle={{ marginTop: "20px" }}
              onSelect={this.setBusinessCategory}
            />
            {/* <LabelDropDownInput
              type="text"
              name="Business Category"
              label="Business Category"
              inputValue={this.props.inputValue}
              flag={this.props.flag}
              errorMsg={this.props.errorMsg}
              data={
                this.props.BusinessCategory && this.props.BusinessCategory.length > 0
                  ? this.props.BusinessCategory
                  : stateData2
              }
              // containerStyle={{ marginTop: "20px" }}
              onChange={this.setBusinessCategory}
            /> */}
          </Fragment>
        );

      case BusinessCategoryStatus.BusinessCategory_GET.LOADING:
        this.state.open = true;
        return (
          <Fragment>
            {/* <Loading /> */}
            <div className='bootle'>
              <MultiSelectDropdown
                type='text'
                name='Business Category'
                label='Business Category:'
                star="*"
                inputValue={this.props.inputValue}
                flag={this.props.flag}
                errorMsg={this.props.errorMsg}
                businessCategoryNames={this.props.businessCategoryNames}
                // data={
                //   this.props.BusinessCategory &&
                //   this.props.BusinessCategory.length > 0
                //     ? this.props.BusinessCategory
                //     : LoadingDummyData
                // }
                onSelect={this.setBusinessCategory}
                LoadingIcon={true}
                loaderStyle={{ left: '-35px', top: '12px' }}
              />
            </div>
          </Fragment>
        );

        break;
      default:
        break;
    }
  }
  render() {
    return (
      <Fragment>
        {this.getScreen(this.props.BusinessCategory_GET_status)}
      </Fragment>
    );
  }
}

export default BusinessCategoryView;
