import React, { Component } from 'react'
import "../Utils/SpinnerGrow.css"

class SpinnerGrow extends Component {
    render() {
        return (
            <div className="main-sipner-g">
                <div className="spinner-grow bg-color-1"></div>
                <div className="spinner-grow bg-color-2"></div>
                <div className="spinner-grow bg-color-3"></div>
            </div>
        )
    }
}

export default SpinnerGrow
