import React, { Component,Fragment, version } from "react";
import "./OverridePricing.css";
import LabelDropDownInput from "../Inputs/LabelDropDownInput";
import LabelInput from "../Inputs/LabelInput";
import SimpleInput from "../Inputs/SimpleInput";
import SingleInputRadio from "../Inputs/InputRadio/SingleInputRadio";
import DatePicker1 from "../Inputs/DatePicker";
import dateFormat from "dateformat";
import validator from 'validator'
import CouponsInventoryContainer from "./CouponsInventory/CouponsInventoryContainer";
import GetQuotasContractDetailsContainer from "./GetQuotasContractDetails/GetQuotasContractDetailsContainer";
import GetPreviousCouponsContainer from "./GetPreviousCoupons/GetPreviousCouponsContainer";
import GetCouponImagesContainer from "./GetCouponImages/GetCouponImagesContainer";
import GetCouponTermsContainer from "./GetCouponTerms/GetCouponTermsContainer";
import GetCouponTypesContainer from "./GetCouponTypes/GetCouponTypesContainer"
import { Server } from "./getMaxCouponCreateLimit";
import CheckBoxSingle from '../Inputs/CheckBoxSingle/CheckBoxSingle'
import { Modal } from "../Cards/PreviewNJTCouponCard";
import { withRouter } from "react-router-dom";
import LabelTextAreaInputWithoutML from "../Inputs/LabelTextAreaInputWithoutML";
import LabelTextAreaInput from "../Inputs/LabelTextAreaInput"
import moment from "moment";
import { coupon_transaction_subtype_id,ref_status, coupon_transaction_type_id } from 'RefRecordsConstants'
import { failure_messages} from 'Messages'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import MaterialTimePicker from "../Inputs/TimePicker/MaterialTimePicker";
import moment_tz from 'moment-timezone';
import Loading from "../Utils/Loading";
import { AcceptVideos} from "../../Config/Config";
import {Text_Field_Limits} from "../../ConfigConstants"


import VideoIcon from "../../../public/icons8-video-48.png";


const {offer_desc_limit, term_and_conditions_limit} = Text_Field_Limits
// alert(JSON.stringify(Text_Field_Limits))
// alert(offer_desc_limit)
// alert(term_and_conditions_limit)


let Videos= AcceptVideos.split(", ")

const monthData1 = [
  { name: "January", value: "01" },
  { name: "February", value: "02" },
  { name: "March", value: "03" },
  { name: "April", value: "04" },
  { name: "May", value: "05" },
  { name: "June", value: "06" },
  { name: "July", value: "07" },
  { name: "August", value: "08" },
  { name: "September", value: "09" },
  { name: "October", value: "10" },
  { name: "November", value: "11" },
  { name: "December", value: "12" },
];

export class ManageCouponInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offer_name: "",
      offerNameFlag: false,
      offerNameErrorMsg: "Field Required",
      xquantity:'',
      xquantityFlag:false,
      xquantityErrorMsg:'Field Required',
      yquantity:'',
      yquantityFlag:false,
      yquantityErrorMsg:'Field Required',
      buttonShow:false,
      year: "",
      yearFlag: false,
      yearErrorMsg: "Field Required",
      month: "",
      monthFlag: false,
      monthErrorMsg: "Field Required",
      faceValue: "",
      faceValueFlag: false,
      faceValueErrorMsg: "Field Required",
      couponValue: "",
      couponValueFlag: false,
      couponValueErrorMsg: "Field Required",
      Quantity: "",
      QuantityFlag: false,
      QuantityErrorMsg: "Field Required",
      startDate: "",
      startDateFlag: false,
      startDateErrorMsg: "Field Required",
      endDate: "",
      endDateFlag: false,
      endDateErrorMsg: "Field Required",
      showMonday: false,
      showTuesday: false,
      showWednesday: false,
      showThursday: false,
      showFriday: false,
      showSaturday: false,
      showSunday: false,
      minimunDate: new Date(),
      getYearQuotas:false,
      yearData: [],
      monthValues: [],
      faceValueData: [],
      QuotasContractDetails: [],
      ExchangeRates: [],
      initialDate: "",
      CouponTerms: "",
      coupon_transaction_type:'',
      maxUnusedQuota: '--',
      points: 0,
      showModal: false,
      isLoading: false,
      BusinessProfileData: this.props.cookies.get("BusinessProfileData"),
      maxCouponsLimit: "",
      coupon_transaction_type_id: '',
      coupon_transaction_subtype_id: 0,
      coupon_transaction_subtype_errorMsg: "Field Required",
      coupon_transaction_subtype_Flag: false,
      legalese: '',
      offerDescription: '',
      SubTypes: [],
      subTypeIndex: '',
      showSubTypes: false,
      showRedemptionUrl: false,
      showDiscountCodeSection: false,
      isCodeEnabled: '0',
      CodeEnabledFlag: false,
      CodeEnabledErrorMsg: "Field Required",
      RedemptionURL: "",
      queryString: "",
      RedemptionURLFlag: false,
      RedemptionURLErrorMsg: "Invalid URL",
      WalletURL: "",
      WalletURLFlag: false,
      WalletURLErrorMsg: "Invalid URL",
      RewardURL: "",
      RewardURLFlag: false,
      RewardURLErrorMsg: "Invalid URL",
      coupon_code: '',
      discountCodeFlag: false,
      discountCodeErrorMsg: "Field Required",
      showQueryString: false,
      showDiscountCode: false,
      updateDraftMode: false,
      coupon_batch_id: '',
      accountPayment:[],
      updateActiveMode:false,
      isfeaturedOffer:false,
      featuredOfferOrder:null,
      featuredOfferOrderFlag:false,
      featuredOfferErrorMsg: "Field Required",
      startTime:null,
      startTimeWithoutOffset:null,
      startTimeFlag:false,
      endTime:null,
      endTimeWithoutOffset:null,
      endTimeFlag:false,
      maxMediaLimit:5,
      CouponImage :[],
      CouponLogo :[],   
      couponImageFlag: false,
      couponImageErrorMsg: "Minimum 1 Image OR Video is required."
    };
    this.state.descriptionPlaceholder = this.props.cookies.get("BusinessProfileData").description
    if( this.state.descriptionPlaceholder &&  this.state.descriptionPlaceholder.trim() !="")
    this.state.descriptionPlaceholder = this.state.descriptionPlaceholder
    else
    this.state.descriptionPlaceholder="This text will appear on your offer card";
  
    this.getMaxCouponCreateLimit()
 
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.editCouponData !== prevProps.editCouponData) {
      // this.props.resetFields()
      // alert(  this.props.editCouponData.coupon_transaction_subtype_id)
      this.setEditData(this.props.editCouponData)
    }
  }

  setYearQuotas = (flag, flag2=false)=>{
    this.setState({
      getYearQuotas:flag,
      updateYearQuotas:flag2
    })
  }

  updateStatesAfterQuotas=async()=>{
    let editData = this.props.editCouponData
    await this.yearHandler({target:{value:editData.year}});
    this.startDateHandler(new Date(editData.promotion_start_date))
    //  this.startDateHandler(new Date(editData.promotion_start_date));
    //  this.endDateHandler(new Date(editData.promotion_end_date));
     this.endDateHandler(new Date(editData.promotion_end_date))

    this.state.startTime = new Date(editData.promotion_start_date)
    this.state.startTimeWithoutOffset = moment(new Date(editData.promotion_start_date)).format("h:mm A")

    this.state.endTime = new Date(editData.promotion_end_date)
    this.state.endTimeWithoutOffset = moment(new Date(editData.promotion_end_date)).format("h:mm A")

    this.state.coupon_transaction_type_id = editData.coupon_transaction_type_id
    this.state.coupon_transaction_subtype_id = editData.coupon_transaction_subtype_id
    this.state.coupon_transaction_type = { value:editData.coupon_transaction_subtype_id?editData.coupon_transaction_subtype_id:editData.coupon_transaction_type_id}
    // if(editData.coupon_transaction_subtype_id)
    // this.state.coupon_transaction_type.isSubType = true

    this.state.needEdit = true
    if(editData.wallet_page_offer_url)
    {
      this.state.showWalletURL=true
      this.state.WalletURL = editData.wallet_page_offer_url
    }else{
      this.state.showWalletURL=false
      this.state.WalletURL=""
    }
    if(editData.rewards_page_offer_url)
  {
    this.state.RewardURL = editData.rewards_page_offer_url
    this.state.showRewardUrl = true
  }else{
    this.state.RewardURL=""
    this.state.showRewardUrl =false
  }
if (editData.redemption_url && editData.redemption_url != "" && editData.redemption_url != "/") {
  // this.state.showRewardUrl = true
    let url = editData.redemption_url.split("?")
    this.state.RedemptionURL = url[0]
  
    this.state.showRedemptionUrl = true
    
    console.log("this is", url)

    if (url.length > 1) {
      this.state.queryString = "?" + url[1]
      this.state.showQueryString = true
      // this.setShowQueryString()
    }
    if (editData.show_code_in_redemption_url) {
      this.state.showDiscountCode = true
    }

}else{
  this.state.RedemptionURL=""
  this.state.showRedemptionUrl=false
  this.state.showQueryString=false
  this.state.showDiscountCode=false
}

if(editData.featured_offer_order){

  this.state.featuredOfferOrder= editData.featured_offer_order
  this.state.isfeaturedOffer = true

}else{
  this.state.featuredOfferOrder=null
  this.state.isfeaturedOffer =false
}
    this.state.faceValue = editData.coupon_face_value
    this.faceValueDataHandler({target:{value:editData.coupon_face_value}});
     if(!this.state.updateActiveMode)
     this.QuantityHandler({target:{value:editData.Inventory}});
     else
    {
      this.state.Quantity= editData.Inventory
      this.state.QuantityFlag=false;

    }
   if(editData.wallet_page_offer_url)
        {
          this.state.showWalletURL=true
          this.state.WalletURL = editData.wallet_page_offer_url
          // this.state.showRewardUrl = true
        }
        if(editData.rewards_page_offer_url)
      {
        this.state.RewardURL = editData.rewards_page_offer_url
        this.state.showRewardUrl = true
      }
    if (editData.redemption_url && editData.redemption_url != "" && editData.redemption_url != "/") {
      // this.state.showRewardUrl = true
        let url = editData.redemption_url.split("?")
        this.state.RedemptionURL = url[0]
      
        this.state.showRedemptionUrl = true
        

        if (url.length > 1) {
          this.state.queryString = "?" + url[1]
          this.state.showQueryString= true
          // this.setShowQueryString()
        }
        if (editData.show_code_in_redemption_url) {
          this.state.showDiscountCode = true
        }

    }

    if(editData.featured_offer_order){

      this.state.featuredOfferOrder= editData.featured_offer_order
      this.state.isfeaturedOffer = true

    }

    if(editData.coupon_code && editData.coupon_code != ""){
      this.setIsCodeEnabled('1')
      this.setDiscountCode({target:{value:editData.coupon_code}})
    }
    else if(editData.coupon_code_file_url && editData.coupon_code_file_url!="")
    {

      this.state.coupon_code_file_url = editData.coupon_code_file_url
      this.state.chooseAFile = editData.coupon_code_file_url.split("---")[1]
      this.setIsCodeEnabled("2")

    }else
    this.setIsCodeEnabled('0')
    this.state.CouponImage = []
    this.state.CouponLogo = []
    let re = /(?:\.([^.]+))?$/;
    // let spl =/(?<=\-\-\-).*/;
    // let fRegex = /(?:\-([^-]+))?$/
    if(editData.media_location)
    {
      editData.media_location.split(/\;\;\;/).forEach(item => {
        let filename = ''
        if(!item.includes('---'))
        filename =item.split(/\-/).pop()
        else
        filename =item.split(/\-\-\-/).pop()

        let ext="."+(re.exec(item)[1])
        this.state.CouponImage.push({value:JSON.stringify({image_location:item}), label:<div><img alt="not found" className={"avatar"} src={Videos.includes(ext)?VideoIcon:item} /> {filename} </div>})
        this.state.CouponLogo.push(JSON.stringify({image_location:item}))
        // map
      })
    }else if(editData.image_location && editData.image_location != "")
    {
      // this.state.CouponImage = editData.image_location
      let item =editData.image_location
      let filename = ''
        if(!item.includes('---'))
        filename =item.split(/\-/).pop()
        else
        filename =item.split(/\-\-\-/).pop()
        
      let ext="."+(re.exec(item)[1])
      this.state.CouponImage.push({value:JSON.stringify({image_location:item}), label:<div><img alt="not found" className={"avatar"} src={Videos.includes(ext)?VideoIcon:item} /> {filename} </div>})
      this.state.CouponLogo.push(JSON.stringify({image_location:item}))
    }
    // alert(JSON.stringify(editData.media_location))
    if (editData.coupon_terms && editData.coupon_terms != "")
      this.setRetailerCouponTerm(editData.coupon_terms)

    if (editData.legalese && editData.legalese != "")
      this.setLegalese({ target: { value: editData.legalese } })
   

  }

  setEditData = async (editData) =>{
      
      console.log("editsjhdhshjd", editData)
      if(ref_status.ACTIVE === editData.status_id)
      {this.state.updateActiveMode = true
        this.state.updateDraftMode = false;
      }
     else
      {
        this.state.updateActiveMode = false
        this.state.updateDraftMode = true;
      }
      
      this.state.coupon_batch_id = editData.coupon_batch_id;
      this.state.offer_name = editData.offer_name;
    
      this.state.offerDescription= editData.offer_description?editData.offer_description:""
      this.state.offerNameFlag= false
      this.state.offerNameErrorMsg= "Field Required"
    
      this.state.xquantityFlag =false
      this.state.xquantityErrorMsg ='Field Required'
     
      this.state.yquantityFlag=false
      this.state.yquantityErrorMsg ='Field Required'
      this.state.xquantity = editData.buy_x_quantity
      this.state.yquantity = editData.get_y_quantity
      this.state.year = editData.year
      if(parseInt(new Date().getFullYear()) > parseInt(editData.year))
      this.setYearQuotas(true)
      else
        this.updateStatesAfterQuotas()
      // this.setYearQuotas(true)

  }
  
  setShowQueryString = () => {

    this.setState({
      showQueryString: !this.state.showQueryString
    })
  }

  setShowDiscountCode = () => {

    this.setState({
      showDiscountCode: !this.state.showDiscountCode
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  offerNameHandler = (e) => {
    const reg = /^[a-zA-Z0-9_ ',%&.`$-]*$/;
    this.setState({ offer_name: e.target.value, CouponTerms:e.target.value, offerNameFlag: false, offerNameErrorMsg:"" });
  };

    // todo: XQuantity Handler
    xQuantityHandler = (e) => {
      const reg = /^[0-9]*$/;
      const validReg = reg.test(e.target.value);
      if (validReg) {
        this.state.xquantityFlag = false
  
      } else {
        this.state.xquantityFlag = true
  
        this.state.xquantityErrorMsg = "Use only Numbers"
  
      }
      this.setState({ xquantity: e.target.value });
    };
  
      // todo: YQuantity Handler
      yQuantityHandler = (e) => {
        const reg = /^[0-9]*$/;
        const validReg = reg.test(e.target.value);
        if (validReg) {
          this.state.yquantityFlag = false
    
        } else {
          this.state.yquantityFlag = true
    
          this.state.yquantityErrorMsg = "Use only Numbers"
    
        }
        this.setState({ yquantity: e.target.value });
      };
  

      WalletURLHandler = (e) => {
        if (validator.isURL(e.target.value)) {
          this.state.WalletURLErrorMsg = ""
          this.state.WalletURLFlag = false
    
    
        }
        else {
          if (e.target.value === "") {
            this.state.WalletURLErrorMsg = ""
            this.state.WalletURLFlag = false
          }
          else {
            this.state.WalletURLErrorMsg = "Please Enter Correct URL"
            this.state.WalletURLFlag = true
          }
        }
        this.setState({ WalletURL: e.target.value });
      }
      setShowWalletURL = () => {  
        this.setState({
          showWalletURL: !(this.state.showWalletURL)
        }, () => {
          if (!this.state.showWalletURL)
            this.setState({
              WalletURL: '',
              WalletURLFlag:false
            })
          else{
    let company_url =this.props.cookies.get("BusinessProfileData").company_url
            this.setState({
              WalletURL: company_url?company_url:'https://',
              WalletURLFlag: company_url?false:true,
              WalletURLErrorMsg: "Enter a Valid URL",
            })
            
          }
    })
      }



  RedemptionURLHandler = (e) => {
    if (validator.isURL(e.target.value)) {
      this.state.RedemptionURLErrorMsg = ""
      this.state.RedemptionURLFlag = false


    }
    else {
      if (e.target.value === "") {
        this.state.RedemptionURLErrorMsg = ""
        this.state.RedemptionURLFlag = false
      }
      else {
        this.state.RedemptionURLErrorMsg = "Please Enter Correct URL"
        this.state.RedemptionURLFlag = true
      }
    }
    this.setState({ RedemptionURL: e.target.value });
  }
  setShowURL = () => {

    this.setState({
      showRedemptionUrl: !(this.state.showRedemptionUrl)
    }, () => {
      if (!this.state.showRedemptionUrl)
        this.setState({
          RedemptionURL: '',
          showQueryString: false,
          queryString: "",
          showDiscountCode: false
        })
      else{
        let company_url =this.props.cookies.get("BusinessProfileData").company_url
        this.setState({
          RedemptionURL: company_url?company_url:'https://',
          RedemptionURLFlag: company_url?false:true,
          RedemptionURLErrorMsg: "Enter a Valid URL",
        })
        
      }
})
  }

  RewardURLHandler = (e) => {
    if (validator.isURL(e.target.value)) {
      this.state.RewardURLErrorMsg = ""
      this.state.RewardURLFlag = false


    }
    else {
      if (e.target.value === "") {
        this.state.RewardURLErrorMsg = ""
        this.state.RewardURLFlag = false
      }
      else {
        this.state.RewardURLErrorMsg = "Please Enter Correct URL"
        this.state.RewardURLFlag = true
      }
    }
    this.setState({ RewardURL: e.target.value });
  }
  setRewardURL= () => {

    this.setState({
      showRewardUrl: !(this.state.showRewardUrl)
    }, () => {
      if (!this.state.showRewardUrl)
        this.setState({
          RewardURL: '',
          RewardURLFlag:false
        })
      else{
        let company_url =this.props.cookies.get("BusinessProfileData").company_url
        this.setState({
          RewardURL: company_url?company_url:'https://',
          RewardURLFlag:company_url?false: true,
          RewardURLErrorMsg: "Enter a Valid URL",
        })
        
      }
})
  }

  setLegalese = (event) => {
    this.setState({
      legalese: event.target.value
    })
    // alert(event.target.value)
  }

  setOfferDescription = (event) => {
    this.setState({
      offerDescription: event.target.value
    })
  }
  

  setCouponThresholdLimit = (response) => {
    let threshold = 0;
    let maxCouponsLimit = 0
    let Display_Coupon_Tag = false
    let maxMediaLimit = this.state.maxMediaLimit
    if(response)
    {
      threshold = response.threshold
      maxCouponsLimit =  parseInt(response.data.global_value)
      Display_Coupon_Tag = JSON.parse(response.Display_Coupon_Tag)
      maxMediaLimit=parseInt(response.MediaUploadLimit)
    }
// alert(maxMediaLimit)
    this.setState({  // response.threshold, parseInt(response.data.global_value), JSON.parse(response.Display_Coupon_Tag)
      threshold: threshold,
      maxCouponsLimit: maxCouponsLimit,
      Display_Coupon_Tag: Display_Coupon_Tag,
      maxMediaLimit:maxMediaLimit
    })
  }

  getMaxCouponCreateLimit = () => {
    const data = {
      setCouponThresholdLimit: this.setCouponThresholdLimit
    }

    Server.getMaxCouponCreateLimit(data)
  }



  setRetailerCouponImage = (image, e) => {
  let flag =true
  // alert(JSON.stringify(image))
    console.log("image set", image)
    



  if(image.length)
  // alert(JSON.stringify(image))
    if(Array.isArray(image) && image.length>this.state.maxMediaLimit)
    flag = false
    
    if(flag)
    this.setState({
      CouponLogo: image,
      CouponImage: e,
      couponImageFlag: false
    });
  };

  setRetailerCouponTerm = (terms) => {
    this.setState({
      CouponTerms: terms
    })
  }

  setSubTypeData = (subTypes) => {
    // if (this.state.SubTypes.length === 0)
    //   this.setState({
    //     SubTypes: subTypes
    //   })
  }

  yearHandler = async (e) => {
    // alert(e.target.value)
    var year = e.target.value;
    var NewMonthData = [];
    var MaxMonthlyQuotaLimit = '';
    var monthlyQuota = 0;
    if (year != "") {
      let EndDate = dateFormat(
        new Date(this.state.QuotasContractDetails[0].term_end_date),
        "mm"
      );
      var startDate = dateFormat(this.state.term_start_date, "mm");

      // this.state.selectedMonth = startDate
      if (this.state.startYear == this.state.endYear) {
        if (new Date(this.state.QuotasContractDetails[0].term_start_date) > new Date()) {

          this.state.minimunDate = new Date(this.state.QuotasContractDetails[0].term_start_date)
          this.state.maximumStartDate = new Date(this.state.QuotasContractDetails[0].term_end_date)
        } else {
          this.state.minimunDate = new Date()
          this.state.maximumStartDate = new Date(this.state.QuotasContractDetails[0].term_end_date)
        }

        while (startDate <= EndDate) {
          NewMonthData.push(monthData1[startDate - 1]);
          startDate++;
        }
      } else if (this.state.endYear == year) {

        this.state.minimunDate = new Date(year + "-01-01")
        this.state.maximumStartDate = new Date(this.state.QuotasContractDetails[0].term_end_date)
        for (var i = 0; EndDate > 0 && i < 13; i++) {
          NewMonthData.push(monthData1[i]);
          EndDate--;
        }
      } else if (
        this.state.startYear == year &&
        this.state.startYear == new Date().getFullYear()
      ) {
        if (new Date(this.state.QuotasContractDetails[0].term_start_date) > new Date()) {
          this.state.minimunDate = new Date(this.state.QuotasContractDetails[0].term_start_date)
          this.state.maximumStartDate = new Date(year + "-12-31")
        } else {
          this.state.minimunDate = new Date()
          this.state.maximumStartDate = new Date(year + "-12-31")
        }

        do {
          NewMonthData.push(monthData1[startDate - 1]);
          startDate++;
        } while (startDate < 13);
      } else {
        this.state.minimunDate = new Date(year + "-01-01")
        this.state.maximumStartDate = new Date(year + "-12-31")
        NewMonthData = Array.from(monthData1);
      }
      // alert(JSON.stringify(year))
      var monthlyQuotas = this.state.QuotasContractDetails.filter((item) => {
        // alert(item.year)
        return item.year == year;
      });
      // alert(parseInt(year) < parseInt(new Date().getFullYear()))
     if(parseInt(year) < parseInt(new Date().getFullYear()))
     { if(this.state.updateDraftMode)
        this.state.yearFlag = true
     }
      else
      {
        // alert("else")
        this.state.yearFlag = false
      }

      var faceValueData = [];
      // alert(JSON.stringify(monthlyQuotas))
      for (var i = 0; i < monthlyQuotas.length; i++) {
        faceValueData.push({
          name: monthlyQuotas[i].face_value,
          value: monthlyQuotas[i].face_value,
        });
      }
      // Sort Face Values in Ascending Order
      faceValueData = faceValueData.sort((a, b) => a.value - b.value)
      // alert(JSON.stringify(faceValueData))
      console.log("monthlyQuotas", monthlyQuotas)
// alert(JSON.stringify(monthlyQuotas))
  //    if (monthlyQuotas.length > 0)
      MaxMonthlyQuotaLimit = monthlyQuotas[0].coupon_lifetime_in_months;
      this.setMaxMonthlyQuotaLimit(MaxMonthlyQuotaLimit);
      this.state.year_id = monthlyQuotas[0].year_id;
    
      NewMonthData.unshift({ value: "", name: "Select Month" });
      faceValueData.unshift({ value: "", name: "Select Offer Value" });


      this.setState({
        MaxMonthlyQuota: monthlyQuota,
        MaxMonthlyQuotaLimit: MaxMonthlyQuotaLimit,
        year: year,
        month: "",
        // monthValues: NewMonthData,
        // faceValue: "",
        MonthlyQuota: 0,
        endDate: "",
        startDate: "",
        Quantity: "",
        faceValueData: faceValueData
      });
    } else
      this.setState({
        monthValues: NewMonthData,
        MaxMonthlyQuotaLimit: 0,
        faceValueData: [],
        year: year,
        month: "",
        // faceValue: "",
        MonthlyQuota: 0,
        MaxMonthlyQuota: 0,
        endDate: "",
        startDate: "",
        Quantity: "",
      });

  };
  setEditType=()=>{
    this.setState({needEdit:false})
  }
  monthHandler = (e) => {
    var month = e.target.value;

    
    this.state.selectedMonth = month;
    this.startDateInit(this.state.year + "-" + month + "-" + "01");

    this.setState({
      month,
      monthFlag: false,
      faceValue: "",
      MonthlyQuota: 0,
      MaxMonthlyQuota: 0,
      endDate: "",
      // startDate:'',
      Quantity: "",
    });

  };

  discountHandler = (e) => {
    if (this.state.faceValue !== "" || this.state.faceValue !== "selectType") {
      this.setState({ faceValueFlag: false });
    }

    this.setState({ faceValue: e.target.value });
  };

  faceValueDataHandler = async (e) => {
    var faceValue = e.target.value;

    // if (this.state.year == "") this.setState({ yearFlag: true });
    //else if (this.state.month == "") this.setState({ monthFlag: true });
    // else {
      //      this.props.faceValueDataHandler(faceValue);
     
      var monthlyQuotas = this.state.QuotasContractDetails.filter((item) => {
        return item.year == this.state.year && item.face_value == faceValue;
      });
      // if(monthlyQuotas.length)
    

        if (monthlyQuotas.length > 0) 
        {
            this.state.yearId = monthlyQuotas[0].year_id;
            this.state.MonthlyQuota = monthlyQuotas[0].coupon_max_quota
            this.state.MaxMonthlyQuota = monthlyQuotas[0].coupon_max_quota
        }else{
          this.state.MonthlyQuota = this.state.QuotasContractDetails[0].coupon_max_quota
          this.state.MaxMonthlyQuota = this.state.QuotasContractDetails[0].coupon_max_quota
        }
         this.setState({
          faceValue: faceValue,
          faceValueFlag: false,
          chooseAFile:"",
          Quantity: "",
        });
    //   }
    
  };

  couponValueDataHandler = (e) => {
    var couponValue = e.target.value;
    this.setState({
      couponValue: couponValue,
      couponValueFlag: false,
    });
  };

  amountValueHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ amountValueFlag: false });
    } else {
      this.setState({
        amountValueFlag: true,
        amountValueErrorMsg: "Only numeric values are allowed",
      });
    }
    this.setState({ amountValue: e.target.value });
  };
  costPointsHandler = (e) => {
    const reg = /^\d*\.?\d*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ costPointsFlag: false });
    } else {
      this.setState({
        costPointsFlag: true,
        costPointsErrorMsg: "Only numeric values are allowed",
      });
    }
    this.setState({ costPoints: e.target.value });
  };

  QuantityHandler = async (e) => {
    const Quantity = e.target.value;
    const reg = /^\d*$/;
    const validReg = reg.test(Quantity);
    // alert(Quantity)
    if (validReg) {
  
      if (Quantity > this.state.maxUnusedQuota) {
        // alert(Quantity)
        this.setState({
          // quantity,
          QuantityFlag: true,
          QuantityErrorMsg: "Offer quantity has exceeded unused quota",
          // quantityRequired: true,
        });
        
      } else if (Quantity < 1 && Quantity != "")
        this.setState({
          QuantityFlag: true,
          QuantityErrorMsg: "offer quantity can not be less than 1",
        });
      else if (this.state.maxCouponsLimit != '' && Quantity > this.state.maxCouponsLimit) {
        this.setState({
          QuantityFlag: true,
          QuantityErrorMsg: "maximum of " + this.state.maxCouponsLimit + ' offers can be generated at a time.',
        });
      }
      else this.setState({ QuantityFlag: false });
    } else {
      this.setState({
        QuantityFlag: true,
        QuantityErrorMsg: "Only numeric values are allowed",
      });
    }

    this.setState({
      Quantity: e.target.value,
      // endDate:'',
      // startDate:''
    });
  };
  // todo: dateRangeRadioHandler onChange Function ...................
  dateRangeRadioHandler = () => {
    alert("dateRangeRadioHandler work");
  };
  // todo: specificDayRadioHandler onChange Function ...................
  specificDayRadioHandler = () => {
    alert("specificDayRadioHandler work");
  };


  startDateHandler = (e) => {
    var maximumDate = new Date(e);

    maximumDate.setMonth(
      maximumDate.getMonth() + this.state.MaxMonthlyQuotaLimit
    );
    if (new Date(this.state.QuotasContractDetails[0].term_end_date) < maximumDate)
      maximumDate = new Date(this.state.QuotasContractDetails[0].term_end_date)

    


    this.setState({
      // initialDate: new Date(e),
      startDateFlag: false,
      startDate: e,
      endDate: "",
      maximumDate: new Date(maximumDate),
      month: (new Date(e).getMonth() + 1),
      monthFlag: false,
      faceValue: "",
      MonthlyQuota: 0,
      MaxMonthlyQuota: 0,
      Quantity: "",
      // coupon_transaction_type:'',
      startTime:undefined,
      startTimeWithoutOffset:null,
      endTime:undefined,
      endTimeWithoutOffset:null
    });
    // this.setState({ startDate: e, startDateFlag: false, endDate: "" });
  };

  // todo: End Date onChange Function ...................

  endDateHandler = (e) => {

    // let startTime = this.state.startTime
    // let endTime = this.state.endTime
    // let startTimeWithoutOffset = this.state.startTimeWithoutOffset

    // const promotion_start_date = new Date(this.state.startDate).getTime()
    // const promotion_end_date = new Date(e).getTime()

    // console.log("promotion_start_date", promotion_start_date)
    // console.log("promotion_start_date", promotion_end_date)
    // console.log("promotion_start_date", startTime)
    // console.log("promotion_start_date", endTime)


    // const isPromStartEndDateEqual = promotion_start_date === promotion_end_date
    // const isPromStartLessThanEndTime = moment(new Date(endTime)).format("h:mm A").toString() == moment(new Date(startTime)).format("h:mm A").toString()

    // if (isPromStartEndDateEqual && (isDate(new Date(startTime)) && isDate(new Date(endTime))) && isPromStartLessThanEndTime) {
    //   startTime = null
    //   startTimeWithoutOffset = null
    // }




    this.setState({ 
      endDate: e, 
      endDateFlag: false ,
      endTime:undefined,
      endTimeWithoutOffset:null
      // startTime: startTime,
      // startTimeWithoutOffset: startTimeWithoutOffset
    });
  };

  // todo: setStartTime onChange Function ...................

  setStartTime = (e)=>{
    if (e != "Invalid Date") {
      var endTime = this.state.endTime
      let startTimeWithoutOffset = e ? moment(new Date(e)).format("h:mm A"): e
      let startDate = "2020-01-01 "
      let endDate = "2020-01-01 "
      if (this.state.startDate != "")
        startDate = moment(this.state.startDate).format("YYYY-MM-DD") + " "
      if (this.state.endDate != "")
        endDate = moment(this.state.endDate).format("YYYY-MM-DD") + " "

      if (endTime)
        if (new Date(startDate + moment(e).format('HH:mm')) >= new Date(endDate + moment(endTime).format('HH:mm')))
         { 
          endTime = undefined
          this.state.endTimeWithoutOffset=null
         }

      this.setState({
        startTime: null
      },()=>{
        this.setState({
          startTime: e,
          startTimeFlag: false,
          endTime: endTime,
          startTimeWithoutOffset:startTimeWithoutOffset
        })
      })
    } else
      this.setState({
        startTimeFlag: true
      })
  //   console.log("time: ", time)
  //   console.log("time", new Date())
  //   console.log("timeses", moment(new Date(time)).format("h:mm A"))

  //   var endTime = this.state.endTime
  //   let startTimeWithoutOffset = moment(new Date(time)).format("h:mm A")
    
  //   // startTimeWithoutOffset

  //   // alert(time)
  //   // if(isDate(new Date(time)) && endTime && new Date(time) > new Date(endTime) && time)
  //   // {
  //   //   endTime = null
  //   //   startTimeWithoutOffset = null
  //   // }

  // //  alert(new Date(this.state.startDate).setHours(0,0,0,0))
  //   const promotion_start_date = !isNaN(new Date(this.state.startDate)) ? new Date(this.state.startDate).setHours(0,0,0,0):new Date(this.state.startDate)
  //   console.log("promotion_start_date", promotion_start_date)

  //   // alert(isNaN(new Date(this.state.endDate).getTime()))
  //   const promotion_end_date = !isNaN(new Date(this.state.endDate)) ? new Date(this.state.endDate).setHours(0,0,0,0):new Date(this.state.endDate)

  //   const isPromStartEndDateEqual = promotion_start_date === promotion_end_date
  //   const isPromStartLessThanEndDate = moment(new Date(endTime)).format("h:mm A").toString() <= moment(new Date(time)).format("h:mm A").toString()

  //   console.log("promotion_start_date", promotion_start_date)
  //   console.log("promotion_start_date", promotion_end_date)

  //   console.log("isPromStartEndDateEqual", isPromStartEndDateEqual)    
  //   console.log("end time", moment(new Date(endTime)).format("h:mm A").toString())
  //   console.log("end time", moment(new Date(time)).format("h:mm A").toString())

  //   console.log("isPromStartLessThanEndDate", isPromStartLessThanEndDate)

  //   if (isPromStartEndDateEqual && (isDate(new Date(time)) && isDate(new Date(endTime))) && isPromStartLessThanEndDate) {
  //     endTime = null
  //     startTimeWithoutOffset = null
  //   }

  //   console.log("invalid", time == "Invalid Date")

  //   this.setState({
  //     endTime: endTime,
  //     startTime: time,
  //     startTimeWithoutOffset: startTimeWithoutOffset,
  //     startTimeFlag: time == "Invalid Date"
  //   })

  }


  startTimeErrorHandler = (error, value) =>{
    console.log("this is error start", error)
    console.log("this is error start", value)

    // if(error === "Invalid Time Format"){
    //   console.log("hello")
    //   this.setState({
    //     startTimeFlag: true
    //   })

    // }
    

  }

  endTimeErrorHandler = (error, value) =>{
    
        console.log("this is error", error)
        console.log("this is error", value)

    if(error !== "" && value)
      this.setState({
        endTimeFlag: true
      })


  }

  setEndTime = (e)=>{

    if (e != "Invalid Date") {
      var startTime = this.state.startTime
      let endTimeWithoutOffset = e ? moment(new Date(e)).format("h:mm A"): e
      let startDate = "2020-01-01 "
      let endDate = "2020-01-01 "
      if (this.state.startDate != "")
        startDate = moment(this.state.startDate).format("YYYY-MM-DD") + " "
      if (this.state.endDate != "")
        endDate = moment(this.state.endDate).format("YYYY-MM-DD") + " "

      if (startTime)
        if (new Date(startDate + moment(startTime).format('HH:mm')) >= new Date(endDate + moment(e).format('HH:mm')))
         { 
           startTime = undefined
           this.state.startTimeWithoutOffset = null
         }
         this.setState({
          endTime:undefined
         },()=>
      this.setState({
        startTime: startTime,
        endTimeFlag: false,
        endTime: e,
        endTimeWithoutOffset:endTimeWithoutOffset
      }))
    } else
      this.setState({
        endTimeFlag: true
      })
    // let startTime = this.state.startTime ? new Date(this.state.startTime): this.state.startTime
    // let endTimeWithoutOffset = time ? moment(new Date(time)).format("h:mm A"): time

    // console.log("timeses mamma", moment(new Date(time)).format("h:mm A"))

    // // if(isDate(new Date(time)) && startTime && new Date(time) < new Date(startTime) && time)
    // // {
    // //   startTime = null
    // //   endTimeWithoutOffset = null
    // // }

    // const promotion_start_date = !isNaN(new Date(this.state.startDate)) ? new Date(this.state.startDate).setHours(0,0,0,0):new Date(this.state.startTime)
    // console.log("promotion_start_date", new Date(startTime))

    // // alert(isNaN(new Date(this.state.endDate).getTime()))
    // const promotion_end_date = !isNaN(new Date(this.state.endDate)) ? new Date(this.state.endDate).setHours(0,0,0,0):new Date(this.state.endDate)

    // console.log("end date", promotion_start_date)
    // console.log("end date", promotion_end_date)

    // console.log("end date", isDate(new Date(time)))
    // console.log("end date", isDate(new Date(startTime)))

    // console.log("end date", moment(new Date(time)).format("h:mm A").toString())
    // console.log("end date", moment(new Date(startTime)).format("h:mm A").toString())

    // const isPromStartEndDateEqual = promotion_start_date === promotion_end_date
    // const isPromStartGreaterThanEndDate = moment(new Date(time)).format("h:mm A").toString() <= moment(startTime).format("h:mm A").toString()


    // if(isPromStartEndDateEqual && (isDate(new Date(time)) && isDate(startTime)) && isPromStartGreaterThanEndDate){
    //   // alert("problem")
    //   startTime = null
    //   endTimeWithoutOffset = null
    //   // alert("wrong time")
    // }


    // this.setState({
    //   endTime:time,
    //   endTimeWithoutOffset: endTimeWithoutOffset,
    //   startTime:startTime,
    //   endTimeFlag: time == "Invalid Date",
    // })
  }

  // todo: prevCouponHandler onChange Function ...................
  prevCouponHandler = () => {
    if (this.ValidateFields()) {
      this.handleShowCouponPreview();
    }
  };
  // todo: genCouponHandler onChange Function ...................
  nextHandler = () => {
    this.props.history.replace({
      pathname: "/business_list",
    });
  };


  setYearsQuota = (QuotaYears, QuotasContractDetails, accountPayment=[]) => {
    // alert("genCouponHandler works")
    // alert(JSON.stringify(QuotaYears))
    if (QuotasContractDetails.length > 0) {
      //   alert(this.props.QuotasContractDetails[0].term_start_date)
      this.state.QuotasContractDetails = QuotasContractDetails;
      var date = new Date(QuotasContractDetails[0].term_start_date);
      if (date > this.state.term_start_date) this.state.term_start_date = date;
      if (QuotaYears.length > 0) this.state.startYear = QuotaYears[0].name;
      // alert(JSON.stringify(QuotaYears))
      // this.state.yearData = QuotaYears;
      QuotaYears.unshift({ value: "", name: "Select Year" });
   
      this.state.endYear = new Date(
        QuotasContractDetails[0].term_end_date
      ).getFullYear();
      this.setState({
        yearData: QuotaYears, 
        accountPayment:accountPayment
      });
    }
  };

  setUpdateYearQuota=(QuotaYears, QuotasContractDetails)=>{
    // alert(QuotasContractDetails.length)
    if (QuotasContractDetails.length > 0) {

      if(!this.state.updateActiveMode)
      {
        let d= new Date()
        QuotaYears= QuotaYears.filter(item=>{
          // alert(parseInt(d.getFullYear()) +"  "+parseInt(item.value))
          return parseInt(item.value) >= parseInt(d.getFullYear())
        })
        QuotaYears.unshift({ value: "Select Year", name: "Select Year" });
  
        this.state.yearData = QuotaYears
        
      }
      var date = new Date(QuotasContractDetails[0].term_start_date);
      if (date > this.state.term_start_date) 
      this.state.term_start_date = date;
      if (QuotaYears.length > 0) this.state.startYear = QuotaYears[0].name;
      this.state.endYear = new Date(
        QuotasContractDetails[0].term_end_date
      ).getFullYear();
        this.setState({
          QuotasContractDetails:QuotasContractDetails
        },()=>{
          this.updateStatesAfterQuotas()
        })
    }
  }

  startDateInit = (startDate) => {
    //-- *************************************************** Sets the start date when user selects Contract year and month from GetQuotasContractDetailsContainer

    var date = new Date(); // **** current date
    if (new Date(startDate) > date) date = new Date(startDate);
    var maxDate = new Date(date);
    var maximumStartDate = new Date(date);
    maximumStartDate.setMonth(maximumStartDate.getMonth() + 1);

    maxDate.setMonth(maxDate.getMonth() + this.state.MaxMonthlyQuotaLimit);
    if (new Date(this.state.QuotasContractDetails[0].term_end_date) < maxDate)
      maxDate = this.state.QuotasContractDetails[0].term_end_date

    maximumStartDate.setDate('01')
    maximumStartDate.setDate(maximumStartDate.getDate() - 1);

    this.setState({
      startDate: date,
      maximumDate: new Date(maxDate),
      maximumStartDate: maximumStartDate,
      endDate: "",
      minimunDate: new Date(date),
    });
  };
  setmaxUnusedQuota = (maxUnusedQuota, ExchangeRates) => {
    //-- *************************************************** Sets the max unused quota which is calculated from GetQuotasContractDetailsContainer
    var points = 0
    if (ExchangeRates.length > 0) {
      points = ExchangeRates[0].points
      this.state.ExchangeRates.forEach(element => {
        if (new Date(this.state.initialDate) >= new Date(element.from_date) && new Date(this.state.initialDate) <= new Date(element.to_date)) {
          points = element.points
        }
      });
    }

    this.setState({
      maxUnusedQuota: maxUnusedQuota,
      ExchangeRates: ExchangeRates,
      points: points
    })
  };
  setMaxMonthlyQuotaLimit = (maxMonthlyLimit) => {
    //-- *************************************************** this function assings the

    this.state.MaxMonthlyQuotaLimit = maxMonthlyLimit;
  };
  backHandler = () => {
    this.props.history.replace({
      pathname: "/busines_coupon_quotas",
    });
  };

  scrollToOffer = () => {
    this.offerName.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
  scrollToCouponYear = () => {
    this.CouponYear.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToCouponQuantity = () => {
    this.CouponQuantity.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToXYQuantity = () => {
    this.xquantity.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  // todo: applyHandler onClick Function ...................

  ValidateFields = () => {


    // this.state.isfeaturedOffer || this.state.featuredOfferOrder
    //
    console.log("feature", this.state.startTime)
    console.log("feature", this.state.endTime)

    console.log("couponImages", this.state.CouponLogo)

    // return;
    

    var flag = false
    if (this.state.offer_name == "" || this.state.offerNameFlag) {
      this.setState({
        offerNameErrorMsg: (this.state.offerNameFlag && this.state.offer_name.length > 0 ? this.state.offerNameErrorMsg : "Field Required"),
        offerNameFlag: true,
      });
      this.scrollToOffer()
    }

    else if (this.state.year === "selectType" || this.state.year === "" || this.state.yearFlag) {
      this.setState({
        yearErrorMsg: "Field Required",
        yearFlag: true,
      });
      this.scrollToCouponYear()
    } 
    else if (this.state.startDate === "") {
      this.setState({
        startDateErrorMsg: "Field Required",
        startDateFlag: true,
      });
      this.scrollToCouponYear()
    } 
    else if ((!this.state.startTime || this.state.startTime == "Invalid Date") && this.state.startTimeFlag) {

      // alert("hello")

      if (this.state.startTime != "Invalid Date")
        this.setState({
          startTimeFlag: true,
          endTimeFlag: false
        })
        
    } else if (this.state.endDate === "") {
      this.setState({
        endDaterMsg: "Field Required",
        endDateFlag: true,
      });
      this.scrollToCouponYear()
    }
    else if ((!this.state.endTime || this.state.endTime == "Invalid Date") && this.state.endTimeFlag) {

      if (this.state.endTime != "Invalid Date")
        this.setState({
          endTimeFlag: true,
          startTimeFlag: false
        })
    }else if (this.state.month === "selectType" || this.state.month === "") {
      this.setState({
        monthErrorMsg: "Field Required",
        monthFlag: true,
      });
      this.scrollToCouponYear()
    }
    else if (
      this.state.coupon_transaction_type_id === "0" ||
      this.state.coupon_transaction_type_id === ""
    ) {
      //alert("in error")
      this.setState({
        couponValueErrorMsg: "Field Required",
        couponValueFlag: true,
      });
      this.scrollToCouponQuantity()
    }
    else if (
      this.state.coupon_transaction_type_id == coupon_transaction_type_id.discount &&
      (this.state.coupon_transaction_subtype_id === 0 ||
        this.state.coupon_transaction_subtype_id === "")
    ) {
      this.setState({
        coupon_transaction_subtype_Flag: true,
      });
      this.scrollToCouponQuantity()
    }

    else if  ( this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y && (this.state.xquantityFlag || this.state.xquantity === "")) {
      this.setState({
        xquantityErrorMsg: "Field Required",
        xquantityFlag: true,
      });
      this.scrollToXYQuantity()  
      }
    else if  (this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y && (this.state.yquantityFlag || this.state.yquantity === "")) {
      this.setState({
        yquantityErrorMsg: "Field Required",
        yquantityFlag: true,
      });
      // this.scrollToEndDate()
      this.scrollToXYQuantity()
        }


    else if (
      this.state.faceValue === "selectType" ||
      this.state.faceValue === ""
    ) {
      this.setState({
        faceValueErrorMsg: "Field Required",
        faceValueFlag: true,
      });
      this.scrollToCouponYear()
    }
    else if (
      // this.state.coupon_transaction_type_id == coupon_transaction_type_id.discount &&
      this.state.isCodeEnabled == "1" &&
      (this.state.discountCodeFlag || this.state.coupon_code.trim() === "")
    ) {
      this.setState({
        discountCodeErrorMsg: this.state.coupon_code.trim() === "" ? "Field Required" : this.state.discountCodeErrorMsg,
        discountCodeFlag: true,
      });
      this.scrollToCouponQuantity()
    }
    else if (
      this.state.isCodeEnabled == "2" &&
      (this.state.chooseAFile == "")
    ) {
      this.setState({
        file_error:this.state.file_error == "" ? "Field Required" : this.state.file_error
      });
      
      // this.scrollToCouponQuantity()
    }
    else if (this.state.QuantityFlag || this.state.Quantity === "") {
      this.setState({
        QuantityErrorMsg:
          this.state.QuantityFlag && this.state.Quantity.length > 0
            ? this.state.QuantityErrorMsg
            : "Field Required",
        QuantityFlag: true,
      });
      this.scrollToCouponQuantity()
    }
    else if (this.state.CouponLogo && this.state.CouponLogo.length < 1) {
      // alert("no coupon images selected")
      this.setState({
        couponImageFlag: true,
      });
      this.scrollToOffer()
    }

    // else if (this.state.startTimeFlag){
    //   this.scrollToCouponYear()
    // }
    // else if (this.state.endTimeFlag){
    //   this.scrollToCouponYear()
    // }
    else if (this.state.isfeaturedOffer && (this.state.featuredOfferOrderFlag || this.state.featuredOfferOrder === "" || !this.state.featuredOfferOrder)) {
      // alert("hello")
      this.setState({
        featuredOfferErrorMsg:
          this.state.featuredOfferOrderFlag 
            ? this.state.featuredOfferErrorMsg
            : "Field Required",
        featuredOfferOrderFlag: true,
      });

    }
    else if (
      this.state.showRewardUrl &&
       (this.state.RewardURLFlag ||  this.state.RewardURL.trim() === "")
    ) {

      this.setState({
        RewardURLErrorMsg: this.state.RewardURL.trim() === "" ? "Field Required" : this.state.RewardURLErrorMsg,
        RewardURLFlag: true,
      });

    }
    else if (
      this.state.showWalletURL && 
      (this.state.WalletURLFlag || this.state.WalletURL.trim() === "")
    ) {
      this.setState({
        WalletURLErrorMsg: this.state.WalletURL.trim() === "" ? "Field Required" : this.state.WalletURLErrorMsg,
        WalletURLFlag: true,
      });
    }
    else if (
      this.state.showRedemptionUrl &&
      (this.state.RedemptionURLFlag || this.state.RedemptionURL.trim() === "")
    ) {
      this.setState({
        RedemptionURLErrorMsg: this.state.RedemptionURL.trim() === "" ? "Field Required" : this.state.RedemptionURLErrorMsg,
        RedemptionURLFlag: true,
      });

    }

    else {
      flag = true
    }
    return flag
  }

  setCouponType = (type2) => {
    if(type2){
   let  type = JSON.parse(type2)
    console.log("typetypetype: ",type.value)
  
    if(!type.coupon_transaction_subtype_id && type.value == coupon_transaction_type_id.prepaid && this.state.accountPayment.length==0)
    toast.info("Can’t create the Prepaid Offer because this business have not connected the Stripe Account.")
    else if(type.value == 0)
    console.log(type)
    else
    this.setState({
      coupon_transaction_type_id:type.coupon_transaction_subtype_id?coupon_transaction_type_id.discount:type.value,
      coupon_transaction_type:type2,
      couponValueFlag: false,
      showRedemptionUrl: false,
      RedemptionURL: "",
      showQueryString: false,
      queryString: "",
      RedemptionURLFlag: false,
      discountCodeFlag: false,
      showQueryString: false,
      showDiscountCode: false,
      coupon_transaction_subtype_id:type.coupon_transaction_subtype_id?type.value: null,// (type.value == coupon_transaction_type_id.discount) ? coupon_transaction_subtype_id.PercentageType : coupon_transaction_subtype_id.DollarType,
      subTypeIndex:  type-1,//(type == coupon_transaction_type_id.discount) ? 0 : 1,
      isCodeEnabled: false,
      coupon_code: "",
      // showSubTypes: (type == coupon_transaction_type_id.discount),
      showDiscountCodeSection: (type != "")
    })
  }
  }

  setCouponSubType = (type, index) => {
  
    this.setState({
      coupon_transaction_subtype_id: type,
      coupon_transaction_subtype_Flag: false,
      subTypeIndex: index
    })
  }

  setIsCodeEnabled = (isCodeEnabled) => {
     if (isCodeEnabled !== "2")
    {
      this.state.chooseAFile =''
    this.state.coupon_code_file_url=''
  }
    if (isCodeEnabled == "0")
      this.setState({
        isCodeEnabled: isCodeEnabled,
        discountCodeFlag: false,
        buttonShow:false
      })

      else if(isCodeEnabled == '1')
      {
        this.setState({
          isCodeEnabled: isCodeEnabled,
          buttonShow:false
        })
      }
      else
      this.setState({
                isCodeEnabled: isCodeEnabled,
                buttonShow:true

      })

    // else
    //   this.setState({
    //     isCodeEnabled: isCodeEnabled
    //   })
  }

  setDiscountCode = (e) => {
    const reg = /^[a-zA-Z0-9-,]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ discountCodeFlag: false });
    } else {
      this.setState({
        discountCodeFlag: true,
        discountCodeErrorMsg: "Use only Alphabets, Numeric , Hyphen and Comma",
      });
    }

    this.setState({
      coupon_code: e.target.value
    })
  }


  applyHandler = () => {
    const data = this.setData();

    if(!this.ValidateFields()){
      return;
    }

     if(this.state.updateActiveMode)
    {
      // alert("update active offer")

      console.log("datasadasdasd", data)
      
      // return;

      this.props.updateExpiryDate({
          coupon_batch_id:data.coupon_batch_id,
          promotion_end_date:data.promotion_end_date,
          redemption_end_date:data.redemption_end_date,
          featured_offer_order: data.featured_offer_order,
          legalese:data.legalese,
          coupon_terms:data.coupon_terms,
          coupon_term:data.coupon_terms,
          // image_location:data.image_location,
          media_location:data.image_location,
          offer_name:data.offer_name,
          offer_description:data.offer_description
        })
      
        return;
    }
  //  else if (this.ValidateFields()) {

    if(data.image_location && !Array.isArray(data.image_location))
    data.image_location = [data.image_location]
  if(data.image_location)  
  {
    data.media_location =  data.image_location
   try {
    data.image_location =  JSON.parse(data.media_location[0]).image_location
   } catch (error) {
    data.image_location = null
    data.media_location = null
   } 
  }
        if(this.state.updateDraftMode){
          this.props.GenerateCouponsFromDraft(data)
        }
        else
        this.props.GenerateCoupons(data);
      // }


  };

  setData=()=>{
    var redemption_url = null;
    if (this.state.showRedemptionUrl) {

      redemption_url = this.state.RedemptionURL.trim()
      // if (redemption_url.slice(-1) != "/")
      //   redemption_url = redemption_url + "/"
      if (this.state.showQueryString)
        redemption_url = redemption_url + "?" + this.state.queryString.replace("?","")
    }
    let endTime = this.state.endTime
    var m = moment();
    m.set({hour:23,minute:59,second:59,millisecond:0})


    endTime=endTime?moment_tz(endTime).format('HH:mm:ss'):moment_tz(m).format('HH:mm:ss')
    let startTime= this.state.startTime
    m.set({hour:0,minute:0,second:0,millisecond:0})

    startTime=startTime?moment_tz(startTime).format('HH:mm:ss'):moment_tz(m).format('HH:mm:ss')

    let StartDate = new Date(this.state.startDate)
    // StartDate = StartDate.toISOString().substring(0,10)
    let endDate = new Date(this.state.endDate)
    // endDate = endDate.toISOString().substring(0,10)
    // var d = new Date();

    // let offSet = d.getTimezoneOffset()
    // offSet = offSet / 60

    // let symbol = "-"
    // if (offSet < 0) {
    //   symbol = "+"
    //   offSet = Math.abs(offSet)
    // }
    // if (offSet < 10)
    //   offSet = "0" + offSet

    // const startTimeWithoutOffset = this.state.startTimeWithoutOffset ? this.state.startTimeWithoutOffset : "00:00:00"
    // const endTimeWithoutOffset = this.state.endTimeWithoutOffset ? this.state.endTimeWithoutOffset : "23:59:59"

    // alert(this.state.endTimeWithoutOffset)
    // alert(endTimeWithoutOffset)

    
    return{
      coupon_face_value: this.state.faceValue,
      coupon_create_dttm: moment_tz(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",
      promotion_start_date:moment_tz(moment(StartDate).utcOffset(0, true).format("YYYY-MM-DD")  + " " +startTime).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",
      promotion_end_date:moment_tz(moment(endDate).utcOffset(0, true).format("YYYY-MM-DD")   + " " +endTime).utc().format('YYYY-MM-DD HH:mm:ss') +"+00:00", // dateFormat(this.state.endDate, "yyyy-mm-dd"),
      redemption_start_date:moment_tz(moment(StartDate).utcOffset(0, true).format("YYYY-MM-DD")  + " " +startTime).utc().format('YYYY-MM-DD HH:mm:ss')+"+00:00",
      redemption_end_date: moment_tz(moment(endDate).utcOffset(0, true).format("YYYY-MM-DD")   + " " +endTime).utc().format('YYYY-MM-DD HH:mm:ss') +"+00:00",
      application_user_id: this.props.cookies.get("njt_admin_userId_partner") && this.props.cookies.get("njt_admin_userId_partner").application_user_id ? this.props.cookies.get("njt_admin_userId_partner").application_user_id : 1,
      business_id: this.props.cookies.get("BusinessProfileData").business_id,
      month_id: this.state.month,
      coupon_quota: this.state.Quantity,
      year_id: this.state.yearId,
      override_coupon_pricing: false,
      image_location: this.state.CouponLogo == "" ? null : this.state.CouponLogo,
      coupon_terms: this.state.CouponTerms == "" ? null : this.capitalize(this.state.CouponTerms),
      coupon_term:this.state.CouponTerms == "" ? null : this.capitalize(this.state.CouponTerms),
      coupon_transaction_type_id: this.state.coupon_transaction_type_id,
      legalese: this.state.legalese == "" ? null : this.state.legalese,
      offer_name: this.state.offer_name,
      coupon_transaction_subtype_id:  this.state.coupon_transaction_subtype_id == 0? null : this.state.coupon_transaction_subtype_id,
      generated_by: this.props.cookies.get("njt_admin_userId_partner") &&
        this.props.cookies.get("njt_admin_userId_partner").user_name
        ? this.props.cookies.get("njt_admin_userId_partner").user_name
        : "admin",
      is_coupon_code_enabled: parseInt(this.state.isCodeEnabled),
      coupon_code: parseInt(this.state.isCodeEnabled) ? this.state.coupon_code.trim() : "",
      redemption_url: redemption_url,
      show_code_in_redemption_url: this.state.showDiscountCode,
      coupon_batch_id: this.state.coupon_batch_id ? this.state.coupon_batch_id : null,
      buy_x_quantity:this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?parseInt(this.state.xquantity):null,
      get_y_quantity:this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?parseInt(this.state.yquantity):null,
      file_img_name:  this.state.coupon_transaction_type_id !=""&&  this.state.isCodeEnabled == "2" && this.state.chooseAFile !=""?this.state.chooseAFile:null,
      coupon_code_file_url: this.state.coupon_transaction_type_id !="" &&  this.state.isCodeEnabled == "2" && this.state.coupon_code_file_url ?this.state.coupon_code_file_url:null,
      business_name:this.props.cookies.get("BusinessProfileData").business_name,
      featured_offer_order: this.state.featuredOfferOrder ? parseInt(this.state.featuredOfferOrder) : null,
      wallet_page_offer_url:this.state.WalletURL.trim() !=="" ?this.state.WalletURL:null,
      rewards_page_offer_url:this.state.RewardURL.trim() !=="" ?this.state.RewardURL:null,
      offer_description:this.state.offerDescription.trim() !=="" ? this.state.offerDescription:null
    };
  }
  saveAsDraftHandler = () => {

    if (this.ValidateFields()) {
     
      let data=this.setData()
      if(data.image_location && !Array.isArray(data.image_location))
        data.image_location = [data.image_location]
      if(data.image_location)  
      {
        data.media_location =  data.image_location
       try {
        data.image_location =  JSON.parse(data.media_location[0]).image_location
       } catch (error) {
        // alert("test")
        data.image_location = null
        data.media_location = null
       }
      }
      // alert( JSON.stringify( data.promotion_end_date))
      if (!this.state.isLoading) {

        this.state.isLoading = true

        if(this.state.updateDraftMode)
          this.props.updateDraft(data);
        else
          this.props.createDraft(data);
        
      }
    }

  };


handleFiles = files => {
  // console.log(files)
  console.log("fired.");
  let reader = new FileReader();
  let file = files.target.files[0];
  if (file) {

    let fileTypeError = file.name.endsWith('.csv') ? false: true

    if(fileTypeError){
      this.setState({
        file_error:failure_messages.csvFileUploadErrorMsg,
        chooseAFile : '',
        coupon_code_file_url :'',
        isBase64 :''
      })
    }
    else if((file.size/(1024*1024)) > 1){
      this.setState({
        file_error:"File Size should be less than 1 MB"
      })
    }
    else{

      if(!this.isCsvFilenameValid(file)){
        // alert("error in filename")
        this.setState({
          file_error: "File name may includes alphabets, numbers, hyphen and underscore only.",
          chooseAFile: '',
          coupon_code_file_url: '',
          isBase64: ''
        })
        return
      }


reader.onload = ()=> {
  var textData=reader.result
        var allTextLines = textData.split(/\r\n|\n/);
        var codes = []
        var error=false
          for (let entry of allTextLines) {
            var tmpEntry=""
            // alert(typeof entry)
            if(entry.charAt(0) == "\"")
            {
              for(var i=1; i<entry.length && (entry[i-1]+entry[i])!= "\",";i++)
              tmpEntry=tmpEntry+entry.charAt(i);
            }
            if(tmpEntry != "")
            codes.push(tmpEntry.trim())         
            else{
              // alert(entry.split(",")[0].trim())
              if (entry !== "")
                codes.push(entry.split(",")[0].trim())
              
            } 
            if(tmpEntry.length > 64 || entry.length>64)
            {
              error="Each offer code should be less than 64 characters"
              break;
            }
          }

          if(codes.length == 1)
          error = 'File does not contain any code except header row'
          else if(codes.length < 1)
          error = 'File does not contain any codes'
          codes.shift()


          // codes.shift()
        
          if(error)
          this.setState({
            file_error:error,
            chooseAFile:""
          })
          else{
        codes = Array.from(new Set(codes))
        var len = codes.length

        if(len < 1){
          this.setState({
            file_error: "File does not contain any code except header row.",
            chooseAFile: '',
            coupon_code_file_url: '',
            isBase64: ''
          })

          // this.QuantityHandler({target:{value:len}})

          return
        }


        if(len > this.state.maxCouponsLimit)
        len = this.state.maxCouponsLimit
        reader = new FileReader();
        reader.onload = ()=> {
          this.state.chooseAFile = file.name
          this.state.coupon_code_file_url = reader.result
          this.state.file_error = ""
          this.state.isBase64 = true
       
          this.QuantityHandler({target:{value:len}})
     
        }
        reader.readAsDataURL(file);
    
      }
    }
    reader.readAsText(file);
  }
  }else{

  }
 
}


isCsvFilenameValid = (file)=>{

  const reg = /^[a-zA-Z0-9-_ ]*$/;
  let filename = file.name.replace(".csv","")

  console.log("filename", filename)

  if(reg.test(filename))
    return true

  return false
}

  featuredOfferCheckBoxHandler = () => {

    this.setState({
      isfeaturedOffer: !(this.state.isfeaturedOffer)
    }, () => {
      if (!this.state.isfeaturedOffer)
        this.setState({
          featuredOfferOrder: null,
          featuredOfferOrderFlag: false
        })

    })

  }

  featuredOfferOrderHandler = (event) => {

    const reg = /^\+?[1-9]\d*$/;

    if(event.target.value === ""){
      this.setState({
        featuredOfferOrderFlag: false,
        featuredOfferOrder: ''
      })
      return
    }


    const validReg = reg.test(event.target.value);
    if (validReg) {
      // this.state.xquantityFlag = false
      this.setState({
        featuredOfferOrderFlag: false
      })

    } else {

      this.state.featuredOfferOrderFlag = true
      this.state.featuredOfferErrorMsg = "Use only positive numbers "

    }

    this.setState({ featuredOfferOrder: event.target.value });
  }



  handleShowCouponPreview = () => this.setState({ showModal: true });
  handleCloseModal = () => this.setState({ showModal: false });
  capitalize = (text) => {
    var couponTerms = text.trim()
    couponTerms = couponTerms.charAt(0).toUpperCase() + couponTerms.substring(1);
    couponTerms = couponTerms.replace(/([^.])$/, '$1.');
    return couponTerms
  }
  render() {
    // alert(this.state.needEdit)
    // console.log("business data", this.props.cookies.get("BusinessProfileData"))
    
    const {
      year,
      yearFlag,
      yearErrorMsg,
      month,
      monthFlag,
      monthErrorMsg,
      xquantity,
      xquantityFlag,
      xquantityErrorMsg,
      yquantity,
      yquantityFlag,
      yquantityErrorMsg,
      faceValue,
      faceValueFlag,
      faceValueErrorMsg,
      couponValue,
      couponValueFlag,
      couponValueErrorMsg,
      Quantity,
      QuantityFlag,
      QuantityErrorMsg,
      startDate,
      startDateFlag,
      startDateErrorMsg,
      initialDate,
      endDate,
      endDateFlag,
      endDateErrorMsg,
      BusinessProfileData,
      legalese,
      offerDescription,
      coupon_transaction_subtype_Flag,
      coupon_transaction_subtype_ErrorMsg,
      isCodeEnabled,
      showDiscountCodeSection,
      startTime,
      startTimeFlag,
      endTimeFlag,
      endTime,
      couponImageFlag,
      couponImageErrorMsg
    } = this.state;
    // alert(this.props.updateExpiryDate)
    // console.log(this.props, "akjsdakjsdkajsd")
    console.log("ahsdjhasdjhasd", faceValue, this.state.maxUnusedQuota)
    // alert(this.state.year)

    const is12HourFormat = true
    const mask12HourFormat = "__:__ _M"

// alert(this.state.CouponImage)
    return (
      <div className="pricing-box"
      >
        {/* <ProgressBarFive
          ProgressBar1="active"
          ProgressBar2="active"
          ProgressBar3="active"
          ProgressBar4="active"
          ProgressBar5="active"
        /> */}
        <div className="container-price">
          <div className="price-box">
            <h5>Manage Offer Inventory</h5>
          </div>
          {this.state.QuotasContractDetails.length >0?
          <div className="pricing-table">
            <CouponsInventoryContainer
              setEditCouponData={this.props.setEditCouponData}
              resetFields={this.props.resetFields}

            />
          </div>
          :<Loading />}
          <div className="override-box"
           >
            <div className="override-left mana-inv-cp">
              <div >
              <div className="override-two">
                <div className="overide-year">
                  <LabelInput
                    label="Offer Title"
                    type="text"
                    name="OfferName"
                    star="*"
                    id="offerTitle"
                    inputValue={this.state.offer_name}
                    //disabled={true}
                    flag={this.state.offerNameFlag}
                    errorMsg={this.state.offerNameErrorMsg}
                    onChange={this.offerNameHandler}
                    maxLength={100}
                  />
                </div>
              </div>
              <div  className="override-two">
                <div className="overide-year">
                <LabelTextAreaInput
                    label="Offer Description"
                    name="offerDescription"
                    id="offerDescription"
                    inputValue={offerDescription}
                    maxLength={offer_desc_limit}
                    placeholder={this.state.descriptionPlaceholder}
                    onChange={this.setOfferDescription}
                  />
                </div>
              </div>
              <div style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}} className="override-two" ref={(ref) => this.offerName = ref}>
                <div className="overide-year">
                  <LabelDropDownInput
                    data={this.state.updateActiveMode?[{name:year,value:year}]:this.state.yearData}
                    label="Year"
                    type="year"
                    star="*"
                    id="year"
                    name="year"
                    inputValue={year}
                    flag={yearFlag}
                    errorMsg={yearErrorMsg}
                    onChange={this.yearHandler}
                  />
                  <GetQuotasContractDetailsContainer
                    setYearsQuota={this.setYearsQuota}
                    setUpdateYearQuota={this.setUpdateYearQuota}
                    getYearQuotas = {this.state.getYearQuotas}
                    setYearQuotas={this.setYearQuotas}
                    updateYearQuotas={this.state.updateYearQuotas}
                    year={year}
                  />
                </div>
              </div>

              <div style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}} className="override-two offer-date">
                <div className="date-and-time-box">
                <div className="overide-year">
                  <div className="label-d">Offer Start Date<span style={{color: "red"}}>*</span></div>
                  <DatePicker1
                    htmlFor="offerStartDate"
                    maxDate={this.state.maximumStartDate}
                    minDate={new Date(this.state.minimunDate) >new Date()?this.state.minimunDate:new Date()}
                    // placeholder="Start Date of Coupons"
                    // placeholderText={this.state.startDate}

                    //disabled={faceValue === ""|| this.state.maxUnusedQuota ==0 ? true : false}
                    disabled={year == "" ? true : false}
                    inputValue={startDate}
                    flag={startDateFlag}
                    errorMsg={startDateErrorMsg}
                    onChange={this.startDateHandler}
                  />
                </div>
                <div className="time-box-mian2">
                <div className="time-left">
              <label className="gpp-date-label point">Start Time</label>
                        <div className="time-box">
                          <MaterialTimePicker
                            inputValue={startTime}
                            id="startTime"
                            onChange={this.setStartTime}
                            disabled={year == "" ? true : false}
                            mask={mask12HourFormat}
                            ampm={is12HourFormat}
                            errorMsg={startTimeFlag ? "Invalid Time" : undefined}
                            // errorHandler={this.startTimeErrorHandler}
                          />
                          {/* {startTimeFlag ? <p className="error-message-time">Field Required</p> : null} */}
                        </div>
            </div>
                </div>
                </div>
              </div>
              </div>
              <div  className="override-two offer-date">
              <div className="date-and-time-box">
                <div className="overide-year">
                  <div className="label-d">Offer Expiration Date<span style={{color: "red"}}>*</span></div>
                  <DatePicker1
                    htmlFor="offerExpirationDate"
                    maxDate={this.state.maximumDate}
                    minDate={startDate == "" ? new Date() : this.props.editCouponData.promotion_end_date && new Date(this.props.editCouponData.promotion_end_date)>new Date() && startDate && this.state.updateActiveMode? new Date(this.props.editCouponData.promotion_end_date): startDate>new Date()?startDate:new Date()}
                    
                    // placeholder="Expiration Date of Coupons"
                    // star="*"
                    // startDate={startDate}
                    // disabled={
                    //   faceValue === "" ||this.state.maxUnusedQuota ==0? true : startDate === "" ? true : false
                    // }
                    disabled={
                      startDate === "" ? true : false
                    }
                    inputValue={endDate}
                    flag={endDateFlag}
                    errorMsg={endDateErrorMsg}
                    onChange={this.endDateHandler}
                  />
                </div>
                  <div className="time-box-mian2">
                    <div className="time-left">
                      <label className="gpp-date-label point">End Time</label>
                      <div className="time-box">
                        <MaterialTimePicker
                          inputValue={endTime}
                          id="endTime"
                          onChange={this.setEndTime}
                          disabled={year == "" ? true : false}
                          mask={mask12HourFormat}
                            ampm={is12HourFormat}
                            errorMsg={endTimeFlag ? "Invalid Time" : undefined}
                          // errorHandler={this.endTimeErrorHandler}
                        />
                        {/* {endTimeFlag ? <p className="error-message-time">Field Required</p> : null} */}
                      </div>
                    </div>
                </div>
                </div>
              </div>
              <div style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
          
              <GetCouponTypesContainer
                setCouponType={this.setCouponType}

                setSubTypeData={this.setSubTypeData}
                SubTypes={this.state.SubTypes}

                // disabled={
                //   endDate === "" ? true : false
                // }
                coupon_transaction_type_id={this.state.coupon_transaction_type_id}
                coupon_transaction_type = {this.state.coupon_transaction_type}
                coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
                needEdit={this.state.needEdit}
                setEditType={this.setEditType}
                couponValueErrorMsg={couponValueErrorMsg}
                couponValueFlag={couponValueFlag}
              />
              {/* {
                this.state.showSubTypes ?
                  <div className="override-two">
                    <div className="overide-year">
                      {this.state.SubTypes.map((item, index) => {
                        return (
                          <SingleInputRadio
                            key={index}
                            label={item.name}
                            name={item.name}
                            checked={index == this.state.subTypeIndex}
                            // value={item.value}
                            value={this.state.coupon_transaction_subtype_id}
                            onChange={() => this.setCouponSubType(item.value, index)}
                          />)
                      })
                      }
                    </div>
                  </div>
                  :
                  null
              } */}
              {
                coupon_transaction_subtype_Flag ? <p className="li_error_text">Field Required</p> : null
              }

              {this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?
              <>
            <div className="override-two"
               ref={(ref) => this.xquantity = ref}
             >
                <div className="overide-year">
                  <LabelInput
                    label="Buying (X) Quantity"
                    type="text"
                    star="*"
                    name="xquantity"
                    id="xquantity"
                    inputValue={xquantity}
                    //disabled={true}
                    flag={xquantityFlag}
                    errorMsg={xquantityErrorMsg}
                    onChange={this.xQuantityHandler}
                    // maxLength={100}
                  />
                </div>
              </div>


              <div className="override-two"
                ref={(ref) => this.xquantity = ref}
              >
                <div className="overide-year">
                  <LabelInput
                    label="Free (Y) Quantity"
                    id="xquantity"
                    type="text"
                    name="OfferName"
                    star="*"
                    inputValue={yquantity}
                    //disabled={true}
                    flag={yquantityFlag}
                    errorMsg={yquantityErrorMsg}
                    onChange={this.yQuantityHandler}
                    // maxLength={100}
                  />
                </div>
              </div>

                </>:""}
              <div className="override-two">
                <div className="overide-year">
                  <LabelDropDownInput
                    data={this.state.faceValueData}
                    label={this.state.coupon_transaction_type_id == coupon_transaction_type_id.buy_x_gey_y?"Minimum Purchase Amount":"Offer Value"}
                    type="offerValue"
                    name="offerValue"
                    star="*"
                    id="offerValue"
                    inputValue={faceValue}
                    flag={faceValueFlag}
                    errorMsg={faceValueErrorMsg}
                    onChange={this.faceValueDataHandler}
                    disabled={endDate == "" ? true : false}
                    coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
                    showDollar={true}
                  />
                </div>
              </div>
              
              {
                showDiscountCodeSection && faceValue !=""?
                  <Fragment>
                    <div className="override-two">
                      <div className="ov-single-b">
                        <SingleInputRadio
                          key="Generate a unique code for each offer card in the batch"
                          label="Generate a unique code for each offer card in the batch"
                          name="Auto Generated"
                          id="uiqueCode"
                          checked={isCodeEnabled == '0'}
                          // value={item.value}
                          //value={this.state.coupon_transaction_subtype_id}
                          onChange={() => this.setIsCodeEnabled('0')}
                        />
                        <SingleInputRadio
                          key="Use offer code below for all the offer cards in the batch"
                          label="Use offer code below for all the offer cards in the batch"
                          name="Auto Generated"
                          id="offerCode"
                          checked={isCodeEnabled == '1'}
                          // value={item.value}
                          //value={this.state.coupon_transaction_subtype_id}
                          onChange={() => this.setIsCodeEnabled('1')}
                        />


                        <SingleInputRadio
                          key="Upload Offer Codes from CSV file"
                          label="Upload Offer Codes from CSV file"
                          name="Auto Generated"
                          id="offerCodeCSV"
                          checked={isCodeEnabled == '2'}
                          buttonShow={this.state.buttonShow}
                          // value={item.value}
                          //value={this.state.coupon_transaction_subtype_id}
                          onChange={() => this.setIsCodeEnabled('2')}
                          />
                        

                 
                        {this.state.buttonShow && 
                        
                        
                         <i buttonShow={this.state.buttonShow} className="fa fa-info-circle icon-info op" data-title2="The system expects all the offer codes to be in the first column. No column header is required."></i>      }
                      </div>
                    </div>

                    {this.state.buttonShow &&      
                                <><input
                                 id="file-input-id"
                                type="file"
                                // accept=".csv"
                                onClick={(event)=> { 
                                  event.target.value = null
                             }}
                                className="pe-inp-btn-upload"
                                 onChange={this.handleFiles} />
                                      <label
                      htmlFor="file-input-id"
                      // className="pe-upload-btn-upload"
                    >
                        <div className="override-left" style={{width:"100%"}}>
              <div className="upload-hover-button">
                Select File
              </div>
            </div>
                      {/* <div className='camera-upload-hover-upload'>Select File</div>  */}
                   
                      {/* <div className="banner-up">Banner</div> */}
                      {/* <div className="camera-upload-hover-upload">Select</div> */}
                    </label>
                  
                    <span className="ml-3">{this.state.chooseAFile}</span> 
                                 <p className="li_error_text" style={{paddingTop:3}}>{this.state.file_error}</p>
                                </>
                                //  <NewUploadInput
                                //  multiple={false}
                                //  saveUrl = {ROOT_URL+"/api/coupons/upload_coupon_codes"}
                                //  token={getCookie("admin_token_partner")}
                                //  //  MultipleFlag={false}
                                //  //  files={this.state.attachment}
                                //  // flag={this.state.attachmentFlag}
                                //  // errorMsg={this.state.attachmentErrorMsg}
                                //  onChange={this.handleFiles}
                                //  />
                                 }


                    <div className="override-two">
                      <div className="overide-year">
                        <LabelInput
                          label="Offer Code"
                          type="text"
                          id="discountCode"
                          star="*"
                          name="Discount Code"
                          inputValue={this.state.coupon_code}
                          disabled={isCodeEnabled == '1' ? false : true}
                          flag={this.state.discountCodeFlag}
                          errorMsg={this.state.discountCodeErrorMsg}
                          onChange={this.setDiscountCode}
                          maxLength={64}
                        />
                      </div>
                    </div>
                  </Fragment>
                  :
                  null
              }


              {/* <div className="override-two">
                <div className="overide-year">
                  <LabelInput
                    label="Monthly Quota"
                    type="text"
                    name="MonthlyQuata"
                    inputValue={this.state.MonthlyQuota}
                    disabled={true}
                    // flag={QuantityFlag}
                    // errorMsg={QuantityErrorMsg}
                    // onChange={this.QuantityHandler}
                  />
                </div>
              </div> */}
              <div
                style={{ float: "left", clear: "both" }}
                ref={(el) => {
                  this.CouponYear = el;
                }}
              ></div>
              <div className="override-two">
                <div className="overide-year">
                  <GetPreviousCouponsContainer
                    // disabled={faceValue === "" ? true : false}
                    // disabled={true}
                    faceValue={faceValue}
                    setmaxUnusedQuota={this.setmaxUnusedQuota}
                    year_id={this.state.year_id}
                    selectedMonth={this.state.month}
                    MonthlyQuota={this.state.MonthlyQuota}
                  />
                </div>
              </div>
              <div className="override-112">
                <div className="overide-year">
                  <LabelInput
                    label="Quantity"
                    type="text"
                    name="Quantity"
                    star="*"
                    id="quantity"
                    inputValue={Quantity}
                    flag={QuantityFlag}
                    errorMsg={QuantityErrorMsg}
                    disabled={faceValue === "" || this.state.maxUnusedQuota == 0 || this.state.maxUnusedQuota == '--' || this.state.chooseAFile !="" ? true : false}
                    onChange={this.QuantityHandler}
                  />
                  <div className="unused-allote">
                    Unused Allotment of Offers: {this.state.maxUnusedQuota}/{this.state.MonthlyQuota ? this.state.MonthlyQuota : '--'}
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="override-left margin">
              <div className="right-over-box">
              <div >
                <div className="ov-feild date-over" >
                  <label className="coupon-trm-l">Upload and Select Coupon Images and Videos - Maximum 5<span style={{color: "red"}}>*</span></label>
                  <GetCouponImagesContainer
                    setRetailerCouponImage={this.setRetailerCouponImage}
                    CouponImage={this.state.CouponImage}
                    maxMediaLimit={this.state.maxMediaLimit}
                  />
                    {
                      couponImageFlag ? <p className="li_error_text">{couponImageErrorMsg}</p> : null
                    }
                </div>
                <div className="ov-feild date-over" >
                  <label className="coupon-trm-l">Offer Terms (Optional)</label>
                  <GetCouponTermsContainer
                    setRetailerCouponTerm={this.setRetailerCouponTerm}
                    faceValue={this.state.faceValue}
                    TermName={this.state.CouponTerms}
                    coupon_transaction_type_id={this.state.coupon_transaction_type_id}
                    xquantity={this.state.xquantity}
                    yquantity={this.state.yquantity}
                    coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
                  />
                </div>
                <div className="ov-feild date-over" >
                  <LabelTextAreaInputWithoutML
                    label="Terms & Conditions (Optional)"
                    id="legalese"
                    name="legalese"
                    inputValue={legalese}
                    maxLength={term_and_conditions_limit}
                    onChange={this.setLegalese}
                  />
                </div>



                <div 
                className="featured-offer-checkbox" 
                >
                    <CheckBoxSingle
                      value={this.state.isfeaturedOffer}
                      onChange={this.featuredOfferCheckBoxHandler}
                      name="Mark Offer as Featured"
                      label="Mark Offer as Featured"
                      id="offerFeatured"
                    />

                  {
                    this.state.isfeaturedOffer &&
                    
                    <div className="override-marg"
                    ref={(ref) => this.xquantity = ref}
                    >
                      <div className="ov-feild box-order-input">
                        <LabelInput
                          label="Order"
                          type="text"
                          name="Order"
                          id="order"
                          inputValue={this.state.featuredOfferOrder}
                          //disabled={true}
                          flag={this.state.featuredOfferOrderFlag}
                          errorMsg={this.state.featuredOfferErrorMsg}
                          onChange={this.featuredOfferOrderHandler}
                          // maxLength={100}
                          />
                      </div>
                    </div>
                  }
                  </div>
                 

                  <div className="" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                    <CheckBoxSingle
                      value={this.state.showRewardUrl}
                      onChange={this.setRewardURL}
                      name="RedemptionUrl"
                      label="Show different base URL in rewards"
                      id="redemptionUrl"
                    />
                  </div>
                  {this.state.showRewardUrl ? 
                    <div className="ov-feild" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                      <SimpleInput
                        label=""
                        type="text"
                        name="RewardURL"
                        id="rewardURL"
                        inputValue={this.state.RewardURL}
                        //disabled={true}
                        // inputContainerStyle={{float:'left'}}
                        flag={this.state.RewardURLFlag}
                        errorMsg={this.state.RewardURLErrorMsg}
                        onChange={this.RewardURLHandler}
                        maxLength={250}
                      />
                    </div>
                  :null}
                    <div className="" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                      <CheckBoxSingle
                        value={this.state.showWalletURL}
                        onChange={this.setShowWalletURL}
                        name="showWalletURL"
                        label="Show different base URL in wallet"
                        id="showWalletURL"
                      />
                      {this.state.showWalletURL ?
                        <div className="ov-feild">
                        <SimpleInput
                        label=""
                        type="text"
                        name="WalletURL"
                        id="walletURL"
                        inputValue={this.state.WalletURL}
                        //disabled={true}
                        // inputContainerStyle={{float:'left'}}
                        flag={this.state.WalletURLFlag}
                        errorMsg={this.state.WalletURLErrorMsg}
                        onChange={this.WalletURLHandler}
                        maxLength={250}
                      />
                        </div> : null}
                    </div>
                    <div className="" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                      <CheckBoxSingle
                        value={this.state.showRedemptionUrl}
                        onChange={this.setShowURL}
                        name="ShowQueryString"
                        label="Show below URL instead of URL from business profile at time of redemption"
                        id="BusinessRedemptionURL"
                      />
                      {this.state.showRedemptionUrl ?
                        <div className="ov-feild">
                            <SimpleInput
                        label=""
                        type="text"
                        name="RedemtionURL"
                        id="BusinessRedemptionURLInput"
                        inputValue={this.state.RedemptionURL}
                        //disabled={true}
                        // inputContainerStyle={{float:'left'}}
                        flag={this.state.RedemptionURLFlag}
                        errorMsg={this.state.RedemptionURLErrorMsg}
                        onChange={this.RedemptionURLHandler}
                        maxLength={250}
                      />
                        </div> : null}
                    </div>
                    {this.state.showRedemptionUrl ?
                    <>
                    <div className="" style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                      <CheckBoxSingle
                        value={this.state.showQueryString}
                        onChange={this.setShowQueryString}
                        name="ShowQueryString"
                        label="Show query string at redemption"
                        id="showQueryString"
                      />
                      {this.state.showQueryString ?
                        <div className="ov-feild">
                          <SimpleInput
                            label=""
                            type="text"
                            name="queryString"
                            id="queryString"
                            inputValue={this.state.queryString}
                            //disabled={true}
                            // inputContainerStyle={{float:'left'}}
                            // flag={this.state.queryString ==""}
                            // errorMsg={this.state.RedemptionURLErrorMsg}
                            onChange={(e) => {
                              this.setState({ queryString: e.target.value })
                            }}
                            maxLength={250}
                          />
                        </div> : null}
                    
                    <div className="">
                      <CheckBoxSingle
                        value={this.state.showDiscountCode}
                        onChange={this.setShowDiscountCode}
                        name="Append offer code to URL"
                        label="Append offer code to URL at redemption"
                        id="appendOfferURL"
                      />
                    </div>

                    </div>
                    </> : null} 
                  </div>

                <div className="btn-box-inv-box box-in">
                  <button id="previewOfferBtn" className="btn-override width" onClick={this.prevCouponHandler}>
                    Preview Offer
            </button>

                </div>
                <div className="btn-box-inv-box box-in">
                  <button id="generateOfferBtn" className="btn-override width" onClick={this.applyHandler}>
                    Generate Offer
                  </button>
                </div>
                <div style={this.state.updateActiveMode?{opacity:0.5, pointerEvents:'none'}:{}}>
                <div className="btn-box-inv-box box-in">
                  <button id="saveDraftbtn" className="btn-override width" disabled={this.state.updateActiveMode} onClick={this.saveAsDraftHandler}>
                    Save As Draft
                  </button>
                </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="btn-box-override">
            <div className="override-left">
              <div className="btn-previous">
                <button
                  id="backbutton4"
                  className="btn-override left"
                  onClick={this.backHandler}
                >
                  <i className="material-icons"> keyboard_arrow_left </i>
                </button>
              </div>
            </div>
            <div className="override-left margin">
              <button id="finishButton" className="btn-override right" onClick={this.nextHandler}>
                Finish
              </button>
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.CouponQuantity = el;
              }}
            ></div>
          </div>
        </div>
        {this.state.showModal ? (
          <Modal
            offer_name={this.state.offer_name}
            image_location={this.state.CouponLogo}
            coupon_face_value={this.state.faceValue}
            CouponTerms={this.capitalize(this.state.CouponTerms)}
            business_name={this.props.cookies.get("BusinessProfileData").business_name}
            threshold={this.state.threshold}
            Quantity={this.state.Quantity}
            expiryDate={endDate}
            endTimeWithoutOffset={this.state.endTimeWithoutOffset}
            points={this.state.points}
            onClose={this.handleCloseModal}
            maxUnusedQuota={this.state.maxUnusedQuota}
            business_logo_url={this.props.cookies.get("BusinessProfileData").logo_url}
            coupon_transaction_type_id={this.state.coupon_transaction_type_id}
            business_street_1={this.props.cookies.get("BusinessProfileData").business_street_1}
            business_street_2={this.props.cookies.get("BusinessProfileData").business_street_2}
            referral_fee_fixed={this.props.cookies.get("BusinessProfileData").referral_fee_fixed}
            referral_fee_percent={this.props.cookies.get("BusinessProfileData").referral_fee_percent}
            referral_fee_type_fixed={this.props.cookies.get("BusinessProfileData").referral_fee_type_fixed}
            referral_fee_type_percent={this.props.cookies.get("BusinessProfileData").referral_fee_type_percent}
            legalese={this.state.legalese}
            offerDescription={this.state.offerDescription}
            Display_Coupon_Tag={this.state.Display_Coupon_Tag}
            coupon_transaction_subtype_id={this.state.coupon_transaction_subtype_id}
            display_address_on_coupon={this.props.cookies.get("BusinessProfileData").display_address_on_coupon}
            city={this.props.cookies.get("BusinessProfileData").city}
            state={this.props.cookies.get("BusinessProfileData").state}
            zip5={this.props.cookies.get("BusinessProfileData").zip5}
            zip4={this.props.cookies.get("BusinessProfileData").zip4}
            company_url={this.props.cookies.get("BusinessProfileData").company_url}
            business_description={this.state.offerDescription.trim()!==""?this.state.offerDescription:this.props.cookies.get("BusinessProfileData").description}
            redemption_url={this.state.RedemptionURL}
            coupon_code={this.state.coupon_code}
            showRedemptionUrl={this.state.showRedemptionUrl}
            showQueryString={this.state.showQueryString}
            queryString={this.state.queryString}
            RewardURL={this.state.RewardURL.trim()!==""?this.state.RewardURL:false}
            WalletURL={this.state.WalletURL.trim()!==""?this.state.WalletURL:false}
            resetFields={this.props.resetFields}
            show_code_in_redemption_url={this.state.showDiscountCode}
            coupon_display_behavior_id={this.props.cookies.get("BusinessProfileData").coupon_display_behavior_id}
            coupon_display_behavior={this.props.cookies.get("BusinessProfileData").coupon_display_behavior}
          />
        ) : null}
      </div>
    );
  }
}
export default withRouter(ManageCouponInventory);
