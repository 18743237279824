import React, { Component, Fragment } from 'react'
import {Modal} from "./UndoDeleteCouponModel"
import {ref_status} from "RefRecordsConstants"
class UndoDeleteCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel:false
        };
    
      }
      
      handleModel=(check)=>{
        // alert(this.props.coupon.Redeemed_Coupons)
        if(this.props.coupon.Claimed_Coupons < 1 && this.props.coupon.Redeemed_Coupons < 1)
        this.setState({
          showModel:check
        })
        else
        this.props.showSnackBar("Cannot delete because some coupons are claimed/redeemed.")
      }
    render() {
     var disabled=""
      if(ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id || ref_status.DELETED === this.props.coupon.status_id)
      disabled="disabled"

        return (
            <Fragment>
            <button id="undoDeleteBtn" disabled={ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id} onClick={()=>this.handleModel(true)} className="decline-box-new-prev-undo">
            Undo Delete
            </button>
         {this.state.showModel? <Modal onClose={()=>this.handleModel(false) } onAccept={this.props.UndoDeleteCoupon}/>:null}
          </Fragment>
        )
    }
}

export default UndoDeleteCoupon
