import React, { Component, Fragment } from 'react'
//import './GetDisplayTypes.css'
import { GetDisplayTypesStatus } from './GetDisplayTypesConstants';
// import LoadingScreen from "../../utils/LoadingScreen"
// import GetDisplayTypes from './GetDisplayTypes'
// import realm from '../../utils/realm/UserSchema'
import { withCookies } from 'react-cookie';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingScreen from "../../Utils/Loading";

export class GetDisplayTypesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      callBack:false,
      // maxUnusedQuota:props.maxUnusedQuota
    }
    props.GetDisplayTypes(props.setParentState)
    // props.resetReducerState()
  //  this.InitializeQuotas()
  }
  closeSnackBar = () => {
    this.setState({
      open: false,
    })
  }
  InitializeQuotas = () => {
    if(this.props.cookies.get("BusinessProfileData"))
    { 
      
      // const data = {
      //    business_id: this.props.cookies.get("BusinessProfileData").business_id
      //  }
      // this.props.GetDisplayTypes(data)
     }
     
  
  }
  
  getScreen(status) {
    switch (status) {
      case GetDisplayTypesStatus.GetDisplayTypes_GET.NEW:
       
        return (
          <Fragment >
              <LoadingScreen />
          </Fragment>
        );


      case GetDisplayTypesStatus.GetDisplayTypes_GET.SUCCESS:
        // alert(JSON.stringify(this.props.DisplayTypes))
       
        // this.state.callBack = true
         //****************** After successful disptch action: QuotasContractDetails are used to populate the year month and face values fields 
        //  this.props.setYearsQuota(this.props.QuotaYears, this.props.QuotasContractDetails)
        //  alert(JSON.stringify(this.props.QuotaYears))
        //   if (this.props.QuotasContractDetails.length > 0) {
        //     //   alert(this.props.QuotasContractDetails[0].term_start_date)
        //     var date = new Date(this.props.QuotasContractDetails[0].term_start_date);
        //     if (date > this.state.term_start_date) this.state.term_start_date = date;
        //     if (this.props.QuotaYears.length > 0)
        //       this.state.startYear = this.props.QuotaYears[0].name;
        //     //alert(JSON.stringify(this.state.startYear))
        //     this.state.yearData = this.props.QuotaYears;
        //     this.state.endYear = new Date(
        //       this.props.QuotasContractDetails[0].term_end_date,
        //     ).getFullYear();
        //     //    alert(this.state.startYear)
        //   }
        return (
          <Fragment >
            {/* <GetDisplayTypes QuotasContractDetails={this.props.QuotasContractDetails} 
            yearDataHandler={this.props.yearDataHandler} monthDataHandler={this.props.monthDataHandler} faceValueDataHandler={this.props.faceValueDataHandler}
            startDateHandler={this.props.startDateHandler}
            setmaxUnusedQuota={this.props.setmaxUnusedQuota}
            setMaxMonthlyQuotalimit={this.props.setMaxMonthlyQuotalimit}
            yearFlag={this.props.yearFlag} 
            monthFlag={this.props.monthFlag} 
            faceValueFlag={this.props.faceValueFlag}
            QuotaYears = {this.props.QuotaYears}
     
            /> */}
          </Fragment>
        );
        break;
      case GetDisplayTypesStatus.GetDisplayTypes_GET.FAILED:
        // this.props.setYearsQuota([], [])
        // this.props.setModeTypeData([])
        return (
          <Fragment>

          {/* <GetDisplayTypes 
          QuotasContractDetails={this.props.QuotasContractDetails} 
          yearDataHandler={this.props.yearDataHandler} monthDataHandler={this.props.monthDataHandler}
          faceValueDataHandler={this.props.faceValueDataHandler}
          startDateHandler={this.props.startDateHandler}
          setmaxUnusedQuota={this.props.setmaxUnusedQuota}
          setMaxMonthlyQuotalimit = {this.props.setMaxMonthlyQuotalimit}
          QuotaYears = {this.props.QuotaYears}
          /> */}
          </Fragment>
        );

      case GetDisplayTypesStatus.GetDisplayTypes_GET.LOADING:
        return (
          <LoadingScreen />
        );
        break;
      default:
        break;
    }

  }
  render() {
    
    return (
      
        this.getScreen(this.props.GetDisplayTypes_GET_status)
      
    )
  }
}

export default withCookies(GetDisplayTypesView)
