import React, { Component, Fragment } from "react";
//import './UpdateApplicantsStatus.css'
import { UpdateApplicantsStatusStatus } from "./UpdateApplicantsStatusConstants";
import UpdateApplicantsStatus from "./UpdateApplicantsStatus";
// import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import Loading from "../../Utils/Loading";

export class UpdateApplicantsStatusView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      status: ''
    };
    props.ResetReducerState()
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };

  componentDidMount() {

    //this.props.UpdateApplicantsStatus();
  }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.NEW:
        //  alert(JSON.stringify( this.props.UpdateApplicantsStatus))
        return (
          <UpdateApplicantsStatus UpdateApplicantsStatus={this.props.UpdateApplicantsStatus}
            statusValue={this.props.statusValue}
            business_application={this.props.business_application}
            setStatusError={this.props.setStatusError}
            notes={this.props.notes}
            message = {this.props.message}
            statusName={this.props.statusName}
            username={this.props.username}
            change = {this.props.change}
            setNotesError = {this.props.setNotesError}
          />
        );

      case UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.SUCCESS:
        toast.success(this.props.UpdateMsg)
        this.props.ResetReducerState()
        this.props.onClick()
        return (
          <Fragment>
            
          </Fragment>
        );
      case UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.FAILED:
        //  alert(JSON.stringify( this.props.UpdateApplicantsStatus))
        toast.error(this.props.UpdateMsg)
        return (
          <Fragment>
            
            <UpdateApplicantsStatus 
              UpdateApplicantsStatus={this.props.UpdateApplicantsStatus}
              statusValue={this.props.statusValue}
              message = {this.props.message}
              business_application={this.props.business_application}
              setStatusError={this.props.setStatusError}
              notes={this.props.notes}
              statusName={this.props.statusName}
              change = {this.props.change}
              setNotesError = {this.props.setNotesError}
            />
          </Fragment>
        );

      case UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.LOADING:
        this.state.open = true;
        return (
          // <div style={{ textAlign: "center" }}>
          //   <Loading/>
          <>
             <UpdateApplicantsStatus UpdateApplicantsStatus={this.props.UpdateApplicantsStatus}
            statusValue={this.props.statusValue}
            business_application={this.props.business_application}
            setStatusError={this.props.setStatusError}
            notes={this.props.notes}
            message = {this.props.message}
            statusName={this.props.statusName}
            username={this.props.username}
            change = {this.props.change}
            setNotesError = {this.props.setNotesError}
            showLoader={true}
          />
          </>
        );
      default:
        return <div />;
    }
  }
  render() {

    return this.getScreen(this.props.UpdateApplicants_status);
  }
}

export default UpdateApplicantsStatusView;
