export const BannerScheduleActions= {
	
	BannerSchedule_POST :{
		NEW:"BannerSchedule_POST_NEW",
		SUCCESS:"BannerSchedule_POST_SUCCESS",
		FAILED:"BannerSchedule_POST_FALIURE",
		LOADING:"BannerSchedule_POST_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	BannerSchedule_GET :{
		NEW:"BannerSchedule_GET_NEW",
		SUCCESS:"BannerSchedule_GET_SUCCESS",
		FAILED:"BannerSchedule_GET_FALIURE",
		LOADING:"BannerSchedule_GET_LOADING",
	},
}


export const BannerScheduleStatus ={

	BannerSchedule_POST :{
		NEW:"BannerSchedule_POST_NEW",
		SUCCESS:"BannerSchedule_POST_SUCCESS",
		FAILED:"BannerSchedule_POST_FALIURE",
		LOADING:"BannerSchedule_POST_LOADING",
	},
	BannerSchedule_GET :{
		NEW:"BannerSchedule_GET_NEW",
		SUCCESS:"BannerSchedule_GET_SUCCESS",
		FAILED:"BannerSchedule_GET_FALIURE",
		LOADING:"BannerSchedule_GET_LOADING",
	},

}
