
//******************Exchange Rate Server Calls******************;

import { BusinessProfileActions } from './BusinessProfileconstants';
import store from '../../Store/store'
import { ROOT_URL,getCookie } from '../../Config/Config';
import {failure_messages} from "Messages"


export const BusinessProfileServer = {
  //  getBusinessProfile:getBusinessProfile,
  saveBusinessProfile: saveBusinessProfile,
  updateBusinessProfile: updateBusinessProfile,
  CreateRetailerBussiness:CreateRetailerBussiness
  ///api/business/CreateRetailerBussiness
};

//******************Insert BusinessProfiles******************;

function saveBusinessProfile(data, user_data) {

  // alert(JSON.stringify(data))

  if( data.selectedImage != "" && (typeof data.selectedImage ) === "object")
  { 
    var image =data.selectedImage.name.split(".")
    var img_name = image[0]
    var img_ext = image[image.length - 1]
    data = {
      ...data,
      isBase64:true,
      img_name:img_name,
      img_ext:img_ext
    }
   
   const request = fetch(ROOT_URL + '/api/business/upload_logo', {
     method: 'Post',
     mode: 'cors',
     headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
     body: JSON.stringify(data)
   }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log("upload response: ",response.data)
        data={
          ...data,
          logo_url:response.data.url
        }
        createBusiness(data, user_data)
///        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")

        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:response.error , payload: data});

      }
    }).catch((error) => {
      //  alert(error)
      store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError, payload: data});
    })
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError, payload: data});
  })
}else
createBusiness(data, user_data)

return { type: BusinessProfileActions.BusinessProfile_Post.LOADING }
};

function createBusiness(data, user_data) {
  console.log("data:", data)
  delete data.selectedImage
  const request = fetch(ROOT_URL + '/api/business/postBusinessEnrollment', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response.data)
        SaveRetailerProfile(response.data, user_data)
        // store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:response.error, payload: data});
        
      }
    }).catch((error) => {
      //  alert(error)
      store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError,  payload: data});
    })
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError,  payload: data});
  })

};


function CreateRetailerBussiness(data, user_data) {

  // alert(JSON.stringify(data))

  if( data.selectedImage != "" && (typeof data.selectedImage ) === "object")
  { 
    var image =data.selectedImage.name.split(".")
    var img_name = image[0]
    var img_ext = image[image.length - 1]
    data = {
      ...data,
      isBase64:true,
      img_name:img_name,
      img_ext:img_ext
    }
   
   const request = fetch(ROOT_URL + '/api/business/upload_logo', {
     method: 'Post',
     mode: 'cors',
     headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
     body: JSON.stringify(data)
   }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log("upload response: ",response.data)
        data={
          ...data,
          logo_url:response.data.url
        }
        SaveRetailerBussiness(data, user_data)
///        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:response.error });

      }
    }).catch((error) => {
      //  alert(error)
      console.log(error)
      store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError});
    })
  }).catch((error) => {
    console.log(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError});
  })
}else
  SaveRetailerBussiness(data, user_data)
return { type: BusinessProfileActions.BusinessProfile_Post.LOADING }

};

function SaveRetailerBussiness(data, user_data) {
  // alert('save')
  const request = fetch(ROOT_URL + '/api/business/CreateRetailerBussiness', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response)
        SaveRetailerProfile(response.data, user_data)
        //  alert('saved')
        // store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:response.error });

      }
    });
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError });
  })

};


function SaveRetailerProfile(payload, user_data) {
  
  // alert(JSON.stringify(payload.business_id))
  // alert(JSON.stringify({...user_data,business:{value:payload.business_id}}))
  const request = fetch(ROOT_URL + '/api/applicationUser/createRetailerProfile', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({...user_data,business:{value:payload.business_id}})
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response)
        //  alert('saved')
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload:payload, message:response.message.replace("New Partner user added","New Partner and Business Profile added") });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:response.error });

      }
    });
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError });
  })

};

function updateBusinessProfile(data, bussinessId) {
//   if(data.selectedImage != "" && (typeof data.selectedImage ) === "object")
//   { 
//     var image =data.selectedImage.name.split(".")
//     var img_name = image[0]
//     var img_ext = image[image.length - 1]
//     data = {
//       ...data,
//       isBase64:true,
//       img_name:img_name,
//       img_ext:img_ext
//     }
 
//    const request = fetch(ROOT_URL + '/api/business/upload_logo', {
//      method: 'Post',
//      mode: 'cors',
//      headers: { 'Content-Type': 'application/json' },
//      body: JSON.stringify(data)
//    }).then((response) => {
//      response.json().then(response => {
//        if (response.status == "200") {
//          console.log("upload response: ",response.data)
//          data={
//            ...data,
//            logo_url:response.data.url
//          }
//          updateBusiness(data, bussinessId)
//  ///        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: response.data, message:response.message });
//          return;
//        } else {
//       //  alert(JSON.stringify(response))
//          // alert("test")
//          store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError });
 
//        }
//      });
//    }).catch((error) => {
//      //  alert(error)
//      store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError});
//    })
//  }else
 updateBusiness(data, bussinessId)

  return { type: BusinessProfileActions.BusinessProfile_Post.LOADING }

};

function updateBusiness(data, bussinessId) {
  if(data.selectedImage != "" && (typeof data.selectedImage ) === "object")
  { 
    var image =data.selectedImage.name.split(".")
    var img_name = image[0]
    var img_ext = image[image.length - 1]
    data = {
      ...data,
      isBase64:true,
      img_name:img_name,
      img_ext:img_ext
    }
  }

  console.log("update business data", data)
  const request = fetch(ROOT_URL + '/api/business/updateBusinessById/' + bussinessId, {
    
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.SUCCESS, payload: {...response.data[1][0],business_category_names:response.business_category_names}, message:response.message });
        // return ;
      } else {
        store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:response.error, payload:data });
        // return ;
      }
    });
  }).catch((error) => {
    store.dispatch({ type: BusinessProfileActions.BusinessProfile_Post.FAILED, error:failure_messages.unExpectedError, payload:data });
  })

};