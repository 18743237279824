
//******************Exchange Rate Server Calls******************;

import { BusinessCategoryActions } from './BusinessCategoryConstants';
import store from '../../../Store/store'
import { ROOT_URL,getCookie } from '../../../Config/Config';


export const BusinessCategoryServer = {
  //  BusinessCategory:BusinessCategory,
  getBusinessCategory: getBusinessCategory,
  //  updateBusinessCategory:updateBusinessCategory
};

//****************** BusinessCategory with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function getBusinessCategory(data, token) {

  const request = fetch(ROOT_URL + '/api/configurations/getBusinessCategory', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        // console.log('Business quota details', response.data)
        console.log("time to call from db", response.data)
  //****************** responce.data contains values of business Quotas along with business crontact
  //****************** responce.CouponFaceValues contains count of generated coupon based on business_id and year_id
        //console.log(response.data)

        var BusinessCategory = response.data.map((item, index)=>{
          return { value: item.value, name: item.name }
        })

        //BusinessCategory.unshift({value:'', name: 'Select'})

        store.dispatch({ type: BusinessCategoryActions.BusinessCategory_GET.SUCCESS, payload: BusinessCategory});
        return;
      } else {
        store.dispatch({ type: BusinessCategoryActions.BusinessCategory_GET.FAILED });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: BusinessCategoryActions.BusinessCategory_GET.FAILED });
    })
  }).catch((error) => {
    store.dispatch({ type: BusinessCategoryActions.BusinessCategory_GET.FAILED });
  })
  return { type: BusinessCategoryActions.BusinessCategory_GET.LOADING }

};
