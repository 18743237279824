import { connect } from "react-redux";
import ApplicantsStatusField_View from "./ApplicantsStatusField_View";
import { ApplicantsStatusFieldServer } from "./server";
// import { ApplicantsStatusFieldActions } from "./ApplicantsStatusFieldConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    ApplicantsStatusField_Get_status:
      state.ApplicantsStatusFieldReducer.ApplicantsStatusField_Status,
      Error:state.ApplicantsStatusFieldReducer.Error,
    ApplicantsStatusField: state.ApplicantsStatusFieldReducer.ApplicantsStatusFieldDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getApplicantsStatusField: () => {
      dispatch(ApplicantsStatusFieldServer.getApplicantsStatusField());
    }
  };
};
const ApplicantsStatusFieldContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantsStatusField_View);

export default ApplicantsStatusFieldContainer;
