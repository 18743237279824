
//******************Exchange Rate Server Calls******************;

import {AdministrationLogoutActions} from './AdministrationLogoutconstants';
import store from '../../../Store/store'
import {ROOT_URL} from '../../../Config/Config';

import {failure_messages} from 'Messages';


export const AdministrationLogoutServer = {
  //  AdministrationLogout:AdministrationLogout,
  AdministrationLogout:AdministrationLogout,
  //  updateAdministrationLogout:updateAdministrationLogout
};

//******************Insert AdministrationLogouts******************;

function AdministrationLogout(data, token){
  
      const request=fetch(ROOT_URL+'/api/applicationUser/logout', {
          method: 'Post',
          mode: 'cors',
          headers: {
            'Content-Type':'application/json',
            'authorization': token
        },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
            store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.SUCCESS,payload:response});
         
             
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:AdministrationLogoutActions.AdministrationLogout.LOADING}

};
  
//******************Get AdministrationLogouts******************;


//******************Update AdministrationLogout******************;

  
// function AdministrationLogout(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.AdministrationLogout.length>0)
//              store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.SUCCESS,payload:response.AdministrationLogout});
//              else
//              store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.FAILED});
//           })
//        return {type:AdministrationLogoutActions.AdministrationLogout.LOADING}
 
//     };
  
// function updateAdministrationLogout(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:AdministrationLogoutActions.AdministrationLogout.FAILED});
//     })
// return {type:AdministrationLogoutActions.AdministrationLogout.LOADING}

// };
