import { BannerScheduleStatus, BannerScheduleActions } from './BannerScheduleConstants';

const BannerSchedule_initialState = {
    BannerSchedule_Status: BannerScheduleStatus.BannerSchedule_POST.NEW,
    BannerSchedule_Error: '',
    BannerScheduleDetails: {},
    message:""
}
export default  function  (state = BannerSchedule_initialState, action) {
    switch (action.type) {
        case BannerScheduleActions.BannerSchedule_POST.LOADING:
            return { ...state, BannerSchedule_Status: BannerScheduleStatus.BannerSchedule_POST.LOADING }
            case BannerScheduleActions.BannerSchedule_POST.NEW:
                return { ...state, BannerSchedule_Status: BannerScheduleStatus.BannerSchedule_POST.NEW, BannerScheduleDetails: [] }
    
        case BannerScheduleActions.BannerSchedule_POST.FAILED:
            return { ...state,  BannerSchedule_Status: BannerScheduleStatus.BannerSchedule_POST.FAILED, BannerSchedule_Error:action.payload.error}
        case BannerScheduleActions.BannerSchedule_POST.SUCCESS:
          
          return { ...state,  BannerSchedule_Status: BannerScheduleStatus.BannerSchedule_POST.SUCCESS, BannerScheduleDetails:action.payload.data,message:action.payload.message}
          
        
        default:
            return { ...state
            }
    }
}
