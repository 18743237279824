import React, { Component } from "react";
// import UploadBannerHeader from "../UploadBanner/UploadBannerHeader/UploadBannerHeader";
import "./ScheduleBanner.css";
import SelectSearchInput from "../Inputs/SelectSearch/SelectSearchInput";
// import CheckBoxSingle from "../Inputs/CheckBoxSingle/CheckBoxSingle";
import {  withRouter } from "react-router-dom";
// import UploadBannerCard from "../Cards/UploadBannerCard/UploadBannerCard";
import UploadBannerCardCheck from "../Cards/UploadBannerCard/UploadBannerCardCheck";
import {getWeeklyScheduledBanners} from "./BannerSchedule/server"
import DatePicker from "../Inputs/DatePicker";
import ProgressBar from "../ProgressBar/ProgressBar";
import dateFormat from "dateformat";
import RetailerListContainer from "../RetailerList/RetailerListContainer";
import BannerListContainer from "../BannerList/BannerListContainer";
import Snackbar from "@material-ui/core/Snackbar/";
import ScheduledWeekListContainer from "./ScheduledWeekList/ScheduledWeekListContainer"
import { toast } from 'react-toastify';
import Loading from '../Utils/Loading';
import moment from "moment"
import 'react-toastify/dist/ReactToastify.min.css';
export class UploadBannerSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      RetailerList: [],
      Retailer: "",
      // bannerList: props.bannerList,
      isSelectedBanners: false,
      showExpired: false,
      weekDisable: false,
      errorMsg:"",
      isWeekSelected:false,
      isCurrentWeekSelected:"",
      week:"",
      open: false,
      WeekList:[],
      isLoading:true
      
    };
    if(this.props.bannerList.length > 0)
    this.state.isLoading = false

  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

setLoading = loading =>{
  this.setState({
    isLoading:loading
  })
}
setIsCurrentWeek = (selected)=>{
  // alert(selected)
  this.setState({
    isCurrentWeekSelected:selected,
    
  })
  this.props.setIsCurrentWeek(selected)
  if(!selected)
  this.props.setSelectedBanner([])
  else
  {
    // alert(JSON.stringify(this.props.weekBanners))
    this.props.setSelectedBanner(this.props.weekBanners)
  }
}
  setRetailerList = RetailerList => {
    this.setState({ RetailerList
     });
  };
  setScheduledBanners = (scheduledBanners) =>{
    // alert(this.props.setWeekBanners)
    if(this.state.isCurrentWeekSelected){
      console.log("this.props.bannerList: ",this.props.bannerList)
      console.log("scheduledBanners: ",scheduledBanners)

      // let yFilter =  scheduledBanners.map(itemY => { return itemY.banner_id; });
      // let filteredX = this.props.bannerList.filter(itemX => yFilter.includes(itemX.banner_id));
      // console.log("filteredX ",filteredX)
    //  var data= this.props.bannerList.filter(item=>{
        
    //      for(var i = 0; i<scheduledBanners.length; i++)
    //   {
    //     if(scheduledBanners[0].banner_id == item.banner_id) 
    //     {
    //         return true
    //     }
    //   }

    //   })

      // this.props.setWeekBanners(filteredX)
    }
    // alert(JSON.stringify(scheduledBanners))

    this.props.setScheduledBanners(scheduledBanners)
  }
  setWeeks  = (weeks, getScheduledBanners = true) =>{
    this.props.setWeeks(weeks)
    // alert(JSON.stringify(getScheduledBanners))
    if(getScheduledBanners)
    {
      
    const data={
      week_start_date:dateFormat(new Date(weeks[0]),"yyyy-mm-dd"),
      week_end_date:dateFormat(new Date(weeks[6]),"yyyy-mm-dd")
    }
    
    var props = {
      setScheduledBanners:this.setScheduledBanners,
      setLoading:this.setLoading,
      bannerList:this.props.bannerList,

      addSelectedBanner:this.addSelectedBanner
    }
    // alert(JSON.stringify(this.props.bannerList))
    getWeeklyScheduledBanners(data, props)

  }

    this.setState({
    isWeekSelected:true,
      errorMsg:""
    })
  }
  setWeekDisable = (check)=>{
    this.setState({
      weekDisable:check
    })
  }
  addSelectedBanner = banner => {
    this.props.addSelectedBanner(banner)
   // this.state.selectedBanners.push(banner);
 //   this.props.cookies.remove("SelectedBanner", { path: "/" });
  //  this.props.cookies.set("SelectedBanner", this.state.selectedBanners, {
    //   path: "/"
    // });
    // this.setState({
    //   errorMsg:"",
    //   isWeekSelected:true
    // })
  };
  removeSelectedBanner = banner => {
    this.props.removeSelectedBanner(banner)
    // // alert(banner.banner_id)
    // const newArr = this.state.selectedBanners.filter(e => {
    //   return e.banner_id != banner.banner_id;
    // });
    // this.state.selectedBanners = newArr;
    //this.props.cookies.remove("SelectedBanner", { path: "/" });
   // this.props.cookies.set("SelectedBanner", newArr, { path: "/" });
    this.setState({
      errorMsg:""
    })
  };
  searchRetailerHandler = e => {
    this.setState({ Retailer: e });
    // const
    //  this.props.cookies.remove("SelectedBanner", { path: "/" });
  };

  setBannerList = (data, weekBanners =false) => {
      // alert(weekBanners)
    this.props.setBannerList(data.filter(e => {
      
      var flag = true;
      // if (new Date() < new Date(e.end_date)) flag = true;
      flag = (new Date() < new Date(e.end_date))
    
      if (this.state.Retailer != "" && this.state.Retailer != "show_all" && flag)
        flag = e.business_id == this.state.Retailer;
        // if (flag)
        // flag = e.number_of_days != e.TotalAllocated
      return flag;
    })

      )
      if(weekBanners)
      {
        // alert("true")
      }
    //   if(weekBanners)
    //  {
       
        // this.props.setWeekBanners(weekBanners)

    //  }

    this.setState({
      isLoading:false
    })
    
  };

  edit_Banner = banner => {
    // this.props.cookies.remove("banner_duplicate", { path: "/" });
    // this.props.cookies.remove("banner_edit", { path: "/" });
    // this.props.cookies.set("banner_edit", banner, { path: "/" });
    // this.props.history.push("/Upload_Banner_Form");
    // alert(JSON.stringify(banner))
  };
setWeekList = data =>{
  this.setState({
    WeekList:data
  })
}
  duplicate_Banner = banner => {
    // if (new Date() > new Date(banner.end_date)) {
    //   this.props.cookies.remove("banner_duplicate", { path: "/" });
    //   this.props.cookies.remove("banner_edit", { path: "/" });
    //   this.props.cookies.set("banner_duplicate", banner, { path: "/" });
    //   this.props.history.push("/Upload_Banner_Form");
    // }
  };

  upload_Banner = () => {
    this.props.cookies.remove("banner_duplicate", { path: "/" });
    this.props.cookies.remove("banner_edit", { path: "/" });
    this.props.history.push("/Upload_Banner_Form");
  };
  Go_Next = (bannerList) => {

    let selectedBanners = []
    // if(this.state.isCurrentWeekSelected)
    if(false)
    {
      if(this.props.weekBanners.length>0)
      {
        selectedBanners = this.props.weekBanners
        this.props.setSelectedBanner(selectedBanners)
        this.props.Go_Next()
      }
      else //if(this.state.isCurrentWeekSelected)
      toast.info("No Current week banners available")
      
    }else{
    this.props.bannerList.forEach( item =>{
      if(item.active){

        selectedBanners.push(item)
      }

    })

     if(bannerList.length > 0)
   { if(!this.state.isWeekSelected){
      toast.error("Please select a week")
      // this.setState({
      //   errorMsg:"Please select a week",
      //   open:true
      // })
    }
   else if (selectedBanners.length == 0) {
    
    toast.error("At least one banner should be selected")
    
    }
    else{
      this.props.setSelectedBanner(selectedBanners)
      this.props.Go_Next()
    }
  }
}
  };
  render() {

  const bannerList = this.props.bannerList.filter(e => {
      var flag = true;
      // if (new Date() < new Date(e.end_date)) flag = true;
      flag = (new Date() < new Date(e.end_date))
    
      if (this.state.Retailer != "" && this.state.Retailer != "show_all" && flag)
        flag = e.business_id == this.state.Retailer;
        // if(this.state.isCurrentWeekSelected !=="" && this.state.isCurrentWeekSelected == false){
                         
        //   flag = e.number_of_days != e.TotalAllocated
        // }
        // alert(flag + this.state.isCurrentWeekSelected !="" + !this.state.isCurrentWeekSelected)
        // if (flag)
        // flag = e.number_of_days != e.TotalAllocated
      return flag;
    })



    const bannerList2= this.props.bannerList
    .filter(e => {
      var flag = true;
      // if (new Date() < new Date(e.end_date)) flag = true;
      flag = (new Date() < new Date(e.end_date))
    
        if (flag)
        flag = e.number_of_days != e.TotalAllocated
      return flag;
    })

    // const weekBanners = this.props.weekBanners.filter(e => {
    //   var flag = true;
      // var flag2 = false
      
      // for(var i = 0; i<scheduledBanners.length; i++)
      // {
      //   if(scheduledBanners[0].banner_id == e.banner_id) 
      //   {
      //     flag = true
      //     break;
      //   }
      // }
    //   if (this.state.Retailer != "" && this.state.Retailer != "show_all" && flag)
    //     flag = e.business_id == this.state.Retailer;

    //   return flag;
    // })
    
    const bannerList3 = bannerList.filter(e => {
      var flag = true;

        if(this.state.isCurrentWeekSelected !=="" && this.state.isCurrentWeekSelected == false){
                         
          flag = e.number_of_days != e.TotalAllocated
        }

      return flag;
    })


    // alert(JSON.stringify(bannerList.length))
    return (
      <div className="upload-manage-wrap">
       
        <div className="upload-pro">
          <ProgressBar ProgressBar1="active" />
        </div>
        <div className="upload-manage-wrap2">
          <div className="upload-manage-container">
            <div className="upload-manage-box">
              <div className="existing-banner box-weeks">
                <div className="weekbo-2">
                <div className="existing-label">Week</div>
  
                <div className="existing-input margin">
                  {/* <DateRange /> */}
                  {/* <WeekPicker WeekList = {this.state.WeekList} weekDisable={this.state.weekDisable} setWeeks={this.setWeeks}/> */}
                  {bannerList.length >  0 || this.state.Retailer != ""?
                  <ScheduledWeekListContainer setIsCurrentWeek = {this.setIsCurrentWeek} Week = {this.props.Week} setWeeks={this.setWeeks}/>:
                  <DatePicker
                  htmlFor="custome-date-range"
                  placeholderText={ this.props.Week !=""?dateFormat(this.props.cookies.get("banner_week").week_start_date,"mm/dd/yyyy") +" - "+dateFormat(this.props.cookies.get("banner_week").week_end_date,"mm/dd/yyyy"): "mm/dd/yyyy - mm/dd/yyyy"}
                  disabled={true}
                  />
                  }
                </div>
                </div>
                <div className="weekbo-2 mar">
                <div className="existing-label">Existing Banners</div>
                <div className="existing-input">
                  <SelectSearchInput
                    placeholder="Search for Partner Banners"
                    inputValue={this.state.Retailer}
                    onChange={this.searchRetailerHandler}
                    data={this.state.RetailerList}
                  />
                  <RetailerListContainer
                    setRetailerList={this.setRetailerList}
                    showAll={true}
                  />
                </div>
                </div>
              </div>
              <div className="upload-check-box">{/* <CheckBoxSingle/> */}</div>
              {/* <div className="upload-two-button-box">
                                <div className="upload-butto1 margin">
                                <Link to="banner_Scheduler">Schedule Banners</Link>
                                </div>
                            </div> */}
            </div>
            <div className="card-list">
              <div className="upload-card-wrap">
                
                <div className="upload-card-container">
                {/* <div style={{ textAlign: "left",display:"flex", justifyContent:"flex-start", alignItems:"flex-start", width:"100%" }}>
            <Loading/>
          </div> */}
                 {
                 //!this.state.isCurrentWeekSelected
                 true?
                  <div>
                  {this.state.isLoading?
         //         <div style={{ textAlign: "left",display:"flex", justifyContent:"flex-start", alignItems:"flex-start", width:"100%" }}>
            <Loading/>
         // </div>
          :
                  <div className="upload-card-box margin-check">
                    {bannerList3.length > 0 ? (
                      
                      bannerList3
                      // .filter((item)=>{
                      //   var flag = true;
                      //   // alert(this.state.isCurrentWeekSelected)
                      //   if(this.state.isCurrentWeekSelected !=="" && this.state.isCurrentWeekSelected == false){
                         
                      //     flag = item.number_of_days != item.TotalAllocated
                      //   }
                        
                      //   return flag
                      // })
                      .map((res, index) => (
                          <div key = {res.banner_id}>

                          <UploadBannerCardCheck
                            bannerImage={res.banner_location}
                            bannerTitle={res.banner_location?
                              res.banner_location.substr(
                              res.banner_location.lastIndexOf("-") + 1
                            ):""}
                            RetailerName = {res.business_name}
                            expiryDate={moment(res.end_date).format("MM/DD/YYYY")
                              // dateFormat(res.end_date, "mm/dd/yyyy")
                            }
                            startDate={moment(res.start_date).format("MM/DD/YYYY")
                              // dateFormat(res.start_date, "mm/dd/yyyy")
                            }
                            banner={res}
                            number_of_days={res.number_of_days}
                            key={res.banner_id}
                            edit_Banner={this.edit_Banner}
                            duplicate_Banner={this.duplicate_Banner}
                            addSelectedBanner={this.props.addSelectedBanner}
                            removeSelectedBanner={this.props.removeSelectedBanner}
                            disableDelete = {true}
                            allocation ={res.TotalAllocated}
                            index = {index}
                            notes = {res.notes}
                          />
                                  
                          </div>
                        ))
                    ) : !this.state.isLoading?
                      <p className="select-empty">No Banners Uploaded yet</p>:null
                    }

                  </div>

            }
            {this.props.bannerList.length>0?null: <BannerListContainer stopLoading={true} setLoading={this.setLoading} setBannerList={this.setBannerList} />}
            </div>:
            
            <div >
                
             {/* {this.state.isLoading && this.state.isCurrentWeekSelected?
             <Loading/>:weekBanners.length>0? <h2 className="headin-curr">Scheduled Banners of Current Week</h2>:<h2 className="headin-curr">No banner scheduled for current week</h2>} */}
              <div>
              {/* {weekBanners.length>0 && !this.state.isLoading?
    weekBanners.map((res, index) => (

                          <UploadBannerCard
                          disable = {true}
                          bannerImage={res.banner_location}
                          bannerTitle={res.banner_location.substr(
                            res.banner_location.lastIndexOf("-") + 1
                          )}
                          expiryDate={dateFormat(
                            res.end_date,
                            "mm/dd/yyyy"
                          )}
                          startDate={dateFormat(
                            res.start_date,
                            "mm/dd/yyyy"
                          )}
                          banner={res}
                          number_of_days={res.number_of_days}
                          key={res.banner_id}
                          RetailerName= {res.business_name}
                          edit_Banner={this.edit_Banner}
                          duplicate_Banner={this.duplicate_Banner}
                          addSelectedBanner={this.addSelectedBanner}
                          removeSelectedBanner={this.removeSelectedBanner}
                          hideButtons={true}
                          allocation={res.TotalAllocated}
                          disableDelete={true}
                          notes = {res.notes}
                          />)):null
              } */}
              </div>
            </div>}
                  <div className="upload-form-button-box">
                        <div className="back-btn">
                          {/* <Link to="/Manage_Banner" className="back-link">
                            Back
                          </Link> */}
                        </div>
                  <div className="upload-next-btn">
                    <button id="nextBtn" onClick={()=>this.Go_Next(bannerList2)}>Next</button>
                 
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={this.state.open}
          autoHideDuration={2000}
          onClose={() => this.setState({ open: false })}
          //  TransitionComponent={<Slide {...props} direction="up" />}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.errorMsg}</span>}
        />
      </div>
    );
  }
}

export default withRouter(UploadBannerSchedule);
