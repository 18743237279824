export const DeleteCouponActions= {
	
	DeleteCoupon_Post :{
		NEW:"DeleteCoupon_Post_NEW",
		SUCCESS:"DeleteCoupon_Post_SUCCESS",
		FAILED:"DeleteCoupon_Post_FALIURE",
		LOADING:"DeleteCoupon_Post_LOADING",
	}
}


export const DeleteCouponStatus ={

	DeleteCoupon_Post :{
		NEW:"DeleteCoupon_Post_NEW",
		SUCCESS:"DeleteCoupon_Post_SUCCESS",
		FAILED:"DeleteCoupon_Post_FALIURE",
		LOADING:"DeleteCoupon_Post_LOADING",
		
	}

}
