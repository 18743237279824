import React, { Component, Fragment } from 'react'
import {ref_status} from "RefRecordsConstants"
class PausePlayCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monthData:[],
            yearData:[],
            selectedMonth:"",
            selectedYear:""
        };
    
      }
      
    render() {
      var disabled=""
      if(ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id || ref_status.SAVED === this.props.coupon.status_id)
      disabled="disabled"
        return (
            <Fragment>
            <button id={ref_status.PAUSED === this.props.coupon.status_id? "resumeBtn":"pauseBtn"} disabled={ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id || ref_status.SAVED === this.props.coupon.status_id} onClick={this.props.onClick} className={"inv-button-pause "+disabled}>
             {ref_status.PAUSED === this.props.coupon.status_id? "Resume":"Pause"}
            </button>
            
          </Fragment>
        )
    }
}

export default PausePlayCoupon
