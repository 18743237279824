
//******************Exchange Rate Server Calls******************;

import { OverridePricingActions } from './OverridePricingConstants';

import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"



export const OverridePricingServer = {
  //  getOverridePricing:getOverridePricing,
  GenerateOverridePriceCoupons: GenerateOverridePriceCoupons,
  GenerateCouponsFromDraft: GenerateCouponsFromDraft,
  createDraft: createDraft,
  updateDraft: updateDraft,
  updateExpiryDate:updateExpiryDate
};

//******************Insert OverridePricings******************;

function GenerateOverridePriceCoupons(data) {
  //alert('save')
  const request = fetch(ROOT_URL + '/api/coupons/generateOverridePriceCoupons', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response)
        //  alert('saved')
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.SUCCESS, payload: data,message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:response.error });

      }
    }).catch((error) => {
      //  alert(error)
      store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages });
    })
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages.unExpectedError });
  })
  return { type: OverridePricingActions.OverridePricing_Post.LOADING }

};


function GenerateCouponsFromDraft(data) {
  const request = fetch(ROOT_URL + '/api/coupons/GenerateOverrideCouponsFromDraft', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json', 
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response)
        //  alert('saved')
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.SUCCESS, payload: data,message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:response.error });

      }
    }).catch((error) => {
      //  alert(error)
      store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages });
    })
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages.unExpectedError });
  })
  return { type: OverridePricingActions.OverridePricing_Post.LOADING }

};


function createDraft(data) {
  const request = fetch(ROOT_URL + '/api/coupons/createDraft', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log("draft created", response)
        //  alert('saved')
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.SUCCESS, payload: data,message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:response.error });
        
      }
    }).catch((error) => {
      //  alert(error)
      store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages });
      
    })
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages.unExpectedError });
  })
  return { type: OverridePricingActions.OverridePricing_Post.LOADING }

}

function updateDraft(data) {
  const request = fetch(ROOT_URL + '/api/coupons/updateDraft', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response)
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.SUCCESS, payload: data,message:response.message });
        return;
      } else {
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:response.error });
      }
    }).catch((error) => {
      
      store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages });
    })
  }).catch((error) => {
    
    store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages.unExpectedError });
  })
  return { type: OverridePricingActions.OverridePricing_Post.LOADING }

}


function updateExpiryDate(data) {
  const request = fetch(ROOT_URL + '/api/coupons/updateExpiryDate', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response)
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.SUCCESS, payload: data,message:response.message });
        return;
      } else {
        store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:response.error });
      }
    }).catch((error) => {
      
      store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages });
    })
  }).catch((error) => {
    
    store.dispatch({ type: OverridePricingActions.OverridePricing_Post.FAILED,error:failure_messages.unExpectedError });
  })
  return { type: OverridePricingActions.OverridePricing_Post.LOADING }

}
