import { combineReducers } from 'redux';

// import {
//   NJT_ExchangeRate_Get_Reducer,
//   NJT_ExchangeRate_Post_Reducer,
// } from '../Components/ExchangeRateAdmin/reducer';
import UnitsReducer from '../Components/ExchangeRateAdmin/UnitSelector/reducer';
import { NJT_BusinessEnrollment_Post_Reducer } from '../Components/EnrollmentForm/reducer';
import BusinessProfileReducer from '../Components/BusinessProfile/BusinessProfileReducer';
import BusinessListReducer from '../Components/BusinessList/BusinessListReducer';
import BusinessContractReducer from '../Components/BusinessContract/BusinessContractReducer';
import DisableBusinessReducer from '../Components/BusinessList/DisableBusiness/DisableBusinessReducer';
import GetFaceValuesReducer from '../Components/BusinessCouponQuotas/GetFaceValues/GetFaceValuesReducer';
import BusinessCouponQuotasReducer from '../Components/BusinessCouponQuotas/BusinessCouponQuotasReducer';
import AuthenticationReducer from '../Components/Authentication/AuthenticationReducer';
import AdministrationLoginReducer from '../Components/AdministrationLoginPage/AdministrationLoginReducer';
import AdministrationLogoutReducer from '../Components/AdministrationMain/AdministrationLogout/AdministrationLogoutReducer';
import ListOfApplicantsReducer from '../Components/ListOfApplicants/reducer';
import CreateApplicantProfileReducer from '../Components/ListOfApplicants/CreateApplicantProfile/CreateApplicantProfileReducer';
import BusinessMessagesReducer from '../Components/BusinessMessages/reducer';
import UpdateApplicantsStatusReducer from '../Components/ApplicantsStatusMessage/UpdateApplicantsStatus/reducer';
import ApplicantsStatusFieldReducer from '../Components/ApplicantsStatusMessage/ApplicantsStatusField/reducer';
import InvoicesReducer from '../Components/Invoices/InvoicesReducer';
import InvoiceDetailsReducer from '../Components/InvoicesPopup/InvoiceDetailsReducer';
import UploadBannerReducer from '../Components/UploadBanner/UploadBannerReducer';
import RetailerListReducer from '../Components/RetailerList/RetailerListReducer';
import BannerListReducer from '../Components/BannerList/BannerListReducer';
import DeleteBannerReducer from '../Components/UploadBannerManage/DeleteBanner/DeleteBannerReducer';
import BannerScheduleReducer from '../Components/ScheduleBanners/BannerSchedule/BannerScheduleReducer';
import ScheduledWeekListReducer from '../Components/ScheduleBanners/ScheduledWeekList/ScheduledWeekListReducer';
import CouponImageListReducer from '../Components/UploadCoupon/CouponImageList/CouponImageListReducer';
import UploadCouponImageReducer from '../Components/UploadCoupon/UploadCouponImage/UploadCouponImageReducer';
import CouponsInventoryReducer from '../Components/OverridePricing/CouponsInventory/CouponsInventoryReducer';
import GetQuotasContractDetailsReducer from '../Components/OverridePricing/GetQuotasContractDetails/GetQuotasContractDetailsReducer';
import GetPreviousCouponsReducer from '../Components/OverridePricing/GetPreviousCoupons/GetPreviousCouponsReducer';
import GenerateCouponsReducer from '../Components/OverridePricing/GenerateCoupons/GenerateCouponsReducer';
import GetCouponImagesReducer from '../Components/OverridePricing/GetCouponImages/GetCouponImagesReducer';
import UsersInventoryReducer from '../Components/UserManagement/UsersInventory/UsersInventoryReducer';
import ChangePasswordReducer from '../Components/UserManagement/ChangePassword/ChangePasswordReducer';
import OverridePricingReducer from '../Components/OverridePricing/ManageOveridePricing/OverridePricingReducer';
import GetModeTypesReducer from '../Components/OverridePricing/GetModeTypes/GetModeTypesReducer';
import OverrideCouponsInventoryReducer from '../Components/OverridePricing/OverrideCouponsInventory/OverrideCouponsInventoryReducer';
import GetDisplayTypesReducer from '../Components/BusinessContract/GetDisplayTypes/GetDisplayTypesReducer';
import GetCouponTermsReducer from '../Components/OverridePricing/GetCouponTerms/GetCouponTermsReducer';
import ManageMessagingReducer from '../Components/ManageMessaging/ManageMessagingReducer';
import MessageHistoryReducer from '../Components/ManageMessaging/MessageHistory/MessageHistoryReducer';
import AddNewUserReducer from '../Components/UserManagement/AddNewUser/AddNewUserReducer';
import UploadSplashScreenReducer from '../Components/SplashScreen/UploadSplashScreenReducer';
import GetCouponTypesReducer from '../Components/OverridePricing/GetCouponTypes/GetCouponTypesReducer';
import GetCoupon_TypesReducer from '../Components/BusinessContract/GetCoupon_Types/GetCoupon_Types_Reducer';
import RetailerCouponsInventoryReducer from '../Components/RetailerCouponApproval/RetailerCouponsInventory/RetailerCouponsInventoryReducer';
import ApprovalCheckListReducer from '../Components/ApprovalCheckList/ApprovalCheckListReducer';
import RetailerCouponApprovalReducer from '../Components/Cards/RetailerCouponApprovalModel/RetailerCouponApproval/RetailerCouponApprovalReducer';
import UserRoleListReducer from '../Components/UserManagement/AddNewUser/UserRoleList/UserRoleListReducer';
import EndCouponReducer from '../Components/OverridePricing/EndCoupon/EndCouponReducer';
import DeleteCouponReducer from '../Components/OverridePricing/DeleteCoupon/DeleteCouponReducer';
import PausePlayCouponReducer from '../Components/OverridePricing/PausePlayCoupon/PausePlayCouponReducer';
import BusinessCategoryReducer from '../Components/BusinessProfile/BusinessCategory/BusinessCategoryReducer';
import ChangeEmailReducer from '../Components/UserManagement/ChangeEmail/ChangeEmailReducer';
import UndoDeleteCouponReducer from '../Components/OverridePricing/UndoDeleteCoupon/UndoDeleteCouponReducer';
import GetReferralInvoicesFlagReducer from "../Components/BusinessContract/GetReferralInvoicesFlag/GetReferralInvoicesFlagReducer"

const reducer = combineReducers({
  // NJT_ExchangeRate_Get_Reducer,
  // NJT_ExchangeRate_Post_Reducer,
  NJT_BusinessEnrollment_Post_Reducer,
  UnitsReducer,
  BusinessProfileReducer,
  BusinessListReducer,
  BusinessContractReducer,
  DisableBusinessReducer,
  GetFaceValuesReducer,
  BusinessCouponQuotasReducer,
  AuthenticationReducer,
  AdministrationLoginReducer,
  AdministrationLogoutReducer,
  ListOfApplicantsReducer,
  CreateApplicantProfileReducer,
  BusinessMessagesReducer,
  UpdateApplicantsStatusReducer,
  ApplicantsStatusFieldReducer,
  InvoicesReducer,
  InvoiceDetailsReducer,
  UploadBannerReducer,
  RetailerListReducer,
  BannerListReducer,
  DeleteBannerReducer,
  BannerScheduleReducer,
  ScheduledWeekListReducer,
  CouponImageListReducer,
  UploadCouponImageReducer,
  CouponsInventoryReducer,
  GetQuotasContractDetailsReducer,
  GetPreviousCouponsReducer,
  GenerateCouponsReducer,
  GetCouponImagesReducer,
  UsersInventoryReducer,
  ChangePasswordReducer,
  OverridePricingReducer,
  GetModeTypesReducer,
  OverrideCouponsInventoryReducer,
  GetDisplayTypesReducer,
  GetCouponTermsReducer,
  ManageMessagingReducer,
  MessageHistoryReducer,
  AddNewUserReducer,
  UploadSplashScreenReducer,
  GetCouponTypesReducer,
  GetCoupon_TypesReducer,
  RetailerCouponsInventoryReducer,
  ApprovalCheckListReducer,
  RetailerCouponApprovalReducer,
  UserRoleListReducer,
  EndCouponReducer,
  PausePlayCouponReducer,
  DeleteCouponReducer,
  BusinessCategoryReducer,
  ChangeEmailReducer,
  UndoDeleteCouponReducer,
  GetReferralInvoicesFlagReducer
});
export default reducer;
