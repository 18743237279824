import React, { Component } from "react";
import { ADMIN_END_BRANDING_IMAGE_URL } from "../../../Config/Config";
import { AcceptImages, AcceptVideos } from "../../../Config/Config";
let Videos = AcceptVideos.split(", ")

class RetailerCard extends Component {
  render() {
    const {
      ImageTitle = "image1.jpg",
      Image = (ADMIN_END_BRANDING_IMAGE_URL+"header-icon.png"),
      business_name = "",
    } = this.props;
   
    // let img_title = ImageTitle.split('-')
    var re = /(?:\.([^.]+))?$/;
    let ext="."+(re.exec(Image)[1])
    // if(ext)
    // {
      // let videoFlag=false
      // let buttonDisable = false
      // alert(ext)
      // alert(JSON.stringify(AcceptVideos.split(", ")))
      // if(AcceptVideos.split(", ").includes(ext)){
    // console.log("jahsdjahsdjhasd", img_title)
    return (
      <div className="retailer-card card-desi">
        <div className="image-text-tt">
          <h3>
            {business_name.length > 20
              ? business_name.slice(0, 20) + "..."
              : business_name}
          </h3>
        </div>
        <div className="retailer-b">
          {Videos.includes(ext)?
  <video controls>
  <source src={Image+'#t=5'} type="video/mp4"/>
  </video>
          :<img src={Image} alt="not found" />
            }
        </div>
        <div className="image-text">
          <div className="image-t-des">
            {ImageTitle.length > 20
              ? ImageTitle.slice(0, 20) + "..."
              : ImageTitle}
              {/* {img_title.length>6?img_title[5]+' '+img_title[6]:img_title.length===6?img_title[5]:img_title[1]} */}
          </div>
        </div>
      </div>
    );
  }
}

export default RetailerCard;
