import React, { Component } from 'react'
// import UploadBannerHeader from '../UploadBanner/UploadBannerHeader/UploadBannerHeader'
import './ScheduleBanner.css'
// import SelectSearchInput from '../Inputs/SelectSearch/SelectSearchInput'
// import CheckBoxSingle from '../Inputs/CheckBoxSingle/CheckBoxSingle'
import {  withRouter } from "react-router-dom";

import UploadBannerCard from '../Cards/UploadBannerCard/UploadBannerCard'
import DateRange from '../Inputs/DateRange/DateRange'
// import WeekPicker from './ScheduledWeekList/'

import ProgressBar from '../ProgressBar/ProgressBar'
// import dateFormat from 'dateformat'
import moment from 'moment'

export class SelectedBannerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          editMode: true,
          RetailerList: [],
          Retailer: "",
      //    bannerList: props.cookies.get("SelectedBanner"),
           selectedBanners:this.props.selectedBanners.sort(function(a, b){
            if(a.business_name.toLowerCase() > b.business_name.toLowerCase())
            return 1
            if(b.business_name.toLowerCase() > a.business_name.toLowerCase())
            return -1
            return 0
          }),
          showExpired: false,
          bannerDelted:false
        };
  }
 
  componentDidMount() {
    window.scrollTo(0, 0);
  }

edit_Banner = banner => {
    // this.props.cookies.remove("banner_duplicate", { path: "/" });
    // this.props.cookies.remove("banner_edit", { path: "/" });
    // this.props.cookies.set("banner_edit", banner, { path: "/" });
    // this.props.history.push("/Upload_Banner_Form");
    // alert(JSON.stringify(banner))
  };

duplicate_Banner = banner => {
    // if (new Date() > new Date(banner.end_date)) {
    //   this.props.cookies.remove("banner_duplicate", { path: "/" });
    //   this.props.cookies.remove("banner_edit", { path: "/" });
    //   this.props.cookies.set("banner_duplicate", banner, { path: "/" });
    //   this.props.history.push("/Upload_Banner_Form");
    // }
  };

upload_Banner = () => {
    this.props.cookies.remove("banner_duplicate", { path: "/" });
    this.props.cookies.remove("banner_edit", { path: "/" });
    this.props.history.push("/Upload_Banner_Form");
  };
  Go_Next = () =>{
    if(this.props.selectedBanners.length > 0){
        this.props.Go_Next()
    }
  }
    render() {
      const {
        selectedBanners
      } = this.state;
        return (
          <div className="upload-manage-wrap">
            {/* <UploadBannerHeader
                heading="Schedule Banners"
                /> */}
            <div className="upload-pro">
              <ProgressBar ProgressBar1="active" ProgressBar2="active" />
            </div>
            <div className="upload-manage-wrap2">
              <div className="upload-manage-container">
                <div className="upload-manage-box">
                  <div className="banner-s">
                  <div className="existing-banner second-b">
                    <div className="existing-label">Week</div>
                    <div className="existing-input margin">
                      <div className="date-rang2">
                      <DateRange
                        week_start_date={
                          this.props.cookies.get("banner_week").week_start_date
                        }
                        week_end_date={
                          this.props.cookies.get("banner_week").week_end_date
                        }
                        disabled={true}
                      />
                      </div>
                      <span className="material-icons">date_range</span>
                    </div>
                    {/* <div className="existing-label">
                                    Existing Banners
                                </div>
                                <div className="existing-input">
                                    <SelectSearchInput
                                    placeholder="Search for Retailer Banners"
                                    />
                                </div> */}
                  </div>
                  </div>
                  {/* <div className="upload-check-box "> */}
                    {/* <CheckBoxSingle/> */}
                  </div>
                  {/* <div className="upload-two-button-box">
                                <div className="upload-butto1 margin">
                                <Link to="">Schedule Banners</Link>
                                </div>
                            </div> */}
                {/* </div> */}
                <div className="card-list">
                  <div className="upload-card-wrap">
                    <div className="upload-card-container">
                      <div className="upload-card-box margin-check">
                        {selectedBanners.map((res, index) => (
                          <div key = {res.banner_id}>
                            <div className="reatiler-b b-head">
                            {index > 0 &&
                              selectedBanners[index - 1].business_name !=
                                selectedBanners[index].business_name ? (
                                <h3 className="retailer-name-h">
                                  {res.business_name}
                                </h3>
                              ) : index == 0 ? (
                                <h3 className="retailer-name-h">
                                  {res.business_name}
                                </h3>
                              ) : (
                                <div className="empty-retailer"></div>
                              )}
                              <UploadBannerCard
                                bannerImage={res.banner_location}
                                bannerTitle={res.banner_location.substr(
                                  res.banner_location.lastIndexOf("-") + 1
                                )}
                                expiryDate={moment(res.end_date).format("MM/DD/YYYY")
                                  // dateFormat(res.end_date,
                                  // "mm/dd/yyyy")
                              }
                                startDate={moment(res.start_date).format("MM/DD/YYYY")
                                //   dateFormat(
                                //   res.start_date,
                                //   "mm/dd/yyyy"
                                // )
                              }
                                banner={res}
                                number_of_days={res.number_of_days}
                                key={res.banner_id}
                                edit_Banner={this.edit_Banner}
                                duplicate_Banner={this.duplicate_Banner}
                                addSelectedBanner={this.addSelectedBanner}
                                removeSelectedBanner={this.removeSelectedBanner}
                                hideButtons={true}
                                allocation={res.TotalAllocated}
                                disableDelete={true}
                                notes = {res.notes}
                              />
                              
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="upload-form-button-box">
                        <div className="back-btn">
                          <a id="backLink" onClick={this.props.Go_Back} className="back-link">
                            Back
                          </a>
                        </div>
                        <div className="upload-next-btn">
                          <a id="nextLink" onClick={this.Go_Next}>Next</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default withRouter(SelectedBannerList)

