export const MessageHistoryActions= {
	
	
	MessageHistory_Get :{
		NEW:"MessageHistory_Get_NEW",
		SUCCESS:"MessageHistory_Get_SUCCESS",
		FAILED:"MessageHistory_Get_FALIURE",
		LOADING:"MessageHistory_Get_LOADING",
		NOT_AUTHORIZED: "MessageHistory_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
		UPDATE:"UpdateApplicantsStatus_Post_SUCCESS",
	},
	MessageHistory_Post :{
		NEW:"MessageHistory_Post_NEW",
		SUCCESS:"MessageHistory_Post_SUCCESS",
		FAILED:"MessageHistory_Post_FALIURE",
		LOADING:"MessageHistory_Post_LOADING",
		NOT_AUTHORIZED: "MessageHistory_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const MessageHistoryStatus ={

	MessageHistory_Get :{
		NEW:"MessageHistory_Get_NEW",
		SUCCESS:"MessageHistory_Get_SUCCESS",
		FAILED:"MessageHistory_Get_FALIURE",
		LOADING:"MessageHistory_Get_LOADING",
		NOT_AUTHORIZED: "MessageHistory_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
	},
	MessageHistory_Post :{
		NEW:"MessageHistory_Post_NEW",
		SUCCESS:"MessageHistory_Post_SUCCESS",
		FAILED:"MessageHistory_Post_FALIURE",
		LOADING:"MessageHistory_Post_LOADING",
		NOT_AUTHORIZED: "MessageHistory_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
