import React, {  Fragment, PureComponent } from "react";
//import './CouponImageList.css'
import { CouponImageListStatus } from "./CouponImageListConstants";
import RetailerCard from '../../Cards/UploadBannerCard/RetailerCard'
import Loading from "../../Utils/Loading";

export class CouponImageListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      Retailer: props.Retailer,
    };
     props.getCouponImageList()
  }
 
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  closePostSnackBar = () => {
    this.setState({
      open: false,
    });
    // this.props.getCouponImageList()
  };

  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.Retailer !== nextProps.Retailer){
      // alert(nextProps.Retailer)
      return{
        Retailer:nextProps.Retailer
      }
   }
   return null;
 }
  getScreen(status) {
    switch (status) {
      case CouponImageListStatus.CouponImageList_GET.NEW:
        return (
          <div className="retailer-card-box">
            <p style={{display:'none'}}>Select a Retailer</p>
          </div>
        );

      case CouponImageListStatus.CouponImageList_GET.SUCCESS:
        const CouponImageList = this.props.CouponImageList.filter((e)=>{
          var flag = true;
          if(this.state.Retailer =="" || this.state.Retailer == 'all')
          return flag
          else
          return e.business_id == this.state.Retailer
        })
        return (
          CouponImageList.length > 0 ?
          CouponImageList.map((item)=>{
            return(
              <div key = {item.coupon_image_id} className="retailer-card-box">
              <RetailerCard business_name = {item.business_name} ImageTitle = {item.name} Image = {item.image_location}/>
            </div>
            )
          }):

          <p className="emty-screen-msge">No Coupon Images uploaded yet.</p>
          
        );
        break;
      case CouponImageListStatus.CouponImageList_GET.FAILED:
        // toast.error(this.props.CouponImageList_Error)
        return (
          <Fragment>
            <p className="emty-screen-msge">No Coupon Images uploaded yet.</p>
          </Fragment>
        );

      case CouponImageListStatus.CouponImageList_GET.LOADING:
        this.state.open = true;
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Loading />
          </div>
        );
        break;
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.CouponImageList_GET_status);
  }
}

export default CouponImageListView;
