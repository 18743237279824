
//******************Exchange Rate Server Calls******************;

import { UploadCouponImageActions } from './UploadCouponImageConstants';
import store from '../../../Store/store'
import { ROOT_URL,getCookie } from '../../../Config/Config';
import {failure_messages} from "Messages"


export const UploadCouponImageServer = {
  //  getUploadCouponImage:getUploadCouponImage,
  saveUploadCouponImage: saveUploadCouponImage,
  // updateRetailerBanner: updateRetailerBanner
  ///api/business/CreateRetailerBussiness
};

//******************Insert UploadCouponImages******************;

function saveUploadCouponImage(data) {

  if(data.isBase64 && data.selectedImage != "" && (typeof data.selectedImage ) === "object")
 { 

  const request = fetch(ROOT_URL + '/api/business/upload_logo', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log("upload response: ",response.data)
        data={
          ...data,
          image_location:response.data.url
        }
        UploadCouponImage(data)
///        store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")

        store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.FAILED, error:response.error });

      }
    });
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.FAILED, error:failure_messages.unExpectedError});
  })
}else if(data.videoFlag)
{
  // alert(data.selectedImage.name)
  let formdata = new FormData();
  formdata.append('any',data.selectedImage)
  fetch(ROOT_URL + '/api/business/upload_video', {
    method: 'Post',
    mode: 'cors',
  headers: {
  'authorization':getCookie("admin_token_partner"),
  'Accept': "application/json",
  'Access-Control-Allow-Origin': "*",
  },
    body:formdata
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log("upload response: ",response.data)
        data={
          ...data,
          image_location:response.data.url
        }
        UploadCouponImage(data)
        return;
      } else {

        store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.FAILED, error:response.error });

      }
    });
  }).catch((error) => {
  
    store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.FAILED, error:failure_messages.unExpectedError});
  })
    // UploadCouponImage(data)
}
return { type: UploadCouponImageActions.UploadCouponImage_Post.LOADING }
};

function UploadCouponImage(data) {
  console.log("data:", data)
  delete data.selectedImage

  const request = fetch(ROOT_URL + '/api/coupons/uploadCouponImage', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log(response.data)
        store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.SUCCESS, payload: response.data, message:response.message });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.FAILED, error:response.error });

      }
    });
  }).catch((error) => {
    //  alert(error)
    store.dispatch({ type: UploadCouponImageActions.UploadCouponImage_Post.FAILED, error:failure_messages.unExpectedError});
  })

};



