import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import RetailerCouponImage from "./CouponImageList/RetailerCouponImage"
import UploadCouponForm from "./UploadCouponImage/UploadCouponForm"
// import UploadBannerHeader from "../UploadBanner/UploadBannerHeader/UploadBannerHeader";
export class CouponImageMainContainer
 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // editMode: true,
      // RetailerList: [],
      // Retailer: "",

      // selectedBanners: [],
      // showExpired: false,
      // bannerDelted: false,
      // errorMsg:"",
      // Week:"",
      screenState:0
    };
  }




  Go_Next = () => {
    this.setState({
        screenState: this.state.screenState + 1
    })
  
  };

  Go_Back = () => {
 
    
    this.setState({
        screenState: this.state.screenState - 1
    })
  }
  getScreen()
  {

    switch(this.state.screenState)
    {
      case 0:
        return(
          <RetailerCouponImage  cookies={this.props.cookies} Go_Next={this.Go_Next}/>
          
        )
      case 1:
        return( <UploadCouponForm cookies={this.props.cookies} Go_Back={this.Go_Back} /> )
        break;

      default:
        
        return(
            <RetailerCouponImage  cookies={this.props.cookies} Go_Next={this.Go_Next}/>      
        )

    }
  }
  render() {
    return (
        
          this.getScreen()
       
    );
  }
}

export default withRouter(CouponImageMainContainer);
