import React, { Component, Fragment } from "react";
//import './GetCoupon_Types.css'
import { GetCoupon_TypesStatus } from "./GetCoupon_Types_Constants";
import GETCOUPON_TYPES from "./GetCoupon_Types";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/";
import Loading from "../../Utils/Loading";

export class GetCoupon_Types_View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success: false,
      filterMsg:""
    };
    if(GetCoupon_TypesStatus.GetCoupon_Types_Get.NEW !==this.props.GetCoupon_Types_Status)
    props.resetReducerState()
    // else
    this.props.getGetCoupon_Types();
  }

  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };

  getScreen(status) {
    // alert(status)
    switch (status) {
      case GetCoupon_TypesStatus.GetCoupon_Types_Get.NEW:
       
                return (
                  <div style={{ textAlign: "center",display:"flex", height:"16vh", width:"100%", justifyContent:"center",alignItems:"center" }}>
                  <Loading/>
                </div>
        );

      case GetCoupon_TypesStatus.GetCoupon_Types_Get.SUCCESS:
        if(this.state.success)
     { 
      this.state.success = false
      // console.log("coupon classed", this.props.dataTable)
      //  if(this.props.dataTable.length>0)
      //  this.props.setCouponType(this.props.dataTable[0].value)
      }
        
        let dataTable = [{ value: "selectType", name: "Select Type" }]
        if(this.props.dataTable)
        dataTable = [...dataTable, ...this.props.dataTable]
        return (
          <GETCOUPON_TYPES
          dataTable={dataTable}
          CouponType={this.props.CouponType}
          CouponTypeFlag={this.props.CouponTypeFlag}
          CouponTypeErrorMsg={this.props.CouponTypeErrorMsg}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          />
        );
      case GetCoupon_TypesStatus.GetCoupon_Types_Get.FAILED:
        if(this.state.success)
        this.state.success = false
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get Offer Types</span>
              }
            />
            <GETCOUPON_TYPES
          dataTable={this.props.dataTable}
          CouponType={this.props.CouponType}
          CouponTypeFlag={this.props.CouponTypeFlag}
          CouponTypeErrorMsg={this.props.CouponTypeErrorMsg}
        
          onChange={this.props.onChange}
          disabled={this.props.disabled}
            />
          </Fragment>
        );

      case GetCoupon_TypesStatus.GetCoupon_Types_Get.LOADING:
        this.state.open = true;
        this.state.success = true
        return (
          <div style={{ textAlign: "center",display:"flex", height:"8vh", justifyContent:"center",alignItems:"center" }}>
          <GETCOUPON_TYPES
          dataTable={this.props.dataTable}
          CouponType={this.props.CouponType}
          CouponTypeFlag={this.props.CouponTypeFlag}
          CouponTypeErrorMsg={this.props.CouponTypeErrorMsg}
        
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          LoadingIcon={true}

            />
            {/* <Loading/> */}
          </div>
        );
      default:
        return <div />;
    }
  }
  render() {

    return this.getScreen(this.props.GetCoupon_Types_Status);
  }
}

export default withRouter(GetCoupon_Types_View);
