import { connect } from "react-redux";
import GetCoupon_Types_View from "./GetCoupon_Types_View";
import { GetCoupon_TypesServer } from "./server";
import { GetCoupon_TypesActions } from "./GetCoupon_Types_Constants";
const mapStateToProps = state => {
  console.log(state);
  return {
    GetCoupon_Types_Status:state.GetCoupon_TypesReducer.GetCoupon_Types_Status,
    error:state.GetCoupon_TypesReducer.error,
    dataTable:state.GetCoupon_TypesReducer.dataTable
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getGetCoupon_Types: () => {
      dispatch(GetCoupon_TypesServer.getGetCoupon_Types());
    },
    resetReducerState:()=>{
      dispatch({type:GetCoupon_TypesActions.GetCoupon_Types_Get.NEW})
    }
  };
};
const GetCoupon_TypesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetCoupon_Types_View);

export default GetCoupon_TypesContainer;
