export const ManageMessagingActions= {
	
	
	ManageMessaging_Get :{
		NEW:"ManageMessaging_Get_NEW",
		SUCCESS:"ManageMessaging_Get_SUCCESS",
		FAILED:"ManageMessaging_Get_FALIURE",
		LOADING:"ManageMessaging_Get_LOADING",
		NOT_AUTHORIZED: "ManageMessaging_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ManageMessaging_Post :{
		NEW:"ManageMessaging_Post_NEW",
		SUCCESS:"ManageMessaging_Post_SUCCESS",
		FAILED:"ManageMessaging_Post_FALIURE",
		LOADING:"ManageMessaging_Post_LOADING",
		NOT_AUTHORIZED: "ManageMessaging_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ManageMessagingStatus ={

	ManageMessaging_Get :{
		NEW:"ManageMessaging_Get_NEW",
		SUCCESS:"ManageMessaging_Get_SUCCESS",
		FAILED:"ManageMessaging_Get_FALIURE",
		LOADING:"ManageMessaging_Get_LOADING",
		NOT_AUTHORIZED: "ManageMessaging_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	ManageMessaging_Post :{
		NEW:"ManageMessaging_Post_NEW",
		SUCCESS:"ManageMessaging_Post_SUCCESS",
		FAILED:"ManageMessaging_Post_FALIURE",
		LOADING:"ManageMessaging_Post_LOADING",
		NOT_AUTHORIZED: "ManageMessaging_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
