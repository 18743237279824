
import React from 'react'
import ReactDOM from 'react-dom'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './FileUpload.css'
// import { withCookies } from "react-cookie"
const modalRoot = document.getElementById("file_upload");


export class Modal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 100,
        height: 100,
        // aspect: 1 / 1,
        showCropImage: false,
        showUploadButton: false,
      },
      imageData: '',
      // retailer_details: props.cookies.get('retailer_details'),
      showFileUpload: false,
      Data:{},
      Load:false //setLoad
    };
  }

  setLoad=(load)=>{
    this.setState({
      Load:load
    })
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        imageData: e.target.files[0]
      })

      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })

      );
      reader.readAsDataURL(e.target.files[0]);
    }

  };

  showCroppedImage = (showCropImage, showFileUpload) => {
    // alert(showCropImage)
    this.setState({
      showCropImage: showCropImage,
      showUploadButton: true,
      showFileUpload: !showFileUpload
    })
  }
  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      console.log(croppedImageUrl)
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);

      }, 'image/jpeg');
    });
  }


  setUploadImage = async () => {

    // alert(this.state.croppedImageUrl)
    
    let blob = await fetch(this.state.croppedImageUrl)
      .then(res => res.blob()
      ) // Gets the response and returns it as a blob
      .then(blob => {
        var reader = new FileReader();
        console.log("file reader",reader)
        let base64data;
        let data;

        reader.readAsDataURL(blob)
        
        reader.onloadend = () => {
          base64data = reader.result;

          console.log("img data", this.state.imageData)
            
        //   editMode: false,
        //   fileimg: reader.result,
        //   selectedImage: file,
        //   ImageErrorMsg: "",
        //   LogoSize: file.size,
        //   FileLength: extFile

        //   data = {
        //     business_id: this.state.retailer_details.business_id,
        //     selectedImage: {name:this.state.imageData.name},
        //     fileimg: base64data 
        //   };

          const fileName = this.state.imageData.name;
          const idxDot = fileName.lastIndexOf(".") + 1;
          const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

          data = {
            editMode: false,
            fileimg: base64data,
            selectedImage: this.state.imageData,
            ImageErrorMsg: "",
            LogoSize: this.state.imageData.size,
            FileLength: extFile 
          }

        //   this.setState({
        //     // Data: data,
        //     showModal: false
            
        //   })

          this.props.inputHandler(data)
          this.props.onClose()
          
          
        }



      }
      )

  }


  render() {

    const { crop, croppedImageUrl, src } = this.state;
    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.8)',
          zIndex: 999,
          minHeight: '100vh',
        }}
      >
        <div
          className="pop-mobile-file"
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '8px',
            display: 'inline-block',
            minHeight: '140px',
            margin: '1rem',
            position: 'relative',
            minWidth: '400px',
            width: '400px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
            zIndex: 99999,
          }}
        >
          <div className="file-upload-model file-upload-model">
            <div className="cross-icon-1">
              <span id="closeModal" onClick={this.props.onClose} className="material-icons">close</span>
            </div>
            <div className="file-upload">
              <h2 className="heading-home">Upload Banner Image</h2>
              <div className="input-file-new-1">
                <div className="input-file-new">
                  <div
                   style={{display: this.state.showFileUpload ? "none": "block"}} 
                  className="reeoe-handle box-upload">
                    <div className="pe-file-container-new-one">
                      <input
                        type="file"
                        id="pe-inp-btn1"
                        className="pe-inp-btn-upload"
                        onChange={this.onSelectFile}
                        accept="image/*"
                      />
                      <label
                        htmlFor="pe-inp-btn1"
                        className="pe-upload-btn-upload pe-upload-btn"
                      >
                        <i className="material-icons">photo_camera</i>
                        <div className="banner-up camera-upload-hover">Upload Image</div>
                      </label>
                    </div>
                  </div>
                  <div className="upload-instruction">
                    <p className="upload-instruction-text">
                      Note: For the optimal display of your banner it should have a width and height of 320 x 100 pixels. Other sizes will result in your image being distorted.
                      </p>
                  </div>
                </div>
                <div className="file-croping-box">
                  {src && !this.state.showCropImage && (
                    <div className="croping-image-offer-12">
                      <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                      <div className="btin">
                        <button id="crop-image" onClick={() => this.showCroppedImage(true, this.state.showFileUpload)}>
                          <span className="material-icons">crop</span> Crop Image
            </button>
                      </div>
                    </div>
                  )}
                  {this.state.showCropImage && croppedImageUrl && (
                    <div className="croping-image-offer">
                      <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />

                      <div className="btin-2">
                        <button id="again-crop-image" onClick={() => this.showCroppedImage(false, this.state.showFileUpload)}>
                          <span className="material-icons">crop</span> Crop Image
            </button>
                      </div>
                      <div className="btin">
                      <button id="upload-image" onClick={this.setUploadImage}>
                            Upload Image
                      </button>
                  {/* <UploadCouponImageContainer onClose={this.props.onClose} setUploadImage={this.setUploadImage} Data={this.state.Data} Load={this.state.Load} setLoad={this.setLoad} /> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.props.children}
        </div>
      </div>,
      modalRoot,
    )
  }
}

class FileUploadInput extends React.Component {
  state = { showModal: false }
  handleShowMessageClick = () => this.setState({ showModal: true })
  handleCloseModal = () => this.setState({ showModal: false });
  render() {
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            maxWidth: 400,
            position: 'relative',
          }}
        >
          {this.state.showModal ? (
            <Modal
              onClose={this.handleCloseModal}

              handleCloseModal={this.props.handleCloseModal}
            //   UploadCoupon={this.props.UploadCoupon}
            //   generateCouponScreen={this.props.generateCouponScreen}
            // handleCloseModal={this.handleCloseModal}
            >
            </Modal>
          ) : null}
        </div>
      </div>
    )
  }
}

export default FileUploadInput
