import { ScheduledWeekListStatus, ScheduledWeekListActions } from './ScheduledWeekListConstants';

const ScheduledWeekList_initialState = {
    ScheduledWeekList_Status: ScheduledWeekListStatus.ScheduledWeekList_GET.NEW,
    ScheduledWeekList_Error: '',
    ScheduledWeekListDetails: [],
}
export default  function  (state = ScheduledWeekList_initialState, action) {
    switch (action.type) {
        case ScheduledWeekListActions.ScheduledWeekList_GET.LOADING:
            return { ...state, ScheduledWeekList_Status: ScheduledWeekListStatus.ScheduledWeekList_GET.LOADING }
            case ScheduledWeekListActions.ScheduledWeekList_GET.NEW:
                return { ...state, ScheduledWeekList_Status: ScheduledWeekListStatus.ScheduledWeekList_GET.NEW, ScheduledWeekListDetails: [] }
    
        case ScheduledWeekListActions.ScheduledWeekList_GET.FAILED:
            return { ...state,  ScheduledWeekList_Status: ScheduledWeekListStatus.ScheduledWeekList_GET.FAILED, ScheduledWeekList_Error:action.error}
        case ScheduledWeekListActions.ScheduledWeekList_GET.SUCCESS:
          
          return { ...state,  ScheduledWeekList_Status: ScheduledWeekListStatus.ScheduledWeekList_GET.SUCCESS, ScheduledWeekListDetails:action.payload}
          
        
        default:
            return { ...state
            }
    }
}
