export const Units_Actions= {
	
	
	Units_Get :{
		NEW:"Units_Get_NEW",
		SUCCESS:"Units_Get_SUCCESS",
		FAILED:"Units_Get_FALIURE",
		LOADING:"Units_Get_LOADING",
		NOT_AUTHORIZED: "Units_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const Units_Status ={

	Units_Get :{
		NEW:"Units_Get_NEW",
		SUCCESS:"Units_Get_SUCCESS",
		FAILED:"Units_Get_FALIURE",
		LOADING:"Units_Get_LOADING",
		NOT_AUTHORIZED: "Units_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
