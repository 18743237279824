import React, { PureComponent } from 'react';
import RotateLoading from '../../../Utils/RotateLoading';
import RetailerCouponDeclineModel from '../RetailerCouponDeclineModel';
// import RetailerDelineModel from '../RetailerDeclineModel'
class RetailerCouponApproval extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collaps: true,
        };
    }
    collapsHandler = () => {
        // this.setState({ menuFlag: !this.state.menuFlag });
        this.setState({ collaps: !this.state.collaps });
      };
      declineHandler = () => {
          alert("work fine")
      }

    render() {
        return (
            <div className="inv-btn-wrap-ma">
            <div className="inv-btn-wrap">
                <nav className="navbar navbar-expand-lg status-bar">
                    <div className="collapse navbar-collapse box-colapse-status">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item dropdown">
                                <div className="nav-main-status icon_under" onClick={this.collapsHandler}>
                                    Change Status
                                    <span class="material-icons">
                                        {this.state.collaps
                                            ? 'expand_more'
                                            : 'expand_less'}
                                    </span>
                                </div>
                                <div
                                style={{
                                    display: this.state.collaps ? 'none' : 'block',
                                  }}
                                //   onClick={this.collapsHandler}
                                 className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="approval-btn">
                                        <button id="approveButton" className="app-box-btn" onClick={this.props.approveHandler}>
                                            Approve
                                        </button>
                                    </div> 
                                        <div className="approval-btn">
                                            <RetailerCouponDeclineModel
                                                declineHandler={this.props.disapproveHandler}
                                                modalTitle="Decline"
                                                id="declineBtn"
                                                modalDescription="Why to Decline"
                                                confirmButtonTitle="Decline"

                                                isEmailEnabled={this.props.isEmailEnabled}
                                                setIsEmailEnabled={this.props.setIsEmailEnabled}
                                            />
                                        </div>
                                        <div className="approval-btn">
                                            <RetailerCouponDeclineModel
                                                declineHandler={this.props.sendFeedbackHandler}
                                                modalTitle="Send Feedback"
                                                id="sendFeedbackBtn"
                                                modalDescription="Feedback"
                                                confirmButtonTitle="Okay"
                                                isEmailEnabled={this.props.isEmailEnabled}
                                                setIsEmailEnabled={this.props.setIsEmailEnabled}
                                            />
                                        </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>

                {/* <div className="decl-box">
                <RetailerCouponDeclineModel   disapproveHandler={this.props.disapproveHandler}/>
            </div>

            <div className="app-btn-box mid-btn">
                <button className="app-box-btn" onClick={this.props.approveHandler}>
                    Approve
                </button>
            </div> */}

                <div className="app-btn-box">
                    <button className="decline-box" onClick={this.props.handleShowPreviewModal}>
                        Preview
                </button>
                </div>
                <RotateLoading LoadingIcon={this.props.LoadingIcon} loaderStyle={this.props.loaderStyle} />
            </div>
            </div>
        );
    }
}


export default RetailerCouponApproval;
