export const CouponsInventoryActions= {
	
	CouponsInventory_GET :{
		NEW:"CouponsInventory_GET_NEW",
		SUCCESS:"CouponsInventory_GET_SUCCESS",
		FAILED:"CouponsInventory_GET_FALIURE",
		LOADING:"CouponsInventory_GET_LOADING",
		UPDATE:"DisableCouponsInventory_Update_SUCCESS",
	},
	
}


export const CouponsInventoryStatus ={

	CouponsInventory_GET :{
		NEW:"CouponsInventory_GET_NEW",
		SUCCESS:"CouponsInventory_GET_SUCCESS",
		FAILED:"CouponsInventory_GET_FALIURE",
		LOADING:"CouponsInventory_GET_LOADING",
	}

}
