import { connect } from "react-redux";
import AdministrationLoginView from "./AdministrationLogin_View";
import {AdministrationLoginServer} from "./server"
// import {AdministrationLoginActions} from './AdministrationLoginconstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        AdministrationLogin_GET_status:state.AdministrationLoginReducer.AdministrationLogin_Status,
        AdministrationLoginDetails:state.AdministrationLoginReducer.AdministrationLoginDetails,
        AdministrationLogin_Message:state.AdministrationLoginReducer.AdministrationLogin_Message
       // AdministrationLoginIndex:state.AdministrationLoginReducer.AdministrationLoginIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        AdministrationLogin: (data) => {

           dispatch( AdministrationLoginServer.AdministrationLogin(data) )
        },
        
    }
};
const AdministrationLoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministrationLoginView)

export default AdministrationLoginContainer;