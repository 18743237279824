import React, { Component } from 'react'
import LabelDropDownInput from '../../Inputs/LabelDropDownInput'
// import RetailerList from "../../RetailerList/RetailerListContainer";

class RetailerCouponApprovalFilter extends Component {
    constructor(props){
        super(props)
        this.state={
            Retailers:[]
        }

        
        
    }
    render() {
        
        return (
            <div className="filter-ap-box">
                <div className="filter-ap-first">
                    {/* <RetailerList /> */}
                    <LabelDropDownInput
                    label="Partner Name"
                    id="partnerName"
                    data={this.props.Retailers}
                    inputValue={this.props.SelectedPartner}
                    onChange={this.props.setPartner}
                    elipse={true}
                    />
                </div>
                <div className="filter-ap-first">
                    <LabelDropDownInput
                    label="Face Value"
                    id="faceValue"
                    data={this.props.FaceValues}
                    inputValue={this.props.SelectedFaceValue}
                    onChange={this.props.setFaceValue}
                    // SelectedFaceValue

                    />
                </div>
                <div className="filter-ap-first">
                    <LabelDropDownInput
                    label="Quota Year"
                    id="quotaYear"
                    isJSON={true}
                    data={this.props.totalYears}
                    inputValue = {this.props.year}
                    onChange={this.props.setYear}
                    selectValue={true}
                    />
                </div>
                <div className="filter-ap-first">
                    <LabelDropDownInput
                    label="Quota Month"
                    id="quotaMonth"
                    data={this.props.totalMonths}
                    isJSON={true}
                    onChange = {this.props.setMonth}
                    inputValue = {this.props.month}
                    selectValue={true}
                    />
                </div>

                <div className="filter-ap-first-button">
                    <div className="coupon-approval-filter-button">
                        <a id="applyLink" onClick={()=>this.props.applyFilterAction(true)}>Apply</a>
                    {/* to="/Upload_Banner_Form" */}
                    </div>
                </div>

            </div>
        )
    }
}

export default RetailerCouponApprovalFilter
