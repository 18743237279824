import React, { Component } from 'react'
import "../HeaderSection/Header.css"
import { Link, withRouter} from "react-router-dom";
// import logo from "../../Assets/Images/logo-2.png"
// import {withCookies} from 'react-cookie'
import { ADMIN_END_BRANDING_IMAGE_URL } from "../../Config/Config"
import AdministrationLogout from '../AdministrationMain/AdministrationLogout/AdministrationLogoutContainer'
class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            isSignIn: false,
        }
     //   alert(this.props.cookies)
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.location.pathname == "/")
        return { isSignIn: false};
        else
        return { isSignIn: true};
       
    }
    render() {
        return (
            <div className="main-header">
             <div className="adm-container-header">
                 <div className="header-in-b1">
                <div className="header-log">
                <Link className="in-header-link" to={this.state.isSignIn ?"/Admin":"/"}><img src={ADMIN_END_BRANDING_IMAGE_URL+'Njt-Logo-admin.png'} width="70px" height="70px"  alt="Logo not Find"/></Link>
                </div>
                <div className="in-header-left right">
                        <div className="in-header-btn-box">
                         { this.state.isSignIn ?
                          <AdministrationLogout
                           cookies={this.props.cookies}
                           className="inner-header-btn" /> 
                           :null
                         }
                        </div>
                    </div>
                </div>
             </div>  
            </div>
        )
    }
}

export default withRouter(Header)
