import React, {  PureComponent } from "react";

import { BusinessCouponQuotasServer } from "./server";
import "../BusinessCouponQuotasList/BusinessCouponQuotasList.css";
// const data = [
//   { yearly: "1/01/2019", monthly: "255" },
//   { yearly: "1/01/2019", monthly: "255" },
//   { yearly: "1/01/2019", monthly: "255" }
// ];

class BusinessCouponQuotasList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      BusinessProfileData: this.props.cookies.get("BusinessProfileData"),

    };
    var data = {
      setQuotas: props.setQuotas,
      business_id: this.state.BusinessProfileData.business_id,
      setRecentlySaved: props.setRecentlySaved,
      RecentlySaved:props.RecentlySaved,
      setLoading : props.setLoading,
      setListLoading : props.setListLoading
    };
     //  alert(JSON.stringify(this.state.BusinessProfileData.business_id))
     if(!props.listLoading)
    BusinessCouponQuotasServer.getBusinessCouponQuotasList(data);
  }

  componentDidMount() {
   // alert("test")

  }

  handleGetQuotas = (item, index) => {
    //alert(JSON.stringify(item))
    // alert(JSON.stringify(item))
   
    this.props.switchEditMode(true);
    this.props.getBusinessCouponQuotasList(
      item.year_id,
      this.state.BusinessProfileData.business_id,
      
    );
  };

  handleCopyQuotas = (item, index)=>{
    // alert(JSON.stringify(this.props.prevYearData))
    // alert(this.props.editMode)
    if (this.props.prevYearData.length > 0 && this.props.QuotaList.length > 0) {
      if(!this.props.editMode)
      this.props.getBusinessCouponQuotasList(
        item.year_id,
        this.state.BusinessProfileData.business_id
      );
    }
  }
  render() {
    
    return (
      <div className="qlist-main-box box13">
        <div className="qlist-box heading">
          <div className="qlist-column">
            <h5>Years</h5>
          </div>
          <div className="qlist-column" style = {{marginRight:45}}>
            <h5>Monthly Quota</h5>
          </div>
          <div className="qlist-column-last"></div>
        </div>
        <div className="qlist-main-box-box-2">
          <p style={{ color: "red" }}>{this.props.status}</p>

          {this.props.QuotaList.map((res, index) => (
            <div
              className="qlist-box padding-b"
              
              key={index}
            >
              <div className="qlist-column">{res.year}</div>
              <div className="qlist-column">{res.Total}</div>
              <div
              id="copyButton"
                className="qlist-column-last-color"
                style= {{paddingRight:10}}
                onClick={() => this.handleCopyQuotas(res, index)}
              >
                Copy
              </div>
              <div
                id="editButton"
                className="qlist-column-last-color"
                style= {{paddingRight:10}}
                onClick={() => this.handleGetQuotas(res, index)}
              >
                Edit
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default BusinessCouponQuotasList;
