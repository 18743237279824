import { UploadCouponImageStatus, UploadCouponImageActions } from './UploadCouponImageConstants';

const UploadCouponImage_initialState = {
    UploadCouponImage_Status: UploadCouponImageStatus.UploadCouponImage_Post.NEW,
    UploadCouponImage_Message: '',
    UploadCouponImageDetails: {}
}
export default function (state = UploadCouponImage_initialState, action) {
    switch (action.type) {
        case UploadCouponImageActions.UploadCouponImage_Post.LOADING:
            return { ...state, UploadCouponImage_Status: UploadCouponImageStatus.UploadCouponImage_Post.LOADING }
        case UploadCouponImageActions.UploadCouponImage_Post.NEW:
            return { ...state, UploadCouponImage_Status: UploadCouponImageStatus.UploadCouponImage_Post.NEW,  UploadCouponImageDetails: {}}
        
        case UploadCouponImageActions.UploadCouponImage_Post.FAILED:
           // alert("test")
            return { ...state, UploadCouponImage_Status: UploadCouponImageStatus.UploadCouponImage_Post.FAILED, UploadCouponImage_Message:action.error }
        case UploadCouponImageActions.UploadCouponImage_Post.SUCCESS:
            //  state.UploadCouponImageDetails.push({})
            return { ...state, UploadCouponImage_Status: UploadCouponImageStatus.UploadCouponImage_Post.SUCCESS, UploadCouponImageDetails: action.payload, UploadCouponImage_Message:action.message }
        

        default:
            return { ...state }
    }
}
