
//******************Exchange Rate Server Calls******************;

import { ApprovalCheckListActions } from './ApprovalCheckListConstants';
import store from '../../Store/store'
import { ROOT_URL ,getCookie} from '../../Config/Config';
import {failure_messages} from 'Messages'

export const ApprovalCheckListServer = {
  //  getApprovalCheckList:getApprovalCheckList,
  getApprovalCheckList: getApprovalCheckList,

};

//******************Insert ApprovalCheckLists******************;

function getApprovalCheckList() {

  const request = fetch(ROOT_URL + '/api/getApprovalChecklist', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(responseJSON => {
      console.log("Approval Check List", responseJSON.approval_checklist)
      if (responseJSON.status == "200") {

        store.dispatch({ type: ApprovalCheckListActions.ApprovalCheckList_Post.SUCCESS, payload: responseJSON.approval_checklist });
        return;
      } else {
        // alert("test")
        store.dispatch({ type: ApprovalCheckListActions.ApprovalCheckList_Post.FAILED,  payload: {}, error:responseJSON.error});

      }
    }).catch((error) => {
        console.log(error)
      store.dispatch({ type: ApprovalCheckListActions.ApprovalCheckList_Post.FAILED,payload: {}, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
      console.log(error)
    store.dispatch({ type: ApprovalCheckListActions.ApprovalCheckList_Post.FAILED, payload: {}, error:failure_messages.unExpectedError });
  })
  return { type: ApprovalCheckListActions.ApprovalCheckList_Post.LOADING }

};

//******************Get ApprovalCheckLists******************;


//******************Update ApprovalCheckList******************;
