import React, {  Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import "./BusinessCouponQuotas.css";
import BUSINESSCOUPONQUOTAS_VIEW from './BusinessCouponQuotas_View'
import BusinessCouponQuotasList from "./BusinessCouponQuotasList/BusinessCouponQuotasList";
import { BusinessCouponQuotasStatus } from './BusinessCouponQuotasconstants';
import ProgressBarFour from '../ProgressBar/ProgressBarFour'
// import ProgressBar from '../ProgressBar/ProgressBarFive'
import BusinessName from "../BusinessName/BusinessName"
class BusinesCouponQuotas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      QuotaList: [],
      editMode:false,
      prevYearData:[],
      RecentlySaved:false,
      isLoading:true,
      listLoading:false,
      override:true
    };
    props.resetReducerState()
    //props.set_Goto_ManageCoupons(true)
  }
  setListLoading = (loading) =>{
    this.setState({
      listLoading:loading
    })
  }
  setLoading = (loading) =>{
    // this.state.isLoading =loading
    this.setState({
      isLoading:loading
    })
  }
  setPrevYearData = (data) => {
    // this.state.prevYearData = data;
    this.setState({
      prevYearData:data
    })
  };
  switchEditMode = (mode) =>{

    this.state.editMode=mode
  }

  switchCopyMode = (mode) =>{
    this.state.editMode=mode
  }
  setRecentlySaved = (save) => {
   // alert(save)
      this.setState({
       RecentlySaved: save
     })
   }

setQuotas = (data, status) =>{
  this.setState({
      QuotaList:data,
      status:status
  })
}


  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin"
    });
  };
  // todo: homeHandler onClick function ..........................
resetQuotas = () =>{
  // alert(JSON.stringify(this.state.prevYearData))
  if(this.state.editMode)
    this.switchEditMode(false)
    if(this.state.prevYearData.length > 0)
    {

      this.state.RecentlySaved = false
      this.props.resetReducerState()
    }
}

componentDidMount() {
  window.scrollTo(0, 0);
}

  render() {

    // alert(this.props.Goto_ManageCoupons)
    return (
      <Fragment>
        <BusinessName/>

        <ProgressBarFour ProgressBar1='active' ProgressBar2='active' ProgressBar3='active' />
      
      <div className="bcq_container">
        <div className="bcq_header">
          <h1>Business Offer Quotas</h1>
          {/* <button className="bcq_btn bcq_btn0" onClick={this.homeHandler}>
            Admin - Home
          </button> */}
        </div>
     
        <div className="bcq_inner_container bus-center">
          <div className="qlist-main-heading">
            <div className="qlist-main-heading-box">
            <div className="qlist-main-inn-h">
              <h5>Business Offer Quotas Name</h5>
            </div>
            <div className="qlist-btn-new">
              <button id="createNew" className="qlist-btn-new-inn" onClick={this.resetQuotas}>New</button>
            </div>
          </div>
        </div>
        <div className="qlist_inner_container">
          
          {this.props.BusinessCouponQuotas_post_status != BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.LOADING?
      
           <BusinessCouponQuotasList setQuotas={this.setQuotas} QuotaList={this.state.QuotaList} status={this.state.status}
          cookies={this.props.cookies}
          switchEditMode={this.switchEditMode}
          getBusinessCouponQuotasList={this.props.getBusinessCouponQuotasList}
          setRecentlySaved={this.setRecentlySaved}
          resetQuotas = {this.resetQuotas}
          editMode={this.state.editMode}
          RecentlySaved={this.state.RecentlySaved}
          prevYearData = {this.state.prevYearData}
          setListLoading = {this.setListLoading}
          listLoading = {this.state.listLoading}
          setLoading = {this.setLoading}
          />:null}
        </div> 
        <BUSINESSCOUPONQUOTAS_VIEW 
        BusinessCouponQuotas_post_status={this.props.BusinessCouponQuotas_post_status}
        BusinessCouponQuotas={this.props.BusinessCouponQuotas}
        saveBusinessCouponQuotas={this.props.saveBusinessCouponQuotas}
        updateBusinessCouponQuotas={this.props.updateBusinessCouponQuotas}
        QuotaList={this.state.QuotaList}
        resetReducerState={this.props.resetReducerState}
        editMode={this.state.editMode}
        setRecentlySaved={this.setRecentlySaved}
        cookies={this.props.cookies}
        switchCopyMode={this.switchCopyMode}
        getBusinessCouponQuotasList={this.props.getBusinessCouponQuotasList}
        RecentlySaved={this.state.RecentlySaved}
        resetQuotas = {this.resetQuotas}
        BusinessCouponQuotas_Message ={this.props.BusinessCouponQuotas_Message}
        set_Goto_ManageCoupons={this.props.set_Goto_ManageCoupons}
        Goto_OverridePricing ={this.props.Goto_OverridePricing}
        Goto_ManageCoupons ={this.props.Goto_ManageCoupons}
        setPrevYearData = {this.setPrevYearData}
        prevYearData = {this.state.prevYearData}
        isLoading = {this.state.isLoading}
        setLoading = {this.setLoading}
        GetFaceValues = {this.props.GetFaceValues}
        />
        </div>

        </div>
        </Fragment>
    );
  }
}

export default withRouter(BusinesCouponQuotas);
