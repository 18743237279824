export const UndoDeleteCouponActions= {
	
	UndoDeleteCoupon_Post :{
		NEW:"UndoDeleteCoupon_Post_NEW",
		SUCCESS:"UndoDeleteCoupon_Post_SUCCESS",
		FAILED:"UndoDeleteCoupon_Post_FALIURE",
		LOADING:"UndoDeleteCoupon_Post_LOADING",
	}
}


export const UndoDeleteCouponStatus ={

	UndoDeleteCoupon_Post :{
		NEW:"UndoDeleteCoupon_Post_NEW",
		SUCCESS:"UndoDeleteCoupon_Post_SUCCESS",
		FAILED:"UndoDeleteCoupon_Post_FALIURE",
		LOADING:"UndoDeleteCoupon_Post_LOADING",
		
	}

}
