import { connect } from "react-redux";
import GetCouponTypesView from "./GetCouponTypes_View";
import {CouponTypesServer} from "./server"
import {CouponTypesActions} from './GetCouponTypesConstants'
const mapStateToProps = state => {
  //  console.warn(state.GetCouponTypesReducer.GetCouponTypes)
    return {
        GetCouponTypes_GET_status:state.GetCouponTypesReducer.GetCouponTypes_Status,
        CouponTypes:state.GetCouponTypesReducer.GetCouponTypes,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GetCouponTypes: ( ) => {

           dispatch( CouponTypesServer.GetCouponTypes() )
        },
        resetReducerState:async () => {

               dispatch({type:CouponTypesActions.GetCouponTypes_GET.NEW})
               
            }
    }
};
const GetCouponTypesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetCouponTypesView)

export default GetCouponTypesContainer;