import {
    UpdateApplicantsStatusStatus,
    UpdateApplicantsStatusActions
  } from "./UpdateApplicantsStatusConstants";
  
  const UpdateApplicantsStatus_initialState = {
    UpdateApplicantsStatus_Status: UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.NEW,
    UpdateMsg: "",
    UpdateApplicantsStatusDetails: []
  };
  export default function(state = UpdateApplicantsStatus_initialState, action) {
    switch (action.type) {
      case UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.LOADING:
        return {
          ...state,
          UpdateApplicantsStatus_Status:
            UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.LOADING
        };
      case UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.NEW:
        return {
          ...state,
          UpdateApplicantsStatus_Status:
            UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.NEW,
            UpdateMsg: "",
          UpdateApplicantsStatusDetails: []
        };
      case UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.SUCCESS:
        return {
          ...state,
          UpdateApplicantsStatus_Status:
            UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.SUCCESS,
          UpdateApplicantsStatusDetails: action.payload,
          UpdateMsg:action.message
        };
      case UpdateApplicantsStatusActions.UpdateApplicantsStatus_Post.FAILED:
        return {
          ...state,
          UpdateApplicantsStatus_Status:
            UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.FAILED,
            UpdateMsg:action.error
        };
  
      default:
        return {
          ...state,
         // UpdateApplicantsStatus_Error: "",
         // UpdateApplicantsStatus_Status: UpdateApplicantsStatusStatus.UpdateApplicantsStatus_Post.NEW
        };
    }
  }
  