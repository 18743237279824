import React, { Component, Fragment } from 'react';
import './AdministrationMainTwo.css';
import { Link, withRouter } from 'react-router-dom';
// import AdministrationLogoutContainer from "./AdministrationLogout/AdministrationLogoutContainer";
import { STATIC_IMAGES_URL } from '../../Config/Config';

class AdministrationMainTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  logoutHandler = () => {
    alert('logoutHandler Works');
    // this.props.history.push({
    //   pathname: "/login_page"
    // });
  };
  render() {
    return (
      <Fragment>
        <div className='header-slider'>
          <img src={STATIC_IMAGES_URL + 'admin_njtransit_banner.jpg'} alt='not found' />
        </div>
        <div className='adm_container-am'>
          <div className='admin-inner-container-am'>
<div className="container">



            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <div className='brand-icon-am'>
                  <img
                    src={STATIC_IMAGES_URL + 'portal-support-icon.svg'}
                    alt=''
                  />
                  <h1 className='adm_h1-am'>Partner Support</h1>
                </div>
              </div>
            </div>

            <div className='main-section-box'>
              <div className='row'>

              <div className='col-sm-12 col-md-6 col-lg-6'>               
                  <div className='main-col1-am margin-third'>
                    <div className='col-box'>
                      <h4 className='link-heading-am'>Partner Setup</h4>
                      <div className='link-bo am'>
                        <p className='adm-p-link margin am'>
                          <Link
                            className='adm_link1 amt'
                            to='list_of_applicants'
                            id="listofApplicants"
                          >
                            List of New Applicants
                          </Link>
                        </p>
                        <p className='adm-p-link margin am'>
                          <Link className='adm_link1 amt' id="businessList" to='/business_list'>
                            Business Participants
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


              <div className='col-sm-12 col-md-6 col-lg-6'>
                  <div className='main-col1-am'>
                    <div className='col-box'>
                      <h4 className='link-heading-am'>Offers Management</h4>
                      <div className='link-bo am'>
                        <p className='adm-p-link margin am'>
                          <Link
                            className='adm_link1 amt'
                            id="retailerCouponsForApproval"
                            to='/RetailerCouponsForApproval'
                          >
                            Partner Offer for Approval
                          </Link>
                        </p>
                        <p className='adm_link1'>
                          <Link
                            className='admmin_link1 amt'
                            to='/Upload_Coupon_Image'
                            id="uploadCouponImage"
                          >
                            Partner Offer Images
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


           

     


              </div>

              <div className='row mt-4'>

         


                <div className='col-sm-12 col-md-6 col-lg-6'>
                  <div className='main-col1-am'>
                    <div className='col-box'>
                      <h4 className='link-heading-am'>Banner Management</h4>
                      <div className='link-bo am'>
                        <p className='adm-p-link margin am'>
                          <Link className='adm_link1 amt' id="manage_Banner" to='/Manage_Banner'>
                            Manage Banners
                          </Link>
                        </p>
                        <p className='adm_link1'>
                          <Link
                            className='admmin_link1 amt'
                            to='/Banner_Scheduler'
                            id="banner_Scheduler"
                          >
                            Schedule Banners
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='col-sm-12 col-md-6 col-lg-6'>            
                  <div className='main-col1-am margin-third'>
                    <div className='col-box'>
                      <h4 className='link-heading-am'>User Management</h4>
                      <div className='link-bo am'>

                      <p className="adm-p-link margin am">
           
                     <Link className="adm_link1 amt" id="userManagement" to="/UserManagement">
                      Manage Users
                      </Link>
                    </p>
                        <p className='adm-p-link margin am'>
                          <Link className='adm_link1 amt' id="manageMessaging" to='/ManageMessaging'>
              
                            Messaging
                          </Link>
                        </p>
                        <p className='adm-p-link margin am'>
                          <Link className='adm_link1 amt' id="messageHistory" to='/MessageHistory'>
                            Messaging History
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>


            </div>

            </div>
          </div>


        </div>
      </Fragment>
    );
  }
}

export default withRouter(AdministrationMainTwo);
