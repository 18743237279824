import { UserRoleListStatus, UserRoleListActions } from './UserRoleListConstants';

const UserRoleList_initialState = {
    UserRoleList_Status: UserRoleListStatus.UserRoleList_GET.NEW,
    UserRoleList_Error: '',
    UserRoleListDetails: []
}
export default  function  (state = UserRoleList_initialState, action) {
    switch (action.type) {
        case UserRoleListActions.UserRoleList_GET.LOADING:
            return { ...state, UserRoleList_Status: UserRoleListStatus.UserRoleList_GET.LOADING }
            case UserRoleListActions.UserRoleList_GET.NEW:
                return { ...state, UserRoleList_Status: UserRoleListStatus.UserRoleList_GET.NEW, UserRoleListDetails: [] }
    
        case UserRoleListActions.UserRoleList_GET.FAILED:
            return { ...state,  UserRoleList_Status: UserRoleListStatus.UserRoleList_GET.FAILED, UserRoleList_Error:action.error}
        case UserRoleListActions.UserRoleList_GET.SUCCESS:
          
          return { ...state,  UserRoleList_Status: UserRoleListStatus.UserRoleList_GET.SUCCESS, UserRoleListDetails:action.payload}
          
        
        default:
            return { ...state,
                UserRoleList_Status: UserRoleListActions.UserRoleList_GET.NEW
            }
    }
}
