import React, { Component, Fragment } from "react";
//import './UndoDeleteCoupon.css'
import { UndoDeleteCouponStatus } from "./UndoDeleteCouponConstants";
import { withCookies } from "react-cookie";
import Loading from "../../Utils/Loading";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import UndoDeleteCoupon from "./UndoDeleteCoupon";
import {ref_status} from "RefRecordsConstants"
export class UndoDeleteCouponView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      retailer_details: props.cookies.get("BusinessProfileData"),
      UndoDeleteCoupon_Message:""
    };
    // this.props.resetReducerState();
  }
  closeSnackBar = () => {
    this.setState({
      open: false,

    });
  };
  
  showSnackBar = (text) => {
    this.setState({
      open: true,
      UndoDeleteCoupon_Message:text
    });
  };

  
  componentWillUnmount() {
    this.props.resetReducerState();
  }


  UndoDeleteCoupon = () => {

  {  var BatchStatus = this.props.ref_data.BatchStatus.find(
      (item) => item.status_id === ref_status.DELETED
    );
    if(new Date(this.props.coupon.promotion_end_date) < new Date())
    {
      BatchStatus.to_status_code= this.props.ref_data.BatchStatus[11].to_status_code
      BatchStatus.display_color =  this.props.ref_data.BatchStatus[11].display_color
    }
    var data = {
      to_status_code: BatchStatus.to_status_code,
      coupon_batch_id: this.props.coupon.coupon_batch_id,
      batch_status: ref_status.ACTIVE,
      display_color:BatchStatus.display_color,
      BussinessId: this.state.retailer_details.business_id,
    };
    // alert(JSON.stringify(data))                    disable = {this.props.ref_status.EXPIRED ==this.props.status_id || this.props.ref_status.CLOSED == this.props.status_id}
    // alert(JSON.stringify(data))
    this.state.loading = true;
    this.props.handleUndoDeleteCoupon(data);
  }
  
  };
  
  getScreen(status) {
    switch (status) {
      case UndoDeleteCouponStatus.UndoDeleteCoupon_Post.NEW:
        return (
          <UndoDeleteCoupon
            UndoDeleteCoupon={this.UndoDeleteCoupon}
            ref_data={this.props.ref_data}
            coupon={this.props.coupon}
            showSnackBar={this.showSnackBar}
          />
        );

      case UndoDeleteCouponStatus.UndoDeleteCoupon_Post.SUCCESS:
        if(this.state.loading)
       { 
         if(this.props.resetFaceValue)
        this.props.resetFaceValue()
        this.state.loading = false;
         var updatedCoupon = this.props.coupon
        updatedCoupon.to_status_code = this.props.UndoDeleteCouponDetails.to_status_code
        this.props.updateSelectedCoupon(updatedCoupon)
        updatedCoupon.status_id =  this.props.UndoDeleteCouponDetails.batch_status
       }
        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="success"
              message={this.props.UndoDeleteCoupon_Message}
            /> */}

            <UndoDeleteCoupon
              UndoDeleteCoupon={this.UndoDeleteCoupon}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
              showSnackBar={this.showSnackBar}
            />
          </Fragment>
        );
        break;
      case UndoDeleteCouponStatus.UndoDeleteCoupon_Post.FAILED:
        this.state.loading = false;
          // alert("test")
        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="error"
              message={this.props.UndoDeleteCoupon_Message}
            /> */}

            <UndoDeleteCoupon
              UndoDeleteCoupon={this.UndoDeleteCoupon}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
              showSnackBar={this.showSnackBar}
            />
          </Fragment>
        );
        break;
      case UndoDeleteCouponStatus.UndoDeleteCoupon_Post.LOADING:
        this.state.loading = true
        this.state.open = true;
        return  <button className={"inv-button-b "}>
           <Loading color={"white"}/>
            </button>
        // if (this.state.loading) return(
        //   <button className={"inv-button-b "}>
        //    <Loading color={"white"}/>
        //    </button>)
        // else
        //   return (
        //     <UndoDeleteCoupon
        //       UndoDeleteCoupon={this.UndoDeleteCoupon}
        //       ref_data={this.props.ref_data}
        //       coupon={this.props.coupon}
        //     />
          // );
      default:
        break;
    }
  }
  render() {

    return <>
      <Snackbar open={this.state.open} autoHideDuration={2000} onClose={this.closeSnackBar}>
        <Alert onClose={this.closeSnackBar} severity="error">
        {this.state.UndoDeleteCoupon_Message}
        </Alert>
      </Snackbar>
   
    {this.getScreen(this.props.UndoDeleteCoupon_post_status)}
    </>

  }
  
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default withCookies(UndoDeleteCouponView);
