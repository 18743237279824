import React, { Component, Fragment } from 'react'
//import './GetQuotasContractDetails.css'
import { GetQuotasContractDetailsStatus } from './GetQuotasContractDetailsConstants';
// import LoadingScreen from "../../utils/LoadingScreen"
// import GetQuotasContractDetails from './GetQuotasContractDetails'
// import realm from '../../utils/realm/UserSchema'
import { withCookies } from 'react-cookie';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingScreen from "../../Utils/Loading";
import LabelDropDownInput from "../../Inputs/LabelDropDownInput";


export class GetQuotasContractDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      callBack:false,
      // year:props.year,
      mount:false,
      // maxUnusedQuota:props.maxUnusedQuota
    }
    // alert(this.state.year)
    // props.resetReducerState()
    // if(!this.props.cookies.get("Coupon_Batch_Id_ForApproval"))
   this.InitializeQuotas()
  }
  closeSnackBar = () => {
    this.setState({
      open: false,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // getYearQuotas = {this.state.getYearQuotas}
    // setYearQuotas={this.setYearQuotas}
    // alert(nextProps.year+" " +  prevState.year+""+nextProps.EditMode)
    if(nextProps.getYearQuotas)
  {
    nextProps.setYearQuotas(false, true)
    if(nextProps.cookies.get("BusinessProfileData"))
    {   const data = {
         business_id: nextProps.cookies.get("BusinessProfileData").business_id,
         year:nextProps.year
       }
      //  if(nextProps.EditMode)
      nextProps.GetYearQuotasDetails(data)
        // alert(JSON.stringify(data))
     }
     
  }
  // alert(nextProps.GetQuotasContractDetails_GET_status)
  // alert(nextProps.EditMode)
  if(nextProps.GetQuotasContractDetails_GET_status === GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.UPDATE && nextProps.updateYearQuotas)
  {
    nextProps.setYearQuotas(false, false)
    if(nextProps.QuotaYears.length > 0)
    {
      // alert("uhsbd")
      // alert(nextProps.QuotaYears.length)
      nextProps.setUpdateYearQuota(nextProps.QuotaYears, nextProps.QuotasContractDetails)

    }else{
     toast.error("Could not generate coupons because it failed to get offer quotas")
    }
  }
  if(nextProps.GetQuotasContractDetails_GET_status === GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.SUCCESS && !prevState.mount)
  {
    if(nextProps.QuotaYears.length > 0)
    {

      nextProps.setYearsQuota(nextProps.QuotaYears, nextProps.QuotasContractDetails, nextProps.paymentAccount)

    }else{
     toast.error("Could not generate coupons because it failed to get offer quotas")
    }
    return{
      mount:true
    }
  }
  if(nextProps.GetQuotasContractDetails_GET_status === GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.FAILED)
  nextProps.setYearsQuota([], [])
  

    return null;
  }

  InitializeQuotas = () => {
    // alert("test")
    if(this.props.cookies.get("BusinessProfileData"))
    {   const data = {
         business_id: this.props.cookies.get("BusinessProfileData").business_id
       }
      this.props.GetQuotasContractDetails(data)
        // alert(JSON.stringify(data))
     }
  
  }
  
  componentWillUnmount(){
    this.props.resetReducerState()
  }
  getScreen(status) {
    switch (status) {
      case GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.NEW:
       
        return (
          <Fragment >
              <LoadingScreen />
          </Fragment>
        );

        case GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.UPDATE:
        
       
       
         return (
           <Fragment >
         
           </Fragment>
         );
         break;
      case GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.SUCCESS:
     
        return (
          <Fragment >
        
          </Fragment>
        );
        break;
      case GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.FAILED:
      
        return (
          <Fragment>

    
          </Fragment>
        );

      case GetQuotasContractDetailsStatus.GetQuotasContractDetails_GET.LOADING:
        return (
          // <LoadingScreen />
          <>
              <div className='outer-loader'>
              <div className='inner-loader'  style={{bottom:"55px", left:"45px"}}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          </>
        );
        break;
      default:
        break;
    }

  }
  render() {
    // alert(this.props.GetQuotasContractDetails_GET_status)
    return (
      
        this.getScreen(this.props.GetQuotasContractDetails_GET_status)
      
    )
  }
}

export default withCookies(GetQuotasContractDetailsView)
