export const PreviousCouponsActions= {
	
	GetPreviousCoupons_GET :{
		NEW:"GetPreviousCoupons_GET_NEW",
		SUCCESS:"GetPreviousCoupons_GET_SUCCESS",
		FAILED:"GetPreviousCoupons_GET_FALIURE",
		LOADING:"GetPreviousCoupons_GET_LOADING",
		UPDATE:"DisableGetPreviousCoupons_Update_SUCCESS",
	},
	
}


export const GetPreviousCouponsStatus ={

	GetPreviousCoupons_GET :{
		NEW:"GetPreviousCoupons_GET_NEW",
		SUCCESS:"GetPreviousCoupons_GET_SUCCESS",
		FAILED:"GetPreviousCoupons_GET_FALIURE",
		LOADING:"GetPreviousCoupons_GET_LOADING",
	}

}
