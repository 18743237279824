import React, { Component, Fragment } from "react";

import "../../OverridePricing/OverridePricing.css";
// import Dateformat from "dateformat";
import moment from "moment"
import UserMessage from "../../UserManagement/UserMessage";
import CouponInventoryPreviewModal from '../CouponInventoryPreviewModal'
import {coupon_transaction_subtype_id, coupon_transaction_type_id, ref_status} from 'RefRecordsConstants'
import EditIcon from '@material-ui/icons/Edit';
import RotateLoading from "../../Utils/RotateLoading";


class OverrideCouponsInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: "",
      accountFlag: false,
      modalVisible: false,
    };
  }
  getUTC_date = (date) => {
    var d = new Date(date);
    d.setDate(d.getUTCDate());
    // alert(d)
    return d;
  };
  render() {
    const { OverrideCouponsInventory, ApprovedBusiness, ref_data } = this.props;

    console.log("coupon data override", this.props)
  //  alert(JSON.stringify(OverrideCouponsInventory))
    return (
      <Fragment>
        <table className="table-p">
          <thead className="thead-all-over">
            <tr className="heading-tab">
             <th>Offer Id</th>
              <th>Offer Title</th>
              <th>Offer Type</th>              
              <th>Offer Value</th>
              {/* <th>Offer Terms</th>  coupon_batch_id */}
              <th>Cost in Points</th>
              <th>Quantity</th>
              <th>Promo Start Date Time</th>
              <th>Promo End Date Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="tbody-over">
            {OverrideCouponsInventory.map((res, index) => {
            console.log("asdjn_to_status_code: ",res.to_status_code)
              var terms = res.coupon_terms == "" ||
              res.coupon_terms == undefined ||
              res.coupon_terms == null
                ? (coupon_transaction_subtype_id.PercentageType == res.coupon_transaction_subtype_id ?(res.coupon_face_value+"%"):("$"+res.coupon_face_value))
                +
                 " off your next purchase."
                : res.coupon_terms
              
              return <tr key={res.coupon_batch_id}>
                <td>{res.coupon_batch_id}</td>
                <td>{res.offer_name ? (res.offer_name.length > 15 ? res.offer_name.slice(0,15)+"...":res.offer_name) :"---"}</td>
                <td>{res.coupon_transaction_type}</td>
                {
                  coupon_transaction_subtype_id.PercentageType == res.coupon_transaction_subtype_id ?
                  <td>{res.coupon_face_value}%</td>:
                  <td>${res.coupon_face_value}</td>
                }               
                {/* <td title={terms}>
                  {terms.length > 15
                    ? terms.slice(0, 15) + "..."
                    : terms}
                </td> */}
                <td>{parseFloat(res.cost_in_points).toLocaleString(undefined, {maximumFractionDigits:0}).replace("NaN","0")}</td>
                <td>{res.Inventory}</td>
                <td>{moment(res.promotion_start_date).format("MMMM Do YYYY") + " at " + moment(res.promotion_start_date).format("h:mm A")
                // Dateformat(res.promotion_start_date, "mmmm dS yyyy")
                }</td>
                <td>{moment(res.promotion_end_date).format("MMMM Do YYYY") + " at " + moment(res.promotion_end_date).format("h:mm A")
                // Dateformat(res.promotion_end_date, "mmmm dS yyyy")
                }</td>
                <td>
                <div key={index}>
                  <span style={{color:res.display_color}}><>
                {res.to_status_code}
                </></span></div>

                  {/* {ref_data.BatchStatus.map((item,index)=>(<div key={index}>
                  <span style={{color:new Date(res.promotion_end_date)<new Date()?ref_data.BatchStatus[11].display_color:item.display_color}}><>
                {item.status_id==res.status_id?new Date(res.promotion_end_date)<new Date()?ref_data.BatchStatus[11].to_status_code:item.to_status_code:''}
                </></span></div>))} */}
                </td>
                                <td>
                                <div className="edit-func">
                                <CouponInventoryPreviewModal   
                                edit_id={(res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE) ? res.to_status_code+'previewEditable' : res.to_status_code+'previewNon-editable'}
                                resetFaceValue={this.props.resetFaceValue} getRetailerCouponsInventory = {this.props.getRetailerCouponsInventory} ref_data = {this.props.ref_data} coupon={res} isOverride={true}/>
                      <EditIcon
                        onClick={()=>this.props.setEditCouponData(res)}
                        style={{
                          opacity: (res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE) ? 1 : 0.3,
                          pointerEvents: (res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE) ? 'cursor' : 'none'
                        }}
                        id={(res.status_id === ref_status.SAVED || res.status_id === ref_status.ACTIVE) ? res.to_status_code+'editable' : res.to_status_code+'non-editable'}
                      />
                    </div>
                 
                </td>
              </tr>
            }
            )}
          </tbody>
        </table>
        {OverrideCouponsInventory.length == 0 ? (
          <>
        {!this.props.showLoader && 
          <UserMessage message="Advanced Coupons Inventory is empty" /> 
        }
          {this.props.showLoader && 
          <UserMessage message="Advanced Coupons Inventory is empty"  LoadingIcon={true} /> 
        }
          </>
        ) : null}
      </Fragment>
    );
  }
}

export default OverrideCouponsInventory;
