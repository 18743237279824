
//******************Exchange Rate Server Calls******************;

import {BusinessEnrollment_Actions} from './constants';
import store from '../../Store/store'
import {ROOT_URL, getCookie} from '../../Config/Config';


export const BusinessEnrollmentServer = {
    getBusinessEnrollments:getBusinessEnrollments,
    saveBusinessEnrollment:saveBusinessEnrollment,
    updateBusinessEnrollement:updateBusinessEnrollement
};

//******************Insert BusinessEnrollments******************;

function saveBusinessEnrollment(data){
      const request=fetch(ROOT_URL+'/api/business/postBusinessEnrollment', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(data=>{
             if(response.status == "200"){
               
            store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Post.SUCCESS,payload:response.data});
            return ;
            }else
            if(response.status == "200"){
              store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Post.FAILED, payload:data});
              return ;
              }    
           });
          }).catch((error) => {
           store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Post.OFFLINE, payload:data});
            })
      return {type:BusinessEnrollment_Actions.BusinessEnrollment_Post.LOADING}
};
  
//******************Get BusinessEnrollments******************;

function getBusinessEnrollments(business_id){
    const request=fetch(ROOT_URL+'/api/business/getBusiness'+business_id, {
        method: 'GET',
        headers: {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8',
        'authorization':getCookie("admin_token_partner")},
        mode: 'cors',
        }).then((response)=>{
         response.json().then(data=>{
          console.log("data:......" , data.BusinessEnrollments)
           if(response.status == "200"){
          store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Get.SUCCESS,payload:data.BusinessEnrollment});
          return ;
          }else
          if(response.status=='500'){
            store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Get.FAILED});
            return ;
            }    
         });
        }).catch((error) => {
         store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Get.OFFLINE});
          })
    return {type:BusinessEnrollment_Actions.BusinessEnrollment_Get.NEW}
};



//******************Update BusinessEnrollment******************;

  
function updateBusinessEnrollement(data){
      const request=fetch(ROOT_URL+'/api/business/updateBusiness'+data._id, {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
          console.log('********'+response);
           response.json().then(data=>{
            console.log("data:......" , data.saveMedicineStatus)
             if(data.saveMedicineStatus == "SUCCESS"){
            store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Get.SUCCESS,payload:data.allMedicineList,payload3:data.updatedBusiness});
            return ;
            }else
            if(data.saveMedicineStatus=='FAILIURE'){
              store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Get.FAILED});
              return ;
              }    
           });
          }).catch((error) => {
           store.dispatch({type:BusinessEnrollment_Actions.BusinessEnrollment_Get.OFFLINE});
            })
      return {type:BusinessEnrollment_Actions.BusinessEnrollment_Get.NEW}
};
  