import React, { Component } from 'react'
//import './OverrideCouponsInventory.css'
import { OverrideCouponsInventoryStatus } from './OverrideCouponsInventoryConstants';
// import LoadingScreen from "../.utils/LoadingScreen"
import OverrideCouponsInventory from './OverrideCouponsInventory'
// import RotateLoading from "../../Utils/RotateLoading"
// import realm from '../utils/realm/UserSchema'
// import SnackBar from '../utils/SnackBar'
// import { NavigationEvents } from 'react-navigation';
// import { withCookies } from 'react-cookie';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingScreen from "../../Utils/Loading";

export class OverrideCouponsInventoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      callBack: false,
    }
    // alert(this.props.OverrideCouponsInventory)
    this.getOverrideCouponsInventory()
  }

  getOverrideCouponsInventory = () => {
    // alert("test")
    if(this.props.cookies.get("BusinessProfileData"))
 {   const data = {
      business_id: this.props.cookies.get("BusinessProfileData").business_id
    }
    this.props.getOverrideCouponsInventory(data)
    // alert(JSON.stringify(data))
  }
    // this.props.getOverrideCouponsInventory(data, realm.objects('UserToken')[0].AccessToken)
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }
  getScreen(status) {
    switch (status) {
      case OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.NEW:

        return (
        <div style={{marginTop:20}}>
         
          <LoadingScreen />
         
           </div>
        );


      case OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.SUCCESS:
          // alert(JSON.stringify(this.props.OverrideCouponsInventory))
        this.state.callBack = true
        return (

            <OverrideCouponsInventory OverrideCouponsInventory={this.props.OverrideCouponsInventory}
              navigation={this.props.navigation}
              ApprovedBusiness = {this.props.cookies.get("BusinessProfileData")}
              ref_data={this.props.ref_data}
              getRetailerCouponsInventory = {this.getOverrideCouponsInventory}
              resetFaceValue={this.props.resetFaceValue}
              setEditCouponData = {this.props.setEditCouponData}
            />
        
        );
        break;
      case OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.FAILED:
        this.state.callBack = true
        // this.props.resetReducerState()
        toast.error(this.props.OverrideCouponsInventory_Error);
        return (

            <OverrideCouponsInventory
              OverrideCouponsInventory={this.props.OverrideCouponsInventory}
            
            />
        );

      case OverrideCouponsInventoryStatus.OverrideCouponsInventory_GET.LOADING:
        return (
          <div style={{marginTop:20}}>
        
            {/* <LoadingScreen /> */}
            <OverrideCouponsInventory OverrideCouponsInventory={this.props.OverrideCouponsInventory}
              navigation={this.props.navigation}
              ApprovedBusiness = {this.props.cookies.get("BusinessProfileData")}
              ref_data={this.props.ref_data}
              getRetailerCouponsInventory = {this.getOverrideCouponsInventory}
              resetFaceValue={this.props.resetFaceValue}
              setEditCouponData = {this.props.setEditCouponData}
              showLoader={true}
            />
          
         
           
             </div>
          );
        break;
      default:
        break;
    }

  }
  render() {
    // alert(this.props.OverrideCouponsInventory_GET_status)
    return (
      

        this.getScreen(this.props.OverrideCouponsInventory_GET_status)
      
    )
  }
}

export default OverrideCouponsInventoryView
