export const BusinessCouponQuotasActions= {
	
	
	BusinessCouponQuotas_Get :{
		NEW:"BusinessCouponQuotas_Get_NEW",
		SUCCESS:"BusinessCouponQuotas_Get_SUCCESS",
		FAILED:"BusinessCouponQuotas_Get_FALIURE",
		LOADING:"BusinessCouponQuotas_Get_LOADING",
		NOT_AUTHORIZED: "BusinessCouponQuotas_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	BusinessCouponQuotas_Post :{
		NEW:"BusinessCouponQuotas_Post_NEW",
		SUCCESS:"BusinessCouponQuotas_Post_SUCCESS",
		FAILED:"BusinessCouponQuotas_Post_FALIURE",
		LOADING:"BusinessCouponQuotas_Post_LOADING",
		NOT_AUTHORIZED: "BusinessCouponQuotas_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
		Go_To_ManageCoupons:"Go_To_ManageCoupons"
	}
}


export const BusinessCouponQuotasStatus ={

	BusinessCouponQuotas_Get :{
		NEW:"BusinessCouponQuotas_Get_NEW",
		SUCCESS:"BusinessCouponQuotas_Get_SUCCESS",
		FAILED:"BusinessCouponQuotas_Get_FALIURE",
		LOADING:"BusinessCouponQuotas_Get_LOADING",
		NOT_AUTHORIZED: "BusinessCouponQuotas_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	BusinessCouponQuotas_Post :{
		NEW:"BusinessCouponQuotas_Post_NEW",
		SUCCESS:"BusinessCouponQuotas_Post_SUCCESS",
		FAILED:"BusinessCouponQuotas_Post_FALIURE",
		LOADING:"BusinessCouponQuotas_Post_LOADING",
		NOT_AUTHORIZED: "BusinessCouponQuotas_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
