import { UploadSplashScreenStatus, UploadSplashScreenActions } from './UploadSplashScreenConstants';

const UploadSplashScreen_initialState = {
    UploadSplashScreen_Status: UploadSplashScreenStatus.UploadSplashScreen_Post.NEW,
    UploadSplashScreen_Message: '',
    UploadSplashScreenDetails: false
}
export default function (state = UploadSplashScreen_initialState, action) {
    switch (action.type) {
        case UploadSplashScreenActions.UploadSplashScreen_Post.LOADING:
            return { ...state, UploadSplashScreen_Status: UploadSplashScreenStatus.UploadSplashScreen_Post.LOADING }
        case UploadSplashScreenActions.UploadSplashScreen_Post.NEW:
            return { ...state, UploadSplashScreen_Status: UploadSplashScreenStatus.UploadSplashScreen_Post.NEW,  UploadSplashScreenDetails: {}}
        
            case UploadSplashScreenActions.SplashScreen_Get.SUCCESS:
            return { ...state, UploadSplashScreen_Status: UploadSplashScreenStatus.SplashScreen_Get.SUCCESS,  UploadSplashScreenDetails: action.payload}
        
        case UploadSplashScreenActions.UploadSplashScreen_Post.FAILED:
           
            return { ...state, UploadSplashScreen_Status: UploadSplashScreenStatus.UploadSplashScreen_Post.FAILED, UploadSplashScreen_Message:action.error }
        case UploadSplashScreenActions.UploadSplashScreen_Post.SUCCESS:
        
            return { ...state, UploadSplashScreen_Status: UploadSplashScreenStatus.UploadSplashScreen_Post.SUCCESS, UploadSplashScreenDetails: action.payload, UploadSplashScreen_Message:action.message }
        

        default:
            return { ...state }
    }
}
