
import React, { PureComponent, Fragment } from 'react';

import CreatableSelect from 'react-select';
import "./SelectSearchInput.css"

const customStyles = {
  menu: (provided) => ({
    
    ...provided

  }),
  control: (provided) => ({
  ...provided,
  height:50
  }),
  singleValue: (provided) => {

    return { ...provided };
  }
}


export default class AutoCompleteInput extends PureComponent {
  
    constructor(props){
        super(props)
        this.state = {
            inputValue:"",
            focus:false
        }
    }
    
  handleChange = (newValue, actionMeta) => {
    // alert(JSON.stringify(newValue))
    // this.setState({
    //     inputValue: newValue.label,
    //     // focus:true
    // })
    this.props.onChange(newValue)
    // console.group('Value Changed');
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    
  };
  handleInputChange = (inputValue, actionMeta) => {
    
      if(actionMeta.action == "input-change")
     { 
       var flag = true
      if(this.props.maxLimit && inputValue.length>this.props.maxLimit)
      flag  = false

if(flag)
  {    var e={
        label:inputValue
      }
      this.props.onChange(e)
      //  this.setState({
      //   inputValue :inputValue,
      //   focus:false
      // })
    
    }
  }
};
checkFocus =( )=>{
  console.log("this.state.focus: ",this.state.focus)
  if(this.state.focus){
    this.setState({
      focus:false
    })
  }
}
  render() {
    let items = [
        { value: '1', label: 'Chocolate' },
        { value: '2', label: 'Strawberry' },
        { value: '3', label: 'Vanilla' }
      ]
    const {
        // errorMsg = "errorMsg",
        // flag = false,
        disabled = false,
        // onChange,
        inputValue="",
        // handleInputChange,
        data = items,
        placeholder="Search for Retailer Name",
        maxLimit
      } = this.props;
      // if(inputValue)
      // this.state.inputValue = inputValue

      // if(inputValue === undefined)
      // this.state.inputValue = ''
    // alert(this.state.focus)
    // alert(JSON.stringify(data))
    console.log("this.state.inputValue: ",this.state.inputValue)
    return (
<Fragment>
      <CreatableSelect
        isDisabled={disabled}
        blurInputOnSelect={true}
        inputValue={inputValue}
        value={inputValue}
        // onFocus={this.checkFocus}
        // onBlur={this.checkFocus}
        closeMenuOnSelect ={true}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        placeholder = {placeholder}
        openMenuOnClick={true}
        noOptionsMessage={() => null}
        className="select-search-input"
        classNamePrefix="searchPrefixInput"
        options={data}
        theme={theme => ({
          ...theme,
          
          colors: {
            ...theme.colors,
            primary25: '#F1F1F1',
            primary: '#cccccc',
            primary50: "#F1F1F1"
          },
        })}
        
        styles={customStyles}
        
      />
      {maxLimit && inputValue?
        <p className="ltai_label" style={{flex:1,alignSelf:'flex-end', textAlign:'right'}}>{inputValue.length} / {maxLimit}</p>:
        null
      }
              {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader'  style={{left:"440px", bottom:"60px"}}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}