export const GetCoupon_TypesActions= {
	
	
	GetCoupon_Types_Get :{
		NEW:"GetCoupon_Types_Get_NEW",
		SUCCESS:"GetCoupon_Types_Get_SUCCESS",
		FAILED:"GetCoupon_Types_Get_FALIURE",
		LOADING:"GetCoupon_Types_Get_LOADING",
		NOT_AUTHORIZED: "GetCoupon_Types_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	GetCoupon_Types_Post :{
		NEW:"GetCoupon_Types_Post_NEW",
		SUCCESS:"GetCoupon_Types_Post_SUCCESS",
		FAILED:"GetCoupon_Types_Post_FALIURE",
		LOADING:"GetCoupon_Types_Post_LOADING",
		NOT_AUTHORIZED: "GetCoupon_Types_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const GetCoupon_TypesStatus ={

	GetCoupon_Types_Get :{
		NEW:"GetCoupon_Types_Get_NEW",
		SUCCESS:"GetCoupon_Types_Get_SUCCESS",
		FAILED:"GetCoupon_Types_Get_FALIURE",
		LOADING:"GetCoupon_Types_Get_LOADING",
		NOT_AUTHORIZED: "GetCoupon_Types_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	GetCoupon_Types_Post :{
		NEW:"GetCoupon_Types_Post_NEW",
		SUCCESS:"GetCoupon_Types_Post_SUCCESS",
		FAILED:"GetCoupon_Types_Post_FALIURE",
		LOADING:"GetCoupon_Types_Post_LOADING",
		NOT_AUTHORIZED: "GetCoupon_Types_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
