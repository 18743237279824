import { connect } from "react-redux";
import ApprovalCheckListView from "./ApprovalCheckList_View";
import {ApprovalCheckListServer} from "./server"
import {ApprovalCheckListActions} from './ApprovalCheckListConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        ApprovalCheckList_post_status:state.ApprovalCheckListReducer.ApprovalCheckList_Status,
        ApprovalCheckList_Message:state.ApprovalCheckListReducer.ApprovalCheckList_Message,
        ApprovalCheckList:state.ApprovalCheckListReducer.ApprovalCheckListDetails,
        
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateApprovalCheckList: (data, bussinessId) => {
        //     dispatch( ApprovalCheckListServer.updateApprovalCheckList(data, bussinessId) )
        //  },

        getApprovalCheckList: () => {

           dispatch( ApprovalCheckListServer.getApprovalCheckList() )
        },
        resetReducerState: () => {
            dispatch({type:ApprovalCheckListActions.ApprovalCheckList_Post.NEW})
         },
         updateApprovalList:async(data)=>{
           await dispatch({type:ApprovalCheckListActions.ApprovalCheckList_Post.LOADING})
            dispatch({type:ApprovalCheckListActions.ApprovalCheckList_Post.UPDATE, payload:data})
         }
        
    }
};
const ApprovalCheckListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ApprovalCheckListView)

export default ApprovalCheckListContainer;