import React, { Component } from "react";
import "../ProgressBar/ProgressBar.css"
import { Link} from "react-router-dom";

class ProgressBar extends Component {
  constructor(props){
    super(props)
    this.state={
      ProgressBar1:props.ProgressBar1,
      ProgressBar2:props.ProgressBar2,
      ProgressBar3:props.ProgressBar3,
      
    }
  }

  render() {

    return (
      <div className="conatiner-progresbar">
        <ul id="progressbar" className="three">
          <li className={this.state.ProgressBar1}><Link to="" href="">1</Link></li>
          <li  className={this.state.ProgressBar2}><Link to="" href="">2</Link></li>
          <li className={this.state.ProgressBar3}><Link to="" href="">3</Link></li>
        </ul>
      </div>
    );
  }
}

export default ProgressBar;
