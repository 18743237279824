export const CouponTypesActions= {
	
	GetCouponTypes_GET :{
		NEW:"GetCouponTypes_GET_NEW",
		SUCCESS:"GetCouponTypes_GET_SUCCESS",
		FAILED:"GetCouponTypes_GET_FALIURE",
		LOADING:"GetCouponTypes_GET_LOADING",
		UPDATE:"DisableGetCouponTypes_Update_SUCCESS",
	},
	
}


export const GetCouponTypesStatus ={

	GetCouponTypes_GET :{
		NEW:"GetCouponTypes_GET_NEW",
		SUCCESS:"GetCouponTypes_GET_SUCCESS",
		FAILED:"GetCouponTypes_GET_FALIURE",
		LOADING:"GetCouponTypes_GET_LOADING",
	}

}
