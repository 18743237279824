import React from 'react';
import ReactDOM from 'react-dom';
import LabelTextAreaInput from '../../Inputs/LabelTextAreaInput';
import './RetailerCouponApprovalModel.css';
import RetailerCouponApprovalContainer from './RetailerCouponApproval/RetailerCouponApprovalContainer';
// import Dateformat from "dateformat"
// import moment from "moment"
import { ADMIN_END_BRANDING_IMAGE_URL, ROOT_URL, getCookie } from '../../../Config/Config';
import moment from 'moment';
import {Modal as PreviewModal} from '../PreviewNJTCouponCard';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from '@material-ui/core';
import { coupon_transaction_type_id, coupon_transaction_subtype_id} from 'RefRecordsConstants';
// import { ref_status} from "RefRecordsConstants"
// import Loading from '../../Utils/Loading';
import { CSVLink } from 'react-csv';

const modalRoot = document.getElementById('coupons_approval');
//const couponPreview = document.getElementById("preview-card");

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      imgURL: '',
      csvdata: {
        data: [],
        headers: [
          { label: 'Offer Codes', key: 'coupon_codes' }
        ],
        filename: 'OfferCode.csv',
      },
    };
    

    var imgUrl = '';
    if (props.image_location && props.image_location !== '')
      imgUrl = props.image_location;
    else if (props.logo_url && props.logo_url !== '') {
      imgUrl = props.logo_url;
    } else imgUrl = ADMIN_END_BRANDING_IMAGE_URL + 'Njt-Logo-All.png';
    // alert(props.ValidFor)
    this.getImageData(imgUrl);
  }

  getImageData = async (imgUrl) => {
    try {
      this.state.loading = true;
      let image = await require('axios')({
        method: 'post',
        url: ROOT_URL + '/api/business/download_image',
        data: {
          url: imgUrl, // This is the body part
        },
        headers: {
          authorization: getCookie('admin_token_partner'),
        },
      });

      this.setState({
        imgURL: 'data:image/jpeg;base64,' + image.data.imageData,
        loading: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };

  CapitalizedText = (text) => {
    text = text.charAt(0).toUpperCase() + text.substring(1);
    return text;
  };

  getUTC_date = (date) => {
    var d = new Date(date);
    d.setDate(d.getUTCDate());

    return d;
  };

  capitalizeTerms = (text ) => {
    var couponTerms =text?text.trim():"";
    couponTerms =
      couponTerms.charAt(0).toUpperCase() + couponTerms.substring(1);
    couponTerms = couponTerms.replace(/([^.])$/, '$1.');
    return couponTerms;
  };

  checkDaysSelected = () => {
    var days = [];
    // alert(JSON.stringify(this.props.coupon))
    if (this.props.coupon.valid_monday) days.push('Monday');
    if (this.props.coupon.valid_tuesday) days.push('Tuesday');
    if (this.props.coupon.valid_wednesday) days.push('Wednesday');
    if (this.props.coupon.valid_thursday) days.push('Thursday');
    if (this.props.coupon.valid_friday) days.push('Friday');
    if (this.props.coupon.valid_saturday) days.push('Saturday');
    if (this.props.coupon.valid_sunday) days.push('Sunday');
    if (days.length > 0 && days.length < 7) return days;
    else return false;
  };

  ValidateURL = (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '(http?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return pattern.test(str);
  };

  singlecCouponCSVDownload = () => {
    const {coupon}=this.props;
    if (coupon.coupon_code) {
      const headers = [
        { label: 'Offer Code', key: 'coupon_code' }
      ];

      let data = []
      data = [...data, {"coupon_code":coupon.coupon_code}]

      const csvReport = {
        data: data,
        headers: headers,
        filename: 'offerCode.csv'
      };
      this.setState({
        csvdata: csvReport,
      })
    }
    else {
    }
  }

  getFormattedAddress = () => {
    if (!this.props.coupon.display_address_on_coupon) return null;

    if (this.ValidateURL(this.props.coupon.business_street_1))
      return this.props.coupon.business_street_1;
    else
      return (
        this.props.coupon.business_street_1 +
        ',' +
        (this.props.coupon.business_street_2
          ? ' ' + this.props.coupon.business_street_2 + ','
          : '') +
        ' ' +
        this.props.coupon.city +
        ' ' +
        this.props.coupon.state +
        ' ' +
        this.props.coupon.zip5
      );
  };

  getFormattedCouponTag = () =>{

    let Display_Coupon_Tag = '';
    try {
      Display_Coupon_Tag = JSON.parse(this.props.ref_data.Display_Coupon_Tag);
    } catch (e) {
      Display_Coupon_Tag = false;
    }

    console.table("coupon trx", Display_Coupon_Tag, this.props.coupon.coupon_transaction_type_id, this.props.coupon.coupon_transaction_subtype_id)

    if(Display_Coupon_Tag){
      if(this.props.coupon.coupon_transaction_type_id === coupon_transaction_type_id.prepaid){
        return "PREPAID"
      }
      else if(this.props.coupon.coupon_transaction_type_id === coupon_transaction_type_id.discount){
        if(this.props.coupon.coupon_transaction_subtype_id === coupon_transaction_subtype_id.PercentageType)
          return "PERCENT DISCOUNT"
        else
          return "DOLLAR DISCOUNT" 
      }
      else if (this.props.coupon.coupon_transaction_type_id === coupon_transaction_type_id.buy_x_gey_y)
        return "BUY X GET Y FREE"
    }
    else
      return null

  }


  render() {
    
    const { coupon, ref_data } = this.props;
    // alert(coupon.coupon_code_file_url)
    console.log('coupon data', coupon);
    console.log('jahsdjhabsdjhas', this.props.coupon.coupon_code_file_url);
    var statusDetails = ref_data.BatchStatus.find((item) => {
      return item.status_id === coupon.status_id;
    });

    const terms =
      coupon.coupon_term == '' ||
      coupon.coupon_term == undefined ||
      coupon.coupon_term == null
        ? '$' + coupon.face_value + ' off your next purchase.'
        : coupon.coupon_term;
    var imgUrl = '';
    // if (coupon.image_location && coupon.image_location !== '')
    //   imgUrl = coupon.image_location;
    if (coupon.logo_url && coupon.logo_url !== '') {
      imgUrl = coupon.logo_url;
    } else imgUrl = ADMIN_END_BRANDING_IMAGE_URL + 'Njt-Logo-All.png';

    var Display_Coupon_Tag = '';
    try {
      Display_Coupon_Tag = JSON.parse(ref_data.Display_Coupon_Tag);
    } catch (e) {
      Display_Coupon_Tag = false;
    }
let couponCodeData

// alert(JSON.stringify(coupon.logo_url))
    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          minHeight: '100vh',
          zIndex: '9',
        }}
        // onClick={onClose}
      >
        <div
          className='resp-p-new'
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '20px',
            display: 'inline-block',
            minHeight: '200px',
            margin: '1rem',
            position: 'relative',
            minWidth: '300px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
            width: '40%',
          }}
        >
          <div className='approvl-model-box'>
            <button id="closeModal" className='cros-icon-model' onClick={this.props.onClose}>
              <span class='material-icons'>close</span>
            </button>
            <div className='app-model-inner-box'>
              <div className='app-model-head'>
                <div className='col-app-img'>
                  <img src={imgUrl} alt='not found' />
                </div>
                <div className='app-head-col2'>
                  <span>
                  Offer For Approval
                    {/* {coupon.offer_name ? coupon.offer_name : '- -'} */}
                 
                  </span>
                </div>
              </div>

              {/* <div className='row'>
                <div className='col-sm-12'>
                  <div className='export-rca'>

                
                  </div>
                </div>
              </div> */}

              <div className='inv-model-box'>
                <div className='inv-model-inner'>
                <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Partner Name</div>
                    <div className='inv-card-right'>{coupon.business_name}</div>
                  </div>
                  <div className='inv-card-feild-text-area'>
                    <div className='inv-card-txt'>
                      <LabelTextAreaInput
                        disabled={true}
                        inputValue={coupon.offer_name ? coupon.offer_name : '- -'}
                        label='Offer Title'
                      />
                    </div>
                  </div>
                  <div className='inv-card-feild-text-area'>
                    <div className='inv-card-txt'>
                      <LabelTextAreaInput
                        disabled={true}
                        inputValue={coupon.offer_description ? coupon.offer_description : (coupon.description? coupon.description:"---")}
                        label='Offer Description'
                      />
                    </div>
                  </div>
                  {coupon.legalese && coupon.legalese.trim() != '' ? (
                    <div className='inv-card-feild-text-area'>
                      <div className='inv-card-txt'>
                        <LabelTextAreaInput
                          disabled={true}
                          inputValue={coupon.legalese}
                          label='Terms & Conditions'
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Start Date</div>
                    <div className='inv-card-right'>
                      {
                        moment(coupon.promotion_start_date).format('MM/DD/YYYY')
                      }{' '}
                      at {moment(coupon.promotion_start_date).format('h:mm A')}
                    </div>
                  </div>
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>
                      {coupon.coupon_transaction_type_id !== 2
                        ? 'End Date'
                        : 'Must Be Used Before'}{' '}
                    </div>
                    <div className='inv-card-right'>
                      {
                        moment(coupon.promotion_end_date).format('MM/DD/YYYY') + " at " +moment(coupon.promotion_end_date).format("h:mm A")
                     
                      }
                    </div>
                  </div>
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Offer Type</div>
                    <div className='inv-card-right'>
                    {coupon.coupon_transaction_type}
                    </div>
                  </div>
                  <div className='inv-card-feild-box'>
                    
                    <div className='inv-card-left'>
                      {
                        parseInt(coupon.coupon_transaction_type_id) === coupon_transaction_type_id.prepaid ?
                          "Face Value" : "Discount Face Value"
                      }
                    </div>


                    <div className='inv-card-right'>
                    {
              parseInt(coupon.coupon_transaction_subtype_id) == coupon_transaction_subtype_id.PercentageType ?
              <>{coupon.face_value}%</>:
              <>${coupon.face_value}</>
            }
                    </div>
                  </div>
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Quantity</div>
                    <div className='inv-card-right'>
                      {coupon.coupon_quantity}
                    </div>
                  </div>
                  <div className='inv-card-feild-box' style={{ display: "flex", alignItems: "center" }}>
                    <div className='inv-card-left'>Offer Code Options</div>
                    <div className='inv-card-right'>

                      {coupon.coupon_code_file_url && coupon.coupon_code_file_url != "" ?
                        <a href={coupon.coupon_code_file_url} download style={{ textDecoration: "none" }}>
                          <Button
                            variant="contained" color="default"
                            startIcon={<GetAppIcon />}
                          >
                            Offer Codes.csv
                          </Button>
                        </a>
                        :
                        coupon.coupon_code && coupon.coupon_code != "" ?
                          // <CSVLink aria-label="Offer CSV Download" {...this.state.csvdata}>
                          //   <button className="material-icons csv-nt"
                          //     aria-label="Offer CSV Download"
                          //     disabled={coupon.coupon_code?.length == 0 ? true : false}
                          //     id={`download${coupon.status_id}`}
                          //     onClick={() => this.singlecCouponCSVDownload()}
                          //   >file_download</button>
                          // </CSVLink>
                          coupon.coupon_code
                          : "Auto Generated"
                      }
                    </div>
                  </div>
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Redemption URL</div>
                    <div className='inv-card-right'>
                    {coupon.redemption_url ? coupon.redemption_url : '- -'}
                    </div>
                  </div>
                  {/* <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Query String</div>
                    <div className='inv-card-right'>
                    ?product=coffee
                    </div>
                  </div>
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Append Offer Code</div>
                    <div className='inv-card-right'>
                    Yes
                    </div>
                  </div> */}
                  <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Status</div>
                    <div className='inv-card-right'>
                      <span style={{ color: statusDetails.display_color }}>
                        {statusDetails.to_status_code}
                      </span>
                    </div>
                  </div>
                  
                  {/* <div className='inv-card-feild-box'>
                    <div className='inv-card-left'>Inventory</div>
                    <div className='inv-card-right'>
                      {coupon.coupon_quantity}
                    </div>
                  </div> */}
                  <div className='inv-card-feild-text-area'>
                    <div className='inv-card-txt'>
                      <LabelTextAreaInput
                        disabled={true}
                        inputValue={this.capitalizeTerms(terms)}
                        label='Offer terms'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <RetailerCouponApprovalContainer
                getRetailerCouponsInventory={
                  this.props.getRetailerCouponsInventory
                }
                handleCloseModal={this.props.onClose}
                coupon_batch_id={coupon.coupon_batch_id}
                activePage={this.props.activePage}
                resetFilterStates={this.props.resetFilterStates}
                coupon={coupon}
                handleShowPreviewModal={this.props.handleShowPreviewModal}
                formattedTerms={
                  terms && terms.length > 0
                    ? terms
                    : '$' +
                      coupon.coupon_face_value +
                      ' off your next purchase.'
                }
                formattedCouponTag={this.getFormattedCouponTag()}
                formattedAddress={this.getFormattedAddress()}
                formattedPoints={
                    parseInt(coupon.coupon_transaction_type_id) === coupon_transaction_type_id.prepaid ?
                     '$'+ coupon.face_value
                    :
                      parseFloat(coupon.points).toFixed(0) +' pts'
                }
                showPointStarIcon={parseInt(coupon.coupon_transaction_type_id) === coupon_transaction_type_id.prepaid ? false: true}
                description={coupon.description}
                company_url={coupon.company_url}
                
              />
            </div>
          </div>
          {this.props.children}
        </div>
                {this.props.showPreviewModal?
        <PreviewModal
        RewardURL={coupon.rewards_page_offer_url}
        WalletURL={coupon.wallet_page_offer_url}
        showRedemptionUrl={coupon.show_code_in_redemption_url}
        showQueryString={false}
        queryString=""
        redemption_message=""
          image_location={coupon.media_location?coupon.media_location.split(";;;"):[]}
          coupon_face_value={coupon.face_value}
          CouponTerms={terms}
          business_name={coupon.business_name}
          threshold={ref_data.threshold}
          Quantity={coupon.coupon_quantity}
          expiryDate={moment(coupon.promotion_end_date).format('MM/DD/YYYY h:mm A')}
          redemption_end_date={moment(coupon.promotion_end_date).format('MM/DD/YYYY h:mm A')}
          fromInventory={true}
          points={coupon.points}
          onClose={this.props.handleClosePreviewModal}
          ValidFor={this.checkDaysSelected}
          Display_Coupon_Tag={ref_data.Display_Coupon_Tag}
          logo_url={coupon.logo_url}
          business_logo_url={coupon.logo_url}
          coupon_transaction_type_id={coupon.coupon_transaction_type_id}
          coupon_transaction_subtype_id={coupon.coupon_transaction_subtype_id}
          legalese={coupon.legalese}
          display_address_on_coupon={coupon.display_address_on_coupon}
          // Function and Variable to Show Preview Modal or Not
          handleClosePreviewModal={this.props.handleClosePreviewModal}
          showPreviewModal={this.props.showPreviewModal}
          business_street_1={coupon.business_street_1}
          business_street_2={coupon.business_street_2}
          referral_fee_fixed={coupon.referral_fee_fixed}
          referral_fee_percent={coupon.referral_fee_percent}
          referral_fee_type_fixed={coupon.referral_fee_type_fixed}
          referral_fee_type_percent={coupon.referral_fee_type_percent}
          state={coupon.state}
          city={coupon.city}
          zip5={coupon.zip5}
          zip4={coupon.zip4}
          offer_name={coupon.offer_name}
          company_url={coupon.rewards_page_offer_url?coupon.rewards_page_offer_url:coupon.company_url}
          business_description={coupon.offer_description?coupon.offer_description:coupon.description}
          offerDescription={coupon.offer_description?coupon.offer_description:coupon.description}
          coupon_display_behavior_id={coupon.coupon_display_behavior_id}
          coupon_display_behavior={coupon.coupon_display_behavior}
          coupon_code={coupon.coupon_code}
          redemption_url={coupon.redemption_url}
          show_code_in_redemption_url={coupon.show_code_in_redemption_url}
        />:null}
      </div>,
      modalRoot
    );
  }
}

class RetailerCouponApprovalModel extends React.Component {
  state = {
    showModal: false,
    showPreviewModal: false,
  };
  handleShowMessageClick = () => this.setState({ showModal: true });
  handleCloseModal = () => this.setState({ showModal: false });

  handleShowPreviewModal = () => this.setState({ showPreviewModal: true });
  handleClosePreviewModal = () => this.setState({ showPreviewModal: false });

  render() {

    console.log('coupon data', this.props.coupon)
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          //   justifyContent: 'center',
          //   alignItems: 'center',
        }}
      >
        <div className='app-dec-line-model'>
          <button id="previewButton" className='mat-crcle' onClick={this.handleShowMessageClick}>
            <span
              // onClick={()=>this.props.openCouponModel(coupon)}
              className='material-icons'
            >
              fiber_manual_record
            </span>
          </button>
          {this.state.showModal ? (
            <Modal
              getRetailerCouponsInventory={
                this.props.getRetailerCouponsInventory
              }
              onClose={this.handleCloseModal}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
              activePage={this.props.activePage}
              resetFilterStates={this.props.resetFilterStates}
              handleShowPreviewModal={this.handleShowPreviewModal}
              handleClosePreviewModal={this.handleClosePreviewModal}
              showPreviewModal={this.state.showPreviewModal}
            ></Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

export default RetailerCouponApprovalModel;
