export const UsersInventoryActions= {
	
	UsersInventory_GET :{
		NEW:"UsersInventory_GET_NEW",
		SUCCESS:"UsersInventory_GET_SUCCESS",
		FAILED:"UsersInventory_GET_FALIURE",
		LOADING:"UsersInventory_GET_LOADING",
		UPDATE:"DisableUsersInventory_Update_SUCCESS",
	},
	
}


export const UsersInventoryStatus ={

	UsersInventory_GET :{
		NEW:"UsersInventory_GET_NEW",
		SUCCESS:"UsersInventory_GET_SUCCESS",
		FAILED:"UsersInventory_GET_FALIURE",
		LOADING:"UsersInventory_GET_LOADING",
	}

}
