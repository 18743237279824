import React from 'react'
import './LabelTextAreaInput.css'
function LabelTextAreaInputWithoutML(props) {

    const { label = 'label', inputValue, placeholder, name = "name", onChange, errorMsg = 'errorMsg', flag = false, containerStyle, maxLength, disabled, id="textArea"} = props
    return (
        <div className="ltai_container" style={containerStyle}>
            <label htmlFor="" className="ltai_label">{label}</label>
            <div className="ltai_input_container">
                <textarea id={id} className="ltai_input" placeholder={placeholder} name={name} value={inputValue} onChange={onChange} maxLength={maxLength} disabled={disabled}/>
                    
                <p className="ltai_error_text" style={{ display: flag ? 'block' : 'none' }}>{errorMsg}</p>

                    <p className="ltai_label" style={{flex:1,alignSelf:'flex-end', textAlign:'right'}}>Character Count : {inputValue.length}{maxLength ? ` / ${maxLength}`:null}</p>
            </div>
        </div>
    )
}

export default LabelTextAreaInputWithoutML