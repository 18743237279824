import React, { Component } from "react";
import RetailerCouponApprovalModel from "../../Cards/RetailerCouponApprovalModel/RetailerCouponApprovalModel";
// import Dateformat from "dateformat"
import moment from "moment";
import {coupon_transaction_subtype_id} from 'RefRecordsConstants'
import EditIcon from '@material-ui/icons/Edit';
import {withCookies} from 'react-cookie'
class RetailerCouponApprovalTable extends Component {
  constructor(props){
    super(props);
    this.state={

    }


  }
  getUTC_date = (date) => {
    var d = new Date(date);
    d.setDate(d.getUTCDate());
    // alert(d)
    return d;
  };

  setCouponCookies=(item)=>{
    // alert(item.term_end_date)

    this.props.cookies.set("BusinessProfileData", item, { path: "/" })
    this.props.cookies.set("Coupon_Batch_Id_ForApproval", item.coupon_batch_id, { path: "/" })
  //  alert( this.props.cookies.get("Coupon_Batch_Id_ForApproval"))
    console.log("sdfasd: ",item.coupon_batch_id )
   this.props.history.push({
      pathname: "/ManageCouponInventory"
    });
  }
  render() {

    const {Inventory, 
      // month, year, SelectedPartner, SelectedFaceValue
    } = this.props
    // var selected_month = ""
    // var selected_year = ""
    // try{
    //   selected_month = JSON.parse(month)
    //   selected_year = JSON.parse(year)
    // }catch(e){
    //   selected_month = month
    //   selected_year = year
    // }
    // alert(JSON.stringify(SelectedPartner))
    var CouponsList = Inventory;
    // var CouponsList = Inventory.filter((element)=>{
    //   var flag = true
    //   if(Dateformat(element.promotion_start_date,"mmmm") !== selected_month.name)
    //   flag = false
    //   if(Dateformat(element.promotion_start_date,"yyyy") !== selected_year.name)
    //   flag = false
    //   if(SelectedPartner !== "all" && SelectedPartner !== element.business_name)
    //   flag = false
    //   if(SelectedFaceValue !== "all" && parseInt(SelectedFaceValue) !== parseInt(element.coupon_face_value))
    //   flag = false

    //   // SelectedFaceValue
    //   return flag
    // })
    console.log('coupon list', CouponsList)

    return (
      <div className="table-box-app">
        {CouponsList.length>0?
        <table className="table-approval">
        <thead className="table-app-head">
          <tr>
            <th>Partner Name</th>
            <th>Offer Title</th>
            <th>Face Value</th>
            <th>Start Date Time</th>
            <th>Expiration Date Time</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody className="table-app-body">{ CouponsList.map((item, index)=>{
            return <tr key={index}>
            <td>{item.business_name}</td>
            <>
              {
                item.offer_name ? 
                <td>
                <div
                title={item.offer_name}
                 className="btite-elpse">
                   {item.offer_name}
                 </div>
                 </td>
                 :
              <td>--</td>
              }
            </>
            {
              parseInt(item.coupon_transaction_subtype_id) == coupon_transaction_subtype_id.PercentageType ?
              <td>{item.face_value}%</td>:
              <td>${item.face_value}</td>
            }
            

            <td>{moment(item.promotion_start_date).format("MM/DD/YYYY") + " at " + moment(item.promotion_start_date).format("h:mm A")
           
        }</td>

{/* quata code <td>{moment(item.promotion_start_date).format("MMMM YYYY")
            // Dateformat(this.getUTC_date(item.promotion_start_date),'mmmm yyyy')
        }</td> */}
           {/* <td>{item.coupon_code}</td> */}
            <td>{moment(item.promotion_end_date).format("MM/DD/YYYY") + " at " + moment(item.promotion_end_date).format("h:mm A")
        //     Dateformat(
        //   this.getUTC_date(item.promotion_end_date),
        //   'mm/dd/yyyy',
        // )
        }</td>
            <td>
              <div className="box-icon-show">
                  <RetailerCouponApprovalModel
                    getRetailerCouponsInventory={this.props.getRetailerCouponsInventory}
                    ref_data={this.props.ref_data}
                    coupon={item}
                    activePage={this.props.activePage}
                    resetFilterStates={this.props.resetFilterStates}
                  />
                  <EditIcon id="editLink" onClick={() => this.setCouponCookies(item)} />
            </div>
            </td>
          </tr>
          })}
          </tbody>
        </table>
         :
        <div className="no-coupons">
        No offers submitted by the partners
        </div>}
      </div>
    );
  }
}

export default withCookies(RetailerCouponApprovalTable);
