import React, { Component } from "react";
import "../UploadBannerCard/UploadBannerCard.css";
// import { Link } from "@material-ui/core";
import DeleteBannerContainer from "../../UploadBannerManage/DeleteBanner/DeleteBannerContainer"
// import DeleteModal from "./DeleteModal";


const zoneIdData = [
  {name: "Select Zone", value:""},
  {name: "Zone 1", value:1},
  {name: "Zone 2", value:2},
  {name: "Zone 3", value:3},
  {name: "Zone 4", value:4},
]

class UploadBannerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  //  props.DeleteBanner()
  }
  
  setIsLoading = (loading)=>{
    this.setState({
      isLoading:loading
    })
  }
  DaysBetween = (StartDate, EndDate) => {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(
      EndDate.getFullYear(),
      EndDate.getMonth(),
      EndDate.getDate()
    );
    const end = Date.UTC(
      StartDate.getFullYear(),
      StartDate.getMonth(),
      StartDate.getDate()
    );
    const days = (start - end) / oneDay;
    if (days > 0) return days;
    else return 0;
  };
  render() {
    const {
      RetailerName  = "",
      bannerTitle = "",
      bannerImage = "",
      startDate = "12/04/2020",
      expiryDate = "12/04/2020",
      number_of_days = "0",
      banner,
      disableDelete = false,
      allocation = "0/0",
      hideButtons = false,
      notes = "",
    } = this.props;

    // const oneDay = 24 * 60 * 60 * 1000;
    // const diffDays = Math.round(Math.abs((new Date(expiryDate) - new Date(startDate)) / oneDay));
    /// alert(diffDays)
    // alert(notes)

    console.log('banner', this.props)

    return (
      <div  className="card-box responsive">
        <div  className="card-box121" data-title2=
        {notes.length > 300
          ? notes.slice(0, 300) + "..."
          : notes
        }>
        <div className="card-img">
          <img src={bannerImage} alt="not found" />
        </div>
        <div className="card-banner-heading">
            <p>File: {bannerTitle.length > 20
              ? bannerTitle.slice(0, 20) + "..."
              : bannerTitle}
              </p>
              <p className="hhh">
              Bus: {RetailerName.length > 20
              ? RetailerName.slice(0, 20) + "..."
              : RetailerName}
              </p>
          </div>  
        <div className="card-banner-text-box">
          <div className="card-banertext-left">
            <div className="card-headig">Start date</div>
          </div>
          <div className="card-banertext-left text-right">
            <div className="card-text">{startDate}</div>
          </div>
        </div>
        <div className="card-banner-text-box">
          <div className="card-banertext-left">
            <div className="card-headig">Expiration Date</div>
          </div>
          <div className="card-banertext-left text-right">
            <div className="card-text">{expiryDate}</div>
          </div>
        </div>
        <div className="card-banner-text-box">
          <div className="card-banertext-left">
            <div className="card-headig">No. of days</div>
          </div>
          <div className="card-banertext-left text-right">
            <div className="card-text">{number_of_days}</div>
          </div>
        </div>
        <div className="card-banner-text-box">
          <div className="card-banertext-left">
            <div className="card-headig">Frequency</div>
          </div>
          <div className="card-banertext-left text-right">
            <div className="card-text">
              {banner.seven_days
                ? "7 days a week"
                : banner.week_days
                ? "Weekdays"
                : banner.week_ends
                ? "Weekends"
                : ""}
            </div>
          </div>
        </div>
        <div className="card-banner-text-box">
          <div className="card-banertext-left">
            <div className="card-headig">Allocation</div>
          </div>
          <div className="card-banertext-left text-right">
            <div className="card-text">
              {allocation} /{" "}
              {number_of_days}
            </div>
          </div>
        </div>

        <div className="card-banner-text-box">
          <div className="card-banertext-left">
            <div className="card-headig">Zone Type</div>
          </div>
            <div className="card-banertext-left text-right">
              <div className="card-text">
                {
                  banner.zone_id ?
                    zoneIdData.map((item, index) => {
                      return (item.value === banner.zone_id ? item.name : "")
                    }):
                    "---"
                }
              </div>
          </div>
        </div>
        </div>

        

        <div className="card-button-2 icon-m">
          <div className="card-icon">
           {!hideButtons? <a id="copyLink" onClick={() => this.props.duplicate_Banner(banner)} title="Copy"><i className="far fa-copy"></i></a>:null}
          </div>
          <div className="card-icon margin">
           {!hideButtons? <a id="editLink" onClick={() => this.props.edit_Banner(banner)} title="Edit"><i className="fas fa-edit"></i></a>:null
            }
          </div>
          <div className="card-icon">
        {!disableDelete ? 
            <DeleteBannerContainer
              isLoading={this.state.isLoading}
              setIsLoading={this.setIsLoading}
              banner={banner}

            />
            :!hideButtons? <a >Delete</a>:null
        }

        </div>
        </div>
        {/* <div className="card-button-center">
        {!disableDelete ? 
            <DeleteBannerContainer
              isLoading={this.state.isLoading}
              setIsLoading={this.setIsLoading}
              banner={banner}

            />
            :!hideButtons? <a >Delete</a>:null
        }

        </div> */}

      </div>
    );
  }
}

export default UploadBannerCard;
