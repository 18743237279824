import React, { Component, Fragment } from "react";

// import {Fonts} from '../../utils/Fonts';
import { GetCouponTypesStatus } from "./GetCouponTypesConstants";
import { withCookies } from "react-cookie";
import LabelDropDownInput from "../../Inputs/LabelDropDownInput";
// import LoadingScreen from "../../../Utils/Loading";
import SingleInputRadio from "../../Inputs/InputRadio/SingleInputRadio";

import {coupon_transaction_type_id} from 'RefRecordsConstants'

export class GetCouponTypesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isEdit:false,
      TransactionTypes:[]
      // retailer_details: props.cookies.get("retailer_details"),
    };
    this.GetCouponTypes();
    //  props.GetCouponTypes()
  }

  GetCouponTypes = () => {
    // alert("test")
    this.props.GetCouponTypes( );
  };

  setTypeChange = (e) => {
    // var type=e.target.value
    // alert(e.target.value);

    this.props.setCouponType(e.target.value)
    // if(image !==""){
    //   this.props.setRetailerCouponImage(JSON.parse(image).image_location)
    // }else{
    //   this.props.setRetailerCouponImage(image)
    // }

  };






  static getDerivedStateFromProps(nextProps, prevState) {
    // alert(nextProps.coupon_transaction_type)
    if(nextProps.coupon_transaction_type !== "" && nextProps.needEdit)
  {  
    console.log("prevState.TransactionTypes: ",prevState.TransactionTypes)
    // alert(JSON.stringify(nextProps.coupon_transaction_subtype_id))
   let found= prevState.TransactionTypes.find((item=>{
    // return (nextProps.coupon_transaction_type_id + '' +nextProps.coupon_transaction_subtype_id) === (item.coupon_transaction_type_id + '' + item.coupon_transaction_subtype_id)
      if(!item.coupon_transaction_subtype_id)
      return nextProps.coupon_transaction_type_id === item.value
      else
      return nextProps.coupon_transaction_subtype_id === item.value
     
  }))
  nextProps.setCouponType((JSON.stringify(found)))
  
    nextProps.setEditType()
  }


    return null;
  }
  componentWillUnmount() {
    this.props.resetReducerState();
  }

  getScreen(status) {
    switch (status) {
      case GetCouponTypesStatus.GetCouponTypes_GET.NEW:
        return(
        <Fragment>
          <LabelDropDownInput
          label="Offer Type"
          type="text"
          name="CouponType"
          id="OfferType"
          onChange={this.setTypeChange}
          data={[]}
          inputValue={this.props.coupon_transaction_type}
          errorMsg={this.props.couponValueErrorMsg}
          flag={this.props.couponValueFlag}
          />
        </Fragment>
        )
        break;

      case GetCouponTypesStatus.GetCouponTypes_GET.SUCCESS:
        console.log("coupon_type", this.props.CouponTypes.coupon_transaction_types)
        
        // if(this.props.SubTypes.length == 0)
        // this.props.setSubTypeData(this.props.CouponTypes.coupon_transaction_subtypes)
          // alert(JSON.stringify(coupon_transaction_type_id))
          // alert(JSON.stringify(this.props.CouponTypes))
          // let categories =[{name:'Select Type', value:0}]
          // this.props.CouponTypes.coupon_transaction_subtypes.forEach(element => {
        
          //   categories.push({...element, isSubType:true})
          // });
          // this.props.CouponTypes.coupon_transaction_types.forEach(element => {
          //   if(element.value !=coupon_transaction_type_id.discount)
          //   categories.push(element)
          // });
         this.state.TransactionTypes =[{value:0, name:"Select Type"}, ...this.props.CouponTypes] //.unshift({value:0, name:"Select Type"})
          // alert(JSON.stringify(this.props.CouponTypes))
        // if(Array.isArray(this.props.CouponTypes.coupon_transaction_types) && this.props.CouponTypes.coupon_transaction_types.length>0)
     
          // alert(this.props.coupon_transaction_type)
        // alert(JSON.stringify(this.props.coupon_transaction_type_id))
          // this.props.setCouponType(categories[0].value)
          //  categories = [...categories, ...this.props.CouponTypes.coupon_transaction_subtypes]
        return (
          <Fragment>

            <div className="override-two">
              <div className="overide-year">
              <LabelDropDownInput
                label="Offer Type"
                type="text"
                id="OfferType"
                star="*"
                name="OfferType"
                isJSON={true}
                onChange={this.setTypeChange}
                inputValue={this.props.coupon_transaction_type}
                data={this.state.TransactionTypes}
                errorMsg={this.props.couponValueErrorMsg}
                flag={this.props.couponValueFlag}
                disabled={this.props.disabled}
              />

            </div>
            </div>
            
            
          </Fragment>
        );
        break;
      case GetCouponTypesStatus.GetCouponTypes_GET.FAILED:
        return (
          <Fragment>
            <div className="override-two">
              <div className="overide-year">
              <LabelDropDownInput
                label="Offer Type"
                type="text"
                name="OfferType"
                star="*"
                id="OfferType"
                onChange={this.setTypeChange}
                inputValue={this.props.coupon_transaction_type}
                disabled={this.props.disabled}
                data={[]}
                couponValueErrorMsg={this.props.couponValueErrorMsg}
                flag={this.props.couponValueFlag}
              />
            </div>
            </div>
          </Fragment>
        );

      case GetCouponTypesStatus.GetCouponTypes_GET.LOADING:
        return(
          <Fragment>
        <div className="override-two">
            <div className="overide-year">
              <LabelDropDownInput
                label="Offer Type"
                type="text"
                name="OfferType"
                star="*"
                id="OfferType"
                onChange={this.setTypeChange}
                inputValue={this.props.coupon_transaction_type_id}
                data={[]}
                disabled={this.props.disabled}
                couponValueErrorMsg={this.props.couponValueErrorMsg}
                flag={this.props.couponValueFlag}
              />
          </div>
          </div>
            </Fragment>
          )
        break;
      default:
        break;
    }
  }
  render() {
    // alert(this.props.GetCouponTypes_GET_status)
    return (
      <Fragment>
        {this.getScreen(this.props.GetCouponTypes_GET_status)}
      </Fragment>
    );
  }
}

export default withCookies(GetCouponTypesView);
