import { connect } from "react-redux";
import GenerateCouponsView from "./GenerateCoupons_View";
import {GenerateCouponsServer} from "./server"
import {GenerateCouponsActions} from './GenerateCouponsConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        GenerateCoupons_post_status:state.GenerateCouponsReducer.GenerateCoupons_Status,
        Generated:state.GenerateCouponsReducer.Generated,
        CouponsDetails:state.GenerateCouponsReducer.GenerateCouponsDetails,
        message:state.GenerateCouponsReducer.message
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GenerateCoupons: (data) => {
           dispatch( GenerateCouponsServer.GenerateCoupons(data) )
        },
        GenerateCouponsFromDraft: (data) => {
            dispatch( GenerateCouponsServer.GenerateCouponsFromDraft(data) )
         },
        createDraft: (data) => {
            dispatch(GenerateCouponsServer.createDraft(data))
        },
        updateDraft: (data) => {
            dispatch(GenerateCouponsServer.updateDraft(data))
        },
        updateExpiryDate:(data)=>{
            dispatch(GenerateCouponsServer.updateExpiryDate(data))
        },
        resetReducerState:async () => {
         await   dispatch( {type:GenerateCouponsActions.GenerateCoupons_Post.LOADING})
         await   dispatch( {type:GenerateCouponsActions.GenerateCoupons_Post.NEW})
            
         },
    }
};
const GenerateCouponsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerateCouponsView)

export default GenerateCouponsContainer;