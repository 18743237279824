import { connect } from "react-redux";
import CouponImageListView from "./CouponImageList_View";
import {CouponImageListServer} from "./server"
import {CouponImageListActions} from './CouponImageListConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        CouponImageList_GET_status:state.CouponImageListReducer.CouponImageList_Status,
        CouponImageList:state.CouponImageListReducer.CouponImageListDetails,
        CouponImageList_Error:state.CouponImageListReducer.CouponImageList_Error,
        ImageSizeLimit:state.CouponImageListReducer.ImageSizeLimit,
        VideoSizeLimit:state.CouponImageListReducer.VideoSizeLimit
       // CouponImageListIndex:state.CouponImageListReducer.CouponImageListIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getCouponImageList: () => {

           dispatch( CouponImageListServer.getCouponImageList() )
        },
        resetReducerState:()=>{
            dispatch({type:CouponImageListActions.CouponImageList_GET.NEW})
        }
    }
};
const CouponImageListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponImageListView)

export default CouponImageListContainer;