import {
    ManageMessagingStatus,
    ManageMessagingActions
  } from "./ManageMessagingConstants";
  
  const ManageMessaging_initialState = {
    ManageMessaging_Status: ManageMessagingStatus.ManageMessaging_Get.NEW,
    ManageMessaging_Message: "",
    ManageMessagingDetails: []
  };
  export default function(state = ManageMessaging_initialState, action) {
    switch (action.type) {
      case ManageMessagingActions.ManageMessaging_Get.LOADING:
        return {
          ...state,
          ManageMessaging_Status:
            ManageMessagingStatus.ManageMessaging_Get.LOADING
        };
      case ManageMessagingActions.ManageMessaging_Get.NEW:
        return {
          ...state,
          ManageMessaging_Status:
            ManageMessagingStatus.ManageMessaging_Get.NEW,
          ManageMessagingDetails: []
        };
      case ManageMessagingActions.ManageMessaging_Get.SUCCESS:
        return {
          ...state,
          ManageMessaging_Status:
            ManageMessagingStatus.ManageMessaging_Get.SUCCESS,
            ManageMessaging_Message: action.message
        };
      case ManageMessagingActions.ManageMessaging_Get.FAILED:
        return {
          ...state,
          ManageMessaging_Status:
            ManageMessagingStatus.ManageMessaging_Get.FAILED,
            ManageMessaging_Message:action.error
        };
  
      default:
        return {
          ...state,
          ManageMessaging_Message: "",
          ManageMessaging_Status: ManageMessagingStatus.ManageMessaging_Get.NEW
        };
    }
  }
  