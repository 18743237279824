import React, { Component, Fragment } from "react";
//import './GenerateCoupons.css'
import { GenerateCouponsStatus } from "./GenerateCouponsConstants";
import GenerateCoupons from "../ManageCouponInventory";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingScreen from "../../Utils/Loading";
// import ProgressBarFive from "../../ProgressBar/ProgressBarFive";
import ProgressBarFour from "../../ProgressBar/ProgressBarFour";
import BusinessName from "../../BusinessName/BusinessName"
export class GenerateCouponsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      override: true,
      editCouponData: {}
    };
    //props.resetReducerState()
  }

  setEditCouponData = (editCouponData) =>{
    this.setState({
      editCouponData: editCouponData
    })
  }

  getScreen(status) {
    switch (status) {
      case GenerateCouponsStatus.GenerateCoupons_Post.NEW:
        return (
          <GenerateCoupons
            GenerateCoupons={this.props.GenerateCoupons}
            GenerateCouponsFromDraft={this.props.GenerateCouponsFromDraft}
            createDraft={this.props.createDraft}
            updateDraft={this.props.updateDraft}
            editCouponData={this.state.editCouponData}
            setEditCouponData={this.setEditCouponData}
            updateExpiryDate={this.props.updateExpiryDate}
            cookies={this.props.cookies}
            resetFields={this.props.resetReducerState}
          />
        );

      case GenerateCouponsStatus.GenerateCoupons_Post.SUCCESS:
        // this.props.resetReducerState()
        // alert(this.props.message)
        toast.success(this.props.message);
        this.props.resetReducerState();
        return (
          <Fragment>
            <GenerateCoupons
              GenerateCoupons={this.props.GenerateCoupons}
              GenerateCouponsFromDraft={this.props.GenerateCouponsFromDraft}
              createDraft={this.props.createDraft}
              updateDraft={this.props.updateDraft}
              editCouponData={this.state.editCouponData}
              setEditCouponData={this.setEditCouponData}
              CouponsGenerationData={this.props.CouponsDetails}
              Generated={this.props.Generated}
              cookies={this.props.cookies}
              resetFields={this.props.resetReducerState}
              updateExpiryDate={this.props.updateExpiryDate}
            />
            {/* <SnackBar title={"Coupons Generated Successfully"} /> */}
          </Fragment>
        );
        break;
      case GenerateCouponsStatus.GenerateCoupons_Post.FAILED:
        toast.error(this.props.message);
        this.props.resetReducerState();
        return (
          <Fragment>
            <GenerateCoupons
              GenerateCoupons={this.props.GenerateCoupons}
              GenerateCouponsFromDraft={this.props.GenerateCouponsFromDraft}
              createDraft={this.props.createDraft}
              updateDraft={this.props.updateDraft}
              editCouponData={this.state.editCouponData}
              setEditCouponData={this.setEditCouponData}
              cookies={this.props.cookies}
              resetFields={this.props.resetReducerState}
              updateExpiryDate={this.props.updateExpiryDate}
            />
            {/* <SnackBar title={"Failed to Generate new Coupons"} /> */}
          </Fragment>
        );
        break;
      case GenerateCouponsStatus.GenerateCoupons_Post.LOADING:
        return <LoadingScreen />;
        break;
      default:
        break;
    }
  }
  render() {
    // alert(this.props.history)
    return (
      <Fragment>
        <BusinessName/>

          <ProgressBarFour
            ProgressBar1="active"
            ProgressBar2="active"
            ProgressBar3="active"
            ProgressBar4="active"
          />
      
        {this.getScreen(this.props.GenerateCoupons_post_status)}
      </Fragment>
    );
  }
}

export default GenerateCouponsView;
