//******************Exchange Rate Server Calls******************;

import { RetailerCouponsInventoryActions } from './RetailerCouponsInventoryConstants';
import store from '../../../Store/store';
import { ROOT_URL, getCookie } from '../../../Config/Config';
import { failure_messages } from 'Messages';

export const RetailerCouponsInventoryServer = {
  //  getRetailerCouponsInventory:getRetailerCouponsInventory,
  getRetailerCouponsInventory: getRetailerCouponsInventory,
  getFilteredCouponsInventory: getFilteredCouponsInventory,
  //  updateRetailerCouponsInventory:updateRetailerCouponsInventory
};

//******************Insert RetailerCouponsInventorys******************;

function getRetailerCouponsInventory(data) {
  const request = fetch(ROOT_URL + '/api/coupon/getAllCouponsInventory', {
    method: 'Post',
    mode: 'cors',
    headers: {
      authorization: getCookie('admin_token_partner'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      response.json().then((response) => {
        if (response.status == '200') {
          data.props.setItemList(response.ref_data.CouponApprovalCount.Coupon_Approval_Count)
          console.log(
            'Response from server: ',
            response.data
          );
          store.dispatch({
            type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET
              .SUCCESS,
            payload: { Inventory: response.data, ref_data: response.ref_data },
            RetailerCouponsInventoryCount:
              response.ref_data.CouponApprovalCount.Coupon_Approval_Count,
          });
          return;
        } else {
          data.props.setItemList(0)
          store.dispatch({
            type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET
              .FAILED,
            error: response.error,
          });
          return;
        }
      });
    })
    .catch((error) => {
      data.props.setItemList(0)
      store.dispatch({
        type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET
          .FAILED,
        error: failure_messages.Businessparticipants,
      });
    });
  return {
    type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.LOADING,
  };
}

// ************************************************************************************
// ********* Get Paginated Coupons on the basis of filters ****************************
// ************************************************************************************

function getFilteredCouponsInventory(data) {
  // alert(data.props)
  const request = fetch(ROOT_URL + '/api/coupon/getFilteredCouponsInventory', {
    method: 'Post',
    mode: 'cors',
    headers: {
      authorization: getCookie('admin_token_partner'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      response.json().then((response) => {
        if (response.status == '200') {
          // alert(JSON.stringify( response.data))
          data.props.setItemList(response.ref_data.CouponApprovalCount.Coupon_Approval_Count)
          console.log('Inventory listItem:', response.data[0]);
          store.dispatch({
            type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET
              .SUCCESS,
            payload: { Inventory: response.data, ref_data: response.ref_data },
            RetailerCouponsInventoryCount:
              response.ref_data.CouponApprovalCount.Coupon_Approval_Count,
          });
          return;
        } else {
          data.props.setItemList(0)
          store.dispatch({
            type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET
              .FAILED,
            error: response.error,
          });
          return;
        }
      });
    })
    .catch((error) => {
      data.props.setItemList(0)
      store.dispatch({
        type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET
          .FAILED,
        error: failure_messages.Businessparticipants,
      });
    });
  return {
    type: RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.LOADING,
  };
}

//******************Get RetailerCouponsInventorys******************;

//******************Update RetailerCouponsInventory******************;

// function getRetailerCouponsInventory(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.RetailerCouponsInventory.length>0)
//              store.dispatch({type:RetailerCouponsInventoryActions.RetailerCouponsInventory_Get.SUCCESS,payload:response.RetailerCouponsInventory});
//              else
//              store.dispatch({type:RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:RetailerCouponsInventoryActions.RetailerCouponsInventory_Get.FAILED});
//                return ;
//                }
//             });
//            }).catch((error) => {
//             store.dispatch({type:RetailerCouponsInventoryActions.RetailerCouponsInventory_Get.FAILED});
//           })
//        return {type:RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.LOADING}

//     };

// function updateRetailerCouponsInventory(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.FAILED});
//         return ;
//         }
//      });
//     }).catch((error) => {
//       store.dispatch({type:RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.FAILED});
//     })
// return {type:RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.LOADING}

// };
