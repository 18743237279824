import { connect } from "react-redux";
import UserRoleListView from "./UserRoleList_View";
import {UserRoleListServer} from "./server"
import {UserRoleListActions} from './UserRoleListConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        UserRoleList_GET_status:state.UserRoleListReducer.UserRoleList_Status,
        UserRoleList:state.UserRoleListReducer.UserRoleListDetails,
        UserRoleList_Error:state.UserRoleListReducer.UserRoleList_Error
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getUserRoleList: () => {

           dispatch( UserRoleListServer.getUserRoleList() )
        },
        resetReducerState:()=>{
            dispatch({type:UserRoleListActions.UserRoleList_GET.NEW})
        }
    }
};
const UserRoleListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRoleListView)

export default UserRoleListContainer;