import { CouponImageListStatus, CouponImageListActions } from './CouponImageListConstants';
import { PreviousCouponsActions } from "../../OverridePricing/GetCouponImages/GetCouponImagesConstants";
const CouponImageList_initialState = {
    CouponImageList_Status: CouponImageListStatus.CouponImageList_GET.NEW,
    CouponImageList_Error: '',
    CouponImageListDetails: [],
    ImageSizeLimit:"loading... ",
    VideoSizeLimit:"loading... "
}
export default  function  (state = CouponImageList_initialState, action) {
    switch (action.type) {
        case CouponImageListActions.CouponImageList_GET.LOADING:
            return { ...state, CouponImageList_Status: CouponImageListStatus.CouponImageList_GET.LOADING }
            case PreviousCouponsActions.GetCouponImages_GET.SUCCESS:
                return { ...state,
                    ImageSizeLimit:action.ref_data.max_image_upload_size,
                    VideoSizeLimit:action.ref_data.max_video_upload_size }
    
            case CouponImageListActions.CouponImageList_GET.NEW:
                return { ...state, CouponImageList_Status: CouponImageListStatus.CouponImageList_GET.NEW, CouponImageListDetails: [] }
    
        case CouponImageListActions.CouponImageList_GET.FAILED:
            return { ...state,  CouponImageList_Status: CouponImageListStatus.CouponImageList_GET.FAILED, CouponImageList_Error:action.error}
        case CouponImageListActions.CouponImageList_GET.SUCCESS:
          
          return { ...state,  CouponImageList_Status: CouponImageListStatus.CouponImageList_GET.SUCCESS, CouponImageListDetails:action.payload.results,
            ImageSizeLimit:action.payload.ref_data.max_image_upload_size,
            VideoSizeLimit:action.payload.ref_data.max_video_upload_size
        }
          
        
        default:
            return { ...state,
                
            }
    }
}
