import {
  ListOfApplicantsStatus,
  ListOfApplicantsActions
} from "./ListOfApplicantsConstants";

const ListOfApplicants_initialState = {
  ListOfApplicants_Status: ListOfApplicantsStatus.ListOfApplicants_Get.NEW,
  ListOfApplicants_Error: "",
  ListOfApplicantsDetails: [],
  ApplicantsCount:0
};
export default function(state = ListOfApplicants_initialState, action) {
  switch (action.type) {
    case ListOfApplicantsActions.ListOfApplicants_Get.LOADING:
      return {
        ...state,
        ListOfApplicants_Status:
          ListOfApplicantsStatus.ListOfApplicants_Get.LOADING
      };
    case ListOfApplicantsActions.ListOfApplicants_Get.NEW:
      return {
        ...state,
        ListOfApplicants_Status:
          ListOfApplicantsStatus.ListOfApplicants_Get.NEW,
        ListOfApplicantsDetails: []
      };
    case ListOfApplicantsActions.ListOfApplicants_Get.SUCCESS:
      return {
        ...state,
        ListOfApplicants_Status:
          ListOfApplicantsStatus.ListOfApplicants_Get.SUCCESS,
        ListOfApplicantsDetails: action.payload, ApplicantsCount:action.ApplicantsCount
      };
    case ListOfApplicantsActions.ListOfApplicants_Get.FAILED:
      return {
        ...state,
        ListOfApplicants_Status:
          ListOfApplicantsStatus.ListOfApplicants_Get.FAILED
      };
      case ListOfApplicantsActions.ListOfApplicants_Get.UPDATE:
       
        state.ListOfApplicantsDetails.forEach(element => {
          if(element.business_application_id === action.payload.business_application_id)
          {
            element.application_status_id = action.payload.application_status_id
            element.note = action.payload.note
          }
        })
        return {
          ...state
        };
  
    default:
      return {
        ...state,
        ListOfApplicants_Error: "",
        ListOfApplicants_Status: ListOfApplicantsStatus.ListOfApplicants_Get.NEW
      };
  }
}
