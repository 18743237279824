import React, { Component, Fragment } from 'react'
import BoxInput from '../Inputs/BoxInput'
import Header from '../Header/Header'
import './EnrollmentForm.css'
// import img from '../../assets/transit.png'
import DropDownInput from '../Inputs/DropDownInput'
export class EnrollmentForm extends Component {
    constructor()
    {
        super();
        this.state = {
            busnessEnrollement:{
            business_name:'',
            business_street_1:'',
            business_street_2:'',
            city:'',
            state:'',
            zip5:'',
            zip:'',
            monthly_coupon_limit:'',
            },
            packageType: [{name: "Package Type",value:''},{name:'100',value:100}, {name:'200',value:200}, {name:'500', value:500}]

        }

        this.onChange = this.onChange.bind(this)
        this.onMonthlyCouponLimitChange = this.onMonthlyCouponLimitChange.bind(this);
        this.onClick = this.onClick.bind(this)

    }
    onClick(event)
    {
        event.preventDefault()
        console.log(this.props)
        this.props.saveBusinessEnrollment(this.state.busnessEnrollement)
    }
    onMonthlyCouponLimitChange(data)
    {
        var obj = this.state.busnessEnrollement;
        obj['monthly_coupon_limit']=data;
        this.setState({...obj})
    }
    onChange(event)
    {
        console.log(event,event.target.value)
        var obj = this.state.busnessEnrollement;
        obj[event.target.name]=event.target.value;
        this.setState({...obj})

    }
    render() {
        return (
            <Fragment>
                <Header />
                <div className="ef_container">
                    <div className="ef_div1">
                        <div className="ef_content_container">
                            <p className="ef_heading">NJ Transit</p>
                            <p className="ef_desc">Enrollment Form for Rewards Platform</p>
                            <section className="ef_sec1">
                                <div className="ef_left_sec">
                                    <BoxInput onChange={this.onChange} name="business_name" type='text' placeholder="Business Name" />
                                    <BoxInput onChange={this.onChange} name="business_street_1" type='text' placeholder="Street 1" />
                                    <BoxInput onChange={this.onChange} name="business_street_2" type='text' placeholder="Street 2" />
                                    <BoxInput onChange={this.onChange} name="city" type='text' placeholder="City" />
                                    <BoxInput onChange={this.onChange} name="state" type='text' placeholder="State" />
                                    <BoxInput onChange={this.onChange} name="zip5" type='text' placeholder="Zip5" />
                                    <BoxInput onChange={this.onChange} name="zip" type='text' placeholder="Zip" />
                                    <DropDownInput onChangeEvent={this.onMonthlyCouponLimitChange}  data={this.state.packageType} />
                                </div>
                                <div className="ef_right_sec">
                                    {/* <img className="ef_img" src={img} alt="image not found" /> */}
                                    <div className="ef_right_sec_inner_div">
                                        <p className="ef_inner_text">INSERT NJ TRANSIT ILLUSTRATION</p>
                                    </div>
                                </div>

                            </section>
                            <button  className="ef_next_btn" onClick={this.onClick}>Next</button>
                        </div>
                        <div className="ef_bottoms">
                            <button className="ef_btn ef_btn1">Help</button>
                            <button className="ef_btn ef_btn2">Privacy</button>
                            <button className="ef_btn ef_btn3">Terms</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default EnrollmentForm
