import React, {  Fragment, PureComponent } from "react";
//import './BannerSchedule.css'
import { BannerScheduleStatus } from "./BannerScheduleConstants";
import BannerSchedule from "./BannerSchedule";
import Loading from "../../Utils/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { withRouter } from "react-router-dom";
// import dateFormat from "dateformat";

export class BannerScheduleView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };

    // const data = {
    //   week_start_date: dateFormat(
    //     this.props.cookies.get("banner_week").week_start_date,
    //     "yyyy-mm-dd"
    //   ),
    //   week_end_date: dateFormat(
    //     this.props.cookies.get("banner_week").week_end_date,
    //     "yyyy-mm-dd"
    //   ),
    // };
    // alert(JSON.stringify(data))
    // props.getScheduledBanners(data);
  }
componentWillUnmount(){
  this.props.resetReducerState()
}
  getScreen(status) {
    switch (status) {
      case BannerScheduleStatus.BannerSchedule_POST.NEW:
        return (
          <Fragment>
            <BannerSchedule
              Go_Back={this.props.Go_Back}
              BannerSchedule={this.props.BannerSchedule}
              Week={this.props.Week}
              cookies={this.props.cookies}
              Go_Next={this.props.Go_Next}
              selectedBanners={this.props.selectedBanners}
              scheduledBanners = {this.props.scheduledBanners}
              isCurrentWeekSelected = {this.props.isCurrentWeekSelected}
              history = {this.props.history}
            />
          </Fragment>
        );

      case BannerScheduleStatus.BannerSchedule_POST.SUCCESS:
        toast.success(this.props.message);
        this.props.history.replace("/Manage_Banner");
        return (
          <Fragment>
            <BannerSchedule
              BannerSchedule={this.props.BannerSchedule}
              Week={this.props.Week}
              cookies={this.props.cookies}
              Go_Next={this.props.Go_Next}
              selectedBanners={this.props.selectedBanners}
              scheduledBanners = {this.props.scheduledBanners}
            />
          </Fragment>
        );
        break;
     
      case BannerScheduleStatus.BannerSchedule_POST.FAILED:
        toast.error(this.props.BannerSchedule_Error);

        return (
          <Fragment>
            <BannerSchedule
              Go_Back={this.props.Go_Back}
              BannerSchedule={this.props.BannerSchedule}
              Week={this.props.Week}
              cookies={this.props.cookies}
              Go_Next={this.props.Go_Next}
              selectedBanners={this.props.selectedBanners}
              scheduledBanners = {this.props.scheduledBanners}
            />
          </Fragment>
        );

      case BannerScheduleStatus.BannerSchedule_POST.LOADING:
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Loading />
          </div>
        );
      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.BannerSchedule_POST_status);
  }
}

export default withRouter(BannerScheduleView);
