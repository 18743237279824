import { InvoicesStatus, InvoicesActions } from './InvoicesConstants';

const Invoices_initialState = {
    Invoices_Status: InvoicesStatus.Invoices_GET.NEW,
    Invoices_Error: '',
    InvoicesDetails: [],
    InvoicesCount : 0
}
export default  function  (state = Invoices_initialState, action) {
    switch (action.type) {
        case InvoicesActions.Invoices_GET.LOADING:
            return { ...state, Invoices_Status: InvoicesStatus.Invoices_GET.LOADING }
            case InvoicesActions.Invoices_GET.NEW:
                return { ...state, Invoices_Status: InvoicesStatus.Invoices_GET.NEW, InvoicesDetails: [] }
    
        case InvoicesActions.Invoices_GET.FAILED:
            return { ...state,  Invoices_Status: InvoicesStatus.Invoices_GET.FAILED, Invoices_Error:action.error}
        case InvoicesActions.Invoices_GET.SUCCESS:
          
          return { ...state,  Invoices_Status: InvoicesStatus.Invoices_GET.SUCCESS, InvoicesDetails:action.payload, InvoicesCount:action.InvoicesCount}
          
        
        default:
            return { ...state,
                
            }
    }
}
