
//******************Exchange Rate Server Calls******************;

import {DeleteBannerActions} from './DeleteBannerConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const DeleteBannerServer = {
  //  deleteBanner:deleteBanner,
  deleteBanner:deleteBanner,
  //  updateDeleteBanner:updateDeleteBanner
};

//******************Insert DeleteBanners******************;

function deleteBanner(data){
      const request=fetch(ROOT_URL+'/api/banners/deleteBanner/', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
            store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.SUCCESS,payload:response.data, message:response.message});
            return;
            }else
            {
              store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.FAILED, error:response.error});
              return ;
            }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:DeleteBannerActions.DeleteBanner_POST.LOADING}

};
  
//******************Get DeleteBanners******************;


//******************Update DeleteBanner******************;

  
// function deleteBanner(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.DeleteBanner.length>0)
//              store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.SUCCESS,payload:response.DeleteBanner});
//              else
//              store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.FAILED});
//           })
//        return {type:DeleteBannerActions.DeleteBanner_POST.LOADING}
 
//     };
  
// function updateDeleteBanner(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:DeleteBannerActions.DeleteBanner_POST.FAILED});
//     })
// return {type:DeleteBannerActions.DeleteBanner_POST.LOADING}

// };
