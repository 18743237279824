export const BusinessProfileActions= {
	
	BusinessProfile_Post :{
		NEW:"BusinessProfile_Post_NEW",
		SUCCESS:"BusinessProfile_Post_SUCCESS",
		FAILED:"BusinessProfile_Post_FALIURE",
		LOADING:"BusinessProfile_Post_LOADING",
		GOTO_CONTRACTS: "BusinessProfile_Post_GOTO_CONTRACTS",
	}
}


export const BusinessProfileStatus ={

	BusinessProfile_Post :{
		NEW:"BusinessProfile_Post_NEW",
		SUCCESS:"BusinessProfile_Post_SUCCESS",
		FAILED:"BusinessProfile_Post_FALIURE",
		LOADING:"BusinessProfile_Post_LOADING",
		GOTO_CONTRACTS: "BusinessProfile_Post_GOTO_CONTRACTS",

	}

}
