import { connect } from "react-redux";
import BusinessContractView from "./BusinessContract_View";
import { BusinessContractServer } from "./server"
import { BusinessContractActions } from './BusinessContractConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        BusinessContract_Status: state.BusinessContractReducer.BusinessContract_Status,
        GotoQuotas: state.BusinessContractReducer.GotoQuotas,
        BusinessContract: state.BusinessContractReducer.BusinessContractDetails,
        BusinessContract_Message:state.BusinessContractReducer.BusinessContract_Message
    };
};
const mapDispatchToProps = dispatch => {
    return {

        saveBusinessContract: (data) => {
            dispatch(BusinessContractServer.createBusinessContract(data))
        },
        getBusinessContractList: (data) => {
            // alert(businessId)
            dispatch(BusinessContractServer.getBusinessContract(data))
        },
        updateBusinessContract: (data, id) => {
            
            dispatch(BusinessContractServer.updateBusinessContract(data, id))
        },
        GOTO_QUOTAS: () => {
            dispatch({ type: BusinessContractActions.BusinessContract_Post.GOTO_QUOTAS })
        },
        
        resetReducerState: () =>{
            dispatch({ type: BusinessContractActions.BusinessContract_Post.NEW })
        },
        resetSuccessState: (data) =>{
            dispatch({ type: BusinessContractActions.BusinessContract_Get.SUCCESS,payload:data })
        }
    }
};
const BusinessContractContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessContractView)

export default BusinessContractContainer;