import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import './TimePicker.css'
import Icon from "@material-ui/core/Icon";

function MaterialTimePicker(props) {
  const [selectedDate, handleDateChange] = useState(null);
  const {
    ampm=false,
    inputValue = selectedDate,
    onChange = handleDateChange,
    errorMsg,
    flag,
    onClick,
    disabled =false,
    placeholder="hh:mm AM/PM",
    errorHandler,
    mask="__:__ _M",
    id="time"
  } = props;
  // alert(errorMsg)
  let txt = <div style={{color:'red'}}>{errorMsg}</div>
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
     
        <KeyboardTimePicker
        clearable
        id={id}
        ampm={ampm}
        value={inputValue}
        onChange={onChange}
        label=""
        placeholder={placeholder}
        disabled={disabled}
        helperText={txt}
        // onInvalid={()=>alert("test")}
        // onError={(error, value)=> errorHandler(error, value)}
        // helperText={null}
        // mask={"__:__"}
        mask={mask}
        KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          keyboardIcon={<Icon>schedule</Icon>}
      />
      
    </MuiPickersUtilsProvider>
  );
}

export default MaterialTimePicker;