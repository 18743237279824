import { connect } from "react-redux";
import BannerListView from "./BannerList_View";
import {BannerListServer} from "./server"
import {BannerListActions} from './BannerListConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        BannerList_GET_status:state.BannerListReducer.BannerList_Status,
        BannerList:state.BannerListReducer.BannerListDetails,
        BannerList_Error:state.BannerListReducer.BannerList_Error,
        DeleteBanner_POST_status:state.DeleteBannerReducer.DeleteBanner_Status,
        weekBanners:state.BannerListReducer.weekBanners
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getBannerList: (data) => {

           dispatch( BannerListServer.getBannerList(data) )
        },
        resetReducerState:()=>{
            dispatch({type:BannerListActions.BannerList_GET.NEW})
        }
    }
};
const BannerListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BannerListView)

export default BannerListContainer;