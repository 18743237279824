import React, { Component, Fragment } from "react";
//import './EndCoupon.css'
import { EndCouponStatus } from "./EndCouponConstants";
import { withCookies } from "react-cookie";
import Loading from "../../Utils/Loading";
// import Snackbar from "../../Utils/Snackbar";
import EndCoupon from "./EndCoupon";
import {ref_status} from "RefRecordsConstants"
export class EndCouponView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      loading: false,
      retailer_details: props.cookies.get("BusinessProfileData"),
    };
    // this.props.resetReducerState();
  }
  // closeSnackBar = () => {
  //   this.setState({
  //     open: false,
  //   });
  // };
  componentWillUnmount() {
    this.props.resetReducerState();
  }

  EndCoupon = () => {
    // alert(this.props.coupon.status_id)
    // var status_id = this.props.coupon.status_id;
    // var ref_status = this.props.ref_data.ref_status;

    // if(status_id == ref_status.PAUSED)
    // status_id = ref_status.ACTIVE
    // else
    // status_id = ref_status.PAUSED

    var BatchStatus = this.props.ref_data.BatchStatus.find(
      (item) => item.status_id === ref_status.CLOSED
    );

    var data = {
      to_status_code: BatchStatus.to_status_code,
      coupon_batch_id: this.props.coupon.coupon_batch_id,
      batch_status: ref_status.CLOSED,
      display_color:BatchStatus.display_color,
      BussinessId: this.state.retailer_details.business_id,
    };
    // alert(JSON.stringify(data))                    disable = {this.props.ref_status.EXPIRED ==this.props.status_id || this.props.ref_status.CLOSED == this.props.status_id}
    // alert(JSON.stringify(data))
    this.state.loading = true;
    this.props.handleEndCoupon(data);
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.redeem) {
  //     // alert(JSON.stringify(nextProps.coupon_code))
  //     nextProps.setRedeem(false);
  //     var data = {
  //       redeemed_datetime: new Date(),
  //       coupon_code: nextProps.coupon_code,
  //       BussinessId : prevState.retailer_details.business_id,
  //       email: prevState.retailer_details.email
  //     };
  //     nextProps.handleEndCoupon(data, prevState.retailer_details.access_token);
  //     // nextProps.LoginRetailer(nextProps.data);
  //   }
  //   return null;
  // }

  getScreen(status) {
    switch (status) {
      case EndCouponStatus.EndCoupon_Post.NEW:
        return (
          <EndCoupon
            EndCoupon={this.EndCoupon}
            ref_data={this.props.ref_data}
            coupon={this.props.coupon}
          />
        );

      case EndCouponStatus.EndCoupon_Post.SUCCESS:
        if(this.state.loading)
       { 
        this.state.loading = false;
         var updatedCoupon = this.props.coupon
        updatedCoupon.to_status_code = this.props.EndCouponDetails.to_status_code
        this.props.updateSelectedCoupon(updatedCoupon)
        updatedCoupon.status_id =  this.props.EndCouponDetails.batch_status
       }
        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="success"
              message={this.props.EndCoupon_Message}
            /> */}

            <EndCoupon
              EndCoupon={this.EndCoupon}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
            />
          </Fragment>
        );
        break;
      case EndCouponStatus.EndCoupon_Post.FAILED:
        this.state.loading = false;
          // alert("test")
        return (
          <Fragment>
            {/* <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              severity="error"
              message={this.props.EndCoupon_Message}
            /> */}

            <EndCoupon
              EndCoupon={this.EndCoupon}
              ref_data={this.props.ref_data}
              coupon={this.props.coupon}
            />
          </Fragment>
        );
        break;
      case EndCouponStatus.EndCoupon_Post.LOADING:
        this.state.loading = true
        this.state.open = true;
        return  <button className={"inv-button-b "}>
           <Loading color={"white"}/>
            </button>
        // if (this.state.loading) return(
        //   <button className={"inv-button-b "}>
        //    <Loading color={"white"}/>
        //    </button>)
        // else
        //   return (
        //     <EndCoupon
        //       EndCoupon={this.EndCoupon}
        //       ref_data={this.props.ref_data}
        //       coupon={this.props.coupon}
        //     />
          // );
      default:
        break;
    }
  }
  render() {
    // alert(this.props.EndCoupon_post_status)
    return this.getScreen(this.props.EndCoupon_post_status);
  }
}

export default withCookies(EndCouponView);
