
//******************Exchange Rate Server Calls******************;

import {BannerScheduleActions} from './BannerScheduleConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const BannerScheduleServer = {
  //  BannerSchedule:BannerSchedule,
  BannerSchedule:BannerSchedule,
  getScheduledBanners:getScheduledBanners
  //  updateBannerSchedule:updateBannerSchedule
};

//******************Insert BannerSchedules******************;

function BannerSchedule(data){
      const request=fetch(ROOT_URL+'/api/banner/postBannerSchedule', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){

            store.dispatch({type:BannerScheduleActions.BannerSchedule_POST.SUCCESS,payload:response});
            return;
            }else
            {
              store.dispatch({type:BannerScheduleActions.BannerSchedule_POST.FAILED, error:response.error});
              return ;
            }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:BannerScheduleActions.BannerSchedule_POST.FAILED, error:failure_messages.BannerScheduleError});
            })
      return {type:BannerScheduleActions.BannerSchedule_POST.LOADING}

};
  



function getScheduledBanners(data){
  const request=fetch(ROOT_URL+'/api/banner/getScheduledBanners', {
      method: 'Post',
      mode: 'cors',
      headers: { 
        'authorization':getCookie("admin_token_partner"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      }).then((response)=>{
       response.json().then(response=>{
         if(response.status == "200"){

        store.dispatch({type:BannerScheduleActions.BannerSchedule_GET.SUCCESS,payload:response.data});
        return;
        }else
        {
          store.dispatch({type:BannerScheduleActions.BannerSchedule_GET.FAILED, error:response.error});
          return ;
        }    
       });
      }).catch((error) => {
      //  alert(error)
       store.dispatch({type:BannerScheduleActions.BannerSchedule_GET.FAILED, error:failure_messages.getScheduledBannerError});
        })
  return {type:BannerScheduleActions.BannerSchedule_POST.LOADING}

};


export function getWeeklyScheduledBanners(data, props){
  // alert("test")
  props.setLoading(true)
  const request=fetch(ROOT_URL+'/api/banner/getScheduledBanners', {
      method: 'Post',
      mode: 'cors',
      headers: { 
        'authorization':getCookie("admin_token_partner"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      }).then((response)=>{
       response.json().then(response=>{
         if(response.status == "200"){
          // alert(JSON.stringify(response))
          var lookup = {};
          var types = {};
          for (var j in response.data) {
             lookup[response.data[j].banner_id] = response.data[j].banner_id;
             if (!types[response.data[j].banner_id]) {
              types[response.data[j].banner_id] = 1;
          } else {
              types[response.data[j].banner_id] += 1;
          }
        }
        // alert(JSON.stringify(types))
        for (var i in props.bannerList) {
            if (typeof lookup[props.bannerList[i].banner_id] != 'undefined') {
              
                props.bannerList[i].active = true
                props.bannerList[i].disable = true
                props.bannerList[i].isScheduled = true
                props.bannerList[i].AlreadyAllocated = props.bannerList[i].TotalAllocated - types[props.bannerList[i].banner_id]
                
                if(props.bannerList[i].AlreadyAllocated < 1)
                {
                  props.bannerList[i].AlreadyAllocated = 1
                }
            

            }else{
              delete props.bannerList[i].active
              delete props.bannerList[i].disable
              delete props.bannerList[i].isScheduled
              delete props.bannerList[i].AlreadyAllocated
            } 
        }

        props.setScheduledBanners(response.data)

        props.setLoading(false)
        return;
        }else
        {
          props.setLoading(false)
        }    
       });
      }).catch((error) => {
        props.setLoading(false)
        })
  
};