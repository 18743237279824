import { UndoDeleteCouponStatus, UndoDeleteCouponActions } from './UndoDeleteCouponConstants';

const UndoDeleteCoupon_initialState = {
    UndoDeleteCoupon_Status: UndoDeleteCouponStatus.UndoDeleteCoupon_Post.NEW,
    UndoDeleteCoupon_Message: '',
    UndoDeleteCouponDetails: {},
}
export default function (state = UndoDeleteCoupon_initialState, action) {
    switch (action.type) {
        case UndoDeleteCouponActions.UndoDeleteCoupon_Post.LOADING:
            return { ...state, 
            UndoDeleteCoupon_Status: UndoDeleteCouponStatus.UndoDeleteCoupon_Post.LOADING, UndoDeleteCoupon_Message: '',
            UndoDeleteCouponDetails: {}
         }
        case UndoDeleteCouponActions.UndoDeleteCoupon_Post.NEW:
            return { ...state, UndoDeleteCoupon_Status: UndoDeleteCouponStatus.UndoDeleteCoupon_Post.NEW}

        case UndoDeleteCouponActions.UndoDeleteCoupon_Post.FAILED:
           // alert("test")
            return { ...state, UndoDeleteCoupon_Status: UndoDeleteCouponStatus.UndoDeleteCoupon_Post.FAILED, UndoDeleteCouponDetails: action.payload, UndoDeleteCoupon_Message: action.error }
        case UndoDeleteCouponActions.UndoDeleteCoupon_Post.SUCCESS:
            return { ...state, UndoDeleteCoupon_Status: UndoDeleteCouponStatus.UndoDeleteCoupon_Post.SUCCESS, UndoDeleteCouponDetails: action.payload, UndoDeleteCoupon_Message: action.message }

        default:
            return { ...state }
    }
}
