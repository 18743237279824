import React, { Component, Fragment } from 'react'
//import './DisableBusiness.css'
import { DisableBusinessStatus } from './DisableBusinessconstants';
import DisableBusiness from "./DisableBusiness"

//import Fragment from "../ConfirmationContract/ConfirmationContract"
import Snackbar from '@material-ui/core/Snackbar/';
import Loading from '../../Utils/Loading';

export default class DisableBusinessView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      disable:true,
      success:false
    }
   // alert(this.props.columns)
  }
  UNSAFE_componentWillMount() {
   // alert(this.props.is_disabled)
    if(this.props.is_disabled){
      this.state.disable=true
    }else{
      this.state.disable=this.props.is_disabled
    }
    //this.props.getDisableBusiness()

  }
  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
    //this.props.getDisableBusiness()
  }
  getScreen(status) {
    switch (status) {
      case DisableBusinessStatus.DisableBusiness_Update.NEW:
        return (
          <Fragment >
          <DisableBusiness business_id ={this.props.business_id} is_disabled={this.state.disable}  DisableBusiness={this.props.DisableBusiness} 
          updateDisableBusiness={this.props.updateDisableBusiness} className={this.props.className}
          />
        </Fragment>
        );


      case DisableBusinessStatus.DisableBusiness_Update.SUCCESS:
      
        if(this.props.business_id == this.props.businessId && !this.state.success)
       { 
          this.state.success=true
          this.state.disable=!this.state.disable
          this.props.changeRowIsDisabled(this.props.business_id, this.state.disable)
       }
        return (
          <Fragment >
            
          <DisableBusiness business_id ={this.props.business_id} is_disabled={this.state.disable}  DisableBusiness={this.props.DisableBusiness} 
          updateDisableBusiness={this.props.updateDisableBusiness} className={this.props.className}
          />
          </Fragment>
        );
        break;
      case DisableBusinessStatus.DisableBusiness_Update.FAILED:
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Failed to disable bussiness</span>}
            />

        <DisableBusiness business_id ={this.props.business_id} is_disabled={this.state.disable}  DisableBusiness={this.props.DisableBusiness} 
          updateDisableBusiness={this.props.updateDisableBusiness} className={this.props.className}
          />
          </Fragment>
        );

      case DisableBusinessStatus.DisableBusiness_Update.LOADING:
        this.state.open = true
        this.state.success=false
        if(this.props.business_id == this.props.businessId  )
          return (
            <div style={{ textAlign: "center" }}>
              <Loading/>
            </div>
          );
        else
        return (
          <Fragment >
          <DisableBusiness business_id ={this.props.business_id} is_disabled={this.state.disable}  DisableBusiness={this.props.DisableBusiness} 
          updateDisableBusiness={this.props.updateDisableBusiness} className={this.props.className}
          />
        </Fragment>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.DisableBusiness_Update_status)
    return (
      this.getScreen(this.props.DisableBusiness_Update_status)
    )
  }
}

