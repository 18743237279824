import { DeleteBannerStatus, DeleteBannerActions } from './DeleteBannerConstants';

const DeleteBanner_initialState = {
    DeleteBanner_Status: DeleteBannerStatus.DeleteBanner_POST.NEW,
    DeleteBanner_Message: '',
    DeleteBannerDetails: [],
}
export default  function  (state = DeleteBanner_initialState, action) {
    switch (action.type) {
        case DeleteBannerActions.DeleteBanner_POST.LOADING:
            return { ...state, DeleteBanner_Status: DeleteBannerStatus.DeleteBanner_POST.LOADING }
            case DeleteBannerActions.DeleteBanner_POST.NEW:
                return { ...state, DeleteBanner_Status: DeleteBannerStatus.DeleteBanner_POST.NEW, DeleteBannerDetails: [] }
    
        case DeleteBannerActions.DeleteBanner_POST.FAILED:
            
            return { ...state,DeleteBanner_Status: DeleteBannerStatus.DeleteBanner_POST.FAILED,
                 DeleteBanner_Message:action.error};

        case DeleteBannerActions.DeleteBanner_POST.SUCCESS:
          
          return { ...state,  DeleteBanner_Status: DeleteBannerStatus.DeleteBanner_POST.SUCCESS,DeleteBanner_Message:action.message, DeleteBannerDetails:action.payload}
          
        
        default:
            return { ...state,
                DeleteBanner_Status: DeleteBannerStatus.DeleteBanner_POST.NEW,
            }
    }
}
