import React, { Component } from "react";
// import RetailerCouponApprovalModel from "../Cards/RetailerCouponApprovalModel/RetailerCouponApprovalModel";
import "./RetailerCouponApproval.css";
// import RetailerCouponApprovalFilter from "./RetailerCouponApprovalFilter/RetailerCouponApprovalFilter";
// import RetailerCouponApprovalTable from "./RetailerCouponApprovalTable/RetailerCouponApprovalTable";
import RetailerCouponsInventoryContainer from "./RetailerCouponsInventory/RetailerCouponsInventoryContainer"
//import { Api_Pagination } from "RefRecordsConstants";
import { Link } from "react-router-dom";
// import { Api_Pagination } from "RefRecordsConstants";
import { Api_Pagination } from "RefRecordsConstants";


class RetailerCouponApproval extends Component {

  constructor(props) {
    super(props);
    this.state = {
        NoOfPages: 0,
        activePage: 1,
        isLoading: false,

        SelectedPartner: "all",
        SelectedFaceValue: "all",
        year: "all",
        month: "all",
        applyFilter: false
    };
  }

  // ************************** START******************************************
  // Functions to set the state of selected partner, face value, year and month
  // **************************************************************************
  setYear = (e)=>{
    // alert(JSON.stringify(e.target.value))
    this.setState({
      year:e.target.value
    })
  }

  setMonth = (e)=>{
    // alert(JSON.stringify(e.target.value))
    this.setState({
      month:e.target.value
    })
  }
setFaceValue = (e)=>{
  // alert(e.target.value)
  this.setState({
    SelectedFaceValue:e.target.value
  })
}
  setPartner=(e)=>{
    // alert(e.target.value)
    this.setState({
      SelectedPartner:e.target.value
    })
  }

  // ************************** END ******************************************

  // ************ Apply Filter Button action
  applyFilterAction = (applyFilter)=>{
    this.setState({
      applyFilter
    })

    // console.log("Filter Values", this.state.SelectedPartner)
    // console.log("Filter Values", this.state.SelectedFaceValue)
    // console.log("Filter Values", JSON.parse(this.state.month).value)
    // console.log("Filter Values", JSON.parse(this.state.year).name)



    // this.setState({
    //   applyFilter
    // })
  }


  setLoading = (loading) => {
    //  alert(loading)
    this.state.isLoading = loading;
  };

  setItemList = (ListLength) => {
    if (ListLength - 1 > 0)
      this.setState({
        NoOfPages:
          Math.trunc(
            (ListLength - 1) / Api_Pagination.CouponApprovalLimit
          ) + 1,
      });
    else{
      this.setState({NoOfPages: 0, activePage: 1})
    }
  };

  ChangePage = (page) => {
    // alert(this.state.isLoading)
    console.log("Loading page:", this.state.isLoading);
    if (this.state.activePage != page && !this.state.isLoading) {
      this.setState({
        activePage: page,
      });
      //   this.setLoading(true)
    }
  };
  handleNext = () => {
    //   alert(this.state.isLoading)
    if (this.state.activePage < this.state.NoOfPages && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage + 1,
      });
      // this.setLoading(true)
    }
  };
  handlePrev = () => {
    // alert(this.state.isLoading)
    if (this.state.activePage > 1 && !this.state.isLoading) {
      this.setState({
        activePage: this.state.activePage - 1,
      });
      // this.setLoading(true)
    }
  };

  handleActivePage = (activePage) =>{
    this.setState({
      activePage
    })
  }

  resetFilterStates = () =>{
    this.setState({
      SelectedPartner: "all",
        SelectedFaceValue: "all",
        year: "all",
        month: "all",
        applyFilter: false,
        activePage: 1
    })
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }

  render() {
    console.log("RetailerCouponsInventoryCount", this.state.NoOfPages)
    const items = [];
    let key = 1
    items.push(
      <Link
      key={key}
        to="#"
        onClick={() => this.ChangePage(1)}
        className={this.state.activePage == 1 ? "active-page" : null}
      >
        {" "}
        1{" "}
      </Link>
    );
    let i = 2;
    let offset = 6;
    if (this.state.activePage > 3 && this.state.NoOfPages > 5) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      key+=key
      items.push(<Link       key={key} to="#">{"..."}</Link>);
    }
    if (this.state.activePage + 3 > this.state.NoOfPages && i > 2)
      i = this.state.NoOfPages - 3;
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset - 1;
      index++
    ) {
      key+=key
      items.push(
        <Link
          to="#"
          key={key}
          onClick={() => this.ChangePage(index)}
          className={i == this.state.activePage ? "active-page" : null}
        >
          {" "}
          {i}{" "}
        </Link>
      );
      i++;
    }
    if (this.state.NoOfPages > this.state.activePage + 2)
      if (this.state.NoOfPages > 5)
      {
         key+=key
         items.push(<Link       key={key} to="#">{"..."}</Link>);
      }
      key+=key 
    items.push(
      <Link
        to="#"
        key={key}
        onClick={() => this.ChangePage(this.state.NoOfPages)}
        className={
          this.state.activePage == this.state.NoOfPages ? "active-page" : null
        }
      >
        {this.state.NoOfPages}{" "}
      </Link>
    );

    return (
      <div className="bl-main-box-wrap">
        <div className="bl_container list-b-bo">
          <div className="bl_header-b22">
            <div className="approval-heading-box">
              <h1>Partner Offers For Approval</h1>
            </div>
          </div>
          <RetailerCouponsInventoryContainer
            activePage={this.state.activePage}
            setItemList={this.setItemList}
            setLoading={this.setLoading}

            setYear={this.setYear}
            setMonth={this.setMonth}
            setFaceValue={this.setFaceValue}
            setPartner={this.setPartner}

            SelectedPartner={this.state.SelectedPartner}
            SelectedFaceValue={this.state.SelectedFaceValue}
            year={this.state.year}
            month={this.state.month}

            applyFilterAction={this.applyFilterAction}
            applyFilter={this.state.applyFilter}

            handleActivePage={this.handleActivePage}


            resetFilterStates={this.resetFilterStates}
          />
            {/* <div className="approval-body-box">
                <RetailerCouponApprovalFilter/>
            </div>
            <div className="box-table-approval">
              <RetailerCouponApprovalTable/>
            </div> */}
        </div>
        {this.state.NoOfPages > 1 ? (
                <div className="pagination-box">
                  <div className="pagination-inner-box">
                    <div className="box-pagination">
                      <div className="next-btn">
                        <div className="cont1">
                          <Link id="prevLink" to="#" onClick={this.handlePrev}>prev</Link>
                        </div>
                        <div style={{minWidth:300}} className="count-btn">
                        {items}
                        </div>
                        <div className="cont1">
                          <Link id="nextLink" to="#" onClick ={this.handleNext}>next</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
      </div>
    );
  }
}

export default RetailerCouponApproval;
