import React, { Component} from 'react'
import '../UserManagement/UserManagement.css'

class UserMessage extends Component {
    render() {
        var message = "Users not Found"
        if(this.props.filter.searchText !== "")
        message = "No users were found against " + this.props.filter.searchBy+": "+this.props.filter.searchText
        return (
            <div className="usr-m-box">
             
            <div className="empty-box">
            <h3>{message}</h3>
            </div>
            </div>

        )
    }
}

export default UserMessage
