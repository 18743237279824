export const GetFaceValuesActions= {
	
	GetFaceValues_GET :{
		NEW:"GetFaceValues_GET_NEW",
		SUCCESS:"GetFaceValues_GET_SUCCESS",
		FAILED:"GetFaceValues_GET_FALIURE",
		LOADING:"GetFaceValues_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const GetFaceValuesStatus ={

	GetFaceValues_GET :{
		NEW:"GetFaceValues_GET_NEW",
		SUCCESS:"GetFaceValues_GET_SUCCESS",
		FAILED:"GetFaceValues_GET_FALIURE",
		LOADING:"GetFaceValues_GET_LOADING",
	}

}
