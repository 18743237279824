import React, { Component, Fragment } from "react";

// import {Fonts} from '../../utils/Fonts';
import { GetCouponImagesStatus } from "./GetCouponImagesConstants";
import { withCookies } from "react-cookie";
// import DropDownList from "../../Inputs/LabelDropDownInput";
import ImageInput from "../../Inputs/SelectSearch/ImageInput";

// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import LoadingScreen from "../../Utils/Loading";
import { AcceptVideos } from "../../../Config/Config";
import UploadCouponImageModal from "../../Cards/UploadCouponImageModal";
import VideoIcon from "../../../../public/icons8-video-48.png";
let Videos = AcceptVideos.split(", ")

const initialDropdownValue = {
  value: 'upload_image', label: "Upload Image or Video"
}

export class GetCouponImagesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageName: "",
      ImageData: [],
      showUploadCouponModel: false
    };
    this.getCouponImages()
  }

  getCouponImages = () => {

    const data = {
      business_id: this.props.cookies.get("BusinessProfileData").business_id,
    };
    this.state.showUploadCouponModel = false
    this.props.GetCouponImages(data)

  }
  Go_Back=()=>{
    this.setState({
      showUploadCouponModel: false
    })
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    // alert(JSON.stringify(nextprops.CouponImages))
    if (nextprops.CouponImages.length > 0) {
      let ImageData = []


      if (nextprops.CouponImage.length === 0)
        nextprops.CouponImages.forEach(element => {
          let re = /(?:\.([^.]+))?$/;
          let ext = "." + (re.exec(element.name)[1])
          // alert(element.image_location)
          ImageData.push({ value: JSON.stringify(element), label: <div><img alt="not found" className={"avatar"} src={Videos.includes(ext) ? VideoIcon : element.image_location} /> {' '} {element.name} </div> })
        });
      else {
        // alert(JSON.stringify(nextprops.CouponImage))
        let lookup = {}
        if (nextprops.CouponImage.forEach)
          nextprops.CouponImage.forEach(item => lookup[JSON.parse(item.value).image_location] = true)
        nextprops.CouponImages.forEach(element => {
          let re = /(?:\.([^.]+))?$/;
          let ext = "." + (re.exec(element.name)[1])
          // alert(element.image_location)
          if (!lookup[element.image_location])
            ImageData.push({ value: JSON.stringify(element), label: <div><img alt="not found" className={"avatar"} src={Videos.includes(ext) ? VideoIcon : element.image_location} /> {' '} {element.name} </div> })
        });
      }
      ImageData.unshift(initialDropdownValue)
      this.state.showUploadCouponModel = false
      this.state.ImageData = ImageData

    }
    if(nextprops.GetCouponImages_GET_status === GetCouponImagesStatus.GetCouponImages_GET.SUCCESS && nextprops.CouponImages.length === 0)
    {
      let ImageData = []
      ImageData.unshift(initialDropdownValue)
      this.state.showUploadCouponModel = false
      this.state.ImageData = ImageData
    }
    // if(nextprops.CouponImage && nextprops.CouponImage.length>0 && nextprops.CouponImages.length > 0) {
    // const excludeMap = nextprops.CouponImage.reduce((all, item) => ({ ...all, [item]: true }), {});
    // alert(JSON.stringify(excludeMap))
    // this.state.ImageData  = arr.filter((item) => !excludeMap?.[item]);
    // const toRemove = new Set(nextprops.CouponImage);
    // this.state.ImageData = this.state.ImageData.filter( x => !toRemove.has(x.value) );
    // }
  }
  setImageChange = (e, i) => {
    console.log("kjsndajksnkasakjsdnasjknkasj ", e)
    // alert(JSON.stringify(e))
    if (i.option && i.option.label === "Upload Image or Video") {
      this.setState({
        showUploadCouponModel: true
      })
      return;
      // return this.setShowUploadCouponModel(true)
    }
    if (e) {
      var image = e.value;

      if (image !== "") {

        if (image == "upload_image") {
          //alert("show coupon model")
          this.setState({
            showUploadCouponModel: true
          })
          return;
          //this.props.setRetailerCouponImage(image, "");
        }
        else {
          console.log("this is on change value bladsllsad", e)

          let multiSelectOptions = []

          if (!image) {

            multiSelectOptions = e.map((item) => item.value)

          }
          else {
            // alert("hello")

            multiSelectOptions.push(e.value)

          }
          // alert("sakndkans "+JSON.stringify(multiSelectOptions))
          // alert("sakndkans "+e)
          this.props.setRetailerCouponImage(
            multiSelectOptions,
            e,
          );
        }

      } else {

        this.props.setRetailerCouponImage(image, "");
      }
      if (image && this.state.ImageName == "")
        this.setState({
          ImageName: image
        })
    } else {
      this.props.setRetailerCouponImage([], "");
      this.setState({
        ImageName: ""
      })
    }
  };
  // setImageChange = (e) => {
  //   // alert(e.value)
  //   var image = e.value;
  //   // alert(e.target.value);
  //   if (image !== "") {
  //     this.props.setRetailerCouponImage(
  //       JSON.parse(image).image_location,
  //       e
  //     );


  //   }
  //    else {
  //     this.props.setRetailerCouponImage(image, "");
  //   }
  // };
  // var image = e.value;
  //   // alert(e.target.value);
  //   if (image !== "") {
  //     this.props.setRetailerCouponImage(
  //       JSON.parse(image).image_location,
  //       e
  //     );

  //   } else {
  //     this.props.setRetailerCouponImage(image, "");
  //   }
  componentWillUnmount() {
    this.props.resetReducerState()
  }

  getScreen(status) {

    const imageSelectText = "Select Image OR Video"

    switch (status) {
      case GetCouponImagesStatus.GetCouponImages_GET.NEW:
        if (this.state.ImageData.length === 0 && this.props.CouponImages.length > 0)
          this.props.CouponImages.forEach(element => {
            let re = /(?:\.([^.]+))?$/;
            let ext = "." + (re.exec(element.name)[1])
            this.state.ImageData.push({ value: JSON.stringify(element), label: <div><img alt="not found" className={"avatar"} src={Videos.includes(ext) ? VideoIcon : element.image_location} /> {' '} {element.name} </div> })
          });
        return (
          <Fragment>
            <ImageInput
              label="Coupon Image (Optional)"
              type="text"
              placeholder={this.state.ImageData.length === 0 ? "No offer image available" : imageSelectText}
              name="CouponImage"
              inputValue={this.props.CouponImage}
              onChange={this.setImageChange}
              data={this.state.ImageData}
              isJSON={true}
              maxLimit={this.props.maxMediaLimit}
              disabled={this.state.ImageData.length === 0}
            />
            {/* <DropDownList
              label=""
              type="text"
              name="CouponImage"
              inputValue={this.state.imageName}
              onChange={this.setImageChange}
              data={[]}
              containerSty
              le = {{}}
            /> */}
          </Fragment>
        );
      case GetCouponImagesStatus.GetCouponImages_GET.SUCCESS:
        // alert(JSON.stringify(this.props.CouponImage))
        // alert(JSON.stringify(this.props.CouponImages))
        // if(this.props.CouponImage)
        // alert(this.props.CouponImage.length)
        // console.log("kasnddskfkdsnlklmlfkans:", this.props.CouponImages)
        // var disabled = false

        // let images = []

        //   this.props.CouponImages.forEach(element => {
        //     let re = /(?:\.([^.]+))?$/;
        //     let ext="."+(re.exec(element.name)[1])
        //     if(this.state.ImageData.length !== this.props.CouponImages.length)
        //     this.state.ImageData.push({value:JSON.stringify(element), label:<div><img alt="not found" className={"avatar"} src={Videos.includes(ext)?VideoIcon:element.image_location} /> {' '} {element.name} </div>})

        //     if(this.props.CouponImage !="" && this.state.imageName =="")
        //     {
        //       // alert(this.props.CouponImage)

        //         if(Array.isArray(this.props.CouponImage))
        //         {
        //           let re = /(?:\.([^.]+))?$/;
        //           let ext="."+(re.exec(element.name)[1])
        //           let flag=this.props.CouponImage.find(item=> item.image_location === element.image_location )
        //           if(flag)
        //           images.push({value:JSON.stringify(element), label:<div><img alt='not found' className={"avatar"} src={Videos.includes(ext)?VideoIcon:element.image_location}  /> {' '} {element.name} </div>})
        //           // alert(JSON.stringify(images))
        //         }
        //         if(element.image_location == this.props.CouponImage)
        //         images = [{value:JSON.stringify(element), label:<div><img alt='not found' className={"avatar"} src={element.image_location} /> {' '} {element.name} </div>}]
        //         // this.setImageChange({value:JSON.stringify(element), label:<div><img alt='not found' className={"avatar"} src={element.image_location} /> {' '} {element.name} </div>})

        //       if(images.length>0)
        //       {
        //           console.log("aksndkajsndkdsan: ",images)
        //         this.setImageChange(images)
        //       }

        //     }

        //   });

        // console.log("ssasxasxasx: ",  this.props.CouponImage)
        // alert(this.props.CouponImage)
        // if(this.props.CouponImage)
        // alert(JSON.stringify(this.props.CouponImage))
        // console.log("kasndkjans: ", /)
        // console.log("kasndkjans: ", this.state.ImageData)
        // this.state.ImageData
        return (
          <Fragment>
            <ImageInput
              label="Coupon Image (Optional)"
              type="text"
              placeholder={this.state.ImageData.length === 0 ? "No offer image available" : imageSelectText}
              name="CouponImage"
              inputValue={this.props.CouponImage}
              onChange={this.setImageChange}
              disabled={this.state.ImageData.length === 0}
              data={this.state.ImageData}
              isJSON={true}
              maxLimit={this.props.maxMediaLimit}
            />
        {this.state.showUploadCouponModel ? <UploadCouponImageModal Go_Back={this.Go_Back} getCouponImages={this.getCouponImages} BusinessProfileData={this.props.cookies.get("BusinessProfileData")} /> : <></>}
          </Fragment>
        );
        break;
      case GetCouponImagesStatus.GetCouponImages_GET.FAILED:
        if (this.state.ImageData.length === 0 && this.props.CouponImages.length > 0)
          this.props.CouponImages.forEach(element => {
            let re = /(?:\.([^.]+))?$/;
            let ext = "." + (re.exec(element.name)[1])
            this.state.ImageData.push({ value: JSON.stringify(element), label: <div><img alt="not found" className={"avatar"} src={Videos.includes(ext) ? VideoIcon : element.image_location} /> {' '} {element.name} </div> })
          });
        return (
          <Fragment>
            <ImageInput
              label="Coupon Image (Optional)"
              type="text"
              placeholder={this.state.ImageData.length === 0 ? "Failed to get Coupon Images" : imageSelectText}
              name="CouponImage"
              inputValue={this.props.CouponImage}
              onChange={this.setImageChange}
              data={this.state.ImageData}
              isJSON={true}
              disabled={this.state.ImageData.length === 0}
              maxLimit={this.props.maxMediaLimit}
            />

          </Fragment>
        );

      case GetCouponImagesStatus.GetCouponImages_GET.LOADING:
        return (

          <>

            <ImageInput
              label="Coupon Image (Optional)"
              type="text"
              placeholder={'Loading Images...'}
              name="CouponImage"
              LoadingIcon={true}
              loaderStyle={{ right: '50px', top: '-34px' }}
            />
          </>
        )
        break;
      default:
        break;
    }
  }
  render() {
    return (
      <Fragment>
        {this.getScreen(this.props.GetCouponImages_GET_status)}

      </Fragment>
    );
  }
}

export default withCookies(GetCouponImagesView);
