import React, {  Fragment, PureComponent } from 'react'
//import './Invoices.css'
import { InvoicesStatus } from './InvoicesConstants';
import InvoicesList from "./InvoicesList"
import { withRouter } from 'react-router-dom';
import { Api_Pagination } from "RefRecordsConstants";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../Utils/Loading';
// import BusinessName from "../BusinessName/BusinessName"

export class InvoicesView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      BusinessProfileData: this.props.cookies.get("BusinessProfileData"),
      mount: false,
      activePage:0
    }
    props.resetReducerState()
    // this.getInvoices()
  }
  
  
  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }
  getInvoices = () =>{

    const data = {
      business_id:this.state.BusinessProfileData.business_id
    }
    this.props.getInvoices(data)
  }
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
   // this.props.getInvoices()
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.activePage !== nextProps.activePage){
      // nextProps.setLoading(true)
      const data = {
        business_id:prevState.BusinessProfileData.business_id,
        offset : (nextProps.activePage - 1) * Api_Pagination.InvoiceListLimit
      }
      // alert(JSON.stringify(data))
      nextProps.getInvoices(data)

      return{
        activePage:nextProps.activePage
      }
   }
   
   return null;
 }
  getScreen(status) {
    switch (status) {
      case InvoicesStatus.Invoices_GET.NEW:
        return (
          <Fragment >
            <InvoicesList Invoices={this.props.Invoices}
            cookies={this.props.cookies}
            />
          </Fragment>
        );


      case InvoicesStatus.Invoices_GET.SUCCESS:
        //  alert(JSON.stringify(this.props.Invoices))
        this.props.setLoading(false)
          
        if(this.props.InvoicesCount > 0 && this.props.activePage == 1){
          this.props.setItemList(this.props.InvoicesCount)
        }
        return (
          <Fragment >
           
          
            <InvoicesList Invoices={this.props.Invoices}
            cookies={this.props.cookies}
            />
          </Fragment>
        );
        break;
      case InvoicesStatus.Invoices_GET.FAILED:
        toast.error(this.props.Invoices_Error)
        this.props.setLoading(false)
        this.props.setItemList(0)
        return (
          <Fragment>
            <InvoicesList 
              Invoices={this.props.Invoices}
            cookies={this.props.cookies}
            />

          </Fragment>
        );

      case InvoicesStatus.Invoices_GET.LOADING:
        this.props.setLoading(true)
 
        this.state.open = true
        return (
          <tr>
          <td></td>
          <td>
              <div className="box-invoice-load">
         <div style={{ textAlign: "center", width: "100%", float: "left" }}>
        <Loading/>
      </div>
      </div>
          </td>
          <td></td>
        </tr>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
    
   // alert(this.props.Invoices_GET_status)
    return (
      <Fragment>
      {this.getScreen(this.props.Invoices_GET_status)}
      </Fragment>
    )
  }
}

export default withRouter(InvoicesView)
