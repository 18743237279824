import { BannerListStatus, BannerListActions } from './BannerListConstants';

const BannerList_initialState = {
    BannerList_Status: BannerListStatus.BannerList_GET.NEW,
    BannerList_Error: '',
    BannerListDetails: [],
    weekBanners:[]
}
export default  function  (state = BannerList_initialState, action) {
    switch (action.type) {
        case BannerListActions.BannerList_GET.LOADING:
            return { ...state, BannerList_Status: BannerListStatus.BannerList_GET.LOADING }
            case BannerListActions.BannerList_GET.NEW:
                return { ...state, BannerList_Status: BannerListStatus.BannerList_GET.NEW, BannerListDetails: [] }
    
        case BannerListActions.BannerList_GET.FAILED:
            return { ...state,  BannerList_Status: BannerListStatus.BannerList_GET.FAILED, BannerList_Error:action.error}
        case BannerListActions.BannerList_GET.SUCCESS:
          
          return { ...state,  BannerList_Status: BannerListStatus.BannerList_GET.SUCCESS, BannerListDetails:action.payload,weekBanners:action.weekBanners}
          
        
        default:
            return { ...state,
                BannerList_Status: BannerListActions.BannerList_GET.NEW
            }
    }
}
