import React, { Component } from 'react';
import {  withRouter } from 'react-router-dom';
// import '../UserManagement/UserManagement.css';
import LabelInput from '../Inputs/LabelInput';

class EditEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',

      email: '',
      emailFlag: '',
      emailErrorMsg: 'Email Required',

      role: '',
      user_id: 0,
      old_email:'',
      // passType:"password"
    };
    // alert(JSON.stringify(this.props.cookies.get('NJTUsers')))
    if (this.props.cookies.get('NJTUsers')) {
        // alert(JSON.stringify(this.props.cookies.get('NJTUsers')));
      const NJTUser = this.props.cookies.get('NJTUsers');
      this.state.username = NJTUser.user_name;
      this.state.email = NJTUser.email_address;
      this.state.role = NJTUser.role_name;
      this.state.old_email = NJTUser.email_address
    }
    // this.props.cookies.set("NJTUsers"
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // todo: .......................... Email Handler ..........................
  emailsHandler = (e) => {

    
    const reg  = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]{2,10}$/;

    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({
        emailId: '',
        emailFlag: false,
      });
    } else {
      this.setState({
        emailFlag: true,
        emailErrorMsg: 'Enter Valid Email',
      });
    }
    this.setState({ email: e.target.value });
  };

  // todo: .......................... Update Email Handler ..........................
  updateHandler = () => {
    if (this.state.emailFlag || this.state.email === '') {
      this.setState({
        emailErrorMsg:
          this.state.emailFlag && this.state.email.length > 0
            ? // ? 'Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol'
              'Enter Valid Email'
            : 'Field Required',
        emailFlag: true,
      });
    } else if (this.props.cookies.get('NJTUsers')) {
      // alert(JSON.stringify(this.props.cookies.get('NJTUsers')))
      const data = {
        // password_digest: this.state.password,
        application_user_id: this.props.cookies.get('NJTUsers')
          .application_user_id,
        role_id: this.props.cookies.get('NJTUsers').role_id,
        email_address: this.state.email,
        user_name: this.state.username,
        phone: this.props.cookies.get('NJTUsers').phone,
        old_email:this.state.old_email
      };
      // alert(JSON.stringify(data))
      this.props.ChangeEmail(data);
      // alert(JSON.stringify(data))
    }
  };

  backHandler = () => {
    this.props.history.replace('/UserManagement');
  };

  render() {
    return (
      <div className='usr-m-box'>
        <div className='usr-m-conatiner'>
          <div className='user-m-heading'>
            <h5>Update Email</h5>
          </div>
          <div className='fiter-search-box change-pass'>
            <div className='inner-coantainer'>
              <div className='pass-change-box'>
                <div className='pass-input'>
                  <LabelInput
                    label='User Name'
                    type='text'
                    name='name'
                    inputValue={this.state.username}
                    //   flag={nameFlag}
                    //   errorMsg={nameErrorMsg}
                    onChange={this.nameHandler}
                    disabled
                  />
                </div>
                <div className='pass-input'>
                  <LabelInput
                    label='Role'
                    type='text'
                    name='role'
                    inputValue={this.state.role}
                    //   flag={roleFlag}
                    //   errorMsg={roleErrorMsg}
                    onChange={this.roleHandler}
                    disabled
                  />
                </div>

                <div className='pass-input'>
                  <LabelInput
                    label='Email'
                    type='email'
                    name='email'
                    inputValue={this.state.email}
                    flag={this.state.emailFlag}
                    errorMsg={this.state.emailErrorMsg}
                    onChange={this.emailsHandler}
                    // disabled
                  />
                </div>

                <div className='two-pass-btn'>
                  <button
                    className='filter-btn2 left'
                    onClick={this.backHandler}
                  >
                    Back
                  </button>
                  <button
                    className='filter-btn right'
                    onClick={this.updateHandler}
                    disabled={this.props.cookies.get('NJTUsers').email_address===this.state.email}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditEmail);
