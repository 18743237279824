import React, {  PureComponent } from "react";
// import RetailerCouponApprovalModel from "../Cards/RetailerCouponApprovalModel/RetailerCouponApprovalModel";
// import Dateformat from 'dateformat';
import RetailerCouponApprovalFilter from "../RetailerCouponApprovalFilter/RetailerCouponApprovalFilter";
import RetailerCouponApprovalTable from "../RetailerCouponApprovalTable/RetailerCouponApprovalTable";
import {withRouter} from 'react-router-dom'
// import RetailerCouponsInventoryContainer from "./RetailerCouponsInventory/RetailerCouponsInventoryContainer"
export class RetailerCouponsInventory extends PureComponent {
  constructor(props) {
    super(props)
    // var d = new Date()

    // var d1 = new Date(props.RetailerCouponsInventory.ref_data.TermDates.term_start_date)
    // if (d1 > d)
    //   d = d1
    this.state = {
      // SelectedPartner: "all",
      // SelectedFaceValue: "all",
      // // year: {value:Dateformat(d, 'yyyy'), name:Dateformat(d, 'yyyy')},
      // // month: {value:Dateformat(d, 'mmmm'), name:Dateformat(d, 'mmmm')},
    
      // year: "all",
      // month: "all",
      Retailers: [],
      FaceValues: []
    }

    this.state.Retailers = props.RetailerCouponsInventory.ref_data.Business_List.map((item, index) => { return { name: item.business_name, value: item.business_name } })
    this.state.Retailers =[{name:"all", value:"all"}, ...Array.from(new Set(this.state.Retailers.map(JSON.stringify))).map(JSON.parse)];
    
    // const faceValueSet = new Set(props.RetailerCouponsInventory.ref_data.FaceValues)

    // console.log("face values", faceValueSet)

    this.state.FaceValues = props.RetailerCouponsInventory.ref_data.FaceValues.map((item, index) => { return { name: item.face_value, value: item.face_value } })
    this.state.FaceValues = [{name:"all", value:"all"}, ...Array.from(new Set(this.state.FaceValues.map(JSON.stringify))).map(JSON.parse)];
    //console.log("face values", props.RetailerCouponsInventory.ref_data.FaceValues)


    console.log("years data", props.RetailerCouponsInventory.ref_data.years)
    console.log("years data", props.RetailerCouponsInventory.ref_data.months)


    
    //props.RetailerCouponsInventory.ref_data.years.unshift({value:'all', name:'all'})
    // this.props.ref_data.months.unshift({value:'all', name:'all'})

    // if (this.state.Retailers.length > 0)
    //   this.state.SelectedPartner = this.state.Retailers[0].name
    // if (this.state.FaceValues.length > 0)
    //   this.state.SelectedFaceValue = this.state.FaceValues[0].

      // alert(this.state.SelectedPartner)
      // alert(this.state.SelectedFaceValue)
      // alert(this.state.year)
      // alert(this.state.month)
      

  }

//   setYear = (e)=>{
//     // alert(JSON.stringify(e.target.value))
//     this.setState({
//       year:e.target.value
//     })
//   }

//   setMonth = (e)=>{
//     // alert(JSON.stringify(e.target.value))
//     this.setState({
//       month:e.target.value
//     })
//   }
// setFaceValue = (e)=>{
//   // alert(e.target.value)
//   this.setState({
//     SelectedFaceValue:e.target.value
//   })
// }
//   setPartner=(e)=>{
//     // alert(e.target.value)
//     this.setState({
//       SelectedPartner:e.target.value
//     })
//   }


  render() {
    // alert(this.props.history)
    const totalYears = [{value:'all', name:'all'}, ...this.props.RetailerCouponsInventory.ref_data.years]
    const totalMonths = [{value:'all', name:'all'}, ...this.props.RetailerCouponsInventory.ref_data.months]
// alert(JSON.stringify(this.props.RetailerCouponsInventory.Inventory))

    console.log("these are records", this.props.RetailerCouponsInventory.Inventory)
    
    return (
      <div>
        <div className="approval-body-box">
          <RetailerCouponApprovalFilter
          ref_data={this.props.RetailerCouponsInventory.ref_data}
          totalMonths={totalMonths}
          totalYears={totalYears}

          Retailers={this.state.Retailers}
          FaceValues={this.state.FaceValues}
          // ** States from filter table ***
          year={this.props.year}
          setYear={this.props.setYear}
          month={this.props.month}
          setMonth = {this.props.setMonth}
          SelectedPartner={this.props.SelectedPartner} 
          setPartner = {this.props.setPartner}
          SelectedFaceValue = {this.props.SelectedFaceValue}
          setFaceValue = {this.props.setFaceValue}

          applyFilter={this.props.applyFilter}
          applyFilterAction={this.props.applyFilterAction}
          />
        </div>
        <div className="box-table-approval">
          <RetailerCouponApprovalTable
            getRetailerCouponsInventory={this.props.getRetailerCouponsInventory}
            ref_data={this.props.RetailerCouponsInventory.ref_data}
            Inventory={this.props.RetailerCouponsInventory.Inventory}
            activePage={this.props.activePage}
            history={this.props.history}
            month = {this.props.month}
            year={this.props.year}
            SelectedPartner ={this.props.SelectedPartner}
            SelectedFaceValue = {this.props.SelectedFaceValue}
            
            // ** reset filter states when coupon is approved or declined ****
            resetFilterStates={this.props.resetFilterStates}
          />
        </div>

       
      </div>
    );
  }
}

export default withRouter(RetailerCouponsInventory);
