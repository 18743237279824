import React, { Component } from "react";
import UploadBannerHeader from "../UploadBanner/UploadBannerHeader/UploadBannerHeader";
import "../UploadBannerManage/UploadBannerManage.css";
import SelectSearchInput from "../Inputs/SelectSearch/SelectSearchInput";
import CheckBoxSingle from "../Inputs/CheckBoxSingle/CheckBoxSingle";
import { Link, withRouter } from "react-router-dom";
import {failure_messages} from "Messages"
import Snackbar from "@material-ui/core/Snackbar/";
import UploadBannerCard from "../Cards/UploadBannerCard/UploadBannerCard";
import RetailerListContainer from "../RetailerList/RetailerListContainer";
import BannerListContainer from "../BannerList/BannerListContainer";
// import dateFormat from "dateformat";
import moment from "moment"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export class UploadBannerManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      RetailerList: [],
      Retailer: "",
      bannerList: [],
      showExpired: false,
      bannerDelted:false,
      open:false,
      isLoading:true
    };
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setExpired = () => {
    this.setState({
      showExpired: !this.state.showExpired
    });
  };

  setRetailerList = RetailerList => {
    this.setState({ RetailerList });
  };

  searchRetailerHandler = e => {
    this.setState({ Retailer: e });
  };

  setBannerList = data => {
    this.setState({
      bannerList: data,
      isLoading:false
    });
  };

  edit_Banner = banner => {

    if (new Date() < new Date(banner.end_date)) {
    this.props.cookies.remove("banner_duplicate", { path: "/" });
    this.props.cookies.remove("banner_edit", { path: "/" });
    this.props.cookies.set("banner_edit", banner, { path: "/" });
    this.props.history.push("/Upload_Banner_Form");
    }else{
      toast.error("You can’t edit an expired banner")
    }
  };

  duplicate_Banner = banner => {
    if (new Date() > new Date(banner.end_date)) {
      this.props.cookies.remove("banner_duplicate", { path: "/" });
      this.props.cookies.remove("banner_edit", { path: "/" });
      this.props.cookies.set("banner_duplicate", banner, { path: "/" });
      this.props.history.push("/Upload_Banner_Form");
    }else{
      toast.error("you can’t duplicate an active banner")
    }
  };

  upload_Banner = () => {
    this.props.cookies.remove("banner_duplicate", { path: "/" });
    this.props.cookies.remove("banner_edit", { path: "/" });
    this.props.history.push("/Upload_Banner_Form");
  };

  render() {
    // alert(this.state.isLoading)
    console.log("banner list", this.state.bannerList);

    const bannerList = this.state.bannerList
    // .sort(function(a, b){
    //   if(a.business_name.toLowerCase() > b.business_name.toLowerCase())
    //   return 1
    //   if(b.business_name.toLowerCase() > a.business_name.toLowerCase())
    //   return -1
    //   return 0
    // })
    .filter(e => {
      
      var flag = false;
      if (this.state.showExpired) flag = true;
      else if (new Date(e.end_date) > new Date()) flag = true;
      if (this.state.Retailer != "" && this.state.Retailer != "show_all" && flag)
        flag = e.business_id == this.state.Retailer;
      return flag;
    })
    const expiredBanners =this.state.bannerList
    .filter(e => {
      var flag = true;
      // if (new Date() < new Date(e.end_date)) flag = true;
      flag = (new Date() < new Date(e.end_date))
      // if (flag)
      // flag = e.number_of_days != e.TotalAllocated
      return flag;
    })
    // alert(JSON.stringify(bannerList))

    return (
      <div className="upload-manage-wrap">
        <UploadBannerHeader heading="Manage Banners" />
        <div className="upload-manage-wrap2">
          <div className="upload-manage-container">
            <div className="upload-manage-box">
              <div className="existing-banner">
                <div className="existing-label">Existing Banners</div>
                <div className="existing-input">
                  <SelectSearchInput
                    placeholder="Search for Partner Banners"
                    inputValue={this.state.Retailer}
                    onChange={this.searchRetailerHandler}
                    data={this.state.RetailerList}
                  />
                  <RetailerListContainer
                    setRetailerList={this.setRetailerList}
                    showAll={true}
                  />
                </div>
              </div>
              <div className="upload-check-box">
                <CheckBoxSingle
                  value={this.state.showExpired}
                  onChange={this.setExpired}
                  label = "Expired Banners"
                  id="expiredBanners"
                />
              </div>
              <div className="upload-two-button-box">
                <div className="upload-butto1">
                  <a id="upload-banner-id" onClick={this.upload_Banner}>New Upload</a>
                  {/* to="/Upload_Banner_Form" */}
                </div>
                <div className="upload-butto1 margin">
                  <Link 
                  id="scheduleBanners"
                  to={expiredBanners.length>0?"banner_Scheduler":"#"}
                  >Schedule Banners</Link>
                </div>
              </div>
            </div>
            <div className="card-list">
              <div className="upload-card-wrap">
                <div className="upload-card-container">
                  <div className="upload-card-box">
                    {bannerList.length > 0?bannerList
                    
  
                      .map((res, index) => (
                        <div key={res.banner_id}>
                        
                        <UploadBannerCard
                          bannerImage={res.banner_location}
                          bannerTitle={res.name}
                          expiryDate={moment(res.end_date).format("MM/DD/YYYY")
                              // dateFormat(res.end_date, "mm/dd/yyyy")
                            }
                            startDate={moment(res.start_date).format("MM/DD/YYYY")
                              // dateFormat(res.start_date, "mm/dd/yyyy")
                            }

                          banner={res}
                          number_of_days={res.number_of_days}
                          key={res.banner_id}
                          edit_Banner={this.edit_Banner}
                          duplicate_Banner={this.duplicate_Banner}
                          allocation ={res.TotalAllocated}
                          RetailerName = {res.business_name}
                          notes = {res.notes}
                         // setBannerDeleted={this.setBannerDeleted}
                        />
                         {/* {index > 0 &&
                              bannerList[index - 1].business_name !=
                              bannerList[index].business_name ? (
                                <h3 class="retailer-name-h">
                                  {res.business_name}
                                </h3>
                              ) : index == 0 ? (
                                <h3 class="retailer-name-h">
                                  {res.business_name}
                                </h3>
                              ) : (
                                <div className="empty-retailer"></div>
                              )}
                            </div> */}
                          </div>
                      )):!this.state.isLoading?
                      <p className="banner-empty">No Banners Uploaded Yet</p>:null
                    }
                  <BannerListContainer setBannerList={this.setBannerList}/>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={this.state.open}
          autoHideDuration={2000}
          onClose={() => this.setState({ open: false })}
          //  TransitionComponent={<Slide {...props} direction="up" />}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{failure_messages.DuplicateActiveBanner}</span>}
        />
      </div>
    );
  }
}

export default withRouter(UploadBannerManage);
