import {
  MessageHistoryStatus,
  MessageHistoryActions
} from "./MessageHistoryConstants";

const MessageHistory_initialState = {
  MessageHistory_Status: MessageHistoryStatus.MessageHistory_Get.NEW,
  MessageHistory_Error: "",
  MessageHistoryDetails: [],
  notificationListCount:0
};
export default function(state = MessageHistory_initialState, action) {
  switch (action.type) {
    case MessageHistoryActions.MessageHistory_Get.LOADING:
      return {
        ...state,
        MessageHistory_Status:
          MessageHistoryStatus.MessageHistory_Get.LOADING
      };
    case MessageHistoryActions.MessageHistory_Get.NEW:
      return {
        ...state,
        MessageHistory_Status:
          MessageHistoryStatus.MessageHistory_Get.NEW,
        MessageHistoryDetails: []
      };
    case MessageHistoryActions.MessageHistory_Get.SUCCESS:
      return {
        ...state,
        MessageHistory_Status:
          MessageHistoryStatus.MessageHistory_Get.SUCCESS,
        MessageHistoryDetails: action.payload, notificationListCount:action.notificationListCount
      };
    case MessageHistoryActions.MessageHistory_Get.FAILED:
      return {
        ...state,
        MessageHistory_Status:
          MessageHistoryStatus.MessageHistory_Get.FAILED
      };
  
    default:
      return {
        ...state,
      };
  }
}
