
//******************Exchange Rate Server Calls******************;

import { ROOT_URL ,getCookie} from '../../Config/Config';
import 'react-toastify/dist/ReactToastify.min.css';
import { failure_messages } from 'Messages';
import { toast } from 'react-toastify';
export const Server = {
  //  getyears:getyears,
  getMaxCouponCreateLimit: getMaxCouponCreateLimit,
  //  updateyears:updateyears
};

//******************Insert yearss******************;

function getMaxCouponCreateLimit(data) {
  // alert(data)
  const request = fetch(ROOT_URL + '/api/ref_globals/getCouponCreateLimit', {
    method: 'POST',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then((response) => {

    response.json().then(response => {

      if (response.status == "200") {

        if (response.data)
          {

            try
            {
                // data.setCouponThresholdLimit(parseInt(response.data.global_value))
                data.setCouponThresholdLimit(response)
            }catch(e){
                console.log(e)
                // data.setMaxCouponsLimit('')
                data.setCouponThresholdLimit(false)
            }
          }
        else {
            toast.error(failure_messages.CouponCreateLimit)
           
          }

           
        
        return;
      } else {
        toast.error(failure_messages.CouponCreateLimit)
           
        return;
      }
    });
  }).catch((error) => {
    toast.error(failure_messages.CouponCreateLimit)
           
  })
  //    return {type:yearsActions.years_GET.LOADING}

};

//******************Get yearss******************;


//******************Update years******************;


// function getyears(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.years.length>0)
//              store.dispatch({type:yearsActions.years_Get.SUCCESS,payload:response.years});
//              else
//              store.dispatch({type:yearsActions.years_GET.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:yearsActions.years_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:yearsActions.years_Get.FAILED});
//           })
//        return {type:yearsActions.years_GET.LOADING}

//     };

// function updateyears(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:yearsActions.years_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:yearsActions.years_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:yearsActions.years_GET.FAILED});
//     })
// return {type:yearsActions.years_GET.LOADING}

// };
