import React, { Component, Fragment } from "react";
//import './BusinessCouponQuotas.css'
import { BusinessCouponQuotasStatus } from "./BusinessCouponQuotasconstants";

import BusinessCouponQuotasForm from "./BusinessCouponQuotasForm";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Loading from "../Utils/Loading";

export class BusinessCouponQuotasView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      // QuotaToUpdate:[]
      // prevYearData: [],
    };
    props.resetReducerState();
    
     props.set_Goto_ManageCoupons(false,false)
  }
 
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  closePostSnackBar = () => {
    this.setState({
      open: false,
    });
    //  this.props.getBusinessCouponQuotasList()
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.BusinessCouponQuotas_post_status === BusinessCouponQuotasStatus.BusinessCouponQuotas_Get.SUCCESS){
      var lookup = {};
      var types = {};
      for (var j in nextProps.BusinessCouponQuotas) {
         lookup[nextProps.BusinessCouponQuotas[j].face_value_id] = nextProps.BusinessCouponQuotas[j].face_value_id;
         if (!types[nextProps.BusinessCouponQuotas[j].face_value_id]) {
          types[nextProps.BusinessCouponQuotas[j].face_value_id] = 1;
      } else {
          types[nextProps.BusinessCouponQuotas[j].face_value_id] += 1;
      }
    }

      for (var i in nextProps.GetFaceValues) {
        if (typeof lookup[nextProps.GetFaceValues[i].face_value_id] != 'undefined') 
        {

          //alert("contains"+ nextProps.GetFaceValues[i].face_value_id)
        }
        else
        {

          nextProps.BusinessCouponQuotas.splice(i, 0,  {...nextProps.GetFaceValues[i], needsInsert:true, coupon_max_quota:"",
          coupon_max_quota_err :"Field Required",
          coupon_max_quota_errFlag:true})

        }
       }
    }
    return null
  }

  getScreen(status) {
    // alert(status)
    switch (status) {
      case BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.NEW:
        
        return <Fragment></Fragment>;
        case BusinessCouponQuotasStatus.BusinessCouponQuotas_Get.LOADING:
          this.state.Loading =true
      case BusinessCouponQuotasStatus.BusinessCouponQuotas_Get.SUCCESS:
        if(this.state.Loading)
       { 
        this.state.Loading =false
     
      }
        return <Fragment></Fragment>;

      case BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.SUCCESS:
        toast.success(this.props.BusinessCouponQuotas_Message);

        if (this.props.Goto_ManageCoupons) 
          this.props.history.push({
            pathname: "/ManageCouponInventory",
          });
         else if(this.props.Goto_OverridePricing)
         this.props.history.push({
          pathname: "/OverridePricing",
        });
         
        this.props.setLoading(false)
        this.props.resetQuotas();
        return <Fragment></Fragment>;
        break;

      case BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.FAILED:
        if(this.props.isLoading)
        toast.error(this.props.BusinessCouponQuotas_Message);
        this.props.setLoading(false)

        this.props.resetQuotas();
        return <Fragment></Fragment>;
        break;
      case BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.LOADING:
        this.state.Loading = true
        this.state.QuotaToUpdate = []
      this.props.setLoading(true)
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              height: "40vh",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        );
        break;
      default:
        return <Fragment />;
    }
  }
  render() {
    //alert(this.props.QuotaList.length)

    return (
      <Fragment>
        {this.props.BusinessCouponQuotas_post_status !=
        BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.LOADING ? (
          <BusinessCouponQuotasForm
            saveBusinessCouponQuotas={this.props.saveBusinessCouponQuotas}
            resetReducerState={this.props.resetReducerState}
            QuotaList={this.props.QuotaList}
            cookies={this.props.cookies}
            switchCopyMode={this.props.switchCopyMode}
            getBusinessCouponQuotasList={this.props.getBusinessCouponQuotasList}
            updateBusinessCouponQuotas={this.props.updateBusinessCouponQuotas}
            BusinessCouponQuotas={this.props.BusinessCouponQuotas}
            editMode={this.props.editMode}
            RecentlySaved={this.props.RecentlySaved}
            setRecentlySaved={this.props.setRecentlySaved}
            setQuotas={this.props.setQuotas}
            status={this.props.status}
            setPrevYearData={this.props.setPrevYearData}
            prevYearData={this.props.prevYearData}
            set_Goto_ManageCoupons={this.props.set_Goto_ManageCoupons}
            isLoading = {this.props.isLoading}
          />
        ) : null}
        {this.getScreen(this.props.BusinessCouponQuotas_post_status)}
      </Fragment>
    );
  }
}

export default withRouter(BusinessCouponQuotasView);
