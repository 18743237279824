import React, { Component } from 'react'
import "../HeaderSection/InnerHeaderBranding.css"
import { Link, withRouter } from "react-router-dom";
// import AdministrationLogout from '../AdministrationMain/AdministrationLogout/AdministrationLogoutContainer'

class InnerHeaderBranding extends Component {
    constructor(props){
        super(props)
        this.state = {
            isSignIn: false,
        }
     //   alert(this.props.cookies)
    }

    static getDerivedStateFromProps(nextProps, prevState){
         if(nextProps.location.pathname == "/")
         return { isSignIn: false};
         else
         return { isSignIn: true};
        
     }
    render() {
        return (
            <div className="in-header-wrap ihb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                        {/* <div className="in-header-left"> */}
                        <h1><Link className="in-header-link ihb" to={this.state.isSignIn ?"/Admin":"/"}>NJ Transit Rewards Program</Link></h1>
                        </div>
                    </div>
                </div>
                {/* <div className="in-header-container">
                    <div className="in-header-left">
                        <h1><Link className="in-header-link" to={this.state.isSignIn ?"/Admin":"/"}>NJ Transit Rewards Program</Link></h1>
                    </div>
                    
                </div> */}
            </div>
        )
    }
}

export default  withRouter(InnerHeaderBranding)
