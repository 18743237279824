import React, { Component } from 'react'
import './BoxInput.css';
export class BoxInput extends Component {
    render() {

        const { name, type, placeholder } = this.props
        return (
            <div className="box_input_container">
                <input className='box_input' onChange={this.props.onChange} type={type} name={name} placeholder={placeholder} />
            </div>
        )
    }
}

export default BoxInput
