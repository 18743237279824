import React, { Component, Fragment } from "react";
//import './OverridePricing.css'
import { OverridePricingStatus } from "./OverridePricingConstants";
import OverridePricing from "../OverridePricing";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingScreen from "../../Utils/Loading";
// import ProgressBarFive from "../../ProgressBar/ProgressBarFive";
import ProgressBarFour from "../../ProgressBar/ProgressBarFour";
import BusinessName from "../../BusinessName/BusinessName"
export class OverridePricingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      override: true,
    };
    //props.resetReducerState()
  }

  getScreen(status) {
    switch (status) {
      case OverridePricingStatus.OverridePricing_Post.NEW:
      
        return (
          <OverridePricing
            GenerateOverridePriceCoupons={this.props.GenerateOverridePriceCoupons}
            cookies={this.props.cookies}
            createDraft={this.props.createDraft}
            GenerateCouponsFromDraft={this.props.GenerateCouponsFromDraft}
            updateDraft={this.props.updateDraft}
            updateExpiryDate={this.props.updateExpiryDate}
          />
        );

      case OverridePricingStatus.OverridePricing_Post.SUCCESS:
        // alert("Success")
        // this.props.resetReducerState()
        toast.success(this.props.message);
        this.props.resetReducerState();
        return (
          <Fragment>
            <OverridePricing
              GenerateOverridePriceCoupons={this.props.GenerateOverridePriceCoupons}
              CouponsGenerationData={this.props.CouponsDetails}
              Generated={this.props.Generated}
              cookies={this.props.cookies}
              createDraft={this.props.createDraft}
              GenerateCouponsFromDraft={this.props.GenerateCouponsFromDraft}
              updateDraft={this.props.updateDraft}
              updateExpiryDate={this.props.updateExpiryDate}
            />
            {/* <SnackBar title={"Coupons Generated Successfully"} /> */}
          </Fragment>
        );
        break;
      case OverridePricingStatus.OverridePricing_Post.FAILED:
        toast.error(this.props.message);
        this.props.resetReducerState();
        return (
          <Fragment>
            <OverridePricing
              GenerateOverridePriceCoupons={this.props.GenerateOverridePriceCoupons}
              cookies={this.props.cookies}
              createDraft={this.props.createDraft}
              GenerateCouponsFromDraft={this.props.GenerateCouponsFromDraft}
              updateDraft={this.props.updateDraft}
              updateExpiryDate={this.props.updateExpiryDate}
            />
            {/* <SnackBar title={"Failed to Generate new Coupons"} /> */}
          </Fragment>
        );
        break;
      case OverridePricingStatus.OverridePricing_Post.LOADING:
        return <LoadingScreen />;
        break;
      default:
        break;
    }
  }
  render() {

    return (
      <Fragment>
        <BusinessName/>
          <ProgressBarFour
            ProgressBar1="active"
            ProgressBar2="active"
            ProgressBar3="active"
            ProgressBar4="active"
          />
      
        {this.getScreen(this.props.OverridePricing_post_status)}
      </Fragment>
    );
  }
}

export default OverridePricingView;
