import { RetailerCouponsInventoryStatus, RetailerCouponsInventoryActions } from './RetailerCouponsInventoryConstants';

const RetailerCouponsInventory_initialState = {
    RetailerCouponsInventory_Status: RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.NEW,
    RetailerCouponsInventory_Error: '',
    RetailerCouponsInventoryDetails: [],
    RetailerCouponsInventoryCount: 0
}
export default  function  (state = RetailerCouponsInventory_initialState, action) {
    switch (action.type) {
        case RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.LOADING:
            return { ...state, RetailerCouponsInventory_Status: RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.LOADING }
            case RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.NEW:
                return { ...state, RetailerCouponsInventory_Status: RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.NEW, RetailerCouponsInventoryDetails: [] }
    
        case RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.FAILED:
            return { ...state,  RetailerCouponsInventory_Status: RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.FAILED, RetailerCouponsInventory_Error:action.error, RetailerCouponsInventoryCount: 0}
        case RetailerCouponsInventoryActions.RetailerCouponsInventory_GET.SUCCESS:
          
          return { ...state,  RetailerCouponsInventory_Status: RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.SUCCESS, RetailerCouponsInventoryDetails:action.payload, RetailerCouponsInventoryCount: action.RetailerCouponsInventoryCount}
          
        
        default:
            return { ...state,
                
            }
    }
}
