import React, {  Fragment, PureComponent } from 'react'
//import './BannerList.css'
import { BannerListStatus } from './BannerListConstants';
import { DeleteBannerStatus } from '../UploadBannerManage/DeleteBanner/DeleteBannerConstants';

// import Loading from '../Utils/Loading';

export class BannerListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
    props.getBannerList({setLoading:props.setLoading,setBannerList:props.setBannerList})
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.DeleteBanner_POST_status === DeleteBannerStatus.DeleteBanner_POST.SUCCESS){
      nextProps.getBannerList({setLoading:nextProps.setLoading,setBannerList:nextProps.setBannerList})
   }
   return null;
 }
  getScreen(status) {
    switch (status) {
      case BannerListStatus.BannerList_GET.NEW:
        
        return (
          <Fragment >
            
          </Fragment>
        );


      case BannerListStatus.BannerList_GET.SUCCESS:
          // if(this.props.setLoading)
          // this.props.setLoading(false)

          // alert(JSON.stringify(this.props.BannerList))
          // this.props.setBannerList(this.props.BannerList, this.props.weekBanners)
          
        return (
          <Fragment >
            
          </Fragment>
        );
        break;
      case BannerListStatus.BannerList_GET.FAILED:
        // if(this.props.setLoading)
        // this.props.setLoading(false)
        return (
          <Fragment>
           <p>Unable to get list of banners</p>
          </Fragment>
        );
      case BannerListStatus.BannerList_GET.LOADING:
      
        // if(this.props.setLoading)
        // this.props.setLoading(true)
        // if(this.props.stopLoading)
        // return <div></div>
        // else
        return (
          // <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%" }}>
          //   <Loading/>
          // </div>
          <></>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.BannerList_GET_status)
    return (
      this.getScreen(this.props.BannerList_GET_status)
    )
  }
}

export default (BannerListView)
