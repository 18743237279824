import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import '../Cards/PreviewCouponCard.css';
import Loading from "../Utils/SpinnerGrow";
const modalRoot = document.getElementById('preview_coupon_portal');

const bodyRef = React.createRef();
// const createPdf = () => props.createPdf(bodyRef.current);

export class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    
    };
    
  }
 
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }
  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  render() {
   
    return ReactDOM.createPortal(
      <div
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.3)',
          minHeight: '100vh',
          // overflow: 'hidden',
          zIndex: '9999999999',
        }}
        // onClick={this.props.onClose}
      >
        {/* <div
          style={{
            padding: 20,
            background: '#fff',
            borderRadius: '8px',
            display: 'inline-block',
            minHeight: '300px',
            margin: '1rem',
            position: 'relative',
            minWidth: '450px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            justifySelf: 'center',
          }}
        > */}
          <div style={{ padding: 20,
            background: '#fff',
            borderRadius: '8px',
            display: 'inline-block', justifySelf: 'center' , width:200}} className='box-sss-scroll'>
              <h5>Uploading file:</h5>
              <Loading />
           </div>
           {/* </div> */}
      </div>,
      modalRoot
    );
  }
}
