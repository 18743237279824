export const BusinessContractActions= {
	
	
	BusinessContract_Get :{
		NEW:"BusinessContract_Get_NEW",
		SUCCESS:"BusinessContract_Get_SUCCESS",
		FAILED:"BusinessContract_Get_FALIURE",
		LOADING:"BusinessContract_Get_LOADING",
		NOT_AUTHORIZED: "BusinessContract_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	BusinessContract_Post :{
		NEW:"BusinessContract_Post_NEW",
		SUCCESS:"BusinessContract_Post_SUCCESS",
		FAILED:"BusinessContract_Post_FALIURE",
		LOADING:"BusinessContract_Post_LOADING",
		GOTO_QUOTAS: "BusinessContract_Get_GOTO_QUOTAS",	
		OFFLINE:"NO_INTERNET"
	}
}


export const BusinessContractStatus ={

	BusinessContract_Get :{
		NEW:"BusinessContract_Get_NEW",
		SUCCESS:"BusinessContract_Get_SUCCESS",
		FAILED:"BusinessContract_Get_FALIURE",
		LOADING:"BusinessContract_Get_LOADING",
		NOT_AUTHORIZED: "BusinessContract_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	BusinessContract_Post :{
		NEW:"BusinessContract_Post_NEW",
		SUCCESS:"BusinessContract_Post_SUCCESS",
		FAILED:"BusinessContract_Post_FALIURE",
		LOADING:"BusinessContract_Post_LOADING",
		NOT_AUTHORIZED: "BusinessContract_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
