
//******************Exchange Rate Server Calls******************;

import {GetFaceValuesActions} from './GetFaceValuesconstants';
import store from '../../../Store/store'
import {ROOT_URL, getCookie} from '../../../Config/Config';


export const GetFaceValuesServer = {
  //  getGetFaceValues:getGetFaceValues,
  getGetFaceValues:getGetFaceValues,
  //  updateGetFaceValues:updateGetFaceValues
};

//******************Insert GetFaceValuess******************;

function getGetFaceValues(Id){
    // alert(Id)
      const request=fetch(ROOT_URL+'/api/coupons/getFacevaluesByCouponTypeId', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({Id:Id})
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
              response.data.sort((a,b) => (a.face_value > b.face_value) ? 1 : ((b.face_value > a.face_value) ? -1 : 0))
            store.dispatch({type:GetFaceValuesActions.GetFaceValues_GET.SUCCESS,payload:response.data});
            return ;
            }else
            {
              store.dispatch({type:GetFaceValuesActions.GetFaceValues_GET.FAILED});
              return ;
              }    
           });
          }).catch((error) => {
           store.dispatch({type:GetFaceValuesActions.GetFaceValues_GET.FAILED});
        })
      return {type:GetFaceValuesActions.GetFaceValues_GET.LOADING}

};
  
//******************Get GetFaceValuess******************;


//******************Update GetFaceValues******************;

  
// function getGetFaceValues(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.GetFaceValues.length>0)
//              store.dispatch({type:GetFaceValuesActions.GetFaceValues_Get.SUCCESS,payload:response.GetFaceValues});
//              else
//              store.dispatch({type:GetFaceValuesActions.GetFaceValues_GET.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:GetFaceValuesActions.GetFaceValues_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:GetFaceValuesActions.GetFaceValues_Get.FAILED});
//           })
//        return {type:GetFaceValuesActions.GetFaceValues_GET.LOADING}
 
//     };
  
// function updateGetFaceValues(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:GetFaceValuesActions.GetFaceValues_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:GetFaceValuesActions.GetFaceValues_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:GetFaceValuesActions.GetFaceValues_GET.FAILED});
//     })
// return {type:GetFaceValuesActions.GetFaceValues_GET.LOADING}

// };
