import { BusinessProfileStatus, BusinessProfileActions } from './BusinessProfileconstants';

const BusinessProfile_initialState = {
    BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.NEW,
    BusinessProfile_Message: '',
    GoToContract: false,
    BusinessProfileDetails: {},
    FormData:false
}
export default function (state = BusinessProfile_initialState, action) {
    switch (action.type) {
        case BusinessProfileActions.BusinessProfile_Post.LOADING:
            return { ...state, BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.LOADING }
        case BusinessProfileActions.BusinessProfile_Post.NEW:
            return { ...state, BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.NEW, GoToContract: false, BusinessProfileDetails: {}, FormData:false}
        
        case BusinessProfileActions.BusinessProfile_Post.FAILED:
           // alert("test")
            return { ...state, BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.FAILED, BusinessProfile_Message:action.error, FormData: action.payload}
        case BusinessProfileActions.BusinessProfile_Post.SUCCESS:
            //  state.BusinessProfileDetails.push({})
            return { ...state, BusinessProfile_Status: BusinessProfileStatus.BusinessProfile_Post.SUCCESS, BusinessProfileDetails: action.payload, BusinessProfile_Message:action.message, FormData:false }
        case BusinessProfileActions.BusinessProfile_Post.GOTO_CONTRACTS:
            return { ...state, GoToContract: true }

        default:
            return { ...state }
    }
}
