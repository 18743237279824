import { connect } from "react-redux";
import ListOfApplicants_View from "./ListOfApplicants_View";
import { ListOfApplicantsServer } from "./server";
// import { ListOfApplicantsActions } from "./ListOfApplicantsConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    ListOfApplicants_Get_status:state.ListOfApplicantsReducer.ListOfApplicants_Status,
    ListOfApplicants:state.ListOfApplicantsReducer.ListOfApplicantsDetails,
    ApplicantsCount:state.ListOfApplicantsReducer.ApplicantsCount
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getListOfApplicants: (data) => {
      dispatch(ListOfApplicantsServer.getListOfApplicants(data));
    }
  };
};
const ListOfApplicantsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfApplicants_View);

export default ListOfApplicantsContainer;
