import React, { Component } from 'react'
import './LegendInput.css';
export class LegendInput extends Component {
    render() {

        const { name, type, placeholder } = this.props
        return (
            <div className="legend_input_container">

                <fieldset className="legend_fieldset">
                    <legend className="legend_heading">{placeholder}</legend>
                    <input className='legend_input' type={type} name={name} />
                </fieldset>
                <p className="legend_edit">Edit</p>
            </div>
        )
    }
}

export default LegendInput
