import React, { Component, Fragment } from 'react'
import { GetReferralInvoicesFlagStatus } from './GetReferralInvoicesFlagConstants';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoadingScreen from "../../Utils/Loading";
import { withCookies } from 'react-cookie';

export class GetReferralInvoicesFlagView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      callBack:false,
      // maxUnusedQuota:props.maxUnusedQuota
    }
    props.GetReferralInvoicesFlag(props.setParentState)
  
  }
  
  
  getScreen(status) {
    switch (status) {
      case GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.NEW:
       
        return (
          <Fragment >
              <LoadingScreen style={{"display":"none"}}/>
          </Fragment>
        );


      case GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.SUCCESS:
        // alert(JSON.stringify(this.props.DisplayTypes))
       
        
        return (
          <Fragment >
           
          </Fragment>
        );
        break;
      case GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.FAILED:
        
        return (
          <Fragment>

         
          </Fragment>
        );

      case GetReferralInvoicesFlagStatus.GetReferralInvoicesFlag_GET.LOADING:
        return (
          <LoadingScreen style={{"display":"none"}}/>
        );
        break;
      default:
        break;
    }

  }
  render() {
    
    return (
      
        this.getScreen(this.props.GetReferralInvoicesFlag_GET_status)
      
    )
  }
}

export default withCookies(GetReferralInvoicesFlagView)
