import React, { Component, Fragment } from "react";
//import './ApprovalCheckList.css'
import { ApprovalCheckListStatus } from "./ApprovalCheckListConstants";
import ApprovalCheckList from "./ApprovalCheckList";
// import Loading from "../Utils/Loading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {withCookies} from "react-cookie"


export class ApprovalCheckListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success: false,
      retailer_details: props.cookies.get('retailer_details')
    };
    this.getApprovalCheckList()
    // this.props.resetReducerState();
  }
  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };

  getApprovalCheckList = ()=>{
    
    this.props.getApprovalCheckList()
  }
  getScreen(status) {
    switch (status) {
      case ApprovalCheckListStatus.ApprovalCheckList_Post.NEW:
        return (
          <Fragment>
            <ApprovalCheckList
              ApprovalCheckList={this.props.ApprovalCheckList}
              updateApprovalList = {this.props.updateApprovalList}
              setCheckListError={this.props.setCheckListError}
            />
          </Fragment>
        );
        case ApprovalCheckListStatus.ApprovalCheckList_Post.UPDATE:
          
            this.getApprovalCheckList()
          
          
          return (
            <Fragment>
          
            </Fragment>
          );
      case ApprovalCheckListStatus.ApprovalCheckList_Post.SUCCESS:
        if(!this.state.success)
       { 
        this.state.success = true;
         
       }
      
        return (
          <Fragment>
           <ApprovalCheckList
              ApprovalCheckList={this.props.ApprovalCheckList}
              updateApprovalList = {this.props.updateApprovalList}
              setCheckListError={this.props.setCheckListError}
            />
          </Fragment>
        );
        break;
      case ApprovalCheckListStatus.ApprovalCheckList_Post.FAILED:
        toast.error(this.props.ApprovalCheckList_Message)
        return (
          <Fragment>
            <ApprovalCheckList
               ApprovalCheckList={this.props.ApprovalCheckList}
               updateApprovalList = {this.props.updateApprovalList}
               setCheckListError={this.props.setCheckListError}
            />
          </Fragment>
        );
        break;
      case ApprovalCheckListStatus.ApprovalCheckList_Post.LOADING:
        this.state.open = true;
        this.state.success = false;
      
        return (
          <Fragment>
             {/* <Loading /> */}
            <ApprovalCheckList
                 ApprovalCheckList={this.props.ApprovalCheckList}
                 updateApprovalList = {this.props.updateApprovalList}
                 setCheckListError={this.props.setCheckListError}
                 LoadingIcon={true}
                 loaderStyle={{}}
            />
          </Fragment>
        );
        break;
      default:
        break;
    }
  }
  render() {
    return this.getScreen(this.props.ApprovalCheckList_post_status);
  }
}

export default withCookies(ApprovalCheckListView);
