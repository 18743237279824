import React, { Component, Fragment } from "react";
//import './ListOfApplicants.css'
import { ListOfApplicantsStatus } from "./ListOfApplicantsConstants";
import ListOfApplicants from "./ListOfApplicants";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/";
// import Loading from "../Utils/Loading";
import { Api_Pagination } from "RefRecordsConstants";
export class ListOfApplicantsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false
    };
    // props.getListOfApplicants()
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.activePage !== nextProps.activePage){
      // nextProps.setLoading(true)
      const data = {
        offset : (nextProps.activePage - 1) * Api_Pagination.ApplicantsLimit,
        props:nextProps
        // setLoading:nextProps.setLoading,
        // ApplicantsCount:nextProps.ApplicantsCount,
        // activePage:nextProps.activePage,
        // setItemList
      }
      // alert(JSON.stringify(data))

      console.log("props data", data)
      nextProps.getListOfApplicants(data)

      return{
        activePage:nextProps.activePage
      }
   }
   
   return null;
 }
  getScreen(status) {
    // alert(status)
    switch (status) {
      case ListOfApplicantsStatus.ListOfApplicants_Get.NEW:
        //  alert(JSON.stringify( this.props.ListOfApplicants))
        return (
          <ListOfApplicants activePage={this.state.activePage} ListOfApplicants={this.props.ListOfApplicants} getListOfApplicants = {this.props.getListOfApplicants} cookies={this.props.cookies} setLoading={this.props.setLoading} setItemList={this.props.setItemList}/>
        );

      case ListOfApplicantsStatus.ListOfApplicants_Get.SUCCESS:
       
        // this.props.setLoading(false)
          
        // if(this.props.ApplicantsCount > 0 && this.props.activePage == 1){
        //   this.props.setItemList(this.props.ApplicantsCount)
        // }
        return (
          <ListOfApplicants activePage={this.state.activePage} ListOfApplicants={this.props.ListOfApplicants} getListOfApplicants = {this.props.getListOfApplicants} cookies={this.props.cookies} setLoading={this.props.setLoading} setItemList={this.props.setItemList}/>
        );
      case ListOfApplicantsStatus.ListOfApplicants_Get.FAILED:
        // this.props.setLoading(false)
        // this.props.setItemList(0)
        return (
          <Fragment>
            <Snackbar
              open={this.state.open}
              autoHideDuration={2000}
              onClose={this.closeSnackBar}
              //  TransitionComponent={<Slide {...props} direction="up" />}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id="message-id">Unable to get list of applicants</span>
              }
            />
            <ListOfApplicants activePage={this.state.activePage} getListOfApplicants = {this.props.getListOfApplicants} ListOfApplicants={[]} cookies={this.props.cookies} setLoading={this.props.setLoading} setItemList={this.props.setItemList}/>
          </Fragment>
        );

      case ListOfApplicantsStatus.ListOfApplicants_Get.LOADING:
        this.state.open = true;
        // this.props.setLoading(true)
 
        return (
      
         <>
             <ListOfApplicants activePage={this.state.activePage} ListOfApplicants={this.props.ListOfApplicants} getListOfApplicants = {this.props.getListOfApplicants} showLoader={true} cookies={this.props.cookies} setLoading={this.props.setLoading} setItemList={this.props.setItemList}/>

              {/* <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center",height:"58vh", width:"100%" }}>
            <Loading/>
          </div> */}
         </>
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.history)
    return this.getScreen(this.props.ListOfApplicants_Get_status);
  }
}

export default withRouter(ListOfApplicantsView);
