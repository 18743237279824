import React, { Component, Fragment } from "react";
import "../ListOfApplicants/ListOfApplicants.css";
import { withRouter } from "react-router-dom";
import CreateApplicantProfileContainer from "./CreateApplicantProfile/CreateApplicantProfileContainer";
import ApplicantsStatusMessage from "../ApplicantsStatusMessage/ApplicantsStatusMessage";
import { Api_Pagination } from "RefRecordsConstants";
import RotateLoading from "../Utils/RotateLoading";
export class ListOfApplicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgPopup: false,
      business_application: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  GotoBussinessProfile = Application => {
    this.props.cookies.remove("BusinessProfileData", { path: "/" });

    this.props.cookies.remove("BusinessApplicationData", { path: "/" });
    this.props.cookies.set("BusinessApplicationData", Application, {
      path: "/"
    });

    this.props.history.push({
      pathname: "/business_profile"
    });
  };

  // todo: msgPopupHandler onClick Function ...................
  msgPopupHandler = res => {
    console.log("msgPopup :", res);
    this.state.business_application = res;
    this.setState({ msgPopup: !this.state.msgPopup });
  };
  clearPopupHandler = () => {
    this.setState({ msgPopup: !this.state.msgPopup });

    const props = {
      setLoading: this.props.setLoading,
      setItemList: this.props.setItemList
    }

    const data = {
      offset : (this.props.activePage - 1) * Api_Pagination.ApplicantsLimit,
      props: props
    }

    this.props.getListOfApplicants(data)
  };
// maskPhone = (phone)=>{
//   var mask = "("+phone.replace(/-/g, "").replace('(',"").replace(')',"").replace('+',"")
//   mask  =  mask.substring(0, 4) + ")-" + mask.substring(4);
//   mask  =  mask.substring(0, 9) + "-" + mask.substring(9);
//   return mask
// }

maskPhone = (phone) => {
  // Remove any non-numeric characters like -, (, ), and +
  let sanitizedPhone = phone.replace(/[-() +]/g, "");

  // Determine the country code length
  const countryCodeLength = sanitizedPhone.length > 10 ? sanitizedPhone.length - 10 : 0;

  // Extract parts based on the length and format
  const countryCode = sanitizedPhone.slice(0, countryCodeLength);
  const areaCode = sanitizedPhone.slice(countryCodeLength, countryCodeLength + 3);
  const centralOfficeCode = sanitizedPhone.slice(countryCodeLength + 3, countryCodeLength + 6);
  const lineNumber = sanitizedPhone.slice(countryCodeLength + 6);

  // Format the phone number as (areaCode)-centralOfficeCode-lineNumber
  const maskedPhone = `(${areaCode})-${centralOfficeCode}-${lineNumber}`;

  // Include the country code if it exists
  return countryCode ? `+${countryCode} ${maskedPhone}` : maskedPhone;
};

  render() {
    console.log("ListOfApplicantsProps:", this.props.ListOfApplicants);
    const { msgPopup } = this.state;
    const { ListOfApplicants } = this.props;
  //  const ListOfApplicants =[]
    return (
      <Fragment>

            <div className="la-col-wrap">
              <div className="la-column-box header">
                <div className="la-col-2">
                  <h5>Business Name</h5>
                </div>
              
                
                <div className="la-col-2">
                  <h5>Method of Contact: Email / Phone Number</h5>
                </div>
              
             
                <div className="la-col-1">
                  <h5>Create Profile</h5>
                </div>
                <div className="la-col-1">
                  <h5>Status</h5>
                </div>
              </div>
              <div className="scroll-la">
              {
              ListOfApplicants && ListOfApplicants.length > 0?
              ListOfApplicants.map((item,index) => (
                <div key={index}>
                         
                <div
                  className="la-column-box-in border"
                  key={item.business_application_id}
                >
                  <div className="la-col-2">
                    <p>{item.business_name}</p>
                  </div>
                
                
                  <div className="la-col-2">
                    <p>{item.phone?this.maskPhone(item.phone):item.email_address}</p>
                  </div>
               
                  <div className="la-col-1">
                    <CreateApplicantProfileContainer
                      cookies={this.props.cookies}
                      Application={item}
                      className="in-header-btn-box"
                    />
                    {/* <div onClick={() => this.GotoBussinessProfile(item)} className="in-header-btn-box">
                    <button className="inner-header-btn">Create</button>
                  </div> */}
                  </div>
                  <div className="la-col-1">
                    <div
                      onClick={() => this.msgPopupHandler(item)}
                      className="in-header-btn-box"
                    >
                      <button id="btnMessage" className="inner-header-btn">Message</button>
                    </div>
                  </div>
                </div>
                </div>
              )):
              
              <div className="empty-text">
                <div className="lav-load" style={{display:"flex", justifyContent:"center"}}>

          {this.props. showLoader &&     <span> <RotateLoading LoadingIcon={true} loaderStyle={{right:"10px", top:"2px"}} /> </span>  } Applications Not Received Yet 
                </div>
              </div>
              }
              </div>
            
        </div>
        {msgPopup ? (
          <ApplicantsStatusMessage
            msgPopup={msgPopup}
            onClick={this.clearPopupHandler}
            business_application={this.state.business_application}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default withRouter(ListOfApplicants);
