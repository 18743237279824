import { connect } from "react-redux";
import UsersInventoryView from "./UsersInventory_View";
import {UsersInventoryServer} from "./server"
import {UsersInventoryActions} from './UsersInventoryConstants'
const mapStateToProps = state => {
  //  console.warn(state.UsersInventoryReducer.UsersInventory)
    return {
        UsersInventory_GET_status:state.UsersInventoryReducer.UsersInventory_Status,
        UsersInventory:state.UsersInventoryReducer.UsersInventory,
        UsersCount:state.UsersInventoryReducer.UsersCount,
        UsersInventory_Error:state.UsersInventoryReducer.UsersInventory_Error,
        UsersRoles:state.UsersInventoryReducer.UsersRoles,
        
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getUsersInventory: (data) => {

           dispatch( UsersInventoryServer.getUsersInventory(data) )
        },
        resetReducerState:() =>{
            dispatch({type:UsersInventoryActions.UsersInventory_GET.NEW})
        }
    }
};
const UsersInventoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersInventoryView)

export default UsersInventoryContainer;