import React, {  Fragment, PureComponent } from 'react'
//import './ScheduledWeekList.css'
import { ScheduledWeekListStatus } from './ScheduledWeekListConstants';
import WeekPicker from "../../Inputs/DateRange/WeekPicker";
// import Loading from '../../Utils/Loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export class ScheduledWeekListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
    props.getScheduledWeekList()
  }
  // this.state.bannerDelted
//   static getDerivedStateFromProps(nextProps, prevState){
//     if(nextProps.bannerDelted){
//       nextProps.setBannerDeleted(false)
//       nextProps.setScheduledWeekList([])
//       nextProps.getScheduledWeekList()
//    }setWeeks={this.setWeeks}
//    return null;
//  }
  getScreen(status) {
    switch (status) {
      case ScheduledWeekListStatus.ScheduledWeekList_GET.NEW:
      
        return (
          <Fragment >
            
          </Fragment>
        );


      case ScheduledWeekListStatus.ScheduledWeekList_GET.SUCCESS:

        return (
          <WeekPicker WeekList = {this.props.ScheduledWeekList} Week={this.props.Week} setWeeks={this.props.setWeeks}
          setIsCurrentWeek = {this.props.setIsCurrentWeek}
          
          />
        );
        break;
      case ScheduledWeekListStatus.ScheduledWeekList_GET.FAILED:
       toast.error(this.props.ScheduledWeekList_Error)
      
        return (
          <Fragment>
           {/* <p>Unable to get list of retailers</p> */}
          </Fragment>
        );

      case ScheduledWeekListStatus.ScheduledWeekList_GET.LOADING:
      
        return (
          // <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%" }}>
          //   <Loading/>
          // </div>
          <WeekPicker  
          WeekList = {this.props.ScheduledWeekList} 
          Week={this.props.Week} 
          setWeeks={this.props.setWeeks}
          setIsCurrentWeek = {this.props.setIsCurrentWeek}
          LoadingIcon={true}          
          />

        
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.ScheduledWeekList_GET_status)
    return (
      this.getScreen(this.props.ScheduledWeekList_GET_status)
    )
  }
}

export default (ScheduledWeekListView)
