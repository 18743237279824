import React, { Component, Fragment } from "react";
//import './ManageMessaging.css'
import { ManageMessagingStatus } from "./ManageMessagingConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import Loading from "../Utils/Loading";
import RotateLoading from "../Utils/RotateLoading";

export class ManageMessagingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      getTableData: {},
      
    };
  }

  closeSnackBar = () => {
    this.setState({
      open: false,
    });
  };
  
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.sendMsg){
      nextProps.sendMessage(false)
      nextProps.sendNotificationsToRetailers(nextProps.Data)
   }
  else if(nextProps.ManageMessaging_Get_status === ManageMessagingStatus.ManageMessaging_Get.NEW){
    nextProps.disableAllFields(false)
  }
  else if(nextProps.ManageMessaging_Get_status === ManageMessagingStatus.ManageMessaging_Get.SUCCESS){
    toast.success(nextProps.ManageMessaging_Message);
    nextProps.resetStates()
    nextProps.resetReducerState()
    nextProps.disableAllFields(false)
  }
 else if(nextProps.ManageMessaging_Get_status === ManageMessagingStatus.ManageMessaging_Get.FAILED){
    toast.error(nextProps.ManageMessaging_Message);
    nextProps.disableAllFields(false)
  }
  
   return null;
 }

  getScreen(status) {
    // alert(status)
    switch (status) {
      case ManageMessagingStatus.ManageMessaging_Get.NEW:
        //  alert(JSON.stringify( this.props.ManageMessaging))
        // this.props.disableAllFields(false)
        return (
          <button id="sendButton" className="upload-button" onClick={this.props.sendHandler}>
            Send
          </button>
        );

      case ManageMessagingStatus.ManageMessaging_Get.SUCCESS:
        
        return (
          <button id="sendButton" className="upload-button" onClick={this.props.sendHandler}>
            Send
          </button>
        );
      case ManageMessagingStatus.ManageMessaging_Get.FAILED:
     
        return (
          <Fragment>
            <button id="sendButton" className="upload-button" onClick={this.props.sendHandler}>
              Send
            </button>
          </Fragment>
        );

      case ManageMessagingStatus.ManageMessaging_Get.LOADING:
        this.state.open = true;
       
        return (
          <>
          <div className="btn-mess" style={{display:"flex",justifyContent:"flex-end"}}>

          <RotateLoading LoadingIcon={true} loaderStyle={{right:"10px", top:"10px"}}  />
               <button id="sendButton" className="upload-button" onClick={this.props.sendHandler}>
            Send
          </button>
          </div>
          </>
        //   <div className="msge-loader">
        //   <Loading />
        // </div>
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.ManageMessaging_Get_status)
    return this.getScreen(this.props.ManageMessaging_Get_status);
  }
}

export default ManageMessagingView;
