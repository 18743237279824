import React, { Component, Fragment } from "react";
//import './ApplicantsStatusField.css'
import { ApplicantsStatusFieldStatus } from "./ApplicantsStatusFieldConstants";
import ApplicantsStatusField from "./ApplicantsStatusField";
// import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from "../../Utils/Loading";

export class ApplicantsStatusFieldView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mount: false,
      msgPopup: false,
    };
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    });
  };
  componentDidMount() {

   // alert(this.props.application_status_id)
  }
  static getDerivedStateFromProps(props, state) {
    if (props.msgPopup && props.msgPopup !== state.msgPopup) {
      
      props.getApplicantsStatusField();
      return {
        msgPopup: props.msgPopup
      };
    } else return null;
  }
  // msgPopup={this.props.msgPopup}
  getScreen(status) {
    // alert(status)
    switch (status) {
      case ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.NEW:
        //  alert(JSON.stringify( this.props.ApplicantsStatusField))
        return (
          <ApplicantsStatusField
            ApplicantsStatusField={this.props.ApplicantsStatusField}
            label={this.props.label}
            onChange={this.props.onChange}
            data={this.props.ApplicantsStatusField}
            id={this.props.id}
            name={this.props.name}
            value={this.props.value}
            inputValue={this.props.inputValue}
            errorMsg={this.props.errorMsg}
            flag={this.props.flag}
          />
        );

      case ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.SUCCESS:
       var ApplicantsStatus  =  []
       var statusCode = ""
       
        for(var i=0 ;i < this.props.ApplicantsStatusField.length ; i++){
          if(this.props.ApplicantsStatusField[i].status_id === this.props.application_status_id){
            statusCode = this.props.ApplicantsStatusField[i]
           // this.props.setStatusName(statusCode.to_status_code)
          }else
          {
            ApplicantsStatus.push(this.props.ApplicantsStatusField[i])
          }

        }
        return (
          <ApplicantsStatusField
            ApplicantsStatusField={this.props.ApplicantsStatusField}
            label={this.props.label}
            onChange={this.props.onChange}
            data={ApplicantsStatus}
            id={this.props.id}
            name={this.props.name}
            value={statusCode.to_status_code}
            inputValue={this.props.inputValue}
            errorMsg={this.props.errorMsg}
            flag={this.props.flag}
          />
        );
      case ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.FAILED:
        //  alert(JSON.stringify( this.props.ApplicantsStatusField))
        toast.error(this.props.Error)
        return (
          <Fragment>
            
            <ApplicantsStatusField
              ApplicantsStatusField={this.props.ApplicantsStatusField}
              label={this.props.label}
              onChange={this.props.onChange}
              data={this.props.ApplicantsStatusField}
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              inputValue={this.props.inputValue}
              errorMsg={this.props.errorMsg}
              flag={this.props.flag}
            />
          </Fragment>
        );

      case ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.LOADING:
        this.state.open = true;
        return (
          // <div style={{ textAlign: "center" }}>
          //   <Loading/>
          // </div>
          <ApplicantsStatusField
          ApplicantsStatusField={this.props.ApplicantsStatusField}
          label={this.props.label}
          onChange={this.props.onChange}
          data={this.props.ApplicantsStatusField}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          inputValue={this.props.inputValue}
          errorMsg={this.props.errorMsg}
          flag={this.props.flag}
          LoadingIcon={true}
        />
        );
      default:
        return <div />;
    }
  }
  render() {
    // alert(this.props.history)
    return this.getScreen(this.props.ApplicantsStatusField_Get_status);
  }
}

export default ApplicantsStatusFieldView;
