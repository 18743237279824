import React, { Component, Fragment } from 'react'
//import './GetPreviousCoupons.css'
import { GetPreviousCouponsStatus } from './GetPreviousCouponsConstants';

// import GetPreviousCoupons from './GetPreviousCoupons'
import { withCookies } from 'react-cookie';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import LoadingScreen from "../../Utils/Loading";

export class GetPreviousCouponsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faceValue:'',
      maxUnusedQuota:0,
      year_id:props.year_id,
      selectedMonth:props.selectedMonth,
      open:true,
      success:false
    }
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.faceValue !== nextProps.faceValue && !isNaN(parseInt(nextProps.faceValue))) {
      const data = {
        business_id: nextProps.cookies.get("BusinessProfileData").business_id,
        coupon_face_value:nextProps.faceValue,
        year_id :nextProps.year_id,
        month : nextProps.selectedMonth
      }
      // alert(data)
      nextProps.GetPreviousCoupons(data)
      return {
        faceValue:nextProps.faceValue
      };
    
    }
    
    return null;
  }

  getScreen(status) {
    switch (status) {
      case GetPreviousCouponsStatus.GetPreviousCoupons_GET.NEW:
        return (
          <Fragment >
            {/* <GetPreviousCoupons disabled={this.props.disabled} MonthlyQuota={this.state.maxUnusedQuota} 

            /> */}
          </Fragment>
        );
      case GetPreviousCouponsStatus.GetPreviousCoupons_GET.SUCCESS:
        // alert(this.props.MonthlyQuota)
         //****************** After successful disptch action: PreviousCoupons are used to calculate unused monthly quota
          if(!this.state.success)
        {this.state.success = true
         this.state.maxUnusedQuota = this.props.MonthlyQuota - this.props.PreviousCoupons[0].UnusedQuota
    
        if(this.state.maxUnusedQuota < 0)
        this.state.maxUnusedQuota = 0

        this.props.setmaxUnusedQuota(this.state.maxUnusedQuota,this.props.exchangeRates)
        }
        return (
          <Fragment >
            {/* <GetPreviousCoupons disabled={this.props.disabled} MonthlyQuota={this.state.maxUnusedQuota} 

            /> */}
          </Fragment>
        );
        break;
      case GetPreviousCouponsStatus.GetPreviousCoupons_GET.FAILED:
        if(this.state.open)
   {     this.state.maxUnusedQuota = 0
        this.props.setmaxUnusedQuota(this.state.maxUnusedQuota, [])
        toast.error(this.props.GetPreviousCoupons_Error)
        this.state.open = false
      }
        return (
          <Fragment>
          {/* <SnackBar title="Failed to get unused quota" /> */}
          {/* <GetPreviousCoupons 
          disabled={this.props.disabled}
          MonthlyQuota={this.state.maxUnusedQuota}    
          /> */}
          </Fragment>
        );

      case GetPreviousCouponsStatus.GetPreviousCoupons_GET.LOADING:
        this.state.open = true
        this.state.success = false
        return (
          <Fragment>
            
          </Fragment>
          // <LoadingScreen />
        );
        break;
      default:
        break;
    }

  }
  render() {
    // alert(this.props.GetPreviousCoupons_GET_status)
    // if (this.props.faceValue == '')
    // {
    //   this.state.faceValue = ''
    // }
    // if(this.props.faceValue != this.state.faceValue)
    // { 

    //   const data = {
    //       business_id: this.props.cookies.get("BusinessProfileData").business_id,
    //       coupon_face_value:this.props.faceValue,
    //       year_id :this.props.year_id,
    //       month : this.props.selectedMonth
    //     }
    //     // alert(JSON.stringify(data))
    // this.props.GetPreviousCoupons(data)
    // this.state.faceValue = this.props.faceValue
    //   }

    return (
      
        this.getScreen(this.props.GetPreviousCoupons_GET_status)
    
    )
  }
}

export default withCookies(GetPreviousCouponsView)
