import { connect } from "react-redux";
import Login from "./UndoDeleteCoupon_View";
import {UndoDeleteCouponServer} from "./server"
import {UndoDeleteCouponActions} from './UndoDeleteCouponConstants'
import { withCookies } from 'react-cookie';
const mapStateToProps = state => {
    console.log(state)
    return {
        UndoDeleteCoupon_post_status:state.UndoDeleteCouponReducer.UndoDeleteCoupon_Status,
        UndoDeleteCoupon_Message:state.UndoDeleteCouponReducer.UndoDeleteCoupon_Message,
        UndoDeleteCouponDetails:state.UndoDeleteCouponReducer.UndoDeleteCouponDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateUndoDeleteCoupon: (data, bussinessId) => {
        //     dispatch( UndoDeleteCouponServer.updateUndoDeleteCoupon(data, bussinessId) )
        //  },

        handleUndoDeleteCoupon: (data) => {

           dispatch(UndoDeleteCouponServer.handleUndoDeleteCoupon(data))
        },
        resetReducerState: () => {
            dispatch({type:UndoDeleteCouponActions.UndoDeleteCoupon_Post.NEW})
         }
        //  updateUndoDeleteCoupon: (data, token) =>{
        //     dispatch( UndoDeleteCouponServer.updateUndoDeleteCoupon(data, token) )
        //  }
        //  GotoContract: () => {
        //     dispatch({type:UndoDeleteCouponActions.UndoDeleteCoupon_Post.GOTO_CONTRACTS})
        //  },
        
    }
};
const UndoDeleteCouponContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

export default withCookies(UndoDeleteCouponContainer);