import React, { Component } from "react";
import "../InputRadio/InputRadio.css";

class InputRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioCheck: false,
    };
  }

  radioMonCheckHandler =() => {
 
    this.props.onFirstChange()
}

radioAnCheckHandler =() => {
  this.props.onFirstChange()
}

  render() {
    // const { labelName="Program Fee Type:", radioCheck, labelA= "Monthly", labelM= "Annual"  } = this.state;
    const { labelName="Program Fee Type:", radioCheck, labelA= "Monthly", labelM= "Annual", onChange
    // onFirstChange, onSecondChange 
  } = this.props;
    // alert(onFirstChange)
    return (
      <div className="rd-container">
        <label className="rd_btn_label">{labelName}</label>
        <div className="rd_btn_container">
             <label className="radio-container">
                {labelA}
            <input type="radio" name={labelA} checked={!radioCheck} onChange= {onChange} />
            <span className="checkmark"></span>
          </label>
          <label className="radio-container">
                {labelM}
            <input type="radio" name={labelM}  checked={radioCheck} onChange= {onChange} />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    );
  }
}

export default InputRadio;
