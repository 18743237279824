import { connect } from "react-redux";
import BusinessProfileView from "./BusinessProfile_View";
import {BusinessProfileServer} from "./server"
import {BusinessProfileActions} from './BusinessProfileconstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        BusinessProfile_post_status:state.BusinessProfileReducer.BusinessProfile_Status,
        BusinessProfile_Message:state.BusinessProfileReducer.BusinessProfile_Message,
        GoToContractScreen:state.BusinessProfileReducer.GoToContract,
        BusinessProfile:state.BusinessProfileReducer.BusinessProfileDetails,
        FormData:state.BusinessProfileReducer.FormData
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateBusinessProfile: (data, bussinessId) => {
            dispatch( BusinessProfileServer.updateBusinessProfile(data, bussinessId) )
         },

        saveBusinessProfile: (data, user_data) => {

           dispatch( BusinessProfileServer.saveBusinessProfile(data, user_data) )
        },
        
        CreateRetailerBussiness: (data, user_data) => {

            dispatch( BusinessProfileServer.CreateRetailerBussiness(data, user_data) )
         },
        
         GotoContract: () => {
            dispatch({type:BusinessProfileActions.BusinessProfile_Post.GOTO_CONTRACTS})
         },
        resetReducerState: () =>{
            dispatch({type:BusinessProfileActions.BusinessProfile_Post.NEW})
        }
    }
};
const BusinessProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessProfileView)

export default BusinessProfileContainer;