import React, { Component } from "react";
//import './ChangePassword.css'
import { ChangeEmailStatus } from "./ChangeEmailConstants";
import ChangeEmail from '../EditEmail'
import Loading from "../../Utils/Loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {  withRouter } from "react-router-dom";
export class ChangeEmailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showModal:false,
      isLoading:false
    };
    //this.getRiderUnAnsweredQuestions()
  // props.getChangeEmail()
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.ChangeEmail_POST_status === ChangeEmailStatus.ChangeEmail_POST.SUCCESS){
        
      toast.success(nextProps.ChangeEmail_Message)
      nextProps.resetReducerState()
      nextProps.history.replace("/UserManagement")
    }
    else if(nextProps.ChangeEmail_POST_status === ChangeEmailStatus.ChangeEmail_POST.FAILED){
      toast.error(nextProps.ChangeEmail_Message)
      nextProps.resetReducerState()
     
    }
    return null
  }

  getScreen(status) {
    switch (status) {
      case ChangeEmailStatus.ChangeEmail_POST.NEW:
        return (
          <ChangeEmail ChangeEmail = {this.props.ChangeEmail}
          
          cookies = {this.props.cookies}
          />
        );

      case ChangeEmailStatus.ChangeEmail_POST.SUCCESS:
   
        return (
          <ChangeEmail ChangeEmail = {this.props.ChangeEmail} 
          
          cookies = {this.props.cookies}
          />
        );
        break;
      case ChangeEmailStatus.ChangeEmail_POST.FAILED:
        
     
        return (
          <ChangeEmail ChangeEmail = {this.props.ChangeEmail} 
                cookies = {this.props.cookies}
          />
        );

      case ChangeEmailStatus.ChangeEmail_POST.LOADING:
        
        return (
          
            <Loading />
        
        );

      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.ChangeEmail_POST_status);
  }
}

export default withRouter(ChangeEmailView);
