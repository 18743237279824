
//******************Exchange Rate Server Calls******************;

import {BusinessCouponQuotasActions} from './BusinessCouponQuotasconstants';
import store from '../../Store/store'
import {ROOT_URL, getCookie} from '../../Config/Config';
import { failure_messages } from 'Messages';

export const BusinessCouponQuotasServer = {
    getBusinessCouponQuotasList:getBusinessCouponQuotasList,
    saveBusinessCouponQuotas:saveBusinessCouponQuotas,
    updateBusinessCouponQuotas:updateBusinessCouponQuotas
};

//******************Insert BusinessCouponQuotass******************;

function saveBusinessCouponQuotas(data){
   // alert(JSON.stringify(data))
      const request=fetch(ROOT_URL+'/api/coupons/postCouponsQuotas', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
           //  alert(JSON.stringify(response))
             if(response.status == "200"){
               console.log(response)
               
            store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.SUCCESS,payload:{message:response.message.replace("20XX", response.data[0].year)} });
            return ;
            }else
            {
              store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
            store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.FAILED, error:failure_messages.quotasSaveError});
          })
      return {type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.LOADING}

};
  
//******************Get BusinessCouponQuotass******************;


//******************Update BusinessCouponQuotas******************;

  
function getBusinessCouponQuotasList(yearId,businessId){
  
      const request=fetch(ROOT_URL+'/api/coupons/getCouponQuotasByBusinessId', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({yearId:yearId,businessId:businessId})
          }).then((response)=>{
            response.json().then(response=>{
              if(response.status == "200"){
                console.log(response)
                if(response.data.length>0)
             {
              //  alert(JSON.stringify(response.data))
               response.data.sort((a,b) => (a.face_value > b.face_value) ? 1 : ((b.face_value > a.face_value) ? -1 : 0))
              
               store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Get.SUCCESS,payload:response.data});
              }
             else
             store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.NEW});
             
             return ;
             }else
             {
               store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Get.FAILED, error:response.error});
               return ;
               }    
            });
           }).catch((error) => {
            store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Get.FAILED, error:failure_messages.quotasListError});
          })
       return {type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.LOADING}
 
    };
  
function updateBusinessCouponQuotas(data){
  const request=fetch(ROOT_URL+'/api/coupons/updateCouponsQuotas', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
    }).then((response)=>{
     response.json().then(response=>{
       if(response.status == "200"){
         console.log(response)
      store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.SUCCESS, payload:{message:response.message.replace("20XX", response.data[0].year)}
    });
      return ;
      }else
      {
        store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.FAILED, error:response.error});
        return ;
        }    
     });
    }).catch((error) => {
   //   alert(error)
      store.dispatch({type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.FAILED, error:failure_messages.quotaUpdateError
      });
    })
return {type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.LOADING}

};
