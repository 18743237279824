import {
    GetCoupon_TypesStatus,
    GetCoupon_TypesActions
  } from "./GetCoupon_Types_Constants";
  
  const GetCoupon_Types_initialState = {
    GetCoupon_Types_Status: GetCoupon_TypesStatus.GetCoupon_Types_Get.NEW,
    error: "",
    message:"",
    dataTable: []
  };
  export default function(state = GetCoupon_Types_initialState, action) {
    switch (action.type) {
      case GetCoupon_TypesActions.GetCoupon_Types_Get.LOADING:
        return {
          ...state,
          GetCoupon_Types_Status:
            GetCoupon_TypesStatus.GetCoupon_Types_Get.LOADING,
            dataTable: []
        };
      case GetCoupon_TypesActions.GetCoupon_Types_Get.NEW:
        return {
          ...state,
          GetCoupon_Types_Status:
            GetCoupon_TypesStatus.GetCoupon_Types_Get.NEW,
            dataTable: []
        };
      case GetCoupon_TypesActions.GetCoupon_Types_Get.SUCCESS:
        return {
          ...state,
          GetCoupon_Types_Status:
            GetCoupon_TypesStatus.GetCoupon_Types_Get.SUCCESS,
            dataTable: action.payload.data, 
        };
      case GetCoupon_TypesActions.GetCoupon_Types_Get.FAILED:
        return {
          ...state,
          GetCoupon_Types_Status:
            GetCoupon_TypesStatus.GetCoupon_Types_Get.FAILED,
            dataTable: [],
            error:action.payload
        };
  
      default:
        return {
          ...state,
          
        };
    }
  }
  