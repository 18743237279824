import { connect } from "react-redux";
import BusinessCategoryView from "./BusinessCategory_View";
import {BusinessCategoryServer} from "./server"
// import {BusinessCategoryActions} from './BusinessCategoryConstants'
const mapStateToProps = state => {
  //  console.warn(state.BusinessCategoryReducer.BusinessCategory)
    console.log(state)
    return {
        BusinessCategory_GET_status:state.BusinessCategoryReducer.BusinessCategory_Status,
        BusinessCategory:state.BusinessCategoryReducer.BusinessCategory,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getBusinessCategory: (data, token) => {

           dispatch( BusinessCategoryServer.getBusinessCategory(data, token) )
        },
        
    }
};
const BusinessCategoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessCategoryView)

export default BusinessCategoryContainer;