export const BusinessEnrollment_Actions= {
	
	
	BusinessEnrollment_Get :{
		NEW:"BusinessEnrollment_Get_NEW",
		SUCCESS:"BusinessEnrollment_Get_SUCCESS",
		FAILED:"BusinessEnrollment_Get_FALIURE",
		LOADING:"BusinessEnrollment_Get_LOADING",
		NOT_AUTHORIZED: "BusinessEnrollment_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
	,
	BusinessEnrollment_Post :{
		NEW:"BusinessEnrollment_Post_NEW",
		SUCCESS:"BusinessEnrollment_Post_SUCCESS",
		FAILED:"BusinessEnrollment_Post_FALIURE",
		LOADING:"BusinessEnrollment_Post_LOADING",
		NOT_AUTHORIZED: "BusinessEnrollment_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const BusinessEnrollment_Status ={

	BusinessEnrollment_Get :{
		NEW:"BusinessEnrollment_Get_NEW",
		SUCCESS:"BusinessEnrollment_Get_SUCCESS",
		FAILED:"BusinessEnrollment_Get_FALIURE",
		LOADING:"BusinessEnrollment_Get_LOADING",
		NOT_AUTHORIZED: "BusinessEnrollment_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	},
	BusinessEnrollment_Post :{
		NEW:"BusinessEnrollment_Post_NEW",
		SUCCESS:"BusinessEnrollment_Post_SUCCESS",
		FAILED:"BusinessEnrollment_Post_FALIURE",
		LOADING:"BusinessEnrollment_Post_LOADING",
		NOT_AUTHORIZED: "BusinessEnrollment_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
