import { connect } from "react-redux";
import MessageHistory_View from "./MessageHistory_View";
import { MessageHistoryServer } from "./server";
// import { MessageHistoryActions } from "./MessageHistoryConstants";
const mapStateToProps = state => {
  console.log(state);
  return {
    MessageHistory_Get_status:
      state.MessageHistoryReducer.MessageHistory_Status,
    MessageHistory:
      state.MessageHistoryReducer.MessageHistoryDetails,
      notificationListCount: state.MessageHistoryReducer.notificationListCount
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getMessageHistory: (data) => {
      dispatch(MessageHistoryServer.getMessageHistory(data));
    }
  };
};
const MessageHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageHistory_View);

export default MessageHistoryContainer;
