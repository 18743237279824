import React from "react";
import "./LabelDatePicker.css";
import DatePicker from "react-datepicker";
function LabelDatePicker(props) {
  const {
    minDate,
    maxDate,
    label = "label",
    inputValue,
    onChange,
    errorMsg,
    flag,
    containerStyle,
    disabled,
    // onClick,
    onCalendarOpen,
    id="date",
    star
  } = props;

  class DatepickerInput extends React.PureComponent {
    render() {
      return (
        <input type="text" {...this.props} readOnly />
      )
    }
  }

  // const DatepickerInput = ({ ...props }) => (
  //   <input type="text" {...props} readOnly />
  // );
  // console.log('minDate:',props.minDate, 'inputValue:', inputValue)
  return (
    <div className="ldpi_input_container" style={containerStyle}>
      <label htmlFor="" className="ldpi_label">
        {label}<span style={{color: "red"}}>{star}</span>
      </label>
      <div className="ldpi_div1">
        <DatePicker
          className="ldpi_input"
          id={id}
          minDate={minDate}
          maxDate={maxDate}
          selected={inputValue}
          onChange={onChange}
          // onInputClick={()=>alert("test")}
          onCalendarOpen = {onCalendarOpen}
          placeholderText="mm/dd/yyyy"
          withPortal
          disabled={disabled}
          customInput={<DatepickerInput />}
          // filterDate={this.isWeekday}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          fixedHeight
          disabledKeyboardNavigation={true}
        />
        {/* <input
                    className="ldpi_input"
                    type="date"
                    value={inputValue}
                    min={minDate}
                    max={maxDate}
                    onChange={onChange}
                    disabled={disabled}
                /> */}
        <p
          className="ldpi_error_text"
          style={{ display: flag ? "block" : "none" }}
        >
          {errorMsg}
        </p>
      </div>
    </div>
  );
}

export default LabelDatePicker;
