import { CouponsInventoryStatus, CouponsInventoryActions } from './CouponsInventoryConstants';

const CouponsInventory_initialState = {
    CouponsInventory_Status: CouponsInventoryStatus.CouponsInventory_GET.NEW,
    CouponsInventory_Error: '',
    CouponsInventory: [],
    ref_data:[],
}
export default  function  (state = CouponsInventory_initialState, action) {
    if(window.location.pathname === "/ManageCouponInventory" && action.payload && action.payload.coupon_batch_id)
    {

        // alert(JSON.stringify(action.payload))

        let updatedPauseCoupon =  state.CouponsInventory.find(item=>{
            if(item.coupon_batch_id == action.payload.coupon_batch_id)
                return item
        })

        // alert(JSON.stringify(updatedPauseCoupon))
        
        // updatedPauseCoupon.to_status_code = action.payload.to_status_code
        // updatedPauseCoupon.status_id = action.payload.batch_status

        updatedPauseCoupon.to_status_code = action.payload.to_status_code
        updatedPauseCoupon.status_id = action.payload.batch_status
        updatedPauseCoupon.display_color = action.payload.display_color

        state = {...state,CouponsInventory:[...state.CouponsInventory] }
        delete action.payload.coupon_batch_id
    }

    switch (action.type) {
        case CouponsInventoryActions.CouponsInventory_GET.LOADING:
            return { ...state, CouponsInventory_Status: CouponsInventoryStatus.CouponsInventory_GET.LOADING,CouponsInventory: [] }
            case CouponsInventoryActions.CouponsInventory_GET.NEW:
                return { ...state, CouponsInventory_Status: CouponsInventoryStatus.CouponsInventory_GET.NEW  }
    
        case CouponsInventoryActions.CouponsInventory_GET.FAILED:
            return { ...state,  CouponsInventory_Status: CouponsInventoryStatus.CouponsInventory_GET.FAILED, CouponsInventory_Error:action.error}
        case CouponsInventoryActions.CouponsInventory_GET.SUCCESS:
        
          return { ...state,  CouponsInventory_Status: CouponsInventoryStatus.CouponsInventory_GET.SUCCESS, CouponsInventory:action.payload,  ref_data:action.ref_data}
        
        default:
            return { ...state,
            }
    }
}
