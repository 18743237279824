//******************Exchange Rate Server Calls******************;

import { GetCoupon_TypesActions } from "./GetCoupon_Types_Constants";
import store from "../../../Store/store";
import { ROOT_URL,getCookie } from "../../../Config/Config";

export const GetCoupon_TypesServer = {
  getGetCoupon_Types: getGetCoupon_Types,
};

//******************Get GetCoupon_Typess******************;

function getGetCoupon_Types() {
 
  const res = fetch(ROOT_URL + "/api/business/getCouponTypes", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
    .then((res) => {
      res
        .json()
        .then((response) => {
          console.log("GetCoupon_Types:", response);
          store.dispatch({
            type:
              GetCoupon_TypesActions.GetCoupon_Types_Get.SUCCESS,
            payload: response,
          });
        })
        .catch((err) => {
          console.log("GetCoupon_TypesError:", err);
          store.dispatch({
            type:
              GetCoupon_TypesActions.GetCoupon_Types_Get.FAILED,
            payload: err,
          });
        });
    })
    .catch((err) => {
      console.log("GetCoupon_TypesError:", err);
      store.dispatch({
        type: GetCoupon_TypesActions.GetCoupon_Types_Get.FAILED,
        payload: err,
      });
    });

  return {
    type: GetCoupon_TypesActions.GetCoupon_Types_Get.LOADING,
  };
}
