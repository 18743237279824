
//******************Exchange Rate Server Calls******************;

import {BusinessListActions} from './BusinessListconstants';
import store from '../../Store/store'
import {ROOT_URL, getCookie} from '../../Config/Config';
import {failure_messages} from "Messages"


export const BusinessListServer = {
  //  getBusinessList:getBusinessList,
  getBusinessList:getBusinessList,
  //  updateBusinessList:updateBusinessList
};

//******************Insert BusinessLists******************;



function getBusinessList(data){
  // alert(JSON.stringify(getCookie("admin_token_partner")))
      const request=fetch(ROOT_URL+'/api/business/getAllBusinesses', {
          method: 'Post',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
             // alert(JSON.stringify(response))
            console.log("this is response", response)

            store.dispatch({type:BusinessListActions.BusinessList_GET.SUCCESS,payload:response.data});
            return ;
            }else
            {
              store.dispatch({type:BusinessListActions.BusinessList_GET.FAILED, error:response.error});
              return ;
              }    
           });
          }).catch((error) => {
          //  alert(error)
           store.dispatch({type:BusinessListActions.BusinessList_GET.FAILED, error:failure_messages.Businessparticipants});
            })
      return {type:BusinessListActions.BusinessList_GET.LOADING}

};
  
//******************Get BusinessLists******************;


//******************Update BusinessList******************;

  
// function getBusinessList(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.BusinessList.length>0)
//              store.dispatch({type:BusinessListActions.BusinessList_Get.SUCCESS,payload:response.BusinessList});
//              else
//              store.dispatch({type:BusinessListActions.BusinessList_GET.NEW});
             
//              return ;
//              }else
//              {
//                store.dispatch({type:BusinessListActions.BusinessList_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:BusinessListActions.BusinessList_Get.FAILED});
//           })
//        return {type:BusinessListActions.BusinessList_GET.LOADING}
 
//     };
  
// function updateBusinessList(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:BusinessListActions.BusinessList_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:BusinessListActions.BusinessList_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:BusinessListActions.BusinessList_GET.FAILED});
//     })
// return {type:BusinessListActions.BusinessList_GET.LOADING}

// };
