import { UsersInventoryStatus, UsersInventoryActions } from './UsersInventoryConstants';

const UsersInventory_initialState = {
    UsersInventory_Status: UsersInventoryStatus.UsersInventory_GET.NEW,
    UsersInventory_Error: '',
    UsersInventory: [],
    UsersRoles:[],
    UsersCount:0
}
export default  function  (state = UsersInventory_initialState, action) {
    switch (action.type) {
        case UsersInventoryActions.UsersInventory_GET.LOADING:
            return { ...state, UsersInventory_Status: UsersInventoryStatus.UsersInventory_GET.LOADING,UsersInventory: [] }
            case UsersInventoryActions.UsersInventory_GET.NEW:
                return { ...state, UsersInventory_Status: UsersInventoryStatus.UsersInventory_GET.NEW  }
    
        case UsersInventoryActions.UsersInventory_GET.FAILED:
            return { ...state,  UsersInventory_Status: UsersInventoryStatus.UsersInventory_GET.FAILED, UsersInventory_Error:action.error}
        case UsersInventoryActions.UsersInventory_GET.SUCCESS:
        
          return { ...state,  UsersInventory_Status: UsersInventoryStatus.UsersInventory_GET.SUCCESS, UsersInventory:action.payload, UsersCount:action.UsersCount, UsersRoles:action.UsersRoles}
        
        default:
            return { ...state,
            }
    }
}
