import { GetModeTypesStatus, GetModeTypesActions } from './GetModeTypesConstants';

const GetModeTypes_initialState = {
    GetModeTypes_Status: GetModeTypesStatus.GetModeTypes_GET.NEW,
    GetModeTypes_Error: '',
    ModeTypes: [],
}
export default  function  (state = GetModeTypes_initialState, action) {
    switch (action.type) {
        case GetModeTypesActions.GetModeTypes_GET.LOADING:
            return { ...state, GetModeTypes_Status: GetModeTypesStatus.GetModeTypes_GET.LOADING }
            case GetModeTypesActions.GetModeTypes_GET.NEW:
                return { ...state, GetModeTypes_Status: GetModeTypesStatus.GetModeTypes_GET.NEW }
    
        case GetModeTypesActions.GetModeTypes_GET.FAILED:
            return { ...state,  GetModeTypes_Status: GetModeTypesStatus.GetModeTypes_GET.FAILED, GetModeTypes_Error:action.error}
        case GetModeTypesActions.GetModeTypes_GET.SUCCESS:
          console.log(action.payload)
          return { ...state,  GetModeTypes_Status: GetModeTypesStatus.GetModeTypes_GET.SUCCESS, ModeTypes:action.payload}
        
        default:
            return { ...state,
                GetModeTypes: [],
                QuotaYears:[]
            }
    }
}
