
//******************Exchange Rate Server Calls******************;

import { ROOT_URL,getCookie } from '../../../Config/Config';
import 'react-toastify/dist/ReactToastify.min.css';
import { failure_messages } from 'Messages';
import { toast } from 'react-toastify';
export const yearsServer = {
  //  getyears:getyears,
  getyears: getyears,
  //  updateyears:updateyears
};

//******************Insert yearss******************;

function getyears(data, year) {
  // alert(data)
  const request = fetch(ROOT_URL + '/api/coupons/getAllyears', {
    method: 'POST',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ years: data.years })
  }).then((response) => {

    response.json().then(response => {

      if (response.status == "200") {

        if (year == "" && response.data && response.data.length>0)
          {
            // alert(JSON.stringify(response))
            data.setYearData(response.data)
            data.setYear(response.data[0].value)
          }
        else {
          if (response.data.length == year.length) {
            //data.failedTogetYears("This contract has reached its year's Limit")
          //  alert('test')
            toast.info(failure_messages.CouponQuotaLimit)
            data.setYear(0)
            data.setYearData([])
          }
          else {
            var filtered = response.data.filter(
              function (val) {
                for (var i = 0; i < year.length; i++) {
                  if (val.name == year[i].year) {
                    return false;
                  }
                }
                return true;
              }
            );
            console.log("filterd lts", filtered)
            if(filtered.length>0)
            data.setYear(filtered[0].value)
            data.setYearData(filtered)

          }
        }
        return;
      } else {
       // data.failedTogetYears("Failed to get years Id's")
        // store.dispatch({type:yearsActions.years_GET.FAILED});
        return;
      }
    });
  }).catch((error) => {
    //    alert(error)
   // data.failedTogetYears("Failed to get years Id's")
    // store.dispatch({type:yearsActions.years_GET.FAILED});
  })
  //    return {type:yearsActions.years_GET.LOADING}

};

//******************Get yearss******************;


//******************Update years******************;


// function getyears(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.years.length>0)
//              store.dispatch({type:yearsActions.years_Get.SUCCESS,payload:response.years});
//              else
//              store.dispatch({type:yearsActions.years_GET.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:yearsActions.years_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:yearsActions.years_Get.FAILED});
//           })
//        return {type:yearsActions.years_GET.LOADING}

//     };

// function updateyears(data){
//   const request=fetch(ROOT_URL+'/api/exchangeRate/BulkUpdateExchangeRate', {
//     method: 'Post',
//     mode: 'cors',
//     headers: {'Content-Type':'application/json'},
//     body: JSON.stringify(data)
//     }).then((response)=>{
//      response.json().then(response=>{
//        if(response.status == "200"){
//          console.log(response)
//       store.dispatch({type:yearsActions.years_GET.SUCCESS});
//       return ;
//       }else
//       {
//         store.dispatch({type:yearsActions.years_GET.FAILED});
//         return ;
//         }    
//      });
//     }).catch((error) => {
//       store.dispatch({type:yearsActions.years_GET.FAILED});
//     })
// return {type:yearsActions.years_GET.LOADING}

// };
