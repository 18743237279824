import { connect } from "react-redux";
import ScheduledWeekListView from "./ScheduledWeekList_View";
import {ScheduledWeekListServer} from "./server"
import {ScheduledWeekListActions} from './ScheduledWeekListConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        ScheduledWeekList_GET_status:state.ScheduledWeekListReducer.ScheduledWeekList_Status,
        ScheduledWeekList:state.ScheduledWeekListReducer.ScheduledWeekListDetails,
        ScheduledWeekList_Error:state.ScheduledWeekListReducer.ScheduledWeekList_Error
       // ScheduledWeekListIndex:state.ScheduledWeekListReducer.ScheduledWeekListIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getScheduledWeekList: () => {

           dispatch( ScheduledWeekListServer.getScheduledWeekList() )
        },
        resetReducerState:()=>{
            dispatch({type:ScheduledWeekListActions.ScheduledWeekList_GET.NEW})
        }
    }
};
const ScheduledWeekListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduledWeekListView)

export default ScheduledWeekListContainer;