import React, { Component } from "react";
import "../ProgressBar/ProgressBar.css"
import { Link, withRouter} from "react-router-dom";
import { withCookies} from "react-cookie";

class ProgressBarFour extends Component {
  constructor(props){
    super(props)
    this.state={
      ProgressBar1:props.ProgressBar1,
      ProgressBar2:props.ProgressBar2,
      ProgressBar3:props.ProgressBar3,
      ProgressBar4:props.ProgressBar4
    }
  }
  setupOneHandler = () =>{
    this.props.history.push({
      pathname: "./business_profile"
    });
  }
  setupTwoHandler = () =>{
    if(this.props.cookies.get("BusinessProfileData")){
      this.props.history.push({
        pathname: "./business_contract"
      });
      return;
    }

    console.log("props loaction", this.props.location)
    
    this.props.history.push({
      pathname: this.props.location.pathname
    });
  }
  setupThreeHandler = () =>{
    if(this.props.cookies.get("BusinessProfileData")&&this.props.cookies.get("BusinessProfileData").coupon_contract_id){
      this.props.history.push({
        pathname: "./busines_coupon_quotas"
      });
      return
    }

    this.props.history.push({
      pathname: this.props.location.pathname
    });
    
  }

  setupFourHandler = () =>{
    if(this.props.cookies.get("BusinessProfileData")&&this.props.cookies.get("BusinessProfileData").coupon_contract_id && this.props.cookies.get("BusinessProfileData").override_default_coupon_behavior){
      this.props.history.push({
        pathname: "./OverridePricing"
      });
      return;
    }
    else if(this.props.cookies.get("BusinessProfileData")&&this.props.cookies.get("BusinessProfileData").coupon_contract_id){
      this.props.history.push({
        pathname: "./ManageCouponInventory"
      });
      return
    }      
    else{
      this.props.history.push({
        pathname: this.props.location.pathname
      });
    }
      

  }
  render() {

    return (
      <div className="conatianer-p-4">
        <ul id="progressbar" className="four-p-bar">
          <li className={this.state.ProgressBar1} onClick={this.setupOneHandler}><Link to="">1</Link></li>
          <li  className={this.state.ProgressBar2} onClick={this.setupTwoHandler}><Link to="">2</Link></li>
          <li className={this.state.ProgressBar3} onClick={this.setupThreeHandler}><Link to="">3</Link></li>
          <li className={this.state.ProgressBar4} onClick={this.setupFourHandler}><Link to="">4</Link></li>
        </ul>
      </div>
    );
  }
}

export default withRouter(withCookies(ProgressBarFour));
