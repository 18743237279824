export const UploadSplashScreenActions= {
	
	UploadSplashScreen_Post :{
		NEW:"UploadSplashScreen_Post_NEW",
		SUCCESS:"UploadSplashScreen_Post_SUCCESS",
		FAILED:"UploadSplashScreen_Post_FALIURE",
		LOADING:"UploadSplashScreen_Post_LOADING",
	},

	SplashScreen_Get :{
		NEW:"UploadSplashScreen_Get_NEW",
		SUCCESS:"UploadSplashScreen_Get_SUCCESS",
		FAILED:"UploadSplashScreen_Get_FALIURE",
		LOADING:"UploadSplashScreen_Get_LOADING",
	}
	
}


export const UploadSplashScreenStatus ={

	UploadSplashScreen_Post :{
		NEW:"UploadSplashScreen_Post_NEW",
		SUCCESS:"UploadSplashScreen_Post_SUCCESS",
		FAILED:"UploadSplashScreen_Post_FALIURE",
		LOADING:"UploadSplashScreen_Post_LOADING",
	},

	SplashScreen_Get :{
		NEW:"UploadSplashScreen_Get_NEW",
		SUCCESS:"UploadSplashScreen_Get_SUCCESS",
		FAILED:"UploadSplashScreen_Get_FALIURE",
		LOADING:"UploadSplashScreen_Get_LOADING",
	}

}
