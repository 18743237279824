import React, {  Fragment, PureComponent } from 'react'
//import './RetailerCouponsInventory.css'
import { RetailerCouponsInventoryStatus } from './RetailerCouponsInventoryConstants';
import RetailerCouponsInventory from "./RetailerCouponsInventory"
import { withRouter } from 'react-router-dom';
//import Fragment from "../ConfirmationContract/ConfirmationContract"
//import Snackbar from '@material-ui/core/Snackbar/';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../../Utils/Loading';
import { Api_Pagination } from "RefRecordsConstants";

export class RetailerCouponsInventoryView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      applyFilter:props.applyFilter,
      activePage:0
    }
    props.resetReducerState()
    this.getAllCoupons(

    )
  }
  
  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
    // this.props.getRetailerCouponsInventory()
  }

  getAllCoupons = ()=>{
    const data = {
      offset : (this.props.activePage - 1) * Api_Pagination.CouponApprovalLimit,
      year: this.props.year == 'all' ?  this.props.year: JSON.parse(this.props.year).name,
      month: this.props.month == 'all' ? this.props.month: JSON.parse(this.props.month).value,
      business_name: this.props.SelectedPartner,
      face_value: this.props.SelectedFaceValue,
      props:this.props
    }
    // alert(data.props)
    this.props.getFilteredCouponsInventory(data);
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.activePage !== nextProps.activePage){

      // ****** Handle Pagination ********
        const data = {
          offset : (nextProps.activePage - 1) * Api_Pagination.CouponApprovalLimit,
          year: nextProps.year == 'all' ?  nextProps.year: JSON.parse(nextProps.year).name,
          month: nextProps.month == 'all' ? nextProps.month: JSON.parse(nextProps.month).value,
          business_name: nextProps.SelectedPartner,
          face_value: nextProps.SelectedFaceValue,
          props:nextProps
        }
        // alert("active: "+data.props.setItemList)
        console.log("Filter Values", data)
        nextProps.getFilteredCouponsInventory(data)
        
      return{
        activePage:nextProps.activePage
      }
   }

   if (prevState.applyFilter !== nextProps.applyFilter){

        // **** offset is 0 because we want to fetch records from the start ****   
        const data = {
          offset : 0,
          year: nextProps.year == 'all' ?  nextProps.year: JSON.parse(nextProps.year).name,
          month: nextProps.month == 'all' ? nextProps.month: JSON.parse(nextProps.month).value,
          business_name: nextProps.SelectedPartner,
          face_value: nextProps.SelectedFaceValue,
          props:nextProps

        }
        // alert(data.props)
        console.log("Filter Values", data)

        // **** Active Page is set to 1 in order to move the pagination to 1st page ******
       
        // alert(data.props.setItemList)
       
        nextProps.handleActivePage(1)
        nextProps.getFilteredCouponsInventory(data)
        nextProps.applyFilterAction(false)

        return{
          applyFilter:nextProps.applyFilter
        }
    
   }
   
   return null;
 }


  getScreen(status) {
    switch (status) {
      case RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.NEW:
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center",height:"65vh", width:"100%" }}>
            <Loading/>
          </div>
        );


      case RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.SUCCESS:
        //  alert(JSON.stringify(this.props.RetailerCouponsInventory))
          // console.log("this.props.RetailerCouponsInventorythis.props.RetailerCouponsInventory:",this.props.RetailerCouponsInventory)
          // console.log("RetailerCouponsInventoryCount", this.props.RetailerCouponsInventoryCount)
          
          // console.log("count", this.props.RetailerCouponsInventoryCount)
          // if(this.props.RetailerCouponsInventoryCount > 0 && this.props.activePage == 1){
            // this.props.setItemList(this.props.RetailerCouponsInventoryCount)

          
          // else{

          // }
          //console.log("coupon", this.props.RetailerCouponsInventory)

        return (
          <Fragment >
            <RetailerCouponsInventory
            RetailerCouponsInventory={this.props.RetailerCouponsInventory}
            getRetailerCouponsInventory = {this.getAllCoupons}
            activePage={this.props.activePage}

            setYear={this.props.setYear}
            setMonth={this.props.setMonth}
            setFaceValue={this.props.setFaceValue}
            setPartner={this.props.setPartner}

            SelectedPartner={this.props.SelectedPartner}
            SelectedFaceValue={this.props.SelectedFaceValue}
            year={this.props.year}
            month={this.props.month}

            applyFilterAction={this.props.applyFilterAction}
            applyFilter={this.props.applyFilter}

            resetFilterStates={this.props.resetFilterStates}
            />
          </Fragment>
        );
        break;
      case RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.FAILED:
        // this.props.setItemList(0)
        toast.error(this.props.RetailerCouponsInventory_Error)
        return (
          <Fragment>
            <h1  className="parner-empt">Failed to get pending coupons list</h1>
            {/* <RetailerCouponsInventory saveRetailerCouponsInventory={this.props.saveRetailerCouponsInventory} 
            cookies={this.props.cookies}
            /> */}

          </Fragment>
        );

      case RetailerCouponsInventoryStatus.RetailerCouponsInventory_GET.LOADING:
        this.state.open = true
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center",height:"65vh", width:"100%" }}>
            <Loading/> 
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.RetailerCouponsInventory_GET_status)
    return (
      this.getScreen(this.props.RetailerCouponsInventory_GET_status)
    )
  }
}

export default withRouter(RetailerCouponsInventoryView)
