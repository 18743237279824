import { connect } from "react-redux";
import DeleteBannerView from "./DeleteBanner_View";
import {DeleteBannerServer} from "./server"
import {DeleteBannerActions} from './DeleteBannerConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        DeleteBanner_POST_status:state.DeleteBannerReducer.DeleteBanner_Status,
        DeleteBanner:state.DeleteBannerReducer.DeleteBannerDetails,
        DeleteBanner_Message:state.DeleteBannerReducer.DeleteBanner_Message
       // DeleteBannerIndex:state.DeleteBannerReducer.DeleteBannerIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        deleteBanner: (data) => {

           dispatch( DeleteBannerServer.deleteBanner(data) )
        },
        resetReducerState:()=>{
            dispatch({type:DeleteBannerActions.DeleteBanner_POST.NEW})
        }
    }
};
const DeleteBannerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteBannerView)

export default DeleteBannerContainer;