import React, { Component } from "react";
// import {PropTypes} from 'prop-types'
import moment from "moment";
import "../ScheduleBanner.css";

import { toast } from "react-toastify";
//import 'moment/locale/zh-cn';
// import 'antd/lib/style/index.less';     //Add this code for locally example
// import Scheduler, {SchedulerData, ViewTypes, DATE_FORMAT, DemoData} from '../GraphScheduler/utils'
import Scheduler, {
  SchedulerData,
  ViewTypes,
  // Resource,
} from "react-big-scheduler";
import Popover from 'antd/lib/popover'
import DemoData from "./utils/DemoData";
import "./utils/css/style.css";
import ProgressBar from "../../ProgressBar/ProgressBar";
// import {
//   Button,
//   UncontrolledPopover,
//   PopoverHeader,
//   PopoverBody,
// } from "reactstrap";

import withDragDropContext from "./withDnDContext";
// import { Link } from "react-router-dom";

const zoneIdData = [
  {name: "Select Zone", value:""},
  {name: "Zone 1", value:1},
  {name: "Zone 2", value:2},
  {name: "Zone 3", value:3},
  {name: "Zone 4", value:4},
]

class Basic extends Component {
  constructor(props) {
    super(props);

    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    let SchedulerDataConfig = {
      schedulerWidth: "90%",
      crossResourceMove: false,
      resourceName :"Resource Details"
    };
    let schedulerData = new SchedulerData(
      this.props.cookies.get("banner_week").week_start_date,
      ViewTypes.Week,
      false,
      false,
      SchedulerDataConfig
    );
    schedulerData.localeMoment.locale("en");
    console.log(this.props.selectedBanners, DemoData.resources);
    var events = this.props.scheduledBanners;
    var resources = this.props.selectedBanners;
    
    schedulerData.setResources(resources);

    schedulerData.setEvents(events);
    this.state = {
      viewModel: schedulerData,
      selectedResource: {},
      selectedSlot: {},
    };
    // alert(JSON.stringify(this.props.scheduledBanners))
  }
  OnSubmit = async () => {
    // if(this.props.isCurrentWeekSelected)
    // this.props.history.replace("/Manage_Banner");
    // else
    {
    let banner_weeks = {
      week_start_date: new Date(
        moment(this.props.Week[0]).format("YYYY-MM-DD")
      ),
      week_end_date: new Date(moment(this.props.Week[6]).format("YYYY-MM-DD")),
    };
    let banner_schedule_list = [];
    console.log(this.state.viewModel);
    await this.state.viewModel.events.forEach((event) => {
      let banner_schedule = {
        banner_id: event.banner_id,
        scheduled_day: event.start,
      };
      // alert(JSON.stringify(event))
      if (!event.alreadyScheduled) banner_schedule_list.push(banner_schedule);
    });
    if (banner_schedule_list.length > 0) {
      if (this.props.scheduledBanners.length == 0)
        this.props.BannerSchedule({
          banner_weeks: banner_weeks,
          banner_schedule_list: banner_schedule_list,
        });
      else {
        const data = {
          banner_weeks: banner_weeks,
          alreadyScheduled: true,
          tbl_banner_week_id: this.props.scheduledBanners[0].tbl_banner_week_id,
          banner_schedule_list: banner_schedule_list,
        };

        this.props.BannerSchedule(data);
      }
    } else {
      toast.error("No new Banner Scheduled Yet");
    }
  }
  };
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  slotItemTemplateResolver = (schedulerData, item, slotClickedFunc, width, clsName) => {
    const style2 = { "backgroundColor": "darkblue" };
    const style3 = { width: "265px" };

    var banner=  this.props.selectedBanners.find((banner)=>{
      
      if(banner.banner_id == item.slotId)
      return banner
    })


      let content = 
      <div className="" style={style3}>
        <div className="ant-row-flex ant-row-flex-middle">
          <div style = {{width:30}} className="ant-col ant-col-4">
            {/* <img className="status-dot" src = {this.state.selectedResource.banner_location} alt={"image not avaiables"}/> */}
            <div className="status-dot" style={style2}></div>
          </div>
          <div className="ant-col ant-col-20 overflow-text">
            <span className="header2-text">
              {banner.business_name}
            </span>
          </div>
        </div>
        <div></div>
        <div className="ant-row-flex ant-row-flex-middle">
          <div className="ant-col ant-col-24">
            {" "}
            <span className="help-text" >
              <b>Note:</b>
              {" "}{banner.notes}{" "}
            </span>
          </div>
        </div>
        <div className="ant-row-flex ant-row-flex-middle">
          <div className="ant-col ant-col-24 overflow-text">
            {" "}
            <span className="help-text" >
              <b>Allocation:</b>{" "}
              {banner.TotalAllocated} /{" "}
              {banner.number_of_days}
            </span>
          </div>
        </div>
      </div>



  let slotItem = <div  style={{flex:1, flexDirection:'row', display:'flex', justifyContent:"space-between"}}>
    <div className={clsName} style={{textAlign: 'left', marginTop:18}}><Popover placement = {"bottomLeft"} content={content} >
      <a  className="slot-cell fix">
        <span className="expander-space"></span>
        <span className="slot-text">{item.slotName.length>10?item.slotName.substring(0,10)+"...":item.slotName}</span>
        
        
          <span className="slot-text bg">
        {
                  banner.zone_id ?
                    zoneIdData.map((item, index) => {
                      return (item.value === banner.zone_id ? item.name : "")
                    }):
                    "---"
                  }
                    </span>
      </a></Popover>
  </div><img src = {banner.banner_location} alt={"not available"} style={{height:90,width:110, padding:10}}/>
 
     </div>
    return slotItem
}

  render() {

    const { viewModel } = this.state;
    return (
      <div className="card-list">
        <div className="upload-pro">
          <ProgressBar
            ProgressBar3="active"
            ProgressBar2="active"
            ProgressBar1="active"
          />
        </div>
        <div className="banner-ww">
          <div className="banner-container">
            <Scheduler
              schedulerData={viewModel}
              prevClick={this.prevClick}
              nextClick={this.nextClick}
              onSelectDate={this.onSelectDate}
              onViewChange={this.onViewChange}
              eventItemClick={this.eventClicked}
              viewEventClick={this.eventClicked}
              // viewEventText="Ops 1"
              //    viewEvent2Text="Ops 2"
              //    viewEvent2Click={this.ops2}
              updateEventStart={this.updateEventStart}
              updateEventEnd={this.updateEventEnd}
              moveEvent={this.moveEvent}
              newEvent={this.newEvent}
              onScrollLeft={this.onScrollLeft}
              onScrollRight={this.onScrollRight}
              onScrollTop={this.onScrollTop}
              onScrollBottom={this.onScrollBottom}
              toggleExpandFunc={this.toggleExpandFunc}
              slotClickedFunc={this.slotClickedFunc}
              slotItemTemplateResolver = {this.slotItemTemplateResolver}

              // newEvent={()=>alert("test")}
            />
          </div>
          {/* Popup on Resource Element */}

          <div className="upload-form-button-box margin">
            <div className="back-btn">
              <a id="backLink" onClick={this.props.Go_Back} className="back-link">
                Back
              </a>
            </div>
            <div className="upload-next-btn">
              <a id="finishLink"  onClick={this.OnSubmit}>Finish</a>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    // schedulerData.setDate(date);
    // schedulerData.setEvents(DemoData.events);
    // this.setState({
    //     viewModel: schedulerData
    // })
  };

  eventClicked = (schedulerData, event) => {

    var events = schedulerData.events.filter((item) => {
      if (item.id != event.id) return item;
      else if (item.alreadyScheduled == 1) return item;
    });
    // alert(JSON.stringify(events))
    console.log("events", events);
    schedulerData.setEvents(events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  ops1 = (schedulerData, event) => {
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };
  isWeekend = (date1, date2) => {
    var d1 = new Date(date1),
      d2 = new Date(date2),
      isWeekend = false;

    while (d1 < d2) {
      var day = d1.getDay();
      isWeekend = day === 6 || day === 0;
      if (isWeekend) {
        return true;
      } // return immediately if weekend found
      d1.setDate(d1.getDate() + 1);
    }
    return false;
  };
  isDateOverlap = (startD1, startD2, endD1, endD2) => {
    return (
      (startD1 >= startD2 && startD1 <= endD2) ||
      (startD2 >= startD1 && startD2 <= endD1) ||
      (startD1 >= startD2 && endD1 <= endD2) ||
      (startD2 >= startD1 && endD2 <= endD1)
    );
  };
  async isDateValidForSchedule(schedulerData, slotId, start, end, eventId) {
    // if(this.props.isCurrentWeekSelected)
    // return false
    var flag = true;
    var banner = await this.props.selectedBanners.filter((banner) => {
      if (banner.banner_id == slotId) {
        return banner;
      }
    });
    //banner is selected for week days. not schedule it for weekend
    var allocated = banner[0].TotalAllocated;
    // alert(allocated)
    if (banner[0].AlreadyAllocated)
     {
      //  alert(allocated +"  "+ banner[0].AlreadyAllocated +"  "+ banner[0].number_of_days)
       if(allocated != banner[0].number_of_days)
       allocated = banner[0].AlreadyAllocated;
      if(parseInt(allocated)===1)
      allocated = 0
      // alert(allocated)
    }

    if (allocated >= banner[0].number_of_days) {
      flag = false;
      
    } else if (banner[0].week_days && this.isWeekend(start, end)) {
      flag = false;
      // alert("1"+flag)
    }

    //banner is selected for weekend. not schedule for week days
    else if (banner[0].week_ends && !this.isWeekend(start, end)) {
      flag = false;
      // alert("2"+flag)
    } else {
      var compareDate = moment(start, "YYYY-MM-DD");
      var startDate = moment(banner[0].start_date, "YYYY-MM-DD");
      var endDate = moment(banner[0].end_date, "YYYY-MM-DD");
      // is given date in between banner range
      if (
        !(
          compareDate.isBetween(startDate, endDate) ||
          compareDate.isSame(startDate) ||
          compareDate.isSame(endDate)
        )
      ) {

        flag = false;
        // alert("3"+flag)
      } else {
        var scheduleCount = 0;
        var isAlreadySchedule = await schedulerData.events.filter((item) => {
          console.log(slotId, item.banner_id);
          if (item.banner_id == slotId && item.id != eventId) {
            // alert(scheduleCount)
            scheduleCount++;
            console.log(this.isDateOverlap(item.start, start, item.end, end));
            if (this.isDateOverlap(item.start, start, item.end, end))
              return item;
          }
        });
        if (isAlreadySchedule.length > 0) {
    
          flag = false;
          // alert("4"+flag)
        } else if (
          scheduleCount + parseInt(allocated) >=
          parseInt(banner[0].number_of_days)
        ) {

          flag = false;
          // alert("5"+flag)
        } else {
          flag = true;
        }
      }
    }

    return flag;
  
  }
  CompareIsEqualDate = (start, end) => {
    return moment(start).startOf("day").isSame(moment(end).startOf("day"));
  };
  newEvent = async (
    schedulerData,
    slotId,
    slotName,
    start,
    end,
    type,
    item
  ) => {
    //if(alert(`Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`))
    {
      // alert(start)
      let newFreshId = 0;
      // console.log(this.CompareIsEqualDate(start,end))
      console.log(
        "&&&&&&&",
        await this.isDateValidForSchedule(
          schedulerData,
          slotId,
          start,
          end,
          -1
        ),
        start.date,
        end.date
      );
      if (
        (await this.isDateValidForSchedule(
          schedulerData,
          slotId,
          start,
          end,
          -1
        )) &&
        this.CompareIsEqualDate(start, end)
      ) {
        // alert("true")

        schedulerData.events.forEach((item) => {
          if (item.id >= newFreshId) newFreshId = item.id + 1;
        });

        let newEvent = {
          id: newFreshId,
          title: slotName,
          start: start,
          end: end,
          resourceId: slotId,
          banner_id: slotId,
          bgColor: "darkblue",
        };
        schedulerData.addEvent(newEvent);
        this.setState({
          viewModel: schedulerData,
        });
      }
    }
  };
  
  updateEventStart = async (schedulerData, event, newStart) => {
    //if(alert(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`))
    // console.log(event)
    // if (
    //   await this.isDateValidForSchedule(
    //     schedulerData,
    //     event.banner_id,
    //     newStart,
    //     event.end,
    //     event.id
    //   )
    // ) {
    //   schedulerData.updateEventStart(event, newStart);
    // }
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = async (schedulerData, event, newEnd) => {
    //if(alert(`Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`))
    // {
    //     console.log(event)
    // if (
    //   await this.isDateValidForSchedule(
    //     schedulerData,
    //     event.banner_id,
    //     event.start,
    //     newEnd,
    //     event.id
    //   )
    // ) {

    //   schedulerData.updateEventEnd(event, newEnd);
    // }

    //}
    this.setState({
      viewModel: schedulerData,
    });
  };

  moveEvent = async (schedulerData, event, slotId, slotName, start, end) => {
    //if(alert(`Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`))
    //  {

    console.log("schedule", event)
    if(!event.alreadyScheduled)
    {

    
    if (await this.isDateValidForSchedule(schedulerData, slotId, start, end)) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
    }
    this.setState({
      viewModel: schedulerData,
    });
    // }
  }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollTop");
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollBottom");
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
        viewModel: schedulerData
    });
}
slotClickedFunc = (schedulerData, slot) => {
    console.log(this)
    var selectedResource = this.props.selectedBanners.filter(item =>{
        return item.id == slot.slotId
    })
    console.log(selectedResource)
    if(Object.keys(this.state.selectedResource).length == 0)
    this.setState({selectedSlot: slot, selectedResource:selectedResource[0]})
    else
    this.setState({selectedSlot: slot, selectedResource:{}})
    
    //return this.eventItemPopoverTemplateResolver(schedulerData,slot)
    // alert(`You just clicked a ${schedulerData.isEventPerspective ? 'task':'resource'}.{id: ${slot.slotId}, name: ${slot.slotName}}`);
}

}
export default withDragDropContext(Basic);


