export const RetailerCouponApprovalActions= {
	
	RefrenceData_Update :{
		NEW:"RefrenceData_Update_NEW",
		SUCCESS:"RefrenceData_Update_SUCCESS",
		FAILED:"RefrenceData_Update_FALIURE",
		LOADING:"RefrenceData_Update_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const RetailerCouponApprovalStatus ={

	RefrenceData_Update :{
		NEW:"RefrenceData_Update_NEW",
		SUCCESS:"RefrenceData_Update_SUCCESS",
		FAILED:"RefrenceData_Update_FALIURE",
		LOADING:"RefrenceData_Update_LOADING",
	}

}
