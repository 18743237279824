import {
  CreateApplicantProfileStatus,
  CreateApplicantProfileActions
} from "./CreateApplicantProfileConstants";

const CreateApplicantProfile_initialState = {
  CreateApplicantProfile_Status: CreateApplicantProfileStatus.CreateApplicantProfile_Post.NEW,
  CreateApplicantProfile_Error: "",
  CreateApplicantProfileDetails:{}
};
export default function(state = CreateApplicantProfile_initialState, action) {
  switch (action.type) {
    case CreateApplicantProfileActions.CreateApplicantProfile_Post.LOADING:
      return {
        ...state,
        CreateApplicantProfile_Status:
          CreateApplicantProfileStatus.CreateApplicantProfile_Post.LOADING
      };
    case CreateApplicantProfileActions.CreateApplicantProfile_Post.NEW:
      return {
        ...state,
        CreateApplicantProfile_Status:
          CreateApplicantProfileStatus.CreateApplicantProfile_Post.NEW,
        CreateApplicantProfileDetails: {}
      };
    case CreateApplicantProfileActions.CreateApplicantProfile_Post.SUCCESS:
   
      return {
        ...state,
        CreateApplicantProfile_Status:
          CreateApplicantProfileStatus.CreateApplicantProfile_Post.SUCCESS,
        CreateApplicantProfileDetails: action.payload
      };
    case CreateApplicantProfileActions.CreateApplicantProfile_Post.FAILED:
      return {
        ...state,
        CreateApplicantProfile_Status:
          CreateApplicantProfileStatus.CreateApplicantProfile_Post.FAILED
      };

    default:
      return {
        ...state,
        CreateApplicantProfile_Error: "",
        CreateApplicantProfile_Status: CreateApplicantProfileStatus.CreateApplicantProfile_Post.NEW
      };
  }
}
