import { GetCouponImagesStatus, PreviousCouponsActions } from './GetCouponImagesConstants';

const GetCouponImages_initialState = {
    GetCouponImages_Status: GetCouponImagesStatus.GetCouponImages_GET.NEW,
    GetCouponImages_Error: '',
    GetCouponImages: [],
}
export default  function  (state = GetCouponImages_initialState, action) {
    switch (action.type) {
        case PreviousCouponsActions.GetCouponImages_GET.LOADING:
            return { ...state, GetCouponImages_Status: GetCouponImagesStatus.GetCouponImages_GET.LOADING }
            case PreviousCouponsActions.GetCouponImages_GET.NEW:
                return { ...state, GetCouponImages_Status: GetCouponImagesStatus.GetCouponImages_GET.NEW, GetCouponImages: [] }
    
        case PreviousCouponsActions.GetCouponImages_GET.FAILED:
            return { ...state,  GetCouponImages_Status: GetCouponImagesStatus.GetCouponImages_GET.FAILED, GetCouponImages_Error:action.error}
        case PreviousCouponsActions.GetCouponImages_GET.SUCCESS:
        //  console.warn(action.payload)
          return { ...state,  GetCouponImages_Status: GetCouponImagesStatus.GetCouponImages_GET.SUCCESS, GetCouponImages:action.payload}
        
        default:
            return { ...state,
            }
    }
}
