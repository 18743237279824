import React, { Suspense, Component } from 'react';
import { Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import './App.css';

import { VERSION_NO } from './Config/Config';
import BusinessEnrollmentContainer from './Components/EnrollmentForm/BusinessEnrollmentContainer';
import AdministrationMain from './Components/AdministrationMain/AdministrationMain';
import BusinessList from './Components/BusinessList/BusinessListContainer';
import BusinessProfile from './Components/BusinessProfile/BusinessProfileContainer';
import BusinessContract from './Components/BusinessContract/BusinessContractContainer';
import BusinesCouponQuotas from './Components/BusinessCouponQuotas/BusinessCouponQuotasContainer';
import AdministrationLoginPage from './Components/AdministrationLoginPage/AdministrationLoginContainer';
import Header from './Components/HeaderSection/Header';
import InnerHeader from './Components/HeaderSection/InnerHeader';
import ListofNewApplicants from './Components/ListOfApplicants/ListofNewApplicants';
import Invoices from './Components/Invoices/Invoices';
import UploadBanner from './Components/UploadBanner/UploadBannerContainer';
import UploadBannerManage from './Components/UploadBannerManage/UploadBannerManage';
import BannerScheduleMainContainer from './Components/ScheduleBanners/BannerScheduleMainContainer';
import CouponImageMainContainer from './Components/UploadCoupon/CouponImageMainContainer';
import GenerateCouponsContainer from './Components/OverridePricing/GenerateCoupons/GenerateCouponsContainer';
import OverridePricing from './Components/OverridePricing/ManageOveridePricing/OverridePricingContainer';
import UserManagement from './Components/UserManagement/UserManagement';
import AddNewUser from './Components/UserManagement/AddNewUser/AddNewUserContainer';
import ChangePassword from './Components/UserManagement/ChangePassword/ChangePasswordContainer';
import EditEmail from './Components/UserManagement/ChangeEmail/ChangeEmailContainer';
import ManageMessaging from './Components/ManageMessaging/ManageMessaging';
import MessageHistory from './Components/ManageMessaging/MessageHistory/MessageHistory';
import RetailerCouponApproval from './Components/RetailerCouponApproval/RetailerCouponApproval';
import InnerHeaderBranding from "./Components/HeaderSection/InnerHeaderBranding"
import HeaderInner from "./Components/HeaderSection/HeaderBranding"
import AdministrationMainTwo from './Components/AdministrationMain/AdministrationMainTwo';


class App extends Component {
  componentWillMount() {}

  render() {
    console.log(this.props);
    console.log('TEST :', this.props);
    return (
      <div>
        {/* <Header cookies={this.props.cookies} /> */}
        <HeaderInner cookies={this.props.cookies} />
        {/* <InnerHeader cookies={this.props.cookies} /> */}
        <InnerHeaderBranding cookies={this.props.cookies} />

        <Suspense fallback={<div style={{ height: '100vh' }}> Loading...</div>}>
          <Route
            exact
            path='/business'
            render={() => <BusinessEnrollmentContainer />}
          />
          <Route
            exact
            path='/'
            render={() => (
              <AdministrationLoginPage cookies={this.props.cookies} />
            )}
          />
          <Route
            exact
            path='/Admin'
            // render={() => <AdministrationMain cookies={this.props.cookies} />}
            render={() => <AdministrationMainTwo cookies={this.props.cookies} />}
          />

          <Route
            exact
            path='/business_list'
            render={() => <BusinessList cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/business_profile'
            render={() => <BusinessProfile cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/business_contract'
            render={() => <BusinessContract cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/busines_coupon_quotas'
            render={() => <BusinesCouponQuotas cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/list_of_applicants'
            render={() => <ListofNewApplicants cookies={this.props.cookies} />}
          />

          <Route
            exact
            path='/Invoices'
            render={() => <Invoices cookies={this.props.cookies} />}
          />

          <Route
            exact
            path='/Upload_Banner_Form'
            render={() => <UploadBanner cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/Manage_Banner'
            render={() => <UploadBannerManage cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/Banner_Scheduler'
            render={() => (
              <BannerScheduleMainContainer cookies={this.props.cookies} />
            )}
          />
          <Route
            exact
            path='/Upload_Coupon_Image'
            render={() => (
              <CouponImageMainContainer cookies={this.props.cookies} />
            )}
          />

          <Route
            exact
            path='/UserManagement'
            render={() => <UserManagement cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/ChangePassword'
            render={() => <ChangePassword cookies={this.props.cookies} />}
          />

          <Route
            exact
            path='/EditEmail'
            render={() => <EditEmail cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/ManageCouponInventory'
            render={() => (
              <GenerateCouponsContainer cookies={this.props.cookies} />
            )}
          />
          <Route
            exact
            path='/OverridePricing'
            render={() => <OverridePricing cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/ManageMessaging'
            render={() => <ManageMessaging cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/MessageHistory'
            render={() => <MessageHistory cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/AddNewUser'
            render={() => <AddNewUser cookies={this.props.cookies} />}
          />
          <Route
            exact
            path='/RetailerCouponsForApproval'
            render={() => (
              <RetailerCouponApproval cookies={this.props.cookies} />
            )}
          />
        </Suspense>
        <div className='version-get-b'>
          <div className='version'>{VERSION_NO}</div>
        </div>
      </div>
    );
  }
}

export default withCookies(App);
