import React, {  Fragment, PureComponent } from 'react'
//import './UserRoleList.css'
import { UserRoleListStatus } from './UserRoleListConstants';
import CustomLabelDropDownInput from "../../../Inputs/CustomLabelDropDownInput/CustomLabelDropDownInput";
import { toast } from 'react-toastify';
import Loading from '../../../Utils/Loading';

let RolesData = [
  // {name:"Admin",value:1},
  {name:"Partner",value:1}
]

export class UserRoleListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      disabled: false
    }
    this.props.getUserRoleList()
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.AddNewUser_POST_status === UserRoleListStatus.UserRoleList_GET.SUCCESS){
      nextProps.setLoading(false)
    }
    else if(nextProps.AddNewUser_POST_status === UserRoleListStatus.UserRoleList_GET.FAILED){
      alert(nextProps.UserRoleList_Error)
      toast.error(nextProps.UserRoleList_Error)
     
      nextProps.setLoading(false)
    }
    else if(nextProps.AddNewUser_POST_status === UserRoleListStatus.UserRoleList_GET.LOADING){
      // if(this.props.setLoading)
      nextProps.setLoading(true)
    }
    
    return null
  }
  getScreen(status) {
    switch (status) {
      case UserRoleListStatus.UserRoleList_GET.NEW:
        
        return (
          <Fragment >
            <CustomLabelDropDownInput
                label="Role"
                name="name"
                id="roleDropDown"
                value=""
                firstName="Select"
                inputValue={this.props.inputValue}
                hideFirst={true}
                onChange={this.props.onChange}
                data={RolesData}
                disabled={this.props.disabled}
                // disabled={RolesData == "1" ? false: false}
                //disabled={this.state.disableAllFields}
               
              />
          </Fragment>
        );


      case UserRoleListStatus.UserRoleList_GET.SUCCESS:
          if(this.props.setLoading)
          // this.props.setLoading(false)
          RolesData = this.props.UserRoleList.map((role)=>{
            return {name:role.role_name, value: role.role_id}
          })

          //console.log("user role list", RolesData)

        return (
          <Fragment >
            <CustomLabelDropDownInput
                label="Role"
                name="name"
                id="roleDropDown"
                value=""
                firstName="Select"
                inputValue={this.props.inputValue}
                hideFirst={true}
                onChange={this.props.onChange}
                data={RolesData}
                //disabled={this.state.disableAllFields}
              />
          </Fragment>
        );
        break;
      case UserRoleListStatus.UserRoleList_GET.FAILED:
       
        return (
          <Fragment>
            
           <CustomLabelDropDownInput
                label="Role"
                name="name"
                id="roleDropDown"
                value=""
                firstName="Select"
                inputValue={this.props.inputValue}
                hideFirst={true}
                onChange={this.props.onChange}
                data={RolesData}
                //disabled={this.state.disableAllFields}
              />
          </Fragment>
        );
      case UserRoleListStatus.UserRoleList_GET.LOADING:
      
        if(this.props.stopLoading)
        return <div></div>
        else
        return (
          <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center", width:"100%" }}>
            <Loading/>
          </div>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.UserRoleList_GET_status)
    return (
      this.getScreen(this.props.UserRoleList_GET_status)
    )
  }
}

export default (UserRoleListView)
