
//******************Exchange Rate Server Calls******************;

import {ROOT_URL, getCookie} from '../../../Config/Config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { failure_messages } from 'Messages';


export const BusinessCouponQuotasServer = {
    getBusinessCouponQuotasList:getBusinessCouponQuotasList,
    
};

  
//******************Get BusinessCouponQuotass******************;


  
function getBusinessCouponQuotasList(data){
  data.setLoading(true)
  data.setListLoading(true) 
    data.setQuotas([], "")
             
      const request=fetch(ROOT_URL+'/api/coupons/getBusinessCouponQuotasList/', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
            
            response.json().then(response=>{
              if(response.status == "200"){
             data.setQuotas(response.data, "")
            //  data.setRecentlySaved(true)
             data.setLoading(false)
             data.setListLoading(false)
             return ;
             }else
             {


                data.setQuotas([], response.error)
                
                toast.error(response.error)
                data.setLoading(false)
                data.setListLoading(false)
               return ;
               }    
            });
           }).catch((error) => {
            data.setQuotas([], failure_messages.quotasListError)
            // alert("test")
            toast.error(failure_messages.quotasListError)
            data.setLoading(false)
            data.setListLoading(false)
          })
             
      // return {type:BusinessCouponQuotasActions.BusinessCouponQuotas_Post.LOADING}
 
    };
  
