import React, { Component } from 'react';
import './MultiSelectDropdown.css';
import { Multiselect } from 'multiselect-react-dropdown';

class MultiSelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    };
    if (this.props.businessCategoryNames) {
      let category_names_array = this.props.businessCategoryNames.split('+');
      console.log(category_names_array, 'ajshdjahsdjahsd');
      let businessCategoryOptions = this.props.data;
      let selectedOptions = [];

      if(businessCategoryOptions){

        category_names_array.forEach((businessCategory)=>{
          
          businessCategoryOptions.forEach((businessCategoryOption)=>{
            if(businessCategoryOption.name === businessCategory){
              console.log("pushing option", businessCategoryOption)
              selectedOptions.push(businessCategoryOption)
            }
          })          
        })

      }

      console.log(selectedOptions, 'akjsdajshdjas');
      this.props.onSelect(selectedOptions);
      this.state.selected = selectedOptions
     
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}
 
  render() {
   
    const {
      label = 'Business(s)',
      onChange,
      errorMsg = 'errorMsg',
      flag = false,
      containerStyle,
      loaderStyle,
      readOnly = false,
      disabled,
      inputStyle,
      onSelect,
      // onRemove,
      placeHolder = 'Select Business',
      inputValue,
      // LOADER,
      data = this.state.options,
      star
    } = this.props;
    // console.log(this.props.businessCategoryNames, "ajhsdhasdha")
    return (
      <>
        <div className='li_container select-dro-mult' style={containerStyle}>
          <label htmlFor='' className='li_label'>
            {label}<span style={{color: "red"}}>{star}</span>
          </label>
          <div className='li_input_container'>
            <Multiselect
              id="MultiSelect"
              options={data} // Options to display in the dropdown
              selectedValues={inputValue ? inputValue : this.state.selected} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onSelect} // Function will trigger on remove event
              displayValue='name' // Property name to display in the dropdown options
              onChange={onChange}
              style={inputStyle}
              disabled={disabled}
              readOnly={readOnly}
              value={inputValue ? inputValue : this.state.selected}
              placeholder={placeHolder}
              emptyRecordMsg='No Option Available'
              avoidHighlightFirstOption='false'
            />
            <p
              className='li_error_text'
              style={{ display: flag ? 'block' : 'none' }}
            >
              {errorMsg}
            </p>
          </div>
          {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader'  style={loaderStyle}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default MultiSelectDropdown;

