import React, { Component } from "react";
import "../Messaging.css";
import MessageHistoryContainer from "./MessageHistoryContainer"
import {Api_Pagination} from "RefRecordsConstants"

// import DateFormat from "dateformat";
import { Link } from "react-router-dom";
// import Loading from "../../Utils/Loading";
class MessageHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NoOfPages: 0,
      showDropdown:true,
      activePage: 1,
      isLoading:false

    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  setLoading = (loading) =>{
    //  alert(loading)
    this.state.isLoading = loading
    
    }

    setItemList = (ListLength) => {
    
    if (ListLength - 1 > 0)
      this.setState({
        NoOfPages: Math.trunc((ListLength - 1) / Api_Pagination.NotificationHistoryLimit) + 1,
        
      });
  };

  ChangePage = (page) => {
    // alert(this.state.isLoading)
    console.log("Loading page:",this.state.isLoading)
    if (this.state.activePage != page && !this.state.isLoading) {
      this.setState({
        activePage: page,
      });
    //   this.setLoading(true)
    }
  };
  handleNext = () =>{
    //   alert(this.state.isLoading)
    if(this.state.activePage < this.state.NoOfPages && !this.state.isLoading){
        this.setState({
            activePage:this.state.activePage+ 1
        })
        // this.setLoading(true)
    }
}
handlePrev = () =>{
    // alert(this.state.isLoading)
    if(this.state.activePage > 1 && !this.state.isLoading){
        this.setState({
            activePage:this.state.activePage- 1
        })
        // this.setLoading(true)
    }
}

  render() {
    const items = [];
    let key = 1
    items.push(<Link to="#" key={key} onClick={() => this.ChangePage(1)}   className={this.state.activePage == 1 ? "active-page" : null} >   1 </Link>
    );
    let i = 2;
    let offset = 6;
    if (this.state.activePage > 3 && this.state.NoOfPages > 5) {
      offset = this.state.activePage + 3;
      i = this.state.activePage - 1;
      key=key+1
      items.push(<Link key={key} to="#">{"..."}</Link>);
    }
    if(this.state.activePage + 3 > this.state.NoOfPages && i > 2)
    i = this.state.NoOfPages - 3
    for (
      let index = 0 + i;
      index < this.state.NoOfPages && index < offset -1;
      index++
    ) {
      key=key+1
      items.push( <Link to="#" key={key}  key={index}  onClick={() => this.ChangePage(index)}  className={i == this.state.activePage ? "active-page" : null} >  {i} </Link>);
      i++;
    }
    key=key+1
    if (this.state.NoOfPages > this.state.activePage + 2)
    if(this.state.NoOfPages > 5)
    
    items.push(<Link key={key} to="#">{"..."}</Link>);    
    items.push(  <Link key={key} to="#"  onClick={() => this.ChangePage(this.state.NoOfPages)} className={this.state.activePage == this.state.NoOfPages ? "active-page" : null}>{this.state.NoOfPages} </Link>
    );

    return (
      <div className="upload-banner-wrap message">
        <div className="upload-banner-container">
          <div className="upload-banner-box">
            <div className="upload-banner-box2">
              <div className="upload-header-heading msge-main-hading-2">
                <h2 className="upload-header-head">Messaging History</h2>
              </div>
              <div className="mesg-history-box">
                {/* <div className="shakir-hassan"> */}

                <div className="mesge-history-in-b">
                  <div className="msge-head">
                    <div className="mesg-row-1 head-f">Sent to</div>
                    <div className="mesg-row-1 head-f">Date Sent</div>
                    <div className="mesg-row-3 head-f">Message</div>
                    <div className="mesg-row-1 head-f"></div>
                  </div>
                  <MessageHistoryContainer 
                                    activePage={this.state.activePage}
                                    setItemList={this.setItemList}
                                    
                                    setLoading = { this.setLoading}
                                    />

                </div>
                                    {/* </div> */}
                
              </div>

            </div>
          </div>
        </div>
        {this.state.NoOfPages > 1 ? (
                <div className="pagination-box">
                  <div className="pagination-inner-box">
                    <div className="box-pagination">
                      <div className="next-btn">
                        <div className="cont1">
                          <Link id="previousLink" to="#" onClick={this.handlePrev}>prev</Link>
                        </div>
                        <div style={{minWidth:300}} className="count-btn">
                        {items}
                        </div>
                        <div className="cont1">
                          <Link id="nexLink" to="#" onClick ={this.handleNext}>next</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
      </div>
    );
  }
}

export default MessageHistory;
