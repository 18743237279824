
import React from 'react';
import { Fragment } from 'react';

import Select from 'react-select';
import "./SelectSearchInput.css"

const customStyles = {
  menu: (provided) => ({
    ...provided,
    
  }),
  control: (provided) => ({
  ...provided,
  // height:50
  paddingTop:4,
  paddingBottom:4
  }),
  singleValue: (provided) => {

    return { ...provided };
  }
}

export default class SelectSearchInput extends React.PureComponent {
 
  constructor(props){
    super(props)
    this.state = {
        inputValue:"",
        focus:false,
    }
}
 
handleChange = (newValue, actionMeta) => {
  // alert(JSON.stringify(newValue))
  this.setState({
      inputValue: newValue.label,
      focus:true
  })
  this.props.onChange(newValue)
  // console.group('Value Changed');
  // console.log(newValue);
  // console.log(`action: ${actionMeta.action}`);
  
};
handleInputChange = (inputValue, actionMeta) => {
    // alert(JSON.stringify(inputValue))
    if(actionMeta.action == "input-change")
   { 
     var flag = true
    if(this.props.maxLimit && inputValue.length>this.props.maxLimit)
    flag  = false

if(flag)
{    var e={
      label:inputValue
    }
    this.props.onChange(e)
     this.setState({
      inputValue :inputValue,
      focus:false
    })
  
  }
}
};
  render() {
    let items = [
      { value: '1', label: 'Chocolate' },
      { value: '2', label: 'Strawberry' },
      { value: '3', label: 'Vanilla', isDisabled: true }
    ]

    //console.log(this.state);
    const {
      // errorMsg = "errorMsg",
      // flag = false,
      disabled = false,
      onChange,
      inputValue="",
      // handleInputChange,
      loaderStyle,
      data = items,
      placeholder="Search for Retailer Name",
      maxLimit
    } = this.props;
    // alert(inputValue.length >= maxLimit)
  // alert(inputValue.length >= maxLimit)
    console.log("length", inputValue)
    console.log("length", maxLimit)


    console.log("length", disabled)

    return (
      <Fragment>
      <Select
      // isDisabled={disabled}
        blurInputOnSelect={true}
        isClearable={true}
        value={inputValue}
        closeMenuOnSelect ={true}
        onChange={onChange}
        isSearchable={false}
        isMulti={inputValue}
        placeholder = {placeholder}
        openMenuOnClick={true}
        noOptionsMessage={()=>"No Options Remaining"}
        className="select-search-input"
        classNamePrefix="imagePrefixInput"
        options={data}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F1F1F1',
            primary: '#cccccc',
            primary50: "#F1F1F1"
          },
        })}
        isOptionDisabled={()=>(Array.isArray(inputValue) && inputValue.length >= maxLimit)}
        
        // isOptionDisabled={(inputValue.length >= maxLimit)}
        styles={customStyles}
        
      />
      {/* {maxLimit && inputValue?
        <p className="ltai_label" style={{flex:1,alignSelf:'flex-end', textAlign:'right'}}>{inputValue.length} / {maxLimit}</p>:
        null
      } */}

               {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader'  style={loaderStyle}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}
