import React, {  Fragment, PureComponent } from 'react'
//import './BusinessList.css'
import { BusinessListStatus } from './BusinessListconstants';
import BusinessList from "./BusinessList"
import { withRouter } from 'react-router-dom';
//import Fragment from "../ConfirmationContract/ConfirmationContract"
//import Snackbar from '@material-ui/core/Snackbar/';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import Loading from '../Utils/Loading';

export class BusinessListView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      sortBy: "tbl_businesses.business_id",
      sortyType: "desc",
      searchVal: "",
      sortFlag:false,
      showDisabled:false
    }

  }
  UNSAFE_componentWillMount() {
    this.props.resetReducerState();
    let data = {
      sortBy: this.state.sortBy,
      sortyType: this.state.sortyType,
      searchVal: this.state.searchVal,
    };
    this.props.getBusinessList(data);
  }
  closeSnackBar = () => {
    this.setState({
      open: false
    })

  }
  closePostSnackBar = () => {
    this.setState({
      open: false
    })
    // this.props.getBusinessList()
    let data = {
      sortBy: this.state.sortBy,
      sortyType: this.state.sortyType,
      searchVal: this.state.searchVal,
      showDisabled: this.state.showDisabled
    };
    this.props.getBusinessList(data);
  }
  applySortOnBusiness = () => {
    let sortyType = this.state.sortyType === "desc" ? "asc" : "desc";
    this.setState(
      {
        sortBy: "business_name",
        sortyType: sortyType,
        sortFlag: true
      },
      () => {
        let data = {
          sortBy: this.state.sortBy,
          sortyType: this.state.sortyType,
          searchVal: this.state.searchVal,
          showDisabled: this.state.showDisabled
        };
        this.props.getBusinessList(data);
      }
    );
  };
  applySearchOnBusinessName = (v) => {
    this.setState(
      {
        searchVal: v,
      }
    );
  };
  searchrecordByBusinessName = ()=>{
    let data = {
      sortBy: this.state.sortBy,
      sortyType: this.state.sortyType,
      searchVal: this.state.searchVal,
      showDisabled: this.state.showDisabled
    };
    this.props.getBusinessList(data);
  }

  setShowDisabled = () =>{

    this.setState({
      showDisabled: !this.state.showDisabled
    }, ()=>{
      let data = {
        sortBy: this.state.sortBy,
        sortyType: this.state.sortyType,
        searchVal: this.state.searchVal,
        showDisabled: this.state.showDisabled
      };

      this.props.getBusinessList(data);
    })
  }

  refreshBusinessList = () =>{
    if(!this.state.showDisabled){
      let data = {
        sortBy: this.state.sortBy,
        sortyType: this.state.sortyType,
        searchVal: this.state.searchVal,
        showDisabled: this.state.showDisabled
      };

      this.props.getBusinessList(data);
    }
  }


  getScreen(status) {
    switch (status) {
      case BusinessListStatus.BusinessList_GET.NEW:
        return (
          <Fragment >
            <BusinessList BusinessList={this.props.BusinessList}
              PrevSearchVal={this.state.searchVal}
              applySortOnBusiness={this.applySortOnBusiness}
              applySearchOnBusinessName={this.applySearchOnBusinessName}
              searchrecordByBusinessName={this.searchrecordByBusinessName}
              sortFlag={this.state.sortFlag}
              sortyType={this.state.sortyType}
              setShowDisabled={this.setShowDisabled}
              showDisabled={this.state.showDisabled}
              refreshBusinessList={this.refreshBusinessList}
              cookies={this.props.cookies}
            />
          </Fragment>
        );


      case BusinessListStatus.BusinessList_GET.SUCCESS:
        //  alert(JSON.stringify(this.props.BusinessList))
          // alert(JSON.stringify(this.props.BusinessList[170]))
        // console.log("business list success", this.props.BusinessList[0].is_disabled)
        
        // let filteredBusinessList;

        // if(this.props.BusinessList.length > 0){
        //   if(!this.state.showDisabled)
        //     filteredBusinessList = this.props.BusinessList.filter((business)=> business.is_disabled === 0)
        //   else
        //     filteredBusinessList = this.props.BusinessList
        // }
          
        return (
          <Fragment >
            <BusinessList BusinessList={this.props.BusinessList}
            PrevSearchVal={this.state.searchVal}
              applySortOnBusiness={this.applySortOnBusiness}
              applySearchOnBusinessName={this.applySearchOnBusinessName}
              searchrecordByBusinessName={this.searchrecordByBusinessName}
              sortFlag={this.state.sortFlag}
              sortyType={this.state.sortyType}
              setShowDisabled={this.setShowDisabled}
              showDisabled={this.state.showDisabled}
              refreshBusinessList={this.refreshBusinessList}
              cookies={this.props.cookies}
            />
          </Fragment>
        );
        break;
      case BusinessListStatus.BusinessList_GET.FAILED:
        toast.error(this.props.BusinessList_Error)
        return (
          <Fragment>
            <BusinessList BusinessList={this.props.BusinessList}
            PrevSearchVal={this.state.searchVal}
              applySortOnBusiness={this.applySortOnBusiness}
              searchrecordByBusinessName={this.searchrecordByBusinessName}
              applySearchOnBusinessName={this.applySearchOnBusinessName}
              sortFlag={this.state.sortFlag}
              sortyType={this.state.sortyType}
              setShowDisabled={this.setShowDisabled}
              showDisabled={this.state.showDisabled}
              refreshBusinessList={this.refreshBusinessList}
              cookies={this.props.cookies}
            />

          </Fragment>
        );

      case BusinessListStatus.BusinessList_GET.LOADING:
        this.state.open = true
        return (
          <>
               <BusinessList BusinessList={this.props.BusinessList}
              PrevSearchVal={this.state.searchVal}
              applySortOnBusiness={this.applySortOnBusiness}
              applySearchOnBusinessName={this.applySearchOnBusinessName}
              searchrecordByBusinessName={this.searchrecordByBusinessName}
              sortFlag={this.state.sortFlag}
              sortyType={this.state.sortyType}
              cookies={this.props.cookies}
              setShowDisabled={this.setShowDisabled}
              showDisabled={this.state.showDisabled}
              refreshBusinessList={this.refreshBusinessList}
              showLoader={true}
            />
            
          {/* <div style={{ textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center",height:"65vh", width:"100%" }}>
            <Loading/>
       
          </div> */}
          </>
        );
        break;
      default:
        return <div />
    }

  }
  render() {
   // alert(this.props.BusinessList_GET_status)
    return (
      this.getScreen(this.props.BusinessList_GET_status)
    )
  }
}

export default withRouter(BusinessListView)
