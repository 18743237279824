import React, { Component, PureComponent } from "react";
import "../UploadCoupon.css";
import SelectSearchInput from "../../Inputs/SelectSearch/SelectSearchInput";

import RetailerListContainer from "../../RetailerList/RetailerListContainer";
import ReactDOM from "react-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../FileUpload/FileUpload.css";
import { AcceptImages, AcceptVideos } from "../../../Config/Config";
import { Modal as PreviewCouponLoader } from "../../Cards/PreviewCouponLoader";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import Cropper from "react-easy-crop";
import ImgDialog from "./NewUploadImage/ImgDialog";
import getCroppedImg from "./NewUploadImage/cropImage";
import "./NewUploadImage/NewUploadFile.css";
import NewUploadFile from "./NewUploadImage/NewUploadFile";

// const AcceptImages=".gif, .jpg, .png, .jpeg, .apng, .avif, .jfif, .pjpeg, .pjp, .svg, .webp, .bmp, .ico, .cur, .tif, .tiff"
// const AcceptVideos=".mp4"
class UploadCouponForm extends PureComponent {
  constructor(props) {
    super(props);
    // const banner = props.UpdateBannerData || props.DuplicateBannerData;
    this.state = {
      editMode: true,
      searchName: "",
      business_name: "",
      searchNameFlag: false,
      searchNameErrorMsg: "Field Required",
      selectedImage: "",
      ImageErrorMsg: "",
      fileFlag: false,
      imageCropErrorMsg: "",
      fileCropingFlag: true,
      notes: "",
      fileimg: "",
      RetailerList: [],
      src: null,
      showCropImage: false,
      showUploadButton: false,
      imageData: "",
      showFileUpload: false,
      buttonDisable: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 3,
      croppedAreaPixels: null,
      croppedImage: null,
      videoFlag: false,
      minZoom: 0.4,
    };
    if (props.BusinessProfileData) {
      this.state.searchName = props.BusinessProfileData.business_id;
      this.state.business_name = props.BusinessProfileData.business_name;
      // alert(JSON.stringify(props.BusinessProfileData.business_name))
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // todo: searchNameHandler onChange function ..........................
  searchNameHandler = (e) => {
    // alert(e)
    // console.log("this e check", e);
    // const businessObj = this.state.RetailerList.find(element => {
    //   return element.value == e;
    // });
    // // alert(JSON.stringify(businessObj))
    this.setState({
      searchName: e,
      searchNameFlag: false,
    });
  };

  setRetailerList = (data) => {
    this.setState({
      RetailerList: data,
    });
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    console.log("jdkjdskxjcdsx", zoom);
    this.setState({ zoom });
  };

  showCroppedImage = async () => {
    // alert("okay")
    const croppedImage = await getCroppedImg(
      this.state.src,
      this.state.croppedAreaPixels
    );
    console.log("croppedImage", croppedImage);
    // alert(buttonDisable)

    // this.props.buttonDisableHandler(true);

    this.setState({
      croppedImage: croppedImage,
      showCropImage: true,
      buttonDisable: true
    });
  };

  handleClose = () => {
    this.setState({ croppedImage: null });
  };
  showResetCropImage = () => {
    this.setState({
      showCropImage: false,
    });
  };

  onSelectFile = (e) => {
    const maxAllowedHeight = 228;
    const maxAllowedWidth = 339;
    if (e.target.files && e.target.files.length > 0) {
      console.log("img data", e.target.files);
      var re = /(?:\.([^.]+))?$/;
      let ext = "." + re.exec(e.target.files[0].name)[1];
      if (ext) {
        let videoFlag = false;
        let buttonDisable = this.state.buttonDisable;
        let fileFlag = false;
        // let buttonDisable = this.state.buttonDisable
        // if(  ImageSizeLimit={this.props.ImageSizeLimit}
        // VideoSizeLimit = {this.props.VideoSizeLimit})
        // alert(ext)
        // alert(JSON.stringify(AcceptVideos.split(", ")))
        if (AcceptVideos.split(", ").includes(ext)) {
          buttonDisable = true;
          videoFlag = true;
          if (
            e.target.files[0].size / 1024 / 1024 >
            this.props.VideoSizeLimit
          ) {
            this.state.ImageErrorMsg =
              "Video size should be less than " +
              this.props.VideoSizeLimit +
              " MB";
            buttonDisable = false;
            fileFlag = true;
          }
        } else {
          if (
            e.target.files[0].size / 1024 / 1024 >
            this.props.ImageSizeLimit
          ) {
            this.state.ImageErrorMsg =
              "Image size should be less than " +
              this.props.ImageSizeLimit +
              " MB";
            fileFlag = true;
            buttonDisable = false;
          }

          var reader = new FileReader();
          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = (e) => {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e.target.result;
            //Validate the File Height and Width.
            image.onload = () => {
              let widthPropottion
              let heightPropottion
              var width = image.naturalWidth;
              var height = image.naturalHeight;
              console.log("imgWidthdkdkk: ", width);
              console.log("imgHeight: ", height);

              if (width < maxAllowedWidth || height < maxAllowedHeight) {
                // alert("Height and Width must not exceed 228px.");

                this.state.ImageErrorMsg =
                  "Width and height must not be less than  " +
                  maxAllowedWidth +
                  " by " +
                  maxAllowedHeight +
                  ".";

                this.setState(
                  {
                    fileFlag: true,
                    buttonDisable: false,
                  },
                  () => {}
                );
              }
              else{
                // widthPropottion= width/maxAllowedWidth
                // heightPropottion= height/maxAllowedHeight


                widthPropottion= maxAllowedWidth/width
                heightPropottion= maxAllowedHeight/height

                let minScaleValue=Math.max(widthPropottion, heightPropottion)
                console.log('heightPropottion: ', widthPropottion);
              console.log('heightPropottion: ', heightPropottion);
              console.log("salevalue here", minScaleValue)

                this.setState({
                    minZoom: minScaleValue
                })

                // buttonDisable= true
              }
            };
          };
        }

        // alert(e.target.files[0])
        

        this.setState({
          imageData: e.target.files[0],
          editMode: true,
          fileFlag: fileFlag,
          videoFlag: videoFlag,
          buttonDisable: buttonDisable,
          src: undefined,
        });
        if (!videoFlag) {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            // alert(reader)
            // console.log("aksjndajksd: ",e.target.files )
            this.setState({ src: reader.result });
          });
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    }
  };

  // todo: SaveHandler onClick Function ...................

  doneHandler = async () => {

    // alert(JSON.stringify(this.state.imageData))
    // console.log("this state", this.state)

    // return;

    if (this.state.imageData === "") {
      // alert('test')
      this.setState({
        fileFlag: true,
        ImageErrorMsg: "Please upload an image",
      });
      return;
    } else if (!this.state.showCropImage && !this.state.videoFlag) {
      // alert('test')
      this.setState({
        // fileCropingFlag: true,
        imageCropErrorMsg: "Please crop an image",
      });
      return;
    } else if (this.state.searchName === "") {
      this.setState({ searchNameFlag: true });
      return;
    }
    if (this.state.videoFlag) {
      // console.log("video: ", this.state.selectedImage)


      let data = {
        business_id: parseInt(this.state.searchName),
        selectedImage: this.state.imageData,
        videoFlag: true,
      };

      console.log("data", data)
      // return

      // alert(JSON.stringify(data))

      this.props.saveUploadCouponImage(data);
    } else
      await fetch(this.state.croppedImage)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then(
          (blob) => {
            var reader = new FileReader();
            console.log("file reader", reader);
            let base64data;
            let data;

            reader.readAsDataURL(blob);

            reader.onloadend = () => {
              base64data = reader.result;

              // alert(base64data)

              const name = this.state.imageData.name;
              const lastDot = name.lastIndexOf(".");

              const fileName = name.substring(0, lastDot);
              const ext = name.substring(lastDot + 1);

              data = {
                business_id: parseInt(this.state.searchName),
                selectedImage: { name: fileName },
                fileimg: base64data,
                img_name: fileName,
                img_ext: ext,
                isBase64: true,
              };
              
              // alert(JSON.stringify(data))

              this.props.saveUploadCouponImage(data);
            };
            // alert(this.props.saveUploadCouponImage)
          }

          // console.log("data", data)
          // console.log("data for upload", data)
        );
  };

  render() {
    const { classes } = this.props;
    const {
      searchName,
      searchNameFlag,
      searchNameErrorMsg,
      crop,
      croppedImageUrl,
      src,
      fileFlag,
      fileCropingFlag,
      minZoom,
      showCropImage,
    } = this.state;
    // alert(JSON.stringify(this.state.RetailerList))

    // alert(fileFlag)

    console.log("imageData", this.state.imageData)

    return (
      <div className="upload-banner-box admin-new psw">
        {this.props.loading ? <PreviewCouponLoader /> : null}
        <div className="upload-banner-box2">
          {/* <NewUploadFile/> */}
          <div className="upload-banner-select">
            <>
              <div className="input-file-new">
                <div className="reeoe-handle box-upload">
                  <div
                    style={{
                      display: this.state.showFileUpload ? "none" : "block",
                    }}
                    className="pe-file-container-upload"
                  >
                    <input
                      type="file"
                      id="pe-inp-btn1"
                      className="pe-inp-btn-upload"
                      onChange={this.onSelectFile}
                      accept={AcceptImages + ", " + AcceptVideos}
                      flag={fileFlag ? fileFlag : undefined}
                    />
                    <label
                      htmlFor="pe-inp-btn1"
                      className="pe-upload-btn-upload pe-upload-btn"
                    >
                      <i className="material-icons">photo_camera</i>
                      <div className="banner-up camera-upload-hover">
                        Upload Image
                      </div>
                    </label>
                  </div>
                </div>
                {fileFlag ? (
                  <p className="error-upload">{this.state.ImageErrorMsg}</p>
                ) : (
                  <></>
                )}

                <div className="upload-instruction">
                  <p className="upload-instruction-text">
                    Note: When the video is displayed it will be resized to fit
                    in a box 228 pixels in height and 339 pixels in width. So as
                    not to distort the video, the video is resized
                    proportionally to have a height of 228 pixels. The maximum
                    image upload size is {this.props.ImageSizeLimit}MB and video
                    upload size {this.props.VideoSizeLimit}MB.
                  </p>
                </div>
              </div>
              {fileFlag ? (
                <></>
              ) : (
                <>
                  <div className="file_croping_box">
                    {src && !this.state.showCropImage && (
                      <div className="">
                        <div className="box_image">
                          <div className={classes.cropContainer}>
                            <Cropper
                              maxZoom={1}
                              minZoom={minZoom}
                              image={this.state.src}
                              crop={this.state.crop}
                              zoom={this.state.zoom}
                              // aspect={this.state.aspect}
                              cropSize={{ width: 339, height: 228 }}
                              restrictPosition={true}
                              onCropChange={this.onCropChange}
                              onCropComplete={this.onCropComplete}
                              onZoomChange={this.onZoomChange}
                            />
                          </div>
                        </div>
                        <div className="controls_slider">
                          <div className="slider_contrl">
                            <Slider
                              value={this.state.zoom}
                              min={minZoom}
                              max={1}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => this.onZoomChange(zoom)}
                              classes={{ container: "slider" }}
                            />
                          </div>
                        </div>
                        <div className="button_new_crop">
                          <button
                            onClick={this.showCroppedImage}
                            variant="contained"
                            color="primary"
                            className="upload-button"
                          >
                            <span className="material-icons">crop</span> Crop
                            Image
                          </button>
                        </div>
                        <div className="croping-im-error">
                          <p className="error-upload">
                            {this.state.imageCropErrorMsg}
                          </p>
                        </div>
                      </div>
                    )}
                    {this.state.showCropImage && (
                      <div className="croping-image-offer">
                        <ImgDialog
                          img={this.state.croppedImage}
                          onClose={this.handleClose}
                        />

                        <div className="btin_reset_new">
                          <button
                            onClick={this.showResetCropImage}
                            variant="contained"
                            color="primary"
                            className="again-crop-image"
                          >
                            <span className="material-icons">restore</span>{" "}
                            Reset Cropped Image
                          </button>
                        </div>
                      </div>
                    )}
                    {this.state.videoFlag && (
                      <div className="video_scren">
                      <div className="croping-video-offer">
                        <video controls>
                          <source
                            src={URL.createObjectURL(this.state.imageData)}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>

            <div className="upload-banner-input-box">
              <div className="upload-form-input-b">
                <div className="upload-form-label">Partner Name</div>
                <div className="upload-form-input">
                  
                  <div>
                    <SelectSearchInput
                      inputValue={searchName}
                      placeholder={
                        this.props.BusinessProfileData
                          ? this.state.business_name
                          : "Filter By Partners"
                      }
                      flag={searchNameFlag}
                      errorMsg={searchNameErrorMsg}
                      onChange={this.searchNameHandler}
                      disabled={this.props.BusinessProfileData ? true : false}
                      data={this.state.RetailerList}
                    />
                    <RetailerListContainer
                      setRetailerList={this.setRetailerList}
                    />
                  </div>
               
                </div>
              </div>

              <div className="upload-form-button-box">
                <div className="back-btn">
                  <a
                    id="backLink"
                    href=""
                    onClick={this.props.Go_Back}
                    className="back-link"
                  >
                    Back
                  </a>
                </div>
                <div className="next-btn">
                  <button
                    //  className="upload-button"
                    //   className="upload-button-disable"
                    id="uploadButton"
                    className={
                      !this.state.buttonDisable
                        ? "upload-button-disable"
                        : "upload-button"
                    }
                    onClick={this.doneHandler}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
};

export default withStyles(styles)(UploadCouponForm);
