import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "./GetFaceValues.css";

import SimpleInput from "../../Inputs/SimpleInput";

class GetFaceValues extends Component {
  constructor(props) {
    super(props);
    this.state = {

      inputValueData: []
    };
  }

  UNSAFE_componentWillMount() {
    //this.state.MaxMonthlyIssue = this.props.GetFaceValues
    if(this.props.MaxMonthlyIssue.length == 0)
    this.props.setQuotasData(this.props.GetFaceValues)
  }
  // todo: Face Value onChange Function ...................

  yearHandler = e => {
    if (this.state.year !== "" || this.state.year !== "selectYear") {
      this.setState({ yearFlag: false });
    }
    this.setState({ year: e.target.value });
  };
  // todo: couponLifeTimeHandler onChange Function ...................

  couponLifeTimeHandler = e => {
    //  alert(e.target.value)
    const reg = /^[0-9]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ couponLifeTimeFlag: false });
    } else {
      this.setState({
        couponLifeTimeFlag: true,
        couponLifeTimeErrorMsg: "Use only Numeric"
      });
    }
    this.setState({ couponLifeTime: e.target.value });
  };

  // todo: inputHandler onChange Function ...................

  inputHandler = (newMaxMonthlyIssue, e) => {
    // console.log("e", e);

    const MaxMonthlyIssue = [...this.props.MaxMonthlyIssue];
    // alert(JSON.stringify(newMaxMonthlyIssue))
    const index = MaxMonthlyIssue.indexOf(newMaxMonthlyIssue);
    // alert(index)
    const reg = /^[0-9]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg && e.target.value.trim() !=="") {

      MaxMonthlyIssue[index] = { ...newMaxMonthlyIssue };
      MaxMonthlyIssue[index].coupon_max_quota = e.target.value;
      MaxMonthlyIssue[index].coupon_max_quota_err = "";
      MaxMonthlyIssue[index].coupon_max_quota_errFlag = false;
      this.props.setQuotasData(MaxMonthlyIssue)

    }else{
      MaxMonthlyIssue[index] = { ...newMaxMonthlyIssue };
      MaxMonthlyIssue[index].coupon_max_quota = e.target.value;
      MaxMonthlyIssue[index].coupon_max_quota_err = e.target.value.trim() !==""? "Use only Numeric":"";
      MaxMonthlyIssue[index].coupon_max_quota_errFlag = true;
      this.props.setQuotasData(MaxMonthlyIssue)
      
    }
    
  //   const regex = /^[0]+\.?[0]*$/;

  //  const RegexValue = regex.test(e.target.value);
  //   if(RegexValue){

  //    MaxMonthlyIssue[index] = { ...newMaxMonthlyIssue };
  //     MaxMonthlyIssue[index].coupon_max_quota = e.target.value;
  //     MaxMonthlyIssue[index].coupon_max_quota_err = "";
  //     MaxMonthlyIssue[index].coupon_max_quota_errFlag = false;
  //     this.props.setQuotasData(MaxMonthlyIssue)
  //   }


    // alert(JSON.stringify(MaxMonthlyIssue))
    // this.props.setQuotasData({MaxMonthlyIssue:MaxMonthlyIssue})



  };

  // todo: homeHandler onClick function ..........................

  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin"
    });
  };
  // todo: homeHandler onClick function ..........................

  businessListHandler = () => {
    this.props.history.push({
      pathname: "/business_list"
    });
  };
  // todo: homeHandler onClick function ..........................

  businessProfileHandler = () => {
    this.props.history.push({
      pathname: "/business_profile"
    });
  };
  // todo: homeHandler onClick function ..........................

  previousYearHandler = () => {
    alert("previousYearHandler Works");
 
  };

  render() {
    const {GetFaceValues,MaxMonthlyIssue } = this.props
    // alert(JSON.stringify(MaxMonthlyIssue))
    console.log("props data", this.props)
    console.log("GetFaceValues", GetFaceValues)
    console.log("MaxMonthlyIssue", MaxMonthlyIssue)
    
    return (
      <Fragment>
      <div className="fc-box">
        <div className="fc-header">
          <div className="fc-col-1 bold">
          FaceValue
          </div>
          <div className="fc-col-1">
          Max Monthly Limit
          </div>
        </div>
        <div className="fc-body-sec">
          <div className="fc-body-row fc-1">
          {GetFaceValues.map((res, index) => (
              <div className="fc-body sect-bold" key={index}>
                {"$" + res.face_value}
              </div>
            ))}
          </div>
          <div className="fc-body-row fc-1">
          {MaxMonthlyIssue.map((res, index) => (
              <div className="fc-body right" key={index}>
                <SimpleInput
                  id="maxMonthlyIssue"
                  inputValue={res.coupon_max_quota}
                  name={`value${index}`}
                  onChange={e => this.inputHandler(res, e)}
                  errorMsg={res.coupon_max_quota_err}
                  flag={res.coupon_max_quota_errFlag}
                  
                />
              </div>
            ))} 
            </div>
        </div>
      </div>
      {/* <table className="gfv_table">
        <thead className="gfv_thead">
          <tr className="gfv_thead_tr">
            <th className="gfv_thead_th">FaceValue</th>
            {this.props.GetFaceValues.map((res, index) => (
              <th className="gfv_thead_th" key={index}>
                {"$" + res.face_value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="gfv_tbody">
          <tr className="gfv_tbody_tr">
            <td className="gfv_tbody_td1">Max Monthly Limit</td>
            {this.props.MaxMonthlyIssue.map((res, index) => (
              <td className="gfv_tbody_td2" key={index}>
                <SimpleInput
                  inputValue={res.coupon_max_quota}
                  name={`value${index}`}
                  onChange={e => this.inputHandler(res, e)}
                  errorMsg={res.coupon_max_quota_err}
                  flag={res.coupon_max_quota_errFlag}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table> */}
       
      </Fragment>
    );
  }
}

export default withRouter(GetFaceValues);
