
//******************Exchange Rate Server Calls******************;

import { BusinessContractActions } from './BusinessContractConstants';
import store from '../../Store/store'
import { ROOT_URL, getCookie } from '../../Config/Config';
import {failure_messages} from "Messages"


export const BusinessContractServer = {
  getBusinessContract: getBusinessContract,
  // getBusinessContractList:getBusinessContractList,
  createBusinessContract: createBusinessContract,
  updateBusinessContract: updateBusinessContract
};

//******************Insert BusinessContractLists******************;

function createBusinessContract(data) {
  // alert(data)
  const request = fetch(ROOT_URL + '/api/business/createBusinessContract', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    //  alert(JSON.stringify(response))
    response.json().then(response => {
      if (response.status == "200") {
      //  alert(JSON.stringify(response.data))
       response.data.coupon_display_behavior=data.coupon_display_behavior
        store.dispatch({ type: BusinessContractActions.BusinessContract_Post.SUCCESS, payload: response.data,  message:response.message });
        return;
      } else {
        store.dispatch({ type: BusinessContractActions.BusinessContract_Post.FAILED, error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: BusinessContractActions.BusinessContract_Post.FAILED, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
    store.dispatch({ type: BusinessContractActions.BusinessContract_Post.FAILED, error:failure_messages.unExpectedError });
  })
  return { type: BusinessContractActions.BusinessContract_Post.LOADING }

};

//******************Get BusinessContractLists******************;

function getBusinessContract(data) {
  // alert(data)
  data.setLoad(true)
  const request = fetch(ROOT_URL + '/api/business/getBusinessContractsById/', {
    method: 'POST',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
     body: JSON.stringify({Id:data.business_id})
  }).then((response) => {
    response.json().then(response => {
      data.setLoad(false)
      if (response.status == "200") {
        if (response.data && response.data.length > 0){

          console.log("response", response.data)

          store.dispatch({ type: BusinessContractActions.BusinessContract_Get.SUCCESS, payload: response.data[0] });
        }
        else
          store.dispatch({ type: BusinessContractActions.BusinessContract_Post.NEW });

        return;
      } else {
        //  alert('failed')
        store.dispatch({ type: BusinessContractActions.BusinessContract_Get.FAILED, error:response.error });
        return;
      }
    }).catch((error) => {
      data.setLoad(false)
      store.dispatch({ type: BusinessContractActions.BusinessContract_Get.FAILED,  error:failure_messages.unExpectedError});
    })

  }).catch((error) => {
    data.setLoad(false)
    store.dispatch({ type: BusinessContractActions.BusinessContract_Get.FAILED,  error:failure_messages.unExpectedError});
  })
  return { type: BusinessContractActions.BusinessContract_Post.LOADING }

};

//******************Update BusinessContractList******************;

function updateBusinessContract(data, Id) {
   //alert(Id)
  //alert(JSON.stringify(data))
  const request = fetch(ROOT_URL + '/api/business/updateBusinessContractById/' + Id, {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
     // alert(JSON.stringify(response))
      if (response.status == "200") {

        console.log("update response", response)
        
        store.dispatch({ type: BusinessContractActions.BusinessContract_Post.SUCCESS, payload: { ...data, coupon_contract_id: Id },  message:response.message });
        return;
      } else {
        store.dispatch({ type: BusinessContractActions.BusinessContract_Post.FAILED, error:response.error});
        return;
      }
    }).catch((error) => {
    // alert(error)
    store.dispatch({ type: BusinessContractActions.BusinessContract_Post.FAILED, error:failure_messages.unExpectedError });
  })

  }).catch((error) => {
    store.dispatch({ type: BusinessContractActions.BusinessContract_Post.FAILED, error:failure_messages.unExpectedError });
  })
  return { type: BusinessContractActions.BusinessContract_Post.LOADING }

};