export const GetQuotasContractDetailsActions= {
	
	GetQuotasContractDetails_GET :{
		NEW:"GetQuotasContractDetails_GET_NEW",
		SUCCESS:"GetQuotasContractDetails_GET_SUCCESS",
		FAILED:"GetQuotasContractDetails_GET_FALIURE",
		LOADING:"GetQuotasContractDetails_GET_LOADING",
		UPDATE:"GetQuotasContractDetails_GET_UPDATE",
	},
	
}


export const GetQuotasContractDetailsStatus ={

	GetQuotasContractDetails_GET :{
		NEW:"GetQuotasContractDetails_GET_NEW",
		SUCCESS:"GetQuotasContractDetails_GET_SUCCESS",
		FAILED:"GetQuotasContractDetails_GET_FALIURE",
		LOADING:"GetQuotasContractDetails_GET_LOADING",
		UPDATE:"GetQuotasContractDetails_GET_UPDATE"
	}

}
