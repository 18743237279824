export const EndCouponActions= {
	
	EndCoupon_Post :{
		NEW:"EndCoupon_Post_NEW",
		SUCCESS:"EndCoupon_Post_SUCCESS",
		FAILED:"EndCoupon_Post_FALIURE",
		LOADING:"EndCoupon_Post_LOADING",
	}
}


export const EndCouponStatus ={

	EndCoupon_Post :{
		NEW:"EndCoupon_Post_NEW",
		SUCCESS:"EndCoupon_Post_SUCCESS",
		FAILED:"EndCoupon_Post_FALIURE",
		LOADING:"EndCoupon_Post_LOADING",
		
	}

}
