import { GetDisplayTypesStatus, GetDisplayTypesActions } from './GetDisplayTypesConstants';

const GetDisplayTypes_initialState = {
    GetDisplayTypes_Status: GetDisplayTypesStatus.GetDisplayTypes_GET.NEW,
    GetDisplayTypes_Error: '',
    DisplayTypes: [],
}
export default  function  (state = GetDisplayTypes_initialState, action) {
    switch (action.type) {
        case GetDisplayTypesActions.GetDisplayTypes_GET.LOADING:
            return { ...state, GetDisplayTypes_Status: GetDisplayTypesStatus.GetDisplayTypes_GET.LOADING }
            case GetDisplayTypesActions.GetDisplayTypes_GET.NEW:
                return { ...state, GetDisplayTypes_Status: GetDisplayTypesStatus.GetDisplayTypes_GET.NEW }
    
        case GetDisplayTypesActions.GetDisplayTypes_GET.FAILED:
            return { ...state,  GetDisplayTypes_Status: GetDisplayTypesStatus.GetDisplayTypes_GET.FAILED, GetDisplayTypes_Error:action.error}
        case GetDisplayTypesActions.GetDisplayTypes_GET.SUCCESS:
          console.log(action.payload)
          return { ...state,  GetDisplayTypes_Status: GetDisplayTypesStatus.GetDisplayTypes_GET.SUCCESS, DisplayTypes:action.payload}
        
        default:
            return { ...state,
                GetDisplayTypes: [],
                QuotaYears:[]
            }
    }
}
