export const ScheduledWeekListActions= {
	
	ScheduledWeekList_GET :{
		NEW:"ScheduledWeekList_GET_NEW",
		SUCCESS:"ScheduledWeekList_GET_SUCCESS",
		FAILED:"ScheduledWeekList_GET_FALIURE",
		LOADING:"ScheduledWeekList_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const ScheduledWeekListStatus ={

	ScheduledWeekList_GET :{
		NEW:"ScheduledWeekList_GET_NEW",
		SUCCESS:"ScheduledWeekList_GET_SUCCESS",
		FAILED:"ScheduledWeekList_GET_FALIURE",
		LOADING:"ScheduledWeekList_GET_LOADING",
	}

}
