import React from 'react'
import './LegendDropDownInput.css'
function LegendDropDownInput(props) {
    const { placeholder, containerStyle } = props
    return (
        <fieldset className="legend_fieldset" style={containerStyle}>
            <legend className="legend_heading">{placeholder}</legend>
            <div className="legend_drop_down_input_container">
                <select className="legend_drop_down_input">
                    <option value="volvo"></option>
                    <option value="saab">Saab</option>
                    <option value="opel">Opel</option>
                    <option value="audi">Audi</option>
                </select>
                <i className="material-icons legend_drop_down_icon">expand_more</i>
            </div >
        </fieldset>
    )
}

export default LegendDropDownInput
