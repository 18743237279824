import { connect } from "react-redux";
import DisableBusinessView from "./DisableBusiness_View";
import {DisableBusinessServer} from "./server"
import {DisableBusinessStatus} from './DisableBusinessconstants'
//import React, { useState } from 'react';

const mapStateToProps = state => {
    
    return {
        DisableBusiness_Update_status:state.DisableBusinessReducer.DisableBusiness_Status,
        businessId:state.DisableBusinessReducer.businessId
      //  IsDisabled:state.DisableBusinessReducer.IsDisabled
    };
   
    
};
const mapDispatchToProps = dispatch  => {
    
    return {
        
        updateDisableBusiness: (data, id) => {
    
            dispatch( DisableBusinessServer.updateDisableBusiness(data, id))
          // dispatch( DisableBusinessServer.getDisableBusiness() )
        },
        
    }
};
const DisableBusinessContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DisableBusinessView)

export default DisableBusinessContainer;