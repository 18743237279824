export const UserRoleListActions= {
	
	UserRoleList_GET :{
		NEW:"UserRoleList_GET_NEW",
		SUCCESS:"UserRoleList_GET_SUCCESS",
		FAILED:"UserRoleList_GET_FALIURE",
		LOADING:"UserRoleList_GET_LOADING",
		UPDATE:"DisableBusiness_Update_SUCCESS",
	},
	
}


export const UserRoleListStatus ={

	UserRoleList_GET :{
		NEW:"UserRoleList_GET_NEW",
		SUCCESS:"UserRoleList_GET_SUCCESS",
		FAILED:"UserRoleList_GET_FALIURE",
		LOADING:"UserRoleList_GET_LOADING",
	}

}
