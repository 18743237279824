import {
  ApplicantsStatusFieldStatus,
  ApplicantsStatusFieldActions
} from "./ApplicantsStatusFieldConstants";

const ApplicantsStatusField_initialState = {
  ApplicantsStatusField_Status:
  ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.NEW,
  Error: "",
  ApplicantsStatusFieldDetails: []
};
export default function(state = ApplicantsStatusField_initialState, action) {
  switch (action.type) {
    case ApplicantsStatusFieldActions.ApplicantsStatusField_Get.LOADING:
      return {
        ...state,
        ApplicantsStatusField_Status:
        ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.LOADING
      };

    case ApplicantsStatusFieldActions.ApplicantsStatusField_Get.SUCCESS:
      return {
        ...state,
        ApplicantsStatusField_Status:
        ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.SUCCESS,
        ApplicantsStatusFieldDetails: action.payload
      };
    case ApplicantsStatusFieldActions.ApplicantsStatusField_Get.FAILED:
      return {
        ...state,
        ApplicantsStatusField_Status:
        ApplicantsStatusFieldStatus.ApplicantsStatusField_Get.FAILED,
        ApplicantsStatusFieldDetails: [],
        Error: action.error
      };

    default:
      return {
        ...state
      };
  }
}
