import { BusinessCouponQuotasStatus, BusinessCouponQuotasActions } from './BusinessCouponQuotasconstants';

const BusinessCouponQuotas_initialState = {
    BusinessCouponQuotas_Status: BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.NEW,
    BusinessCouponQuotas_Message: '',
    BusinessCouponQuotasDetails: [],
    Goto_ManageCoupons:false,
    Goto_OverridePricing:false
}
export default function (state = BusinessCouponQuotas_initialState, action) {
    switch (action.type) {
        case BusinessCouponQuotasActions.BusinessCouponQuotas_Post.LOADING:
        
            return { ...state, BusinessCouponQuotas_Status: BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.LOADING }
      
            case BusinessCouponQuotasActions.BusinessCouponQuotas_Post.NEW:
            return { ...state, BusinessCouponQuotas_Status: BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.NEW, BusinessCouponQuotasDetails:[] }
            case BusinessCouponQuotasActions.BusinessCouponQuotas_Post.Go_To_ManageCoupons:
    
                return { ...state,  Goto_ManageCoupons: action.payload.ManageCouponFlag, Goto_OverridePricing:action.payload.OverridePricingFlag }
        case BusinessCouponQuotasActions.BusinessCouponQuotas_Get.SUCCESS:
            
            return { ...state, BusinessCouponQuotas_Status: BusinessCouponQuotasStatus.BusinessCouponQuotas_Get.SUCCESS, BusinessCouponQuotasDetails: action.payload }
        case BusinessCouponQuotasActions.BusinessCouponQuotas_Get.FAILED:
            return { ...state, BusinessCouponQuotas_Status: BusinessCouponQuotasStatus.BusinessCouponQuotas_Get.FAILED, BusinessCouponQuotas_Message: action.error }

        case BusinessCouponQuotasActions.BusinessCouponQuotas_Post.FAILED:
            return { ...state, BusinessCouponQuotas_Status: BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.FAILED, BusinessCouponQuotas_Message: action.error }
        case BusinessCouponQuotasActions.BusinessCouponQuotas_Post.SUCCESS:
            return { ...state, BusinessCouponQuotas_Status: BusinessCouponQuotasStatus.BusinessCouponQuotas_Post.SUCCESS, BusinessCouponQuotas_Message: action.payload.message }

        default:
            return { ...state}
    }
}
