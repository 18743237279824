
//******************Exchange Rate Server Calls******************;

import { CouponsInventoryActions } from './CouponsInventoryConstants';
import store from '../../../Store/store'
import {ROOT_URL,getCookie} from '../../../Config/Config';
import {failure_messages} from "Messages"


export const CouponsInventoryServer = {
  //  CouponsInventory:CouponsInventory,
  getCouponsInventory: getCouponsInventory,
  //  updateCouponsInventory:updateCouponsInventory
};

//****************** CouponsInventory with coupons ******************;

//****************** This Api fetch the Quotas of bussiness Id along with total coupons already generated; grouped by all the years of the contract.

function getCouponsInventory(data) {

  const request = fetch(ROOT_URL + '/api/coupons/getCouponsInventory', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    response.json().then(response => {
      if (response.status == "200") {
        console.log('Business quota details', response)
      //  alert( JSON.stringify(response.data[0].media_location))
        store.dispatch({ type: CouponsInventoryActions.CouponsInventory_GET.SUCCESS, payload: response.data,ref_data:response.ref_data });
        return;
      } else {
        store.dispatch({ type: CouponsInventoryActions.CouponsInventory_GET.FAILED, error:response.error });
        return;
      }
    }).catch((error) => {
      store.dispatch({ type: CouponsInventoryActions.CouponsInventory_GET.FAILED,error:failure_messages.quotasDetailsError });
    })
  }).catch((error) => {
    store.dispatch({ type: CouponsInventoryActions.CouponsInventory_GET.FAILED,error:failure_messages.quotasDetailsError });
  })
  return { type: CouponsInventoryActions.CouponsInventory_GET.LOADING }

};
