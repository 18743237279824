
//******************Exchange Rate Server Calls******************;

import { EndCouponActions } from './EndCouponConstants';
import store from '../../../Store/store'
import {  ROOT_URL,getCookie } from '../../../Config/Config';
import {failure_messages} from 'Messages'

export const EndCouponServer = {
  //  getEndCoupon:getEndCoupon,
  handleEndCoupon: handleEndCoupon
  // updateEndCoupon: updateEndCoupon
};

//******************Insert EndCoupons******************;

function handleEndCoupon(data) {
  //alert('save')
 
  const request = fetch(ROOT_URL + '/api/coupon/HandleCouponBatchUpdate', {
    method: 'Post',
    mode: 'cors',
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    // alert(JSON.stringify(response))
    response.json().then(responseJson => {

      if (responseJson.status == "200") {
        console.log(responseJson)
        // alert(JSON.stringify(data.batch_status))
        store.dispatch({ type: EndCouponActions.EndCoupon_Post.SUCCESS, payload: data, message:responseJson.message });
      
      } else {

        store.dispatch({ type: EndCouponActions.EndCoupon_Post.FAILED,  payload: data, error:responseJson.error});

      }
    }).catch((error) => {
        console.log(error)
      store.dispatch({ type: EndCouponActions.EndCoupon_Post.FAILED,payload: data, error:failure_messages.unExpectedError });
    })
  }).catch((error) => {
      console.log(error)
    store.dispatch({ type: EndCouponActions.EndCoupon_Post.FAILED, payload: data, error:failure_messages.unExpectedError });
  })
  return { type: EndCouponActions.EndCoupon_Post.LOADING }

};

//******************Get EndCoupons******************;


//******************Update EndCoupon******************;


// function getEndCoupon(){
//       const request=fetch(ROOT_URL+'/api/exchangeRate/getExchangeRate/', {
//           method: 'GET',
//           mode: 'cors',
//           headers: {'Content-Type':'application/json'},
//          // body: JSON.stringify(data)
//           }).then((response)=>{
//             response.json().then(response=>{
//               if(response.status == "200"){
//                 console.log(response)
//                 if(response.EndCoupon.length>0)
//              store.dispatch({type:EndCouponActions.EndCoupon_Get.SUCCESS,payload:response.EndCoupon});
//              else
//              store.dispatch({type:EndCouponActions.EndCoupon_Post.NEW});

//              return ;
//              }else
//              {
//                store.dispatch({type:EndCouponActions.EndCoupon_Get.FAILED});
//                return ;
//                }    
//             });
//            }).catch((error) => {
//             store.dispatch({type:EndCouponActions.EndCoupon_Get.FAILED});
//           })
//        return {type:EndCouponActions.EndCoupon_Post.LOADING}

//     };

// function updateEndCoupon(data, token) {
//   const request = fetch(RETAILER_API_URL + '/api/business/updateApplicationBusiness', {

//     method: 'Post',
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-access-token': token },
//     body: JSON.stringify(data)
//   }).then((response) => {
//     response.json().then(response => {
//       if (response.status == "200") {

//         store.dispatch({ type: EndCouponActions.EndCoupon_Post.SUCCESS, payload: data.data, message:response.message });
//         return;
//       } else {
//         // alert("test")
//         store.dispatch({ type: EndCouponActions.EndCoupon_Post.FAILED,  payload: data.data, error:response.error});

//       }
//     }).catch((error) => {
//         console.warn(error)
//       store.dispatch({ type: EndCouponActions.EndCoupon_Post.FAILED,payload: data.data, error:failure_messages.unExpectedError });
//     })
//   }).catch((error) => {
//       console.log(error)
//     store.dispatch({ type: EndCouponActions.EndCoupon_Post.FAILED, payload: data.data, error:failure_messages.unExpectedError });
//   })
//   return { type: EndCouponActions.EndCoupon_Post.LOADING }

// };
