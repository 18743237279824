import { connect } from "react-redux";
import Login from "./EndCoupon_View";
import {EndCouponServer} from "./server"
import {EndCouponActions} from './EndCouponConstants'
import { withCookies } from 'react-cookie';
const mapStateToProps = state => {
    console.log(state)
    return {
        EndCoupon_post_status:state.EndCouponReducer.EndCoupon_Status,
        EndCoupon_Message:state.EndCouponReducer.EndCoupon_Message,
        EndCouponDetails:state.EndCouponReducer.EndCouponDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateEndCoupon: (data, bussinessId) => {
        //     dispatch( EndCouponServer.updateEndCoupon(data, bussinessId) )
        //  },

        handleEndCoupon: (data) => {

           dispatch(EndCouponServer.handleEndCoupon(data))
        },
        resetReducerState: () => {
            dispatch({type:EndCouponActions.EndCoupon_Post.NEW})
         }
        //  updateEndCoupon: (data, token) =>{
        //     dispatch( EndCouponServer.updateEndCoupon(data, token) )
        //  }
        //  GotoContract: () => {
        //     dispatch({type:EndCouponActions.EndCoupon_Post.GOTO_CONTRACTS})
        //  },
        
    }
};
const EndCouponContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

export default withCookies(EndCouponContainer);