import { AddNewUserStatus, AddNewUserActions } from './AddNewUserConstants';

const AddNewUser_initialState = {
    AddNewUser_Status: AddNewUserStatus.AddNewUser_POST.NEW,
    AddNewUser_Message: '',
    AddNewUserDetails: [],
}
export default  function  (state = AddNewUser_initialState, action) {
    switch (action.type) {
        case AddNewUserActions.AddNewUser_POST.LOADING:
            return { ...state, AddNewUser_Status: AddNewUserStatus.AddNewUser_POST.LOADING }
            case AddNewUserActions.AddNewUser_POST.NEW:
                return { ...state, AddNewUser_Status: AddNewUserStatus.AddNewUser_POST.NEW, AddNewUserDetails: [] }
    
        case AddNewUserActions.AddNewUser_POST.FAILED:
            return { ...state,  AddNewUser_Status: AddNewUserStatus.AddNewUser_POST.FAILED, AddNewUser_Message:action.error}
        case AddNewUserActions.AddNewUser_POST.SUCCESS:
          
          return { ...state,  AddNewUser_Status: AddNewUserStatus.AddNewUser_POST.SUCCESS, AddNewUserDetails:action.payload,
            AddNewUser_Message:action.message
        }
          
        
        default:
            return { ...state,
                
            }
    }
}
