import React, { Component } from "react";
// import LabelDropDownInput from "../../Inputs/LabelDropDownInput";
import CustomLabelDropDownInput from "../../Inputs/CustomLabelDropDownInput/CustomLabelDropDownInput";
export class ApplicantsStatusField extends Component {
  render() {
    const {
      onChange,
      data,
      label,
      id="status",
      name,
      value,
      inpuValue,
      flag,
      errorMsg
    } = this.props;
    return (
      <div className="asm-drowpdown">
        <CustomLabelDropDownInput
          label={label}
          onChange={onChange}
          data={data}
          id={id}
          name={name}
          value={value}
          inputValue={inpuValue}
          flag={flag}
          errorMsg={errorMsg}
        />
            {this.props.LoadingIcon && (
            <div className='outer-loader'>
              <div className='inner-loader' style={{bottom:"13px", left
            :"490px"}}>
                <span className='input-group-addon'>
                  <i className='fa fa-refresh fa-spin bc'></i>
                </span>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default ApplicantsStatusField;
