import { connect } from "react-redux";
import UploadCouponImageView from "./UploadCouponImage_View";
import {UploadCouponImageServer} from "./server"
import {UploadCouponImageActions} from './UploadCouponImageConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        UploadCouponImage_post_status:state.UploadCouponImageReducer.UploadCouponImage_Status,
        UploadCouponImage_Message:state.UploadCouponImageReducer.UploadCouponImage_Message,
        ImageSizeLimit:state.CouponImageListReducer.ImageSizeLimit,
        VideoSizeLimit:state.CouponImageListReducer.VideoSizeLimit,
        UploadCouponImage:state.UploadCouponImageReducer.UploadCouponImageDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // updateRetailerBanner: (data, bannerId) => {
        //     dispatch( UploadCouponImageServer.updateRetailerBanner(data, bannerId) )
        //  },

        saveUploadCouponImage: (data) => {

           dispatch( UploadCouponImageServer.saveUploadCouponImage(data) )
        },
        
        resetReducerState: () =>{
            dispatch({type:UploadCouponImageActions.UploadCouponImage_Post.NEW})
        }
    }
};
const UploadCouponImageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadCouponImageView)

export default UploadCouponImageContainer;