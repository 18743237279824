//******************Exchange Rate Server Calls******************;

import { ManageMessagingActions } from "./ManageMessagingConstants";
import store from "../../Store/store";
import { ROOT_URL,getCookie } from "../../Config/Config";
import { failure_messages } from "Messages";

export const ManageMessagingServer = {
  sendNotificationsToRetailers: sendNotificationsToRetailers
};

//******************Get ManageMessagings******************;

function sendNotificationsToRetailers(data) {
  const res = fetch(ROOT_URL + "/api/messaging/sendNotificationsToRetailers", {
    method: "POST",
    mode: "cors",
    headers: { 
      'authorization':getCookie("admin_token_partner"),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      res
        .json()
        .then(response => {
          console.log("ManageMessaging:", response);
          if (response.status == "200") {
            store.dispatch({
              type:
                ManageMessagingActions.ManageMessaging_Get
                  .SUCCESS,
              message: response.message
            });
          } else {
            store.dispatch({
              type:
                ManageMessagingActions.ManageMessaging_Get
                  .FAILED,
                  error: response.error
            });
          }
        })
        .catch(err => {
          console.log("ManageMessagingError:", err);
          store.dispatch({
            type:
              ManageMessagingActions.ManageMessaging_Get
                .FAILED,
                error: failure_messages.unExpectedError
          });
        });
    })
    .catch(err => {
      console.log("ManageMessagingError:", err);
      store.dispatch({
        type:
          ManageMessagingActions.ManageMessaging_Get.FAILED,
          error: failure_messages.unExpectedError
      });
    });

  return {
    type: ManageMessagingActions.ManageMessaging_Get.LOADING
  };
}
