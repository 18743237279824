import React, { Component, Fragment } from "react";
// import "./GetCoupon_Types.css";
import LabelDropDownInput from "../../Inputs/LabelDropDownInput";
export class GetCoupon_Types extends Component {
  constructor(props) {
    super(props);

  }

  downloadHandler = () => {
    //  alert("downloadHandler works");
  };

  render() {
    let dataTable =[]
    if(this.props.dataTable)
    dataTable= this.props.dataTable;

    return (
      <Fragment>
        <LabelDropDownInput
                    data={dataTable}
                      type="text"
                      name="CouponType"
                      label="Offer Class:"
                      star="*"
                      id="offerClass"
                      inputValue={this.props.CouponType}
                      flag={this.props.CouponTypeFlag}
                      errorMsg={this.props.CouponTypeErrorMsg}
                      containerStyle={{ marginTop: "20px" }}
                      onChange={this.props.onChange}
                      disabled={this.props.disabled}
                      LoadingIcon={this.props.LoadingIcon}
          loaderStyle={{ left: '15px', top: '8px' }}
        />
      </Fragment>
    );
  }
}

export default GetCoupon_Types;
