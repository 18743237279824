export const UploadBannerActions= {
	
	UploadBanner_Post :{
		NEW:"UploadBanner_Post_NEW",
		SUCCESS:"UploadBanner_Post_SUCCESS",
		FAILED:"UploadBanner_Post_FALIURE",
		LOADING:"UploadBanner_Post_LOADING",
		GOTO_CONTRACTS: "UploadBanner_Post_GOTO_CONTRACTS",
	}
}


export const UploadBannerStatus ={

	UploadBanner_Post :{
		NEW:"UploadBanner_Post_NEW",
		SUCCESS:"UploadBanner_Post_SUCCESS",
		FAILED:"UploadBanner_Post_FALIURE",
		LOADING:"UploadBanner_Post_LOADING",
		GOTO_CONTRACTS: "UploadBanner_Post_GOTO_CONTRACTS",

	}

}
