//****************** Http Request status constants ******************;

module.exports = {
    Timer:{ // constansts for timer settings in our app
        UnClaimed:30 // Time in minutes, for Coupons that a user can unclaim after first purchasing it.
    },

    'ref_table': {
        'ref_coupon_types':1,
         'ref_face_values':2,
         'ref_mode_of_transportation':3,
         'ref_months':4,
         'ref_points_transaction_type':5,
         'ref_roles':6,
         'ref_stations':7,
         'ref_tables':8,
         'ref_transaction_type':9,
         'ref_units':10,
         'ref_years':11,
         'tbl_exchange_rates':12,
         'tbl_application_users':13,
         'tbl_businesses':14,
         'tbl_coupon_contracts':15,
         'tbl_coupon_quotas':16,
         'tbl_coupons':17,
         'tbl_mytix_users':18,
         'tbl_points':19,
         'tbl_tickets':20,
         'tbl_business_applications':21,
         'tbl_promotional_points_log':22
    },
    'Api_Pagination':{
        Limit:100,
        PointsHistoryLimit:25,
        RiderQuestionsLimit:10,
        NotificationHistoryLimit:20,
        InvoiceListLimit:20,
        UsersListLimit:20,
        ApplicantsLimit:20,
        CouponApprovalLimit:20
    },
    'AD_TYPE': {
        NATIVE_AD :"1",
        KEVEL_AD :"2",
        GOOGLE_AD :"3",
    },
    'Text_Field_Limits':{
        "offer_desc_limit":1000,
        "term_and_conditions_limit":1000
    },
    ELASTIC_INDEX:"offers",
    API_delay_time:15
}



