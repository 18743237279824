import { connect } from "react-redux";
import GetDisplayTypesView from "./GetDisplayTypes_View";
import {GetDisplayTypesServer} from "./server"
import {GetDisplayTypesActions} from './GetDisplayTypesConstants'
const mapStateToProps = state => {
  //  console.warn(state.GetDisplayTypesReducer.GetDisplayTypes)
    return {
        GetDisplayTypes_GET_status:state.GetDisplayTypesReducer.GetDisplayTypes_Status,
        DisplayTypes:state.GetDisplayTypesReducer.DisplayTypes,
        // QuotaYears:state.GetDisplayTypesReducer.QuotaYears,
        GetDisplayTypes_Error:state.GetDisplayTypesReducer.GetDisplayTypes_Error
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GetDisplayTypes: (setParentState) => {

           dispatch( GetDisplayTypesServer.GetDisplayTypes(setParentState) )
        },
        resetReducerState: async()=>{
        //    await dispatch( {type:GetDisplayTypesActions.GetDisplayTypes_GET.LOADING} )
            dispatch( {type:GetDisplayTypesActions.GetDisplayTypes_GET.NEW} )
           
        }
    }
};
const GetDisplayTypesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetDisplayTypesView)

export default GetDisplayTypesContainer;