import { connect } from "react-redux";
import GetCouponImagesView from "./GetCouponImages_View";
import {PreviousCouponsServer} from "./server"
import {PreviousCouponsActions} from './GetCouponImagesConstants'
const mapStateToProps = state => {
  //  console.warn(state.GetCouponImagesReducer.GetCouponImages)
    return {
        GetCouponImages_GET_status:state.GetCouponImagesReducer.GetCouponImages_Status,
        CouponImages:state.GetCouponImagesReducer.GetCouponImages,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GetCouponImages: (data) => {

           dispatch( PreviousCouponsServer.GetCouponImages(data) )
        },
        resetReducerState:()=>{
            dispatch({type:PreviousCouponsActions.GetCouponImages_GET.NEW})
        }
    }
};
const GetCouponImagesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetCouponImagesView)

export default GetCouponImagesContainer;