
//******************Exchange Rate Server Calls******************;

import {RetailerCouponApprovalActions} from './RetailerCouponApprovalConstants';
import store from '../../../../Store/store'
import {ROOT_URL, getCookie} from '../../../../Config/Config';
import {failure_messages} from "Messages"

export const RetailerCouponApprovalServer = {
  //  handleRetailerCouponApproval:handleRetailerCouponApproval,
  handleRetailerCouponApproval:handleRetailerCouponApproval,
  
};

//******************Insert RetailerCouponApprovals******************;

function handleRetailerCouponApproval(data){
      const request=fetch(ROOT_URL+'/api/coupon/updateCouponBatchStatus', {
          method: 'POST',
          mode: 'cors',
          headers: { 
            'authorization':getCookie("admin_token_partner"),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
          }).then((response)=>{
           response.json().then(response=>{
             if(response.status == "200"){
            store.dispatch({type:RetailerCouponApprovalActions.RefrenceData_Update.SUCCESS,payload:data, message:response.message});
            return ;
            }else
            {
              store.dispatch({type:RetailerCouponApprovalActions.RefrenceData_Update.FAILED, error:response.error });
              return ;
              }    
           }).catch((error) => {
            store.dispatch({type:RetailerCouponApprovalActions.RefrenceData_Update.FAILED, error:failure_messages.unExpectedError});
             })
          }).catch((error) => {
           store.dispatch({type:RetailerCouponApprovalActions.RefrenceData_Update.FAILED, error:failure_messages.unExpectedError});
            })
      return {type:RetailerCouponApprovalActions.RefrenceData_Update.LOADING}

};