import React, { Component, Fragment } from 'react'
import {Modal} from "./DeleteCouponModel"
import {ref_status} from "RefRecordsConstants"
class DeleteCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel:false
        };
    
      }
      
      handleModel=(check)=>{
        // alert(this.props.coupon.Redeemed_Coupons)
        if(this.props.coupon.Claimed_Coupons < 1 && this.props.coupon.Redeemed_Coupons < 1)
        this.setState({
          showModel:check
        })
        else
        this.props.showSnackBar("Cannot delete because some coupons are claimed/redeemed.")
      }
    render() {
     var disabled=""
      if(ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id || ref_status.DELETED === this.props.coupon.status_id || ref_status.SAVED === this.props.coupon.status_id)
      disabled="disabled"

        return (
            <Fragment>
            <button id="deleteBtn" disabled={ref_status.CLOSED === this.props.coupon.status_id || ref_status.EXPIRED === this.props.coupon.status_id || ref_status.SAVED === this.props.coupon.status_id} onClick={()=>this.handleModel(true)} className={"inv-button-pause "+disabled}>
            Delete
            </button>
         {this.state.showModel? <Modal onClose={()=>this.handleModel(false) } onAccept={this.props.DeleteCoupon}/>:null}
          </Fragment>
        )
    }
}

export default DeleteCoupon
