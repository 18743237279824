import { connect } from "react-redux";
import BusinessListView from "./BusinessList_View";
import {BusinessListServer} from "./server"
import {BusinessListActions} from './BusinessListconstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        BusinessList_GET_status:state.BusinessListReducer.BusinessList_Status,
        BusinessList:state.BusinessListReducer.BusinessListDetails,
        BusinessList_Error:state.BusinessListReducer.BusinessList_Error
       // BusinessListIndex:state.BusinessListReducer.BusinessListIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getBusinessList: (data) => {

           dispatch( BusinessListServer.getBusinessList(data) )
        },
        resetReducerState:()=>{
            dispatch({type:BusinessListActions.BusinessList_GET.NEW})
        }
    }
};
const BusinessListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessListView)

export default BusinessListContainer;