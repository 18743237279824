import { OverridePricingStatus, OverridePricingActions } from './OverridePricingConstants';

const OverridePricing_initialState = {
    OverridePricing_Status: OverridePricingStatus.OverridePricing_Post.NEW,
    message: '',
    OverridePricingDetails: {},
    Generated:false
}
export default function (state = OverridePricing_initialState, action) {
    switch (action.type) {
        case OverridePricingActions.OverridePricing_Post.LOADING:
            return { ...state, OverridePricing_Status: OverridePricingStatus.OverridePricing_Post.LOADING }
        case OverridePricingActions.OverridePricing_Post.NEW:
            return { ...state, OverridePricing_Status: OverridePricingStatus.OverridePricing_Post.NEW }

        case OverridePricingActions.OverridePricing_Post.FAILED:
           // alert("test")
            return { ...state, OverridePricing_Status: OverridePricingStatus.OverridePricing_Post.FAILED,message:action.error }
        case OverridePricingActions.OverridePricing_Post.SUCCESS:
            return { ...state, OverridePricing_Status: OverridePricingStatus.OverridePricing_Post.SUCCESS, OverridePricingDetails: action.payload,Generated:true, message:action.message }

        default:
            return { ...state}
    }
}
