export const ListOfApplicantsActions= {
	
	
	ListOfApplicants_Get :{
		NEW:"ListOfApplicants_Get_NEW",
		SUCCESS:"ListOfApplicants_Get_SUCCESS",
		FAILED:"ListOfApplicants_Get_FALIURE",
		LOADING:"ListOfApplicants_Get_LOADING",
		NOT_AUTHORIZED: "ListOfApplicants_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
		UPDATE:"UpdateApplicantsStatus_Post_SUCCESS",
	},
	ListOfApplicants_Post :{
		NEW:"ListOfApplicants_Post_NEW",
		SUCCESS:"ListOfApplicants_Post_SUCCESS",
		FAILED:"ListOfApplicants_Post_FALIURE",
		LOADING:"ListOfApplicants_Post_LOADING",
		NOT_AUTHORIZED: "ListOfApplicants_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const ListOfApplicantsStatus ={

	ListOfApplicants_Get :{
		NEW:"ListOfApplicants_Get_NEW",
		SUCCESS:"ListOfApplicants_Get_SUCCESS",
		FAILED:"ListOfApplicants_Get_FALIURE",
		LOADING:"ListOfApplicants_Get_LOADING",
		NOT_AUTHORIZED: "ListOfApplicants_Get_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET",
	},
	ListOfApplicants_Post :{
		NEW:"ListOfApplicants_Post_NEW",
		SUCCESS:"ListOfApplicants_Post_SUCCESS",
		FAILED:"ListOfApplicants_Post_FALIURE",
		LOADING:"ListOfApplicants_Post_LOADING",
		NOT_AUTHORIZED: "ListOfApplicants_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
