export const CreateApplicantProfileActions= {
	
	
	CreateApplicantProfile_Post :{
		NEW:"CreateApplicantProfile_Post_NEW",
		SUCCESS:"CreateApplicantProfile_Post_SUCCESS",
		FAILED:"CreateApplicantProfile_Post_FALIURE",
		LOADING:"CreateApplicantProfile_Post_LOADING",
		NOT_AUTHORIZED: "CreateApplicantProfile_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}
}


export const CreateApplicantProfileStatus ={
	
	CreateApplicantProfile_Post :{
		NEW:"CreateApplicantProfile_Post_NEW",
		SUCCESS:"CreateApplicantProfile_Post_SUCCESS",
		FAILED:"CreateApplicantProfile_Post_FALIURE",
		LOADING:"CreateApplicantProfile_Post_LOADING",
		NOT_AUTHORIZED: "CreateApplicantProfile_Post_NOT_AUTHORIZED",
		OFFLINE:"NO_INTERNET"
	}

}
