import React, { Component, Fragment } from 'react'
//import './UploadCouponImage.css'
import { UploadCouponImageStatus } from './UploadCouponImageConstants';
import UploadCoupon from "./UploadCoupon"
// import UploadCouponImageHeader from "./UploadCouponImageHeader/UploadCouponImageHeader"
// import ProgressBar from '../ProgressBar/ProgressBar'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from '../../Utils/Loading';

export class UploadCouponImageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      success: false
    }
    props.resetReducerState()
  }

  componentWillUnmount() {
    this.props.resetReducerState()
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.UploadCouponImage_post_status === UploadCouponImageStatus.UploadCouponImage_Post.SUCCESS) {
  //     nextProps.resetReducerState()

  //     var object = nextProps.UploadCouponImage

  //     const business = nextProps.cookies.get("UploadCouponImageData") ||  nextProps.cookies.get("BusinessApplicationData")
  //     object = { ...business, ...object }
  //     nextProps.cookies.remove("BusinessApplicationData", { path: '/' })
  //     nextProps.cookies.remove("UploadCouponImageData", { path: '/' })


  //     nextProps.cookies.set("UploadCouponImageData", object, { path: '/' })

  //     if (nextProps.GoToContractScreen) {

  //       nextProps.history.push({
  //         pathname: "./business_contract"
  //       });
  //     }else
  //     toast.success(nextProps.UploadCouponImage_Message)

  //   }
  //   else return null;
  // }

  // closePostSnackBar = () => {
  //   this.setState({
  //     open: false
  //   })
  // }
  getScreen(status) {

    // alert(JSON.stringify(this.props.cookies.get('UploadCouponImageData')))
    switch (status) {
      case UploadCouponImageStatus.UploadCouponImage_Post.NEW:

        return (
          <UploadCoupon
            BusinessProfileData={this.props.BusinessProfileData}
            ImageSizeLimit={this.props.ImageSizeLimit}
            VideoSizeLimit={this.props.VideoSizeLimit}
            saveUploadCouponImage={this.props.saveUploadCouponImage}
            Go_Back={this.props.Go_Back}
          />
        );

      case UploadCouponImageStatus.UploadCouponImage_Post.SUCCESS:
        if (!this.state.success) {
          this.state.success = true
          // alert(this.props.getCouponImages)
          toast.success(this.props.UploadCouponImage_Message)
          this.props.resetReducerState()
          if (this.props.getCouponImages)
            this.props.getCouponImages()
          else
            this.props.Go_Back()
        }
        return (
          <Fragment>
            <UploadCoupon
              BusinessProfileData={this.props.BusinessProfileData}
              ImageSizeLimit={this.props.ImageSizeLimit}
              VideoSizeLimit={this.props.VideoSizeLimit}
              saveUploadCouponImage={this.props.saveUploadCouponImage}
              Go_Back={this.props.Go_Back}
            />

          </Fragment>
        );
        break;
      case UploadCouponImageStatus.UploadCouponImage_Post.FAILED:
        toast.error(this.props.UploadCouponImage_Message)

        return (
          <Fragment>

            <UploadCoupon
              BusinessProfileData={this.props.BusinessProfileData}
              ImageSizeLimit={this.props.ImageSizeLimit}
              VideoSizeLimit={this.props.VideoSizeLimit}
              saveUploadCouponImage={this.props.saveUploadCouponImage}
              Go_Back={this.props.Go_Back}
            />

          </Fragment>
        );
        break
      case UploadCouponImageStatus.UploadCouponImage_Post.LOADING:
        this.state.open = true
        this.state.success = false
        return (
          <UploadCoupon
            BusinessProfileData={this.props.BusinessProfileData}
            ImageSizeLimit={this.props.ImageSizeLimit}
            VideoSizeLimit={this.props.VideoSizeLimit}
            saveUploadCouponImage={this.props.saveUploadCouponImage}
            Go_Back={this.props.Go_Back}
            loading={true}
          />

        );
        break;
      default:
        return <div />
    }

  }
  render() {
    // alert(this.props.UploadCouponImage_post_status)
    return (
      <Fragment>

        {this.getScreen(this.props.UploadCouponImage_post_status)}
      </Fragment>
    )
  }
}

export default withRouter(UploadCouponImageView)
