export const OverridePricingActions= {
	
	OverridePricing_Post :{
		NEW:"OverridePricing_Post_NEW",
		SUCCESS:"OverridePricing_Post_SUCCESS",
		FAILED:"OverridePricing_Post_FALIURE",
		LOADING:"OverridePricing_Post_LOADING",
	}
}


export const OverridePricingStatus ={

	OverridePricing_Post :{
		NEW:"OverridePricing_Post_NEW",
		SUCCESS:"OverridePricing_Post_SUCCESS",
		FAILED:"OverridePricing_Post_FALIURE",
		LOADING:"OverridePricing_Post_LOADING",
	
	}

}
