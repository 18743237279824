import React, { Component } from "react";
import "../UploadCoupon.css";
// import SelectSearchInput from "../../Inputs/SelectSearch/SelectSearchInput";

import UploadBannerHeader from "../../UploadBanner/UploadBannerHeader/UploadBannerHeader";
// import RetailerListContainer from "../../RetailerList/RetailerListContainer";
import UploadCouponImageContainer from "./UploadCouponImageContainer"

class UploadCouponForm extends Component {
  constructor(props) {
    super(props);
    // const banner = props.UpdateBannerData || props.DuplicateBannerData;
    this.state = {
    };
  }

  
  // todo: homeHandler onClick function ..........................
 
  render() {

    return (
      <div className="upload-banner-wrap">
        <div className="upload-banner-new-container">
            <div className="upload-coupon-header">
                <UploadBannerHeader
                heading="Upload Offer Image / Video"
                />
            </div>
              <UploadCouponImageContainer getCouponImages={this.props.getCouponImages} BusinessProfileData={this.props.BusinessProfileData}  Go_Back={this.props.Go_Back} />
            </div>
          </div>
      
    );
  }
}

export default UploadCouponForm;
