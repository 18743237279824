import { connect } from "react-redux";
import GetCouponTermsView from "./GetCouponTerms_View";
import {PreviousCouponsServer} from "./server"
import {PreviousCouponsActions} from './GetCouponTermsConstants'
const mapStateToProps = state => {
  //  console.warn(state.GetCouponTermsReducer.GetCouponTerms)
    return {
        GetCouponTerms_GET_status:state.GetCouponTermsReducer.GetCouponTerms_Status,
        CouponTerms:state.GetCouponTermsReducer.GetCouponTerms,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        GetCouponTerms: () => {

           dispatch( PreviousCouponsServer.GetCouponTerms() )
        },
        resetReducerState:async () => {

               dispatch({type:PreviousCouponsActions.GetCouponTerms_GET.NEW})
               
            }
    }
};
const GetCouponTermsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GetCouponTermsView)

export default GetCouponTermsContainer;