import React, { Component } from "react";
//import './ChangePassword.css'
import { ChangePasswordStatus } from "./ChangePasswordConstants";
import ChangePassword from '../ChangePassword'
import Loading from "../../Utils/Loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {  withRouter } from "react-router-dom";
export class ChangePasswordView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showModal:false,
      isLoading:false
    };
    //this.getRiderUnAnsweredQuestions()
  // props.getChangePassword()
  }
  
  getScreen(status) {
    switch (status) {
      case ChangePasswordStatus.ChangePassword_POST.NEW:
        return (
          <ChangePassword ChangePassword = {this.props.ChangePassword}
          
          cookies = {this.props.cookies}
          />
        );

      case ChangePasswordStatus.ChangePassword_POST.SUCCESS:
      
         toast.success(this.props.ChangePassword_Message)
        this.props.resetReducerState()
        this.props.history.replace("/UserManagement")
        return (
          <ChangePassword ChangePassword = {this.props.ChangePassword} 
          
          cookies = {this.props.cookies}
          />
        );
        break;
      case ChangePasswordStatus.ChangePassword_POST.FAILED:
        
         toast.error(this.props.ChangePassword_Message)
         this.props.resetReducerState()
        
        return (
          <ChangePassword ChangePassword = {this.props.ChangePassword} 
                cookies = {this.props.cookies}
          />
        );

      case ChangePasswordStatus.ChangePassword_POST.LOADING:
        
        return (
          
            <Loading />
        
        );

      default:
        return <div />;
    }
  }
  render() {
    return this.getScreen(this.props.ChangePassword_POST_status);
  }
}

export default withRouter(ChangePasswordView);
