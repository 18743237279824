import { ApprovalCheckListStatus, ApprovalCheckListActions } from './ApprovalCheckListConstants';
//import { PaymentCollectionActions } from '../PaymentCollection/PaymentCollectionConstants';

const ApprovalCheckList_initialState = {
    ApprovalCheckList_Status: ApprovalCheckListStatus.ApprovalCheckList_Post.NEW,
    ApprovalCheckList_Message: '',
    ApprovalCheckListDetails:
    [
        {rejected_text:'Image is clear'},
        {rejected_text:'Offer description is not ambiguous'}
      ],
}
export default function (state = ApprovalCheckList_initialState, action) {
    switch (action.type) {
        case ApprovalCheckListActions.ApprovalCheckList_Post.LOADING:
            return { ...state, 
            ApprovalCheckList_Status: ApprovalCheckListStatus.ApprovalCheckList_Post.LOADING,ApprovalCheckList_Message: '',
            // ApprovalCheckListDetails: []
         }
        case ApprovalCheckListActions.ApprovalCheckList_Post.NEW:
            return { ...state, ApprovalCheckList_Status: ApprovalCheckListStatus.ApprovalCheckList_Post.NEW}

        case ApprovalCheckListActions.ApprovalCheckList_Post.FAILED:
           // alert("test")
            return { ...state, ApprovalCheckList_Status: ApprovalCheckListStatus.ApprovalCheckList_Post.FAILED,  ApprovalCheckList_Message: action.error }
        case ApprovalCheckListActions.ApprovalCheckList_Post.SUCCESS:
            return { ...state, ApprovalCheckList_Status: ApprovalCheckListStatus.ApprovalCheckList_Post.SUCCESS, ApprovalCheckListDetails: action.payload }
            case ApprovalCheckListActions.ApprovalCheckList_Post.UPDATE:
                return { ...state, ApprovalCheckListDetails: action.payload,      ApprovalCheckList_Status: ApprovalCheckListStatus.ApprovalCheckList_Post.SUCCESS}
    
            // case PaymentCollectionActions.PaymentCollection_Post.SUCCESS:
            //     return { ...state, ApprovalCheckList_Status: ApprovalCheckListStatus.ApprovalCheckList_Post.UPDATE }
    
        default:
            return { ...state }
    }
}
