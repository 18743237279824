import { connect } from "react-redux";
import InvoicesView from "./Invoices_View";
import {InvoicesServer} from "./server"
import {InvoicesActions} from './InvoicesConstants'
const mapStateToProps = state => {
    console.log(state)
    return {
        Invoices_GET_status:state.InvoicesReducer.Invoices_Status,
        Invoices:state.InvoicesReducer.InvoicesDetails,
        InvoicesCount : state.InvoicesReducer.InvoicesCount,
        Invoices_Error:state.InvoicesReducer.Invoices_Error
       // InvoicesIndex:state.InvoicesReducer.InvoicesIndex
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getInvoices: (data) => {

           dispatch( InvoicesServer.getInvoices(data) )
        },
        resetReducerState:()=>{
            dispatch({type:InvoicesActions.Invoices_GET.NEW})
        }
    }
};
const InvoicesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoicesView)

export default InvoicesContainer;