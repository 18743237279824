import React, { Component} from 'react'
import '../UserManagement/UserManagement.css'

import RotateLoading from "../Utils/RotateLoading"

class UserMessage extends Component {
    render() {
        return (
            <div className="usr-m-box">
             
            <div className="empty-box" style={{display:"flex", justifyContent:"center"}}>
            <h3>{this.props.message}</h3>
            <span>    <RotateLoading  LoadingIcon={this.props.LoadingIcon} 
            loaderStyle={{left:"15px"}} 
            />  </span>
            </div>
            </div>

        )
    }
}

export default UserMessage
