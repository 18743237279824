import React, { Component, Fragment } from "react";
import "./BusinessProfile.css";
import { withRouter } from "react-router-dom";
import LabelInput from "../Inputs/LabelInput";
// import LabelDropDownInput from '../Inputs/LabelDropDownInput';
// import SimpleInput from '../Inputs/SimpleInput';
// import MaskInput from '../Inputs/MaskInput/MaskInput';
// import BusniessMessages from '../BusinessMessages/BusinessMessages';
import moment from "moment";
import CheckBoxSingle from "../Inputs/CheckBoxSingle/CheckBoxSingle";
import BusinessCategoryContainer from "../BusinessProfile/BusinessCategory/BusinessCategoryContainer";
import validator from "validator";
import CountryAddress from "../CountryAddress/CountryAddress";
import LabelTextAreaInput from "../Inputs/LabelTextAreaInput";
// import {Modal} from '../FileUpload/FileUploadInput'
import FileUploadInput from "../FileUpload/FileUploadInput";
import { Text_Field_Limits } from "../../ConfigConstants";
import PasswordShowHide from "../Inputs/PasswordShowHide/PasswordShowHide";
// const data = [ ..  Text_Field_Limits.business_description_limit
//   { value: '1' },
//   { value: '100' },
//   { value: '3' },
//   { value: '4' },
//   { value: '300' },
//   { value: '20' },
//   { value: '40' },
// ];

class BusinessProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // brandLogo:'',
      password: "",
      passwordErrorMsg: "Field Required",
      passwordFlag: "",
      email:"",
      emailFlag:false,
      emailErrorMsg:"Field Required",
      userNameErrorMsg:"Field Required",
      userNameFlag:false,
      userName:"",
      businessName: "",
      businessNameErrorMsg: "Field Required",
      businessNameFlag: false,
      businessCategory: "",
      businessCategoryErrorMsg: "Field Required",
      businessCategoryFlag: false,
      addressLine1: "",
      addressLine1ErrorMsg: "Field Required",
      addressLine1Flag: false,
      businessURL: "",
      businessURLErrorMSG: "Field Required",
      businessURLFlag: false,
      addressLine2: "",
      addressLine2ErrorMsg: "Field Required",
      addressLine2Flag: false,
      // GoogleId: '',
      // GoogleIdErrorMsg: 'Field Required',
      // GoogleIdFlag: false,
      // ReviewURL: '',
      // ReviewURLErrorMsg: 'Field Required',
      // ReviewURLFlag: false,
      city: "",
      cityErrorMsg: "Field Required",
      cityFlag: false,
      state: "NJ",
      stateErrorMsg: "Field Required",
      stateFlag: false,
      zip5: "",
      zip5ErrorMsg: "Field Required",
      zip5Flag: "",
      zip: "",
      zipErrorMsg: "Field Required",
      zipFlag: "",
      description: "",
      descriptionErrorMsg: "",
      descriptionFlag: false,
      couponLimit: "",
      fileimg: "",
      couponLimitErrorMsg: "Field Required",
      couponLimitFlag: false,
      qouta: false,
      pointContactErrorMsg: "Field Required",
      pointContactFlag: false,
      pointContact: "",
      phoneNumberErrorMsg: "Field Required",
      phoneNumberFlag: false,
      phoneNumber: "",
      validateNumFlag1: true,
      initialCheck: false,
      numberError1: "",
      helperText: false,
      msgPopup: false,
      editMode: true,
      display_address_on_coupon: false,
      GotoContract: false,
      selectedImage: "",
      // LogoSize:0,
      ImageErrorMsg: "",
      showModal: false,

      business_category: [],

      categoriesForApplication: "",

      countryName: "United States",
      countryFlag: false,
      countryError: "Field Required",
      countryCode: "",
      created_on: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // inputHandler = () => {
  //   this.setState({
  //     editMode: true,
  //     fileimg: '',
  //     selectedImage: '',
  //   });
  // };

  clearDataHandler = () => {
    this.setState({
      editMode: true,
      fileimg: "",
      selectedImage: "",
      ImageErrorMsg: "",
      LogoSize: "",
      FileLength: "",
    });
  };

  inputHandler = (data) => {
    // this function clears the upload file image
    // alert(JSON.stringify(data))
    console.log("imageDate", data);

    const {
      editMode,
      fileimg,
      selectedImage,
      ImageErrorMsg,
      LogoSize,
      FileLength,
    } = data;
    // alert(fileimg)
    this.setState({
      editMode: editMode,
      fileimg: fileimg,
      selectedImage: selectedImage,
      ImageErrorMsg: ImageErrorMsg,
      LogoSize: LogoSize,
      FileLength: FileLength,
    });
  };

  // todo: description onChange Function ...................

  descriptionHandler = (e) => {
    if (e.target.value === "") {
      this.setState({ descriptionFlag: false });
    }
    if (e.target.value.length > 0 && e.target.value.trim().length === 0) {
      this.setState({ descriptionFlag: true });
    } else {
      this.setState({ descriptionFlag: false });
    }
    this.setState({ description: e.target.value });

    // if (this.state.description !== "") {
    //   this.setState({ descriptionFlag: false });
    // }
    // this.setState({ description: e.target.value });
  };

  // todo: Business Name onChange Function ...................
  pointContactHandler = (e) => {
    //  alert(e.target.value)
    const reg = /^[a-zA-Z .]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ pointContactFlag: false });
    } else {
      this.setState({
        pointContactFlag: true,
        pointContactErrorMsg: "Use only alphabets and period",
      });
    }
    this.setState({ pointContact: e.target.value });
  };
  phoneNumberHandler = (e) => {
    // alert(e.target.value)
    // const reg = /[\d]{3}-[\d]{3}-[\d]{4}$/;
    // const validReg = reg.test(e);
    // if (validReg) {
    //   this.setState({ phoneNumberFlag: false });
    // } else {
    //   this.setState({
    //     phoneNumberFlag: true,
    //     phoneNumberErrorMsg: '',
    //   });
    // }
    this.setState({ phoneNumber: e, phoneNumberFlag: false }, () => {
      console.log("phone:", this.state.phoneNumber);
    });
  };
  fileHandler = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var fileName = file.name;
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, file.length).toLowerCase();

      console.log("length: ", file.length);
      //  var FileLength
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        if (Math.round(file.size / 1024) > 2048) {
          document.getElementById("pe-inp-btn1").value = "";
          //alert("File size should be less than 2MB.");
          // alert("Height and Width must not exceed 100px.");
          this.setState({
            selectedImage: "",
            ImageErrorMsg: "File size should be less than 2MB",
          });
        } else
          reader.onload = async () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
              const imgWidth = img.naturalWidth;
              const imgHeight = img.naturalHeight;

              console.log("imgWidth: ", imgWidth);
              console.log("imgHeight: ", imgHeight);
              var ratio = imgWidth / imgHeight;
              // if(imgWidth > 70 || imgHeight > 70)
              // if (ratio !== 1)
              //   this.setState({
              //     selectedImage: '',
              //     ImageErrorMsg:
              //       'Please upload an image with 1 : 1 in aspect ratio',
              //   });
              // else
              //   this.setState({
              //     editMode: false,
              //     fileimg: reader.result,
              //     selectedImage: file,
              //     ImageErrorMsg: '',
              //     LogoSize: file.size,
              //     FileLength: extFile,
              //   });
              if (ratio === 1 || ratio === 2) {
                this.setState({
                  editMode: false,
                  fileimg: reader.result,
                  selectedImage: file,
                  ImageErrorMsg: "",
                  LogoSize: file.size,
                  FileLength: extFile,
                });
              } else {
                this.setState({
                  selectedImage: "",
                  fileimg: "",
                  ImageErrorMsg:
                    "Please upload an image with 1 : 1 or 2:1 in aspect ratio.",
                });
              }
            };

            // console.log("This is preview path");
            console.log("Result : ", reader.result);

            //  alert("Uploaded image has valid Height and Width.");
          };

        reader.readAsDataURL(file);
      } else {
        this.setState({
          selectedImage: "",
          fileimg: "",
          ImageErrorMsg: "Please upload an image with extensions: jpg/jpeg/png",
        });
      }
    }
  };


/*********************** New Feild Add ************************/ 
  userNameHandler = e => {
    const reg = /^[a-zA-Z0-9-_.]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ userNameFlag: false });
    } else {
      this.setState({
        userNameFlag: true,
        userNameErrorMsg: "Use only Alphanumeric, hyphen, underscore and period"
      });
    }
    this.setState({ userName: e.target.value });
  };

  emailHandler = (e) => {

    const reg  = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+[.][a-zA-Z]{2,10}$/;
    
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ email: e.target.value, emailFlag: false });
    } else {
      this.setState({
        emailFlag: true,
        emailErrorMsg: "Enter Valid Email",
        email: e.target.value,
      });
    }
    // this.setState({ email: e.target.value });
  };
  passwordHandler = e => {
    const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,])[A-Z\a-z\d.\\<>?;:"'`~!@#$%^&*()\[\]{}_+=|\-\/,]{8,}$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ passwordFlag: false });
    } else {
      this.setState({
        passwordFlag: true,
        passwordErrorMsg: "Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol"
      });
    }
    this.setState({ password: e.target.value});
  };

  passwordGenerateHandler = () => {
    var pLength = 9
    var keyListAlpha="abcdefghijklmnopqrstuvwxyz",
    keyListInt="123456789",
    keyListSpec="!@#$%*?&_",
    password='';
  var len = Math.ceil(pLength/2);
  len = len - 1;
  var lenSpec = pLength-2*len;
  
  for (var i=0;i<len;i++) {
    password+=keyListAlpha.charAt(Math.floor(Math.random()*keyListAlpha.length));
    password+=keyListInt.charAt(Math.floor(Math.random()*keyListInt.length));
  }
  
  for (var i=0;i<lenSpec;i++)
    password+=keyListSpec.charAt(Math.floor(Math.random()*keyListSpec.length));
  
  password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
    // alert(password)
    // password = password.substr(0,len)
    this.setState({ password: password, passwordFlag:false});
    
          //  if (this.state.passwordFlag || this.state.password === "") {
          //   this.setState({
          //     passwordErrorMsg:
          //       this.state.passwordFlag && this.state.password.length > 0
          //         ? "Password should be alphanumeric and more than 7 digits and atleast 1 symbol"
          //         : "Field Required",
          //     passwordFlag: true
          //   });
          // } else {
  
          // }
        };
/*********************** New Feild Add ************************/ 


  scrollToBusinessName = () => {
    this.businessName.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToBusinessUrl = () => {
    this.businessURL.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToBusinessAdress = () => {
    this.businessAddress.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToState = () => {
    this.businessState.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToUserName = () => {
    this.newFeiledScroll.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  newFeiledScroll

  scrollToContact = () => {
    this.businessContact.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollToPhone = () => {
    this.businessPhone.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  businessNameHandler = (e) => {
    // alert(JSON.stringify(this.state.fileName))
    const reg = /^[a-zA-Z0-9_ `',&.-]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ businessNameFlag: false });
    } else {
      this.setState({
        businessNameFlag: true,
        businessNameErrorMsg:
          "Use only Alphabets, numeric , hyphen , dash, comma, ampersand, period and apostrophe",
      });
    }
    this.setState({
      businessName:
        e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1),
    });
  };

  businessCategoryHandler = (category) => {
    // alert(JSON.stringify(category))
    // this.state.business_category.push(category)
    this.setState(
      {
        business_category: category,
        businessCategoryFlag: false,
      },
      () => {
        //       let category_string =''
        //       for(let i = 0 ;i<this.state.businessCategory.length;i++){
        //         let name
        //         if(i!=0){
        //           name = "+"+this.state.businessCategory[i].name
        // category_string = category_string.concat(name)
        //         }
        //         else{
        //           name = this.state.businessCategory[i].name
        // category_string = category_string.concat(name)
        //         }
        //       }
        let category_string = "";
        if (category.length > 0)
          category_string = category.map((u) => u.name).join("+");
        // alert(category_string)
        this.setState({ categoriesForApplication: category_string });
      }
    );
  };

  // todo: Address Line 1 onChange Function ...................
  UNSAFE_componentWillMount() {
    // if(this.props.BusinessApplicationData)
    if (this.props.BusinessProfileData || this.props.FormData) {
      // if (this.props.cookies) {
      //   this.props.cookies.remove("BusinessProfileData", { path: "/" });
      //   this.props.cookies.set(
      //     "BusinessProfileData",
      //     this.props.BusinessProfile,
      //     { path: "/" }
      //   );
      // }
      console.log("form data", this.props.FormData);
      console.log("form data", this.props.BusinessProfileData);

      const data = this.props.FormData || this.props.BusinessProfileData;
      // alert(data.google_business_id)
      // if (data.selectedImage) this.state.selectedImage = data.selectedImage;
      this.state.businessName = data.business_name;
      this.state.addressLine1 = data.business_street_1;
      this.state.addressLine2 = data.business_street_2;
      this.state.created_on = data.created_on;
      // if(data.google_business_id)
      // this.state.GoogleId= data.google_business_id
      // if(data.google_review_url)
      // this.state.ReviewURL= data.google_review_url

      this.state.categoriesForApplication = data.business_category_names;
      this.state.display_address_on_coupon = data.display_address_on_coupon;
      this.state.description = data.description;
      this.state.city = data.city;
      this.state.state = data.state;
      this.state.zip5 = data.zip5;
      this.state.countryName = data.country;
      if (data.zip4 && data.zip4 != "")
        this.state.zip5 = data.zip5 + "-" + data.zip4;
      // this.state.zip = data.zip4;
      this.state.pointContact = data.point_of_contact;
      // alert(data.phone)
      this.state.phoneNumber = data.phone;
      if (data.business_category_names)
        this.state.business_category = data.business_category_names;
      if (data.logo_url && data.logo_url != "") {
        this.state.editMode = false;
        this.state.fileimg = data.logo_url;
       
        // this.state.selectedImage={uri:data.logo_url,name:data.logo_url.split("---")[1]}
      }

      this.state.businessURL = data.company_url;
      //this.state.couponLimit = data.monthly_coupon_limit;
      if (data.coupon_contract_id) {
        this.state.qouta = true;
      }
    } else if (this.props.BusinessApplicationData) {
      const data = this.props.BusinessApplicationData;
      console.log("BusinessApplicationData: ", data);
      this.state.categoriesForApplication = data.business_category_names;
      this.state.businessName = data.business_name;
      this.state.addressLine1 = data.business_street_1;
      this.state.addressLine2 = data.business_street_2;
      this.state.city = data.business_city;
      this.state.state = data.business_state;
      this.state.description = data.description;
      this.state.zip5 = data.zip5;
      this.state.businessURL = data.company_url;
      this.state.created_on = data.created_on;
      if(this.state.state ==='')
      this.state.countryName = data.country;
      if (data.zip4 && data.zip4 != "")
        this.state.zip5 = data.zip5 + "-" + data.zip4;
      this.state.pointContact = data.point_of_contact;
      this.state.phoneNumber = data.phone;

      // this.state.businessCategory = data.business_category_id;
    }
  }

  addressLine1Handler = (e) => {
    var name = e.target.value;
    var reg = /^[a-zA-Z0-9_ ,\-.#&@()]+$/;
    if (name.includes("htt"))
      reg =/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;

    const validReg = reg.test(name);
    if (validReg) {
      this.setState({ addressLine1Flag: false });
    } else {
      this.setState({
        addressLine1Flag: true,
        addressLine1ErrorMsg: name.includes("htt")
          ? "Enter valid url"
          : "Use only Alphabets, numeric and ,_. '#- @&,(,)",
      });
    }
    if (e.target.value == "") {
      this.setState({ addressLine1ErrorMsg: "" });
    }
    this.setState({ addressLine1: name });
  };

  // todo: ------------- BusinessURL Handler -------------
  businessURLHandler = (e) => {
    if (validator.isURL(e.target.value)) {
      this.state.businessURLFlag = false;
    } else if (e.target.value === "") {
      this.state.businessURLFlag = false;
    } else {
      this.state.businessURLFlag = true;
      this.state.businessURLErrorMSG = "Enter Valid URL";
    }
    this.setState({ businessURL: e.target.value });
    // const reg = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
    // // const reg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    // const validReg = reg.test(e.target.value);
    // if (validReg) {
    //   this.setState({ businessURLFlag: false });
    // } else {
    //   this.setState({
    //     businessURLFlag: true,
    //     businessURLErrorMSG: 'Enter Valid URL',
    //   });
    // }
    // if (e.target.value === '') {
    //   this.setState({ businessURLFlag: false });
    // }
    // this.setState({ businessURL: e.target.value });
  };

  addressLine2Handler = (e) => {
    var name = e.target.value;
    var reg = /^[a-zA-Z0-9_ ,\-.#&@()]+$/;
    if (name.includes("htt"))
      reg =/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;

    const validReg = reg.test(name);
    if (validReg) {
      this.setState({ addressLine2Flag: false });
    } else {
      this.setState({
        addressLine2Flag: true,
        addressLine2ErrorMsg: name.includes("htt")
          ? "Enter valid url"
          : "Use only Alphabets, numeric and ,_. '#- @&,(,)",
      });
    }
    if (e.target.value == "") {
      this.setState({ addressLine2ErrorMsg: "", addressLine2Flag: false });
    }
    this.setState({ addressLine2: name });
  };

  // addressLine2Handler = e => {
  //   //  alert(e.target.value)
  //   // const reg = /^[a-zA-Z0-9\s,_.'#-]*$/;
  //   const reg = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
  //   const validReg = reg.test(e.target.value);
  //   if (validReg) {
  //     this.setState({ addressLine2Flag: false });
  //   } else {
  //     this.setState({
  //       addressLine2Flag: true,
  //       addressLine2ErrorMsg: "Use only Alphabets, numeric and ,_. #-"
  //     });
  //   }
  //   this.setState({ addressLine2: e.target.value });
  // };
  // todo: city onChange Function ...................

  cityHandler = (e) => {
    //  alert(e.target.value)
    const reg = /^[a-zA-Z ]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ cityFlag: false });
    } else {
      this.setState({
        cityFlag: true,
        cityErrorMsg: "Enter valid city name",
      });
    }
    this.setState({
      city:
        e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1),
    });
  };
  // todo: State onChange Function ...................

  stateHandler = (e) => {
    if (this.state.state !== "" || this.state.state !== "selectState") {
      this.setState({ stateFlag: false });
    }
    this.setState({ state: e });
  };
  // todo: Zip5 onChange Function ...................

  zip5Handler = (e) => {
    var input = e.target.value;
    var reg = "";
    if (input.length > 5) {
      reg = /[\d]{5}-[\d]{4}$/;
    } else {
      reg = /[\d]{5}$/;
    }
    const validReg = reg.test(input);
    if (validReg) {
      this.setState({ zip5Flag: false });
    } else {
      this.setState({
        zip5Flag: true,
        zip5ErrorMsg: "Enter 5 or 9 digit zip code",
      });
    }
    if (input.length > 5 && !input.includes("-")) {
      input = input.substring(0, 5) + "-" + input.slice(5);
    }
    this.setState({ zip5: input });
  };
  // todo: Zip onChange Function ...................

  zipHandler = (e) => {
    //  alert(e.target.value)
    // con\
    this.setState({ zip5: e, zip5Flag: false });
  };
  // todo: Coupon Limit onChange Function ...................

  couponLimitHandler = (e) => {
    //  alert(e.target.value)
    const reg = /^[0-9]*$/;
    const validReg = reg.test(e.target.value);
    if (validReg) {
      this.setState({ couponLimitFlag: false });
    } else {
      this.setState({
        couponLimitFlag: true,
        couponLimitErrorMsg: "Use only Numeric",
      });
    }
    this.setState({ couponLimit: e.target.value });
  };

  // todo: SaveHandler onClick Function ...................

  saveHandler = () => {
    console.log("business data", this.props.BusinessProfileData);
    // if(this.state.ImageErrorMsg !=='' || this.state.fileimg===''){
    //   // alert("test")
    //   this.setState({
    //     selectedImage: '',
    //     ImageErrorMsg: 'Field Required',
    //   });
    //   this.scrollToBusinessName();
    // }
    //   else
    if (this.state.businessNameFlag || this.state.businessName === "") {
      this.setState({
        businessNameErrorMsg:
          this.state.businessNameFlag && this.state.businessName.length > 0
            ? "Use only Alphabets, numeric and _ '-"
            : "Field Required",
        businessNameFlag: true,
      });
      this.scrollToBusinessName();
    } else if (this.state.business_category.length == 0) {
      this.setState({
        businessCategoryErrorMsg: "Field Required",
        businessCategoryFlag: true,
      });
      this.scrollToBusinessName();
    } else if (this.state.pointContactFlag || this.state.pointContact === "" || this.state.pointContact == null) {
      this.setState({
        pointContactErrorMsg:
          this.state.pointContactFlag && this.state.pointContact.length > 0
            ? "Enter Alphabets Only"
            : "Field Required",
        pointContactFlag: true,
      });
      this.scrollToBusinessAdress();
    } else if (this.state.addressLine1Flag || this.state.addressLine1 === ""  || this.state.addressLine1 == null) {
      this.setState({
        addressLine1ErrorMsg:
          this.state.addressLine1Flag && this.state.addressLine1.length > 0
            ? "Use only Alphabets, numeric and ,_. '#- @&,(,)"
            : "Field Required",
        addressLine1Flag: true,
      });
      this.scrollToBusinessAdress();
    }
     else if (this.state.addressLine2Flag) {
      this.setState({
        addressLine2ErrorMsg:
          this.state.addressLine2Flag && this.state.addressLine2.length > 0
            ? "Use only Alphabets, numeric and ,_. '#- @&,(,)"
            : "Field Required",
        addressLine2Flag: true,
      });
      this.scrollToBusinessAdress();
    } else if (this.state.countryName === "" || this.state.countryFlag || this.state.countryName == null) {
      this.setState({
        countryFlag: true,
        countryError: "Field Required",
      });
      this.scrollToState();
    } else if (this.state.state === "selectState" || this.state.state === "") {
      this.setState({
        stateErrorMsg: "Field Required",
        stateFlag: true,
      });
      this.scrollToState();
    } else if (this.state.cityFlag || this.state.city === "" || this.state.city == null) {
      this.setState({
        cityErrorMsg:
          this.state.cityFlag && this.state.city.length > 0
            ? "Enter valid city name"
            : "Field Required",
        cityFlag: true,
      });
      this.scrollToState();
    } else if (
      this.state.zip5Flag ||
      this.state.zip5 === ""
      || this.state.zip5 == null
    ) {
      this.setState({
        zip5ErrorMsg:
          this.state.zip5Flag || this.state.zip5.length > 0
            ? "Enter 5 or 9 digit zip code"
            : "Field Required",
        zip5Flag: true,
      });
      this.scrollToState();
    } else if (this.state.phoneNumberFlag || this.state.phoneNumber === ""  || this.state.phoneNumber == null) {
      this.setState({
        phoneNumberErrorMsg:
          this.state.phoneNumberFlag && this.state.phoneNumber.length > 0
            ? "Enter Valid Phone Number"
            : "Field Required",
        phoneNumberFlag: true,
      });
      this.scrollToState();
    } else if ((this.state.userNameFlag || this.state.userName === "") && !this.props.BusinessProfileData) {
      this.setState({
        userNameErrorMsg:
          this.state.userNameFlag && this.state.userName.length > 0
            ? this.state.userNameErrorMsg
            : "Field Required",
            userNameFlag: true
      });
      this.scrollToUserName();
    }  
    else if ((this.state.emailFlag || this.state.email === "") && !this.props.BusinessProfileData) {
      this.setState({
        emailErrorMsg:
          this.state.emailFlag && this.state.email.length > 0
            ? this.state.emailErrorMsg
            : "Field Required",
            emailFlag: true
      });
      this.scrollToUserName();
    } 
    else if ((this.state.passwordFlag || this.state.password === "") && !this.props.BusinessProfileData) {
      this.setState({
        passwordErrorMsg:
          this.state.passwordFlag && this.state.password.length > 0
            ? "Password should be more than 7 characters, at least 1 letter, 1 number and 1 symbol"
            : "Field Required",
        passwordFlag: true
      });
      this.scrollToUserName();
    }   

    else if (this.state.businessURLFlag) {
      this.scrollToState();
    } else if (
      this.state.descriptionFlag
      // ||
      // this.state.description.trim() === ''
    ) {
      this.setState({
        descriptionErrorMsg:
          // this.state.descriptionFlag &&
          this.state.description.trim().length > 0
            ? "Use only Alphabets and Space"
            : "Field Required",
        descriptionFlag: true,
      });
    }
    // else if (
    //   this.state.zip.length &&
    //   (this.state.zipFlag || this.state.zip === "" || this.state.zip.length < 4)
    // ) {
    //   this.setState({
    //     zipErrorMsg:
    //       this.state.zipFlag || this.state.zip.length > 0
    //         ? "Maximum 4 AlphaNumeric"
    //         : "Field Required",
    //     zipFlag: true
    //   });
    // }
    else {
      var data = {
        business_name: this.state.businessName,
        business_street_1: this.state.addressLine1,
        business_street_2: this.state.addressLine2 ===""?null:this.state.addressLine2,
        city: this.state.city,
        state: this.state.state,
        zip5: this.state.zip5.split("-")[0],
        zip4:this.state.zip5.split("-")[1] && this.state.zip5.split("-")[1] != ""
            ? this.state.zip5.split("-")[1]
            : null,
        fileimg: this.state.fileimg,
        selectedImage: this.state.selectedImage,
        point_of_contact: this.state.pointContact,
        phone: this.state.phoneNumber
          .replace(/-/g, "")
          .replace("(", "")
          .replace(")", ""),
        last_updated: new Date(),
        display_address_on_coupon: this.state.display_address_on_coupon,
        // business_category_id: this.state.businessCategory,
        company_url:
          this.state.businessURL !== "" ? this.state.businessURL : null,
        description:
          this.state.description !== "" ? this.state.description : null,
        business_category: this.state.business_category,
        country_code: this.state.countryCode,
        country: this.state.countryName,
        user_name:this.state.userName.trim(),
        // google_business_id: this.state.GoogleId.trim() !== "" ? this.state.GoogleId.trim() : null,
        // google_review_url:this.state.ReviewURL.trim() !== "" ? this.state.ReviewURL.trim() : null,
      };

      if (this.props.BusinessProfileData) {
        var flag = false;
        var isAddressUpdated = false;
        var prevData = this.props.BusinessProfileData;
        if (data.business_name != prevData.business_name) 
        {
          flag = true;
        }
        if (data.business_street_1 != prevData.business_street_1) {
          flag = true;
          isAddressUpdated = true;
        }

        if (data.business_street_2 != prevData.business_street_2) {
          flag = true;
          isAddressUpdated = true;
        }

        if (data.city != prevData.city) {
          flag = true;
          isAddressUpdated = true;
        }

        if (data.state != prevData.state) {
          flag = true;
          isAddressUpdated = true;
        }

        if (data.zip5 != prevData.zip5) {
          flag = true;
          isAddressUpdated = true;
        }

        // if (data.zip4 != prevData.zip4) {
        //   flag = true;
        //   isAddressUpdated = true;
        // }
        // if (data.google_business_id != prevData.google_business_id) {
        //   flag = true;
        //   isAddressUpdated = true
        // }

        // if (data.google_review_url != prevData.google_review_url) {
        //   flag = true;
        //   isAddressUpdated = true
        // }
        if (data.point_of_contact != prevData.point_of_contact) {
          flag = true;
        }
        if (data.phone != prevData.phone)
        { flag = true;
        }
        if (data.fileimg != prevData.logo_url) {
          flag = true;
        }
        if (data.company_url != prevData.company_url) {
          flag = true;
       
        }
        if (data.description != prevData.description) 
        {
          flag = true;
        }
        if (data.country != prevData.country) {
         
          flag = true;
          isAddressUpdated = true;
        }

        if (data.display_address_on_coupon != prevData.display_address_on_coupon)
          {
            flag = true;
          }
        if (
          this.state.categoriesForApplication !=
          prevData.business_category_names
        )
        {
            flag = true;
        }
       
        if (flag)
          this.props.updateBusinessProfile(
            {
              ...data,
              logo_url: this.state.fileimg,
              isAddressUpdated: isAddressUpdated,
            },
            this.props.BusinessProfileData.business_id
          )
        else if (this.state.GotoContract) {
          this.props.history.push({
            pathname: "./business_contract",
          });
        }
      } else if (this.props.BusinessApplicationData) {
        // alert(JSON.stringify(data))
        let user_data = {
        email_address : this.state.email.trim(),
        user_name:this.state.userName.trim(),
        password_digest:this.state.password,
        retailerAddedByAdmin: true
        }
        // alert("test: "+JSON.stringify(this.props.BusinessApplicationData.application_user_id))
        this.props.CreateRetailerBussiness({
          ...data,
          business_application_id:
          this.props.BusinessApplicationData.business_application_id,
          note: this.props.BusinessApplicationData.note,
          isNewAddress: true,
        }, user_data);
      }
      // alert(JSON.stringify(data.business_category))
      else
       {
        let user_data = {
          email_address : this.state.email.trim(),
          user_name:this.state.userName.trim(),
          password_digest:this.state.password,
          retailerAddedByAdmin: true
          }
         this.props.saveBusinessProfile({
          ...data,
          isNewAddress: true,
        }, user_data);
      }
    }
  };

  // todo: homeHandler onClick function ..........................

  homeHandler = () => {
    this.props.history.push({
      pathname: "/Admin",
    });
  };
  // todo: listHandler onClick Function ...................
  listHandler = () => {
    this.props.history.push({
      pathname: "/business_list",
    });
  };
  // todo: msgPopupHandler onClick Function ...................
  msgPopupHandler = () => {
    this.setState({ msgPopup: !this.state.msgPopup });
  };
  // todo: backHandler onClick Function ...................
  backHandler = () => {
    // alert("backHandler work");

    this.props.history.push({
      pathname: "/business_list",
    });
  };
  // todo: nextHandler onClick Function ...................
  nextHandler = () => {
    alert("nextHandler work");
  };
  // todo: contractHandler onClick Function ...................
  contractHandler = () => {
    this.state.GotoContract = true;
    this.props.GotoContract();
    this.saveHandler();
  };

  setServer = () => {
    // alert(this.state.sendServer)
    this.setState({
      display_address_on_coupon: !this.state.display_address_on_coupon,
    });
  };

  googleBusinessIdHandler = (e) => {
    // inputValue={this.state.GoogleId}
    // flag={this.state.GoogleIdFlag}
    // errorMsg={this.state.GoogleIdErrorMsg}
    let googleBusinessId = e.target.value;
    // var reg = /^[a-zA-Z0-9_ ,-.'#]+$/;
    let reg = /^[0-9]+$/;

    const validReg = reg.test(googleBusinessId);
    if (validReg) {
      this.state.GoogleIdFlag = false;
    } else {
      this.state.GoogleIdFlag = true;
      this.state.GoogleIdErrorMsg = "Only Numeric Characters Allowed.";
    }
    if (e.target.value == "") {
      this.state.GoogleIdFlag = false;
    }
    this.setState({ GoogleId: googleBusinessId });
  };

  //Country Handler
  setCoutry = (e) => {
    // alert(JSON.stringify(e))
    let val = e;
    if (val) {
      this.setState({
        countryName: val,
        countryFlag: false,
      });
    } else {
      this.setState({
        countryName: "",
        countryCode: "",
      });
    }
  };

  handleModel = (check) => {
    this.setState({
      showModel: check,
    });
  };

  handeshowFileModal = (v) => {
    this.setState({
      showModal: v,
    });
  };

  // showName = (v) => {
  //   this.setState({
  //     brandLogo:v,
  //     // editMode:false
  //   })
  // }

  render() {
    console.log(this.state, "ashdkjasdhjasd");
    // alert(this.props.BusinessApplicationData)

    const {
      businessName,
      businessNameErrorMsg,
      businessNameFlag,
      businessCategory,
      businessCategoryErrorMsg,
      businessCategoryFlag,
      addressLine1,
      addressLine1ErrorMsg,
      addressLine1Flag,
      businessURL,
      businessURLFlag,
      businessURLErrorMSG,
      addressLine2,
      addressLine2ErrorMsg,
      addressLine2Flag,
      description,
      descriptionErrorMsg,
      descriptionFlag,
      pointContactErrorMsg,
      pointContactFlag,
      pointContact,
      userNameErrorMsg,
      userNameFlag,
      userName,
      email,
      emailErrorMsg,
      emailFlag,
      password,
      passwordErrorMsg,
      passwordFlag,
    } = this.state;
    // alert(this.state.categoriesForApplication)
    // console.log('asdasdasdasdas', this.state.brandLogo);
    // const {fileimg,selectedImage} = this.state.brandLogo

    return (
      <Fragment>
        <div className="bp_container bp-b-b-box">
          <div className="bp_header">
            <h1>Business Profile</h1>

            {/* new upload button */}

            {this.state.editMode === true ? (
              <div className="pe-file-container">
                <FileUploadInput
                  onClose={() => this.handleModel(false)}
                  inputHandler={(data) => this.inputHandler(data)}
                  handeshowFileModal={this.handeshowFileModal}
                  showModal={this.state.showModal}
                />

                <button
                  id="brandingLogo"
                  className="ubl-bp"
                  onClick={() => this.handeshowFileModal(true)}
                >
                  Upload Branding Logo
                </button>
                <p className="pe-p1-1">{this.state.selectedImage.name}</p>
                <p className="error-upload" style={{ paddingTop: 10 }}>
                  {this.state.ImageErrorMsg}
                </p>
              </div>
            ) : (
              <div className="pe-img-container1 logo-b12">
                <button
                  id="close"
                  className="pe-btn1"
                  onClick={this.clearDataHandler}
                >
                  <i className="material-icons pe-btn-clear">clear</i>
                </button>
                <div className="pe-img-icon1">
                  <img
                    src={this.state.fileimg}
                    alt="not found"
                    onLoad={this.onImgLoad}
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <div className="pe-text-block1">
                  <p className="pe-p1-1">{this.state.selectedImage.name}</p>
                </div>
              </div>
            )}
          </div>
          <div className="bp_content_container ">
            <div className="b-b-p-2">
              <LabelInput
                type="text"
                maxLength="30"
                name="businessName"
                id="businessName"
                label="Business Name:"
                star="*"
                inputValue={businessName}
                flag={businessNameFlag}
                errorMsg={businessNameErrorMsg}
                onChange={this.businessNameHandler}
              />
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.businessName = el;
              }}
            ></div>
            <div className="box-cate-input">
              <BusinessCategoryContainer
                businessCategoryNames={
                  this.props.BusinessProfileData?.business_category_names
                    ? this.props.BusinessProfileData?.business_category_names
                    : this.props.BusinessApplicationData
                        ?.business_category_names
                }
                inputValue={businessCategory}
                flag={businessCategoryFlag}
                errorMsg={businessCategoryErrorMsg}
                onChange={this.businessCategoryHandler}
              />
            </div>
            <LabelInput
              type="text"
              maxLength="225"
              name="PointContact"
              id="pointContact"
              star="*"
              label="Point of Contact:"
              inputValue={pointContact}
              containerStyle={{ marginTop: "20px" }}
              flag={pointContactFlag}
              errorMsg={pointContactErrorMsg}
              onChange={this.pointContactHandler}
            />
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.businessPhone = el;
              }}
            ></div>
            <LabelInput
              type="text"
              maxLength="255"
              name="addressLine1"
              star="*"
              id="addressLine1"
              label="Address Line 1:"
              inputValue={addressLine1}
              flag={addressLine1Flag}
              errorMsg={addressLine1ErrorMsg}
              // containerStyle={{ marginTop: "20px" }}
              onChange={this.addressLine1Handler}
            />
            <div
              style={{ float: 'left', clear: 'both' }}
              ref={(el) => {
                this.businessAddress = el;
              }}
            ></div>
            <LabelInput
              type="text"
              maxLength="255"
              name="addressLine2"
              id="addressLine2"
              label="Address Line 2:"
              inputValue={addressLine2}
              flag={addressLine2Flag}
              errorMsg={addressLine2ErrorMsg}
              // containerStyle={{ marginTop: "20px" }}
              onChange={this.addressLine2Handler}
            />

            <div className="counttry">
              <CountryAddress
                setCoutry={this.setCoutry}
                setCoutryState={this.stateHandler}
                Zipcode={this.state.zip5}
                ZipcodeErrorMsg={this.state.zip5ErrorMsg}
                ZipcodeFlag={this.state.zip5Flag}
                zipCodeHandler={this.zipHandler}
                cityHandler={this.cityHandler}
                city={this.state.city}
                cityErrorMsg={this.state.cityErrorMsg}
                cityFlag={this.state.cityFlag}
                countryName={this.state.countryName}
                countryError={this.state.countryError}
                countryFlag={this.state.countryFlag}
                stateErrorMsg={this.state.stateErrorMsg}
                state={this.state.state}
                BusinessApplicationData={this.props.BusinessApplicationData}
                stateFlag={this.state.stateFlag}
                phone={this.state.phoneNumber}
                phoneNumberErrorMsg={this.state.phoneNumberErrorMsg}
                phoneNumberFlag={this.state.phoneNumberFlag}
                phoneNumberHandler={this.phoneNumberHandler}
              />
            </div>
            <LabelInput
              type="text"
              maxLength="255"
              name="businessURL"
              id="businessURL"
              label="Company URL (Optional)"
              inputValue={businessURL}
              flag={businessURLFlag}
              errorMsg={businessURLErrorMSG}
              // containerStyle={{ marginTop: "33px" }}
              onChange={this.businessURLHandler}
            />
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.businessState = el;
              }}
            ></div>
            <LabelTextAreaInput
              label="Description (Optional)"
              inputValue={description}
              name="Description"
              id="description"
              onChange={this.descriptionHandler}
              flag={descriptionFlag}
              errorMsg={descriptionErrorMsg}
              maxLength={100} //Text_Field_Limits.business_description_limit}
            />
            {!this.props.BusinessProfileData?<>
            <div className="input_name_f_new">
            <LabelInput
                  label="User Name:"
                  id="userName"
                  star="*"
                  type="text"
                  name="name"
                  inputValue={userName}
                  errorMsg={userNameErrorMsg}
                  flag={userNameFlag}
                  onChange={this.userNameHandler}
                />
            </div>
                 <LabelInput
                  label="Email:"
                  star="*"
                  type="text"
                  name="email"
                  id="emailInput"
                  inputValue={email}
                  flag={emailFlag}
                  errorMsg={emailErrorMsg}
                  onChange={this.emailHandler}
                
                />
              <div className="inpt_pass_new">
              <PasswordShowHide
            type={this.state.passType}
            label="New Password"
            name="text"
            id="newPassword"
            inputValue={password}
            errorMsg={passwordErrorMsg}
            flag={passwordFlag}
            onChange={this.passwordHandler}
          />

          <div className="generate-btn">
                    <button className="filter-btn" onClick={this.passwordGenerateHandler}>
                        Generate Password
                    </button>
                </div>
              </div>
              <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.newFeiledScroll = el;
              }}
            ></div>
           </>:null}
            {this.props.BusinessProfileData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <LabelInput
                  type="text"
                  name="Created On"
                  label="Created On:"
                  id="createdOn"
                  // inputValue={moment(
                  //   this.props.BusinessProfileData.last_updated
                  // ).format('MM/DD/YYYY, hh:mm:ss')}

                  inputValue={moment(this.state.created_on).format(
                    "MM/DD/YYYY, hh:mm:ss"
                  )}
                  disabled={true}
                />
              </div>
            ) : (
              ""
            )}

            {this.props.BusinessProfileData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <LabelInput
                  type="text"
                  name="Last Updated"
                  label="Last Updated:"
                  id="lastUpdated"
                  //maxLength="10"
                  //inputValue={this.props.BusinessProfileData.last_updated}
                  inputValue={moment(
                    this.props.BusinessProfileData.last_updated
                  ).format("MM/DD/YYYY, hh:mm:ss")}
                  disabled={true}

                  // flag={zip5Flag}
                  // errorMsg={zip5ErrorMsg}
                  // inputStyle={{ width: "147px" }}
                  // containerStyle={{ marginTop: "20px" }}
                  //onChange={this.zip5Handler}
                />
              </div>
            ) : (
              ""
            )}
            <div className="manage-f-box">
              <div className="manage-radio check-box">
                <CheckBoxSingle
                  value={this.state.display_address_on_coupon}
                  onChange={this.setServer}
                  name="sendServer"
                  id="sendServer"
                  label="display business address on offers"
                />
              </div>
            </div>
            <div className="bp_btn_container change-box">
              {/* <button className="bp_btn0" onClick={this.homeHandler}>
              Back To Home
            </button> */}
              {/* <button className="bp_btn1" onClick={this.listHandler}>
              Back To Business List
            </button> */}
              <div className="bp_btn_ch_left">
                {/* <button className="bp_btn1 left" onClick={this.msgPopupHandler}>
                  Message
                </button> */}
              </div>
              {/* <button className="bp_btn2" onClick={this.contractHandler}>
              Business Contract
            </button> */}
              <div className="bp_btn_ch_left right">
                <button
                  id="saveButton"
                  className="bp_btn3"
                  onClick={this.saveHandler}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="bp_btn_container change-box margin">
              <div className="bp_btn_ch_left">
                <button
                  id="backButton"
                  className="bp_btn1 left"
                  onClick={this.backHandler}
                >
                  <i className="material-icons"> keyboard_arrow_left </i>
                </button>
              </div>
              {/* <button className="bp_btn2" onClick={this.contractHandler}>
              Business Contract
            </button> */}
              <div className="bp_btn_ch_left right">
                <button
                  id="nextButton"
                  className="bp_btn3"
                  onClick={this.contractHandler}
                >
                  <i className="material-icons"> keyboard_arrow_right </i>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {msgPopup ? <BusniessMessages onClick={this.msgPopupHandler} /> : null} */}
      </Fragment>
    );
  }
}

export default withRouter(BusinessProfile);
