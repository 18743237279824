export const PausePlayCouponActions= {
	
	PausePlayCoupon_Post :{
		NEW:"PausePlayCoupon_Post_NEW",
		SUCCESS:"PausePlayCoupon_Post_SUCCESS",
		FAILED:"PausePlayCoupon_Post_FALIURE",
		LOADING:"PausePlayCoupon_Post_LOADING",
	}
}


export const PausePlayCouponStatus ={

	PausePlayCoupon_Post :{
		NEW:"PausePlayCoupon_Post_NEW",
		SUCCESS:"PausePlayCoupon_Post_SUCCESS",
		FAILED:"PausePlayCoupon_Post_FALIURE",
		LOADING:"PausePlayCoupon_Post_LOADING",
		
	}

}
