import { connect } from "react-redux";
import OverrideCouponsInventoryView from "./OverrideCouponsInventory_View";
import {OverrideCouponsInventoryServer} from "./server"
import {OverrideCouponsInventoryActions} from './OverrideCouponsInventoryConstants'
const mapStateToProps = state => {
  //  console.warn(state.OverrideCouponsInventoryReducer.OverrideCouponsInventory)
    return {
        OverrideCouponsInventory_GET_status:state.OverrideCouponsInventoryReducer.OverrideCouponsInventory_Status,
        OverrideCouponsInventory:state.OverrideCouponsInventoryReducer.OverrideCouponsInventory,
        OverrideCouponsInventory_Error:state.OverrideCouponsInventoryReducer.OverrideCouponsInventory_Error,
        ref_data:state.OverrideCouponsInventoryReducer.ref_data
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
        getOverrideCouponsInventory: (data) => {

           dispatch( OverrideCouponsInventoryServer.getOverrideCouponsInventory(data) )
        },
        resetReducerState:() =>{
            dispatch({type:OverrideCouponsInventoryActions.OverrideCouponsInventory_GET.NEW})
        }
    }
};
const OverrideCouponsInventoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OverrideCouponsInventoryView)

export default OverrideCouponsInventoryContainer;