import React, { PureComponent, Fragment } from "react";
import ReactDOM from "react-dom";
import {
  ADMIN_END_BRANDING_IMAGE_URL,
  ROOT_URL,
  getCookie,
} from "../../Config/Config";
import "../Cards/PreviewCouponCard.css";
// import dateFormat from "dateformat";
// import Doc from "../PDFDownload/DocService";
// import Loading from "../Utils/Loading"
import moment from "moment";
import {
  coupon_transaction_subtype_id,
  coupon_transaction_type_id,
} from "RefRecordsConstants";
import SingleInputRadio from "../Inputs/InputRadio/SingleInputRadio";
// import ViewCouponModel from '../Cards/ViewCouponModel/ViewCouponModel'
import {
  MyCardDesign,
  MyCardDetailed,
  MyCopyCouponModal,
  MyDownloadPdf,
} from "njt_card_design";
import "njt_card_design/dist/index.css";
import "njt_card_design/dist/productslider.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AcceptVideos,
  REGION,
  SERVER_S3_BUCKET,
  DYNAMIC_IMAGES_URL,
} from "../../Config/Config";
let Videos = AcceptVideos.split(", ");

const modalRoot = document.getElementById("preview_coupon_portal");
var cache_url = "";
var cache_imgdata = "";
const bodyRef = React.createRef();
// const createPdf = () => props.createPdf(bodyRef.current);

export class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collaps: true,
      detailShow: false,
      screenCapture: "",
      loading: true,
      imgURL: cache_imgdata,
      inRewadsCheck: true,
      inWalletCheck: false,
      showModal: false,
      showWalletModal: false,
    };
    // alert(props.image_location.length)
    // var imgUrl = '';
    // if (props.image_location && props.image_location !== '')
    //   imgUrl = props.image_location;
    // else if (props.logo_url && props.logo_url !== '') {
    //   imgUrl = props.logo_url;
    // } else imgUrl = ADMIN_END_BRANDING_IMAGE_URL + 'Njt-Logo-All.png';
    // if (cache_url != imgUrl) {
    //   this.getImageData(imgUrl);
    // } else {
    //   this.state.loading = false;
    // }
  }
  getImageData = async (imgUrl) => {
    try {
      // alert("test")
      this.state.loading = true;
      let image = await require("axios")({
        method: "post",
        url: ROOT_URL + "/api/business/download_image",
        data: {
          url: imgUrl, // This is the body part
        },
        headers: {
          authorization: getCookie("admin_token_partner"),
        },
      });
      cache_url = imgUrl;
      cache_imgdata = "data:image/jpeg;base64," + image.data.imageData;
      this.setState({ imgURL: cache_imgdata, loading: false });
    } catch (e) {
      this.setState({ loading: false });

      console.log(e);
    }
  };

  ValidateURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "(http?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };

  handleScreenCapture = (screenCapture) => {
    this.setState({
      screenCapture,
    });
  };
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  collapsHandler = () => {
    // this.setState({ menuFlag: !this.state.menuFlag });
    this.setState({ collaps: !this.state.collaps });
  };
  getUTC_date = (date) => {
    var d = new Date(date);
    d.setHours(d.getHours() + 5);
    // alert(d)
    return d;
  };

  createPdf = async (html) => {
    // console.log(html)
    // Doc.createPdf(html)

    //     // const image = <img src = {imgData} />
    //     // document.body.appendChild(image);
    //     // Doc.createPdf(canvas, this.props.business_name.replace(/ /g,'_')+'_Coupon_' +
    //     // dateFormat(new Date(), 'mm-dd-yyyy') +
    //     // 'T' +
    //     // dateFormat(this.getUTC_date(new Date()), 'UTC:h:MM:ss')
    //     // )
    // }).catch(err=>{
    //   console.log(err)
    // })

    //   html2canvas( ReactDOMServer.renderToStaticMarkup(html)).then((canvas)=> {
    //     console.log(canvas)

    //     const imgData = canvas.toDataURL('image/png');
    //     console.log(imgData)
    //     // const image = <img src = {imgData} />
    //     // document.body.appendChild(image);
    //     // Doc.createPdf(canvas, this.props.business_name.replace(/ /g,'_')+'_Coupon_' +
    //     // dateFormat(new Date(), 'mm-dd-yyyy') +
    //     // 'T' +
    //     // dateFormat(this.getUTC_date(new Date()), 'UTC:h:MM:ss')
    //     // )
    // }).catch(err=>{
    //   console.log(err)
    // })

    // console.log(html);
    // var container = document.body; // full page
    // html2canvas(html).then(function(canvas) {
    //           var link = document.createElement("a");
    //           document.body.appendChild(link);
    //           link.download = "html_image.png";
    //           link.href = canvas.toDataURL("image/png");
    //           link.target = '_blank';
    //           link.click();
    //       });

    // alert(this.state.imgURL)
    // fetch(this.state.imgURL, {mode: 'no-cors'})
    // .then(response => response.json())
    //   .then(json => {
    //     console.log('parsed json', json) // access json.body here
    //   })

    console.log(html);
    // Doc.createPdf(
    //   html,
    //   this.props.business_name.replace(/ /g, '_') +
    //     '_Coupon_' +
    //     moment(new Date()).format('MM/DD/YYYY') + //dateFormat(new Date(), 'mm-dd-yyyy') +
    //     'T' +
    //     moment(new Date()).format('hh:mm:ss') +
    //     // dateFormat(this.getUTC_date(new Date()), 'UTC:h:mm:ss')+
    //     '.pdf'
    // );
  };

  // this.toDataUrl(this.state.imgURL)
  //         .then(result => console.log(result))
  //         .catch(err => console.error(err));

  // var link = document.createElement("a");
  //           document.body.appendChild(link);
  //           link.download = "html_image.png";
  //           link.href = this.state.imgURL;
  //           link.target = '_blank';
  //           link.click();
  // Doc.createPdf(html, this.props.business_name.replace(/ /g,'_')+'_Coupon_' +
  // dateFormat(new Date(), 'mm-dd-yyyy') +
  // 'T' +
  // dateFormat(this.getUTC_date(new Date()), 'UTC:h:MM:ss')
  // )
  handleRedeemButton = () => {
    if (!this.state.inRewadsCheck) {
      this.setState({
        showWalletModal: true,
      });
      // alert("wallet"+JSON.stringify(this.props.coupon_display_behavior))
    }
  };

  closeModalHandler = () => {
    this.setState({
      showWalletModal: false,
    });
  };

  inRewardsHandler = () => {
    this.setState({
      inRewadsCheck: true,
      inWalletCheck: false,
      showModal: false,
    });
  };
  inWalletHandler = () => {
    this.setState({
      inRewadsCheck: false,
      inWalletCheck: true,
      showModal: false,
    });
  };

  getDisplayCouponTag = () => {
    console.log(
      "getDisplay",
      this.props.Display_Coupon_Tag,
      this.props.couponType,
      this.props.coupon_transaction_subtype_id
    );
    // alert(this.props.coupon_transaction_subtype_id)
    if (!this.props.Display_Coupon_Tag) return "";

    if (
      this.props.coupon_transaction_type_id ===
      coupon_transaction_type_id.prepaid
    ) {
      return "PREPAID";
    } else if (
      this.props.coupon_transaction_type_id ===
      coupon_transaction_type_id.discount
    ) {
      // alert("sds")
      if (
        this.props.coupon_transaction_subtype_id ===
        coupon_transaction_subtype_id.PercentageType
      )
        return "PERCENT DISCOUNT";
      else return "DOLLAR DISCOUNT";
    } else if (
      this.props.coupon_transaction_type_id ===
      coupon_transaction_type_id.buy_x_gey_y
    )
      return "BUY X GET Y FREE";
  };
  setExpityDate = (fromInventory) => {
    let endTimeWithoutOffset = this.props.endTimeWithoutOffset;
    let expiryDate = this.props.expiryDate;
    let redemption_end_date = this.props.redemption_end_date;
    let canBeUsedEndTimeWithoutOffset =
      this.props.canBeUsedEndTimeWithoutOffset;
    let can_only_be_used_on = this.props.can_only_be_used_on;
    // alert(redemption_end_date)
    if (fromInventory) {
      if (!this.state.inRewadsCheck) {
        if (redemption_end_date)
          return moment(redemption_end_date).format("MM/DD/YYYY h:mm A");
        else if (can_only_be_used_on)
          return moment(can_only_be_used_on).format("MM/DD/YYYY") + " 11:59 PM";
      }
      return expiryDate;
    } else {
      if (!this.state.inRewadsCheck) {
        // alert(this.props.redemption_end_date )
        if (this.props.redemption_end_date)
          return (
            moment(redemption_end_date).format("MM/DD/YYYY") +
            " " +
            (canBeUsedEndTimeWithoutOffset
              ? canBeUsedEndTimeWithoutOffset
              : "11:59 PM")
          );
        else if (can_only_be_used_on)
          return moment(can_only_be_used_on).format("MM/DD/YYYY") + " 11:59 PM";
        //alert('jhasb')
      }
      return (
        moment(expiryDate).format("MM/DD/YYYY") +
        " " +
        (endTimeWithoutOffset ? endTimeWithoutOffset : "11:59 PM")
      );
    }
  };

  showDetails = () => {
    this.setState({
      showModal: true,
    });
  };

  hideDetails = () => {
    this.setState({
      showModal: false,
    });
  };


  RedeemButtonHandler = ()=>{

    const {redemption_url}= this.props


    if(redemption_url && redemption_url !='/'){
      // alert(redemption_url)

      let redemption_url_modified= redemption_url

      if(!redemption_url.includes("http"))
      redemption_url_modified = "//" + redemption_url

      window.open(redemption_url_modified, "_blank");

    }

  }

  render() {
    console.log(this.props, "jhasdjhasjdhasjda");
    // alert(DYNAMIC_IMAGES_URL)
    var {
      // collapsDes,
      Quantity,
      // threshold,
      // image_location,
      // logo_url,
      coupon_face_value,
      // coupon_terms,
      CouponTerms,
      business_name,
      business_description,
      points,
      expiryDate,
      // ValidFor,
      legalese,
      business_street_1,
      business_street_2,
      referral_fee_type_percent,
      // referral_fee_type_fixed,
      referral_fee_percent,
      referral_fee_fixed,
      display_address_on_coupon,
      state = "", //{coupon.state}
      city = "", //{coupon.city}
      zip5 = "", //{coupon.zip5}
      zip4 = "", //{coupon.zip4}
      WalletURL,
      RewardURL,
      company_url,
      redemption_end_date,
      can_only_be_used_on,
      endTimeWithoutOffset,
      canBeUsedEndTimeWithoutOffset,
      coupon_display_behavior,
      image_location,
      business_logo_url,
      offer_name,
      offerDescription,
      fromInventory,
    } = this.props;
    zip4 = zip4 ? zip4 : "";
    // alert(business_logo_url)
    let main_offer_image = ADMIN_END_BRANDING_IMAGE_URL + "Njt-Logo-All.svg";

    // let logo_url = business_logo_url //ADMIN_END_BRANDING_IMAGE_URL + "Njt-Logo-All.svg";
    
    // if (business_logo_url && business_logo_url !=="")
    // main_offer_image = business_logo_url;
    // else
    // business_logo_url =  ADMIN_END_BRANDING_IMAGE_URL + "Njt-Logo-All.svg";

    console.log("array", this.props)

    console.log("images", image_location)

    let images = [];
    let videos = [];
    if (image_location && image_location.length > 0) {
      if (fromInventory) {
        main_offer_image = image_location[0];
        image_location.forEach((element) => {
          images.push(element);
        });
      } else {
        main_offer_image = JSON.parse(image_location[0]).image_location;
        image_location.forEach((element) => {
          let item = JSON.parse(element);
          images.push(item.image_location);
        });
      }
    } 
    // else images.push(main_offer_image);

    // alert(RewardURL)
    
    console.log("images", images)
    
    //visitWebsiteURL

    let rewardWebsiteURL;
    let walletWebsiteURL;
    
    if (RewardURL) {
      rewardWebsiteURL = RewardURL.includes("http")
        ? RewardURL
        : "http://" + RewardURL;
      } else if (company_url && company_url != "") {
        rewardWebsiteURL = company_url.includes("http")
        ? company_url
        : "http://" + company_url;
      } else {
        rewardWebsiteURL = null;
      }
      
      
      // alert(WalletURL)
      
    if (WalletURL && WalletURL != "") {
      walletWebsiteURL = WalletURL.includes("http")
        ? WalletURL
        : "http://" + WalletURL;
    }
    else if (company_url && company_url != "") {
      walletWebsiteURL = company_url.includes("http")
        ? company_url
        : "http://" + company_url;
    } else {
      walletWebsiteURL = null;
    }



    // Visit Website Business Logic

    let locationURL = "#";
    if (display_address_on_coupon) {
      locationURL =
        "http://maps.google.com/?q=" +
        business_street_1 +
        "," +
        (business_street_2 ? " " + business_street_2 + "," : "") +
        " " +
        city +
        " " +
        state +
        " " +
        zip5 +
        " " +
        zip4;
    }



    // let images = []
    // let videos=[]
    // console.log("media Array", this.props.coupon.CouponMediaArray)
    // let re = /(?:\.([^.]+))?$/;
    // if(this.props.coupon.CouponMediaArray && this.props.coupon.CouponMediaArray.length > 0){
    //     this.props.coupon.CouponMediaArray.split(";;;").forEach((item)=>{
    //       let ext="."+(re.exec(item)[1])
    //       if(Videos.includes(ext))
    //       videos.push(item)
    //       else
    //       images.push(item)
    //     })
    // }else if(this.props.coupon.image_location)
    // images.push(this.props.coupon.image_location)

    var potentialFee = 0;
    if (referral_fee_type_percent)
      potentialFee =
        ((referral_fee_percent * parseFloat(coupon_face_value)) / 100.0) *
        parseFloat(Quantity);
    else potentialFee = referral_fee_fixed * parseFloat(Quantity);

    // alert(WalletURL)
    // alert(this.props.coupon_transaction_type_id)
    // let SliderProps = {}
    // alert(JSON.stringify(images))
    // if(this.state.showWalletModal)
    // return  <MyCopyCouponModal
    //          showModal={this.state.showWalletModal}
    //          business_name={business_name}
    //          showCopyOfferCode= {coupon_display_behavior.split('')[0]}
    //          showCopyQRCode= {coupon_display_behavior.split('')[1]}
    //          showRedeemButton= {coupon_display_behavior.split('')[2]}
    //          closeModalHandler={this.closeModalHandler}
    // />
    // alert(business_logo_url)

    // alert(points)
    images=images.filter((item)=>item)
// alert(JSON.stringify(images))
    console.log("business desc", this.props.business_description);


    let businessAddress =
    business_street_1 +
    "," +
    (business_street_2 ? " " + business_street_2 + "," : "") +
    " " +
    city +
    " " +
    state +
    " " +
    (zip5 ? zip5: "")+
    " " +
    (zip4 ? zip4: ""); 
    // alert(!!parseInt(coupon_display_behavior.split("")[0]))
    // alert(coupon_display_behavior)
    return ReactDOM.createPortal(
      <div
        style={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.3)",
          minHeight: "100vh",
          // overflow: 'hidden',
          zIndex: "10",
        }}
        // onClick={this.props.onClose}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            borderRadius: "8px",
            display: "inline-block",
            minHeight: "300px",
            margin: "1rem",
            position: "relative",
            maxWidth: "450px",
            width:"450px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
            justifySelf: "center",
          }}
        >
          <div className="box-sss-scroll">
            <MyCopyCouponModal
              showModal={this.state.showWalletModal}
              business_name={business_name}
              offer_terms={this.props.legalese}
              showCopyOfferCode={!!parseInt(coupon_display_behavior.split("")[0])}
                // coupon_display_behavior.split("")[0]}
              showCopyQRCode={!!parseInt(coupon_display_behavior.split("")[1])}
              showRedeemButton={!!parseInt(coupon_display_behavior.split("")[2])}
              closeModalHandler={this.closeModalHandler}
              coupon_code={"XXXX-XXXX"}

              RedeemButtonHandler={this.RedeemButtonHandler}

            />

            <div className="main-box-cpn">
              <div className="cros-icon new-design">
                <span
                  id="colseModal"
                  onClick={this.props.onClose}
                  className="material-icons"
                >
                  clear
                </span>
              </div>
              <div className="header-new-card">
                <div className="new-head-letf-card">
                  <h3 className="cpn-heading">Preview Offer</h3>
                  <div className="admin-new-card-dd">
                    This is what the customer will see
                  </div>
                </div>
                <div className="right-head-card-new">
                  <div className="download-icon-box">
                    <MyDownloadPdf
                 
                      DYNAMIC_IMAGES_URL={DYNAMIC_IMAGES_URL}
                      SERVER_S3_BUCKET={SERVER_S3_BUCKET}
                      REGION={REGION}
                      imageGallery={images}
                      CardDesignProps={{
                        bodyRef: null,
                        DYNAMIC_IMAGES_URL: DYNAMIC_IMAGES_URL,
                        SERVER_S3_BUCKET: SERVER_S3_BUCKET,
                        REGION: REGION,
                        isWallet:
                          !this.state.inRewadsCheck &&
                          parseInt(this.props.coupon_transaction_type_id) !==
                            coupon_transaction_type_id.prepaid,
                        redirectOfferDetails: this.showDetails,
                        display_address_on_coupon: display_address_on_coupon,
                        businessAddress: city + ", " + state,
                        OfferList: [
                          {
                            rotating_offer_img:
                              "https://njtbranding.s3.amazonaws.com/Offer-Type-Label.svg",
                            offer_type: this.getDisplayCouponTag(),
                            main_offer_img: main_offer_image,
                            images: images,
                            offer_logo: business_logo_url,
                            Business_name: business_name,
                            offer_points:
                              parseInt(
                                this.props.coupon_transaction_type_id
                              ) === coupon_transaction_type_id.prepaid
                                ? "$" + coupon_face_value
                                : parseFloat(points).toFixed(0) + "pts",
                            offer_title: offer_name,
                            showStarIcon:
                              parseInt(
                                this.props.coupon_transaction_type_id
                              ) !== coupon_transaction_type_id.prepaid,
                          },
                        ],
                      }}
                      CardDetailedProps={{
                        DYNAMIC_IMAGES_URL: DYNAMIC_IMAGES_URL,
                        SERVER_S3_BUCKET: SERVER_S3_BUCKET,
                        REGION: REGION,
                        Business_name: business_name,
                        defaultColumns: 12,
                        businessAddress:businessAddress,
                        display_address_on_coupon:display_address_on_coupon,
                        offer_cost:
                          parseInt(this.props.coupon_transaction_type_id) ===
                          coupon_transaction_type_id.prepaid
                            ? "$" + coupon_face_value
                            : parseFloat(points).toFixed(0) + "pts",
                        location_url: locationURL,
                        website_url:
                          this.state.inRewadsCheck ? rewardWebsiteURL: walletWebsiteURL,
                        showReturnButton:
                          !this.state.inRewadsCheck &&
                          parseInt(this.props.coupon_transaction_type_id) !==
                            coupon_transaction_type_id.prepaid,
                        SliderProps: {
                          infiniteLoop: null,
                          ribbon:
                            "https://njtbranding.s3.amazonaws.com/Offer-Type-Label.svg",
                          offer_type: this.getDisplayCouponTag(),
                          images: images,
                          videos: videos,
                          offer_logo: business_logo_url,
                          backButtonHandler: this.hideDetails,
                        },
                        CardDetailsProps:{
                          Offer_title: offer_name,
                          showReturnButton:
                            !this.state.inRewadsCheck &&
                            parseInt(this.props.coupon_transaction_type_id) !==
                              coupon_transaction_type_id.prepaid,
                          Business_Description:
                            business_description && business_description != ""
                              ? business_description
                              : "",
                          expiry_date: this.setExpityDate(fromInventory), //fromInventory?expiryDate: moment(expiryDate).format('MM/DD/YYYY') + " " + (endTimeWithoutOffset ? endTimeWithoutOffset: "11:59 PM"),
                          expiry_date_time: this.setExpityDate(fromInventory), //fromInventory?expiryDate: moment(expiryDate).format('MM/DD/YYYY') + " " + (endTimeWithoutOffset ? endTimeWithoutOffset: "11:59 PM"),
                          TermsAndConditions: legalese
                            ? legalese
                            : "No Terms & Conditions Applied",
                          RedeemText: this.state.inRewadsCheck
                            ? "Click to Claim the Offer Code"
                            : "View Offer",
                          handleRedeemButton: this.handleRedeemButton,
                        },
                        showStarIcon:
                          parseInt(this.props.coupon_transaction_type_id) !==
                          coupon_transaction_type_id.prepaid,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="radio-btn-reward">
              <div className="radio-inn-btn-wrap">
                <SingleInputRadio
                  label="In Rewards"
                  onChange={this.inRewardsHandler}
                  checked={this.state.inRewadsCheck}
                  id="inrewards"
                />
                <SingleInputRadio
                  label="In Wallet"
                  onChange={this.inWalletHandler}
                  checked={!this.state.inRewadsCheck}
                  id="inWallet"
                />
              </div>
            </div>
            <div className="design_new_card new_card">
            {this.state.showModal ? (
              <MyCardDetailed
                DYNAMIC_IMAGES_URL={DYNAMIC_IMAGES_URL}
                SERVER_S3_BUCKET={SERVER_S3_BUCKET}
                REGION={REGION}
                Business_name={business_name}
                defaultColumns={12}
                display_address_on_coupon={display_address_on_coupon}
                offer_cost={
                  parseInt(this.props.coupon_transaction_type_id) ===
                  coupon_transaction_type_id.prepaid
                    ? "$" + coupon_face_value
                    : parseFloat(points).toFixed(0) + "pts"
                }
                location_url={locationURL}
                website_url={
                  this.state.inRewadsCheck ? rewardWebsiteURL: walletWebsiteURL
                }
                showReturnButton={
                  !this.state.inRewadsCheck &&
                  parseInt(this.props.coupon_transaction_type_id) !==
                    coupon_transaction_type_id.prepaid
                }
                businessAddress={businessAddress}
                SliderProps={{
                  DYNAMIC_IMAGES_URL:DYNAMIC_IMAGES_URL,
                  SERVER_S3_BUCKET:SERVER_S3_BUCKET,
                  REGION:REGION,
                  ribbon:
                    "https://njtbranding.s3.amazonaws.com/Offer-Type-Label.svg",
                  offer_type: this.getDisplayCouponTag(),
                  images: images,
                  videos: videos,
                  offer_logo: business_logo_url,
                  backButtonHandler: this.hideDetails,
                }}
                CardDetailsProps={{
                  Offer_title: offer_name,
                  showReturnButton:
                    !this.state.inRewadsCheck &&
                    parseInt(this.props.coupon_transaction_type_id) !==
                      coupon_transaction_type_id.prepaid,
                  Business_Description:
                    business_description && business_description != ""
                      ? business_description
                      : "",
                  expiry_date: this.setExpityDate(fromInventory), //fromInventory?expiryDate: moment(expiryDate).format('MM/DD/YYYY') + " " + (endTimeWithoutOffset ? endTimeWithoutOffset: "11:59 PM"),
                  expiry_date_time: this.setExpityDate(fromInventory), //fromInventory?expiryDate: moment(expiryDate).format('MM/DD/YYYY') + " " + (endTimeWithoutOffset ? endTimeWithoutOffset: "11:59 PM"),
                  TermsAndConditions: legalese
                    ? legalese
                    : "No Terms & Conditions Applied",
                  RedeemText: this.state.inRewadsCheck
                    ? "Click to Claim the Offer Code"
                    : "View Offer",
                  handleRedeemButton: this.handleRedeemButton,
                }}
                showStarIcon={
                  parseInt(this.props.coupon_transaction_type_id) !==
                  coupon_transaction_type_id.prepaid
                }
              />
            ) : (
              <MyCardDesign
                DYNAMIC_IMAGES_URL={DYNAMIC_IMAGES_URL}
                SERVER_S3_BUCKET={SERVER_S3_BUCKET}
                REGION={REGION}
                isWallet={false}
                //   !this.state.inRewadsCheck &&
                //   parseInt(this.props.coupon_transaction_type_id) !==
                //     coupon_transaction_type_id.prepaid
                // }
                claimOfferDetails={this.handleRedeemButton}
                ClaimText={this.state.inRewadsCheck ? (parseInt(this.props.coupon_transaction_type_id) === coupon_transaction_type_id.prepaid ?"Purchase Offer":"Claim Offer"):"Offer Code"}
                redirectOfferDetails={this.showDetails}
                display_address_on_coupon={display_address_on_coupon}
                businessAddress={city + ", " + state}
                OfferList={[
                  {
                    rotating_offer_img:
                      "https://njtbranding.s3.amazonaws.com/Offer-Type-Label.svg",
                    offer_type: this.getDisplayCouponTag(),
                    main_offer_img: main_offer_image,
                    images: images,
                    offer_logo: business_logo_url,
                    Business_name: business_name,
                    offer_points:
                      parseInt(this.props.coupon_transaction_type_id) ===
                      coupon_transaction_type_id.prepaid
                        ? "$" + coupon_face_value
                        : parseFloat(points).toFixed(0) + "pts",
                    offer_title: offer_name,
                    showStarIcon:
                      parseInt(this.props.coupon_transaction_type_id) !==
                      coupon_transaction_type_id.prepaid,
                  }
                ]
              }
              />
            )}
            </div>
            <div className="admin-new-card-dd-2">
              <b>Note:</b> These are your costs.
            </div>

            <div className="inner-liab-box">
              <div className="inner-liab-wrap">
                <div className="new-cost-box">Potential Partner Costs</div>
                <div className="inner-liab-txt-box margin">
                  <div className="liab-text-left">Total Referral Fees</div>
                  <div className="liab-text-right">
                    ${potentialFee.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      modalRoot
    );
  }
}

class PreviewCouponCard extends React.Component {
  state = { showModal: false };
  handleShowMessageClick = () => this.setState({ showModal: true });
  handleCloseModal = () => this.setState({ showModal: false });
  render() {
    return (
      <div
        style={{
          height: "100%",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 400,
            position: "relative",
          }}
        >
          <button
            id="previewCoupon"
            className="buttonStyle"
            onClick={this.handleShowMessageClick}
          >
            Preview Coupon
          </button>
          {this.state.showModal ? (
            <Modal onClose={this.handleCloseModal}>
              {/* This is the secret modal message! */}
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

export default PreviewCouponCard;
